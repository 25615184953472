import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import {
  YearlyKycUpdateDetailsForm,
  YearlyKycUpdateDetailsFormValues,
} from '../../Profile/YearlyKycUpdateDetails/YearlyKycUpdateDetailsForm/YearlyKycUpdateDetailsForm'
import {
  useYearlyKycUpdateDetails,
  useYearlyKycUpdateDetailsMutate,
} from '../../Profile/YearlyKycUpdateDetails/YearlyKycUpdateDetailsPage'
import { RiskWarningModal } from '../../SignUp/SignupPage'
import { Loading } from '../../global/Loading/Loading'
import { CompletionBlock } from '../../global/completionBlock/CompletionBlock'
import { useLossPercentagesReadContext } from '../../global/context/LossPercentagesContext'
import { useProductReadContext, useProductWriteContext } from '../../global/context/ProductContext'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { CancelActionModal } from '../../global/modal/CancleActionModal'
import { InformationModal } from '../../global/modal/InformationModal'
import { Modal } from '../../global/modal/Modal'
import { FormTemplate } from '../../global/templates/FormTemplate'
import { TickmillProductType } from '../../model/TickmillProductType'
import { useAccountReadContext, useAccountWriteContext } from '../../utils/AccountContextContext'
import { isTickmillPartner } from '../../utils/companyName.utils'
import { navigateBasedOnProductType } from '../../utils/navigationUtils'
import { ProductRiskWarning } from '../ProductRiskWarning'

import styles from './ProductPersonalDetails.module.scss'

export const ProductPersonalDetailsPage: React.FC = () => {
  const navigate = useNavigate
  const { account } = useAccountReadContext()

  const { product } = useProductReadContext()
  const { changeProductContext } = useProductWriteContext()
  const { isDefaultCFDProductType } = useProductReadContext()
  const { percentages } = useLossPercentagesReadContext()

  const [riskWarningModalVisible, setRiskWarningModalVisible] = useState(false)
  const [exitConfirmationModal, setExitConfirmationModal] = useState(false)

  const isCFDProduct = isDefaultCFDProductType()
  const isPartner = isTickmillPartner(account)

  const productKey = TickmillProductType[product]

  const { countries, accountKycTest, tinUnavailableReasons, isLoading } =
    useYearlyKycUpdateDetails()

  const newProductRegistrationRequirement = true
  const mutation = useYearlyKycUpdateDetailsMutate(newProductRegistrationRequirement)

  const handleSubmitForm = async (values: YearlyKycUpdateDetailsFormValues) => {
    await mutation.mutate(account, accountKycTest, values, setAlreadyUploadedModal)
  }

  const [alreadyUploadedModal, setAlreadyUploadedModal] = useState(false)

  const handleCloseErrorModal = () => setAlreadyUploadedModal(false)

  const { t } = useTranslation()

  const closeExitConfirmationModal = () => {
    setExitConfirmationModal(false)
  }

  const handleOnExit = () => {
    setExitConfirmationModal(true)
  }

  const handleRiskWarningClick = () => {
    setRiskWarningModalVisible(true)
  }

  const closeRiskWarning = () => {
    setRiskWarningModalVisible(false)
  }

  const handleConfirmExit = () => {
    setExitConfirmationModal(false)
    changeProductContext(isCFDProduct ? TickmillProductType.ETD : TickmillProductType.CFD)
    navigateBasedOnProductType(isCFDProduct, navigate, isPartner)
  }

  return (
    <Loading isLoading={isLoading || mutation.isLoading} showLoadingIcon>
      {riskWarningModalVisible && (
        <Modal
          closeModal={closeRiskWarning}
          render={({ closeModal }) => (
            <RiskWarningModal
              onCancel={closeModal}
              products={[productKey]}
              risk={percentages?.percentage}
            />
          )}
        />
      )}
      {exitConfirmationModal && (
        <Modal
          closeModal={closeExitConfirmationModal}
          render={() => (
            <CancelActionModal
              onCancel={closeExitConfirmationModal}
              onConfirm={handleConfirmExit}
            />
          )}
        />
      )}
      {alreadyUploadedModal && (
        <Modal
          closeModal={handleCloseErrorModal}
          render={() => (
            <InformationModal
              onCancel={handleCloseErrorModal}
              buttonText={t('Got It')}
              title={t('Upload failed')}
            >
              <p className='text'>{t('The document has been already uploaded')}</p>
            </InformationModal>
          )}
        />
      )}
      <FormTemplate
        title={t('Profile.Are your details up to date?')}
        renderIcon={<ProductRiskWarning onClick={handleRiskWarningClick} />}
        goBack={handleOnExit}
        contentClassName={classNames(styles.container, { [styles.boxLess]: !mutation.isSuccess })}
      >
        {!mutation.isSuccess && (
          <YearlyKycUpdateDetailsForm
            account={account}
            countries={countries}
            accountKycTest={accountKycTest}
            onSubmit={handleSubmitForm}
            tinUnavailableReasons={tinUnavailableReasons}
            productRegistrationRequirement={newProductRegistrationRequirement}
          />
        )}
        {mutation.isSuccess && <SuccessPage />}
      </FormTemplate>
    </Loading>
  )
}

const SuccessPage: React.FC = () => {
  const { isDefaultCFDProductType } = useProductReadContext()
  const { account } = useAccountReadContext()
  const isPartner = isTickmillPartner(account)

  const navigate = useNavigate()
  const { refreshAccount } = useAccountWriteContext()
  const locale = useSessionLanguage()

  const goToDashboard = async () => {
    await refreshAccount(locale)
    navigateBasedOnProductType(isDefaultCFDProductType(), navigate, isPartner)
  }

  return <CompletionBlock onButtonClick={goToDashboard} />
}
