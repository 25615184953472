import React from 'react'

import { NameDto } from '../../model/NameDto'
import { InformationModal } from './InformationModal'
import { Modal } from './Modal'

interface TermsModalProps {
  title: string
  terms: NameDto[]

  onClose(): void

  renderItem?(item: string): React.ReactNode
}

export const TermsModal: React.FC<TermsModalProps> = (props) => {
  const { title, terms, onClose, renderItem } = props

  return (
    <Modal
      closeModal={onClose}
      render={() => (
        <InformationModal title={title} onCancel={onClose}>
          <ul>
            {terms.map((x, index) => (
              <li key={x.id}>
                {renderItem ? renderItem(`${index + 1}. ${x.name}`) : `${x.id}. ${x.name}`}
              </li>
            ))}
          </ul>
        </InformationModal>
      )}
    />
  )
}
