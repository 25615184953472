import React, { ForwardedRef, HTMLAttributes, forwardRef } from 'react'
import classNames from 'classnames'

import styles from './Typography.module.scss'

interface TypoProps extends Omit<HTMLAttributes<HTMLSpanElement>, 'className'> {
  id?: string
  children?: React.ReactNode
  className?: string
}

enum ETypographyKind {
  textHero = 'textHero',
  textH1 = 'textH1',
  textH2 = 'textH2',
  textH3 = 'textH3',
  textH4 = 'textH4',
  textLarge = 'textLarge',
  textLargeStrong = 'textLargeStrong',
  text = 'text',
  textStrong = 'textStrong',
  textSmall = 'textSmall',
  textSmallStrong = 'textSmallStrong',
  textTiny = 'textTiny',
  textTinyStrong = 'textTinyStrong',
  textMicro = 'textMicro',
}

const typographyFactory = (kind: ETypographyKind) => {
  return forwardRef(
    (
      props: TypoProps & { isParagraph?: boolean },
      ref?: ForwardedRef<HTMLParagraphElement | HTMLSpanElement>
    ) => {
      const { isParagraph, ...restProps } = props

      if (isParagraph) {
        return (
          <p
            {...restProps}
            ref={ref as React.LegacyRef<HTMLParagraphElement>}
            className={classNames(styles[kind], props.className)}
          >
            {props.children}
          </p>
        )
      }

      return (
        <span
          {...restProps}
          ref={ref as React.LegacyRef<HTMLSpanElement>}
          className={classNames(styles[kind], props.className)}
        >
          {props.children}
        </span>
      )
    }
  )
}

export const TextHero = typographyFactory(ETypographyKind.textHero)

export const TextH1 = typographyFactory(ETypographyKind.textH1)
export const TextH2 = typographyFactory(ETypographyKind.textH2)
export const TextH3 = typographyFactory(ETypographyKind.textH3)
export const TextH4 = typographyFactory(ETypographyKind.textH4)

export const TextLarge = typographyFactory(ETypographyKind.textLarge)
export const TextLargeStrong = typographyFactory(ETypographyKind.textLargeStrong)
export const Text = typographyFactory(ETypographyKind.text)
export const TextStrong = typographyFactory(ETypographyKind.textStrong)
export const TextSmall = typographyFactory(ETypographyKind.textSmall)
export const TextSmallStrong = typographyFactory(ETypographyKind.textSmallStrong)
export const TextTiny = typographyFactory(ETypographyKind.textTiny)
export const TextTinyStrong = typographyFactory(ETypographyKind.textTinyStrong)
export const TextMicro = typographyFactory(ETypographyKind.textMicro)
