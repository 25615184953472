import React, { SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikErrors, FormikProps, withFormik } from 'formik'
import { t } from 'i18next'

import { RawSelectFormField } from '../../global/formField/FormSelectField'
import { NameDto } from '../../model/NameDto'
import { Text } from '../../ui/Typography/Typography'
import { FormSubmitValues } from '../../utils/formValidation'

import styles from './AccountSettingsPage.module.scss'

export interface DateFormatFormValues {
  dateFormatTypeId: string
}

const DateFormatFormUI: React.FC<FormikProps<DateFormatFormValues> & OuterProps> = (props) => {
  const { handleSubmit, setSubmitting } = props
  const { t } = useTranslation()
  const handleChangeForm = (event: SyntheticEvent) => {
    event.preventDefault()
    setSubmitting(true)
    handleSubmit()
  }

  return (
    <>
      <Text>{t('Profile.Choose suitable date format')}</Text>
      <Form className={styles.form} onChange={handleChangeForm}>
        <RawSelectFormField
          showLabel
          component='select'
          type='number'
          name={'dateFormatTypeId'}
          label={t('Profile.Date Format')}
          required
        >
          {(props.dateFormats || []).map((dateFormat) => (
            <option value={dateFormat.id} key={dateFormat.id}>
              {dateFormat.name}
            </option>
          ))}
        </RawSelectFormField>
      </Form>
    </>
  )
}

interface OuterProps {
  dateFormatTypeId?: number
  dateFormats: NameDto<number>[]
  onSubmit(values: FormSubmitValues<DateFormatFormValues>): Promise<void>
}

export const DateFormatForm = withFormik<OuterProps, DateFormatFormValues>({
  mapPropsToValues: ({ dateFormatTypeId }) => {
    return {
      dateFormatTypeId: dateFormatTypeId?.toString() || '2',
    }
  },
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit(values)
    } finally {
      setSubmitting(false)
    }
  },
  validate: (values) => {
    const errors: FormikErrors<DateFormatFormValues> = {}
    if (!values.dateFormatTypeId) {
      errors.dateFormatTypeId = t('Validation.Required')
    }
    return errors
  },
  enableReinitialize: true,
})(DateFormatFormUI)
