import React from 'react'
import { useTranslation } from 'react-i18next'

import { CopyIcon } from '../../icons/CopyIcon'
import { CopyLink } from '../copyLink/CopyLink'
import IconButton, { IconButtonProps } from '../iconButton/IconButton'

import styles from './CopyButton.module.scss'

export interface CopyButtonProps extends Omit<IconButtonProps, 'size' | 'appearance'> {
  label?: string
  value: string
}

export const CopyButton: React.FC<CopyButtonProps> = (props) => {
  const { t } = useTranslation()

  const { label = t('Text/link copied!'), value } = props

  return (
    <CopyLink label={label} value={value}>
      <IconButton {...props} className={styles.button}>
        <CopyIcon size={20} />
      </IconButton>
    </CopyLink>
  )
}
