import { UAParser } from 'ua-parser-js'

import { useLinksConfig } from '../configs/configs'
import { usePublicDomainsReadContext } from '../global/context/PublicDomainsContext'
import {
  overrideForZHToCNLanguage,
  useSessionLanguage,
} from '../global/context/SessionSettingsContext'
import {
  getPlatformTypeByVersion,
  getVersionByPlatformType,
  isMT4PlatformType,
  isMT5PlatformType,
  isTMTPlatformType,
} from '../model/PlatformTypeEnum'
import { DemoTradingAccount, TradingAccount } from '../model/TradingAccount'

const parser = new UAParser()

interface SearchParamsProps {
  homePageUrl?: string
  locale?: string
  languageId?: string
  server?: string
  version?: string
  login?: string
}

const useSearchParams = (): SearchParamsProps => {
  const url = new URL(window.location.href)
  const searchParams = url.searchParams

  const { domains } = usePublicDomainsReadContext()
  const locale = useSessionLanguage()
  const languageId = overrideForZHToCNLanguage(locale)

  const homePageUrl = domains?.homePageUrl

  const server = searchParams.get('server') || undefined
  const version = searchParams.get('version') || undefined
  const login = searchParams.get('login') || undefined

  return { homePageUrl, locale, languageId, server, version, login }
}

const buildTradeUrlWithSearchParams = (searchParams: SearchParamsProps) => {
  const { login, server, version, homePageUrl, languageId } = searchParams
  if (login && server && version) {
    const urlSearchParams = {
      login,
      trade_server: server,
      version,
    }

    const params = new URLSearchParams(urlSearchParams)
    const queryParams = params.toString()

    if (languageId !== 'en') {
      return `${homePageUrl}/${languageId}/trade?${queryParams}`
    }

    return `${homePageUrl}/trade?${queryParams}`
  }
  return
}

export const useTraderUrlByPlatform = (
  tradingAccount?: TradingAccount | DemoTradingAccount | undefined
): string | undefined => {
  const { platformType: accountPlatformType, server, platformOverview } = tradingAccount ?? {}

  const locale = useSessionLanguage()
  const { links } = useLinksConfig()
  const languageId = overrideForZHToCNLanguage(locale)
  const { domains } = usePublicDomainsReadContext()

  const osName = parser.getResult().os.name?.toLowerCase()
  const searchParams = useSearchParams()

  const homePageUrl = domains?.homePageUrl
  const hasRequiredSearchParams = searchParams.login && searchParams.server && searchParams.version
  const searchParamsPlatformType = getPlatformTypeByVersion(searchParams.version)

  if (accountPlatformType || searchParamsPlatformType) {
    if (osName === 'ios') {
      if (isTMTPlatformType(accountPlatformType?.id)) return links.TradingLinks.IOS.TMT
      if (isMT4PlatformType(accountPlatformType?.id ?? searchParamsPlatformType))
        return links.TradingLinks.IOS.MT4
      if (isMT5PlatformType(accountPlatformType?.id ?? searchParamsPlatformType))
        return links.TradingLinks.IOS.MT5
    } else if (osName === 'android') {
      if (isTMTPlatformType(accountPlatformType?.id)) return links.TradingLinks.Android.TMT
      if (isMT4PlatformType(accountPlatformType?.id ?? searchParamsPlatformType))
        return links.TradingLinks.Android.MT4
      if (isMT5PlatformType(accountPlatformType?.id ?? searchParamsPlatformType))
        return links.TradingLinks.Android.MT5
    } else {
      if (isTMTPlatformType(accountPlatformType?.id)) return links.TradingLinks.Web.TMT
    }
  }

  if (hasRequiredSearchParams) {
    return buildTradeUrlWithSearchParams(searchParams)
  }

  if (
    platformOverview &&
    server &&
    getVersionByPlatformType(accountPlatformType?.id) &&
    accountPlatformType
  ) {
    const queryParams = new URLSearchParams({
      login: platformOverview.login,
      trade_server: server.publicName,
      version: getVersionByPlatformType(accountPlatformType.id),
    }).toString()

    if (languageId !== 'en') return `${homePageUrl}/${languageId}/trade?${queryParams}`
    return `${homePageUrl}/trade?${queryParams}`
  }
  return
}
