import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { AppMarketQRCode } from '../global/appMarketLink/desktop/AppMarketQRCode'
import { AppMarketLink } from '../global/appMarketLink/mobile/AppMarketLink'
import { Button } from '../global/button/Button'
import IconButton from '../global/iconButton/IconButton'
import { Notification } from '../global/notification/notification'
import { ArrowLeftIcon } from '../icons/ArrowLeftIcon'
import { Text, TextSmall } from '../ui/Typography/Typography'
import { useTwoFactorAuth } from './useTwoFactorAuth'

import styles from './TwoFactorAuthPage.module.scss'

export interface TwoFactorAuthErrorLocationState {
  email?: string
}

export interface Resend2FAError {
  hideResend: boolean
  errorTimer: number
}

export const TwoFactorAuthErrorPage: React.FC = () => {
  const navigate = useNavigate()
  const { renderRecoveryModal, setRecoveryCodeModal, stateChecker } = useTwoFactorAuth()
  const { t } = useTranslation()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => stateChecker().effect(), stateChecker().dependants)

  return (
    <div className={styles.topWrapper}>
      <div className={styles.wrapper}>
        {renderRecoveryModal()}
        <div className={classNames(styles.qrCode, 'is-hidden-mobile')}>
          <AppMarketQRCode />
        </div>
        <div className={classNames(styles.inner, 'pt-0')}>
          <IconButton className={'my-2'} onClick={() => navigate('/login')}>
            <div className={styles.backToLogin}>
              <ArrowLeftIcon color='text' />
              <Text>{t('Sign up.Back to Log In')}</Text>
            </div>
          </IconButton>

          <div className={classNames(styles.title, 'my-2')}>
            <h3>{t('Sign up.Two-Factor Authentication')}</h3>
          </div>
          <Notification
            wrapperClassName={'my-0'}
            variant={'danger'}
            subtitle={t('TwoFactorAuth.Youve reached the maximum amount of verification attempts')}
          />
          <TextSmall className={classNames(styles.notice, 'mt-0')}>
            {t('TwoFactorAuth.To restore access to your Client Area')}
          </TextSmall>
          <Button
            className={styles.recoveryCodeButton}
            type='button'
            onClick={() => setRecoveryCodeModal(true)}
            appearance='plain'
            size='S'
          >
            {t('Sign up.Enter recovery code')}
          </Button>
        </div>
        <div className='mt-4 is-hidden-desktop is-hidden-tablet'>
          <AppMarketLink />
        </div>
      </div>
    </div>
  )
}
