import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Button } from '../../../global/button/Button'
import { Dropdown } from '../../../global/dropdown/Dropdown'
import { Table } from '../../../global/table/Table'
import { useFormatNumber } from '../../../hooks/useFormatNumber'
import { DotsIcon } from '../../../icons/DotsIcon'
import { WarningIcon } from '../../../icons/WarningIcon'
import { WalletDto } from '../../../model/WalletDto'
import { AlertIconWrapper } from '../../../ui/AlertIconWrapper/AlertIconWrapper'
import { WalletRestrictions, isRestricted, isWalletSuspended } from '../../../utils/wallet.utils'
import { WalletBalancesOptions } from './WalletBalancesOptions'

import styles from './WalletsBalancesTable.module.scss'

type ExtendedWalletDto = WalletDto & {
  isOpen?: boolean
}

interface WalletTableProps {
  wallets: ExtendedWalletDto[]
  setModalVisible: (v: boolean) => void
  setWalletOptionsModal: (wallet: WalletDto) => void
}

export const WalletBalancesTable: React.FC<WalletTableProps> = (props) => {
  const { wallets, setModalVisible, setWalletOptionsModal } = props

  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()

  const handleRestrictionModalOpen = (wallet: WalletDto) => {
    const hasAllRestrictions = isWalletSuspended(wallet.restrictions)
    if (!hasAllRestrictions) {
      setModalVisible(true)
    }
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>{t('Wallet.Wallet')}</th>
          <th>{t('Wallet.Available Balance')}</th>
          <th>{t('Wallet.Reserved')}</th>
          <th />
          <th />
          <th />
        </tr>
      </thead>
      <tbody className={styles.tableBody}>
        {wallets.map((wallet) => {
          const hasAllRestrictions = isWalletSuspended(wallet.restrictions)
          const isDepositRestricted = isRestricted(WalletRestrictions.DEPOSIT, wallet.restrictions)
          const isTransferFromRestricted = isRestricted(
            WalletRestrictions.TRANSFER_FROM,
            wallet.restrictions
          )

          return (
            <tr key={wallet.id} onClick={() => hasAllRestrictions && setModalVisible(true)}>
              <td onClick={() => hasAllRestrictions && setModalVisible(true)}>
                {hasAllRestrictions && <WarningIcon color={'error'} size={20} />}
                {wallet.currency.id} {t('Wallet.Wallet')}
              </td>
              <td>
                <strong>{formatMoney(wallet.balance, wallet.currency.id)}</strong>
              </td>
              <td>{formatMoney(wallet.reserved, wallet.currency.id)}</td>
              <td>
                {isDepositRestricted && (
                  <AlertIconWrapper spaced isActive={!hasAllRestrictions}>
                    <Button
                      appearance='primary'
                      state='disabled'
                      size='XS'
                      onClick={() => handleRestrictionModalOpen(wallet)}
                      className={styles.button}
                    >
                      {t('Deposit')}
                    </Button>
                  </AlertIconWrapper>
                )}
                {!isDepositRestricted && (
                  <Link to={`/dashboard/traders-room/wallets/${wallet.id}/deposit`}>
                    <Button appearance='primary' size='XS' className={styles.button}>
                      {t('Deposit')}
                    </Button>
                  </Link>
                )}
              </td>
              <td>
                {isTransferFromRestricted && (
                  <AlertIconWrapper spaced isActive={!hasAllRestrictions}>
                    <Button
                      appearance='secondary'
                      state='disabled'
                      size='XS'
                      onClick={() => handleRestrictionModalOpen(wallet)}
                      className={styles.button}
                    >
                      {t('Transfer')}
                    </Button>
                  </AlertIconWrapper>
                )}
                {!isTransferFromRestricted && (
                  <Link to={`/dashboard/traders-room/wallets/${wallet.id}/transfer`}>
                    <Button appearance='secondary' size='XS' className={styles.button}>
                      {t('Transfer')}
                    </Button>
                  </Link>
                )}
              </td>
              <td>
                <span className='has-cursor-pointer' onClick={() => setWalletOptionsModal(wallet)}>
                  <DotsIcon />
                </span>
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}
