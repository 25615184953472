import { useLocation } from 'react-router-dom'

import {
  LeadDto,
  isLeadSourceLiveRegistration,
  isLeadSourceMobileRegistration,
} from '../model/LeadDto'
import {
  ReferralParams,
  getReferralParamsOrCookie,
  isAffiliatesParams,
  isReferralCode,
  isReferralLink,
} from './helpers'

export interface UseReferralToken {
  getReferralTokenValues: (lead?: LeadDto) =>
    | {
        aff_token: string
        referral_code: null
        referral_main_ib_code: null
      }
    | {
        aff_token: null
        referral_code: string
        referral_main_ib_code: string | null
      }
    | {
        aff_token: null
        referral_code: null
        referral_main_ib_code: null
      }
  getReferralTokenCookieValues: () =>
    | {
        aff_token: string
        referral_code: null
        referral_main_ib_code: null
      }
    | {
        aff_token: null
        referral_code: string
        referral_main_ib_code: string | null
      }
    | {
        aff_token: null
        referral_code: null
        referral_main_ib_code: null
      }
  getReferralTokenLeadValues: (lead: LeadDto) =>
    | {
        aff_token: null
        referral_code: null
        referral_main_ib_code: null
      }
    | {
        aff_token: string
        referral_code: null
        referral_main_ib_code: null
      }
    | {
        aff_token: null
        referral_code: string
        referral_main_ib_code: string | null
      }
}

export const useReferralToken = (): UseReferralToken => {
  const location = useLocation()

  const search = location.search
  const referralParams = getReferralParamsOrCookie(search)

  return {
    getReferralTokenValues: (lead?: LeadDto) => getReferralTokenValues(referralParams, lead),

    getReferralTokenCookieValues: () => getReferralTokenCookieValues(referralParams),

    getReferralTokenLeadValues: (lead: LeadDto) => getReferralTokenLeadValues(referralParams, lead),
  }
}

const getReferralTokenValues = (params: ReferralParams, lead?: LeadDto) => {
  if (isAffiliatesParams(params) || isReferralCode(params)) {
    return getReferralTokenCookieValues(params)
  } else {
    return getReferralTokenLeadValues(params, lead)
  }
}

const getReferralTokenCookieValues = (params: ReferralParams) => {
  if (params.aff_token) {
    return {
      aff_token: params.aff_token,
      referral_code: null,
      referral_main_ib_code: null,
    }
  }

  if (params.referral_code) {
    return {
      aff_token: null,
      referral_code: params.referral_code,
      referral_main_ib_code: isReferralLink(params) ? params.referral_code : null,
    }
  }

  return {
    aff_token: null,
    referral_code: null,
    referral_main_ib_code: null,
  }
}

const getReferralTokenLeadValues = (params: ReferralParams, lead?: LeadDto) => {
  if (!lead) {
    return {
      aff_token: null,
      referral_code: null,
      referral_main_ib_code: null,
    }
  }

  const [leadActivity] = lead.activities.filter(
    (x) =>
      isLeadSourceLiveRegistration(x.leadSource) || isLeadSourceMobileRegistration(x.leadSource)
  )

  if (leadActivity.affiliateToken) {
    return {
      aff_token: leadActivity.affiliateToken,
      referral_code: null,
      referral_main_ib_code: null,
    }
  }

  if (leadActivity.referralCode) {
    return {
      aff_token: null,
      referral_code: leadActivity.referralCode,
      referral_main_ib_code: isReferralLink(params) ? leadActivity.referralCode : null,
    }
  }

  return {
    aff_token: null,
    referral_code: null,
    referral_main_ib_code: null,
  }
}
