import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { PageNotFound } from '../global/notFound/PageNotFound'
import { TickmillLogoIcon } from '../icons/TickmillLogoIcon'
import { useApiClient } from '../utils/ApiClient'
import { AuthSessionContext } from '../utils/AuthContext'
import { ClientApiClient } from '../utils/clientApi'
import { getRedirectDomain } from '../utils/companyName.utils'

import styles from './WrongEntityPage.module.scss'

export const WrongEntityPage: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [auth, setAuth] = useContext(AuthSessionContext) // eslint-disable-line @typescript-eslint/no-unused-vars
  const apiClient = useApiClient(ClientApiClient)
  useEffect(() => {
    let delayDebounceFn: NodeJS.Timeout
    delayDebounceFn = setTimeout(() => {
      if (auth?.tickmillCompany.id) {
        try {
          apiClient.logOut(auth?.id).then(() => {
            setAuth(null)
            window.location.replace('https://' + getRedirectDomain(auth?.tickmillCompany.id))
          })
        } finally {
          setAuth(null)
          navigate('/login')
        }
      } else {
        try {
          if (auth?.id) {
            apiClient.logOut(auth?.id).then(() => {
              setAuth(null)
              navigate('/login')
            })
          } else {
            setAuth(null)
            navigate('/login')
          }
        } catch (e: unknown) {
          console.error(e)
        }
      }
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!auth?.tickmillCompany) {
    return <PageNotFound />
  }
  return (
    <div>
      <div className={styles.wrapper}>
        <div className={styles.inner}>
          <TickmillLogoIcon />
          <span>{t('You have logged in to the Client Area from a different domain.')}</span>
          <span>{t('You will be shortly redirected to the entity where you registered.')}</span>
        </div>
      </div>
    </div>
  )
}
