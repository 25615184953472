import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { CampaignStatus } from '../../Traders-Room/Campaign-Results/campaign.utils'
import { Button } from '../../global/button/Button'
import { ClientIbContestCampaignDto } from '../../model/ClientIbContestCampaignDto'
import { TextSmall } from '../../ui/Typography/Typography'
import { AuthSessionContext } from '../../utils/AuthContext'
import { formatDate } from '../../utils/date.utils'

import styles from './IBContestPage.module.scss'

interface IBContestCardsProps {
  data: ClientIbContestCampaignDto[]
  setSelectedCampaign(campaignResult: ClientIbContestCampaignDto): void
}

export const IBContestCards: React.FC<IBContestCardsProps> = (props) => {
  const { data, setSelectedCampaign } = props

  return (
    <>
      <div className='mt-5'>
        {data.map((x) => (
          <IBContestCard data={x} setSelectedCampaign={setSelectedCampaign} key={x.id} />
        ))}
      </div>
    </>
  )
}

interface IBContestCard {
  data: ClientIbContestCampaignDto
  setSelectedCampaign(campaignResult: ClientIbContestCampaignDto): void
}

const IBContestCard: React.FC<IBContestCard> = (props) => {
  const { data, setSelectedCampaign } = props

  const { t } = useTranslation()
  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name

  return (
    <div className={styles.result}>
      <div className='is-flex is-justify-content-space-between mb-2'>
        <p className={styles.textSmall}>
          <span className='text-strong'>{data.name}</span>
        </p>
        <p>
          <CampaignStatus {...data} />
        </p>
      </div>

      <div className='is-flex is-justify-content-space-between mb-2'>
        <p className={styles.textSmall}>
          <span className={classNames('text-secondary', styles.label)}>
            <>{t('Campaigns.Start Date')}</>
          </span>
        </p>
        <p>
          <span className={styles.textSmall}>{formatDate(data.from, dateFormat)}</span>
        </p>
      </div>

      <div className='is-flex is-justify-content-space-between mb-4'>
        <p className={styles.textSmall}>
          <span className={classNames('text-secondary', styles.label)}>
            <>{t('Campaigns.End Date')}</>
          </span>
        </p>
        <p>
          <span className={styles.textSmall}>{formatDate(data.to, dateFormat)}</span>
        </p>
      </div>

      <div className='is-flex is-justify-content-space-between'>
        <span className='is-flex is-justify-content-flex-end is-flex-grow-1'>
          <Button appearance='link' onClick={() => setSelectedCampaign(data)}>
            <TextSmall>{t('Campaigns.View results')}</TextSmall>
          </Button>
        </span>
      </div>
    </div>
  )
}
