import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getDocUploadFailMessage } from '../../../Profile/DocumentManagementUpload/documentManagementUploadStatuses'
import { Loading } from '../../../global/Loading/Loading'
import { useSessionLanguage } from '../../../global/context/SessionSettingsContext'
import { InformationModal } from '../../../global/modal/InformationModal'
import { Modal } from '../../../global/modal/Modal'
import { ClientNciPriorityDto } from '../../../model/ClientNciPriorityDto'
import { CreateClientDocument } from '../../../model/CreateClientDocuments'
import { ClientCreateNciFinalHistory } from '../../../model/CreateNciHistory'
import { DocumentCategoryType } from '../../../model/DocumentCategories'
import { useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import { useFetchOne } from '../../../utils/useFetch'
import { DocumentInfoNonNciPageStep1Form } from './DocumentInfoNonNciPageStep1Form'

import styles from './DocumentInfoNonNciPageStep1Page.module.scss'

interface OuterProps {
  accountNciAvailablePriorities: ClientNciPriorityDto

  onSkip(): void

  onSkipDocuments(): void

  handleSubmitReason(reason: string): void

  handleSubmit(): void
}

export const DocumentInfoNonNciPageStep1Page: React.FC<OuterProps> = (props) => {
  const apiClient = useApiClient(ClientApiClient)
  const locale = useSessionLanguage()

  const callback = useCallback(async () => {
    return apiClient.getDocumentCategories(DocumentCategoryType.Personal, locale)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const { data: documentCategoryTypes, isLoading } = useFetchOne(callback)

  const handleSubmit = async (
    values: ClientCreateNciFinalHistory,
    documents: CreateClientDocument[]
  ) => {
    try {
      await apiClient.uploadDocuments({ documents })
      props.handleSubmit()
    } catch (error: unknown) {
      setUploadErrorModal(true)
      setErrorMessage((error as any).response?.response?.data?.code)
    }
  }

  const [uploadErrorModal, setUploadErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const handleCloseErrorModal = () => setUploadErrorModal(false)

  const { t } = useTranslation()

  return (
    <Loading isLoading={isLoading} showLoadingIcon>
      {uploadErrorModal && (
        <Modal
          closeModal={handleCloseErrorModal}
          render={() => (
            <InformationModal
              onCancel={handleCloseErrorModal}
              buttonText={t('Got It')}
              title={t('Upload failed')}
            >
              <p className={styles.text}>{t(getDocUploadFailMessage(errorMessage))}</p>
            </InformationModal>
          )}
        />
      )}
      {documentCategoryTypes && (
        <DocumentInfoNonNciPageStep1Form
          {...props}
          documentCategoryTypes={documentCategoryTypes}
          handleSubmit={handleSubmit}
        />
      )}
    </Loading>
  )
}
