import { useMemo } from 'react'
import { i18n } from 'i18next'
import Cookies from 'js-cookie'

import { getDomainName } from '../../utils/cookie.utils'
import { useWindowResize, useWindowResizeTablet } from '../../utils/domUtils'
import { isOne } from '../../utils/validations'
import { overrideForZHToCNLanguage } from '../context/SessionSettingsContext'

export const localeFlagMap: { [x in Locale]: string } = {
  en: 'GB',
  es: 'ES',
  it: 'IT',
  pl: 'PL',
  th: 'TH',
  tr: 'TR',
  zh: 'CN',
  id: 'ID',
  ar: 'AE',
  de: 'DE',
  ko: 'KR',
  ms: 'MY',
  pt: 'PT',
  ru: 'RU',
  vi: 'VN',
}

export type Locale =
  | 'en'
  | 'zh'
  | 'it'
  | 'pl'
  | 'es'
  | 'th'
  | 'tr'
  | 'ar'
  | 'de'
  | 'id'
  | 'ko'
  | 'ms'
  | 'pt'
  | 'vi'
  | 'ru'

export type LocaleLabels = {
  label: string
  value: Locale
}

export type OverrideLocaleForZHLanguage =
  | 'en'
  | 'cn'
  | 'it'
  | 'pl'
  | 'es'
  | 'th'
  | 'tr'
  | 'ar'
  | 'de'
  | 'id'
  | 'ko'
  | 'ms'
  | 'pt'
  | 'vi'
  | 'ru'

export const AllLocales: ReadonlyArray<Locale> = [
  'en',
  'zh',
  'it',
  'pl',
  'es',
  'th',
  'tr',
  'ar',
  'de',
  'id',
  'ko',
  'ms',
  'pt',
  'vi',
  'ru',
]

export const LabeledLocales: ReadonlyArray<LocaleLabels> = [
  { value: 'en', label: 'English' },
  { value: 'zh', label: '中文' },
  { value: 'it', label: 'Italiano' },
  { value: 'pl', label: 'Polski' },
  { value: 'es', label: 'Español' },
  { value: 'th', label: 'ภาษาไทย' },
  { value: 'tr', label: 'Türkçe' },
  { value: 'ar', label: 'العربية' },
  { value: 'de', label: 'Deutsch' },
  { value: 'id', label: 'Bahasa Indonesia' },
  { value: 'ko', label: '한국어' },
  { value: 'ms', label: 'Bahasa Malaysia' },
  { value: 'pt', label: 'Português' },
  { value: 'vi', label: 'Tiếng Việt' },
  { value: 'ru', label: 'Русский' },
]

export const AllEULocales: ReadonlyArray<Partial<Locale>> = [
  'en',
  'zh',
  'it',
  'pl',
  'es',
  'th',
  'tr',
  'de',
  'id',
  'ko',
  'ms',
  'pt',
  'vi',
  'ru',
]

export function getLocales(): ReadonlyArray<Locale> {
  return AllLocales
}

const reorderForColumnLayout = (columns: number): LocaleLabels[] | ReadonlyArray<LocaleLabels> => {
  if (isOne(columns)) return LabeledLocales
  const rows = Math.ceil(LabeledLocales.length / columns)
  const reordered: LocaleLabels[] = new Array(LabeledLocales.length)

  for (let i = 0; i < LabeledLocales.length; i++) {
    const row = i % rows
    const col = Math.floor(i / rows)
    const newIndex = row * columns + col
    reordered[newIndex] = LabeledLocales[i]
  }

  return reordered.filter((item) => !!item)
}

export function useLabeledLocales(shorten?: boolean): ReadonlyArray<LocaleLabels> {
  const isMobile = useWindowResize()
  const isTablet = useWindowResizeTablet()

  if (isMobile) return reorderForColumnLayout(1)
  if (isTablet) {
    if (!shorten) return reorderForColumnLayout(3)
    return reorderForColumnLayout(3).map((item) => ({
      label: overrideForZHToCNLanguage(item.value).toUpperCase(),
      value: item.value,
    }))
  }
  return reorderForColumnLayout(4)
}

export const usePathLocale = (): string => {
  const locale = detectLocaleClientSide()
  const path = window.location.pathname

  const pathLocale = path.split('/')[1]

  const passedLocale = useMemo(() => {
    if (AllLocales.includes(pathLocale as Locale)) return `/${pathLocale}`
    else if (locale) {
      window.history.replaceState(
        '',
        '',
        `/${locale}${window.location.pathname}${window.location.search}`
      )
      return `/${locale}`
    } else {
      window.history.replaceState(
        '',
        '',
        `/${defaultLocale}${window.location.pathname}${window.location.search}`
      )
      return `/${defaultLocale}`
    }
  }, [locale, pathLocale])

  return passedLocale
}

export function getEULocales(): ReadonlyArray<Partial<Locale>> {
  return AllEULocales
}

export const defaultLocale: Locale = 'en'

function detectLocales(
  storedLocale: string | undefined,
  navigatorCandidates: ReadonlyArray<string>
): Locale {
  const navigatorLocales = navigatorCandidates.map((l) => l && l.split('-')[0])
  return (
    ([storedLocale, ...navigatorLocales].find((l) => AllLocales.includes(l as Locale)) as Locale) ||
    defaultLocale
  )
}

const localeCookieName = 'locale'

export function detectLocaleClientSide(): Locale {
  const storedLocale = Cookies.get(localeCookieName)
  return detectLocales(storedLocale, navigator.languages || [navigator.language])
}

export function storeLocaleClientSide(i18n: i18n, locale: string): void {
  Cookies.set(localeCookieName, locale, {
    expires: 999999,
    domain: getDomainName(),
  })
  i18n.changeLanguage(locale)
}
