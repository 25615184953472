import { ReactNode, useRef, useState } from 'react'
import classNames from 'classnames'

import { Locale, localeFlagMap, useLabeledLocales } from '../locale/Locale'

import styles from './LocaleSelector.module.scss'
import 'react-dropdown/style.css'
import ReactCountryFlag from 'react-country-flag'
import { useTranslation } from 'react-i18next'

import { useDisableMainScroll } from '../../hooks/useDisableMainScroll'
import { CheckIcon } from '../../icons/CheckIcon'
import { CloseIcon } from '../../icons/CloseIcon'
import { DummyIcon } from '../../icons/DummyIcon'
import { Popover } from '../../ui/Popover/Popover'
import { TextStrong } from '../../ui/Typography/Typography'
import { useWindowResize } from '../../utils/domUtils'
import { dispatchLangSelectEvent } from '../../utils/language.utils'
import { useLocallyPersistedState } from '../../utils/useStorage'
import { useArabicSessionLanguage, useSessionSettings } from '../context/SessionSettingsContext'
import IconButton from '../iconButton/IconButton'
import { languageSelectorKey } from './LocaleSelector'

const LocaleFlag: Record<Locale, ReactNode> = {} as Record<Locale, ReactNode>

for (const [locale, countryCode] of Object.entries(localeFlagMap)) {
  LocaleFlag[locale as Locale] = (
    <ReactCountryFlag className={styles.countryFlag} countryCode={countryCode} svg />
  )
}

export const LanguageVariants: React.FC = () => {
  const isArabic = useArabicSessionLanguage()
  const { enableMainScroll } = useDisableMainScroll()
  const [showVariants, setShowVariants] = useLocallyPersistedState<boolean>(
    languageSelectorKey,
    false
  )
  const [settings, setSettings] = useSessionSettings()
  const isMobile = useWindowResize()
  const labeledLocales = useLabeledLocales()
  const { t } = useTranslation()
  const myRef = useRef<HTMLDivElement>(null)
  const [showShadow, setShowShadow] = useState(false)

  const closePopover = () => {
    enableMainScroll()
    setShowVariants(false)
  }

  const selectLanguage = (locale: Locale) => {
    dispatchLangSelectEvent()

    setSettings({ locale })
    closePopover()
  }

  const checkScrollPosition = () => {
    if (myRef.current) {
      const scrollPosition = myRef.current.scrollTop
      if (scrollPosition > 1) {
        if (!showShadow) setShowShadow(true)
      } else {
        if (showShadow) setShowShadow(false)
      }
    }
  }

  return (
    <Popover isOpen={showVariants} onClick={closePopover}>
      {isMobile && (
        <div
          className={classNames(styles.closeBox, {
            [styles.showShadow]: !!showShadow,
          })}
        >
          <DummyIcon />
          <TextStrong>{t('Language')}</TextStrong>
          <IconButton onClick={closePopover} className={styles.closeIcon}>
            <CloseIcon size={16} color={'text'} />
          </IconButton>
        </div>
      )}
      <div
        ref={myRef}
        onScroll={checkScrollPosition}
        className={classNames(styles.variantsScroller, { [styles.isArabic]: isArabic })}
      >
        {labeledLocales.map((lang) => (
          <div
            key={lang.value}
            onClick={() => selectLanguage(lang.value)}
            className={classNames(styles.option, {
              [styles.isSelected]: lang.value === settings.locale,
            })}
          >
            <span className={styles.labelWrapper}>
              {LocaleFlag[lang.value]} <span>{lang.label}</span>
            </span>
            <div className={styles.checkIcon}>
              <CheckIcon size={14} color='textInteractive' />
            </div>
          </div>
        ))}
      </div>
    </Popover>
  )
}
