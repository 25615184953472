import React from 'react'
import { useFormikContext } from 'formik'

import { createFormCurrencyAmountField } from '../../../../global/formField/CurrencyAmountRateField'
import { WalletDepositFormValues, getCurrencyLimits } from './WalletDepositForm'

const FormCurrencyAmountField = createFormCurrencyAmountField<WalletDepositFormValues>()

export const CurrencyAmountField = () => {
  const { values } = useFormikContext<WalletDepositFormValues>()

  const fromCurrency = values.paymentProvider.currency.id
  const toCurrency = values.wallet.currency.id
  const currencyRestriction = getCurrencyLimits(values.wallet, values.paymentProvider)

  return (
    <FormCurrencyAmountField
      name='amount'
      fromCurrency={fromCurrency}
      toCurrency={toCurrency}
      max={currencyRestriction?.maxAmount}
      required
    />
  )
}
