import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import * as XLSX from 'xlsx'

import { Loading } from '../../global/Loading/Loading'
import { Paging } from '../../global/Paging/Paging'
import { Button } from '../../global/button/Button'
import { FilterQueryProps } from '../../global/filter/FilterQueryModal'
import { ExportModal } from '../../global/modal/ExportModal'
import { Modal } from '../../global/modal/Modal'
import { SortByModal } from '../../global/modal/SortByModal'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { NameDto } from '../../model/NameDto'
import { SubscriptionHistoryItem } from '../../model/SubscriptionsDto'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { SearchTermState } from '../../ui/Table/Header/PageHeaderParts'
import { NoResults } from '../../ui/Table/NoResults/NoResults'
import { PageQuery, useApiClient } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { ClientApiClient } from '../../utils/clientApi'
import { formatDate } from '../../utils/date.utils'
import { useWindowResize } from '../../utils/domUtils'
import { getAppliedFiltersLength, setQuery } from '../../utils/filter.utils'
import { normalizeSortLabel } from '../../utils/format.utils'
import { generatePDFTable } from '../../utils/prepare.pdf.utils'
import { useCallbackWithForceRefresh } from '../../utils/useCallbackWithForceRefresh'
import { IAppendableFetchResult, useFetchAppendablePage } from '../../utils/useFetch'
import { SubscriptionsHistoryCard } from './SubscriptionsHistoryCard'
import { SubscriptionsHistoryFilterModal } from './SubscriptionsHistoryFilterModal'
import { SubscriptionsHistoryTable } from './SubscriptionsHistoryTable'

import styles from './SubscriptionsHistoryTable.module.scss'

const initialQuery = {}

interface Props {
  data?: SubscriptionHistoryItem[]
}

export interface ISubscriptionsHistoryQuery extends FilterQueryProps {
  DateFrom?: string
  DateTo?: string
}

const useSubscriptionsHistoryFetch = (props: SearchResponseProps) => {
  const { search, searchTerm } = props

  const apiClient = useApiClient(ClientApiClient)
  const initialRender = useRef(true)

  const { callback: subscriptionCallback } = useCallbackWithForceRefresh(
    async (query?: PageQuery) => {
      return await apiClient.getSubscriptionsHistory({
        ...query,
        caller: 'ca',
        search,
      })
    },
    [search]
  )

  const result = useFetchAppendablePage(subscriptionCallback)

  useEffect(
    () => {
      if (initialRender.current) {
        initialRender.current = false
      } else {
        const timer = setTimeout(
          () =>
            searchTerm?.searchTerm !== undefined &&
            result.setPageQuery!((oldPageQuery: PageQuery) => ({
              ...oldPageQuery,
              pageIndex: 1,
              ca_search_TextSearch: searchTerm.searchTerm,
            })),
          500
        )
        return () => clearTimeout(timer)
      }
    },
    [searchTerm?.searchTerm] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    ...result,
    subscriptions: result.data,
  }
}

interface GenerateDocumentFileProps {
  query: IAppendableFetchResult<SubscriptionHistoryItem[]>
}

const useGenerateDocumentFile = (props: GenerateDocumentFileProps) => {
  const { query } = props
  const { formatMoney } = useFormatNumber()

  const exportFilename = 'subscriptions_history_report'

  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name

  const [isOptionsModalOpen, setOptionsModalOpen] = useState<boolean>(false)

  const handleGenerateExcel = () => {
    const table = tableBody()
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.aoa_to_sheet(table)

    XLSX.utils.book_append_sheet(wb, ws, exportFilename)
    XLSX.writeFile(wb, `${exportFilename}.xlsx`)
  }

  const handleGeneratePDF = () => {
    const title = 'Subscriptions History Report'
    const data = tableBody()
    generatePDFTable({
      data,
      title,
      fileName: exportFilename,
    })
    setOptionsModalOpen(false)
  }

  const tableBody = () => {
    const headerCsvData = [['Date Submitted', 'Product', 'Request Type', 'Price', 'Status']]

    return query.data.reduce((previousValue, currentValue) => {
      return previousValue.concat([
        [
          formatDate(currentValue.createdDate, dateFormat),
          currentValue.subscription.name,
          currentValue.type.name,
          formatMoney(currentValue.amount),
          currentValue.state.name,
        ],
      ])
    }, headerCsvData)
  }

  return {
    handleGenerateExcel,
    handleGeneratePDF,
    tableBody,
    isOptionsModalOpen,
    setOptionsModalOpen,
    exportFilename,
  }
}

const useSort = () => {
  const { t } = useTranslation()

  const sortOptions = [
    { id: 'CreatedDate', name: t('Date') },
    {
      id: 'ClientSubscriptionDetail.SubscriptionDetail.Subscription.Name',
      name: t('Subscriptions.Product'),
    },
    {
      id: 'ClientSubscriptionDetail.SubscriptionDetail.Amount',
      name: t('Subscriptions.Price'),
    },
    {
      id: 'state.Order',
      name: t('Subscriptions.Status'),
    },
  ]

  return {
    sortOptions,
  }
}

interface SearchResponseProps {
  filtersLength: number
  clearSearch(): void
  search: FilterQueryProps
  setSearch(value: FilterQueryProps): void
  searchTerm: SearchTermState
  showSearchTerm(value: SearchTermState): void
}

const useSearch = (): SearchResponseProps => {
  const [search, setSearch] = useState<ISubscriptionsHistoryQuery>(initialQuery)
  const [searchTerm, showSearchTerm] = useState<SearchTermState>({
    show: false,
    searchTerm: undefined,
  })

  const handleClearSearch = () => {
    showSearchTerm({ searchTerm: '', show: false })
    setSearch(initialQuery)
  }

  return {
    clearSearch: handleClearSearch,
    filtersLength: getAppliedFiltersLength({ ...search }),
    search,
    setSearch,
    searchTerm,
    showSearchTerm,
  }
}

export const SubscriptionsHistoryPageETD: React.FC<Props> = () => {
  const [filterModal, setFilterModalOpen] = useState<boolean>(false)
  const [sortModalOpen, setSortModalOpen] = useState<boolean>(false)
  const [optionsModal, setOptionsModal] = useState<boolean>(false)

  const sort = useSort()
  const search = useSearch()

  const query = useSubscriptionsHistoryFetch(search)
  const generateDocumentFile = useGenerateDocumentFile({ query })
  const { pageQuery, setPageQuery, meta, isLoading } = query

  return (
    <React.Fragment>
      {filterModal && (
        <Modal
          closeModal={() => setFilterModalOpen(false)}
          render={({ closeModal }) => (
            <SubscriptionsHistoryFilterModal
              currentQuery={search.search}
              onConfirm={({ searchFilters, currentFilter }) => {
                let q: ISubscriptionsHistoryQuery = {
                  ...setQuery(currentFilter),
                  ...searchFilters,
                }
                if (!currentFilter) {
                  q = Object.keys(q)
                    .filter(
                      (key) => key !== 'DateCreated' && key !== 'DateFrom' && key !== 'DateTo'
                    )
                    .reduce((acc, key) => {
                      return Object.assign(acc, {
                        [key]: q[key],
                      })
                    }, {})
                }
                search.setSearch(q)
                closeModal()
              }}
            />
          )}
        />
      )}
      {optionsModal && (
        <Modal
          render={({ closeModal }) => (
            <ExportModal
              fileName={generateDocumentFile.exportFilename}
              onCloseModal={closeModal}
              csvData={generateDocumentFile.tableBody()}
              onExportToCSV={closeModal}
              onExportToPdf={generateDocumentFile.handleGeneratePDF}
              onExportToExcel={generateDocumentFile.handleGenerateExcel}
            />
          )}
          closeModal={() => setOptionsModal(false)}
        />
      )}
      {sortModalOpen && (
        <Modal
          closeModal={() => setSortModalOpen(false)}
          render={({ closeModal }) => (
            <SortByModal
              onCancel={closeModal}
              options={sort.sortOptions}
              onConfirm={(option, sortBy) => {
                setPageQuery?.({
                  ...pageQuery,
                  sort: option,
                  sortOrder: sortBy,
                })
                closeModal()
              }}
            />
          )}
        />
      )}
      <Header
        query={query}
        search={search}
        setOptionsModalOpen={setOptionsModal}
        setFilterModalOpen={setFilterModalOpen}
        sortOptions={sort.sortOptions}
      />
      <Loading isLoading={isLoading} showLoadingIcon>
        <SubscriptionsHistoryRecords query={query} search={search} />
      </Loading>
      {meta && meta.itemsCount > 0 && (
        <Paging
          pageData={meta}
          isLoading={isLoading}
          onPageChanged={(pageIndex, pageSize) => {
            return setPageQuery!({
              ...pageQuery,
              pageIndex,
              pageSize,
            })
          }}
        />
      )}
    </React.Fragment>
  )
}

interface SubscriptionsHistoryRecordsProps {
  query: IAppendableFetchResult<SubscriptionHistoryItem[]>
  search: SearchResponseProps
}

const SubscriptionsHistoryRecords: React.FC<SubscriptionsHistoryRecordsProps> = (props) => {
  const { query, search } = props

  const { t } = useTranslation()
  const isMobile = useWindowResize()

  if (hasRecords(query)) {
    if (isMobile) {
      return <SubscriptionsHistoryCard data={query.data} />
    } else {
      return (
        <SubscriptionsHistoryTable
          data={query.data}
          pageQuery={query.pageQuery}
          setPageQuery={query.setPageQuery}
        />
      )
    }
  }

  if (isSearch(search.searchTerm)) {
    return <NoResults hideLink subtitle={t('No results found')} />
  }

  if (search.filtersLength >= 1) {
    return <NoResults hideLink subtitle={t('No results found')} />
  }

  return (
    <NoResults
      subtitle={t('Subscriptions.You have no Subscriptions yet')}
      hideLink
      children={
        <Link to={'/dashboard/subscriptions/trading-platforms'}>
          <Button className={styles.button} type='button' appearance='primary' size='M'>
            + {t('Subscriptions.Add Subscription')}
          </Button>
        </Link>
      }
    />
  )
}

interface HeaderProps {
  query: IAppendableFetchResult<SubscriptionHistoryItem[]>
  search: SearchResponseProps
  sortOptions: NameDto<string>[]
  setOptionsModalOpen(value: boolean): void
  setFilterModalOpen(value: boolean): void
}

const Header: React.FC<HeaderProps> = (props) => {
  const {
    search: searchResponse,
    query,
    setOptionsModalOpen,
    setFilterModalOpen,
    sortOptions,
  } = props
  const { searchTerm, showSearchTerm, clearSearch, filtersLength } = searchResponse

  const { t } = useTranslation()

  if (query.hasInitialResults) {
    return (
      <PageHeader
        title={t('Subscriptions.Subscription History')}
        search={{
          show: searchTerm.show,
          searchTerm: searchTerm.searchTerm,
          placeholder: t('Search Product Name'),
          setShow: (v) => showSearchTerm({ ...searchTerm, show: v }),
          setSearchTerm: (v) => {
            showSearchTerm({ ...searchTerm, searchTerm: v })
          },
        }}
        optionsToggle={() => setOptionsModalOpen(true)}
        filterToggles={{
          openFilterModal: () => setFilterModalOpen(true),
          resetFilters: clearSearch,
          activeFilters: filtersLength,
          openSortModal: () => setFilterModalOpen(true),
          sortLabel: normalizeSortLabel(t, sortOptions, query?.pageQuery?.sort),
        }}
      />
    )
  }

  return <PageHeader title={t('Subscriptions.Subscription History')} />
}

const hasRecords = (query: IAppendableFetchResult<SubscriptionHistoryItem[]>): boolean => {
  return !!query?.data?.length && !query?.isLoading
}

const isSearch = (searchTerm: SearchTermState) => {
  const isSearchActive = searchTerm?.show
  const isSearching =
    searchTerm?.searchTerm !== undefined && searchTerm?.searchTerm.trim().length > 0
  return isSearching || isSearchActive
}
