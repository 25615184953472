import React from 'react'

import {
  LegalDocumentDto,
  isLegalDocumentWidgetCheckbox,
  isLegalDocumentWidgetReadAndAgree,
} from '../../../model/CompanyLegalDocumentDto'
import { DocumentFieldItem as DocumentCheckBoxFieldItem } from '../PersonalInfoPage/DocumentCheckBoxField'
import { DocumentFieldItem as DocumentReadAndAgreeFieldItem } from '../PersonalInfoPage/DocumentReadAndAgreeField'
import {
  PersonalInfoDocumentsFactory,
  filterDocumentByGroupReference,
  isDocumentCheckboxReferences,
  isDocumentReadAndAgreeReferences,
} from '../PersonalInfoPage/PersonalInfoDocumentsFactory'

interface DocumentGroupsFactoryProps {
  groupReferences: GroupReference[]
  documents: LegalDocumentDto[]
  renderItem?(props: DocumentReadAndAgreeFieldItem | DocumentCheckBoxFieldItem): React.ReactNode
}

type GroupReference = string | null

export const DocumentGroupsFactory: React.FC<DocumentGroupsFactoryProps> = (props) => {
  const { documents, groupReferences, renderItem } = props

  const buildGroupReference = (
    filter: (documents: LegalDocumentDto[] | LegalDocumentDto | undefined | null) => boolean
  ) => (
    <div className='is-flex is-flex-direction-column'>
      {groupReferences.map((groupReference) => (
        <PersonalInfoDocumentsFactory
          groupReference={groupReference}
          documents={documents
            .filter(filter)
            .filter(filterDocumentByGroupReference(groupReference))}
          renderItem={renderItem}
          key={groupReference}
        />
      ))}
    </div>
  )

  return (
    <div>
      {isDocumentReadAndAgreeReferences(groupReferences, documents) &&
        buildGroupReference(isLegalDocumentWidgetReadAndAgree)}
      {isDocumentCheckboxReferences(groupReferences, documents) &&
        buildGroupReference(isLegalDocumentWidgetCheckbox)}
    </div>
  )
}
