export const countries = [
  {
    id: 'AFG',
    name: 'Afghanistan',
    shortName: 'AF',
    isEuropeanUnion: false,
  },
  {
    id: 'ALB',
    name: 'Albania',
    shortName: 'AL',
    isEuropeanUnion: false,
  },
  {
    id: 'DZA',
    name: 'Algeria',
    shortName: 'DZ',
    isEuropeanUnion: false,
  },
  {
    id: 'ASM',
    name: 'American Samoa',
    shortName: 'AS',
    isEuropeanUnion: false,
  },
  {
    id: 'AND',
    name: 'Andorra',
    shortName: 'AD',
    isEuropeanUnion: false,
  },
  {
    id: 'AGO',
    name: 'Angola',
    shortName: 'AO',
    isEuropeanUnion: false,
  },
  {
    id: 'AIA',
    name: 'Anguilla',
    shortName: 'AI',
    isEuropeanUnion: false,
  },
  {
    id: 'ATA',
    name: 'Antarctica',
    shortName: 'AQ',
    isEuropeanUnion: false,
  },
  {
    id: 'ATG',
    name: 'Antigua and Barbuda',
    shortName: 'AG',
    isEuropeanUnion: false,
  },
  {
    id: 'ARG',
    name: 'Argentina',
    shortName: 'AR',
    isEuropeanUnion: false,
  },
  {
    id: 'ARM',
    name: 'Armenia',
    shortName: 'AM',
    isEuropeanUnion: false,
  },
  {
    id: 'ABW',
    name: 'Aruba',
    shortName: 'AW',
    isEuropeanUnion: false,
  },
  {
    id: 'AUS',
    name: 'Australia',
    shortName: 'AU',
    isEuropeanUnion: false,
  },
  {
    id: 'AUT',
    name: 'Austria',
    shortName: 'AT',
    isEuropeanUnion: true,
  },
  {
    id: 'AZE',
    name: 'Azerbaijan',
    shortName: 'AZ',
    isEuropeanUnion: false,
  },
  {
    id: 'BHS',
    name: 'Bahamas',
    shortName: 'BS',
    isEuropeanUnion: false,
  },
  {
    id: 'BHR',
    name: 'Bahrain',
    shortName: 'BH',
    isEuropeanUnion: false,
  },
  {
    id: 'BGD',
    name: 'Bangladesh',
    shortName: 'BD',
    isEuropeanUnion: false,
  },
  {
    id: 'BRB',
    name: 'Barbados',
    shortName: 'BB',
    isEuropeanUnion: false,
  },
  {
    id: 'BLR',
    name: 'Belarus',
    shortName: 'BY',
    isEuropeanUnion: false,
  },
  {
    id: 'BEL',
    name: 'Belgium',
    shortName: 'BE',
    isEuropeanUnion: true,
  },
  {
    id: 'BLZ',
    name: 'Belize',
    shortName: 'BZ',
    isEuropeanUnion: false,
  },
  {
    id: 'BEN',
    name: 'Benin',
    shortName: 'BJ',
    isEuropeanUnion: false,
  },
  {
    id: 'BMU',
    name: 'Bermuda',
    shortName: 'BM',
    isEuropeanUnion: false,
  },
  {
    id: 'BTN',
    name: 'Bhutan',
    shortName: 'BT',
    isEuropeanUnion: false,
  },
  {
    id: 'BOL',
    name: 'Bolivia',
    shortName: 'BO',
    isEuropeanUnion: false,
  },
  {
    id: 'BIH',
    name: 'Bosnia and Herzegovina',
    shortName: 'BA',
    isEuropeanUnion: false,
  },
  {
    id: 'BWA',
    name: 'Botswana',
    shortName: 'BW',
    isEuropeanUnion: false,
  },
  {
    id: 'BRA',
    name: 'Brazil',
    shortName: 'BR',
    isEuropeanUnion: false,
  },
  {
    id: 'IOT',
    name: 'British Indian Ocean Territory',
    shortName: 'IO',
    isEuropeanUnion: false,
  },
  {
    id: 'VGB',
    name: 'British Virgin Islands',
    shortName: 'VG',
    isEuropeanUnion: false,
  },
  {
    id: 'BRN',
    name: 'Brunei',
    shortName: 'BN',
    isEuropeanUnion: false,
  },
  {
    id: 'BGR',
    name: 'Bulgaria',
    shortName: 'BG',
    isEuropeanUnion: true,
  },
  {
    id: 'BFA',
    name: 'Burkina Faso',
    shortName: 'BF',
    isEuropeanUnion: false,
  },
  {
    id: 'MMR',
    name: 'Burma (Myanmar)',
    shortName: 'MM',
    isEuropeanUnion: false,
  },
  {
    id: 'BDI',
    name: 'Burundi',
    shortName: 'BI',
    isEuropeanUnion: false,
  },
  {
    id: 'KHM',
    name: 'Cambodia',
    shortName: 'KH',
    isEuropeanUnion: false,
  },
  {
    id: 'CMR',
    name: 'Cameroon',
    shortName: 'CM',
    isEuropeanUnion: false,
  },
  {
    id: 'CAN',
    name: 'Canada',
    shortName: 'CA',
    isEuropeanUnion: false,
  },
  {
    id: 'CPV',
    name: 'Cape Verde',
    shortName: 'CV',
    isEuropeanUnion: false,
  },
  {
    id: 'CYM',
    name: 'Cayman Islands',
    shortName: 'KY',
    isEuropeanUnion: false,
  },
  {
    id: 'CAF',
    name: 'Central African Republic',
    shortName: 'CF',
    isEuropeanUnion: false,
  },
  {
    id: 'TCD',
    name: 'Chad',
    shortName: 'TD',
    isEuropeanUnion: false,
  },
  {
    id: 'CHL',
    name: 'Chile',
    shortName: 'CL',
    isEuropeanUnion: false,
  },
  {
    id: 'CHN',
    name: 'China',
    shortName: 'CN',
    isEuropeanUnion: false,
  },
  {
    id: 'CXR',
    name: 'Christmas Island',
    shortName: 'CX',
    isEuropeanUnion: false,
  },
  {
    id: 'CCK',
    name: 'Cocos (Keeling) Islands',
    shortName: 'CC',
    isEuropeanUnion: false,
  },
  {
    id: 'COL',
    name: 'Colombia',
    shortName: 'CO',
    isEuropeanUnion: false,
  },
  {
    id: 'COM',
    name: 'Comoros',
    shortName: 'KM',
    isEuropeanUnion: false,
  },
  {
    id: 'COK',
    name: 'Cook Islands',
    shortName: 'CK',
    isEuropeanUnion: false,
  },
  {
    id: 'CRI',
    name: 'Costa Rica',
    shortName: 'CR',
    isEuropeanUnion: false,
  },
  {
    id: 'HRV',
    name: 'Croatia',
    shortName: 'HR',
    isEuropeanUnion: true,
  },
  {
    id: 'CUB',
    name: 'Cuba',
    shortName: 'CU',
    isEuropeanUnion: false,
  },
  {
    id: 'CYP',
    name: 'Cyprus',
    shortName: 'CY',
    isEuropeanUnion: true,
  },
  {
    id: 'CZE',
    name: 'Czech Republic',
    shortName: 'CZ',
    isEuropeanUnion: true,
  },
  {
    id: 'COD',
    name: 'Democratic Republic of the Congo',
    shortName: 'CD',
    isEuropeanUnion: false,
  },
  {
    id: 'DNK',
    name: 'Denmark',
    shortName: 'DK',
    isEuropeanUnion: true,
  },
  {
    id: 'DJI',
    name: 'Djibouti',
    shortName: 'DJ',
    isEuropeanUnion: false,
  },
  {
    id: 'DMA',
    name: 'Dominica',
    shortName: 'DM',
    isEuropeanUnion: false,
  },
  {
    id: 'DOM',
    name: 'Dominican Republic',
    shortName: 'DO',
    isEuropeanUnion: false,
  },
  {
    id: 'ECU',
    name: 'Ecuador',
    shortName: 'EC',
    isEuropeanUnion: false,
  },
  {
    id: 'EGY',
    name: 'Egypt',
    shortName: 'EG',
    isEuropeanUnion: false,
  },
  {
    id: 'SLV',
    name: 'El Salvador',
    shortName: 'SV',
    isEuropeanUnion: false,
  },
  {
    id: 'GNQ',
    name: 'Equatorial Guinea',
    shortName: 'GQ',
    isEuropeanUnion: false,
  },
  {
    id: 'ERI',
    name: 'Eritrea',
    shortName: 'ER',
    isEuropeanUnion: false,
  },
  {
    id: 'EST',
    name: 'Estonia',
    shortName: 'EE',
    isEuropeanUnion: true,
  },
  {
    id: 'ETH',
    name: 'Ethiopia',
    shortName: 'ET',
    isEuropeanUnion: false,
  },
  {
    id: 'FLK',
    name: 'Falkland Islands',
    shortName: 'FK',
    isEuropeanUnion: false,
  },
  {
    id: 'FRO',
    name: 'Faroe Islands',
    shortName: 'FO',
    isEuropeanUnion: false,
  },
  {
    id: 'FJI',
    name: 'Fiji',
    shortName: 'FJ',
    isEuropeanUnion: false,
  },
  {
    id: 'FIN',
    name: 'Finland',
    shortName: 'FI',
    isEuropeanUnion: true,
  },
  {
    id: 'FRA',
    name: 'France',
    shortName: 'FR',
    isEuropeanUnion: true,
  },
  {
    id: 'PYF',
    name: 'French Polynesia',
    shortName: 'PF',
    isEuropeanUnion: false,
  },
  {
    id: 'GAB',
    name: 'Gabon',
    shortName: 'GA',
    isEuropeanUnion: false,
  },
  {
    id: 'GMB',
    name: 'Gambia',
    shortName: 'GM',
    isEuropeanUnion: false,
  },
  {
    id: 'GAZ',
    name: 'Gaza Strip',
    shortName: 'GS',
    isEuropeanUnion: false,
  },
  {
    id: 'GEO',
    name: 'Georgia',
    shortName: 'GE',
    isEuropeanUnion: false,
  },
  {
    id: 'DEU',
    name: 'Germany',
    shortName: 'DE',
    isEuropeanUnion: true,
  },
  {
    id: 'GHA',
    name: 'Ghana',
    shortName: 'GH',
    isEuropeanUnion: false,
  },
  {
    id: 'GIB',
    name: 'Gibraltar',
    shortName: 'GI',
    isEuropeanUnion: false,
  },
  {
    id: 'GRC',
    name: 'Greece',
    shortName: 'GR',
    isEuropeanUnion: true,
  },
  {
    id: 'GRL',
    name: 'Greenland',
    shortName: 'GL',
    isEuropeanUnion: false,
  },
  {
    id: 'GRD',
    name: 'Grenada',
    shortName: 'GD',
    isEuropeanUnion: false,
  },
  {
    id: 'GUM',
    name: 'Guam',
    shortName: 'GU',
    isEuropeanUnion: false,
  },
  {
    id: 'GTM',
    name: 'Guatemala',
    shortName: 'GT',
    isEuropeanUnion: false,
  },
  {
    id: 'GGY',
    name: 'Guernsey',
    shortName: 'GG',
    isEuropeanUnion: false,
  },
  {
    id: 'GIN',
    name: 'Guinea',
    shortName: 'GN',
    isEuropeanUnion: false,
  },
  {
    id: 'GNB',
    name: 'Guinea-Bissau',
    shortName: 'GW',
    isEuropeanUnion: false,
  },
  {
    id: 'GUY',
    name: 'Guyana',
    shortName: 'GY',
    isEuropeanUnion: false,
  },
  {
    id: 'HTI',
    name: 'Haiti',
    shortName: 'HT',
    isEuropeanUnion: false,
  },
  {
    id: 'VAT',
    name: 'Holy See (Vatican City)',
    shortName: 'VA',
    isEuropeanUnion: false,
  },
  {
    id: 'HND',
    name: 'Honduras',
    shortName: 'HN',
    isEuropeanUnion: false,
  },
  {
    id: 'HKG',
    name: 'Hong Kong',
    shortName: 'HK',
    isEuropeanUnion: false,
  },
  {
    id: 'HUN',
    name: 'Hungary',
    shortName: 'HU',
    isEuropeanUnion: true,
  },
  {
    id: 'ISL',
    name: 'Iceland',
    shortName: 'IS',
    isEuropeanUnion: true,
  },
  {
    id: 'IND',
    name: 'India',
    shortName: 'IN',
    isEuropeanUnion: false,
  },
  {
    id: 'IDN',
    name: 'Indonesia',
    shortName: 'ID',
    isEuropeanUnion: false,
  },
  {
    id: 'IRN',
    name: 'Iran',
    shortName: 'IR',
    isEuropeanUnion: false,
  },
  {
    id: 'IRQ',
    name: 'Iraq',
    shortName: 'IQ',
    isEuropeanUnion: false,
  },
  {
    id: 'IRL',
    name: 'Ireland',
    shortName: 'IE',
    isEuropeanUnion: true,
  },
  {
    id: 'IMN',
    name: 'Isle of Man',
    shortName: 'IM',
    isEuropeanUnion: false,
  },
  {
    id: 'ISR',
    name: 'Israel',
    shortName: 'IL',
    isEuropeanUnion: false,
  },
  {
    id: 'ITA',
    name: 'Italy',
    shortName: 'IT',
    isEuropeanUnion: true,
  },
  {
    id: 'CIV',
    name: 'Ivory Coast',
    shortName: 'CI',
    isEuropeanUnion: false,
  },
  {
    id: 'JAM',
    name: 'Jamaica',
    shortName: 'JM',
    isEuropeanUnion: false,
  },
  {
    id: 'JPN',
    name: 'Japan',
    shortName: 'JP',
    isEuropeanUnion: false,
  },
  {
    id: 'JEY',
    name: 'Jersey',
    shortName: 'JE',
    isEuropeanUnion: false,
  },
  {
    id: 'JOR',
    name: 'Jordan',
    shortName: 'JO',
    isEuropeanUnion: false,
  },
  {
    id: 'KAZ',
    name: 'Kazakhstan',
    shortName: 'KZ',
    isEuropeanUnion: false,
  },
  {
    id: 'KEN',
    name: 'Kenya',
    shortName: 'KE',
    isEuropeanUnion: false,
  },
  {
    id: 'KIR',
    name: 'Kiribati',
    shortName: 'KI',
    isEuropeanUnion: false,
  },
  {
    id: 'XKX',
    name: 'Kosovo',
    shortName: 'XK',
    isEuropeanUnion: false,
  },
  {
    id: 'KWT',
    name: 'Kuwait',
    shortName: 'KW',
    isEuropeanUnion: false,
  },
  {
    id: 'KGZ',
    name: 'Kyrgyzstan',
    shortName: 'KG',
    isEuropeanUnion: false,
  },
  {
    id: 'LFT',
    name: 'Labuan FT',
    shortName: 'LN',
    isEuropeanUnion: false,
  },
  {
    id: 'LAO',
    name: 'Laos',
    shortName: 'LA',
    isEuropeanUnion: false,
  },
  {
    id: 'LVA',
    name: 'Latvia',
    shortName: 'LV',
    isEuropeanUnion: true,
  },
  {
    id: 'LBN',
    name: 'Lebanon',
    shortName: 'LB',
    isEuropeanUnion: false,
  },
  {
    id: 'LSO',
    name: 'Lesotho',
    shortName: 'LS',
    isEuropeanUnion: false,
  },
  {
    id: 'LBR',
    name: 'Liberia',
    shortName: 'LR',
    isEuropeanUnion: false,
  },
  {
    id: 'LBY',
    name: 'Libya',
    shortName: 'LY',
    isEuropeanUnion: false,
  },
  {
    id: 'LIE',
    name: 'Liechtenstein',
    shortName: 'LI',
    isEuropeanUnion: true,
  },
  {
    id: 'LTU',
    name: 'Lithuania',
    shortName: 'LT',
    isEuropeanUnion: true,
  },
  {
    id: 'LUX',
    name: 'Luxembourg',
    shortName: 'LU',
    isEuropeanUnion: true,
  },
  {
    id: 'MAC',
    name: 'Macau',
    shortName: 'MO',
    isEuropeanUnion: false,
  },
  {
    id: 'MKD',
    name: 'Macedonia',
    shortName: 'MK',
    isEuropeanUnion: false,
  },
  {
    id: 'MDG',
    name: 'Madagascar',
    shortName: 'MG',
    isEuropeanUnion: false,
  },
  {
    id: 'MWI',
    name: 'Malawi',
    shortName: 'MW',
    isEuropeanUnion: false,
  },
  {
    id: 'MYS',
    name: 'Malaysia',
    shortName: 'MY',
    isEuropeanUnion: false,
  },
  {
    id: 'MDV',
    name: 'Maldives',
    shortName: 'MV',
    isEuropeanUnion: false,
  },
  {
    id: 'MLI',
    name: 'Mali',
    shortName: 'ML',
    isEuropeanUnion: false,
  },
  {
    id: 'MLT',
    name: 'Malta',
    shortName: 'MT',
    isEuropeanUnion: true,
  },
  {
    id: 'MHL',
    name: 'Marshall Islands',
    shortName: 'MH',
    isEuropeanUnion: false,
  },
  {
    id: 'MRT',
    name: 'Mauritania',
    shortName: 'MR',
    isEuropeanUnion: false,
  },
  {
    id: 'MUS',
    name: 'Mauritius',
    shortName: 'MU',
    isEuropeanUnion: false,
  },
  {
    id: 'MYT',
    name: 'Mayotte',
    shortName: 'YT',
    isEuropeanUnion: false,
  },
  {
    id: 'MEX',
    name: 'Mexico',
    shortName: 'MX',
    isEuropeanUnion: false,
  },
  {
    id: 'FSM',
    name: 'Micronesia',
    shortName: 'FM',
    isEuropeanUnion: false,
  },
  {
    id: 'MDA',
    name: 'Moldova',
    shortName: 'MD',
    isEuropeanUnion: false,
  },
  {
    id: 'MCO',
    name: 'Monaco',
    shortName: 'MC',
    isEuropeanUnion: false,
  },
  {
    id: 'MNG',
    name: 'Mongolia',
    shortName: 'MN',
    isEuropeanUnion: false,
  },
  {
    id: 'MNE',
    name: 'Montenegro',
    shortName: 'ME',
    isEuropeanUnion: false,
  },
  {
    id: 'MSR',
    name: 'Montserrat',
    shortName: 'MS',
    isEuropeanUnion: false,
  },
  {
    id: 'MAR',
    name: 'Morocco',
    shortName: 'MA',
    isEuropeanUnion: false,
  },
  {
    id: 'MOZ',
    name: 'Mozambique',
    shortName: 'MZ',
    isEuropeanUnion: false,
  },
  {
    id: 'NAM',
    name: 'Namibia',
    shortName: 'NA',
    isEuropeanUnion: false,
  },
  {
    id: 'NRU',
    name: 'Nauru',
    shortName: 'NR',
    isEuropeanUnion: false,
  },
  {
    id: 'NPL',
    name: 'Nepal',
    shortName: 'NP',
    isEuropeanUnion: false,
  },
  {
    id: 'NLD',
    name: 'Netherlands',
    shortName: 'NL',
    isEuropeanUnion: true,
  },
  {
    id: 'ANT',
    name: 'Netherlands Antilles',
    shortName: 'AN',
    isEuropeanUnion: false,
  },
  {
    id: 'NCL',
    name: 'New Caledonia',
    shortName: 'NC',
    isEuropeanUnion: false,
  },
  {
    id: 'NZL',
    name: 'New Zealand',
    shortName: 'NZ',
    isEuropeanUnion: false,
  },
  {
    id: 'NIC',
    name: 'Nicaragua',
    shortName: 'NI',
    isEuropeanUnion: false,
  },
  {
    id: 'NER',
    name: 'Niger',
    shortName: 'NE',
    isEuropeanUnion: false,
  },
  {
    id: 'NGA',
    name: 'Nigeria',
    shortName: 'NG',
    isEuropeanUnion: false,
  },
  {
    id: 'NIU',
    name: 'Niue',
    shortName: 'NU',
    isEuropeanUnion: false,
  },
  {
    id: 'NFK',
    name: 'Norfolk Island',
    shortName: 'NF',
    isEuropeanUnion: false,
  },
  {
    id: 'PRK',
    name: 'North Korea',
    shortName: 'KP',
    isEuropeanUnion: false,
  },
  {
    id: 'MNP',
    name: 'Northern Mariana Islands',
    shortName: 'MP',
    isEuropeanUnion: false,
  },
  {
    id: 'NOR',
    name: 'Norway',
    shortName: 'NO',
    isEuropeanUnion: true,
  },
  {
    id: 'OMN',
    name: 'Oman',
    shortName: 'OM',
    isEuropeanUnion: false,
  },
  {
    id: 'PAK',
    name: 'Pakistan',
    shortName: 'PK',
    isEuropeanUnion: false,
  },
  {
    id: 'PLW',
    name: 'Palau',
    shortName: 'PW',
    isEuropeanUnion: false,
  },
  {
    id: 'PAN',
    name: 'Panama',
    shortName: 'PA',
    isEuropeanUnion: false,
  },
  {
    id: 'PNG',
    name: 'Papua New Guinea',
    shortName: 'PG',
    isEuropeanUnion: false,
  },
  {
    id: 'PRY',
    name: 'Paraguay',
    shortName: 'PY',
    isEuropeanUnion: false,
  },
  {
    id: 'PER',
    name: 'Peru',
    shortName: 'PE',
    isEuropeanUnion: false,
  },
  {
    id: 'PHL',
    name: 'Philippines',
    shortName: 'PH',
    isEuropeanUnion: false,
  },
  {
    id: 'PCN',
    name: 'Pitcairn Islands',
    shortName: 'PN',
    isEuropeanUnion: false,
  },
  {
    id: 'POL',
    name: 'Poland',
    shortName: 'PL',
    isEuropeanUnion: true,
  },
  {
    id: 'PRT',
    name: 'Portugal',
    shortName: 'PT',
    isEuropeanUnion: true,
  },
  {
    id: 'PRI',
    name: 'Puerto Rico',
    shortName: 'PR',
    isEuropeanUnion: false,
  },
  {
    id: 'QAT',
    name: 'Qatar',
    shortName: 'QA',
    isEuropeanUnion: false,
  },
  {
    id: 'COG',
    name: 'Republic of the Congo',
    shortName: 'CG',
    isEuropeanUnion: false,
  },
  {
    id: 'ROU',
    name: 'Romania',
    shortName: 'RO',
    isEuropeanUnion: true,
  },
  {
    id: 'RUS',
    name: 'Russia',
    shortName: 'RU',
    isEuropeanUnion: false,
  },
  {
    id: 'RWA',
    name: 'Rwanda',
    shortName: 'RW',
    isEuropeanUnion: false,
  },
  {
    id: 'BLM',
    name: 'Saint Barthelemy',
    shortName: 'BL',
    isEuropeanUnion: false,
  },
  {
    id: 'SHN',
    name: 'Saint Helena',
    shortName: 'SH',
    isEuropeanUnion: false,
  },
  {
    id: 'KNA',
    name: 'Saint Kitts and Nevis',
    shortName: 'KN',
    isEuropeanUnion: false,
  },
  {
    id: 'LCA',
    name: 'Saint Lucia',
    shortName: 'LC',
    isEuropeanUnion: false,
  },
  {
    id: 'MAF',
    name: 'Saint Martin',
    shortName: 'MF',
    isEuropeanUnion: false,
  },
  {
    id: 'SPM',
    name: 'Saint Pierre and Miquelon',
    shortName: 'PM',
    isEuropeanUnion: false,
  },
  {
    id: 'VCT',
    name: 'Saint Vincent and the Grenadines',
    shortName: 'VC',
    isEuropeanUnion: false,
  },
  {
    id: 'WSM',
    name: 'Samoa',
    shortName: 'WS',
    isEuropeanUnion: false,
  },
  {
    id: 'SMR',
    name: 'San Marino',
    shortName: 'SM',
    isEuropeanUnion: false,
  },
  {
    id: 'STP',
    name: 'Sao Tome and Principe',
    shortName: 'ST',
    isEuropeanUnion: false,
  },
  {
    id: 'SAU',
    name: 'Saudi Arabia',
    shortName: 'SA',
    isEuropeanUnion: false,
  },
  {
    id: 'SEN',
    name: 'Senegal',
    shortName: 'SN',
    isEuropeanUnion: false,
  },
  {
    id: 'SRB',
    name: 'Serbia',
    shortName: 'RS',
    isEuropeanUnion: false,
  },
  {
    id: 'SYC',
    name: 'Seychelles',
    shortName: 'SC',
    isEuropeanUnion: false,
  },
  {
    id: 'SLE',
    name: 'Sierra Leone',
    shortName: 'SL',
    isEuropeanUnion: false,
  },
  {
    id: 'SGP',
    name: 'Singapore',
    shortName: 'SG',
    isEuropeanUnion: false,
  },
  {
    id: 'SVK',
    name: 'Slovakia',
    shortName: 'SK',
    isEuropeanUnion: true,
  },
  {
    id: 'SVN',
    name: 'Slovenia',
    shortName: 'SI',
    isEuropeanUnion: true,
  },
  {
    id: 'SLB',
    name: 'Solomon Islands',
    shortName: 'SB',
    isEuropeanUnion: false,
  },
  {
    id: 'SOM',
    name: 'Somalia',
    shortName: 'SO',
    isEuropeanUnion: false,
  },
  {
    id: 'ZAF',
    name: 'South Africa',
    shortName: 'ZA',
    isEuropeanUnion: false,
  },
  {
    id: 'KOR',
    name: 'South Korea',
    shortName: 'KR',
    isEuropeanUnion: false,
  },
  {
    id: 'ESP',
    name: 'Spain',
    shortName: 'ES',
    isEuropeanUnion: true,
  },
  {
    id: 'LKA',
    name: 'Sri Lanka',
    shortName: 'LK',
    isEuropeanUnion: false,
  },
  {
    id: 'PSE',
    name: 'State of Palestine',
    shortName: 'PS',
    isEuropeanUnion: false,
  },
  {
    id: 'SDN',
    name: 'Sudan',
    shortName: 'SD',
    isEuropeanUnion: false,
  },
  {
    id: 'SUR',
    name: 'Suriname',
    shortName: 'SR',
    isEuropeanUnion: false,
  },
  {
    id: 'SJM',
    name: 'Svalbard',
    shortName: 'SJ',
    isEuropeanUnion: false,
  },
  {
    id: 'SWZ',
    name: 'Swaziland',
    shortName: 'SZ',
    isEuropeanUnion: false,
  },
  {
    id: 'SWE',
    name: 'Sweden',
    shortName: 'SE',
    isEuropeanUnion: true,
  },
  {
    id: 'CHE',
    name: 'Switzerland',
    shortName: 'CH',
    isEuropeanUnion: true,
  },
  {
    id: 'SYR',
    name: 'Syria',
    shortName: 'SY',
    isEuropeanUnion: false,
  },
  {
    id: 'TWN',
    name: 'Taiwan',
    shortName: 'TW',
    isEuropeanUnion: false,
  },
  {
    id: 'TJK',
    name: 'Tajikistan',
    shortName: 'TJ',
    isEuropeanUnion: false,
  },
  {
    id: 'TZA',
    name: 'Tanzania',
    shortName: 'TZ',
    isEuropeanUnion: false,
  },
  {
    id: 'THA',
    name: 'Thailand',
    shortName: 'TH',
    isEuropeanUnion: false,
  },
  {
    id: 'TLS',
    name: 'Timor-Leste',
    shortName: 'TL',
    isEuropeanUnion: false,
  },
  {
    id: 'TGO',
    name: 'Togo',
    shortName: 'TG',
    isEuropeanUnion: false,
  },
  {
    id: 'TKL',
    name: 'Tokelau',
    shortName: 'TK',
    isEuropeanUnion: false,
  },
  {
    id: 'TON',
    name: 'Tonga',
    shortName: 'TO',
    isEuropeanUnion: false,
  },
  {
    id: 'TTO',
    name: 'Trinidad and Tobago',
    shortName: 'TT',
    isEuropeanUnion: false,
  },
  {
    id: 'TUN',
    name: 'Tunisia',
    shortName: 'TN',
    isEuropeanUnion: false,
  },
  {
    id: 'TUR',
    name: 'Turkey',
    shortName: 'TR',
    isEuropeanUnion: false,
  },
  {
    id: 'TKM',
    name: 'Turkmenistan',
    shortName: 'TM',
    isEuropeanUnion: false,
  },
  {
    id: 'TCA',
    name: 'Turks and Caicos Islands',
    shortName: 'TC',
    isEuropeanUnion: false,
  },
  {
    id: 'TUV',
    name: 'Tuvalu',
    shortName: 'TV',
    isEuropeanUnion: false,
  },
  {
    id: 'UGA',
    name: 'Uganda',
    shortName: 'UG',
    isEuropeanUnion: false,
  },
  {
    id: 'UKR',
    name: 'Ukraine',
    shortName: 'UA',
    isEuropeanUnion: false,
  },
  {
    id: 'ARE',
    name: 'United Arab Emirates',
    shortName: 'AE',
    isEuropeanUnion: false,
  },
  {
    id: 'GBR',
    name: 'United Kingdom',
    shortName: 'GB',
    isEuropeanUnion: false,
  },
  {
    id: 'USA',
    name: 'United States',
    shortName: 'US',
    isEuropeanUnion: false,
  },
  {
    id: 'URY',
    name: 'Uruguay',
    shortName: 'UY',
    isEuropeanUnion: false,
  },
  {
    id: 'VIR',
    name: 'US Virgin Islands',
    shortName: 'VI',
    isEuropeanUnion: false,
  },
  {
    id: 'UZB',
    name: 'Uzbekistan',
    shortName: 'UZ',
    isEuropeanUnion: false,
  },
  {
    id: 'VUT',
    name: 'Vanuatu',
    shortName: 'VU',
    isEuropeanUnion: false,
  },
  {
    id: 'VEN',
    name: 'Venezuela',
    shortName: 'VE',
    isEuropeanUnion: false,
  },
  {
    id: 'VNM',
    name: 'Vietnam',
    shortName: 'VN',
    isEuropeanUnion: false,
  },
  {
    id: 'WLF',
    name: 'Wallis and Futuna',
    shortName: 'WF',
    isEuropeanUnion: false,
  },
  {
    id: 'WEB',
    name: 'West Bank',
    shortName: 'WB',
    isEuropeanUnion: false,
  },
  {
    id: 'ESH',
    name: 'Western Sahara',
    shortName: 'EH',
    isEuropeanUnion: false,
  },
  {
    id: 'YEM',
    name: 'Yemen',
    shortName: 'YE',
    isEuropeanUnion: false,
  },
  {
    id: 'ZMB',
    name: 'Zambia',
    shortName: 'ZM',
    isEuropeanUnion: false,
  },
  {
    id: 'ZWE',
    name: 'Zimbabwe',
    shortName: 'ZW',
    isEuropeanUnion: false,
  },
]

export const getCountryCode = (id: string) =>
  countries.find((x) => x.id.toLowerCase() === id.toLowerCase())?.shortName
