import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Loading } from '../../global/Loading/Loading'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { TransactionStateHistoryDto } from '../../model/TransactionDto'
import { useApiClient } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { ClientApiClient } from '../../utils/clientApi'
import { formatDate } from '../../utils/date.utils'
import { isNotEmpty } from '../../utils/isEmpty'
import { useFetchOne } from '../../utils/useFetch'
import { TransactionBadgeStatus } from './TransactionBadgeStatus'

import styles from './TransactionStatusHistoryModal.module.scss'

interface Props {
  id: string
  onCancel: () => void
}

const TransactionStatusHistoryBody: React.FC<{
  transactionStateHistory: TransactionStateHistoryDto | undefined
}> = ({ transactionStateHistory }) => {
  const { t } = useTranslation()
  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name

  return (
    <section className={classNames('modal-card-body', styles.body)}>
      {transactionStateHistory && isNotEmpty(transactionStateHistory?.history) ? (
        transactionStateHistory?.history.map((item, i) => (
          <div className={styles.itemWrapper} key={i}>
            <div className={styles.wrapper}>
              <div className={styles.label}>{t('Date')}</div>
              <div>{formatDate(item.createdDate, dateFormat)}</div>
            </div>
            <div className={styles.wrapper}>
              <div className={styles.label}>{t('Status')}</div>
              <TransactionBadgeStatus {...item.transactionState} />
            </div>
          </div>
        ))
      ) : (
        <span>{t('No history')}</span>
      )}
    </section>
  )
}
export const TransactionStatusHistoryModal: React.FC<Props> = ({ onCancel, id }) => {
  const locale = useSessionLanguage()

  const apiClient = useApiClient(ClientApiClient)
  const historyCallback = useCallback(
    () => apiClient.getTransactionStatusHistory(id, locale),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, locale]
  )

  const { data, isLoading } = useFetchOne(historyCallback)
  const { t } = useTranslation()
  return (
    <Loading isLoading={isLoading} isModal>
      <header className='modal-card-head'>
        <p className='modal-card-title'>{t('Status History')}</p>
      </header>
      <TransactionStatusHistoryBody transactionStateHistory={data} />
      <footer className='modal-card-foot'>
        <button className='button' onClick={onCancel}>
          {t('Got It')}
        </button>
      </footer>
    </Loading>
  )
}
