import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { InfoModal } from '../../global/modal/InfoModal'
import { Modal } from '../../global/modal/Modal'

import styles from '../../global/modal/CancelActionModal.module.scss'

interface AuthorisedTradersInfoModalProps {
  closeModal(): void
}

export const AuthorisedTradersInfoModal: React.FC<AuthorisedTradersInfoModalProps> = (props) => {
  const { closeModal } = props

  const { t } = useTranslation()

  return (
    <Modal
      closeModal={closeModal}
      render={({ closeModal }) => (
        <InfoModal
          onCancel={closeModal}
          title={t('Sign up.Authorised Traders')}
          renderBody={() => (
            <div className={classNames('modal-card-body', styles.body)}>
              <p
                dangerouslySetInnerHTML={{
                  __html: t(
                    'Sign up.The following persons are employees of the above-mentioned company and are hereby authorised to execute trades on behalf of the company.'
                  ),
                }}
              />
            </div>
          )}
          renderFooter={() => (
            <button className='button' onClick={closeModal} type='button'>
              <b>{t('Got It')}</b>
            </button>
          )}
          onConfirm={closeModal}
        />
      )}
    />
  )
}
