import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Loading } from '../../global/Loading/Loading'
import { Tabs, defineTab } from '../../global/tabs/Tabs'
import { ScrollToIds } from '../../hooks/useScrollToElementIds'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { useWindowResize } from '../../utils/domUtils'
import { useIBReferralCodes } from '../../utils/useIBReferralCodes'
import { IBSignUpPage } from '../SignUp/IBSignUpPage'
import { MultiTierCommision } from './MultiTierParts/MultiTierCommision'
import { MultiTierNetwork } from './MultiTierParts/MultiTierNetwork'
import { MultiTierSubAccounts } from './MultiTierParts/MultiTierSubAccounts'

import styles from './MultiTierPage.module.scss'

enum MultiTierTabs {
  CommissionPlans = 'commissionPlans',
  SubIB = 'subIB',
  Network = 'network',
}

export const MultiTierPage = (): JSX.Element => {
  const { t } = useTranslation()
  const isMobile = useWindowResize()
  const [tab, setTab] = useState<MultiTierTabs>(MultiTierTabs.CommissionPlans)
  const { isLoading, ibCode, mtCode, refreshReferralCodes } = useIBReferralCodes()
  const [registering, setRegistering] = useState(false)

  function* getTabs() {
    yield {
      ...defineTab(MultiTierTabs.CommissionPlans, t('IB.MultiTier.IB Commission Plans')),
    }
    yield {
      ...defineTab(MultiTierTabs.SubIB, t('IB.MultiTier.Sub-IB Accounts')),
    }
    yield {
      ...defineTab(MultiTierTabs.Network, t('IB.MultiTier.Multi-tier Network')),
    }
  }

  const tabs = [...getTabs()]

  if (isLoading) return <Loading showLoadingIcon isLoading={isLoading} />

  if (!mtCode || registering)
    return (
      <>
        <PageHeader title={t('IB.Introducing Broker Program')} id={ScrollToIds.IBSignupHeader} />
        <IBSignUpPage reloadCodes={refreshReferralCodes} />
      </>
    )

  if (isMobile) {
    return (
      <div>
        <div className={styles.tabsWrapper}>
          <Tabs tabs={tabs} currentTab={tab} onTabChanged={setTab} />
        </div>
        <div className={styles.balances}>
          {tab === MultiTierTabs.CommissionPlans && <MultiTierCommision />}
          {tab === MultiTierTabs.SubIB && <MultiTierSubAccounts />}
          {tab === MultiTierTabs.Network && <MultiTierNetwork />}
        </div>
      </div>
    )
  }

  return (
    <>
      <h3 className={styles.title}>{t('IB.MultiTier.Multi-Tier Dashboard')}</h3>
      <div className={styles.multiTierTables}>
        <div className={styles.column}>
          <MultiTierCommision />
          <MultiTierNetwork />
        </div>
        <div className={styles.column}>
          <MultiTierSubAccounts />
        </div>
      </div>
    </>
  )
}
