import React, { PropsWithChildren, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Loading } from '../../../global/Loading/Loading'
import { AccountType } from '../../../model/AccountType'
import { LeadDto } from '../../../model/LeadDto'
import { TickmillProductType } from '../../../model/TickmillProductType'
import { Text } from '../../../ui/Typography/Typography'
import { useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import { TickmillCompaniesEnum } from '../../../utils/companyName.utils'
import { useFetchOne } from '../../../utils/useFetch'
import { useLocallyPersistedState } from '../../../utils/useStorage'
import { LeftSection, SignupFormWrapper, SignupStep, SignupWrapper } from '../../SignupPage'
import { usePersonalInfoTotalSteps } from '../usePersonalInfoTotalSteps'

import styles from '../PersonalInfoPage.module.scss'

export interface SignUpRegisterTemplateProps {
  lead: LeadDto
  subStep: number
  isLoading: boolean
  onStepChange(value: number): void
  reversed?: boolean
}

export const SignUpRegisterTemplate: React.FC<PropsWithChildren<SignUpRegisterTemplateProps>> = (
  props
) => {
  const { lead, subStep, isLoading, children, onStepChange, reversed } = props
  const apiClient = useApiClient(ClientApiClient)

  const totalStepsCount = usePersonalInfoTotalSteps()

  const totalSteps = useMemo(() => {
    if (lead.type.id === AccountType.Individual) {
      return totalStepsCount
    } else {
      if (subStep < totalStepsCount + 1) return totalStepsCount
      return 3
    }
  }, [lead.type.id, subStep, totalStepsCount])

  const { t } = useTranslation()

  const [_, setSelectedProductIds] = useLocallyPersistedState<TickmillProductType[]>(
    'selectedProductIdsKey',
    []
  )

  const callback = useCallback(
    async () => {
      if (!lead.id || !lead.token) return []

      const products = await apiClient.getClientProductsByToken(lead?.id, lead?.token)

      if (products.length) {
        setSelectedProductIds(products.map((product) => product.id))
      }

      return products
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lead?.id, lead?.token]
  )

  const { data: products = [] } = useFetchOne(callback)

  return (
    <SignupFormWrapper
      label={t('Sign up.Sign up')}
      shouldHideBackArrow={subStep === 4}
      onBackToPreviousStep={() => {
        if (subStep > 4) onStepChange(subStep - 1)
      }}
      products={products}
    >
      <SignupStep
        subStep={{
          current:
            lead.type.id === AccountType.Corporate && subStep > totalStepsCount
              ? subStep - totalStepsCount
              : subStep,
          total: totalSteps,
        }}
        section={lead.type.id === AccountType.Corporate && subStep > totalStepsCount ? 3 : 2}
        accountTypeId={lead.type.id}
      />
      <SignupWrapper>
        <Loading isLoading={isLoading}>
          <React.Fragment>
            <LeftSection
              title={subStep < 7 ? t('Sign up.Personal Details') : t('Sign up.Company Details')}
              description={() => (
                <React.Fragment>
                  {lead.type.id === AccountType.Individual &&
                    (lead.tickmillCompany.id === TickmillCompaniesEnum.TICKMILL_UK ? (
                      <Text isParagraph className={styles.text}>
                        {t(
                          'Sign up.Next up, we would like to know a little bit more about you. Please complete the'
                        )}
                      </Text>
                    ) : (
                      <Text isParagraph className={styles.text}>
                        {t(
                          'Sign up.Next up, we would like to know a little bit more about you. It will take you about 10'
                        )}
                      </Text>
                    ))}
                  {lead.type.id === AccountType.Corporate &&
                    subStep < 7 &&
                    (lead.tickmillCompany.id === TickmillCompaniesEnum.TICKMILL_UK ? (
                      <Text isParagraph className={styles.text}>
                        {t(
                          'Sign up.Next up, we would like to know a little bit more about you. Please complete the'
                        )}
                      </Text>
                    ) : (
                      <Text isParagraph className={styles.text}>
                        {t(
                          "Sign up.Next up, we like to know a little bit more about you. It'll take you about 20"
                        )}
                      </Text>
                    ))}
                  {lead.type.id === AccountType.Corporate && subStep >= 7 && (
                    <div>
                      {lead.tickmillCompany.id === TickmillCompaniesEnum.TICKMILL_UK ? (
                        <Text isParagraph className={styles.text}>
                          {t(
                            "Sign up.Next up, we'd like to know a little bit more about your company UK"
                          )}
                        </Text>
                      ) : (
                        <Text isParagraph className={styles.text}>
                          {t(
                            "Sign up.Next up, we'd like to know a little bit more about your company"
                          )}
                        </Text>
                      )}
                      <br />
                      <Text isParagraph className={styles.text}>
                        {t('Sign up.Be prepared by having your company documents next to you')}
                      </Text>
                    </div>
                  )}
                  <br />
                </React.Fragment>
              )}
            />
            <section
              className={classNames(styles.formSection, styles.wrapper, {
                [styles.reversed]: reversed,
              })}
            >
              {children}
            </section>
          </React.Fragment>
        </Loading>
      </SignupWrapper>
    </SignupFormWrapper>
  )
}
