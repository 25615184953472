import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Button } from '../../../global/button/Button'
import { useFormatNumber } from '../../../hooks/useFormatNumber'
import { DotsIcon } from '../../../icons/DotsIcon'
import { WarningIcon } from '../../../icons/WarningIcon'
import { WalletDto } from '../../../model/WalletDto'
import { AlertIconWrapper } from '../../../ui/AlertIconWrapper/AlertIconWrapper'
import { WalletRestrictions, isRestricted, isWalletSuspended } from '../../../utils/wallet.utils'

import styles from './WalletBalancesCard.module.scss'

interface WalletBalancesCardProps {
  wallet: WalletDto
  setWalletOptionsModal(wallet: WalletDto): void
  setModalVisible(v: boolean): void
}

export const WalletBalancesCard: React.FC<WalletBalancesCardProps> = (props) => {
  const { wallet, setModalVisible, setWalletOptionsModal } = props

  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()

  const handleRestrictionModalOpen = () => {
    const hasAllRestrictions = isWalletSuspended(wallet.restrictions)
    if (!hasAllRestrictions) {
      setModalVisible(true)
    }
  }

  const hasAllRestrictions = isWalletSuspended(wallet.restrictions)
  const isDepositRestricted = isRestricted(WalletRestrictions.DEPOSIT, wallet.restrictions)
  const isTransferFromRestricted = isRestricted(
    WalletRestrictions.TRANSFER_FROM,
    wallet.restrictions
  )

  return (
    <div className={styles.card}>
      <span className={styles.mainWrap}>
        {hasAllRestrictions && (
          <span className='is-pulled-left'>
            <WarningIcon size={20} color={'error'} />
          </span>
        )}{' '}
        {wallet.currency.id} {t('Wallet.Wallet')}
        <span className='is-pulled-right'>
          <span onClick={() => setWalletOptionsModal(wallet)} className='has-cursor-pointer'>
            <DotsIcon />
          </span>
        </span>
      </span>
      <h2 className={styles.title}>{formatMoney(wallet.balance, wallet.currency.id)}</h2>
      <div>
        <span className={styles.subLabel}>{t('Wallet.Reserved')}</span>
        <span className={styles.subLabelValue}>
          {formatMoney(wallet.reserved, wallet.currency.id)}
        </span>
      </div>
      <div className='mt-4 mb-1'>
        {isDepositRestricted && (
          <AlertIconWrapper fullWidth spaced isActive={!hasAllRestrictions}>
            <Button
              appearance='primary'
              state='disabled'
              size='M'
              onClick={handleRestrictionModalOpen}
              className={styles.button}
            >
              {t('Deposit')}
            </Button>
          </AlertIconWrapper>
        )}
        {!isDepositRestricted && (
          <Link to={`/dashboard/traders-room/wallets/${wallet.id}/deposit`}>
            <Button appearance='primary' size='M' className={styles.button}>
              {t('Deposit')}
            </Button>
          </Link>
        )}
      </div>
      <div className='mb-4'>
        {isTransferFromRestricted && (
          <AlertIconWrapper fullWidth spaced isActive={!hasAllRestrictions}>
            <Button
              appearance='secondary'
              state='disabled'
              size='M'
              onClick={handleRestrictionModalOpen}
              className={styles.button}
            >
              {t('Transfer')}
            </Button>
          </AlertIconWrapper>
        )}
        {!isTransferFromRestricted && (
          <Link to={`/dashboard/traders-room/wallets/${wallet.id}/transfer`}>
            <Button appearance='secondary' size='M' className={styles.button}>
              {t('Transfer')}
            </Button>
          </Link>
        )}
      </div>
      <div>
        <Link
          className={styles.history}
          to='/dashboard/traders-room/transaction-history'
          state={{ walletId: wallet.id }}
        >
          {t('View Transaction History')}
        </Link>
      </div>
    </div>
  )
}
