export enum TutorialGroupEnum {
  introducingToFutures = 1,
  understandingFutures = 2,
  keyEconomic = 3,
  exchangeInstrument = 5,
}
export interface TutorialVideosDto {
  tutorialGroup: {
    id: TutorialGroupEnum
    name: string
  }
  tutorials?: {
    title: string
    tutorialLink: string
  }[]
}
