import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { TradingAccount } from '../../../model/TradingAccount'
import { WalletDto, WalletTypeEnum } from '../../../model/WalletDto'
import { PageData } from '../../../utils/ApiClient'
import {
  TradingAccountRestrictions,
  isRestricted as taIsRestricted,
} from '../../../utils/trading-account.utils'
import { WalletRestrictions, isRestricted } from '../../../utils/wallet.utils'
import { Balance } from './Balance'
import { WalletOrTradingAccountType } from './TransferForm'

import styles from './TransferFromModal.module.scss'

interface Props {
  wallets: WalletDto[]
  tradingAccounts: PageData<TradingAccount> | undefined
  setTransferTo: (transferTo: WalletOrTradingAccountType) => void
  transferFrom: WalletOrTradingAccountType
  transferTo: WalletOrTradingAccountType

  onCancel(): void
}

interface BodyProps {
  wallets: WalletDto[]
  tradingAccounts: PageData<TradingAccount> | undefined
  transferFrom: WalletOrTradingAccountType
  transferTo: WalletOrTradingAccountType

  setTransferTo(transferTo: WalletOrTradingAccountType): void
}

export const isWalletType = (
  tradingAccountOrWallet: WalletOrTradingAccountType
): tradingAccountOrWallet is WalletDto => {
  return 'walletType' in tradingAccountOrWallet
}

export interface RadioBoxProps {
  isWallet?: boolean
  isDisabled: boolean
  tradingAccountOrWallet: WalletOrTradingAccountType
  checked: boolean

  setTransferField(tradingAccountOrWallet: WalletOrTradingAccountType): void

  isFromWallet?: boolean
}

export const RadioBoxField: React.FC<RadioBoxProps> = ({
  isDisabled,
  setTransferField,
  tradingAccountOrWallet,
  isWallet,
  checked,
}) => {
  const { t } = useTranslation()

  const getWalletFullName = (walletOrTa: WalletDto) => {
    return walletOrTa.walletType.id === WalletTypeEnum.IB
      ? t('Wallet.Currency IB Wallet', {
          walletCurrencyId: walletOrTa.currency.id,
        })
      : t('Wallet.Currency Wallet', {
          walletCurrencyId: walletOrTa.currency.id,
        })
  }

  return (
    <label
      className={classNames({
        'is-clickable': !isDisabled,
        [styles.textStrong]: isWallet,
      })}
      onClick={() => (isDisabled ? '' : setTransferField(tradingAccountOrWallet))}
    >
      <input type='radio' name='toClientId' disabled={isDisabled} checked={checked} />
      {isWalletType(tradingAccountOrWallet)
        ? getWalletFullName(tradingAccountOrWallet)
        : tradingAccountOrWallet.name}{' '}
    </label>
  )
}

const TransferToModalBody: React.FC<BodyProps> = ({
  wallets,
  tradingAccounts,
  setTransferTo,
  transferFrom,
  transferTo,
}) => {
  return (
    <section className={classNames(styles.body, 'modal-card-body')}>
      <div className={styles.wrapper}>
        {wallets.map((wallet, i) => {
          const hasTransferToRestriction = isRestricted(
            WalletRestrictions.TRANSFER_TO,
            wallet.restrictions
          )
          const isDisabled =
            hasTransferToRestriction ||
            (!('wallet' in transferFrom) && wallet.id === transferFrom.id)
          const isSameAsTransferFrom = transferFrom === wallet

          return (
            <div className={styles.walletWrapper} key={i}>
              {!isSameAsTransferFrom && (
                <div
                  className={classNames(styles.wallet, {
                    [styles.grayText]: isDisabled,
                  })}
                >
                  <RadioBoxField
                    tradingAccountOrWallet={wallet}
                    isDisabled={isDisabled}
                    setTransferField={setTransferTo}
                    isWallet
                    checked={transferTo === wallet}
                  />
                  <Balance
                    isDisabled={isDisabled}
                    balance={wallet.balance}
                    currencyId={wallet.currency.id}
                    isWallet={isWalletType(wallet)}
                    isTransferTo
                  />
                </div>
              )}
              {isSameAsTransferFrom && (
                <div
                  className={classNames(styles.wallet, {
                    [styles.grayText]: isDisabled,
                  })}
                >
                  <RadioBoxField
                    tradingAccountOrWallet={wallet}
                    isDisabled={isDisabled}
                    setTransferField={setTransferTo}
                    isWallet
                    checked={transferTo === wallet}
                  />
                </div>
              )}

              {tradingAccounts?.items
                .filter((ta) => ta.currency.id === wallet.currency.id)
                .map((ta, i) => {
                  const hasTransferToRestriction = taIsRestricted(
                    TradingAccountRestrictions.TRANSFER_TO,
                    ta.restrictions
                  )
                  const isDisabled =
                    hasTransferToRestriction ||
                    (!('wallet' in transferFrom) && transferFrom.id !== ta.wallet.id)

                  const taIsFromSameWallet =
                    !('wallet' in transferFrom) && transferFrom.id === ta.wallet.id

                  return taIsFromSameWallet ? (
                    <div
                      key={i}
                      className={classNames(styles.accountWrapper, {
                        [styles.grayText]: isDisabled,
                      })}
                    >
                      <div className={styles.account}>
                        <RadioBoxField
                          tradingAccountOrWallet={ta as WalletOrTradingAccountType}
                          isDisabled={isDisabled}
                          setTransferField={setTransferTo}
                          checked={transferTo === ta}
                        />
                        <Balance
                          isDisabled={isDisabled}
                          balance={ta.platformOverview.balance}
                          currencyId={ta.currency.id}
                          freeMargin={ta.platformOverview.freeMargin}
                          isWallet={isWalletType(ta)}
                          isTransferTo
                        />
                      </div>
                    </div>
                  ) : null
                })}
            </div>
          )
        })}
      </div>
    </section>
  )
}

export const TransferToModal: React.FC<Props> = ({
  onCancel,
  wallets,
  tradingAccounts,
  setTransferTo,
  transferFrom,
  transferTo,
}) => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <header className='modal-card-head'>
        <p className='modal-card-title'>{t('Wallet.Transfer To')}</p>
      </header>
      <TransferToModalBody
        wallets={wallets}
        tradingAccounts={tradingAccounts}
        setTransferTo={setTransferTo}
        transferFrom={transferFrom}
        transferTo={transferTo}
      />
      <footer className={classNames('modal-card-foot', styles.footer)}>
        <button className='button' onClick={onCancel}>
          {t('Cancel')}
        </button>
      </footer>
    </React.Fragment>
  )
}
