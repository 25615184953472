import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { Button } from '../global/button/Button'
import { useSessionLanguage } from '../global/context/SessionSettingsContext'
import { useAccountWriteContext } from '../utils/AccountContextContext'

import styles from './WarningAccountPage.module.scss'

export const FirstAppropriateTest: FC = () => {
  const { t } = useTranslation()
  const { refreshAccount } = useAccountWriteContext()
  const locale = useSessionLanguage()

  useEffect(() => {
    refreshAccount(locale)
  }, [])

  return (
    <section className={styles.wrapper}>
      <div className='my-6 has-text-centered'>
        <h3 className='mb-5'>{t('Attention!')}</h3>
        <div className={classNames(styles.box, 'has-text-centered')}>
          <div className='has-text-centered mt-5'>
            <h1 className='mb-5'>{t('Required Step: Complete the Appropriateness Test')}</h1>
            <p className='text'>
              {t(
                'To gain access to your Client Area and open a Trading Account, you must first pass the Appropriateness Test.'
              )}
            </p>
          </div>
          <div className={classNames(styles.reverseColumns, 'columns is-centered mt-3 mb-5')}>
            <div className='column is-4'>
              <Link to='/profile/appropriateness-test/take-test'>
                <Button size='M' appearance='primary' className='is-fullwidth'>
                  <span>{t('Proceed to the Test')}</span>
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
