import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import {
  hasProductAppropriatenessTestSubmittedStatus,
  hasProductPendingDocumentVerificationStatus,
} from '../../model/AccountDetailedDto'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { isTickmillPartner } from '../../utils/companyName.utils'
import { isNoTabsPath } from '../../utils/path'
import { useProductReadContext } from '../context/ProductContext'
import { Tabs, defineTab } from '../tabs/Tabs'

const useDashboardTabsData = () => {
  const { account } = useAccountReadContext()
  const { t } = useTranslation()
  const { product } = useProductReadContext()
  const isAccountPending =
    hasProductAppropriatenessTestSubmittedStatus(account, product) ||
    hasProductPendingDocumentVerificationStatus(account, product)

  const ibOrPartnerTab = isTickmillPartner(account)
    ? t('Tabs.Partners')
    : t('Tabs.Introducing broker')

  return {
    isAccountPending,
    ibOrPartnerTab,
    account,
  }
}

export const DashboardTabs: React.FC<{ currentTab?: string }> = ({ currentTab }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const { isAccountPending, ibOrPartnerTab, account } = useDashboardTabsData()
  const { t } = useTranslation()
  function* getTabs() {
    yield {
      ...defineTab(
        'traders-room',
        t('Tabs.Traders room'),
        false,
        false,
        'traders-room/wallets',
        classNames({
          'is-hidden': isAccountPending || !account?.companyConfiguration.hasTraderRoom,
        })
      ),
    }
    yield {
      ...defineTab(
        'introducing-broker',
        ibOrPartnerTab,
        undefined,
        false,
        'introducing-broker/wallets',
        classNames({
          'is-hidden': isAccountPending || !account?.companyConfiguration.hasIBRoom,
        })
      ),
    }
    if (!!account?.clientPaymentAgent && account.clientPaymentAgent.isActive) {
      yield {
        ...defineTab(
          'payment-agent',
          t('Payment Agent.Payment Agent'),
          undefined,
          false,
          'payment-agent/wallets',
          classNames({
            'is-hidden': isAccountPending || !account?.companyConfiguration.hasPARoom,
          })
        ),
      }
    }
    yield {
      ...defineTab(
        'tools',
        t('Tabs.Tools'),
        undefined,
        false,
        'tools/platforms',
        classNames({
          'is-hidden': isAccountPending || !account?.companyConfiguration.hasAutochartist,
        })
      ),
    }
    yield {
      ...defineTab(
        'learning',
        t('Tabs.Learning'),
        undefined,
        false,
        isTickmillPartner(account) ? 'learning/trainings' : 'learning/getting-started',
        classNames({
          'is-hidden': !account?.companyConfiguration.hasForexTools,
        })
      ),
    }
  }

  const tabs = [...getTabs()]

  if (!account || tabs.length < 2 || isNoTabsPath(location.pathname) || !currentTab) return null

  return (
    <Tabs
      tabs={tabs}
      currentTab={currentTab}
      onTabChanged={(tab) => {
        const path = tabs.find((x) => x.id === tab)
        if (path) {
          return navigate(`/dashboard/${path.path}`)
        }
        return navigate(`/dashboard/${tab}`)
      }}
    />
  )
}
