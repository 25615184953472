import { IconProps, iconColor } from './IconProps'

export function NotificationCircledIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.75'
        y='0.75'
        width='38.5'
        height='38.5'
        rx='19.25'
        stroke={iconColor(props)}
        stroke-width='1.5'
      />
      <path
        d='M20 13H19.6483C17.0601 13 14.9003 14.9748 14.6158 17.5473C14.2093 21.2235 13.5912 26.022 13 27.5H18.3846M20 13H20.3517C22.9399 13 25.0997 14.9748 25.3842 17.5473C25.7907 21.2235 26.4088 26.022 27 27.5H21.6154M20 13V10M18.3846 27.5V27.8846C18.3846 28.7768 19.1078 29.5 20 29.5V29.5C20.8922 29.5 21.6154 28.7768 21.6154 27.8846V27.5M18.3846 27.5H21.6154'
        stroke={iconColor(props)}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
