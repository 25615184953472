import React, { PropsWithChildren, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { CancelActionModal } from '../../global/modal/CancleActionModal'
import { CloseIcon } from '../../icons/CloseIcon'
import { DummyIcon } from '../../icons/DummyIcon'
import { useScrollToTop } from '../../utils/useScrollToTop'
import IconButton from '../iconButton/IconButton'
import { Modal } from '../modal/Modal'

import styles from './FormWrapper.module.scss'

interface Props {
  label: string
  goBack?(): void
}

export const FormWrapper: React.FunctionComponent<PropsWithChildren<Props>> = ({
  label,
  children,
  goBack,
}) => {
  const navigate = useNavigate()
  const [cancelModal, setCancelModal] = useState(false)
  useScrollToTop()

  const handleGoBack = () => {
    if (goBack) goBack()
    else navigate(-1)
  }

  return (
    <React.Fragment>
      {cancelModal && (
        <Modal
          closeModal={() => setCancelModal(false)}
          render={() => (
            <CancelActionModal onConfirm={handleGoBack} onCancel={() => setCancelModal(false)} />
          )}
        />
      )}
      <main className={styles.wrapper}>
        <div className={styles.titleBox}>
          <IconButton onClick={() => setCancelModal(true)}>
            <CloseIcon size={14} />
          </IconButton>
          <h3>{label}</h3>
          <DummyIcon size={14} />
        </div>
        {children}
      </main>
    </React.Fragment>
  )
}
