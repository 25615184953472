import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { useFormatNumber } from '../../hooks/useFormatNumber'
import PSPLogo from '../../images/online-banking-icon-48x48px.png'
import { WalletPaymentProvider } from '../../model/WalletPaymentProvider'
import { useProductReadContext } from '../context/ProductContext'
import { SelectModal } from '../field/SelectModal'
import { Modal } from './Modal'

import styles from './PaymentProviderModal.module.scss'

interface PaymentProviderModalProps {
  onClose(): void

  render(): React.ReactNode
}

const SC_TRANSACT365_MYR_ID = 'b0f704f5-628a-11ee-96cf-06bf40e8b50a'
const SC_TRANSACT365_BDT = '9e064c72-7e03-11ee-96cf-06bf40e8b50a'
const SINGAPORE_ONLINE_BANK_TRANSFER_METHOD_ID = 'b82dc307-9d79-11ed-8b0c-0a51dcb142a4'

export const PaymentProviderModal: React.FC<PaymentProviderModalProps> = ({ onClose, render }) => {
  const { t } = useTranslation()
  return (
    <Modal
      closeModal={onClose}
      render={({ closeModal }) => (
        <SelectModal
          onCancel={closeModal}
          title={t('Choose Payment Provider')}
          renderOptions={() => render()}
        />
      )}
    />
  )
}

interface PaymentProviderItemProps {
  item: WalletPaymentProvider
  onSelectOption(option: WalletPaymentProvider): void
}

export const PaymentProviderWithdrawalModalItem: React.FC<PaymentProviderItemProps> = (props) => {
  const { item } = props

  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()
  const { isDefaultCFDProductType } = useProductReadContext()

  return (
    <PaymentProviderModalItem {...props}>
      <h3>{item.description}</h3>
      <p>
        {t('Processing Time')} {item.fundingTime}
      </p>
      {isDefaultCFDProductType() && (
        <>
          <p>
            {t('Fee')} {formatMoney(item.fee, item.currency.id)}
          </p>
          <p>
            {t('Minimum')} {t('withdrawal')} {formatMoney(item.minAmount, item.currency.id)}
          </p>
        </>
      )}

      {item.regulator && <p>{item.regulator}</p>}
    </PaymentProviderModalItem>
  )
}

export const PaymentProviderDepositModalItem: React.FC<PaymentProviderItemProps> = (props) => {
  const { item } = props

  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()
  const { isDefaultCFDProductType } = useProductReadContext()

  return (
    <PaymentProviderModalItem {...props}>
      <h3>{item.description}</h3>
      <p>
        {t('Processing Time')} {item.fundingTime}
      </p>
      {isDefaultCFDProductType() && (
        <>
          <p>
            {t('Fee')} {formatMoney(item.fee, item.currency.id)}
          </p>
          <p>
            {t('Minimum')} {t('deposit')} {formatMoney(item.minAmount, item.currency.id)}
          </p>
        </>
      )}

      {item.regulator && <p>{item.regulator}</p>}
      {item.method.id === SINGAPORE_ONLINE_BANK_TRANSFER_METHOD_ID && (
        <p className={classNames(styles.error, 'mt-2')}>
          {t('The service is unavailable from 4 PM to 9 AM')}
        </p>
      )}
      {item.method.id === SC_TRANSACT365_MYR_ID && (
        <p className={classNames(styles.error, 'mt-2')}>{t('Service hours')}</p>
      )}
      {item.method.id === SC_TRANSACT365_BDT && (
        <p className={classNames(styles.error, 'mt-2')}>{t('Service hours SC_TRANSACT365_BDT')}</p>
      )}
    </PaymentProviderModalItem>
  )
}

export const PaymentProviderModalItem: React.FC<PropsWithChildren<PaymentProviderItemProps>> = (
  props
) => {
  const { item, onSelectOption, children } = props

  return (
    <label
      key={item.name}
      className='radio column is-full-desktop radio'
      onClick={() => onSelectOption(item)}
    >
      <div className='is-flex px-3'>
        <div>
          <div className={classNames('box', styles.box)}>
            <div>
              <img src={item.logo || PSPLogo} alt='' style={{ maxWidth: 'initial' }} />
            </div>
          </div>
        </div>
        <div>
          <div className='columns ml-1'>
            <div className='column is-full'>{children}</div>
          </div>
        </div>
      </div>
    </label>
  )
}
