import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, isString, useFormikContext } from 'formik'

import { DocumentFileUploads } from '../../global/fileUpload/DocumentFileUploads'
import { DoubleSidedDocumentFileUploads } from '../../global/fileUpload/DoubleSidedDocumentFileUploads'
import { FileData } from '../../global/fileUpload/FileUpload'
import { isDoubleSidedDocument } from '../../utils/uploadDocument'

interface DocumentFileUploadsFactoryProps {
  files?: FileData[]
  documentTypeId: number | string
  documentCategory: number
  onChange(fileData: FileData[]): void
  onBeforeRemove?(file: FileData): void
}

interface DocumentManagementDocument {
  filename: string
  error: boolean
}

export const DocumentFileUploadsFactory: React.FC<DocumentFileUploadsFactoryProps> = (props) => {
  const { documentTypeId, documentCategory, files = [], onChange, onBeforeRemove } = props

  const documentType = getDocumentType(documentTypeId)
  const isDoubleSided = isDoubleSidedDocument(documentCategory, documentType)

  const { setFieldValue } = useFormikContext()
  const { t } = useTranslation()

  useEffect(() => {
    setFieldValue('files', [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentTypeId])

  if (isDoubleSided) {
    return (
      <Field name='documents' validate={validateDocuments(t('Validation.Required'))}>
        {() => (
          <DoubleSidedDocumentFileUploads
            files={files}
            documentCategory={documentCategory}
            onChange={onChange}
            onBeforeRemove={onBeforeRemove}
          />
        )}
      </Field>
    )
  }

  return (
    <DocumentFileUploads
      files={files}
      documentCategory={documentCategory}
      onChange={onChange}
      onBeforeRemove={onBeforeRemove}
    />
  )
}

const validateDocuments = (message: string) => (value: DocumentManagementDocument[]) => {
  const documents = value.filter((x) => !!x?.filename).filter((x) => !x.error)

  if (documents.length <= 1) {
    return message
  }

  return undefined
}

const getDocumentType = (documentTypeId: string | number) => {
  return isString(documentTypeId) ? Number.parseInt(documentTypeId, 10) : documentTypeId
}
