import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import Cookies from 'js-cookie'

import { useProductReadContext } from '../global/context/ProductContext'
import { useGuardedContext } from '../global/useGuardedContext'
import { ClientGuideDto, GuideTypes } from '../model/ClientGuideDto'
import { useApiClient } from './ApiClient'
import { AuthSessionContext } from './AuthContext'
import { ClientApiClient } from './clientApi'

export type FirstTimeGuideStepType =
  | 'deposit'
  | 'createAccount'
  | 'transfer'
  | 'download'
  | 'getApp'
  | 'done'
  | undefined

type FirstTimeGuideContextType =
  | [
      firstTimeGuideStep: FirstTimeGuideStepType,
      handleSetFirstTimeGuideStep: (step: FirstTimeGuideStepType) => void
    ]
  | undefined

const getFirstTimeGuide = (): FirstTimeGuideStepType =>
  Cookies.get('fti_value') as FirstTimeGuideStepType

export function useFirstTimeGuideContext() {
  return useGuardedContext(FirstTimeGuideContext)
}

const FirstTimeGuideContext = createContext<FirstTimeGuideContextType>([undefined, () => {}])
FirstTimeGuideContext.displayName = 'FirstTimeGuideContext'

export function useShowFirstTimeGuide(): boolean {
  const [firstTimeGuideStep] = useFirstTimeGuideContext()
  return firstTimeGuideStep === undefined || firstTimeGuideStep !== 'done'
}

export const FirstTimeGuideContextProvider: React.FC<{
  children: ReactNode
}> = ({ children }) => {
  const [firstTimeGuideStep, setFirstTimeGuideStep] = useState<FirstTimeGuideStepType>(
    getFirstTimeGuide()
  )

  const handleSetFirstTimeGuideStep = (step: FirstTimeGuideStepType) => {
    setFirstTimeGuideStep(step)

    if (step) {
      Cookies.set('fti_value', step)
    }
  }

  return (
    <FirstTimeGuideContext.Provider value={[firstTimeGuideStep, handleSetFirstTimeGuideStep]}>
      {children}
    </FirstTimeGuideContext.Provider>
  )
}

const firstTimeGuideSessionName = 'fti_guide_session'

export const useGuide = () => {
  const [auth] = useContext(AuthSessionContext)
  const [isLoading, setIsLoading] = useState(false)
  const [guide, setGuide] = useState<ClientGuideDto | undefined>(undefined)
  const { product } = useProductReadContext()
  const authSessionId = auth?.sessionId
  const apiClient = useApiClient(ClientApiClient)

  const fetchGuide = async () => {
    const guide = await apiClient.getGuideByTypeId(GuideTypes.IntroductionGuide, {
      tickmillProductId: product,
    })
    setGuide(guide)
  }

  const markGuideAsRead = async () => {
    if (!authSessionId) return
    const cookieName = `${firstTimeGuideSessionName}_${product}`
    const guideSessionId = Cookies.get(cookieName)
    if (authSessionId === guideSessionId) return
    Cookies.set(cookieName, authSessionId)
    await apiClient.updateClientGuideRead(GuideTypes.IntroductionGuide, {
      tickmillProductId: product,
    })
  }

  const handleGuide = async () => {
    try {
      setIsLoading(true)
      if (!authSessionId) return
      await markGuideAsRead()
      await fetchGuide()
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleGuide()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authSessionId])

  return {
    guide,
    fetchGuide,
    markGuideAsRead,
    isLoading,
  }
}
