import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { PasswordIcon } from '../../icons/PasswordIcon'
import { WarningIcon } from '../../icons/WarningIcon'
import { NameDto } from '../../model/NameDto'
import { TradingAccountRestrictions, isRestricted } from '../../utils/trading-account.utils'

import styles from './ManagePasswordsModal.module.scss'

interface Props {
  onCancel: () => void
  setResetPassword: (visible: boolean) => void
  handleChangeTradingAccountPassword: () => void
  restrictions: NameDto[]
  setChangePasswordRestrictionMessageModal: () => void
  setResetPasswordRestrictionMessageModal: () => void
}

interface BodyProps {
  onCancel: () => void
  setResetPassword: (visible: boolean) => void
  handleChangeTradingAccountPassword: () => void
  restrictions: NameDto[]

  setChangePasswordRestrictionMessageModal: () => void
  setResetPasswordRestrictionMessageModal: () => void
}
const ManagePasswordsModalBody: React.FC<BodyProps> = ({
  onCancel,
  setResetPassword,
  handleChangeTradingAccountPassword,
  restrictions,
  setChangePasswordRestrictionMessageModal,
  setResetPasswordRestrictionMessageModal,
}) => {
  const hasResetMasterPasswordRestriction = isRestricted(
    TradingAccountRestrictions.RESET_MASTER_PASSWORD,
    restrictions
  )
  const hasChangeMasterPasswordRestriction = isRestricted(
    TradingAccountRestrictions.CHANGE_MASTER_PASSWORD,
    restrictions
  )
  const { t } = useTranslation()

  return (
    <section className={classNames(styles.body, 'modal-card-body')}>
      <ul>
        <li
          onClick={() => {
            if (hasChangeMasterPasswordRestriction) setChangePasswordRestrictionMessageModal()
            else handleChangeTradingAccountPassword()
            onCancel()
          }}
        >
          <div className={styles.wrapper}>
            <div>
              <PasswordIcon />
            </div>
            <div>{t('Trading Account.Change user id password')}</div>
            {hasChangeMasterPasswordRestriction && (
              <>
                <div className={styles.restricted}>{t('(Restricted)')}</div>
                <div className={styles.errorIcon}>
                  <WarningIcon size={24} color={'error'} />
                </div>
              </>
            )}
          </div>
        </li>
        <li
          onClick={() => {
            if (hasResetMasterPasswordRestriction) setResetPasswordRestrictionMessageModal()
            else setResetPassword(true)
            onCancel()
          }}
        >
          <div className={styles.wrapper}>
            <div>
              <PasswordIcon />
            </div>
            <div>{t('Trading Account.Reset user id password')}</div>
            {hasResetMasterPasswordRestriction && (
              <>
                <div className={styles.restricted}>{t('(Restricted)')}</div>
                <div className={styles.errorIcon}>
                  <WarningIcon size={24} color={'error'} />
                </div>
              </>
            )}
          </div>
        </li>
      </ul>
    </section>
  )
}

export const ManageTickmillTraderPasswordsModal: React.FC<Props> = ({
  onCancel,
  setResetPassword,
  handleChangeTradingAccountPassword,
  restrictions,
  setChangePasswordRestrictionMessageModal,
  setResetPasswordRestrictionMessageModal,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <header className='modal-card-head'>
        <p className='modal-card-title'>{t('Trading Account.Tickmill Trader Login Credentials')}</p>
      </header>
      <ManagePasswordsModalBody
        handleChangeTradingAccountPassword={handleChangeTradingAccountPassword}
        setResetPassword={setResetPassword}
        onCancel={onCancel}
        restrictions={restrictions}
        setChangePasswordRestrictionMessageModal={setChangePasswordRestrictionMessageModal}
        setResetPasswordRestrictionMessageModal={setResetPasswordRestrictionMessageModal}
      />
      <footer className='modal-card-foot'>
        <button className='button' onClick={onCancel}>
          {t('Cancel')}
        </button>
      </footer>
    </>
  )
}
