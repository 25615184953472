import React from 'react'

import IconButton from '../../../global/iconButton/IconButton'
import { DotsIcon } from '../../../icons/DotsIcon'
import { AssignedCommissionPlan } from '../../../model/MultiTierDto'

import styles from './MultiTierCards.module.scss'

interface MultiTierCommisionCardProps {
  data: AssignedCommissionPlan

  setActionsModal: React.Dispatch<React.SetStateAction<AssignedCommissionPlan | undefined>>
}

export const MultiTierCommisionCard: React.FC<MultiTierCommisionCardProps> = ({
  data,
  setActionsModal,
}) => {
  return (
    <div className={styles.card}>
      <header className={styles.cardHeader}>
        <span className={styles.cardTitle}>{data.name}</span>

        {!data.isDefault && (
          <IconButton onClick={() => setActionsModal(data)}>
            <DotsIcon />
          </IconButton>
        )}
      </header>
      {data.commissionPlans.map((plan) => (
        <div className={styles.title}>
          <span className={styles.label}>{plan.tradingAccountType.name}</span>
          <span className={styles.value}>{plan.amount}</span>
        </div>
      ))}
    </div>
  )
}
