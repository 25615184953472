import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Loading } from '../../global/Loading/Loading'
import { useProductReadContext } from '../../global/context/ProductContext'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { ConversionIcon } from '../../icons/ConversionIcon'
import { DepositIcon } from '../../icons/DepositIcon'
import { TransferIcon } from '../../icons/TransferIcon'
import { WithdrawIcon } from '../../icons/WithdrawIcon'
import { TotalWalletBalanceDto } from '../../model/TotalWalletBalanceDto'
import { FirstTimeGuideCarousel } from '../../ui/FirstTimeGuideCarousel/FirstTimeGuideCarousel'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { useApiClient } from '../../utils/ApiClient'
import { useShowFirstTimeGuide } from '../../utils/FirstTimeGuideContext'
import { ClientApiClient } from '../../utils/clientApi'
import { AppENV, enableByEnv } from '../../utils/config'
import { useFetchOne } from '../../utils/useFetch'
import { Card, Cards } from './Card'

import styles from './TransactionsPage.module.scss'

const useTransactionsFetch = () => {
  const { account } = useAccountReadContext()
  const apiClient = useApiClient(ClientApiClient)

  const callback = useCallback(async () => {
    if (account?.displayCurrency?.id) {
      return await apiClient.getTotalBalanceByCurrency(account.displayCurrency.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { data, isLoading } = useFetchOne(callback)

  return { data, isLoading }
}

const useTransactionsData = (data?: TotalWalletBalanceDto) => {
  const { t } = useTranslation()
  const { isDefaultCFDProductType } = useProductReadContext()
  const { formatMoney } = useFormatNumber()

  const balance = data?.currency?.id ? `≈${formatMoney(data.balance, data.currency.id)}` : ''

  const description = !isDefaultCFDProductType()
    ? t('Transactions.Wallets balances', {
        balance,
      })
    : undefined

  return {
    balance,
    description,
  }
}

export const TransactionsPage: React.FC = () => {
  const { t } = useTranslation()
  const showFirstTimeGuid = useShowFirstTimeGuide()
  const { isDefaultCFDProductType } = useProductReadContext()

  return (
    <div className={styles.wrapper}>
      {showFirstTimeGuid && <FirstTimeGuideCarousel />}
      <PageHeader title={t('Transactions.Transactions')} />
      {isDefaultCFDProductType() && <TransactionsCardsCFD />}
      {!isDefaultCFDProductType() && <TransactionsCardsETD />}
    </div>
  )
}

const TransactionsCardsCFD = () => {
  const { t } = useTranslation()

  const { data, isLoading } = useTransactionsFetch()
  const { description } = useTransactionsData(data)

  return (
    <Loading showLoadingIcon isLoading={isLoading}>
      <Cards>
        <Card
          title={t('Transactions.Deposit')}
          renderDescription={() => (
            <p className={styles.descriptionCFD}>{t('Transactions.Move Funds')}</p>
          )}
          buttonText={t('Transactions.Start Deposit')}
          appearance='primary'
          to='/dashboard/traders-room/wallets/deposit'
          additionalDescription={description}
          icon={<DepositIcon size={80} />}
        />
        <Card
          title={t('Transactions.Withdraw')}
          renderDescription={() => (
            <p className={styles.descriptionCFD}>{t('Transactions.Move funds wallet bank')}</p>
          )}
          buttonText={t('Transactions.Start Withdrawal')}
          appearance='secondary'
          to='/dashboard/traders-room/wallets/withdrawal'
          additionalDescription={description}
          icon={<WithdrawIcon size={80} />}
        />
        <Card
          title={t('Transactions.Transfer Funds')}
          renderDescription={() => (
            <p className={styles.descriptionCFD}>{t('Transactions.Move funds between')}</p>
          )}
          buttonText={t('Transactions.Start Transfer')}
          appearance='secondary'
          to='/dashboard/traders-room/wallets/transfer'
          icon={<TransferIcon size={80} />}
        />
      </Cards>
    </Loading>
  )
}

const TransactionsCardsETD = () => {
  const { t } = useTranslation()

  const { data, isLoading } = useTransactionsFetch()
  const { description } = useTransactionsData(data)

  return (
    <Loading showLoadingIcon isLoading={isLoading}>
      <Cards>
        <Card
          title={t('Transactions.Deposit')}
          renderDescription={() => (
            <p className={styles.descriptionETD}>{t('Transactions.Move Funds')}</p>
          )}
          buttonText={t('Transactions.Start Deposit')}
          appearance='primary'
          to='/dashboard/traders-room/wallets/deposit'
          additionalDescription={description}
          icon={<DepositIcon size={80} />}
        />
        <Card
          title={t('Transactions.Withdraw')}
          renderDescription={() => (
            <p className={styles.descriptionETD}>{t('Transactions.Move funds wallet bank')}</p>
          )}
          buttonText={t('Transactions.Start Withdrawal')}
          appearance='secondary'
          to='/dashboard/traders-room/wallets/withdrawal'
          additionalDescription={description}
          icon={<WithdrawIcon size={80} />}
        />
        <Card
          title={t('Transactions.Transfer Funds')}
          renderDescription={() => (
            <p className={styles.descriptionETD}>{t('Transactions.Move funds between')}</p>
          )}
          buttonText={t('Transactions.Start Transfer')}
          appearance='secondary'
          to='/dashboard/traders-room/wallets/transfer'
          icon={<TransferIcon size={80} />}
        />
        <Card
          title={t('Transactions.Conversion')}
          renderDescription={() => (
            <p className={styles.descriptionETD}>{t('Transactions.Convert currencies')}</p>
          )}
          buttonText={t('Transactions.Start Conversion')}
          appearance='secondary'
          to='/dashboard/traders-room/transactions/conversion'
          icon={<ConversionIcon size={80} />}
        />
      </Cards>
    </Loading>
  )
}
