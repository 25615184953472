import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { TickmillLoadingIcon } from '../../icons/TickmillLoadingIcon'
import { LoadingProps } from './Loading'

import styles from './Loading.module.scss'

export const LoadingOverlay: React.FC<PropsWithChildren<LoadingProps>> = (props) => {
  const { text, showLoadingIcon, isModal, className, children } = props
  const { t } = useTranslation()

  return (
    <div className={styles.overlay}>
      <div
        className={classNames(styles.wrapper, {
          [styles.isModal]: isModal,
        })}
      >
        {showLoadingIcon && (
          <div className={styles.icon}>
            <TickmillLoadingIcon />
          </div>
        )}
        <p className={className}>{text ?? t('Loading...')}</p>
      </div>
      {children}
    </div>
  )
}
