import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../button/Button'
import { CancelActionModal } from './CancleActionModal'

interface InformationModalProps {
  title?: string
  onCancel(): void
  onBack?(): void
  spacing?: 'regular' | 'compact'
  buttonText?: string
  className?: string
}

export const InformationModal: React.FC<PropsWithChildren<InformationModalProps>> = (props) => {
  const { t } = useTranslation()
  const {
    onCancel,
    children,
    title,
    spacing = 'regular',
    buttonText = t('Okay') + '!',
    onBack,
    className,
  } = props

  return (
    <CancelActionModal
      title={title}
      spacing={spacing}
      footer={
        <Button appearance='plain' onClick={onCancel}>
          {buttonText}
        </Button>
      }
      onBack={onBack}
      className={className}
    >
      {children}
    </CancelActionModal>
  )
}
