import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { CheckOutlinedIcon } from '../../icons/CheckOutlinedIcon'

import styles from './TransactionSuccessDialogueTemplate.module.scss'

interface TransactionSuccessDialogueTemplateProps {
  title: string
  goBack?(): void
}

export const TransactionSuccessDialogueTemplate: React.FunctionComponent<
  PropsWithChildren<TransactionSuccessDialogueTemplateProps>
> = ({ children, title, goBack = () => {} }) => {
  const { t } = useTranslation()

  return (
    <div className={classNames(styles.wrapper)}>
      <div className={classNames(styles.box)}>
        <div>
          <div className={classNames(styles.header)}>
            <div className={classNames(styles.contentIcon)}>
              <CheckOutlinedIcon />
            </div>
            <div>
              <h3 className='has-text-centered'>{title}</h3>
            </div>
          </div>
          <div className={styles.content}>{children}</div>
        </div>
        {goBack && (
          <footer className={classNames(styles.footer)}>
            <button onClick={goBack}>{t('OK')}</button>
          </footer>
        )}
      </div>
    </div>
  )
}
