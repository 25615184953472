import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { CartIcon } from '../icons/CartIcon'
import { TextStrong } from '../ui/Typography/Typography'
import { SubscriptionMobileTabs } from './SubscriptionMobileTabs'

import styles from './SubscriptionsETD.module.scss'

interface SubscriptionsETDTemplateProps {
  subscriptionsHeader: React.ReactNode
  thirdPartySubscriptionsHeader?: React.ReactNode
  subscriptionsTable: React.ReactNode
  thirdPartyTradingSubscriptionsTable?: React.ReactNode
  sidebar: React.ReactNode[]
  isCartEmpty: boolean
  hasSubscriptions: boolean
  isCartItemsLoading: boolean
}

export const SubscriptionsETDTemplate: React.FC<SubscriptionsETDTemplateProps> = ({
  thirdPartySubscriptionsHeader,
  thirdPartyTradingSubscriptionsTable: thirdPartyTradingSubcriptionsTable,
  subscriptionsHeader,
  subscriptionsTable,
  sidebar,
  isCartEmpty,
  hasSubscriptions,
  isCartItemsLoading,
}) => {
  const { t } = useTranslation()
  return (
    <div className={styles.subscriptionsBox}>
      <div className={styles.subscriptionsTemplateWrapper}>
        <div className='is-hidden-desktop is-hidden-tablet mb-2'>
          <SubscriptionMobileTabs />
        </div>
        <div>
          <div>{subscriptionsHeader}</div>
          <div>{subscriptionsTable}</div>
        </div>

        <div>
          {thirdPartySubscriptionsHeader && <div>{thirdPartySubscriptionsHeader}</div>}
          {thirdPartyTradingSubcriptionsTable && <div>{thirdPartyTradingSubcriptionsTable}</div>}
        </div>
      </div>

      <div className={styles.sidebarWrapper}>
        {!isCartItemsLoading && !hasSubscriptions && (
          <div className={classNames(styles.warning, styles.sidebar, 'mb-4')}>
            <Trans i18nKey='Subscriptions.Side Bar Warnings'>
              <TextStrong className={styles.important}>WARNING!</TextStrong> subscription to a{' '}
              <strong>trading platform</strong> and <strong>market data</strong>
            </Trans>
          </div>
        )}

        {isCartEmpty ? (
          <div className='is-hidden-mobile'>
            <div className={styles.sidebar}>
              <h3>{t('Subscriptions.Cart')}</h3>
              <div className='has-text-centered'>
                <div className='my-4'>
                  <CartIcon color={'contrastMediumLow'} />
                </div>
                <p className='text'>{t('Subscriptions.Subscriptions cart is empty')}</p>
              </div>
            </div>
          </div>
        ) : (
          sidebar.map((component, index) => (
            <div key={index} className={styles.sidebar}>
              {component}
            </div>
          ))
        )}
      </div>
    </div>
  )
}
