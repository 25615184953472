import React, { SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { Form, FormikErrors, FormikProps, withFormik } from 'formik'
import { t } from 'i18next'

import { Button } from '../global/button/Button'
import { createFormField } from '../global/formField/FormField'
import { TextSmall } from '../ui/Typography/Typography'
import {
  TickmillCompaniesEnum,
  getTickmillPublicDomain,
  isTickmillPartnerType,
} from '../utils/companyName.utils'
import { FormSubmitValues } from '../utils/formValidation'

import styles from './ForgotPasswordPage.module.scss'

export interface ForgotPasswordFormValues {
  email: string
  communicationLanguageId: string
  tickmillPublicDomain: string
}

const EmailField = createFormField<ForgotPasswordFormValues>()

const ForgotPasswordFormUI: React.FC<FormikProps<ForgotPasswordFormValues> & OuterProps> = (
  props
) => {
  const { handleSubmit, setSubmitting, isValid } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const handleSubmitForm = (event: SyntheticEvent) => {
    event.preventDefault()
    setSubmitting(true)
    handleSubmit()
  }

  return (
    <Form className={styles.form} onSubmit={handleSubmitForm}>
      <EmailField
        type='email'
        label={t('Email Address')}
        placeholder={t('Email Address')}
        name='email'
      />
      <div className={styles.buttonBox}>
        <Button
          className={styles.button}
          type='button'
          onClick={() => navigate('/login')}
          appearance='secondary'
          size='L'
        >
          {t('Cancel')}
        </Button>
        <Button
          disabled={!isValid}
          className={styles.button}
          type='submit'
          appearance='primary'
          size='L'
        >
          {t('Reset')}
        </Button>
      </div>
      {!isTickmillPartnerType(props.entity) && (
        <TextSmall className={styles.signup}>
          {t('Not a member')}
          <Link to='/sign-up'>
            <button type='button' className={classNames('is-link', 'has-cursor-pointer')}>
              {t('Sign up.Sign up')}
            </button>
          </Link>
        </TextSmall>
      )}
    </Form>
  )
}

interface OuterProps {
  entity: TickmillCompaniesEnum
  email?: string
  onSubmit(values: FormSubmitValues<ForgotPasswordFormValues>): Promise<void>
}

export const ForgotPasswordForm = withFormik<OuterProps, ForgotPasswordFormValues>({
  mapPropsToValues: (props) => {
    return {
      email: props?.email || '',
      communicationLanguageId: 'en',
      tickmillPublicDomain: getTickmillPublicDomain(),
    }
  },

  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit(values)
    } finally {
      setSubmitting(false)
    }
  },
  validate: (values) => {
    const errors: FormikErrors<ForgotPasswordFormValues> = {}

    if (!values.email) {
      errors.email = t('Validation.Required')
    }
    return errors
  },
  enableReinitialize: true,
})(ForgotPasswordFormUI)
