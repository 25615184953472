import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { InformationModal } from '../../global/modal/InformationModal'
import { Modal } from '../../global/modal/Modal'
import { SubscriptionDetail } from '../../model/SubscriptionTypes'
import {
  SubscriptionRequestType,
  SubscriptionTimeInForce,
  UpdateSubscriptionRequest,
} from '../../model/UpdateSubscriptionRequest'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { TextTiny } from '../../ui/Typography/Typography'
import { PropertyDetailResponseType, ResponseError, useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useWindowResize } from '../../utils/domUtils'
import { useFetchOne } from '../../utils/useFetch'
import { isZero } from '../../utils/validations'
import { Cart } from '../Cart'
import { HowToUseLink } from '../HowToUseLink'
import { PaymentDoneModal } from '../PaymentDoneModal'
import { SubscriptionsETDTemplate } from '../SubscriptionsETDTemplate'
import useCartItemsData from '../hooks/useCartItemsData'
import { SubscriptionContent } from './SubscriptionContent'
import { UnsubscribeOpenPositionModal } from './UnsubscribeConfirmationModal'
import { getSubscribedBundles } from './subscriptionUtils'

import subscriptionsStyles from '../SubscriptionsETD.module.scss'
import styles from './MarketDataPage.module.scss'

export const MarketDataPage: React.FC = () => {
  const { t } = useTranslation()
  const clientApiClient = useApiClient(ClientApiClient)
  const [counter, setCounter] = useState(0)
  const isMobile = useWindowResize()
  const [paymentDoneModalVisible, setPaymentDoneModalVisible] = useState(false)
  const [isMutuallyExclusiveModal, setIsMutuallyExclusiveModal] = useState<
    Record<string, PropertyDetailResponseType> | undefined
  >(undefined)
  const [isAlreadySubscribedModal, setIsAlreadySubscribedModal] = useState<
    Record<string, PropertyDetailResponseType> | undefined
  >(undefined)

  const [subscriptionWithOpenPosition, setSubscriptionWithOpenPosition] = useState<
    SubscriptionDetail | undefined
  >()

  const subscriptionBundlesCallback = useCallback(() => {
    return clientApiClient.getSubscriptionBundles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter])

  const { data: subscriptionBundles = [], isLoading: subscriptionBundlesLoading } = useFetchOne(
    subscriptionBundlesCallback
  )

  const subscriptionGroupCallback = useCallback(() => {
    return clientApiClient.getSubscriptionGroups()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter])

  const { data: subscriptionGroups = [], isLoading: subscriptionGroupsLoading } =
    useFetchOne(subscriptionGroupCallback)

  const {
    isExpanded,
    setIsExpanded,
    scrollToCart,
    pendingUnsubscriptions,
    noneActiveSubscriptions,
    activeSubscriptions,
    forceRefreshCartItems,
    pendingUnsubscriptionsData,
    isPendingUnsubscriptionsLoading,
    isActiveSubscriptionsLoading,
    isNoneActiveSubscriptionsLoading,
    isTradingAccountsLoading,
    availableToWithdraw,
    hasSubscriptions,
    upgradeOrDowngradeSubscriptionId,
  } = useCartItemsData()

  const handleOnUpdateSubscription = async (
    data: UpdateSubscriptionRequest,
    selectedItem?: SubscriptionDetail
  ) => {
    try {
      await clientApiClient.updateSubscriptionRequest(data)
      forceRefreshCartItems()
      setCounter((prev) => prev + 1)
    } catch (error: unknown) {
      if (
        error instanceof ResponseError &&
        error.response.response?.data.code === 'trading_account_has_open_positions'
      ) {
        return setSubscriptionWithOpenPosition(selectedItem)
      }

      if (
        error instanceof ResponseError &&
        error.response.response?.data.code === 'subscription_detail_is_excluded'
      ) {
        return setIsMutuallyExclusiveModal(error.response.response.data?.properties)
      }

      if (
        error instanceof ResponseError &&
        error.response.response?.data.code === 'subscription_detail_is_excluded_by_bundle'
      ) {
        return setIsAlreadySubscribedModal(error.response.response.data?.properties)
      }
    }
  }

  const handleOnClickUnsubscribeWithOpenPosition = (): void => {
    handleOnUpdateSubscription(
      {
        subscriptions: [
          {
            subscriptionDetailId: subscriptionWithOpenPosition?.id,
            subscriptionRequestTypeId: SubscriptionRequestType.Cancel,
          },
        ],
        overrideOpenPositionsForCancel: true,
        subscriptionTimeInForceId: SubscriptionTimeInForce.BeginningOfNextMonth,
      },
      subscriptionWithOpenPosition
    )

    setSubscriptionWithOpenPosition(undefined)
  }

  const subscribedBundles = getSubscribedBundles(subscriptionBundles)

  const handleOnClickPay = async () => {
    const noneActiveSubscriptionsMapped = noneActiveSubscriptions?.map((item) => ({
      subscriptionDetailId: item.subscriptionDetail?.id || '',
      subscriptionRequestTypeId: item.subscriptionRequestType.id,
    }))

    await clientApiClient.updateSubscriptionRequest({
      subscriptions: noneActiveSubscriptionsMapped,
      subscriptionTimeInForceId: SubscriptionTimeInForce.Immediate,
    })

    setCounter((prev) => prev + 1)
    forceRefreshCartItems()
    setPaymentDoneModalVisible(true)
  }

  const handleOnRemoveCartItem = async (id: string) => {
    await clientApiClient.deleteSubscriptionCartItem(id)
    setCounter((prev) => prev + 1)
    forceRefreshCartItems()
  }
  const handleOnAddCartItem = async (
    subscriptionDetailId: string,
    subscriptionRequestTypeId: SubscriptionRequestType
  ) => {
    try {
      await clientApiClient.addSubscriptionItemToCart({
        subscriptionDetailId,
        subscriptionTimeInForceId: SubscriptionTimeInForce.Immediate,
        subscriptionRequestTypeId,
      })

      forceRefreshCartItems()
    } catch (error: unknown) {
      if (
        error instanceof ResponseError &&
        error.response.response?.data.code === 'subscription_detail_is_excluded'
      ) {
        return setIsMutuallyExclusiveModal(error.response.response.data?.properties)
      }

      if (
        error instanceof ResponseError &&
        error.response.response?.data.code === 'subscription_detail_is_excluded_by_bundle'
      ) {
        return setIsAlreadySubscribedModal(error.response.response.data?.properties)
      }
    }
  }

  const isCartEmpty = isZero(noneActiveSubscriptions?.length) && isZero(activeSubscriptions?.length)

  const isCartItemsLoading =
    isTradingAccountsLoading ||
    isNoneActiveSubscriptionsLoading ||
    isActiveSubscriptionsLoading ||
    isPendingUnsubscriptionsLoading

  return (
    <>
      {isAlreadySubscribedModal && (
        <Modal
          closeModal={() => setIsAlreadySubscribedModal(undefined)}
          render={({ closeModal }) => (
            <InformationModal
              title={t('Subscriptions.Already Subscribed')}
              onCancel={closeModal}
              buttonText={t('Got it!')}
            >
              <p className='text'>
                {t('Subscriptions.Bundle is is already subscribed', {
                  parent: isAlreadySubscribedModal?.BundleParentSubscriptionDetail?.Name,
                  child: isAlreadySubscribedModal?.BundleChildSubscriptionDetail?.Name,
                })}
              </p>
            </InformationModal>
          )}
        />
      )}

      {isMutuallyExclusiveModal && (
        <Modal
          closeModal={() => setIsMutuallyExclusiveModal(undefined)}
          render={({ closeModal }) => (
            <InformationModal
              title={t('Subscriptions.Already Subscribed')}
              onCancel={closeModal}
              buttonText={t('Got it!')}
            >
              <p className='text'>
                {t('Subscriptions.Bundle is mutually exclusive', {
                  parent: isMutuallyExclusiveModal?.ExcludedBySubscriptionDetail?.Name,
                  child: isMutuallyExclusiveModal?.SubscriptionDetail?.Name,
                })}
              </p>
            </InformationModal>
          )}
        />
      )}
      {paymentDoneModalVisible && (
        <Modal
          cardClassName={subscriptionsStyles.paymentModal}
          size='xsmall'
          closeModal={() => setPaymentDoneModalVisible(false)}
          render={() => <PaymentDoneModal onCancel={() => setPaymentDoneModalVisible(false)} />}
        />
      )}

      {subscriptionWithOpenPosition && (
        <UnsubscribeOpenPositionModal
          onClickUnsubscribe={handleOnClickUnsubscribeWithOpenPosition}
          setUnsubscribeOpenPositionModal={() => setSubscriptionWithOpenPosition(undefined)}
        />
      )}

      <SubscriptionsETDTemplate
        subscriptionsHeader={<MarketDataPageHeader />}
        subscriptionsTable={
          <div className='mt-5'>
            <h4 className='mb-4'>{t('Market Data.Top Selling Bundles')}</h4>
            <div className='is-hidden-mobile px-4'>
              <div className={classNames('pb-2', styles.header)}>
                <div className={styles.name}>
                  <TextTiny className={styles.headerTextStyles}>
                    {t('Market Data.Product')}
                  </TextTiny>
                </div>
                <div className={styles.price}>
                  <TextTiny className={styles.headerTextStyles}>
                    {' '}
                    {t('Market Data.Price')}{' '}
                  </TextTiny>
                </div>
                <div className={styles.status}>
                  <TextTiny className={styles.headerTextStyles}>{t('Market Data.Status')}</TextTiny>
                </div>
              </div>
            </div>

            <SubscriptionContent
              subscriptionBundlesLoading={subscriptionBundlesLoading}
              subscriptionGroupsLoading={subscriptionGroupsLoading}
              scrollToCart={scrollToCart}
              subscribedBundles={subscribedBundles}
              subscriptionGroups={subscriptionGroups}
              subscriptionBundles={subscriptionBundles}
              onUpdateSubscription={handleOnUpdateSubscription}
              onAddCartItem={handleOnAddCartItem}
              nonActiveSubscriptions={noneActiveSubscriptions}
            />
          </div>
        }
        hasSubscriptions={hasSubscriptions}
        isCartItemsLoading={isCartItemsLoading}
        isCartEmpty={isMobile ? isCartEmpty : isCartEmpty && !isCartItemsLoading}
        sidebar={[
          <Cart
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            cartItemsIsLoading={isCartItemsLoading}
            handleOnUpdateSubscription={handleOnUpdateSubscription}
            handleOnClickPay={handleOnClickPay}
            onRemoveCartItem={handleOnRemoveCartItem}
            hasSubscriptions={hasSubscriptions}
            activeSubscriptions={activeSubscriptions}
            hasPendingUnsubscriptionRequest={pendingUnsubscriptions?.itemsCount > 0}
            pendingUnsubscriptionsData={pendingUnsubscriptionsData}
            noneActiveSubscriptions={noneActiveSubscriptions}
            availableToWithdraw={availableToWithdraw}
            upgradeOrDowngradeSubscriptionId={upgradeOrDowngradeSubscriptionId}
          />,
        ]}
      />
    </>
  )
}

const MarketDataPageHeader = () => {
  const { t } = useTranslation()

  const title = t('Market Data.How to use Market data')
  const path = '/dashboard/subscriptions/market-data/why-market-data'

  return (
    <PageHeader
      renderTitle={() => (
        <>
          <h3 className='is-hidden-mobile'>{t('Market Data.title')}</h3>
          <HowToUseLink hideOn='is-hidden-desktop is-hidden-tablet' path={path} title={title} />
        </>
      )}
      rightRender={<HowToUseLink hideOn='is-hidden-mobile' title={title} path={path} />}
    />
  )
}
