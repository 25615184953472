import { TFunction } from 'i18next'

import { OptionSplitter } from '../global/modal/OptionSelectModal'
import {
  SelectOption,
  getDayOptions,
  getDaysFromDateStrings,
  getMonthOptions,
  getMonthsFromDateStrings,
} from './date.utils'

export interface AvailableStatementDates {
  lockMonths?: boolean
  availableMonthOptions: SelectOption[]
  availableDayOptions: {
    month: number
    days: SelectOption[]
  }[]
}

export const getAvailableMonthOptions = (t: TFunction, months: number[]): SelectOption[] => {
  const monthOptions = getMonthOptions(t)
  return monthOptions.map((option) =>
    months.includes(option.id) ? { ...option, disabled: false } : { ...option, disabled: true }
  )
}

export const getAvailableDayOptions = (month: number, days: number[]): SelectOption[] => {
  const dayOptions = getDayOptions(month)
  return dayOptions.map((option) =>
    days.includes(option.id) ? { ...option, disabled: false } : { ...option, disabled: true }
  )
}

export const getAvailableDates = (t: TFunction, dates: string[]): AvailableStatementDates => {
  const availableMonths = getMonthsFromDateStrings(dates)
  const availableMonthOptions = getAvailableMonthOptions(t, availableMonths).filter(
    (month) => !month.disabled
  )
  const availableDayOptions = availableMonths
    .map((month) => ({
      month: month,
      days: getAvailableDayOptions(month, getDaysFromDateStrings(dates, month)).filter(
        (day) => !day.disabled
      ),
    }))
    .filter((month) => month.days.some((day) => !day.disabled))
  return {
    availableMonthOptions,
    availableDayOptions,
    lockMonths: !availableMonthOptions.some((month) => !month.disabled),
  }
}

export const getYearSplitter = (dateList: SelectOption[]): OptionSplitter[] => {
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth() + 1
  const currentYear = currentDate.getFullYear()
  const nonDisabledMonths = dateList.filter((date) => !date.disabled)
  if (!nonDisabledMonths.some((date) => date.id > currentMonth)) return []
  const response = dateList.flatMap((date, idx) => {
    if (date.id <= currentMonth) return [{ value: currentYear.toString(), index: idx }]
    if (date.id > currentMonth) return [{ value: (currentYear - 1).toString(), index: idx }]
    return []
  })
  const uniqueValues = new Map()
  const filteredData = response.filter((obj) => {
    const { value, index } = obj
    if (!uniqueValues.has(value)) {
      uniqueValues.set(value, index)
      return true
    }
    const existingIndex = uniqueValues.get(value)
    return index < existingIndex
  })

  return filteredData
}
