import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'

import {
  hasProductAppropriatenessTestSubmittedStatus,
  hasProductEmailVerifiedStatus,
  hasProductPendingDocumentVerificationStatus,
} from '../../model/AccountDetailedDto'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { useWindowResize } from '../../utils/domUtils'
import { getPath, isNoTabsPath } from '../../utils/path'
import { useProductReadContext } from '../context/ProductContext'
import { Tabs } from '../tabs/Tabs'
import { getMobileTabs, tabMapping } from './getMobileTabs'

import styles from './MobileTabs.module.scss'

export const MobileTabs: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const isMobile = useWindowResize()

  const { account } = useAccountReadContext()
  const { t } = useTranslation()
  const { isDefaultCFDProductType, product } = useProductReadContext()
  const isProductTypeCFD = isDefaultCFDProductType()

  const isAccountPending =
    hasProductAppropriatenessTestSubmittedStatus(account, product) ||
    hasProductPendingDocumentVerificationStatus(account, product) ||
    hasProductEmailVerifiedStatus(account, product)

  const [currentTab, setCurrentTab] = useState<string>('')

  const isTraderRoomVisible = account?.companyConfiguration?.hasTraderRoom ?? false

  const tabs = useMemo(
    () =>
      getMobileTabs({
        isAccountPending,
        isTraderRoomVisible,
        t,
        isProductTypeCFD,
      }),
    [isAccountPending, isTraderRoomVisible, isProductTypeCFD, t]
  )

  useEffect(() => {
    const path = getPath(location.pathname) ?? {}
    const tab = tabMapping(t, isProductTypeCFD).find(
      (x) => x.main === path.main && x.sub === path.sub && x.tab === path.tab
    )
    setCurrentTab(tab?.id || '')
  }, [location.pathname, t, isProductTypeCFD])

  if (!isMobile) {
    return null
  }

  if (
    isMobile &&
    ['/sign-up', '/register2/:leadId'].some((path) => matchPath({ path }, location.pathname))
  ) {
    return null
  }

  if (
    !account ||
    tabs.length < 2 ||
    isNoTabsPath(location.pathname, [
      '/introducing-broker',
      '/statements',
      '/users/register',
      '/profile',
      '/payment-agent',
      '/trading-accounts/new',
      '/trading-accounts/new-demo',
      '/dashboard/closed-account',
      // '/dashboard/inactive-account',
      '/dashboard/legal-documents',
      '/dashboard/take-app-test',
      '/dashboard/product-registration/appropriateness-test',
    ])
  ) {
    return <div className={styles.noTabs} />
  }

  return (
    <Tabs
      wrapperClassName={styles.tabsWrapper}
      tabs={tabs}
      currentTab={currentTab}
      onTabChanged={(tab) => {
        const path = tabs.find((x) => x.id === tab)
        if (path) {
          return navigate(`/dashboard/${path.path}`)
        }
        return navigate(`/dashboard/${tab}`)
      }}
    />
  )
}
