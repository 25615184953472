import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { Button } from '../../../../global/button/Button'
import { FieldGroup } from '../../../../global/form/FieldGroup'
import { createFormField } from '../../../../global/formField/FormField'
import { PaymentProviderProceedModal } from '../../../../global/modal/PaymentProviderProceedModal'
import { TransactionFormActionButtonTemplate } from '../../../../global/templates/TransactionFormActionButtonTemplate'
import { DropArrowDownIcon } from '../../../../icons/DropArrowDownIcon'
import {
  isPaymentProviderBankType,
  isPaymentProviderCardProviderType,
  isPaymentProviderPSPType,
  isPaymentProviderPaymentAgentType,
} from '../../../../model/PaymentProviderType'
import { WalletPaymentProviderCurrency } from '../../../../model/WalletPaymentProviderParameters'
import { DepositFormWarningNote } from '../../../DepositFormWarningNote'
import { WalletDepositCurrencyModal } from '../../../Wallets/WalletDeposit/WalletDepositCurrencyModal'
import { CurrencyAmountChip } from '../../CurrencyAmountChip'
import { CurrencyAmountField } from './CurrencyAmountField'
import { FormTermsConditionsPSP } from './FormTermsConditionsFactory'
import { TradingAccountDepositFormValues } from './TradingAccountDepositForm'
import { TradingAccountFormFieldsFactory } from './TradingAccountFormFieldsFactory'

import styles from './TradingAccountDepositPSPFields.module.scss'

const FormField = createFormField<TradingAccountDepositFormValues>()

interface TradingAccountDepositPSPFieldsProps {
  onCancel(): void
  onClickTermsConditions(): void
}

export const TradingAccountDepositPSPFields: React.FC<TradingAccountDepositPSPFieldsProps> = (
  props
) => {
  const { onCancel, onClickTermsConditions } = props

  const { values, setValues, isValid, handleSubmit } =
    useFormikContext<TradingAccountDepositFormValues>()

  const { values: paymentProviderFormValues } = useFormikContext<TradingAccountDepositFormValues>()

  const paymentProvider = paymentProviderFormValues?.paymentProvider

  const [isCurrencyModalOpen, setCurrencyModalOpen] = useState(false)

  const [isPaymentProviderProceedModalOpen, setPaymentProviderProceedModalOpen] = useState(false)

  const handleExitPageConfirmationModalOpen = () => {
    onCancel()
  }

  const handleSubmitForm = () => {
    handleSubmit()
  }

  const handlePaymentProviderProceed = () => {
    if (isPaymentProviderPSPType(values.paymentProvider.providerCategory.id)) {
      setPaymentProviderProceedModalOpen(true)
    }

    if (isPaymentProviderCardProviderType(values.paymentProvider.providerCategory.id)) {
      setPaymentProviderProceedModalOpen(true)
    }

    if (isPaymentProviderBankType(values.paymentProvider.providerCategory.id)) {
      handleSubmit()
    }

    if (isPaymentProviderPaymentAgentType(values.paymentProvider.providerCategory.id)) {
      handleSubmit()
    }
  }

  const handlePaymentProviderProceedModalClose = () => {
    setPaymentProviderProceedModalOpen(false)
  }

  const handleCurrencyModalOpen = () => {
    setCurrencyModalOpen(true)
  }

  const handleCurrency = (walletPaymentProviderCurrency: WalletPaymentProviderCurrency) => {
    setValues({
      ...values,
      paymentProvider: {
        ...values.paymentProvider,
        currency: walletPaymentProviderCurrency,
      },
    })
    setCurrencyModalOpen(false)
  }

  const handleCurrencyModalClose = () => {
    setCurrencyModalOpen(false)
  }

  const disabled =
    values.paymentProvider?.parameters && !(values.paymentProvider.parameters.currencies.length > 1)

  const { t } = useTranslation()

  return (
    <React.Fragment>
      {values.paymentProvider.id && (
        <React.Fragment>
          {isCurrencyModalOpen && (
            <WalletDepositCurrencyModal
              onSelectOption={handleCurrency}
              onClose={handleCurrencyModalClose}
            />
          )}
          {isPaymentProviderProceedModalOpen && (
            <PaymentProviderProceedModal
              onProceed={handleSubmitForm}
              onClose={handlePaymentProviderProceedModalClose}
            />
          )}

          <TradingAccountFormFieldsFactory />

          <FieldGroup>
            <CurrencyAmountField />
            <div className={styles.currencyFieldWrapper}>
              <FormField
                name='paymentProvider.currency.id'
                label={t('Currency')}
                disabled={disabled}
                rightIcon={!disabled && <DropArrowDownIcon />}
                onClick={handleCurrencyModalOpen}
              />
            </div>
          </FieldGroup>

          <FieldGroup>
            <CurrencyAmountChip minValue={paymentProvider?.currency?.minAmount} />
          </FieldGroup>

          <FormField
            name='comment'
            label={t('Wallet.Additional Comment')}
            placeholder={t('Wallet.Additional Comment')}
          />

          <DepositFormWarningNote paymentProvider={paymentProvider} />

          <div className='my-5'>
            <FormTermsConditionsPSP onClickTermsConditions={onClickTermsConditions} />
          </div>

          <TransactionFormActionButtonTemplate>
            <Button
              appearance='secondary'
              size='L'
              type='button'
              onClick={handleExitPageConfirmationModalOpen}
            >
              {t('Cancel')}
            </Button>
            <Button
              appearance='primary'
              size='L'
              type='button'
              onClick={handlePaymentProviderProceed}
              disabled={!isValid}
            >
              {t('Confirm')}
            </Button>
          </TransactionFormActionButtonTemplate>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
