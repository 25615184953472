import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikErrors, FormikProps, useFormikContext, withFormik } from 'formik'
import { t } from 'i18next'

import { DocumentMarketDataClassifications } from '../../../ProductRegistration/LegalDocuments/DocumentMarketDataClassifications'
import {
  ReadDocumentMarketDataPage,
  useDocumentMarketDataIdState,
} from '../../../ProductRegistration/LegalDocuments/ReadDocumentMarketDataPage'
import { ExternalLink } from '../../../global/ExternalLink'
import { Button } from '../../../global/button/Button'
import { useSessionEntity } from '../../../global/context/EntityContext'
import { usePublicDomainsReadContext } from '../../../global/context/PublicDomainsContext'
import { useHomePageUrl } from '../../../hooks/useHomePageUrl'
import { LegalDocumentDto } from '../../../model/CompanyLegalDocumentDto'
import { LeadDto, isLeadTypeCorporate, isLeadTypeIndividual } from '../../../model/LeadDto'
import { NameDto } from '../../../model/NameDto'
import { isTickmillProductTypeETD } from '../../../model/TickmillProductType'
import { Text } from '../../../ui/Typography/Typography'
import { isTickmillPartnerType, isTickmillUKType } from '../../../utils/companyName.utils'
import { ReadDocumentPage } from '../PersonalInfoPage/DocumentReadAndAgreeField'
import {
  AgreedLegalDocument,
  FormValuesLegalDocs,
} from '../PersonalInfoPage/PersonalInfoDocumentsFactory'
import {
  SignUpRegisterTemplate,
  SignUpRegisterTemplateProps,
} from '../SignUpRegisterPage/SignUpRegisterTemplate'
import { DocumentGroupsFactory } from './DocumentGroupsFactory'
import { DocumentItemFactory } from './DocumentItemFactory'

import styles from '../PersonalInfoStep3Page/PersonalInfoStep3Form.module.scss'

interface ReadDocumentMarketData {
  marketDataClassificationId: number
  isDocumentMarketDataRead: boolean
  isDocumentMarketDataAccepted: boolean
}

const PersonalInfoStep6DocumentsFormUI: React.FC<FormikProps<FormValuesLegalDocs> & OuterProps> = (
  props
) => {
  const {
    documents,
    groupReferences,
    products,
    subStep,
    isLoading,
    lead,
    shouldReceiveNewsletter,
  } = props
  const { handleSubmit, onStepChange, onSubmit } = props

  useEffect(() => {
    if (!documents.length) handleSubmit()
  }, [documents.length, handleSubmit])

  return (
    <Form className={styles.form} onSubmit={handleSubmit}>
      <FormDocumentsFactory
        documents={documents}
        groupReferences={groupReferences}
        products={products}
        onStepChange={onStepChange}
        subStep={subStep}
        isLoading={isLoading}
        lead={lead}
        shouldReceiveNewsletter={shouldReceiveNewsletter}
        onSubmit={onSubmit}
      />
    </Form>
  )
}

type FieldOuterProps = Omit<OuterProps, 'marketDataClassificationId'>

const FormDocumentsFactory: React.FC<FieldOuterProps> = (props) => {
  const entity = useSessionEntity()
  const { values, validateForm } = useFormikContext<FormValuesLegalDocs>()

  const readDocument = values?.readDocument
  const readDocumentMarketData = values?.readDocumentMarketData

  useEffect(() => {
    validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readDocument])

  if (readDocument) {
    return <ReadDocumentPage />
  }

  if (readDocumentMarketData?.isDocumentMarketDataRead) {
    return <ReadDocumentMarketDataClientClassificationPage />
  }

  if (isTickmillUKType(entity)) {
    return <LegalDocumentsUK {...props} />
  }

  if (isTickmillPartnerType(entity)) {
    return <LegalDocumentsPA {...props} />
  }

  return <LegalDocuments {...props} />
}

const ReadDocumentMarketDataClientClassificationPage = () => {
  const { values, setFieldValue } = useFormikContext<FormValuesLegalDocs>()

  const { setMarketDataClassificationId } = useDocumentMarketDataIdState()

  const handleAccept = async () => {
    const value = values.readDocumentMarketData.marketDataClassificationId || 0
    setMarketDataClassificationId(value)
    setFieldValue('readDocumentMarketData', {
      marketDataClassificationId: value,
      isDocumentMarketDataRead: false,
      isDocumentMarketDataAccepted: true,
    })
  }

  return <ReadDocumentMarketDataPage onAccept={handleAccept} />
}

const LegalDocumentsUK: React.FC<FieldOuterProps> = (props) => {
  const { documents = [], groupReferences = [], products } = props

  const { domains } = usePublicDomainsReadContext()

  const { t } = useTranslation()

  return (
    <SignUpRegisterTemplate {...props} reversed>
      <div className='pb-4'>
        <h3>{t('Sign up.Legal Documents')}</h3>
      </div>
      <div className='is-flex pt-4'>
        <Text>
          {t('Sign up.Please refer to our legal documents section')}{' '}
          <ExternalLink
            title={t('Profile.Legal Documents')}
            url={`${domains?.homePageUrl}/about/legal-documents`}
            className='is-link'
          >
            {t('Sign up.here')}
          </ExternalLink>
          {'.'}
        </Text>
      </div>
      <div className='is-flex pt-4'>
        <Text>
          {t('Sign up.Read & Accept the Legal Documents to continue with your registration.')}
        </Text>
      </div>
      <DocumentGroupsFactory
        groupReferences={groupReferences}
        documents={documents}
        renderItem={(props) => <DocumentItemFactory {...props} />}
      />
      {isTickmillProductTypeETD(products.map((x) => x.id)) && <DocumentMarketDataClassifications />}
      <SubmitForm />
    </SignUpRegisterTemplate>
  )
}

const LegalDocumentsPA: React.FC<FieldOuterProps> = (props) => {
  const { documents = [], groupReferences = [] } = props

  const homePageUrl = useHomePageUrl()

  const { t } = useTranslation()

  return (
    <SignUpRegisterTemplate {...props} reversed>
      <div className='pb-4'>
        <h3>{t('Sign up.Legal')}</h3>
      </div>
      <div className='is-flex pt-4'>
        <Text>
          {t('Sign up.Please refer to our legal documents section')}{' '}
          <ExternalLink
            title={t('Profile.Legal Documents')}
            url={`${homePageUrl}/about/legal-documents`}
            className='is-link'
          >
            {t('Sign up.here')}
          </ExternalLink>
          {'.'}
        </Text>
      </div>
      <DocumentGroupsFactory
        groupReferences={groupReferences}
        documents={documents}
        renderItem={(props) => <DocumentItemFactory {...props} />}
      />
      <SubmitForm />
    </SignUpRegisterTemplate>
  )
}

const LegalDocuments: React.FC<FieldOuterProps> = (props) => {
  const { documents = [], groupReferences = [] } = props

  const { t } = useTranslation()

  return (
    <SignUpRegisterTemplate {...props} reversed>
      <div className='pb-4'>
        <h3>{t('Sign up.Legal')}</h3>
      </div>
      <Text>
        {t('Sign up.Please check the boxes below once you’ve reviewed our legal documentation.')}
      </Text>
      <DocumentGroupsFactory
        groupReferences={groupReferences}
        documents={documents}
        renderItem={(props) => <DocumentItemFactory {...props} />}
      />
      <SubmitForm />
    </SignUpRegisterTemplate>
  )
}

const SubmitForm = () => {
  const { t } = useTranslation()

  const { values, isSubmitting, isValid } = useFormikContext<FormValuesLegalDocs>()

  return (
    <Button
      appearance='primary'
      size='L'
      type='submit'
      disabled={isSubmitting || !isValid}
      className={styles.button}
    >
      {isLeadTypeIndividual(values.lead) && t('Sign up.Create Profile')}
      {isLeadTypeCorporate(values.lead) && t('Next')}
    </Button>
  )
}

type GroupReference = string | null

interface OuterProps extends SignUpRegisterTemplateProps {
  lead: LeadDto
  groupReferences: GroupReference[]
  documents: LegalDocumentDto[]
  products: NameDto[]
  marketDataClassificationId: number
  shouldReceiveNewsletter?: boolean
  onSubmit(v: FormValuesLegalDocs): Promise<void>
}

export const PersonalInfoStep6Form = withFormik<OuterProps, FormValuesLegalDocs>({
  mapPropsToValues: (props) => {
    const { lead, shouldReceiveNewsletter } = props

    return {
      lead,
      shouldReceiveNewsletter: shouldReceiveNewsletter || false,
      agreedLegalDocuments: [],
      readDocument: undefined,
      readDocumentMarketData: {
        marketDataClassificationId: props.marketDataClassificationId || 0,
        isDocumentMarketDataRead: false,
        isDocumentMarketDataAccepted: props.marketDataClassificationId >= 1 || false,
      },
      documents: {},
    }
  },
  handleSubmit: async (values, { props, setSubmitting }) => {
    const { onSubmit } = props

    try {
      setSubmitting(true)
      await onSubmit(values)
      setSubmitting(false)
    } finally {
      setSubmitting(false)
    }
  },
  validate: (values, { documents }) => {
    const errors: FormikErrors<FormValuesLegalDocs> = {}

    if (!isDocumentsPositiveValue(values.documents)) {
      errors.agreedLegalDocuments = t('Validation.Required')
    }

    if (!isDocumentsAgreed(documents, values.agreedLegalDocuments)) {
      errors.agreedLegalDocuments = t('Validation.Required')
    }

    return errors
  },
  validateOnMount: true,
  enableReinitialize: false,
})(PersonalInfoStep6DocumentsFormUI)

const isDocumentsPositiveValue = (documents: { [key: string]: boolean } = {}) => {
  return Object.entries(documents || []).every(([_, value]) => value)
}

const isDocumentsAgreed = (
  documents: LegalDocumentDto[] = [],
  agreedLegalDocuments: AgreedLegalDocument[] = []
) => {
  return documents.every((document) =>
    agreedLegalDocuments.some((agreedDocument) => agreedDocument.id === document.id)
  )
}
