import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { ConversionIcon } from '../../../icons/ConversionIcon'
import { InfoIcon } from '../../../icons/InfoIcon'
import { WithdrawIcon } from '../../../icons/WithdrawIcon'
import { WalletDto } from '../../../model/WalletDto'

import styles from './WalletOptionsModal.module.scss'

interface Props {
  wallet: WalletDto
  onWalletInformationModal(): void
  onCancel(): void
}

export const WalletOptionsModal: React.FC<Props> = (props) => {
  const { wallet, onWalletInformationModal, onCancel } = props

  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleWithdrawToBankAccount = () => {
    navigate(`/dashboard/traders-room/wallets/${wallet.id}/withdrawal`)
  }

  const handleConversion = () => {
    navigate(`/dashboard/traders-room/transactions/conversion`, {
      state: {
        walletId: wallet.id,
      },
    })
  }

  const handleWalletInformationModal = () => {
    onWalletInformationModal()
  }

  return (
    <React.Fragment>
      <header className='modal-card-head'>
        <p className='modal-card-title'>
          {wallet.currency.id} {t('Wallet.Wallet')}
        </p>
      </header>
      <section className={classNames(styles.body, 'modal-card-body')}>
        <ul>
          <li onClick={handleWithdrawToBankAccount}>
            <div className={styles.wrapper}>
              <WithdrawIcon color='text' strokeWidth={1.5} size={24} />
              <div>{t('Wallet.Withdraw to my Bank account')}</div>
            </div>
          </li>
          <li onClick={handleConversion}>
            <div className={styles.wrapper}>
              <ConversionIcon color='text' strokeWidth={1.5} size={24} />
              <div>{t('Wallet.Convert currencies between Tickmill Wallets')}</div>
            </div>
          </li>
          <li onClick={handleWalletInformationModal}>
            <div className={styles.wrapper}>
              <InfoIcon color='text' strokeWidth={1.5} size={24} />
              <div>{t('Wallet.Wallet Information')}</div>
            </div>
          </li>
        </ul>
      </section>
      <footer className='modal-card-foot'>
        <button className='button' onClick={onCancel}>
          {t('Cancel')}
        </button>
      </footer>
    </React.Fragment>
  )
}
