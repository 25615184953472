import React, { useRef } from 'react'
import ReactDatePicker from 'react-datepicker'
import classNames from 'classnames'
import { format } from 'date-fns'
import type { FieldConfig as FormikFieldConfig } from 'formik/dist/Field'
import { t } from 'i18next'

import { CalendarIcon } from '../../icons/CalendarIcon'
import { useArabicSessionLanguage } from '../context/SessionSettingsContext'
import { getLabel, getPlaceholder, isFocus } from '../formField/helpers'
import type { TypedFieldProps } from '../types/TypedFieldProps'

import styles from '../formField/FormField.module.scss'

export type DatePickerValue = Date | string | undefined | null
interface FieldConfig extends Omit<FormikFieldConfig, 'name'> {}
interface DatePickerProps extends FieldConfig {
  maxDate?: Date | null
  showYearDropdown?: boolean
  value?: DatePickerValue
  hint?: string
  onChange?(value: Date | undefined | null): void
  onClick?(): void
  error?: string
  minDate?: Date | null
}

export function DatePicker<FormValues>(props: DatePickerProps & TypedFieldProps<FormValues>) {
  const {
    hint,
    type = 'text',
    showLabel = false,
    className,
    required,
    disabled,
    maxDate,
    onChange = () => {},
    showYearDropdown,
    error,
    minDate,
  } = props

  const fieldRef = useRef<HTMLDivElement>(null)

  const value = getDateValue(props.value)
  const label = getLabel(props)
  const placeholder = getPlaceholder(props, value)
  const isArabic = useArabicSessionLanguage()

  return (
    <div className={classNames('field', styles.field)} ref={fieldRef}>
      {(showLabel || isFocus({ value, label })) && !!label && (
        <label
          dir={isArabic ? 'rtl' : ''}
          className={classNames(styles.labelSecondary, {
            [styles.error]: !!error,
          })}
        >
          {label}
        </label>
      )}

      <div className='control'>
        <ReactDatePicker
          popperProps={{ strategy: 'fixed' }}
          popperModifiers={[
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                rootBoundary: 'viewport',
                tether: false,
                altAxis: true,
              },
            },
          ]}
          maxDate={maxDate}
          dateFormat={'dd/MM/yyyy'}
          dropdownMode='select'
          wrapperClassName={styles.datePickerWrapper}
          selected={value ? new Date(value) : null}
          onChange={onChange}
          required={required}
          showYearDropdown={showYearDropdown}
          shouldCloseOnSelect
          disabled={disabled}
          customInput={
            <span>
              <input
                value={value ? format(new Date(value), 'dd/MM/yyyy') : ''}
                type={type}
                required={required}
                disabled={disabled}
                className={classNames('input', styles.input, className, {
                  [styles.inputFocus]: isFocus({
                    value,
                    label,
                  }),
                  [styles.inputError]: !!error,
                  [styles.isIconRight]: true,
                })}
                placeholder={placeholder ?? t('Format - dd.mm.yy')}
              />
              <span
                dir={isArabic ? 'rtl' : ''}
                onClick={disabled ? () => {} : props.onClick}
                className={classNames({ 'is-clickable': !disabled }, styles.icon, styles.iconRight)}
              >
                <CalendarIcon />
              </span>
            </span>
          }
          minDate={minDate}
        />
      </div>

      {hint && <span className={styles.hint}>{hint}</span>}

      {!!error && <div className={classNames(styles.errorMessage, 'is-danger')}>{error}</div>}
    </div>
  )
}

const getDateValue = (date: DatePickerValue) => {
  if (date instanceof Date) {
    return date.toISOString()
  }

  if (typeof date === 'string') {
    return date
  }

  return undefined
}
