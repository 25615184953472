import React from 'react'
import { useTranslation } from 'react-i18next'

import { useAccountReadContext } from '../../utils/AccountContextContext'
import { DocumentManagementNCIPage } from './DocumentManagementNCI/DocumentManagementNCIPage'
import { DocumentManagementNonNCIPage } from './DocumentManagementNonNCI/DocumentManagementNonNCIPage'

export const DocumentManagementIdentificationPage: React.FC = () => {
  const { account } = useAccountReadContext()

  const { t } = useTranslation()

  return (
    <React.Fragment>
      {account?.companyConfiguration?.hasNCI && <DocumentManagementNCIPage />}
      {!account?.companyConfiguration?.hasNCI && (
        <DocumentManagementNonNCIPage documentTitle={t('Sign up.Identification')} />
      )}
    </React.Fragment>
  )
}
