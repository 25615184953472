import React from 'react'

import { CloseIcon } from '../../../../icons/CloseIcon'
import { TextField } from '../../../field/TextField'
import { FilterOptions, FilterQueryProps } from '../../FilterQueryModal'

import styles from './FilterModalTextFieldFilter.module.scss'

interface Props {
  name: string
  setState: (value: React.SetStateAction<FilterQueryProps | undefined>) => void
  filterType: FilterOptions
  state?: FilterQueryProps
}
export const FilterModalTextFieldFilter: React.FC<Props> = ({
  name,
  setState,
  filterType,
  state,
}) => {
  return (
    <div>
      <span className={styles.title}>{name}</span>
      <TextField
        value={state?.[filterType]?.toString()}
        type={'text'}
        className={styles.input}
        onChange={(e) =>
          setState({
            ...state,
            [filterType]: e,
          })
        }
        renderIcon={
          <span
            className='icon is-small is-right is-clickable'
            onClick={() =>
              setState({
                ...state,
                [filterType]: '',
              })
            }
          >
            <CloseIcon />
          </span>
        }
        iconPosition={'right'}
      />
    </div>
  )
}
