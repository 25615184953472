import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { FilterQueryProps } from '../../global/filter/FilterQueryModal'
import { SortHeader } from '../../global/sortHeader/SortHeader'
import { Table } from '../../global/table/Table'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { DealDto } from '../../model/DealDto'
import { SearchTermState } from '../../ui/Table/Header/PageHeaderParts'
import { NoResults } from '../../ui/Table/NoResults/NoResults'
import { PageQuery } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { getCompanyName } from '../../utils/companyName.utils'
import { formatDate } from '../../utils/date.utils'
import { getAppliedFiltersLength } from '../../utils/filter.utils'
import { IBNoData } from '../IBNoData'

interface IBIncomeTableProps {
  data: DealDto[]
  search: SearchResponseProps
}

interface SearchResponseProps {
  search: FilterQueryProps
  searchTerm: SearchTermState
  pageQuery: PageQuery | undefined
  setPageQuery(value: PageQuery): void
}

export const IBIncomeTable: React.FC<IBIncomeTableProps> = (props) => {
  const { data, search } = props
  const { pageQuery, setPageQuery } = search

  const { t } = useTranslation()
  const [auth] = useContext(AuthSessionContext)
  const { formatNumber } = useFormatNumber()
  const dateFormat = auth?.dateFormatType?.name

  if (data.length <= 0) {
    return <NoData search={search} />
  }

  return (
    <Table>
      <thead>
        <tr>
          <SortHeader
            id='TradingAccount.Name'
            sort={pageQuery?.sort || undefined}
            sortOrder={pageQuery?.sortOrder}
            setSort={(sort, sortOrder) => {
              setPageQuery!({
                ...pageQuery,
                sort,
                sortOrder,
              })
            }}
          >
            {t('Trading Account.Trading Account')}
          </SortHeader>
          <SortHeader
            id='TradingAccount.ClientTradingAccount.Wallet.Client.Name'
            sort={pageQuery?.sort || undefined}
            sortOrder={pageQuery?.sortOrder || 'DESC'}
            setSort={(sort, sortOrder) => {
              setPageQuery!({
                ...pageQuery,
                sort,
                sortOrder,
              })
            }}
          >
            {t('IB.Name')}
          </SortHeader>
          <SortHeader
            id='TradedAt'
            sort={pageQuery?.sort || undefined}
            sortOrder={pageQuery?.sortOrder || 'DESC'}
            setSort={(sort, sortOrder) => {
              setPageQuery!({
                ...pageQuery,
                sort,
                sortOrder,
              })
            }}
          >
            {t('IB.Date & Time')}
          </SortHeader>

          <SortHeader
            id='CurrencyId'
            sort={pageQuery?.sort || undefined}
            sortOrder={pageQuery?.sortOrder}
            setSort={(sort, sortOrder) => {
              setPageQuery!({
                ...pageQuery,
                sort,
                sortOrder,
              })
            }}
          >
            {t('IB.Currency')}
          </SortHeader>
          <SortHeader
            id='TradingAccount.AccountGroupServer.AccountGroup.AccountGroupTypeId'
            sort={pageQuery?.sort || undefined}
            sortOrder={pageQuery?.sortOrder}
            setSort={(sort, sortOrder) => {
              setPageQuery!({
                ...pageQuery,
                sort,
                sortOrder,
              })
            }}
          >
            {t('Trading Account.Account Type')}
          </SortHeader>
          <SortHeader
            id='Instrument.Name'
            sort={pageQuery?.sort || undefined}
            sortOrder={pageQuery?.sortOrder}
            setSort={(sort, sortOrder) => {
              setPageQuery!({
                ...pageQuery,
                sort,
                sortOrder,
              })
            }}
          >
            {t('IB.Instrument')}
          </SortHeader>
          <SortHeader
            id='Volume'
            sort={pageQuery?.sort || undefined}
            sortOrder={pageQuery?.sortOrder}
            setSort={(sort, sortOrder) => {
              setPageQuery!({
                ...pageQuery,
                sort,
                sortOrder,
              })
            }}
          >
            {t('IB.Volume')}
          </SortHeader>
          <SortHeader
            id='Amount'
            sort={pageQuery?.sort || undefined}
            sortOrder={pageQuery?.sortOrder}
            setSort={(sort, sortOrder) => {
              setPageQuery!({
                ...pageQuery,
                sort,
                sortOrder,
              })
            }}
          >
            {t('IB.Commission')}
          </SortHeader>
          <SortHeader
            id='TradingAccount.ClientTradingAccount.Wallet.Client.TickmillCompany.Name'
            sort={pageQuery?.sort || undefined}
            sortOrder={pageQuery?.sortOrder}
            setSort={(sort, sortOrder) => {
              setPageQuery!({
                ...pageQuery,
                sort,
                sortOrder,
              })
            }}
          >
            {t('IB.Entity')}
          </SortHeader>
        </tr>
      </thead>
      <tbody>
        {data.map((deal) => {
          return (
            <tr key={deal.id}>
              <td>{deal.tradingAccount.name}</td>
              <td className='is-link'>{deal.client.name}</td>
              <td>{formatDate(deal.tradedAt, dateFormat)}</td>
              <td>{deal.currency.id}</td>
              <td>{deal.tradingAccountType.name}</td>
              <td>{deal.instrument.name}</td>
              <td>{formatNumber(deal.volume)}</td>
              <td>
                <strong>{formatNumber(deal.amount)}</strong>
              </td>
              <td>{getCompanyName(deal.tickmillCompany.id)}</td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export interface NoDataProps {
  search: SearchResponseProps
}

export const NoData: React.FC<NoDataProps> = (props: NoDataProps) => {
  const { search } = props

  const { t } = useTranslation()

  const areFiltersActive =
    getAppliedFiltersLength({
      ...search.search,
      ca_search_TradingAccountName: undefined,
    }) > 0

  if (search.searchTerm.searchTerm || areFiltersActive) {
    return <NoResults hideLink subtitle={t('No results')} />
  } else {
    return <IBNoData title={t('IB.No income yet')} />
  }
}
