import React from 'react'

import { IconProps, iconColor } from './IconProps'

export const AlertIcon: React.FC<IconProps> = (props) => {
  const { size = 24 } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z'
        fill={iconColor(props)}
      />
      <path
        d='M13.4994 15.5C13.4994 16.3284 12.8278 17 11.9994 17C11.1709 17 10.4994 16.3284 10.4994 15.5C10.4994 14.6716 11.1709 14 11.9994 14C12.8278 14 13.4994 14.6716 13.4994 15.5Z'
        fill='white'
      />
      <path
        d='M10.3282 8.97279C10.156 7.94009 10.9524 7 11.9994 7C13.0463 7 13.8427 7.94009 13.6706 8.97279L13.1638 12.0136C13.0689 12.5828 12.5764 13 11.9994 13C11.4223 13 10.9298 12.5828 10.835 12.0136L10.3282 8.97279Z'
        fill='white'
      />
    </svg>
  )
}
