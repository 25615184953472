import { IconProps } from './IconProps'

export const AppStoreIcon = (props: IconProps): JSX.Element => {
  const width = props.width || '164'
  const height = props.height || '55'
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox='0 0 164 55'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M150.882 0.000178015H13.1256C12.6234 0.000178015 12.1273 0.000178015 11.6265 0.0029167C11.2073 0.00565539 10.7914 0.0136113 10.3682 0.0203074C9.44867 0.0311276 8.53134 0.112021 7.62414 0.262284C6.71821 0.415818 5.84067 0.705274 5.02118 1.12086C4.2027 1.53997 3.45482 2.08455 2.80469 2.73485C2.15115 3.38333 1.60637 4.13288 1.19131 4.95468C0.775096 5.77485 0.486493 6.65373 0.335468 7.56098C0.183006 8.46708 0.100966 9.38363 0.0900815 10.3024C0.077374 10.7222 0.076032 11.1435 0.0693359 11.5634V43.2147C0.076032 43.6399 0.077374 44.0518 0.0900815 44.4771C0.100969 45.3958 0.18301 46.3123 0.335468 47.2184C0.486076 48.1262 0.774696 49.0056 1.19131 49.826C1.60618 50.6452 2.15104 51.3917 2.80469 52.0366C3.45236 52.6898 4.20071 53.2347 5.02118 53.6506C5.84066 54.0673 6.71811 54.3585 7.62414 54.5144C8.53149 54.6635 9.44873 54.7444 10.3682 54.7565C10.7914 54.7658 11.2073 54.7712 11.6265 54.7712C12.1273 54.7739 12.6235 54.7739 13.1256 54.7739H150.882C151.374 54.7739 151.874 54.7739 152.366 54.7712C152.783 54.7712 153.211 54.7658 153.628 54.7565C154.546 54.745 155.462 54.6641 156.367 54.5144C157.276 54.3574 158.157 54.0663 158.98 53.6506C159.8 53.2345 160.547 52.6895 161.195 52.0366C161.846 51.3892 162.393 50.6432 162.813 49.826C163.226 49.005 163.512 48.1257 163.66 47.2184C163.813 46.3122 163.898 45.3959 163.914 44.4771C163.92 44.0518 163.92 43.6399 163.92 43.2147C163.93 42.7172 163.93 42.2225 163.93 41.7169V13.0584C163.93 12.5569 163.93 12.0595 163.92 11.5634C163.92 11.1435 163.92 10.7222 163.914 10.3023C163.898 9.38349 163.813 8.46715 163.66 7.56092C163.511 6.6542 163.226 5.77539 162.813 4.95462C161.967 3.30741 160.627 1.96659 158.98 1.12074C158.157 0.706166 157.276 0.416787 156.367 0.262161C155.462 0.111236 154.546 0.0303142 153.628 0.0201156C153.211 0.0134333 152.783 0.0054089 152.366 0.00273869C151.874 0 151.374 0 150.882 0V0.000178015Z'
        fill='#A6A6A6'
      />
      <path
        d='M11.634 53.5707C11.2168 53.5707 10.8097 53.5654 10.3958 53.5561C9.53825 53.5449 8.68275 53.4702 7.83627 53.3327C7.04696 53.1968 6.28234 52.9439 5.56762 52.5825C4.85945 52.224 4.21355 51.754 3.65465 51.1904C3.08766 50.6334 2.61561 49.9875 2.25722 49.2781C1.89488 48.5641 1.64412 47.7988 1.51367 47.0088C1.3728 46.16 1.29658 45.3017 1.28567 44.4413C1.27699 44.1525 1.26562 43.191 1.26562 43.191V11.5584C1.26562 11.5584 1.27773 10.6116 1.28574 10.3334C1.29618 9.47444 1.37196 8.61746 1.51241 7.76994C1.6431 6.97781 1.89405 6.21029 2.25659 5.49396C2.61367 4.78507 3.08309 4.13862 3.64667 3.57969C4.20961 3.01544 4.85758 2.54299 5.56695 2.17957C6.28003 1.81928 7.0432 1.56819 7.83091 1.43473C8.68017 1.29584 9.53865 1.22074 10.3991 1.21008L11.6347 1.19336H152.36L153.61 1.21075C154.463 1.22088 155.314 1.29531 156.155 1.43339C156.951 1.56853 157.722 1.82136 158.443 2.18359C159.864 2.91597 161.021 4.07468 161.75 5.4973C162.107 6.20868 162.354 6.96994 162.483 7.75524C162.625 8.60971 162.705 9.47342 162.721 10.3395C162.725 10.7273 162.725 11.1438 162.725 11.5584C162.736 12.0719 162.736 12.5606 162.736 13.0534V41.7119C162.736 42.2094 162.736 42.6949 162.725 43.1843C162.725 43.6296 162.725 44.0375 162.72 44.4573C162.704 45.3079 162.626 46.1562 162.486 46.9954C162.358 47.7911 162.109 48.5624 161.746 49.2822C161.385 49.9839 160.916 50.6243 160.356 51.1797C159.796 51.7463 159.149 52.2191 158.439 52.5799C157.72 52.9441 156.95 53.1979 156.155 53.3327C155.309 53.471 154.453 53.5456 153.596 53.5561C153.195 53.5654 152.775 53.5707 152.367 53.5707L150.882 53.5735L11.634 53.5707Z'
        fill='black'
      />
      <path
        d='M33.9872 27.7937C34.0019 26.6507 34.3055 25.5301 34.8697 24.536C35.4339 23.5419 36.2404 22.7067 37.2141 22.1081C36.5956 21.2246 35.7795 20.4976 34.8308 19.9847C33.8821 19.4718 32.8268 19.1872 31.7489 19.1535C29.4494 18.9121 27.2202 20.5294 26.0484 20.5294C24.854 20.5294 23.0499 19.1774 21.1072 19.2174C19.8506 19.258 18.6259 19.6234 17.5525 20.278C16.4791 20.9327 15.5936 21.8542 14.9822 22.9528C12.3339 27.5379 14.3093 34.2765 16.8462 37.9828C18.1154 39.7977 19.5988 41.8249 21.5398 41.753C23.4391 41.6742 24.1485 40.5419 26.4412 40.5419C28.7126 40.5419 29.3781 41.753 31.3585 41.7073C33.3966 41.6742 34.6807 39.8844 35.9054 38.0523C36.8174 36.7592 37.5191 35.33 37.9846 33.8176C36.8006 33.3168 35.7901 32.4785 35.0792 31.4073C34.3684 30.3361 33.9886 29.0793 33.9872 27.7937V27.7937Z'
        fill='white'
      />
      <path
        d='M30.2474 16.7193C31.3587 15.3853 31.9061 13.6706 31.7736 11.9395C30.0758 12.1178 28.5076 12.9292 27.3813 14.212C26.8306 14.8387 26.4089 15.5678 26.1401 16.3576C25.8714 17.1474 25.761 17.9824 25.8152 18.8149C26.6643 18.8237 27.5044 18.6396 28.2722 18.2766C29.0399 17.9136 29.7153 17.3811 30.2474 16.7193Z'
        fill='white'
      />
      <path
        d='M57.9974 37.1603H51.5158L49.9592 41.7565H47.2139L53.3532 24.752H56.2055L62.3448 41.7565H59.5526L57.9974 37.1603ZM52.1871 35.0394H57.3249L54.7921 27.5803H54.7212L52.1871 35.0394Z'
        fill='white'
      />
      <path
        d='M75.604 35.5581C75.604 39.4107 73.542 41.8859 70.4302 41.8859C69.6419 41.9272 68.858 41.7456 68.1681 41.362C67.4783 40.9784 66.9104 40.4082 66.5295 39.7169H66.4706V45.8575H63.9258V29.3586H66.389V31.4206H66.4358C66.8343 30.7326 67.4117 30.1654 68.1069 29.7794C68.802 29.3935 69.5887 29.2032 70.3834 29.2288C73.5299 29.2289 75.604 31.7161 75.604 35.5581ZM72.9884 35.5581C72.9884 33.0481 71.6912 31.3979 69.7121 31.3979C67.7677 31.3979 66.4599 33.0828 66.4599 35.5581C66.4599 38.056 67.7677 39.7289 69.7121 39.7289C71.6912 39.7289 72.9884 38.0908 72.9884 35.5581Z'
        fill='white'
      />
      <path
        d='M89.2466 35.558C89.2466 39.4107 87.1846 41.8859 84.0728 41.8859C83.2845 41.9271 82.5006 41.7456 81.8107 41.362C81.1208 40.9783 80.5529 40.4082 80.172 39.7169H80.1131V45.8575H77.5684V29.3586H80.0316V31.4206H80.0784C80.4768 30.7325 81.0543 30.1654 81.7494 29.7794C82.4445 29.3935 83.2313 29.2032 84.0259 29.2288C87.1725 29.2288 89.2466 31.7161 89.2466 35.558ZM86.6309 35.558C86.6309 33.048 85.3338 31.3979 83.3547 31.3979C81.4103 31.3979 80.1025 33.0828 80.1025 35.558C80.1025 38.056 81.4103 39.7289 83.3547 39.7289C85.3338 39.7289 86.6309 38.0908 86.6309 35.558H86.6309Z'
        fill='white'
      />
      <path
        d='M98.2661 37.0209C98.4547 38.7071 100.093 39.8143 102.331 39.8143C104.476 39.8143 106.02 38.7071 106.02 37.1866C106.02 35.8667 105.089 35.0765 102.885 34.5349L100.681 34.004C97.5587 33.2498 96.1092 31.7895 96.1092 29.4199C96.1092 26.4859 98.666 24.4707 102.297 24.4707C105.89 24.4707 108.353 26.4859 108.436 29.4199H105.867C105.713 27.7229 104.31 26.6986 102.26 26.6986C100.21 26.6986 98.8077 27.7349 98.8077 29.2434C98.8077 30.4455 99.7037 31.1529 101.895 31.6945L103.769 32.1545C107.258 32.9796 108.707 34.381 108.707 36.8683C108.707 40.0496 106.173 42.0421 102.143 42.0421C98.3717 42.0421 95.8256 40.0964 95.6611 37.0207L98.2661 37.0209Z'
        fill='white'
      />
      <path
        d='M114.2 26.4297V29.3636H116.558V31.3788H114.2V38.2135C114.2 39.2753 114.672 39.7701 115.709 39.7701C115.989 39.7652 116.268 39.7455 116.546 39.7112V41.7144C116.08 41.8015 115.606 41.8409 115.132 41.8321C112.622 41.8321 111.643 40.8893 111.643 38.4849V31.3788H109.841V29.3636H111.643V26.4297H114.2Z'
        fill='white'
      />
      <path
        d='M117.925 35.5629C117.925 31.6621 120.222 29.2109 123.805 29.2109C127.399 29.2109 129.686 31.6621 129.686 35.5629C129.686 39.4743 127.411 41.9148 123.805 41.9148C120.199 41.9148 117.925 39.4743 117.925 35.5629ZM127.093 35.5629C127.093 32.887 125.867 31.3077 123.805 31.3077C121.743 31.3077 120.518 32.8991 120.518 35.5629C120.518 38.2494 121.743 39.8167 123.805 39.8167C125.867 39.8167 127.093 38.2494 127.093 35.5629H127.093Z'
        fill='white'
      />
      <path
        d='M131.783 29.3585H134.21V31.4686H134.269C134.433 30.8096 134.82 30.2271 135.363 29.8192C135.906 29.4113 136.573 29.2028 137.251 29.2287C137.545 29.2277 137.837 29.2595 138.123 29.3237V31.704C137.753 31.5908 137.367 31.5389 136.98 31.5502C136.61 31.5352 136.242 31.6004 135.899 31.7413C135.557 31.8822 135.25 32.0954 134.998 32.3664C134.746 32.6374 134.556 32.9597 134.44 33.3111C134.324 33.6626 134.286 34.0349 134.328 34.4025V41.7561H131.783L131.783 29.3585Z'
        fill='white'
      />
      <path
        d='M149.857 38.1197C149.515 40.3703 147.323 41.9148 144.519 41.9148C140.912 41.9148 138.674 39.4984 138.674 35.6217C138.674 31.733 140.924 29.2109 144.412 29.2109C147.842 29.2109 149.999 31.5672 149.999 35.3262V36.1981H141.243V36.3518C141.202 36.8081 141.259 37.2677 141.41 37.7002C141.561 38.1327 141.802 38.5282 142.118 38.8603C142.433 39.1924 142.816 39.4537 143.24 39.6266C143.664 39.7996 144.12 39.8803 144.578 39.8635C145.179 39.9198 145.783 39.7805 146.298 39.4663C146.814 39.1522 147.215 38.6799 147.441 38.1197L149.857 38.1197ZM141.255 34.4195H147.453C147.476 34.0093 147.414 33.5987 147.27 33.2137C147.127 32.8286 146.905 32.4773 146.62 32.1818C146.334 31.8864 145.991 31.6531 145.611 31.4967C145.231 31.3403 144.823 31.2641 144.412 31.273C143.998 31.2705 143.587 31.3501 143.203 31.5072C142.82 31.6644 142.471 31.8959 142.178 32.1884C141.884 32.481 141.652 32.8287 141.493 33.2117C141.335 33.5946 141.254 34.0051 141.255 34.4195V34.4195Z'
        fill='white'
      />
      <path
        d='M51.8663 11.9546C52.3999 11.9163 52.9352 11.9969 53.4338 12.1906C53.9324 12.3842 54.3818 12.6862 54.7496 13.0745C55.1174 13.4629 55.3944 13.9281 55.5606 14.4365C55.7269 14.9449 55.7782 15.4838 55.7109 16.0145C55.7109 18.6248 54.3001 20.1252 51.8663 20.1252H48.915V11.9546H51.8663ZM50.1841 18.9697H51.7246C52.1058 18.9925 52.4873 18.93 52.8414 18.7868C53.1954 18.6435 53.513 18.4231 53.7711 18.1416C54.0292 17.8601 54.2213 17.5246 54.3334 17.1595C54.4455 16.7944 54.4748 16.409 54.4191 16.0311C54.4708 15.6548 54.4385 15.2717 54.3247 14.9093C54.2109 14.5468 54.0183 14.2141 53.7608 13.9348C53.5033 13.6556 53.1872 13.4368 52.8351 13.294C52.4831 13.1513 52.1039 13.0882 51.7246 13.1092H50.1841V18.9697Z'
        fill='white'
      />
      <path
        d='M57.1456 17.0411C57.1068 16.6359 57.1532 16.227 57.2817 15.8408C57.4102 15.4545 57.618 15.0994 57.8918 14.7982C58.1656 14.497 58.4993 14.2563 58.8716 14.0916C59.2438 13.9269 59.6464 13.8418 60.0534 13.8418C60.4605 13.8418 60.8631 13.9269 61.2353 14.0916C61.6076 14.2563 61.9413 14.497 62.2151 14.7982C62.4889 15.0994 62.6967 15.4545 62.8252 15.8408C62.9537 16.227 63.0001 16.6359 62.9613 17.0411C63.0008 17.4467 62.955 17.8561 62.8268 18.243C62.6987 18.6299 62.491 18.9857 62.2171 19.2875C61.9433 19.5893 61.6093 19.8305 61.2367 19.9956C60.864 20.1606 60.461 20.2459 60.0534 20.2459C59.6459 20.2459 59.2428 20.1606 58.8702 19.9956C58.4976 19.8305 58.1636 19.5893 57.8898 19.2875C57.6159 18.9857 57.4082 18.6299 57.2801 18.243C57.1519 17.8561 57.1061 17.4467 57.1456 17.0411ZM61.7096 17.0411C61.7096 15.7045 61.1092 14.9229 60.0554 14.9229C58.9977 14.9229 58.4026 15.7045 58.4026 17.0411C58.4026 18.3884 58.9977 19.164 60.0554 19.164C61.1092 19.164 61.7096 18.383 61.7096 17.0411H61.7096Z'
        fill='white'
      />
      <path
        d='M70.6909 20.125H69.4286L68.1542 15.5837H68.0579L66.7888 20.125H65.5385L63.8389 13.959H65.0732L66.1777 18.664H66.2687L67.5364 13.959H68.7038L69.9715 18.664H70.0678L71.167 13.959H72.3839L70.6909 20.125Z'
        fill='white'
      />
      <path
        d='M73.8135 13.961H74.9849V14.9406H75.0758C75.2301 14.5887 75.4903 14.2938 75.8201 14.0969C76.15 13.9 76.533 13.8109 76.9159 13.842C77.2159 13.8195 77.5172 13.8647 77.7973 13.9744C78.0775 14.084 78.3294 14.2554 78.5344 14.4756C78.7393 14.6959 78.8921 14.9594 78.9814 15.2467C79.0707 15.534 79.0942 15.8378 79.0501 16.1354V20.127H77.8333V16.441C77.8333 15.4501 77.4027 14.9573 76.5027 14.9573C76.299 14.9478 76.0956 14.9825 75.9066 15.0589C75.7175 15.1354 75.5473 15.2518 75.4074 15.4002C75.2676 15.5486 75.1615 15.7255 75.0964 15.9188C75.0313 16.112 75.0088 16.3171 75.0304 16.5199V20.1271H73.8135L73.8135 13.961Z'
        fill='white'
      />
      <path d='M80.9893 11.5527H82.2062V20.1258H80.9893V11.5527Z' fill='white' />
      <path
        d='M83.8965 17.0373C83.8578 16.6321 83.9042 16.2232 84.0327 15.8369C84.1612 15.4507 84.3691 15.0955 84.6429 14.7943C84.9167 14.4931 85.2505 14.2524 85.6228 14.0877C85.995 13.923 86.3976 13.8379 86.8047 13.8379C87.2118 13.8379 87.6144 13.923 87.9867 14.0877C88.359 14.2524 88.6927 14.4931 88.9665 14.7943C89.2404 15.0955 89.4482 15.4507 89.5767 15.8369C89.7052 16.2232 89.7516 16.6321 89.7129 17.0373C89.7523 17.443 89.7065 17.8524 89.5783 18.2392C89.4501 18.6261 89.2424 18.9819 88.9685 19.2837C88.6946 19.5855 88.3606 19.8267 87.988 19.9917C87.6153 20.1568 87.2123 20.242 86.8047 20.242C86.3972 20.242 85.9941 20.1568 85.6215 19.9917C85.2488 19.8267 84.9148 19.5855 84.6409 19.2837C84.367 18.9819 84.1593 18.6261 84.0311 18.2392C83.9029 17.8524 83.8571 17.443 83.8965 17.0373ZM88.4606 17.0373C88.4606 15.7007 87.8601 14.9191 86.8064 14.9191C85.7486 14.9191 85.1536 15.7007 85.1536 17.0373C85.1536 18.3846 85.7487 19.1602 86.8064 19.1602C87.8601 19.1602 88.4606 18.3792 88.4606 17.0373H88.4606Z'
        fill='white'
      />
      <path
        d='M90.9941 18.3831C90.9941 17.2732 91.8206 16.6333 93.2875 16.5424L94.9577 16.4461V15.9139C94.9577 15.2626 94.5271 14.8949 93.6954 14.8949C93.0161 14.8949 92.5454 15.1443 92.4103 15.5802H91.2322C91.3565 14.5211 92.3528 13.8418 93.7515 13.8418C95.2974 13.8418 96.1693 14.6114 96.1693 15.9139V20.1269H94.9979V19.2603H94.9016C94.7062 19.5712 94.4317 19.8246 94.1063 19.9946C93.781 20.1647 93.4163 20.2454 93.0495 20.2285C92.7906 20.2554 92.529 20.2278 92.2815 20.1474C92.034 20.067 91.806 19.9356 91.6124 19.7618C91.4187 19.5879 91.2637 19.3754 91.1572 19.1379C91.0507 18.9005 90.9952 18.6433 90.9941 18.3831ZM94.9577 17.8562V17.3407L93.452 17.437C92.6028 17.4938 92.2177 17.7827 92.2177 18.3262C92.2177 18.8812 92.6991 19.2041 93.3611 19.2041C93.5551 19.2238 93.751 19.2042 93.9372 19.1466C94.1235 19.0889 94.2962 18.9944 94.4452 18.8686C94.5941 18.7429 94.7163 18.5884 94.8043 18.4145C94.8924 18.2406 94.9446 18.0507 94.9577 17.8562Z'
        fill='white'
      />
      <path
        d='M97.7705 17.0402C97.7705 15.0918 98.7721 13.8575 100.33 13.8575C100.715 13.8397 101.098 13.932 101.433 14.1236C101.767 14.3152 102.041 14.5981 102.221 14.9393H102.312V11.5527H103.529V20.1258H102.363V19.1516H102.266C102.072 19.4905 101.79 19.7699 101.448 19.9596C101.107 20.1492 100.72 20.2418 100.33 20.2275C98.7614 20.2275 97.7705 18.9932 97.7705 17.0402ZM99.0276 17.0402C99.0276 18.348 99.644 19.135 100.675 19.135C101.701 19.135 102.335 18.3366 102.335 17.0455C102.335 15.7604 101.694 14.9507 100.675 14.9507C99.6507 14.9507 99.0275 15.743 99.0275 17.0402H99.0276Z'
        fill='white'
      />
      <path
        d='M108.563 17.0411C108.524 16.6359 108.57 16.227 108.699 15.8408C108.827 15.4545 109.035 15.0994 109.309 14.7982C109.583 14.497 109.916 14.2563 110.289 14.0916C110.661 13.9269 111.063 13.8418 111.47 13.8418C111.877 13.8418 112.28 13.9269 112.652 14.0916C113.025 14.2563 113.358 14.497 113.632 14.7982C113.906 15.0994 114.114 15.4545 114.242 15.8408C114.371 16.227 114.417 16.6359 114.378 17.0411C114.418 17.4467 114.372 17.8561 114.244 18.243C114.116 18.6299 113.908 18.9857 113.634 19.2875C113.36 19.5893 113.026 19.8305 112.654 19.9956C112.281 20.1606 111.878 20.2459 111.47 20.2459C111.063 20.2459 110.66 20.1606 110.287 19.9956C109.915 19.8305 109.581 19.5893 109.307 19.2875C109.033 18.9857 108.825 18.6299 108.697 18.243C108.569 17.8561 108.523 17.4467 108.563 17.0411ZM113.127 17.0411C113.127 15.7045 112.526 14.9229 111.472 14.9229C110.415 14.9229 109.82 15.7045 109.82 17.0411C109.82 18.3884 110.415 19.164 111.472 19.164C112.526 19.164 113.127 18.383 113.127 17.0411Z'
        fill='white'
      />
      <path
        d='M116.011 13.961H117.182V14.9406H117.273C117.427 14.5887 117.688 14.2938 118.017 14.0969C118.347 13.9 118.73 13.8109 119.113 13.842C119.413 13.8195 119.714 13.8647 119.995 13.9744C120.275 14.084 120.527 14.2554 120.732 14.4756C120.937 14.6959 121.089 14.9594 121.179 15.2467C121.268 15.534 121.291 15.8378 121.247 16.1354V20.127H120.031V16.441C120.031 15.4501 119.6 14.9573 118.7 14.9573C118.496 14.9478 118.293 14.9825 118.104 15.0589C117.915 15.1354 117.745 15.2518 117.605 15.4002C117.465 15.5486 117.359 15.7255 117.294 15.9188C117.229 16.112 117.206 16.3171 117.228 16.5199V20.1271H116.011V13.961Z'
        fill='white'
      />
      <path
        d='M128.122 12.4238V13.9871H129.458V15.0121H128.122V18.1827C128.122 18.8286 128.388 19.1114 128.994 19.1114C129.149 19.1109 129.304 19.1015 129.458 19.0833V20.097C129.239 20.136 129.018 20.1568 128.796 20.1591C127.443 20.1591 126.904 19.6831 126.904 18.4943V15.012H125.925V13.987H126.904V12.4238H128.122Z'
        fill='white'
      />
      <path
        d='M131.123 11.5527H132.329V14.9507H132.426C132.587 14.5956 132.855 14.299 133.191 14.1013C133.527 13.9037 133.917 13.8146 134.306 13.8461C134.604 13.8299 134.902 13.8797 135.179 13.9921C135.456 14.1044 135.705 14.2765 135.908 14.496C136.111 14.7156 136.263 14.9772 136.353 15.2622C136.443 15.5472 136.469 15.8486 136.429 16.1449V20.1258H135.211V16.4451C135.211 15.4602 134.752 14.9614 133.893 14.9614C133.683 14.9442 133.473 14.973 133.276 15.0456C133.079 15.1183 132.901 15.2331 132.753 15.382C132.605 15.5309 132.491 15.7103 132.42 15.9076C132.349 16.105 132.321 16.3156 132.34 16.5246V20.1258H131.123L131.123 11.5527Z'
        fill='white'
      />
      <path
        d='M143.524 18.4582C143.359 19.0217 143 19.5092 142.512 19.8353C142.024 20.1614 141.436 20.3054 140.852 20.2421C140.446 20.2528 140.042 20.175 139.669 20.014C139.296 19.8531 138.962 19.6129 138.691 19.3101C138.42 19.0073 138.218 18.6491 138.1 18.2605C137.981 17.8718 137.948 17.462 138.004 17.0594C137.95 16.6556 137.983 16.2449 138.101 15.8551C138.22 15.4653 138.421 15.1055 138.69 14.8C138.96 14.4945 139.292 14.2506 139.664 14.0846C140.036 13.9186 140.439 13.8345 140.847 13.838C142.563 13.838 143.598 15.0102 143.598 16.9464V17.371H139.243V17.4392C139.224 17.6655 139.253 17.8933 139.327 18.1079C139.401 18.3225 139.519 18.5193 139.674 18.6856C139.828 18.852 140.016 18.9841 140.225 19.0737C140.433 19.1632 140.659 19.2082 140.886 19.2057C141.177 19.2406 141.472 19.1882 141.733 19.0551C141.994 18.922 142.21 18.7142 142.353 18.4581L143.524 18.4582ZM139.243 16.471H142.358C142.373 16.2641 142.345 16.0562 142.275 15.8608C142.206 15.6654 142.096 15.4867 141.953 15.3363C141.81 15.1858 141.637 15.067 141.445 14.9873C141.254 14.9076 141.048 14.8689 140.84 14.8737C140.63 14.8711 140.421 14.9106 140.226 14.9899C140.031 15.0693 139.854 15.1868 139.705 15.3357C139.556 15.4846 139.439 15.6617 139.359 15.8567C139.28 16.0517 139.241 16.2606 139.243 16.471H139.243Z'
        fill='white'
      />
    </svg>
  )
}
