import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Field, useFormikContext } from 'formik'

import { useSessionEntity } from '../../../global/context/EntityContext'
import { createFormField } from '../../../global/formField/FormField'
import { ClientNciDocumentTypeDto, ClientNciPriorityDto } from '../../../model/ClientNciPriorityDto'
import { TickmillCompaniesEnum } from '../../../utils/companyName.utils'
import { isOne } from '../../../utils/validations'
import { DocumentInfoPageFormValuesStep1 } from './DocumentInfoNCIPageStep1Form'

import styles from './DocumentNumberFieldFactory.module.scss'

const FormField = createFormField<DocumentInfoPageFormValuesStep1>()

interface DocumentNumberFieldFactoryProps {
  accountNciAvailablePriorities: ClientNciPriorityDto
  handleChooseAnotherDocument?: () => void
}

export const DocumentNumberFieldFactory: React.FC<DocumentNumberFieldFactoryProps> = (props) => {
  const { accountNciAvailablePriorities } = props
  const entity = useSessionEntity()

  return (
    <div className={entity === TickmillCompaniesEnum.TICKMILL_UK ? 'pb-5' : 'pt-5 pb-5'}>
      {isOneDocumentType(accountNciAvailablePriorities) && <DocumentNumberField {...props} />}
      {isMultipleDocumentTypes(accountNciAvailablePriorities) && <DocumentRadioField {...props} />}
    </div>
  )
}

const DocumentNumberField: React.FC<DocumentNumberFieldFactoryProps> = (props) => {
  const { accountNciAvailablePriorities, handleChooseAnotherDocument } = props

  const { t } = useTranslation()

  return (
    <React.Fragment>
      <div className='pb-4'>
        <h3>{props.accountNciAvailablePriorities.nciRule.shortName}:</h3>
      </div>
      <FormField
        name='nciDocumentNumber'
        label={accountNciAvailablePriorities.nciRule.shortName || ''}
        placeholder={accountNciAvailablePriorities.nciRule.shortName || ''}
        required
      />
      <div className='pt-2 pb-2'>
        <p className={styles.text}>
          {t('Sign up.Cant provide')} {accountNciAvailablePriorities.nciRule.shortName}?
        </p>
      </div>
      <div className='pt-2 pb-2'>
        <p className={styles.text}>
          <span onClick={handleChooseAnotherDocument} className='is-link'>
            {t('Sign up.Choose another document')}
          </span>
        </p>
      </div>
    </React.Fragment>
  )
}

const DocumentRadioField: React.FC<DocumentNumberFieldFactoryProps> = (props) => {
  const { accountNciAvailablePriorities, handleChooseAnotherDocument } = props
  const { t } = useTranslation()
  const entity = useSessionEntity()

  const { values, setFieldValue, setFieldTouched } =
    useFormikContext<DocumentInfoPageFormValuesStep1>()

  const handleDocument = async (documentType: ClientNciDocumentTypeDto) => {
    await setFieldValue('documentTypeId', documentType.documentTypeId)
    setFieldTouched('documentTypeId')
  }

  return (
    <React.Fragment>
      {entity === TickmillCompaniesEnum.TICKMILL_UK && (
        <FormField
          name='nciDocumentNumber'
          label={accountNciAvailablePriorities.nciRule.shortName || ''}
          placeholder={accountNciAvailablePriorities.nciRule.shortName || ''}
          required
        />
      )}
      {accountNciAvailablePriorities.documentTypes.map((x, index) => (
        <div className='is-flex is-align-items-center mb-2' key={index}>
          <Field
            className={classNames('radio')}
            type='radio'
            required
            value={x.documentTypeId}
            name='documentTypeId'
            onChange={() => handleDocument(x)}
            checked={x.documentTypeId === Number(values.documentTypeId)}
          />
          <label className={classNames(styles.textSmall, 'pl-2')}>{x.name}</label>
        </div>
      ))}
      {entity !== TickmillCompaniesEnum.TICKMILL_UK && (
        <FormField
          name='nciDocumentNumber'
          label={accountNciAvailablePriorities.nciRule.shortName || ''}
          placeholder={accountNciAvailablePriorities.nciRule.shortName || ''}
          required
        />
      )}
      {handleChooseAnotherDocument && (
        <div className='pt-2 pb-2'>
          <p className={styles.text}>
            <span onClick={handleChooseAnotherDocument} className='is-link'>
              {t('Sign up.Choose another document')}
            </span>
          </p>
        </div>
      )}
    </React.Fragment>
  )
}

const isOneDocumentType = (value: ClientNciPriorityDto) => {
  return isOne(value.documentTypes.length)
}
const isMultipleDocumentTypes = (value: ClientNciPriorityDto) => {
  return value.documentTypes.length > 1
}
