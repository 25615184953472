import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useFormatNumber } from '../../../hooks/useFormatNumber'
import { CurrencyType } from '../../../model/WalletDto'
import { useRedirectToSupport } from '../../../utils/useRedirectToSupport'

import styles from './TransferFromModal.module.scss'

export interface BalanceProps {
  balance: number
  currencyId: CurrencyType
  isDisabled: boolean
  freeMargin?: number
  isWallet?: boolean
  isTransferFrom?: boolean
  isTransferTo?: boolean
}

export const Balance: React.FC<BalanceProps> = ({
  balance,
  currencyId,
  isDisabled,
  freeMargin,
  isWallet,
  isTransferFrom,
  isTransferTo,
}) => {
  const { t } = useTranslation()
  const { supportUrl } = useRedirectToSupport()
  const { formatMoney } = useFormatNumber()
  const getRestrictionMessage = (
    isTransferFrom = false,
    isTransferTo = false,
    isWallet = false
  ) => {
    if (isWallet) {
      if (isTransferFrom) {
        return t('Transfer restricted from.TM Wallet')
      } else if (isTransferTo) {
        return t('Transfer restricted to.TM Wallet')
      }
    } else {
      if (isTransferFrom) {
        return t('Transfer restricted from.TM Trading Account')
      } else if (isTransferTo) {
        return t('Transfer restricted to.TM Trading Account')
      }
    }
  }

  return (
    <div className={styles.balance}>
      <p>
        {t('Wallet.Balance')}: {formatMoney(balance, currencyId)}
      </p>
      {freeMargin !== undefined && (
        <p>
          {t('Wallet.Available')}: {formatMoney(freeMargin, currencyId)}
        </p>
      )}
      {isDisabled && (
        <>
          <p className={styles.primaryText}>
            {getRestrictionMessage(isTransferFrom, isTransferTo, isWallet)}
          </p>
          <div>
            {t('Please')}{' '}
            <Link to={supportUrl} target='_blank' rel='noreferrer'>
              {t('Contact Support').toLowerCase()}
            </Link>{' '}
            {t('for more info.')}
          </div>
        </>
      )}
    </div>
  )
}
