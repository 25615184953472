import React from 'react'

import { AccountDetailedDto } from '../../../model/AccountDetailedDto'
import { ClientNciPriorityDto } from '../../../model/ClientNciPriorityDto'
import { useAccountReadContext } from '../../../utils/AccountContextContext'
import { isTickmillAS, isTickmillPartner, isTickmillSC } from '../../../utils/companyName.utils'
import { DocumentInfoNCIPageStep1Page } from './DocumentInfoNCIPageStep1Page'
import { DocumentInfoNonNciPageStep1Page } from './DocumentInfoNonNciPageStep1Page'

interface OuterProps {
  accountNciAvailablePriorities: ClientNciPriorityDto

  onSkip(): void

  onSkipDocuments(): void

  handleSubmitReason(reason: string): void

  handleChoosePreviousDoc(doc: ClientNciPriorityDto, count: number): void

  handleSubmit(): void
}

export const DocumentInfoPageStep1Page: React.FC<OuterProps> = (props) => {
  const { account } = useAccountReadContext()

  return (
    <React.Fragment>
      {isNonNci(account) ? (
        <DocumentInfoNonNciPageStep1Page {...props} />
      ) : (
        <DocumentInfoNCIPageStep1Page {...props} />
      )}
    </React.Fragment>
  )
}

const isNonNci = (account: AccountDetailedDto | undefined) => {
  return isTickmillSC(account) || isTickmillAS(account) || isTickmillPartner(account)
}
