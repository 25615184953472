import { NameDto } from '../model/NameDto'

export enum WalletRestrictions {
  CREATE_WALLET = 2,
  ATTACH_TRADING_ACCOUNTS = 10,
  ADD_TRADING_ACCOUNT = 11,
  DEPOSIT = 12,
  WITHDRAW = 13,
  TRANSFER_FROM = 14,
  TRANSFER_TO = 15,
}

const WalletRestriction: Record<WalletRestrictions, string> = {
  [WalletRestrictions.ATTACH_TRADING_ACCOUNTS]: 'Attach Trading Account',
  [WalletRestrictions.ADD_TRADING_ACCOUNT]: 'Add Trading Account',
  [WalletRestrictions.DEPOSIT]: 'Deposit',
  [WalletRestrictions.WITHDRAW]: 'Withdraw',
  [WalletRestrictions.TRANSFER_FROM]: 'Transfer From',
  [WalletRestrictions.TRANSFER_TO]: 'Transfer To',
  [WalletRestrictions.CREATE_WALLET]: 'Create Wallet',
}

export function isRestricted(restrictionId: WalletRestrictions, restrictions: NameDto[] = []) {
  return (
    !!WalletRestriction[restrictionId] &&
    !!restrictions.find((x) => x.name === WalletRestriction[restrictionId])
  )
}

export function isWalletSuspended(restrictions: NameDto[]) {
  return (
    !!restrictions.find((x) => x.id === WalletRestrictions.DEPOSIT) &&
    !!restrictions.find((x) => x.id === WalletRestrictions.WITHDRAW) &&
    !!restrictions.find((x) => x.id === WalletRestrictions.TRANSFER_FROM) &&
    !!restrictions.find((x) => x.id === WalletRestrictions.TRANSFER_TO)
  )
}
