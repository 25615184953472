import React from 'react'
import { useNavigate } from 'react-router-dom'

import { WalletDepositTransactionSuccessPage } from '../../Traders-Room/Wallets/WalletDeposit/WalletDepositTransactionPage/WalletDepositTransactionSuccessPage'
import { useProductReadContext } from '../../global/context/ProductContext'

export const PaymentAgentWalletDepositTransactionSuccessPage: React.FC = (props) => {
  const navigate = useNavigate()
  const { isDefaultCFDProductType } = useProductReadContext()

  const handleCancel = () => {
    navigate(
      isDefaultCFDProductType()
        ? '/dashboard/payment-agent/wallets'
        : '/dashboard/traders-room/balances'
    )
  }

  return <WalletDepositTransactionSuccessPage {...props} onCancel={handleCancel} />
}
