import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { NameDto } from '../../model/NameDto'
import { useWindowResize } from '../../utils/domUtils'
import { TextSmall } from '../Typography/Typography'

import styles from './SwitcherTabs.module.scss'

interface SwitcherTabsProps {
  tabs?: NameDto<string | number>[]
  handleChangeTab?: (tab: string | number) => void
  initialTab?: string | number | null
  allowEmpty?: boolean
}

export const SwitcherTabs: React.FC<SwitcherTabsProps> = ({
  tabs,
  handleChangeTab,
  initialTab,
  allowEmpty,
}) => {
  const [firstTab] = tabs || []
  const [activeTab, setActiveTab] = useState<string | number | null>(
    initialTab !== undefined ? initialTab : firstTab?.id
  )

  useEffect(() => {
    if (initialTab) setActiveTab(initialTab)
  }, [initialTab])

  const { t } = useTranslation()
  const isMobile = useWindowResize()

  const getTabName = (name: string, isMobile: boolean) => {
    if (isMobile) {
      switch (name) {
        case 'TickmillTrader':
          return t('Tickmill Trader')
        default:
          return t(`${name}`)
      }
    } else {
      switch (name) {
        case 'MT4':
          return t('MetaTrader 4')
        case 'MT5':
          return t('MetaTrader 5')
        case 'TickmillTrader':
          return t('Tickmill Trader')
        default:
          return t(`${name}`)
      }
    }
  }

  return (
    <div className={styles.switcherTabs}>
      {tabs?.map((tab) => {
        const tabName = getTabName(tab.name, isMobile)
        return (
          <div
            key={tab.id}
            onClick={() => {
              setActiveTab(tab.id)
              if (allowEmpty) if (tab.id === activeTab) setActiveTab(null)
              if (handleChangeTab) handleChangeTab(tab.id)
            }}
            className={classNames(styles.tab, {
              [styles.active]: activeTab === tab.id,
            })}
          >
            <TextSmall>{tabName}</TextSmall>
          </div>
        )
      })}
    </div>
  )
}
