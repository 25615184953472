import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import * as XLSX from 'xlsx'

import { Loading } from '../../global/Loading/Loading'
import { Paging } from '../../global/Paging/Paging'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import {
  FilterOptions,
  FilterQueryModal,
  FilterQueryProps,
} from '../../global/filter/FilterQueryModal'
import { ExportModal } from '../../global/modal/ExportModal'
import { Modal } from '../../global/modal/Modal'
import { SortByModal } from '../../global/modal/SortByModal'
import { ClientIbContestCampaignDto } from '../../model/ClientIbContestCampaignDto'
import { FilterOptionsDto } from '../../model/FilterOptionsDto'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { PageQuery, SortOrder, useApiClient } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { useDateFilterWriteContext } from '../../utils/DateFilterContext'
import { ClientApiClient, emptyPaginationResponse } from '../../utils/clientApi'
import { formatDate } from '../../utils/date.utils'
import { useWindowResize } from '../../utils/domUtils'
import { IDateFilter } from '../../utils/filter.utils'
import { normalizeSortLabel } from '../../utils/format.utils'
import { generatePDFTable } from '../../utils/prepare.pdf.utils'
import { useCallbackWithForceRefresh } from '../../utils/useCallbackWithForceRefresh'
import { IAppendableFetchResult, useFetchAppendablePage } from '../../utils/useFetch'
import { isZero } from '../../utils/validations'
import { IBIncomeQuery } from '../Income/IBIncomePage'
import { IBContestCards } from './IBContestCard'
import { IBContestResultsPage } from './IBContestResultsPage'
import { IBContestTable } from './IBContestTable'

import styles from './IBContestPage.module.scss'

export type IBContestSort = 'name' | 'start_date' | 'end_date' | 'status'

const initialQuery = {}

interface GenerateDocumentFileProps {
  clientIbContestCampaigns: ClientIbContestCampaignDto[]
}

const useGenerateDocumentFile = (props: GenerateDocumentFileProps) => {
  const { clientIbContestCampaigns } = props

  const exportFilename = 'ib_contest-campaign'

  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name

  const [isOptionsModalOpen, setOptionsModalOpen] = useState<boolean>(false)

  const handleGeneratePDF = () => {
    const data = tableBody()
    generatePDFTable({ data, fileName: exportFilename })
    setOptionsModalOpen(false)
  }

  const handleGenerateExcel = () => {
    const data = tableBody()
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.aoa_to_sheet(data)

    XLSX.utils.book_append_sheet(wb, ws, exportFilename)
    XLSX.writeFile(wb, `${exportFilename}.xlsx`)

    setOptionsModalOpen(false)
  }

  const tableBody = () => {
    const headerCsvData = [['Campaign name', 'Start Date', 'End Date', 'Status']]
    if (clientIbContestCampaigns) {
      return clientIbContestCampaigns.reduce((previousValue, currentValue) => {
        return previousValue.concat([
          [
            currentValue.name + '',
            formatDate(currentValue.from, dateFormat),
            formatDate(currentValue.to, dateFormat),
            currentValue.status.name,
          ],
        ])
      }, headerCsvData)
    }
    return headerCsvData
  }

  return {
    handleGenerateExcel,
    handleGeneratePDF,
    tableBody,
    isOptionsModalOpen,
    exportFilename,
    setOptionsModalOpen,
  }
}

const useSelectCampaign = () => {
  const [selectedCampaign, setSelectedCampaign] = useState<ClientIbContestCampaignDto | undefined>()

  const handleSelectCampaign = (campaign: ClientIbContestCampaignDto) => {
    setSelectedCampaign(campaign)
  }

  const handleGoBack = () => {
    setSelectedCampaign(undefined)
  }

  return { selectedCampaign, handleSelectCampaign, handleGoBack }
}

interface IbContestCampaignsFetchProps {
  search: SearchResponseProps
}

const useIbContestCampaignsFetch = (props: IbContestCampaignsFetchProps) => {
  const { search } = props

  const locale = useSessionLanguage()
  const apiClient = useApiClient(ClientApiClient)
  const { account } = useAccountReadContext()

  const { callback, forceRefresh } = useCallbackWithForceRefresh(
    async (query?: PageQuery) => {
      if (account?.id) {
        return await apiClient.getIbContestCampaigns({
          ...query,
          ...search.pageQuery,
          search: search.search,
          caller: 'ca',
          languageId: locale,
        })
      }
      return Promise.resolve(emptyPaginationResponse)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search.search, search.pageQuery, locale]
  )
  const query = useFetchAppendablePage(callback)

  return { query, forceRefresh }
}

interface SearchResponseProps {
  clearSearch(callback: () => void): void
  search: FilterQueryProps
  setSearch(value: FilterQueryProps): void
  pageQuery: PageQuery | undefined
  setPageQuery(value: PageQuery): void
}

const useSearch = (props: SortResponseProps): SearchResponseProps => {
  const { sort, sortOrder } = props

  const location = useLocation()
  const { clearFilter } = useDateFilterWriteContext()

  const [search, setSearch] = useState<FilterQueryProps>(initialQuery)
  const [pageQuery, setPageQuery] = useState<PageQuery>({ sort, sortOrder })

  useEffect(() => {
    setSearch(initialQuery)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const clearSearch = (callback: () => void) => {
    callback()
    setSearch(initialQuery)
    clearFilter()
  }

  return {
    clearSearch,
    search,
    setSearch,
    pageQuery,
    setPageQuery,
  }
}

interface SortResponseProps {
  getSortName(): string | undefined
  closeSortModal(): void
  isSortModalOpen: boolean
  setSort(value: IBContestSort): void
  sort: IBContestSort | undefined
  sortOrder: SortOrder | undefined
  setSortOrder(value: SortOrder): void
  setSortModalOpen(value: boolean): void
  sortOptions: { id: string; name: string }[]
}

const useSort = (): SortResponseProps => {
  const { t } = useTranslation()

  const [sort, setSort] = useState<IBContestSort>()
  const [sortOrder, setSortOrder] = useState<SortOrder>()
  const [isSortModalOpen, setSortModalOpen] = useState(false)

  const sortOptions = [
    { id: 'Name', name: t('Campaigns.Campaign name') },
    { id: 'From', name: t('Campaigns.Start Date') },
    { id: 'To', name: t('Campaigns.End Date') },
    { id: 'StatusId', name: t('Campaigns.Status') },
  ]

  const getSortName = () => {
    return sortOptions.find((x) => x.id === sort)?.name
  }

  const closeSortModal = () => {
    setSortModalOpen(false)
  }

  return {
    getSortName,
    closeSortModal,
    isSortModalOpen,
    setSort,
    sort,
    sortOrder,
    setSortOrder,
    setSortModalOpen,
    sortOptions,
  }
}

export const IBContestPage = () => {
  const navigate = useNavigate()
  const isMobile = useWindowResize()

  const { selectedCampaign, handleSelectCampaign, handleGoBack } = useSelectCampaign()

  const { t } = useTranslation()
  const sort = useSort()
  const search = useSearch(sort)
  const ibContestCampaigns = useIbContestCampaignsFetch({ search })
  const generateDocumentFile = useGenerateDocumentFile({
    clientIbContestCampaigns: ibContestCampaigns.query.data,
  })

  useEffect(() => {
    if (
      isZero(ibContestCampaigns?.query?.meta?.totalResults) &&
      ibContestCampaigns?.query?.data?.length <= 0
    ) {
      navigate('/dashboard/introducing-broker/wallets')
    }
  }, [ibContestCampaigns?.query?.meta?.totalResults])

  const handleClearSearch = () => {
    search.clearSearch(() => {
      ibContestCampaigns.query.setPageQuery?.(undefined)
    })
  }

  const handlePageChange = (pageIndex: number, pageSize: number) => {
    ibContestCampaigns.query.setPageQuery!({
      ...ibContestCampaigns.query.pageQuery,
      pageIndex,
      pageSize,
    })
  }

  if (selectedCampaign) {
    return <IBContestResultsPage campaign={selectedCampaign} onGoBack={handleGoBack} />
  }

  return (
    <>
      {generateDocumentFile.isOptionsModalOpen && (
        <Modal
          render={({ closeModal }) => (
            <ExportModal
              fileName={generateDocumentFile.exportFilename}
              csvData={generateDocumentFile.tableBody()}
              onCloseModal={closeModal}
              onExportToCSV={closeModal}
              onExportToPdf={generateDocumentFile.handleGeneratePDF}
              onExportToExcel={generateDocumentFile.handleGenerateExcel}
            />
          )}
          closeModal={() => generateDocumentFile.setOptionsModalOpen(false)}
        />
      )}
      {isMobile && sort.isSortModalOpen && (
        <Modal
          closeModal={sort.closeSortModal}
          render={() => (
            <SortByModal
              onConfirm={(iBContestSort: IBContestSort, sortOrder: SortOrder) => {
                search.setPageQuery?.({
                  ...search.pageQuery,
                  sort: iBContestSort,
                  sortOrder: sortOrder,
                })
                sort.closeSortModal()
              }}
              options={sort.sortOptions}
              onCancel={sort.closeSortModal}
            />
          )}
        />
      )}
      <Header
        {...search}
        query={ibContestCampaigns.query}
        sort={sort}
        clearSearch={handleClearSearch}
        onOptionsModalOpen={() => generateDocumentFile.setOptionsModalOpen(true)}
      />
      <Loading showLoadingIcon isLoading={ibContestCampaigns.query.isLoading}>
        {!ibContestCampaigns.query.data ? (
          <div className={styles.noResult}>{t('No results')}</div>
        ) : (
          <>
            {!isMobile && (
              <IBContestTable
                data={ibContestCampaigns.query.data}
                setSelectedCampaign={handleSelectCampaign}
                pageQuery={search.pageQuery}
                setPageQuery={search.setPageQuery}
              />
            )}
            {isMobile && (
              <IBContestCards
                data={ibContestCampaigns.query.data}
                setSelectedCampaign={handleSelectCampaign}
              />
            )}
          </>
        )}
      </Loading>
      {ibContestCampaigns.query.meta && (
        <Paging
          pageData={ibContestCampaigns.query.meta}
          isLoading={ibContestCampaigns.query.isLoading}
          onPageChanged={handlePageChange}
        />
      )}
    </>
  )
}

interface HeaderProps {
  query: IAppendableFetchResult<ClientIbContestCampaignDto[]>
  search?: FilterQueryProps
  sort: SortResponseProps
  pageQuery: PageQuery | undefined
  setSearch(value: FilterQueryProps): void
  setPageQuery(pageQuery?: PageQuery): void
  clearSearch(): void
  onOptionsModalOpen(): void
}

const Header: React.FC<HeaderProps> = (props) => {
  const { query, pageQuery, sort, onOptionsModalOpen } = props

  const { t } = useTranslation()
  const isMobile = useWindowResize()

  if (query.hasInitialResults) {
    return (
      <>
        {!isMobile && (
          <PageHeader title={t('Campaigns.Campaigns')} optionsToggle={onOptionsModalOpen} />
        )}
        {isMobile && (
          <PageHeader
            title={t('Campaigns.Campaigns')}
            optionsToggle={onOptionsModalOpen}
            filterToggles={{
              openSortModal: () => sort.setSortModalOpen(true),
              sortLabel: normalizeSortLabel(t, sort?.sortOptions, pageQuery?.sort),
            }}
          />
        )}
      </>
    )
  }

  return <PageHeader title={t('Campaigns.Campaigns')} />
}

interface FilterModalProps {
  filterOptions?: FilterOptionsDto
  currentQuery?: IBIncomeQuery
  onConfirm({
    searchFilters,
    currentFilter,
  }: {
    searchFilters?: FilterQueryProps
    currentFilter?: IDateFilter
  }): void
}

export const FilterModal: React.FC<FilterModalProps> = (props) => {
  const { onConfirm, currentQuery, filterOptions } = props
  const [searchFilters, setSearchFilters] = useState(currentQuery)

  return (
    <FilterQueryModal
      searchFilters={searchFilters}
      setSearchFilters={setSearchFilters}
      currencyOptions={filterOptions?.walletCurrencies}
      tradingAccountTypes={filterOptions?.accountTypes}
      entities={filterOptions?.entities}
      filterOptions={[FilterOptions.Date, FilterOptions.Currency]}
      onConfirm={onConfirm}
    />
  )
}
