import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Loading } from '../../global/Loading/Loading'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { FileData } from '../../global/fileUpload/FileUpload'
import { SuccessModal } from '../../global/modal/SuccessModal'
import { BankAccountFormValues } from '../../model/BankAccount'
import { DocumentCategoryType } from '../../model/DocumentCategories'
import { DocumentCategoryOptionType } from '../../model/DocumentCategoryOptionType'
import { DocumentStatus } from '../../model/DocumentStatus'
import { WalletCurrency } from '../../model/WalletDto'
import { PageQuery, useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useCallbackWithForceRefresh } from '../../utils/useCallbackWithForceRefresh'
import { useFetchAppendablePage } from '../../utils/useFetch'
import { CreateBankAccountForm } from './CreateBankAccountForm'

import styles from './CreateBankAccountPage.module.scss'

export interface CreateBankAccountFormValues extends Omit<BankAccountFormValues, 'documentId'> {
  availableCurrencies: WalletCurrency[]
  document?: FileData
  previouslyUploadedDocumentId?: string
}

interface UserDocProps {
  doc: FileData
  id: string
}

const useDocuments = () => {
  const apiClient = useApiClient(ClientApiClient)

  const locale = useSessionLanguage()

  const { callback, forceRefresh } = useCallbackWithForceRefresh(
    (query?: PageQuery) =>
      apiClient.getDocuments({
        ...query,
        search: {
          Type: [DocumentCategoryOptionType.BankStatement].toString(),
          Status: [DocumentStatus.Approved].toString(),
        },
        languageId: locale,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  )
  const query = useFetchAppendablePage(callback)
  return { ...query, forceRefresh }
}

export const CreateBankAccountPage: React.FC = () => {
  const navigate = useNavigate()
  const [availableCurrencies, setAvailableCurrencies] = useState<WalletCurrency[]>([])
  const apiClient = useApiClient(ClientApiClient)
  const [userDoc, setUserDoc] = useState<UserDocProps>()
  const [modal, setModal] = useState(false)
  const { t } = useTranslation()
  const location = useLocation()
  const [createdBankAccountId, setCreatedBankAccountId] = useState<string>()
  const { data: documents, isLoading } = useDocuments()

  const handleSubmitBankAccount = async (values: CreateBankAccountFormValues) => {
    try {
      let documentId = values.previouslyUploadedDocumentId || userDoc?.id

      if (values.document && userDoc?.doc.fileName !== values.document.fileName) {
        const response = await apiClient.uploadDocuments({
          documents: [
            {
              categoryId: DocumentCategoryType.Payment,
              typeId: DocumentCategoryOptionType.BankStatement,
              file: values.document.base64Content,
              filename: values.document.fileName,
            },
          ],
        })
        documentId = response[0]?.id
        setUserDoc({ doc: values.document, id: documentId })
      }
      const { document, availableCurrencies, ...bankAccountData } = values

      if (!documentId) return

      const createdBankAccount = await apiClient.addBankAccount({
        ...bankAccountData,
        documentId: documentId,
      })

      setCreatedBankAccountId(createdBankAccount.id)
      setModal(true)
    } catch (e: unknown) {
      console.error(e)
    }
  }

  const fetchAvailableCurrencies = async () =>
    setAvailableCurrencies(await apiClient.getAllCurrencies())

  useEffect(() => {
    fetchAvailableCurrencies()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const successFooter = () => {
    if (location?.state?.walletId) {
      return (
        <div className={styles.successFooter}>
          <button className='button' onClick={() => navigate('/profile/bank-accounts')}>
            {t('Close')}
          </button>
          <button
            className='button'
            onClick={() =>
              navigate(`/dashboard/traders-room/wallets/${location.state.walletId}/withdrawal`, {
                state: { values: location.state?.values, bankAccountId: createdBankAccountId },
              })
            }
          >
            {t('Wallet.Back to Withdrawal Form')}
          </button>
        </div>
      )
    } else {
      return (
        <button className='button' onClick={() => navigate('/profile/bank-accounts')}>
          {t('OK')}
        </button>
      )
    }
  }

  return (
    <Loading isLoading={isLoading}>
      {modal && (
        <SuccessModal
          title={t('Success')}
          body={<span>{t('Bank Account Added')}</span>}
          onConfirm={() => navigate('/profile/bank-accounts')}
          renderFooter={successFooter}
        />
      )}
      <CreateBankAccountForm
        availableCurrencies={availableCurrencies}
        onSubmit={handleSubmitBankAccount}
        documents={documents}
      />
    </Loading>
  )
}
