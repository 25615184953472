import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { InformationModal } from '../../../global/modal/InformationModal'
import { Modal } from '../../../global/modal/Modal'
import { TermsModal } from '../../../global/modal/TermsModal'
import { PaymentProvider } from '../../../model/PaymentProviderDto'
import {
  Wallet,
  WalletDepositFormValues,
  getCurrencyLimits,
} from './WalletDepositForm/WalletDepositForm'

interface TermsModalProps {
  onClose(): void
}

interface WalletDepositCUPTermProps {
  title: string
  terms: string[]
  onClose(): void
}
export const WalletDepositCUPTermsModal: React.FC<WalletDepositCUPTermProps> = ({
  title,
  terms,
  onClose,
}) => {
  return (
    <Modal
      closeModal={onClose}
      render={() => (
        <InformationModal title={title} onCancel={onClose}>
          <ul>
            {terms.map((x, index) => (
              <li
                key={index}
                dangerouslySetInnerHTML={{
                  __html: `${index + 1}. ${x}`,
                }}
              ></li>
            ))}
          </ul>
        </InformationModal>
      )}
    />
  )
}
export const WalletDepositTermsModal: React.FC<TermsModalProps> = ({ onClose }) => {
  const { values } = useFormikContext<WalletDepositFormValues>()
  const { t } = useTranslation()

  return (
    <TermsModal
      title={t('Wallet.General Terms and Conditions for Deposits')}
      terms={values.paymentProvider.parameters.termsConditions}
      renderItem={(name) =>
        walletDepositTermsConditionsMessageFormat(name, values.wallet, values.paymentProvider)
      }
      onClose={onClose}
    />
  )
}

const walletDepositTermsConditionsMessageFormat = (
  message: string,
  wallet: Wallet,
  paymentProvider: PaymentProvider
): string => {
  const currencyLimit = getCurrencyLimits(wallet, paymentProvider)
  const minAmount = currencyLimit?.minAmount?.toString() || '0'
  const maxAmount = currencyLimit?.maxAmount?.toString() || '0'

  const currency = paymentProvider.currency.id || ''
  return message
    .replace(/{minAmount}/g, minAmount)
    .replace(/{maxAmount}/g, maxAmount)
    .replace(/{paymentProviderCurrency}/g, currency)
}
