import { useTranslation } from 'react-i18next'

import { FormWarningMessage, FormWarningMessageGroup } from '../global/form/FormWarningMessage'
import { PaymentProvider } from '../model/PaymentProviderDto'
import { isPaymentProviderSCOrbitalCrypto } from '../model/PaymentProviderId'

import styles from './DepositFormWarningNote.module.scss'

const paymentProviderMethods = [
  { name: 'SC Alogateway CUP', message: 'china info' },
  { name: 'SC Transact365 CNY', message: 'This payment provider channel is' },
]

export const DepositFormWarningNote = ({
  paymentProvider,
}: {
  paymentProvider: PaymentProvider
}) => {
  const { t } = useTranslation()

  return (
    <>
      {paymentProviderMethods.map(
        (paymentProviderMethod) =>
          paymentProvider.method_name === paymentProviderMethod.name && (
            <FormWarningMessageGroup>
              <FormWarningMessage
                message={
                  <>
                    <span className='has-text-danger pr-1'>{t('Note')}</span>
                    {t(paymentProviderMethod.message)}
                  </>
                }
              />
            </FormWarningMessageGroup>
          )
      )}

      {paymentProvider.name === 'Crypto Currency' && (
        <>
          <FormWarningMessageGroup>
            <FormWarningMessage message={<>{t('Crypto info')}</>} />
          </FormWarningMessageGroup>
          {isPaymentProviderSCOrbitalCrypto(paymentProvider.id) && (
            <div className={styles.warningMessage}>
              {t(
                'Wallet.The wallet address and exchange rate will expire within 1 hour, please make your payment within this time.'
              )}
            </div>
          )}
        </>
      )}
    </>
  )
}
