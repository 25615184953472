import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFormikContext } from 'formik'

import { Loading } from '../../../global/Loading/Loading'
import { useSessionLanguage } from '../../../global/context/SessionSettingsContext'
import {
  PaymentProviderDepositModalItem,
  PaymentProviderModal,
} from '../../../global/modal/PaymentProviderModal'
import { PaymentProvider } from '../../../model/PaymentProviderDto'
import { WalletPaymentProvider } from '../../../model/WalletPaymentProvider'
import { useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import { useCallbackWithForceRefresh } from '../../../utils/useCallbackWithForceRefresh'
import { useFetchOne } from '../../../utils/useFetch'
import { WalletDepositFormValues } from './WalletDepositForm/WalletDepositForm'
import { usePaymentProviderFactory } from './usePaymentProviderFactory'

interface PaymentProviderModalProps {
  onSelectOption(option: PaymentProvider, walletPaymentProvider: WalletPaymentProvider): void
  onClose(): void
}

export const WalletDepositPaymentProviderModal: React.FC<PaymentProviderModalProps> = ({
  onSelectOption,
  onClose,
}) => {
  const { walletId } = useParams<{ walletId?: string }>()
  const { values } = useFormikContext<WalletDepositFormValues>()

  const apiClient = useApiClient(ClientApiClient)
  const locale = useSessionLanguage()
  const { createPaymentProvider } = usePaymentProviderFactory()

  const [isSelectLoading, setSelectLoading] = useState<boolean>(false)

  const { callback } = useCallbackWithForceRefresh(async () => {
    if (values.wallet.id) return apiClient.getWalletDepositMethods(locale, values.wallet.id)
    if (walletId) return apiClient.getWalletDepositMethods(locale, walletId)
  }, [locale])

  const { data: paymentProviders = [], isLoading } = useFetchOne(callback)

  const handleSelectOption = async (walletPaymentProvider: WalletPaymentProvider) => {
    setSelectLoading(true)
    if (values.wallet.currency) {
      const paymentProvider = await createPaymentProvider(walletPaymentProvider, values.wallet)
      onSelectOption(paymentProvider, walletPaymentProvider)
    }
  }

  return (
    <PaymentProviderModal
      render={() => (
        <Loading isModal isLoading={isLoading || isSelectLoading} showLoadingIcon>
          {paymentProviders.map((x) => (
            <PaymentProviderDepositModalItem
              item={x}
              onSelectOption={handleSelectOption}
              key={x.method.id}
            />
          ))}
        </Loading>
      )}
      onClose={onClose}
    />
  )
}
