import { ChangeEvent, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useEntitySettings } from '../global/context/EntityContext'
import { useProductReadContext } from '../global/context/ProductContext'
import {
  hasProductActivatedStatus,
  hasProductAppropriatenessTestSubmittedStatus,
  hasProductPendingDocumentVerificationStatus,
  isAccountDetailedActivatedStatus,
  isProductAppropriatenessValid,
} from '../model/AccountDetailedDto'
import { isAccountCorporate, showMarketDataClassification } from '../model/AccountType'
import { isTickmillProductTypeETD } from '../model/TickmillProductType'
import { useAccountReadContext, useAccountWriteContext } from '../utils/AccountContextContext'
import { useApiClient } from '../utils/ApiClient'
import { AuthSessionContext } from '../utils/AuthContext'
import { useIBCampaignsResultsWriteContext } from '../utils/IBCampaignsResultsContext'
import { SharedContext, initialSharedContext } from '../utils/SharedContext'
import { ClientApiClient } from '../utils/clientApi'
import { getTickmillCompanyByHostname, isTickmillUK } from '../utils/companyName.utils'
import { clearLocalStorage } from '../utils/useStorage'

interface ProfileMenuItem {
  label: string
  to?: string
  hidden?: boolean
  onClick?: (event: ChangeEvent<{}>) => void
}

export const useAccountMenu = (): ProfileMenuItem[] => {
  const apiClient = useApiClient(ClientApiClient)
  const navigate = useNavigate()

  const [auth, setAuth] = useContext(AuthSessionContext)
  const [, setSharedState] = useContext(SharedContext)

  const [, setEntity] = useEntitySettings()

  const { clearAccount } = useAccountWriteContext()
  const { t } = useTranslation()
  const { refreshIBCampaigns } = useIBCampaignsResultsWriteContext()

  const { account } = useAccountReadContext()
  const { product } = useProductReadContext()
  const isTickmillUKType = isTickmillUK(account)

  const { isDefaultCFDProductType } = useProductReadContext()
  const isProductTypeCFD = isDefaultCFDProductType()

  const handleLogOut = useCallback(
    async (event: ChangeEvent<{}>) => {
      event.preventDefault()
      if (auth) {
        await apiClient.logOut(auth.id)
      }
      refreshIBCampaigns()
      setSharedState(initialSharedContext)
      clearAccount()
      clearLocalStorage()
      setAuth(null)
      setEntity({ entity: getTickmillCompanyByHostname() })
      navigate('/logout')
    },
    [apiClient, auth, clearAccount, navigate, refreshIBCampaigns, setAuth, setSharedState]
  )

  const isAccountAppropriatenessTest = isProductAppropriatenessValid(product, account)
  const isAccountPending =
    hasProductAppropriatenessTestSubmittedStatus(account, product) ||
    hasProductPendingDocumentVerificationStatus(account, product)

  const isAccountActivated = isTickmillUKType
    ? hasProductActivatedStatus(account, product)
    : isAccountDetailedActivatedStatus(account?.status.id || -1)

  return useMemo(
    () => [
      {
        label: t('My Profile'),
        to: '/profile/personal-info',
      },
      {
        label: t('Profile.Appropriateness Test'),
        to: 'profile/appropriateness-test',
        hidden: !isAccountAppropriatenessTest,
      },
      {
        label: t('Profile.My Documents'),
        to: '/profile/document-management',
        hidden: isAccountAppropriatenessTest,
      },
      {
        label: t('Profile.Client Classification'),
        to: '/profile/client-classification',
        hidden: !(
          hasProductActivatedStatus(account, product) &&
          account?.companyConfiguration.hasClassification
        ),
      },
      {
        label: t('Profile.tax form'),
        to: '/profile/tax-form',
        hidden:
          !account?.companyConfiguration.hasStocks ||
          !isAccountActivated ||
          isTickmillProductTypeETD(product),
      },
      {
        label: t('Profile.W-8BEN-e Form'),
        to: '/profile/e-tax-form',
        hidden: !(isAccountActivated && isAccountCorporate(account)),
      },
      {
        label: t('Profile.Introducing Broker'),
        to: '/profile/introducing-broker',
        hidden: !account?.companyConfiguration.hasIBRoom || isAccountPending || !isAccountActivated,
      },
      {
        label: t('Account Settings'),
        to: '/profile/account-settings',
      },
      {
        label: t('Profile.Security Settings'),
        to: '/profile/security-settings',
      },
      {
        label: t('Profile.Market Data Classification'),
        to: '/profile/market-data-classification',
        hidden: !showMarketDataClassification(account, product),
      },
      {
        label: t('My Bank Accounts'),
        to: '/profile/bank-accounts',
        hidden: !isAccountActivated,
      },
      {
        label: t('My Phone Numbers'),
        to: '/profile/phone-numbers',
      },
      {
        label: t('Profile.Logout'),
        onClick: (e) => handleLogOut(e),
      },
    ],
    [t, isAccountActivated, account, handleLogOut, product, isProductTypeCFD]
  )
}
