import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../global/button/Button'
import { ConfirmationModal } from '../../global/modal/ConfirmationModal'
import { Modal } from '../../global/modal/Modal'

import styles from './DocumentsInfoPage.module.scss'

export const DocumentInfoPageStepPendingForm: React.FC<OuterProps> = (props) => {
  const { onSkipDocuments, handleSubmit } = props
  const { t } = useTranslation()

  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)

  const handleConfirmationModalOpen = () => {
    setConfirmationModalOpen(true)
  }

  const handleConfirmationModalClose = () => {
    setConfirmationModalOpen(false)
  }

  return (
    <React.Fragment>
      {isConfirmationModalOpen && (
        <Modal
          closeModal={handleConfirmationModalClose}
          cardClassName={styles.modal}
          render={() => (
            <ConfirmationModal
              title={t('Sign up.Skip document upload?')}
              renderFooter={() => (
                <React.Fragment>
                  <button className='button' onClick={handleConfirmationModalClose} type='button'>
                    {t('Sign up.Back to Upload')}
                  </button>
                  <button className='submit' onClick={onSkipDocuments} type='button'>
                    {t('Sign up.Skip upload')}
                  </button>
                </React.Fragment>
              )}
              onConfirm={onSkipDocuments}
              onCancel={handleConfirmationModalClose}
            >
              <p>
                {t(
                  'Sign up.You can skip document upload, but uploading documents is required step to become Tickmill customer.'
                )}
              </p>
            </ConfirmationModal>
          )}
        />
      )}
      <div>
        <h3>{t('Sign up.Document Verification')}</h3>
        <p className={styles.text}>
          {t(
            'Sign up.As a regulated entity we need these documents to verify who you are and where you live'
          )}
          :
        </p>

        <div className='pt-5'>
          <p className='content'>{t('Sign up.1. Proof of ID Document')}</p>
          <p className='content'>{t('Sign up.2. Proof of ID Address')}</p>
          <p className='content'>{t('Sign up.3. Proof of ID Residence Permit')}</p>
        </div>

        <div className='is-flex is-justify-content-center'>
          <Button
            onClick={handleConfirmationModalOpen}
            className={styles.button}
            type='button'
            appearance='secondary'
            size='L'
          >
            {t('Skip')}
          </Button>
          <Button
            type='button'
            appearance='primary'
            size='L'
            onClick={handleSubmit}
            className={styles.button}
          >
            {t('Submit')}
          </Button>
        </div>
      </div>
    </React.Fragment>
  )
}

interface OuterProps {
  onSkip(): void

  onSkipDocuments(): void

  handleSubmit(): void
}
