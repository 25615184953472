import React from 'react'

import { NameDto } from '../../../../model/NameDto'
import { SwitcherTabs } from '../../../../ui/SwitcherTabs/SwitcherTabs'
import { FilterOptions, FilterQueryProps } from '../../FilterQueryModal'

import styles from './FilterModalToggleFilter.module.scss'

interface FilterModalChipFilterProps {
  name: string
  options: NameDto<string | number>[]
  filterType: FilterOptions
  currencies?: NameDto<string | number>[]
  setState: (value: React.SetStateAction<FilterQueryProps | undefined>) => void
  state?: FilterQueryProps
}

export const FilterModalToggleFilter: React.FC<FilterModalChipFilterProps> = (props) => {
  const { options, setState, filterType, state, name } = props

  const getInitialState = () => {
    const initialState = state?.[filterType]

    if (initialState) return initialState.toString()
    else return null
  }

  const handleUpdate = (option: string | number) => {
    const newState = {
      ...state,
      [filterType]: state?.[filterType] === option ? undefined : option,
    }

    setState(newState)
  }

  return (
    <div className={styles.toggleFilter}>
      <span className={styles.name}>{name}</span>
      <SwitcherTabs
        initialTab={getInitialState()}
        allowEmpty
        tabs={options}
        handleChangeTab={handleUpdate}
      />
    </div>
  )
}
