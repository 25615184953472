import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { Button } from '../../../../global/button/Button'
import { DocumentFileUpload } from '../../../../global/fileUpload/DocumentFileUpload'
import { FileData } from '../../../../global/fileUpload/FileUpload'
import { FieldGroup } from '../../../../global/form/FieldGroup'
import { createFormField } from '../../../../global/formField/FormField'
import { createFormTextField } from '../../../../global/formField/FormTextField'
import { TransactionFormActionButtonTemplate } from '../../../../global/templates/TransactionFormActionButtonTemplate'
import { DocumentCategoryType } from '../../../../model/DocumentCategories'
import { WalletDto, isWallet } from '../../../../model/WalletDto'
import { CurrencyAmountField } from './CurrencyAmountField'
import { PaymentAgentTransferFormValues } from './PaymentAgentTransferForm'

import styles from './PaymentAgentTransferClientDetailsFields.module.scss'

interface PaymentAgentTransferClientDetailsFieldsProps {
  onCancel(): void
}
interface PaymentAgentTransferClientDetailsFormValues {
  client: {
    name: string
  }
  clientWallet: WalletDto
  paymentProviderWallet?: WalletDto
  campaignName: string
  system: string
  account: string
  comment: string
}

const FormField = createFormField<PaymentAgentTransferClientDetailsFormValues>()
const FormTextField = createFormTextField<PaymentAgentTransferClientDetailsFormValues>()

export const PaymentAgentTransferClientDetailsFields: React.FC<
  PaymentAgentTransferClientDetailsFieldsProps
> = (props) => {
  const { onCancel } = props

  const { values, isValid, setValues } = useFormikContext<PaymentAgentTransferFormValues>()

  const handleUploadDocumentField = (data: FileData) => {
    setValues({
      ...values,
      fileName: data.fileName,
      fileData: data,
      base64Content: data.base64Content,
    })
  }

  const { t } = useTranslation()

  return (
    <React.Fragment>
      <FormTextField name='clientWallet.name' label={t('Wallet.Client Wallet Name')} required />
      <FormTextField name='client.name' label={t('Wallet.Client Name')} required />

      {isWallet(values.paymentProviderWallet) && (
        <FormTextField
          name='paymentProviderWallet.currency.id'
          label={t('Wallet.Transfer From')}
          value={
            values?.paymentProviderWallet?.currency &&
            `${values.paymentProviderWallet?.currency?.id} ${t('Wallet.Wallet')}`
          }
          hint={
            values?.paymentProviderWallet?.currency &&
            `${t('Wallet.Balance')} ${values.paymentProviderWallet.balance} ${
              values.paymentProviderWallet.currency?.id
            }`
          }
        />
      )}

      <FieldGroup>
        <CurrencyAmountField />
        <div className={styles.currencyFieldWrapper}>
          <FormField name='clientWallet.currency.id' label={t('Wallet.Currency')} disabled />
        </div>
      </FieldGroup>

      <div className='pt-4'>
        <div className='pb-4'>
          <h3>{t('Wallet.Additional Transaction Details')}</h3>
        </div>
        <FormField
          name='system'
          label={t("Wallet.Client's Bank or Payment System")}
          placeholder={t("Wallet.Client's Bank or Payment System")}
          required
        />
        <FormField
          name='account'
          label={t("Wallet.Client's Bank or Payment System Account")}
          placeholder={t("Wallet.Client's Bank or Payment System Account")}
          required
        />
        <FormField
          name='comment'
          label={t('Wallet.Additional Comment')}
          placeholder={t('Wallet.Additional Comment')}
        />
        <FormField
          name='campaignName'
          label={t('Wallet.Campaign Account')}
          placeholder={t('Wallet.Campaign Account')}
        />
      </div>

      <div className='pt-4 pb-4'>
        <span className={styles.wallet}>{t('Wallet.WalletProof of Payment')} *</span>
        <div>
          <DocumentFileUpload
            title={t('Wallet.Document front side:')}
            file={values.fileData}
            documentCategory={DocumentCategoryType.PaymentAgent}
            onUpload={handleUploadDocumentField}
          />
        </div>
      </div>

      <TransactionFormActionButtonTemplate>
        <Button appearance='secondary' size='L' type='button' onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button appearance='primary' size='L' type='submit' disabled={!isValid}>
          {t('Confirm')}
        </Button>
      </TransactionFormActionButtonTemplate>
    </React.Fragment>
  )
}
