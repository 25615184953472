import { NameDto } from './NameDto'

export enum PlatformTypeEnum {
  MT4 = 1,
  MT5 = 2,
  CQGCAST = 3,
  TickmillTrader = 4,
  None = 0,
}

export const getPlatformType = (platformType: PlatformTypeEnum): NameDto => {
  if (platformType === PlatformTypeEnum.MT4) {
    return { id: PlatformTypeEnum.MT4, name: 'MT4' }
  }

  if (platformType === PlatformTypeEnum.MT5) {
    return { id: PlatformTypeEnum.MT5, name: 'MT5' }
  }

  if (platformType === PlatformTypeEnum.CQGCAST) {
    return { id: PlatformTypeEnum.CQGCAST, name: 'CQGCAST' }
  }

  return { id: PlatformTypeEnum.None, name: 'None' }
}

export const getPlatformTypes = (...platformTypes: PlatformTypeEnum[]): NameDto[] => {
  const data = [
    { id: PlatformTypeEnum.MT4, name: 'MT4' },
    { id: PlatformTypeEnum.MT5, name: 'MT5' },
    { id: PlatformTypeEnum.CQGCAST, name: 'CQGCAST' },
  ]
  return platformTypes.length > 0 ? data.filter((x) => platformTypes?.includes(x.id)) : data
}

export enum OperatingSystemEnum {
  WINDOWS = 1,
  OSX = 2,

  WEB_TRADER = 3,

  ANDROID = 4,
  ANDROID_APK = 6,
  IOS = 5,
}

export enum OperatingSystemNameEnum {
  iOS = 'iOS',
  Android = 'Android',
  Windows_Phone = 'Windows Phone',
}

export const isMT4PlatformType = (platformType?: PlatformTypeEnum): boolean => {
  return platformType === PlatformTypeEnum.MT4
}

export const isMT5PlatformType = (platformType?: PlatformTypeEnum): boolean => {
  return platformType === PlatformTypeEnum.MT5
}

export const isTMTPlatformType = (platformType?: PlatformTypeEnum): boolean => {
  return platformType === PlatformTypeEnum.TickmillTrader
}

export const isPlatformTypeCQGCAST = (platformType: PlatformTypeEnum): boolean => {
  return platformType === PlatformTypeEnum.CQGCAST
}

export const getVersionByPlatformType = (platformType: PlatformTypeEnum | undefined): string => {
  switch (platformType) {
    case PlatformTypeEnum.MT4:
      return '4'
    case PlatformTypeEnum.MT5:
      return '5'
    default:
      return ''
  }
}

export const getPlatformTypeByVersion = (
  version: string | undefined
): PlatformTypeEnum | undefined => {
  switch (version) {
    case '4':
      return PlatformTypeEnum.MT4
    case '5':
      return PlatformTypeEnum.MT5
  }
}
