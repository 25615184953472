import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Badge } from '../../global/badge/Badge'
import { Button } from '../../global/button/Button'
import { InformationModal } from '../../global/modal/InformationModal'
import { Modal } from '../../global/modal/Modal'
import { ProductServiceModal } from '../../global/modal/ProductServiceModal'
import { CheckIcon } from '../../icons/CheckIcon'
import { ChevronDownIcon } from '../../icons/ChevronDownIcon'
import { ChevronUpIcon } from '../../icons/ChevronUpIcon'
import { ClockIcon } from '../../icons/ClockIcon'
import { IconProps } from '../../icons/IconProps'
import { InfoIcon } from '../../icons/InfoIcon'
import { SubscriptionBundleDto } from '../../model/SubscriptionBundleDto'
import { SubscriptionCartItem } from '../../model/SubscriptionCart'
import { SubscriptionGroupDto } from '../../model/SubscriptionGroupDto'
import { SubscriptionRequestState } from '../../model/SubscriptionRequestState'
import { SubscriptionRequestTypeEnum } from '../../model/SubscriptionRequestTypeEnum'
import {
  Subscription,
  isSubscriptionActive,
  isSubscriptionImmediateAndPending,
  isSubscriptionPending,
} from '../../model/SubscriptionTypes'
import {
  SubscriptionRequestType,
  UpdateSubscriptionRequest,
} from '../../model/UpdateSubscriptionRequest'
import { TextSmall, TextSmallStrong } from '../../ui/Typography/Typography'
import { PriceRange } from '../SubscriptionsPriceRange'
import { SubscriptionGroupSubscription } from './SubscriptionGroupSubscription'

import styles from './MarketDataPage.module.scss'

interface SubscriptionGroupStatusProps {
  isCollapsed: boolean
  setIsCollapsed(visible: boolean): void
  hasActiveSubscriptionRequest(): boolean
  hasPendingSubscriptionRequest(): boolean
  hasPendingUnsubscriptionRequest(): boolean
  hasProcessSubscriptionRequest(): boolean
  hasDeductingFundOrInProcessSubscriptionRequest(): boolean
  isSubscribedToBundle: boolean
  onSetActivePackageModal(): void
  isCancellationRequested(): boolean
  isActiveAndCancellationRequested(): boolean
}

const SubscriptionGroupStatus: React.FC<SubscriptionGroupStatusProps> = ({
  setIsCollapsed,
  hasActiveSubscriptionRequest,
  hasPendingSubscriptionRequest,
  hasPendingUnsubscriptionRequest,
  hasDeductingFundOrInProcessSubscriptionRequest,
  hasProcessSubscriptionRequest,
  isSubscribedToBundle,
  onSetActivePackageModal,
  isCollapsed,
  isCancellationRequested,
  isActiveAndCancellationRequested,
}) => {
  const { t } = useTranslation()
  switch (true) {
    case hasActiveSubscriptionRequest():
      return <Badge appearance='success' text={t('Subscriptions.Active')} />
    case isSubscribedToBundle:
      return <ActiveViaPackage onSetActivePackageModal={onSetActivePackageModal} />
    case hasProcessSubscriptionRequest():
      return isCollapsed ? null : <Badge text={t('Market Data.Processing')} appearance='pending' />
    case hasPendingUnsubscriptionRequest():
      return <Badge text={t('Subscriptions.Pending')} appearance='pending' />
    case hasDeductingFundOrInProcessSubscriptionRequest():
      return isCollapsed ? null : (
        <Badge appearance='warning' text={t('Subscriptions.Processing')} />
      )
    case isActiveAndCancellationRequested():
      return <Badge text={t('Subscriptions.Pending')} appearance='pending' />
    case isCancellationRequested():
      return <Badge text={t('Subscriptions.Pending Cancellation')} appearance='pending' />
    case hasPendingSubscriptionRequest():
      return <Badge text={t('Subscriptions.Pending')} appearance='pending' />

    default:
      return isCollapsed ? null : (
        <Button
          size='XS'
          appearance='primary'
          onClick={() => {
            setIsCollapsed(true)
          }}
        >
          {t('Subscriptions.Subscribe')}
        </Button>
      )
  }
}

interface ActiveViaPackageProps {
  onSetActivePackageModal(): void
}

export const ActiveViaPackage: React.FC<ActiveViaPackageProps> = ({ onSetActivePackageModal }) => {
  const { t } = useTranslation()

  return (
    <div className='is-flex'>
      <Badge text={t('Subscriptions.Active Via Bundle')} appearance='tertiary' />
      <span className='ml-2 pt-1' onClick={onSetActivePackageModal}>
        <InfoIcon />
      </span>
    </div>
  )
}

interface SubscriptionGroupProps {
  subscribedBundles: SubscriptionBundleDto[]
  subscriptionGroup: SubscriptionGroupDto
  onUpdateSubscription(data: UpdateSubscriptionRequest): void
  onAddCartItem(
    subscriptionDetailId: string,
    subscriptionRequestTypeId: SubscriptionRequestType
  ): void
  scrollToCart?: () => void
  nonActiveSubscriptions: SubscriptionCartItem[]
}

interface ProductStatusIconProps extends IconProps {
  subscription?: Subscription
  bundle?: SubscriptionBundleDto
}

export const ProductStatusIcon: React.FC<ProductStatusIconProps> = (props) => {
  const { subscription, bundle } = props

  if (subscription) {
    if (isSubscriptionActive(subscription)) {
      return <CheckIcon {...props} color={'success'} size={16} />
    } else if (isSubscriptionPending(subscription.subscriptionRequest)) {
      return <ClockIcon {...props} color={'warning'} />
    }
  }

  return null
}
export const SubscriptionGroup: React.FC<SubscriptionGroupProps> = ({
  subscriptionGroup,
  subscribedBundles,
  onUpdateSubscription,
  onAddCartItem,
  scrollToCart,
  nonActiveSubscriptions,
}) => {
  const { t } = useTranslation()

  const [isCollapsed, setIsCollapsed] = useState(false)
  const isSubscribedToBundle = !!subscribedBundles.filter(
    (bundle) => !!bundle.bundledGroups.filter((bg) => bg.id === subscriptionGroup.id).length
  ).length
  const [activePackageModal, setActivePackageModal] = useState(false)

  const hasActiveSubscriptionRequest = () => {
    return !!subscriptionGroup.subscriptions.find(
      (subscription) => subscription.activeSubscriptionRequest
    )
  }
  const hasPendingSubscriptionRequest = () => {
    return !!subscriptionGroup.subscriptions.find(
      (subscription) =>
        subscription.subscriptionRequest?.state?.name === SubscriptionRequestState.Pending &&
        subscription.subscriptionRequest?.type?.name ===
          SubscriptionRequestTypeEnum.AddNewSubscription
    )
  }

  const hasProcessSubscriptionRequest = () => {
    return !!subscriptionGroup.subscriptions.find((subscription) =>
      isSubscriptionImmediateAndPending(subscription.subscriptionRequest)
    )
  }

  const hasPendingUnsubscriptionRequest = () => {
    return !!subscriptionGroup.subscriptions.find(
      (subscription) =>
        subscription.subscriptionRequest?.state?.name === SubscriptionRequestState.Pending &&
        subscription.subscriptionRequest?.type?.name ===
          SubscriptionRequestTypeEnum.CancelExistingSubscription
    )
  }

  const hasDeductingFundOrInProcessSubscriptionRequest = () => {
    return !!subscriptionGroup.subscriptions.find(
      (subscription) =>
        subscription.subscriptionRequest?.state?.name === SubscriptionRequestState.DeductingFunds ||
        subscription.subscriptionRequest?.state?.name === SubscriptionRequestState.InProcess
    )
  }

  const isCancellationRequested = () => {
    return !!subscriptionGroup.subscriptions.find(
      (subscription) =>
        (subscription.subscriptionRequest?.state?.name === SubscriptionRequestState.Pending &&
          subscription.subscriptionRequest?.type?.name ===
            SubscriptionRequestTypeEnum.CancelExistingSubscription) ||
        subscription.subscriptionRequest?.state?.name === SubscriptionRequestState.InProcessCancel
    )
  }

  const isActiveAndCancellationRequested = () => {
    return !!subscriptionGroup.subscriptions.find(
      (subscription) =>
        (subscription?.activeSubscriptionRequest?.state?.name === SubscriptionRequestState.Active &&
          subscription.subscriptionRequest?.state?.name === SubscriptionRequestState.Pending &&
          subscription.subscriptionRequest?.type?.name ===
            SubscriptionRequestTypeEnum.CancelExistingSubscription) ||
        subscription.subscriptionRequest?.state?.name === SubscriptionRequestState.InProcessCancel
    )
  }

  const [productDescriptionModal, setProductDescriptionModal] = useState<SubscriptionGroupDto>()

  const handleOnSubscriptionGroupSelected = (group: SubscriptionGroupDto) => {
    setProductDescriptionModal(group)
  }

  const handleOnProductDescriptionClose = () => {
    setProductDescriptionModal(undefined)
  }

  const toggleIsCollapsed = () => setIsCollapsed((prev) => !prev)

  const handleOnSetActivePackageModal = () => setActivePackageModal(true)

  return (
    <React.Fragment>
      {activePackageModal && (
        <Modal
          closeModal={() => setActivePackageModal(false)}
          render={({ closeModal }) => (
            <InformationModal
              title={t('Market Data.Included in Active Package')}
              onCancel={closeModal}
              buttonText={t('Got It')}
            >
              <p className='text'>
                {t(
                  'Market Data.The product is already included in your active package subscription'
                )}
              </p>
            </InformationModal>
          )}
        />
      )}

      {productDescriptionModal && (
        <ProductServiceModal
          showModal={productDescriptionModal}
          setShowModal={handleOnProductDescriptionClose}
        />
      )}

      <div className={styles.product}>
        <div className={classNames(styles.subHeader)}>
          <div className={classNames(styles.name)}>
            <p
              onClick={() => handleOnSubscriptionGroupSelected(subscriptionGroup)}
              className='is-link'
            >
              <TextSmallStrong>{subscriptionGroup.name}</TextSmallStrong>
            </p>
            <Button className='pl-0' appearance='plain' onClick={toggleIsCollapsed}>
              <TextSmall className='text-secondary'>{subscriptionGroup.subName}</TextSmall>
            </Button>
            {!isCollapsed && (
              <div className={classNames('is-hidden-desktop is-hidden-tablet-only', styles.price)}>
                <PriceRange
                  minAmount={subscriptionGroup.minAmount}
                  maxAmount={subscriptionGroup.maxAmount}
                />
              </div>
            )}
          </div>
          <div className={classNames('is-hidden-mobile', styles.price)}>
            {!isCollapsed && (
              <PriceRange
                minAmount={subscriptionGroup.minAmount}
                maxAmount={subscriptionGroup.maxAmount}
              />
            )}
          </div>
          <div className={styles.status}>
            <SubscriptionGroupStatus
              hasActiveSubscriptionRequest={hasActiveSubscriptionRequest}
              hasPendingSubscriptionRequest={hasPendingSubscriptionRequest}
              hasPendingUnsubscriptionRequest={hasPendingUnsubscriptionRequest}
              hasDeductingFundOrInProcessSubscriptionRequest={
                hasDeductingFundOrInProcessSubscriptionRequest
              }
              hasProcessSubscriptionRequest={hasProcessSubscriptionRequest}
              isActiveAndCancellationRequested={isActiveAndCancellationRequested}
              isCollapsed={isCollapsed}
              setIsCollapsed={setIsCollapsed}
              isSubscribedToBundle={isSubscribedToBundle}
              isCancellationRequested={isCancellationRequested}
              onSetActivePackageModal={handleOnSetActivePackageModal}
            />
          </div>
          <div className={classNames(styles.control)}>
            <button onClick={toggleIsCollapsed} className='is-link has-text-centered'>
              {isCollapsed ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </button>
          </div>
        </div>
        <div
          className={classNames({
            'is-hidden': !isCollapsed,
          })}
        >
          {subscriptionGroup.subscriptions.map((subscription) => (
            <SubscriptionGroupSubscription
              subscription={subscription}
              scrollToCart={scrollToCart}
              isSubscribedToBundle={isSubscribedToBundle}
              key={subscription.id}
              onUpdateSubscription={onUpdateSubscription}
              isCollapsed={isCollapsed}
              onAddCartItem={onAddCartItem}
              nonActiveSubscriptions={nonActiveSubscriptions}
            />
          ))}
        </div>
      </div>
    </React.Fragment>
  )
}
