import { useTranslation } from 'react-i18next'

import { Toast, errorToast } from '../../global/toast/Toast'
import { useAccountWriteContext } from '../../utils/AccountContextContext'
import { ResponseError, isAlreadyFetchingAccessToken } from '../../utils/ApiClient'
import { MaybeAuthSession } from '../../utils/AuthContext'
import { useMaintenance } from '../../utils/MaintenanceModeContext'
import {
  isAuthAccessDeniedStatus,
  isNotFoundStatus,
  isServiceUnavailableStatus,
} from '../../utils/httpStatus'
import { clearLocalStorage } from '../../utils/useStorage'
import {
  handleToastType,
  isInvalidPassword,
  isPspServiceCheckError,
  isSkipErrorCode,
  isSubscriptionCartForClientExists,
} from '../RouterToaster'
import { getErrorResponseData, getPspServiceCheckErrorMessage, isIpApiCall } from './ErrorResponse'

export const useErrorHandler = (
  setToast: (value: Toast) => void,
  setAuth: (value: MaybeAuthSession) => void
): { errorHandler: (error: ResponseError) => Promise<void | null> } => {
  const { t } = useTranslation()
  const { setMaintenance } = useMaintenance()
  const { clearAccount } = useAccountWriteContext()

  const errorHandler = async (error: ResponseError) => {
    const title = t('Something went wrong! Please try again later')
    const responseData = getErrorResponseData(error)
    const { status, confirmationCode, isMaintenanceMode, maintenance } = responseData
    /**
     * https://jira.tickmill.com/browse/DL-6330
     */
    if (isIpApiCall(error)) {
      return null
    }

    if (isAuthAccessDeniedStatus(status)) {
      if (!isAlreadyFetchingAccessToken) {
        clearLocalStorage()
        setAuth(null)
        clearAccount()
        return null
      }
    }

    /**
     * CAR-267 (https://jira.tickmill.com/browse/CAR-267)
     *
     * @description:
     * When error 404 is received by any call in the backend, do not throw toast error message for it. Each 404 will be handled properly wherever is needed.
     */
    if (isNotFoundStatus(status)) {
      return null
    }

    /**
     * CAR-634 (https://jira.tickmill.com/browse/CAR-634)
     *
     */
    if (isMaintenanceMode) {
      console.log(1)
      setMaintenance?.(maintenance)
    }
    if (isMaintenanceMode && isServiceUnavailableStatus(status)) {
      console.log(2)
      return null
    }

    // TODO: BE has a third-part service, which can't provide the normal "code", BE should fix it.
    if (isInvalidPassword(error)) {
      return setToast(errorToast(t('errors.Invalid password')))
    }

    if (isSubscriptionCartForClientExists(error)) {
      return setToast(
        errorToast(t('errors.The selected item already exists in subscriptions cart'))
      )
    }

    if (isPspServiceCheckError(error)) {
      const toastMessage = getPspServiceCheckErrorMessage(error)
      return setToast(errorToast(toastMessage || t('errors.Something went wrong')))
    }

    if (isSkipErrorCode(error)) {
      return null
    }

    if (confirmationCode) {
      const toastType = handleToastType(error, t)
      return toastType && setToast(toastType)
    }

    if (isMaintenanceMode) {
      return setMaintenance?.(maintenance)
    }

    return setToast(errorToast(title))
  }

  return { errorHandler }
}
