import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Loading } from '../../global/Loading/Loading'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { SelectModal, SelectModalOption } from '../../global/field/SelectModal'
import { Modal } from '../../global/modal/Modal'
import { DocumentCategoryType } from '../../model/DocumentCategories'
import { NameDto } from '../../model/NameDto'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useFetchOne } from '../../utils/useFetch'

interface DocumentManagementIdentificationModalProps {
  documentCategoryType: DocumentCategoryType

  onSelectOption(category: NameDto<DocumentCategoryType>, type: NameDto): void

  onClose(): void
}

export const DocumentManagementCategoryModal: React.FC<
  DocumentManagementIdentificationModalProps
> = (props) => {
  const { documentCategoryType, onSelectOption, onClose } = props
  const { t } = useTranslation()
  const apiClient = useApiClient(ClientApiClient)
  const locale = useSessionLanguage()

  const callback = useCallback(async () => {
    return apiClient.getDocumentCategories(documentCategoryType, locale)
  }, [apiClient, documentCategoryType, locale])

  const { data, isLoading } = useFetchOne(callback)

  const handleSelectOption = (documentCategory: NameDto) => {
    if (data) onSelectOption(data.category, documentCategory)
  }

  return (
    <Modal
      closeModal={onClose}
      render={({ closeModal }) => (
        <SelectModal
          onCancel={closeModal}
          title={t('Profile.Document Type')}
          renderOptions={() => (
            <Loading isLoading={isLoading} showLoadingIcon>
              {data?.types.map((x) => (
                <SelectModalOption
                  label={x.name}
                  value={false}
                  onClick={() => handleSelectOption(x)}
                  key={x.id}
                />
              ))}
            </Loading>
          )}
        />
      )}
    />
  )
}
