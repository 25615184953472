import React, { useCallback, useContext, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AxiosResponseHeaders } from 'axios'
import classNames from 'classnames'
import { differenceInMinutes } from 'date-fns'

import { Loading } from '../../global/Loading/Loading'
import { Button } from '../../global/button/Button'
import { CompletionBlock } from '../../global/completionBlock/CompletionBlock'
import { useProductReadContext } from '../../global/context/ProductContext'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { InformationModal } from '../../global/modal/InformationModal'
import { Modal } from '../../global/modal/Modal'
import { NotificationDanger } from '../../global/notification/notification'
import { FormTemplate } from '../../global/templates/FormTemplate'
import {
  AccountDetailedDto,
  hasProductActivatedStatus,
  isKYCUpdateDue,
} from '../../model/AccountDetailedDto'
import { DocumentCategoryType } from '../../model/DocumentCategories'
import { DocumentCategoryOptionType } from '../../model/DocumentCategoryOptionType'
import { DocumentPropertyType } from '../../model/DocumentPropertyType'
import { TestSectionsDto } from '../../model/TestSectionsDto'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { Text } from '../../ui/Typography/Typography'
import { useAccountReadContext, useAccountWriteContext } from '../../utils/AccountContextContext'
import { useApiClient } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { ClientApiClient } from '../../utils/clientApi'
import { isTickmillPartner } from '../../utils/companyName.utils'
import { formatDate } from '../../utils/date.utils'
import { navigateBasedOnProductType } from '../../utils/navigationUtils'
import { useFetchOne } from '../../utils/useFetch'
import {
  YearlyKycUpdateDetailsForm,
  YearlyKycUpdateDetailsFormValues,
} from './YearlyKycUpdateDetailsForm/YearlyKycUpdateDetailsForm'

import styles from './YearlyKycUpdateDetailsForm/YearlyKycUpdateDetailsForm.module.scss'

export const useYearlyKycUpdateDetails = () => {
  const apiClient = useApiClient(ClientApiClient)
  const locale = useSessionLanguage()

  const callback = useCallback(async () => {
    return Promise.all([
      apiClient.getCountries(),
      apiClient.getAccountKycTest(locale),
      apiClient.getTaxUnavailableReasons(locale),
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const { data = [], isLoading } = useFetchOne(callback)
  const [countries = [], accountKycTest, tinUnavailableReasons = []] = data

  return { countries, accountKycTest, tinUnavailableReasons, isLoading }
}

export const useYearlyKycUpdateDetailsMutate = (productRegistrationRequirement?: boolean) => {
  const apiClient = useApiClient(ClientApiClient)

  const [isLoading, setLoading] = useState(false)
  const [isSuccess, setSuccess] = useState(false)

  const mutate = async (
    account: AccountDetailedDto | undefined,
    accountKycTest: TestSectionsDto | undefined,
    values: YearlyKycUpdateDetailsFormValues,
    setAlreadyUploadedModal: (visible: boolean) => void
  ) => {
    try {
      setLoading(true)
      const selectedAnswers = Object.entries(values.selectedAnswers).map(([, value]) => value)
      const freeAnswers = Object.entries(values.freeAnswers).map(([key, value]) => {
        return { testQuestionId: key, answer: value }
      })

      if (
        !account?.isDormantUpdateNeeded &&
        (account?.isKYCUpdateNeeded || productRegistrationRequirement)
      ) {
        await apiClient.createClientTests({
          clientId: values.clientId,
          category: values?.accountKycTest?.category || '',
          testId: accountKycTest?.id || '',
          dateTaken: new Date().toISOString(),
          selectedAnswers,
          freeAnswers,
        })
        const response = await apiClient.updateAccountKycDetailsResponse({
          clientId: values.clientId,
          phoneNumber: values.phoneNumber,
          phoneCountryCode: values.countryCode,
          state: values.state,
          city: values.city,
          street: values.street,
          zipCode: values.zipCode,
          taxIdentificationNumber: values.taxIdentificationNumber,
          kycUpdateTestId: accountKycTest?.id || '',
        })
        !!values.documents.length &&
          (await apiClient.uploadDocuments({
            documents: values.documents.map((file) => ({
              categoryId: DocumentCategoryType.Additional,
              typeId: DocumentCategoryOptionType.Other,
              file: file.base64Content,
              filename: file.fileName,
              properties: {
                [DocumentPropertyType.TicketId]: getTestId(response.headers),
              },
            })),
          }))
      }

      if (account?.isDormantUpdateNeeded) {
        const response = await apiClient.updateAccountDormantDetailsResponse({
          clientId: values.clientId,
          phoneNumber: values.phoneNumber,
          phoneCountryCode: values.countryCode,
          state: values.state,
          city: values.city,
          street: values.street,
          zipCode: values.zipCode,
          taxIdentificationNumber: values.taxIdentificationNumber,
          taxIdUnavailableReasonId: values.taxIdUnavailableReasonId || null,
        })
        !!values.documents.length &&
          (await apiClient.uploadDocuments({
            documents: values.documents.map((file) => ({
              categoryId: DocumentCategoryType.Address,
              typeId: DocumentCategoryOptionType.Other,
              file: file.base64Content,
              filename: file.fileName,
              properties: {
                [DocumentPropertyType.TicketId]: getTestId(response.headers),
              },
            })),
          }))
      }
      setSuccess(true)
    } catch {
      setAlreadyUploadedModal(true)
    } finally {
      setLoading(false)
    }
  }

  return { mutate, isLoading, isSuccess }
}

interface YearlyKycUpdateDetailsPageProps {
  accountPassed?: AccountDetailedDto
}

export const YearlyKycUpdateDetailsPage: React.FC<YearlyKycUpdateDetailsPageProps> = ({
  accountPassed,
}) => {
  const { account } = useAccountReadContext()

  const { countries, accountKycTest, tinUnavailableReasons, isLoading } =
    useYearlyKycUpdateDetails()

  const mutation = useYearlyKycUpdateDetailsMutate()

  const handleSubmitForm = async (values: YearlyKycUpdateDetailsFormValues) => {
    await mutation.mutate(account, accountKycTest, values, setAlreadyUploadedModal)
  }

  const [alreadyUploadedModal, setAlreadyUploadedModal] = useState(false)

  const handleCloseErrorModal = () => setAlreadyUploadedModal(false)

  const { t } = useTranslation()
  return (
    <Loading isLoading={isLoading}>
      {alreadyUploadedModal && (
        <Modal
          closeModal={handleCloseErrorModal}
          render={() => (
            <InformationModal
              onCancel={handleCloseErrorModal}
              buttonText={t('Got It')}
              title={t('Upload failed')}
            >
              <Text isParagraph>{t('The document has been already uploaded')}</Text>
            </InformationModal>
          )}
        />
      )}
      {!mutation.isSuccess && !mutation.isLoading && (
        <YearlyKycUpdateDetailsForm
          account={accountPassed || account}
          countries={countries}
          accountKycTest={accountKycTest}
          onSubmit={handleSubmitForm}
          tinUnavailableReasons={tinUnavailableReasons}
        />
      )}
      {mutation.isSuccess && !mutation.isLoading && <SuccessPage />}
    </Loading>
  )
}

const SuccessPage: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const locale = useSessionLanguage()
  const { refreshAccount } = useAccountWriteContext()
  const { isDefaultCFDProductType } = useProductReadContext()
  const { account } = useAccountReadContext()
  const { product } = useProductReadContext()

  const isPartner = isTickmillPartner(account)

  const isProductActivated = hasProductActivatedStatus(account, product)

  const goToDashboard = async () => {
    await refreshAccount(locale)
    navigateBasedOnProductType(isDefaultCFDProductType(), navigate, isPartner)
  }

  return (
    <React.Fragment>
      {isKYCUpdateDue(account) && !isProductActivated && !account?.isDormantUpdateNeeded ? (
        <FormTemplate
          title={t('Sign up.Registration Futures Trading')}
          titleClassName={styles.successTitle}
          contentClassName={styles.successContent}
        >
          <CompletionBlock onButtonClick={goToDashboard} />
        </FormTemplate>
      ) : (
        <>
          <PageHeader hasExtraMarginTop title={t('Profile.Are your details up to date?')} />
          <div className={styles.wrapper}>
            <div className={styles.section}>
              <div className={styles.box}>
                <div className='pt-6 pb-6'>
                  <div className='pb-5'>
                    <h1 className='text-align-center'>{t('Thank You!')}</h1>
                  </div>
                  <div className='pb-5'>
                    <Text isParagraph className='text-align-center'>
                      {t(
                        'Profile.Your details have been successfully updated. We will only contact you if we require further details.'
                      )}
                    </Text>
                  </div>
                  <div className='pb-4'>
                    <div className='text-align-center'>
                      <Text isParagraph>
                        <Button
                          appearance='secondary'
                          size='L'
                          type='button'
                          onClick={goToDashboard}
                        >
                          {t('Go To Dashboard')}
                        </Button>
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  )
}

const getTestId = (
  headers:
    | AxiosResponseHeaders
    | Partial<Record<string, string> & { 'set-cookie'?: string[] | undefined }>
) => {
  const parts = headers.location?.split('/')
  if (parts) return parts[parts.length - 1]
  return ''
}

export const YearlyKycUpdateDetailsNotification: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { account } = useAccountReadContext()
  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name

  const goToKycDetails = () => {
    navigate('/profile/kyc-update-details')
  }

  return (
    <NotificationDanger
      title={t('Profile.Warning please update your details')}
      subtitle={
        <React.Fragment>
          <p>
            {t(
              'WarningMessage.Please acknowledge and update your profile with the most accurate information'
            )}
          </p>
          <p>
            <Trans i18nkey={t('WarningMessage.Kyc Update Details Message')}>
              {t('Profile.Please note that if the information is not acknowledged before')}{' '}
              <span className={styles.textStrong}>
                {account?.detailsUpdateAvailableUntil && (
                  <React.Fragment>
                    {formatDate(account.detailsUpdateAvailableUntil, dateFormat)}{' '}
                  </React.Fragment>
                )}
              </span>{' '}
              {t('Profile.then you will automatically redirected to update details page.')}
            </Trans>
            <div className={classNames(styles.detailsWrapper, 'pt-3')}>
              <Button appearance='primary' size='S' onClick={goToKycDetails}>
                {t('Profile.Update Details')}
              </Button>
            </div>
          </p>
        </React.Fragment>
      }
    />
  )
}

export const isKycUpdateDetailsNotificationNeeded = (
  accountDetailed?: AccountDetailedDto
): boolean => {
  if (!accountDetailed) {
    return false
  }

  const dateDifferenceInMinutes = differenceInMinutes(
    new Date(),
    new Date(accountDetailed?.detailsUpdateAvailableUntil ?? '')
  )

  return accountDetailed?.isKYCUpdateNeeded && dateDifferenceInMinutes <= 0
}
