import React, {
  ChangeEvent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import classNames from 'classnames'
import { Field, Form, FormikErrors, FormikProps, useFormikContext, withFormik } from 'formik'
import { t } from 'i18next'

import { getReferralCookies } from '../../../Referral/helpers'
import { Loading } from '../../../global/Loading/Loading'
import { Button } from '../../../global/button/Button'
import { CheckBox } from '../../../global/checkBox/CheckBox'
import { useEntitySettings } from '../../../global/context/EntityContext'
import { useLossPercentagesWriteContext } from '../../../global/context/LossPercentagesContext'
import { useArabicSessionLanguage } from '../../../global/context/SessionSettingsContext'
import { createFormCheckBoxField } from '../../../global/formField/FormCheckBoxField'
import { createFormField } from '../../../global/formField/FormField'
import IconButton from '../../../global/iconButton/IconButton'
import { BackIcon } from '../../../icons/BackIcon'
import { CheckIcon } from '../../../icons/CheckIcon'
import { CloseIcon } from '../../../icons/CloseIcon'
import { DropArrowDownIcon } from '../../../icons/DropArrowDownIcon'
import { ForwardIcon } from '../../../icons/ForwardIcon'
import { InfoIcon } from '../../../icons/InfoIcon'
import { WarningIcon } from '../../../icons/WarningIcon'
import { LegalDocumentDto } from '../../../model/CompanyLegalDocumentDto'
import { LeadMembersEnum } from '../../../model/CreateLead'
import { NameDto } from '../../../model/NameDto'
import {
  RegulatorDto,
  RegulatorOptionDto,
  RegulatorOptionItemDto,
  isRegulatorOptionErrorType,
  isRegulatorOptionSuccessType,
} from '../../../model/RegulatorOptionDto'
import { TickmillProductType } from '../../../model/TickmillProductType'
import { Text, TextSmall, TextTiny } from '../../../ui/Typography/Typography'
import {
  TickmillCompaniesEnum,
  isTickmillEUType,
  isTickmillPartnerType,
  isTickmillSCType,
  isTickmillUKType,
} from '../../../utils/companyName.utils'
import { isOne, minTwoDigits } from '../../../utils/validations'
import { wait } from '../../../utils/wait'
import { DocumentFieldItem, ReadDocumentPage } from '../PersonalInfoPage/DocumentReadAndAgreeField'
import { PersonalDetailsTemplate } from '../PersonalInfoPage/PersonalDetailsTemplate'
import { AgreedLegalDocument } from '../PersonalInfoPage/PersonalInfoDocumentsFactory'
import { FormValuesStep0 } from '../PersonalInfoPage/PersonalInfoStepAccountTypePage'
import { FormValuesStep1 } from '../PersonalInfoStep1Page/PersonalInfoDetailsStep1Form'
import { FormValuesStep3 } from '../PersonalInfoStep3Page/PersonalInfoStep3Form'
import { DocumentGroupsFactory } from './DocumentGroupsFactory'
import {
  CountryOfResidenceModal,
  EntityInfoModal,
  ProductInfoModal,
  RegulatorInfoModal,
  RegulatorPopUpModal,
} from './PersonalInfoModal'

import styles from '../PersonalInfoPage.module.scss'

type FormValues = FormValuesStep0 & FormValuesStep1 & FormValuesStep2 & FormValuesStep3

export interface FormValuesStep2 {
  countryId: string
  tickmillCompanyId: number
  productIds: number[]
  optIn: boolean
  readDocument?: LegalDocumentDto | undefined
  documents?: { [key: string]: boolean }
  agreedLegalDocuments?: AgreedLegalDocument[]
}

type GroupReference = string | null

interface OuterProps {
  step: number

  goBack(): void

  isLoading: boolean

  countryId?: string
  tickmillCompanyId?: TickmillCompaniesEnum
  regulatorSelected: RegulatorDto | undefined
  regulators: RegulatorOptionDto[]
  groupReferences: GroupReference[]
  passedDocuments: LegalDocumentDto[]

  formValues: FormValues | undefined
  disabled: boolean

  onSubmit(values: FormValuesStep2): void

  onCountrySelectedChange(regulator: string | undefined): void

  onRegulatorSelectedChange(regulator: RegulatorDto | undefined): void
}

interface RegulatorInfoModalProps {
  regulator?: RegulatorDto
  visible: boolean
}

const FormField = createFormField<FormValuesStep2>()
const FormCheckBoxField = createFormCheckBoxField<FormValuesStep2>()

const PersonalDetailsStep2FormUI: React.FC<FormikProps<FormValuesStep2> & OuterProps> = (props) => {
  const {
    step,
    isLoading,
    values,
    regulatorSelected,
    regulators,
    groupReferences,
    passedDocuments,
    isSubmitting,
    isValid,
    handleSubmit,
    setFieldValue,
    disabled,
    errors,
    touched,
  } = props

  const { t } = useTranslation()
  const [entity] = useEntitySettings()
  const UKPolicyRef = useRef<HTMLDivElement>(null)

  const goBackFromDoc = async () => {
    setFieldValue('readDocument', undefined)
    wait(300).then(() => {
      if (UKPolicyRef.current) UKPolicyRef.current.scrollIntoView()
      else
        wait(200).then(() => {
          if (UKPolicyRef.current) UKPolicyRef.current.scrollIntoView()
        })
    })
  }

  const [regulatorInfoModal, setRegulatorInfoModal] = useState<RegulatorInfoModalProps>()
  const [regulatorPopupModal, setRegulatorPopupModal] = useState<RegulatorInfoModalProps>()
  const [selectedProductIds, setSelectedProductIds] = useState<TickmillProductType[]>([])

  const [isEntityInfoModalOpen, setEntityInfoModalOpen] = useState(false)
  const [isCountryModalOpen, setIsCountryModalOpen] = useState(false)
  const [isProductModalOpen, setIsProductModalOpen] = useState(false)

  const regulator = useRegulator(props, setSelectedProductIds)
  const redirection = useRedirection(props)

  useValidationEffect(props)
  useCompanyChangeEffect(props, regulator, regulatorSelected)
  const { countryName } = useSelectCountryEffect(props)
  useSelectRegulatorEffect(props, regulatorSelected)

  const handleRegulatorSelected = (data: RegulatorDto | undefined) => {
    regulator.handleRegulatorSelected(data)
  }

  const handleChangeProductIds = (checked: boolean, productId: TickmillProductType) => {
    const productIds = checked
      ? [...values.productIds, productId]
      : values.productIds.filter((p) => p !== productId)
    setFieldValue('productIds', productIds)
    setSelectedProductIds(productIds)
  }

  const goBack = () => {
    localStorage.removeItem('selectedProductIdsKey')
    props.goBack()
  }

  const handleCountryChangeOption = (country: NameDto<string>) => {
    handleRegulatorSelected(undefined)
    setFieldValue('countryId', country.id)
    setFieldValue('tickmillCompanyId', undefined)
    setFieldValue('agreedLegalDocuments', [])
    resetPrivacyPolicy()
    handleCountryModalClose()
    props.onCountrySelectedChange(country.id)
    props.onRegulatorSelectedChange(undefined)
  }

  const handleRegulatorChange = (regulator: RegulatorDto) => {
    if (regulator.redirect) {
      handleRegulatorSelected(regulator)
      if (regulator.popup) {
        setRegulatorPopupModal({
          regulator,
          visible: true,
        })
      } else {
        redirection.redirect(regulator)
      }
    } else {
      handleRegulatorSelected(regulator)
      setFieldValue('tickmillCompanyId', regulator.tickmillCompanyId)
      setFieldValue('agreedLegalDocuments', [])
      resetPrivacyPolicy()
    }
  }

  const handleRegulatorInfoModalOpen = (regulator: RegulatorDto) => {
    setRegulatorInfoModal({
      regulator,
      visible: true,
    })
  }

  const handleRegulatorPopUpModalSubmit = () => {
    if (regulatorSelected?.redirect) {
      redirection.redirect(regulatorSelected)
      setRegulatorInfoModal({
        regulator: undefined,
        visible: false,
      })
    } else {
      regulator.handleRegulatorSelected(regulatorPopupModal?.regulator)
      setFieldValue('tickmillCompanyId', regulatorPopupModal?.regulator?.tickmillCompanyId)
      handleRegulatorPopUpModalClose()
    }
  }

  const handleRegulatorPopUpModalClose = () => {
    setRegulatorInfoModal({
      regulator: undefined,
      visible: false,
    })
  }

  const handleRegulatorInfoModalClose = () => {
    setRegulatorInfoModal({
      regulator: undefined,
      visible: false,
    })
  }

  const handleProductModalOpen = () => {
    return setIsProductModalOpen(true)
  }

  const handleProductModalClose = () => {
    return setIsProductModalOpen(false)
  }

  const handleCountryModalOpen = () => {
    setIsCountryModalOpen(true)
  }

  const handleCountryModalClose = () => {
    setIsCountryModalOpen(false)
  }

  const handleEntityInfoModalClose = () => {
    setEntityInfoModalOpen(false)
  }

  const resetPrivacyPolicy = () => {
    setFieldValue('documents', {})
    setFieldValue('optIn', false)
  }

  const hasProductIdsError = errors.productIds && touched.productIds

  const UKPrivacyPolicy = useMemo(
    () =>
      (values.tickmillCompanyId || regulatorSelected?.tickmillCompanyId) ===
      TickmillCompaniesEnum.TICKMILL_UK
        ? passedDocuments.find((d) => d.code === 'privacy_policy')
        : undefined,
    [passedDocuments, regulatorSelected?.tickmillCompanyId, values.tickmillCompanyId]
  )

  const regularDocuments = useMemo(
    () => passedDocuments.filter(({ id }) => id !== UKPrivacyPolicy?.id),
    [UKPrivacyPolicy, passedDocuments]
  )

  if (values?.readDocument) {
    return <ReadDocumentPage goBackFromDoc={goBackFromDoc} />
  }

  return (
    <PersonalDetailsTemplate
      step={step}
      selectedProductIds={selectedProductIds}
      goBack={goBack}
      reversed
      renderDescription={() => (
        <Text isParagraph>
          {t(
            'Sign up.Your personal information is required in the next step to complete the application process. Your ID documents are needed for this step.'
          )}
        </Text>
      )}
    >
      <Loading showLoadingIcon isLoading={isLoading}>
        {isCountryModalOpen && (
          <CountryOfResidenceModal
            regulators={regulators}
            selectedCountry={values.countryId}
            onChangeOption={handleCountryChangeOption}
            closeModal={handleCountryModalClose}
          />
        )}
        {isProductModalOpen && <ProductInfoModal closeModal={handleProductModalClose} />}
        {regulatorInfoModal?.visible && regulatorInfoModal.regulator && (
          <RegulatorInfoModal
            regulator={regulatorInfoModal.regulator}
            visible={regulatorInfoModal.visible}
            closeModal={handleRegulatorInfoModalClose}
          />
        )}
        {regulatorPopupModal?.visible && regulatorSelected?.popup && (
          <RegulatorPopUpModal
            regulator={regulatorPopupModal.regulator}
            visible={regulatorPopupModal.visible}
            onSubmit={handleRegulatorPopUpModalSubmit}
            closeModal={handleRegulatorPopUpModalClose}
          />
        )}
        {isEntityInfoModalOpen && <EntityInfoModal closeModal={handleEntityInfoModalClose} />}
        <Form className={styles.form} onSubmit={handleSubmit}>
          <h3>{t('Sign up.Country of Residence')}</h3>
          <IconButton onClick={handleCountryModalOpen} fullWidth>
            <FormField
              name='countryId'
              label={t('Sign up.Country')}
              placeholder={t('Sign up.Country')}
              value={countryName}
              rightIcon={<DropArrowDownIcon />}
              readOnly
              required
              fullWidth
            />
          </IconButton>

          {!isTickmillPartnerType(entity.entity) && (
            <React.Fragment>
              <h3>{t('Sign up.Legal Entity')}</h3>
              <Text isParagraph>
                {t(
                  'Sign up.Based on your country of residence, you can register under the following entities'
                )}
                .
              </Text>
              {regulator.regulators.map((regulator, index) => (
                <RegulatorField
                  regulator={regulator}
                  onChange={handleRegulatorChange}
                  onRegulatorInfoModalOpen={handleRegulatorInfoModalOpen}
                  key={index}
                />
              ))}
            </React.Fragment>
          )}

          {regulatorSelected?.products?.length !== undefined && (
            <React.Fragment>
              {isTickmillUKType(entity.entity) && (
                <React.Fragment>
                  <h3>
                    {regulatorSelected.products.length > 1
                      ? t('Sign up.Select Product')
                      : t('Sign up.Selected Product')}
                  </h3>
                  <div
                    className={classNames(styles.textSmall, 'control', {
                      'has-error': hasProductIdsError,
                    })}
                  >
                    <div className={styles.productContainer}>
                      {regulatorSelected?.products
                        .map((x) => x.id)
                        .includes(TickmillProductType.CFD) && (
                        <ProductField
                          tickmillProductType={TickmillProductType.CFD}
                          disabled={isOne(regulatorSelected?.products.length)}
                          onChangeProductIds={handleChangeProductIds}
                          onProductModalOpen={handleProductModalOpen}
                        >
                          <Text> CFD</Text>
                        </ProductField>
                      )}
                      {regulatorSelected?.products
                        .map((x) => x.id)
                        .includes(TickmillProductType.ETD) && (
                        <ProductField
                          tickmillProductType={TickmillProductType.ETD}
                          disabled={isOne(regulatorSelected?.products.length)}
                          onChangeProductIds={handleChangeProductIds}
                          onProductModalOpen={handleProductModalOpen}
                        >
                          <Text>{t('Sign up.Futures')}</Text>
                        </ProductField>
                      )}
                    </div>
                    {hasProductIdsError && <span className='has-error'>{errors.productIds}</span>}
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}

          <h3>{t('Sign up.Privacy Policy')}</h3>
          {UKPrivacyPolicy?.title && (
            <DocumentGroupsFactory
              renderItem={(props: DocumentFieldItem) => (
                <div ref={UKPolicyRef} className={styles.ukTerms}>
                  <CheckBox
                    onClick={props.isAccepted ? () => undefined : props.onClick}
                    value={props.isAccepted}
                  />
                  <Text>
                    {t('Validation.I have read and accepted the')}{' '}
                    <Button
                      appearance='link'
                      type='button'
                      onClick={
                        props.isAccepted
                          ? () => window.open(UKPrivacyPolicy.url || '', '_blank', 'noreferrer')
                          : props.onClick
                      }
                    >
                      {UKPrivacyPolicy.title}
                    </Button>
                  </Text>
                </div>
              )}
              groupReferences={groupReferences}
              documents={[UKPrivacyPolicy]}
            />
          )}
          {regularDocuments.length >= 1 && (
            <DocumentGroupsFactory groupReferences={groupReferences} documents={regularDocuments} />
          )}

          <div className='control pt-1'>
            <FieldCheckBoxOptInField />
          </div>

          <Button
            type='submit'
            appearance='primary'
            size='L'
            renderRightIcon={() => <SubmitIcon />}
            disabled={isSubmitting || !isValid || disabled}
            className={styles.button}
          >
            {t('Next')}
          </Button>
        </Form>
      </Loading>
    </PersonalDetailsTemplate>
  )
}

interface RegulatorFieldProps {
  regulator: RegulatorDto

  onChange(regulator: RegulatorDto): void

  onRegulatorInfoModalOpen(regulator: RegulatorDto): void
}

const RegulatorField: React.FC<RegulatorFieldProps> = (props) => {
  const { regulator, onChange, onRegulatorInfoModalOpen } = props

  const { values } = useFormikContext<FormValuesStep2>()

  const handleClick = (e: React.MouseEvent<HTMLDivElement | HTMLSelectElement, MouseEvent>) => {
    onChange(regulator)
    e.stopPropagation()
    e.preventDefault()
  }

  return (
    <div className={classNames('field', styles.regulator)} onClick={handleClick}>
      <div
        className={classNames('control', {
          [styles.isActive]: values.tickmillCompanyId === regulator.tickmillCompanyId,
        })}
      >
        <label className='is-flex is-align-items-center'>
          <Field
            name='tickmillCompanyId'
            required
            type='radio'
            onChange={(e: React.MouseEvent<HTMLSelectElement>) => {
              e.stopPropagation()
              handleClick(e)
            }}
            checked={values.tickmillCompanyId === regulator.tickmillCompanyId}
            key={regulator.name}
            className={classNames('radio')}
          />
          <span className='ml-4'>{regulator.title}</span>
          <button
            type='button'
            onClick={(e) => {
              e.stopPropagation()
              onRegulatorInfoModalOpen(regulator)
            }}
            className={styles.plainButton}
          >
            <InfoIcon size={16} />
          </button>
        </label>
        <TextSmall isParagraph className='text-secondary'>
          {regulator.name}
        </TextSmall>
        {regulator.options.length > 0 && (
          <div className={styles.regulatorDetails}>
            {regulator.options.map((x, index) => {
              return (
                <div className={styles.optionWrapper} key={index}>
                  {x.showIcon && (
                    <span className={styles.textSmallStrong}>
                      <RegulatorOptionIcon {...x} />
                    </span>
                  )}
                  {x.showHeaderString && (
                    <span className={styles.textSmallStrong}>{x.headerString}</span>
                  )}
                  <TextTiny className={styles.centerSmallText}>{x.optionText}</TextTiny>
                </div>
              )
            })}
          </div>
        )}
        <div>
          {regulator.warnings.map((warning) => (
            <TextSmall className='pt-2' key={warning}>
              <WarningIcon size={20} color={'warning'} /> {warning}
            </TextSmall>
          ))}
        </div>
      </div>
    </div>
  )
}

interface ProductFieldProps {
  tickmillProductType: TickmillProductType
  disabled?: boolean

  onChangeProductIds(value: boolean, tickmillProductType: TickmillProductType): void

  onProductModalOpen(): void
}

const ProductField: React.FC<PropsWithChildren<ProductFieldProps>> = (props) => {
  const {
    tickmillProductType,
    disabled = false,
    onChangeProductIds,
    onProductModalOpen,
    children,
  } = props

  const { values } = useFormikContext<FormValuesStep2>()

  return (
    <div className={classNames('is-flex is-align-items-center', styles.itemContainer)}>
      <Field
        name='productIds'
        type='checkbox'
        checked={values.productIds.includes(tickmillProductType)}
        disabled={disabled}
        required={!values.productIds.length}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          onChangeProductIds(event.target.checked, tickmillProductType)
        }}
        className={classNames(styles.hasIncreasedSize, styles.checkbox)}
      />
      <span className={styles.item}>{children}</span>
      <IconButton onClick={onProductModalOpen} className='is-flex is-align-items-center'>
        <InfoIcon size={16} />
      </IconButton>
    </div>
  )
}

const FieldCheckBoxOptInField = (): JSX.Element => {
  const { t } = useTranslation()
  const [entity] = useEntitySettings()

  return (
    <FormCheckBoxField name='optIn' className={styles.hasIncreasedSize}>
      {isTickmillEUType(entity.entity) && (
        <Text>
          {t('Sign up.I agree to submit my information to Tickmill Europe Ltd to communicate')}
        </Text>
      )}
      {isTickmillUKType(entity.entity) && (
        <Text>
          {t('Sign up.I agree to submit my information to Tickmill UK Ltd to communicate')}
        </Text>
      )}
      {isTickmillSCType(entity.entity) && (
        <Text>
          {t(
            'Sign up.I agree to receiving marketing communication from Tickmill, which I can opt-out at any time'
          )}
        </Text>
      )}
      {!isTickmillSCType(entity.entity) &&
        !isTickmillEUType(entity.entity) &&
        !isTickmillUKType(entity.entity) && (
          <Text>{t('Sign up.I agree to submit my information to Tickmill to communicate')}</Text>
        )}
    </FormCheckBoxField>
  )
}

const SubmitIcon = () => {
  const isArabic = useArabicSessionLanguage()
  return (
    <span className='is-flex is-align-items-center'>
      {isArabic ? <BackIcon inverse /> : <ForwardIcon inverse />}
    </span>
  )
}

const RegulatorOptionIcon: React.FC<RegulatorOptionItemDto> = (props) => (
  <React.Fragment>
    {isRegulatorOptionSuccessType(props) && <CheckIcon size={18} color={'success'} />}
    {isRegulatorOptionErrorType(props) && <CloseIcon size={18} color={'error'} />}
  </React.Fragment>
)

const initialState = (props: OuterProps): FormValuesStep2 => {
  const searchParams = new URLSearchParams(window.location.search)

  const tickmillCompanyId =
    props?.formValues?.tickmillCompanyId ||
    Number(searchParams.get(LeadMembersEnum.TICKMILL_COMPANY_ID)) ||
    0

  return {
    ...props?.formValues,
    countryId: props.countryId || '',
    tickmillCompanyId,
    optIn: props?.formValues?.optIn || false,
    productIds: props?.formValues?.productIds || [],
    agreedLegalDocuments: [],
    readDocument: undefined,
    documents: {},
  }
}

export const PersonalDetailsStep2Form = withFormik<OuterProps, FormValuesStep2>({
  mapPropsToValues: (props) => {
    return initialState(props)
  },
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      setSubmitting(true)
      await props.onSubmit(dataToSubmit(values, props))
    } finally {
      setSubmitting(false)
    }
  },
  validate: (values, { passedDocuments, regulatorSelected }) => {
    const errors: FormikErrors<FormValuesStep2> = {}
    const { agreedLegalDocuments, productIds } = values

    if (passedDocuments.length <= 0) {
      errors.agreedLegalDocuments = t('Validation.Required')
    }

    if (!isDocumentsPositiveValue(values.documents)) {
      errors.agreedLegalDocuments = t('Validation.Required')
    }

    if (!isDocumentsAgreed(passedDocuments, agreedLegalDocuments)) {
      errors.agreedLegalDocuments = t('Validation.Required')
    }

    if (
      !isTickmillPartner(regulatorSelected) &&
      !isProductSelected(regulatorSelected, productIds)
    ) {
      errors.productIds = t('Validation.Please select at least one product')
    }

    return errors
  },
  validateOnMount: true,
  validateOnBlur: true,
  validateOnChange: true,
  enableReinitialize: true,
})(PersonalDetailsStep2FormUI)

const dataToSubmit = (values: FormValuesStep2, props: OuterProps): FormValuesStep2 => {
  if (isTickmillPartnerType(props.tickmillCompanyId)) {
    return {
      ...values,
      tickmillCompanyId: TickmillCompaniesEnum.TICKMILL_PA,
    }
  }

  return values
}

const isTickmillPartner = (regulatorSelected?: RegulatorDto) => {
  return isTickmillPartnerType(regulatorSelected?.tickmillCompanyId)
}

const isProductSelected = (regulatorSelected?: RegulatorDto, productIds: number[] = []) => {
  if (regulatorSelected?.products?.length !== undefined) {
    return productIds.length
  }
  return true
}

const isDocumentsPositiveValue = (documents: { [key: string]: boolean } = {}) => {
  return Object.entries(documents || []).every(([_, value]) => value)
}

const isDocumentsAgreed = (
  documents: LegalDocumentDto[] = [],
  agreedLegalDocuments: AgreedLegalDocument[] = []
) => {
  return documents.length === agreedLegalDocuments.length
}

interface RegulatorProps {
  handleRegulatorSelected(regulator: RegulatorDto | undefined, callback?: () => void): void

  regulators: RegulatorDto[]
}

const useRegulator = (
  props: FormikProps<FormValuesStep2> & OuterProps,
  setProductIds: React.Dispatch<React.SetStateAction<TickmillProductType[]>>
): RegulatorProps => {
  const { values, setFieldValue, regulators } = props

  const [, setEntity] = useEntitySettings()

  const { refreshLossPercentages } = useLossPercentagesWriteContext()

  const handleRegulatorSelected = useCallback(
    (regulator: RegulatorDto | undefined) => {
      props.onRegulatorSelectedChange(regulator)
      if (regulator) {
        setEntity({ entity: regulator.tickmillCompanyId })
        refreshLossPercentages({
          entity: regulator.tickmillCompanyId,
        })
        const newProductIds = regulator.products.map((p) => p.id)
        if (isOne(newProductIds.length)) {
          setFieldValue('productIds', newProductIds)
          setProductIds(newProductIds)
        } else {
          setFieldValue('productIds', [])
          setProductIds([])
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setEntity, setFieldValue, props.onRegulatorSelectedChange, setProductIds]
  )

  const getRegulator = useCallback(() => {
    const result =
      (regulators || []).find(
        (x) =>
          x.country.id.toUpperCase() === values.countryId.toUpperCase() ||
          x.country.shortName?.toUpperCase() === values.countryId.toUpperCase()
      )?.regulators || []

    return result
  }, [regulators, values.countryId])

  useEffect(() => {
    if (!props.regulatorSelected && values.tickmillCompanyId) {
      const matchingRegulatorOption = regulators.find((regulatorOption) => {
        return (
          (regulatorOption.country.id.toUpperCase() === values.countryId.toUpperCase() ||
            regulatorOption.country.shortName?.toUpperCase() === values.countryId.toUpperCase()) &&
          regulatorOption.regulators.some(
            (regulator) => regulator.tickmillCompanyId === values.tickmillCompanyId
          )
        )
      })
      const matchingRegulator = matchingRegulatorOption?.regulators.find(
        (regulator) => regulator.tickmillCompanyId === values.tickmillCompanyId
      )
      if (matchingRegulator) {
        handleRegulatorSelected(matchingRegulator)
      }
    }
  }, [values.tickmillCompanyId, regulators, props.regulatorSelected, handleRegulatorSelected])

  return { handleRegulatorSelected, regulators: getRegulator() }
}

const useCompanyChangeEffect = (
  props: FormikProps<FormValuesStep2> & OuterProps,
  regulator: RegulatorProps,
  regulatorSelected?: RegulatorDto
) => {
  const { countryId, isLoading, setFieldValue } = props
  const { handleRegulatorSelected } = regulator

  const [entity] = useEntitySettings()

  useEffect(() => {
    if (entity.entity === TickmillCompaniesEnum.TICKMILL_PA) {
      setFieldValue('tickmillCompanyId', TickmillCompaniesEnum.TICKMILL_PA)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity.entity, setFieldValue])

  useEffect(() => {
    const preSelected = regulator.regulators.find((x) => x.preSelected)
    if (preSelected && !regulatorSelected) {
      handleRegulatorSelected(preSelected)
      setFieldValue('tickmillCompanyId', preSelected.tickmillCompanyId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regulator.regulators, handleRegulatorSelected, regulatorSelected, setFieldValue])
}

const useSelectCountryEffect = (props: FormikProps<FormValuesStep2> & OuterProps) => {
  const { values, regulators } = props
  const country = useMemo(() => {
    const regulator = regulators.find(
      (x) =>
        x.country.id === values.countryId.toUpperCase() ||
        x.country.shortName === values.countryId.toUpperCase()
    )
    return regulator?.country
  }, [regulators, values.countryId])
  return { countryId: country?.id, countryName: country?.name }
}

const useValidationEffect = (props: FormikProps<FormValuesStep2> & OuterProps) => {
  useEffect(() => {
    props.validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.optIn, props.values.documents])
}

const useSelectRegulatorEffect = (
  props: FormikProps<FormValuesStep2> & OuterProps,
  regulator: RegulatorDto | undefined
) => {
  useEffect(() => {
    if (regulator) {
      props.onRegulatorSelectedChange(regulator)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regulator, props.onRegulatorSelectedChange])
}

const useRedirection = (props: FormikProps<FormValuesStep2> & OuterProps) => {
  const { values, formValues } = props

  const [params] = useSearchParams()

  const getRedirectionLink = (link: string) => {
    const referral = getReferralCookies()
    params.set('countryId', values.countryId || formValues?.countryId || '')

    params.set('tickmillCompanyId', formValues?.tickmillCompanyId.toString() || '')
    params.set(LeadMembersEnum.GENDER_ID, formValues?.genderId || '')
    params.set(LeadMembersEnum.TYPE_ID, formValues?.typeId.toString() || '')
    params.set(LeadMembersEnum.FIRST_NAME, formValues?.firstName || '')
    params.set(LeadMembersEnum.MIDDLE_NAME, formValues?.middleName || '')
    params.set(LeadMembersEnum.LAST_NAME, formValues?.lastName || '')
    params.set(LeadMembersEnum.NATIVE_NAME, formValues?.nativeName || '')
    params.set(
      LeadMembersEnum.COUNTRY_CODE,
      formValues?.countryCode || params.get(LeadMembersEnum.COUNTRY_CODE) || ''
    )
    params.set(
      LeadMembersEnum.PHONE_NUMBER,
      formValues?.phoneNumber || params.get(LeadMembersEnum.PHONE_NUMBER) || ''
    )
    params.set(
      LeadMembersEnum.BIRTHDAY,
      `${formValues?.yearOfBirth}-${minTwoDigits(formValues?.monthOfBirth)}-${minTwoDigits(
        formValues?.dayOfBirth
      )}` || ''
    )
    params.set(LeadMembersEnum.EMAIL, formValues?.email || params.get(LeadMembersEnum.EMAIL) || '')
    params.set(
      LeadMembersEnum.LANGUAGE_ID,
      formValues?.languageId || params.get(LeadMembersEnum.LANGUAGE_ID) || ''
    )

    Object.entries(referral).map(([k, v]) => params.set(k.toString(), v.toString()))

    return `${link}&${params.toString()}`
  }

  const redirect = (regulator?: RegulatorDto, regulatorSelected?: RegulatorDto) => {
    window.location.replace(
      getRedirectionLink(regulator ? regulator.link : regulatorSelected?.link || '')
    )
  }

  return { getRedirectionLink, redirect }
}
