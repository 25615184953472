import React, { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Form, FormikErrors, FormikProps, useFormikContext, withFormik } from 'formik'
import { t } from 'i18next'

import { ReadDocumentPage } from '../../SignUp/PersonalInfo/PersonalInfoPage/DocumentReadAndAgreeField'
import {
  AgreedLegalDocument,
  FormValuesLegalDocs,
} from '../../SignUp/PersonalInfo/PersonalInfoPage/PersonalInfoDocumentsFactory'
import { DocumentGroupsFactory } from '../../SignUp/PersonalInfo/PersonalInfoStep6Page/DocumentGroupsFactory'
import { DocumentItemFactory } from '../../SignUp/PersonalInfo/PersonalInfoStep6Page/DocumentItemFactory'
import { ExternalLink } from '../../global/ExternalLink'
import { Button } from '../../global/button/Button'
import { useSessionEntity } from '../../global/context/EntityContext'
import { useProductReadContext } from '../../global/context/ProductContext'
import { usePublicDomainsReadContext } from '../../global/context/PublicDomainsContext'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { useHomePageUrl } from '../../hooks/useHomePageUrl'
import { EmailIcon } from '../../icons/EmailIcon'
import { LiveChatIcon } from '../../icons/LiveChatIcon'
import { PhoneIcon } from '../../icons/PhoneIcon'
import { LegalDocumentDto } from '../../model/CompanyLegalDocumentDto'
import { Text } from '../../ui/Typography/Typography'
import { getContactUsLink, isTickmillUKType } from '../../utils/companyName.utils'
import { dispatchOpenChat } from '../../utils/cookie.utils'
import { useScrollToTop } from '../../utils/useScrollToTop'
import { ProductRegistrationStepsTemplate } from '../ProductRegistrationStepsPage/ProductRegistrationStepsTemplate'
import { DocumentMarketDataClassifications } from './DocumentMarketDataClassifications'
import {
  ReadDocumentMarketDataPage,
  useDocumentMarketDataClassificationsMutate,
  useDocumentMarketDataIdState,
} from './ReadDocumentMarketDataPage'

import styles from './LegalDocumentsPage.module.scss'

export interface LegalDocumentsValues {
  agreedLegalDocuments: AgreedLegalDocument[]
  documents?: { [key: string]: boolean }
  readDocument: LegalDocumentDto | undefined
  readDocumentMarketData: ReadDocumentMarketData
}

interface ReadDocumentMarketData {
  marketDataClassificationId: number
  isDocumentMarketDataRead: boolean
  isDocumentMarketDataAccepted: boolean
}

type GroupReference = string | null

const LegalDocumentsFormUI: React.FC<FormikProps<LegalDocumentsValues> & OuterProps> = (props) => {
  const { title, subtitle, values, handleSubmit, documents, groupReferences, validateForm } = props
  const { readDocument, readDocumentMarketData } = values

  const homePageUrl = useHomePageUrl()

  const { t } = useTranslation()
  const entity = useSessionEntity()

  const { isDefaultCFDProductType } = useProductReadContext()

  const isCFDProduct = isDefaultCFDProductType()

  useEffect(() => {
    validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readDocument])

  if (readDocument) {
    return <ReadDocumentPage />
  }

  if (readDocumentMarketData?.isDocumentMarketDataRead) {
    return <ReadDocumentMarketDataClientClassificationPage />
  }

  return (
    <ProductRegistrationStepsTemplate title={title} subtitle={subtitle}>
      <div className={classNames(styles.wrapper, 'is-flex')}>
        <SupportOptions />
        <div className={styles.form}>
          <Form onSubmit={handleSubmit}>
            <h3>{isCFDProduct ? t('Legal Documents') : t('Legal Agreements')}</h3>
            <div className='mt-5'>
              <Text>
                {t('Sign up.Please refer to our legal documents section')}{' '}
                <ExternalLink
                  title={isCFDProduct ? t('Legal Documents') : t('Legal Agreements')}
                  url={`${homePageUrl}/about/legal-documents`}
                  className='is-link'
                >
                  {t('Sign up.here')}
                </ExternalLink>
                {'.'}
              </Text>
            </div>

            <Text className='is-flex pt-4'>
              {t('Sign up.Read & Accept the Legal Documents to continue with your registration.')}
            </Text>
            <DocumentGroupsFactory
              groupReferences={groupReferences}
              documents={documents}
              renderItem={(props) => <DocumentItemFactory {...props} />}
            />
            {isTickmillUKType(entity) && !isCFDProduct && <DocumentMarketDataClassifications />}
            <SubmitForm />
          </Form>
        </div>
      </div>
    </ProductRegistrationStepsTemplate>
  )
}

const ReadDocumentMarketDataClientClassificationPage = () => {
  const { values, setFieldValue } = useFormikContext<FormValuesLegalDocs>()

  const mutation = useDocumentMarketDataClassificationsMutate()
  const { setMarketDataClassificationId } = useDocumentMarketDataIdState()

  const handleAccept = async () => {
    await mutation.mutate(values, () => {
      const value = values.readDocumentMarketData.marketDataClassificationId || 0
      setMarketDataClassificationId(value)
      setFieldValue('readDocumentMarketData', {
        marketDataClassificationId: value,
        isDocumentMarketDataRead: false,
        isDocumentMarketDataAccepted: true,
      })
    })
  }

  return <ReadDocumentMarketDataPage onAccept={handleAccept} />
}

const SubmitForm = () => {
  useScrollToTop()
  const { t } = useTranslation()

  const { isDefaultCFDProductType } = useProductReadContext()
  const { isSubmitting, isValid, values } = useFormikContext<LegalDocumentsValues>()

  const isCFDProduct = isDefaultCFDProductType()

  const isDocumentMarketDataAccepted = values.readDocumentMarketData.isDocumentMarketDataAccepted

  return (
    <div className='has-text-centered'>
      <Button
        appearance='primary'
        size='L'
        type='submit'
        disabled={isSubmitting || !isValid || (!isCFDProduct && !isDocumentMarketDataAccepted)}
        className='px-6'
      >
        {t('Continue')}
      </Button>
    </div>
  )
}

interface OuterProps {
  title: string
  subtitle?: string | React.ReactNode
  groupReferences: GroupReference[]
  documents: LegalDocumentDto[]
  marketDataClassificationId: number

  onSubmit(v: LegalDocumentsValues): Promise<void>
}

export const LegalDocumentsForm = withFormik<OuterProps, LegalDocumentsValues>({
  mapPropsToValues: (props) => {
    return {
      agreedLegalDocuments: [],
      readDocument: undefined,
      readDocumentMarketData: {
        marketDataClassificationId: props.marketDataClassificationId || 0,
        isDocumentMarketDataRead: false,
        isDocumentMarketDataAccepted: props.marketDataClassificationId >= 1 || false,
      },
      documents: {},
    }
  },
  handleSubmit: async (values, { props, setSubmitting }) => {
    const { onSubmit } = props

    try {
      setSubmitting(true)
      await onSubmit(values)
      setSubmitting(false)
    } finally {
      setSubmitting(false)
    }
  },
  validate: (values, { documents }) => {
    const errors: FormikErrors<LegalDocumentsValues> = {}

    if (documents.length <= 0) {
      errors.agreedLegalDocuments = t('Validation.Required')
    }

    if (!isDocumentsPositiveValue(values.documents)) {
      errors.agreedLegalDocuments = t('Validation.Required')
    }

    if (!isDocumentsAgreed(documents, values.agreedLegalDocuments)) {
      errors.agreedLegalDocuments = t('Validation.Required')
    }

    return errors
  },
  validateOnMount: true,
  enableReinitialize: false,
})(LegalDocumentsFormUI)

const isDocumentsPositiveValue = (documents: { [key: string]: boolean } = {}) => {
  return Object.entries(documents || []).every(([_, value]) => value)
}

const isDocumentsAgreed = (
  documents: LegalDocumentDto[] = [],
  agreedLegalDocuments: AgreedLegalDocument[] = []
) => {
  return documents.every((document) =>
    agreedLegalDocuments.some((agreedDocument) => agreedDocument.id === document.id)
  )
}

const SupportOptions = (): ReactElement => {
  const { t } = useTranslation()
  const { domains } = usePublicDomainsReadContext()
  const locale = useSessionLanguage()
  const link = getContactUsLink(domains, locale)

  return (
    <div className={classNames(styles.supportOptions, 'is-fullwidth')}>
      <div className='mb-3'>
        <span className='has-text-weight-bold'>{t('Support.We are here to help')}</span>
      </div>

      <span className={styles.helpIcon} onClick={dispatchOpenChat}>
        <LiveChatIcon />
        <span className='is-link'>{t('Support.Start a Chat')}</span>
      </span>
      <span className={styles.helpIcon}>
        <PhoneIcon />
        <ExternalLink className='is-link' url={link}>
          {t('Support.Call Us')}
        </ExternalLink>
      </span>
      <span className={styles.helpIcon}>
        <EmailIcon />{' '}
        <ExternalLink className='is-link' url={link} title={t('Support.Send a Message')}>
          {t('Support.Send a Message')}
        </ExternalLink>
      </span>
    </div>
  )
}
