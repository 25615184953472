import React, { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { useSessionEntity } from '../../../global/context/EntityContext'
import { ToastContext, successToast } from '../../../global/toast/Toast'
import { AccountType } from '../../../model/AccountType'
import { LeadResponseDto } from '../../../model/LeadResponseDto'
import { useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import { getTickmillSupportEmail } from '../../../utils/companyName.utils'
import { SignupErrorStep, SignupFormWrapper, SignupStep } from '../../SignupPage'
import { FormValuesStep0 } from '../PersonalInfoPage/PersonalInfoStepAccountTypePage'
import { FormValuesStep1 } from '../PersonalInfoStep1Page/PersonalInfoDetailsStep1Form'
import { FormValuesStep2 } from '../PersonalInfoStep2Page/PersonalInfoStep2Form'
import { FormValuesStep3 } from '../PersonalInfoStep3Page/PersonalInfoStep3Form'
import { FormValuesStep4 } from '../PersonalInfoStep4Form'
import { usePersonalInfoTotalSteps } from '../usePersonalInfoTotalSteps'

import styles from '../PersonalInfoPage.module.scss'

export type FormValues = FormValuesStep0 &
  FormValuesStep1 &
  FormValuesStep2 &
  FormValuesStep3 &
  FormValuesStep4

interface PersonalInfoDetailsStep4FormProps {
  step: number
  lead: LeadResponseDto | undefined
  values?: Partial<FormValues>
  error?: string
  setStep(step: number): void
}

export const PersonalInfoDetailsStep4Page: React.FC<PersonalInfoDetailsStep4FormProps> = (
  props
) => {
  const { step, lead, values: formValues, error, setStep } = props

  const { t } = useTranslation()

  const apiClient = useApiClient(ClientApiClient)
  const setToast = useContext(ToastContext)

  const handleResend = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    if (lead) {
      await apiClient.resendCreateLeadEmail(lead?.leadId)
      setToast(successToast(t('Email was re-sent')))
    } else if (formValues?.email) {
      await apiClient.resendCreateLeadEmailByEmail(formValues.email)
      setToast(successToast(t('Email was re-sent')))
    }
  }

  const goBack = () => {
    setStep(1)
  }

  if (error) {
    if (isInvalidLeadError(error)) {
      return <InvalidLeadError error={error} goBack={goBack} />
    }

    if (isCannotCreateLeadError(error)) {
      return <CannotCreateLeadError error={error} onResend={handleResend} goBack={goBack} />
    }

    return <LeadError goBack={goBack} />
  }

  return <SuccessPage values={formValues} step={step} error={error} onResend={handleResend} />
}

interface SuccessPageProps {
  step: number
  values?: Partial<FormValues>
  error?: string
  onResend(event: React.MouseEvent<HTMLButtonElement>): void
}

const SuccessPage: React.FC<SuccessPageProps> = (props) => {
  const { step, values: formValues, onResend } = props

  const { t } = useTranslation()
  const totalSteps = usePersonalInfoTotalSteps()

  return (
    <SignupFormWrapper label={t('Sign up.Sign up')} shouldHideBackArrow>
      <SignupStep
        subStep={{
          current: step,
          total: totalSteps,
        }}
        section={2}
        accountTypeId={AccountType.Individual}
      />
      <div className={styles.confirmationWrapper}>
        <h1>{t('Sign up.Verify your Email')}!</h1>
        <span className={styles.verification}>
          {t('Sign up.Please check your inbox', {
            email: formValues?.email,
          })}
          {
            <span className={styles.textStrong}>
              {t('Sign up.click the verification link to continue')}
            </span>
          }{' '}
          {t('Sign up.Your verification link is active for the next 48 hours')}.
        </span>
        <span className={styles.buttonWrapper}>
          {t(`Sign up.Didn't get the link`)}? {t('Sign up.Make sure to check your spam folder or')}
          <button type='button' className={styles.plainButton} onClick={onResend}>
            {t('Sign up.Resend')}
          </button>
        </span>
      </div>
    </SignupFormWrapper>
  )
}

interface InvalidLeadErrorProps {
  error: string
  goBack(): void
}

const InvalidLeadError: React.FC<InvalidLeadErrorProps> = (props) => {
  const { error, goBack } = props

  const { t } = useTranslation()

  const entity = useSessionEntity()

  return (
    <React.Fragment>
      <SignupErrorStep goBack={goBack} />
      <div className={styles.confirmationWrapper}>
        <h1>{t('Sign up.Error!')}</h1>
        <span className={classNames(styles.verification)}>
          {error === 'missing_gender_id' && <span>{t('Sign up.Gender was not specified.')}</span>}
          {error === 'invalid_gender' && <span>{t('Sign up.Invalid gender.')}</span>}
          {error === 'name_contains_illegal_characters' && (
            <span>{t('Sign up.Please use latin characters only for name.')}</span>
          )}
          {error === 'invalid_first_name' && (
            <span>{t('Sign up.First name contains unsupported characters.')}</span>
          )}
          {error === 'invalid_last_name' && (
            <span>{t('Sign up.Last name contains unsupported characters.')}</span>
          )}
          {error === 'invalid_birth_day' && (
            <span>{t('Sign up.Selected birthday is invalid.')}</span>
          )}
          {error === 'invalid_email' && <span>{t('Sign up.Email provided is invalid.')}</span>}
          {error === 'invalid_lead_type' && (
            <span>{t('Sign up.Account type selected is invalid.')}</span>
          )}
          {error === 'invalid_phone_number_length' && (
            <span>{t('Sign up.Phone number needs to be 5 or more digits.')}</span>
          )}
          {error === 'invalid_country' && (
            <span>{t('Sign up.This country is not available for this Tickmill entity.')}</span>
          )}
          {error === 'invalid_language' && (
            <span>{t('Sign up.Language selected is invalid.')}</span>
          )}
          {error === 'invalid_user_status_aptest_limit' && (
            <span>
              {t(
                'Sign up.Maximum attempts reached. You will be able to re-take the appropriateness test in 1 year. Please contact {{email}} for further assistance.',
                {
                  email: getTickmillSupportEmail(entity),
                }
              )}
            </span>
          )}
          {error === 'invalid_user_status_vulnerable_client' && (
            <span>
              {t(
                'Sign up.From the information you have provided, we do not think this product is appropriate for you at this time. If you would like to discuss further, please contact {{email}}.',
                {
                  email: getTickmillSupportEmail(entity),
                }
              )}
            </span>
          )}
          {error === 'address_street_client_contains_illegal_characters' && (
            <span>{t('Sign up.Address street contains unsupported characters')}</span>
          )}
          {error === 'address_city_client_contains_illegal_characters' && (
            <span>{t('Sign up.Address city contains unsupported characters')}</span>
          )}
          {error === 'address_county_client_contains_illegal_characters' && (
            <span>{t('Sign up.Address state contains unsupported characters')}</span>
          )}
          {error === 'address_postcode_client_contains_illegal_characters' && (
            <span>{t('Sign up.Address postcode contains unsupported characters')}</span>
          )}
          {error === 'address_street_company_contains_illegal_characters' && (
            <span>{t('Sign up.Company address street contains unsupported characters')}</span>
          )}
          {error === 'address_city_company_contains_illegal_characters' && (
            <span>{t('Sign up.Company address city contains unsupported characters')}</span>
          )}
          {error === 'address_county_company_contains_illegal_characters' && (
            <span>{t('Sign up.Company address state contains unsupported characters')}</span>
          )}
          {error === 'address_postcode_company_contains_illegal_characters' && (
            <span>{t('Sign up.Company address postcode contains unsupported characters')}</span>
          )}
        </span>
        <span className={styles.buttonWrapper}>
          {' '}
          {t('Sign up.Go back to')}{' '}
          <Link to={'/login'}>
            <button type='button' className={styles.plainButton}>
              {t('Sign up.Login Page')}
            </button>
          </Link>
        </span>
      </div>
    </React.Fragment>
  )
}

interface CannotCreateLeadErrorProps {
  error: string
  onResend(event: React.MouseEvent<HTMLButtonElement>): void
  goBack(): void
}

const CannotCreateLeadError: React.FC<CannotCreateLeadErrorProps> = (props) => {
  const { error, onResend, goBack } = props

  const { t } = useTranslation()

  return (
    <React.Fragment>
      <SignupErrorStep goBack={goBack} />
      <div className={styles.confirmationWrapper}>
        <h1>{t('Sign up.Error!')}</h1>
        <span className={styles.verification}>
          {error === 'already_awaiting_lead_verification' && (
            <span>
              {t(
                'Sign up.Verification email has already been sent to you, please verify your email before proceeding.'
              )}
            </span>
          )}
          {error === 'lead_already_verified' && (
            <Trans
              i18nkey={t(
                'Sign up.You have started the registration process earlier. To continue registration please press'
              )}
            >
              <span>
                {t(
                  'Sign up.You have started the registration process earlier. To continue registration please press'
                )}
              </span>
              <Link to='#'>
                <button type='button' className={styles.plainButton} onClick={onResend}>
                  {t('Sign up.Resend')} {t('Sign up.email')}
                </button>
              </Link>
              .
            </Trans>
          )}
          {error === 'already_started_another_lead_verification_process' && (
            <Trans
              i18nkey={t(
                'Sign up.You have started the registration process earlier. To continue registration please press'
              )}
            >
              <span>
                {t(
                  'Sign up.You have started the registration process earlier. To continue registration please press'
                )}
              </span>
              <Link to='#'>
                <button type='button' className={styles.plainButton} onClick={onResend}>
                  {t('Sign up.Resend')} {t('Sign up.email')}
                </button>
              </Link>
              .
            </Trans>
          )}
          {error === 'lead_already_converted' && (
            <span>{t('Sign up.We already have a record with this e-mail account.')}</span>
          )}
          {error === 'lead_already_exists' && (
            <span>
              {t(
                'Sign up.We already have a record with this e-mail account. Please verify your e-mail.'
              )}
            </span>
          )}
        </span>
        <span className={styles.buttonWrapper}>
          {' '}
          {t('Sign up.Go back to')}{' '}
          <Link to={'/login'}>
            <button type='button' className={styles.plainButton}>
              {t('Sign up.Login Page')}
            </button>
          </Link>
        </span>
      </div>
    </React.Fragment>
  )
}

interface LeadErrorProps {
  goBack(): void
}

const LeadError: React.FC<LeadErrorProps> = (props) => {
  const { goBack } = props

  const { t } = useTranslation()

  return (
    <React.Fragment>
      <SignupErrorStep goBack={goBack} />
      <div className={styles.confirmationWrapper}>
        <h1>{t('Sign up.Error!')}</h1>
        <span className={styles.verification}>
          {t('Sign up.Something went wrong, please contact support.')}
        </span>
        <span className={styles.buttonWrapper}>
          {' '}
          {t('Sign up.Go back to')}{' '}
          <Link to={'/login'}>
            <button type='button' className={styles.plainButton}>
              {t('Sign up.Login Page')}
            </button>
          </Link>
        </span>
      </div>
    </React.Fragment>
  )
}

const isInvalidLeadError = (error: string | undefined) => {
  const code = error?.toLocaleLowerCase() || ''
  return (
    code === 'missing_gender_id' ||
    code === 'invalid_gender' ||
    code === 'name_contains_illegal_characters' ||
    code === 'invalid_first_name' ||
    code === 'invalid_last_name' ||
    code === 'invalid_birth_day' ||
    code === 'invalid_email' ||
    code === 'invalid_lead_type' ||
    code === 'invalid_phone_number_length' ||
    code === 'invalid_country' ||
    code === 'invalid_language' ||
    code === 'invalid_user_status_aptest_limit' ||
    code === 'invalid_user_status_vulnerable_client' ||
    code === 'address_street_client_contains_illegal_characters' ||
    code === 'address_city_client_contains_illegal_characters' ||
    code === 'address_county_client_contains_illegal_characters' ||
    code === 'address_postcode_client_contains_illegal_characters' ||
    code === 'address_street_company_contains_illegal_characters' ||
    code === 'address_city_company_contains_illegal_characters' ||
    code === 'address_county_company_contains_illegal_characters' ||
    code === 'address_postcode_company_contains_illegal_characters'
  )
}

const isCannotCreateLeadError = (error: string | undefined) => {
  const code = error?.toLocaleLowerCase() || ''
  return (
    code === 'already_awaiting_lead_verification' ||
    code === 'lead_already_verified' ||
    code === 'already_started_another_lead_verification_process' ||
    code === 'lead_already_converted'
  )
}
