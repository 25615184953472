import React, { Fragment, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TransactionBadgeStatus } from '../../Traders-Room/Transaction-History/TransactionBadgeStatus'
import { useArabicSessionLanguage } from '../../global/context/SessionSettingsContext'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { TransactionDto } from '../../model/TransactionDto'
import { TransactionStatus } from '../../model/TransactionStatus'
import { TextSmall } from '../../ui/Typography/Typography'
import { PageQuery } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { formatDate } from '../../utils/date.utils'
import {
  TransactionFields,
  TransactionType,
  getPATransactionFieldsByTransactionType,
  getTransactionType,
} from '../../utils/transaction.utils'

import styles from './PaymentAgentTransactionHistoryCard.module.scss'

interface Props {
  data: TransactionDto

  isLoading: boolean
  pageQuery?: PageQuery
  setPageQuery: ((pageQuery?: PageQuery | undefined) => void) | undefined

  setSelectedTransaction(id: string): void

  setHistoryModal(v: boolean): void

  setOptionModal({
    data,
    visible,
    step,
  }: {
    data?: TransactionDto
    visible: boolean
    step: number
  }): void
}

export const PaymentAgentTransactionHistoryCard: React.FC<Props> = ({
  data,
  setHistoryModal,
  setSelectedTransaction,
  setOptionModal,
}) => {
  const { t } = useTranslation()
  const isArabic = useArabicSessionLanguage()
  const [expanded, setExpanded] = useState(false)
  const [auth] = useContext(AuthSessionContext)
  const { formatMoney } = useFormatNumber()

  const getReversedTransactionFields = (fields: TransactionFields): TransactionFields => {
    return {
      ...fields,
      from: fields.to,
      to: fields.from,
    }
  }

  const isReversed = data.type.id === TransactionType.Reversed
  const reversedTransactionType = data.reversedTransactions?.[0].type.id
  const dateFormat = auth?.dateFormatType?.name

  const transactionType =
    isReversed && reversedTransactionType
      ? getTransactionType(reversedTransactionType)
      : getTransactionType(data.type.id)

  let fields = getPATransactionFieldsByTransactionType(
    transactionType,
    data,
    isArabic,
    auth?.id,
    isReversed
  )

  if (isReversed) {
    fields = getReversedTransactionFields(fields)
  }

  const canBeCancelled =
    (data.type.id === TransactionType.WithdrawToPaymentAgent ||
      data.type.id === TransactionType.WalletWithdrawal) &&
    data.state.id === TransactionStatus.AwaitingApproval

  const handleSetTransactionHistoryModal = (id: string) => {
    setSelectedTransaction(id)
    setHistoryModal(true)
  }
  return (
    <div onClick={() => setExpanded((prevState) => !prevState)} className={styles.card}>
      <div className={styles.title}>
        <span className={styles.strong}>{data.type.name}</span>
        <span className={styles.strong}>{formatMoney(fields.amount, fields.currency)}</span>
      </div>
      <div className={styles.subtitle}>
        <span>{formatDate(data.createdDate, dateFormat)}</span>
        <span onClick={() => handleSetTransactionHistoryModal(data.id)} className={styles.active}>
          <TransactionBadgeStatus {...data.state} />
        </span>
      </div>
      {expanded && (
        <Fragment>
          <div className={styles.subtitle}>
            <span className={styles.secondary}>{t('From')}</span>
            <span dir={isArabic ? 'ltr' : ''}>{fields.from}</span>
          </div>
          <div className={styles.subtitle}>
            <span className={styles.secondary}>{t('To')}</span>
            <span dir={isArabic ? 'ltr' : ''}>{fields.from}</span>
          </div>
          <div className={styles.subtitle}>
            <span className={styles.secondary}>{t('IB.Reference number')}</span>
            <span>{data.referenceId}</span>
          </div>
          {canBeCancelled && (
            <div className='ml-auto'>
              <TextSmall
                onClick={() => setOptionModal({ data, visible: true, step: 2 })}
                className={styles.error}
              >
                {t('Transactions.Cancel Transaction')}
              </TextSmall>
            </div>
          )}
        </Fragment>
      )}
    </div>
  )
}
