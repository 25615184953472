import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { hasAdditionalId } from '../SignUp/SignupPage'
import { ProductKey } from '../global/context/ProductContext'
import { clearNotificationHiding } from '../model/Notification'
import { TokenStorage } from './tokenStorage'

export function useLocallyPersistedState<T>(
  key: string,
  defaultValue: T
): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState<T>(() => {
    const persistedValueRaw = localStorage.getItem(key)
    try {
      return persistedValueRaw ? JSON.parse(persistedValueRaw) : defaultValue
    } catch (error: unknown) {
      console.error('Failed to parse JSON:', error)
      return defaultValue
    }
  })

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === key && e.newValue) {
        setValue(JSON.parse(e.newValue))
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [key, value])

  const setter = (newValue: T) => {
    if (typeof newValue === 'boolean') {
      localStorage.setItem(key, JSON.stringify(newValue))
    }
    if (newValue) {
      localStorage.setItem(key, JSON.stringify(newValue))
    } else {
      localStorage.removeItem(key)
    }

    window.dispatchEvent(
      new StorageEvent('storage', {
        key: key,
        oldValue: JSON.stringify(value),
        newValue: JSON.stringify(newValue),
      })
    )

    setValue(newValue)
  }

  return [
    value,
    (newValue) => {
      if (typeof newValue === 'function') {
        setter((newValue as Function)(value)) // eslint-disable-line @typescript-eslint/ban-types
      } else {
        setter(newValue)
      }
    },
  ]
}

export const clearLocalStorage = (): void => {
  localStorage.removeItem('hideDocumentExpiration')
  localStorage.removeItem('auth')
  localStorage.removeItem('IntroducingBrokerContext')
  localStorage.removeItem('luckyDrawEligible')
  localStorage.removeItem('luckyDrawViewed')
  localStorage.removeItem('account')
  localStorage.removeItem('isBrowserOutdatedModalDisplayed')
  localStorage.removeItem('productHandled')
  localStorage.removeItem('walletId')
  localStorage.removeItem(ProductKey)
  localStorage.removeItem('hasDismissedFirstDepositPrompt')
  localStorage.removeItem(hasAdditionalId)

  TokenStorage.clear()
  clearNotificationHiding()
}
