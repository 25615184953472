import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikErrors, FormikProps, useFormikContext, withFormik } from 'formik'
import { t } from 'i18next'

import { createFormField } from '../../../../global/formField/FormField'
import { CancelActionModal } from '../../../../global/modal/CancleActionModal'
import { Modal } from '../../../../global/modal/Modal'
import { FormTemplate } from '../../../../global/templates/FormTemplate'
import { FormatMoney, useFormatNumber } from '../../../../hooks/useFormatNumber'
import { DropArrowDownIcon } from '../../../../icons/DropArrowDownIcon'
import { PaymentProvider } from '../../../../model/PaymentProviderDto'
import {
  PaymentProviderType,
  isPaymentProviderBankType,
  isPaymentProviderCardProviderType,
  isPaymentProviderNoneType,
  isPaymentProviderPSPType,
  isPaymentProviderPaymentAgentType,
} from '../../../../model/PaymentProviderType'
import { TransactionDto } from '../../../../model/TransactionDto'
import { TransactionStatus } from '../../../../model/TransactionStatus'
import { WalletDto, WalletTypeEnum } from '../../../../model/WalletDto'
import { WalletPaymentProvider } from '../../../../model/WalletPaymentProvider'
import { WalletPaymentProviderMethodCurrency } from '../../../../model/WalletPaymentProviderParameters'
import { useAccountReadContext } from '../../../../utils/AccountContextContext'
import { useApiClient } from '../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../utils/clientApi'
import { TickmillCompaniesEnum } from '../../../../utils/companyName.utils'
import { prefixFilter } from '../../../../utils/filter.utils'
import { useIsLangSelected } from '../../../../utils/language.utils'
import { TransactionType } from '../../../../utils/transaction.utils'
import { useCallbackWithForceRefresh } from '../../../../utils/useCallbackWithForceRefresh'
import { useFetchOne } from '../../../../utils/useFetch'
import { isZero } from '../../../../utils/validations'
import { WalletDepositModal } from '../WalletDepositModal'
import { WalletDepositPaymentProviderModal } from '../WalletDepositPaymentProviderModal'
import { WalletDepositTermsConditions } from '../WalletDepositTermsConditions'
import { usePaymentProviderFactory } from '../usePaymentProviderFactory'
import { WalletDepositPSPFields } from './WalletDepositPSPFields'
import { WalletDepositPaymentAgentFields } from './WalletDepositPaymentAgentFields'
import { WalletDepositProviderNoneFields } from './WalletDepositProviderNoneFields'

export interface WalletDepositFormValues {
  wallet: Wallet
  paymentProvider: PaymentProvider
  walletType?: WalletTypeEnum
  amount: number | undefined
  comment: string
  terms: {
    responsibility: boolean
    depositing: boolean
    paymentOperations: boolean
    powerOfAttorney?: boolean
    cupResponsibility?: boolean
  }
  fields: { [key: string]: string }
}

export interface Wallet {
  id: string | undefined
  name: string | undefined
  currency: { id: string; name: string }
  walletType: { id: number; name: string }
  balance: number | undefined
}

export const getCurrencyLimits = (
  wallet: Wallet,
  paymentProvider: PaymentProvider
): WalletPaymentProviderMethodCurrency | undefined => {
  return paymentProvider?.parameters?.currenciesLimits
    .filter((x) => wallet?.walletType?.id)
    .find((x) => x.id === paymentProvider.currency.id)
}

const FormField = createFormField<WalletDepositFormValues>()

const WalletDepositFormUI: React.FC<FormikProps<WalletDepositFormValues> & OuterProps> = (
  props
) => {
  const { title, wallet, setValues, onCancel, paymentProvider } = props
  const [isOpenTermsCondition, setIsOpenTermsCondition] = useState(false)
  const [walletPaymentProvider, setWalletPaymentProvider] = useState<
    WalletPaymentProvider | undefined
  >()

  const { values, resetForm } = useFormikContext<WalletDepositFormValues>()
  const { wallet: walletFromValues, paymentProvider: paymentProviderFromValues } = values
  const selectedWallet = walletFromValues || wallet
  const selectedPaymentProvider = paymentProviderFromValues || paymentProvider

  const { t } = useTranslation()

  const [isPageExitConfirmation, setPageExitConfirmation] = useState(false)
  const apiClient = useApiClient(ClientApiClient)
  const { account } = useAccountReadContext()

  useIsLangSelected(() => {
    resetForm()
    setValues({ ...initialState(), wallet: values.wallet, walletType: values.walletType })
  })

  const { callback } = useCallbackWithForceRefresh(
    async () => {
      return await apiClient.getTransactions({
        caller: 'ca',
        pageSize: 1,
        ca_search_ClientWalletType: prefixFilter({
          prefix: account?.id,
          value: WalletTypeEnum.ETD,
        }),
        ca_search_TransactionTypeId: TransactionType.WalletDeposit,
        ca_search_TransactionStateId: TransactionStatus.Completed,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const { data: transactions } = useFetchOne(callback)
  const { createPaymentProvider } = usePaymentProviderFactory()

  useEffect(() => {
    ;(async () => {
      if (walletPaymentProvider && wallet) {
        const paymentProvider = await createPaymentProvider(walletPaymentProvider, wallet)
        setValues({
          ...values,
          paymentProvider,
        })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePageExitConfirmation = () => {
    onCancel()
  }

  const handleExitPageConfirmationModalOpen = () => {
    setPageExitConfirmation(true)
  }

  const handleExitPageConfirmationModalClose = () => {
    setPageExitConfirmation(false)
  }

  const getTitle = () => {
    return title ? `${title} ${t('Wallet.Deposit')}` : t('Wallet.Deposit')
  }
  const onClickTermsConditions = () => setIsOpenTermsCondition(true)

  const handleSetWalletPaymentProvider = (
    walletPaymentProvider: WalletPaymentProvider | undefined
  ) => setWalletPaymentProvider(walletPaymentProvider)

  if (isOpenTermsCondition && selectedWallet && selectedPaymentProvider) {
    return (
      <WalletDepositTermsConditions
        wallet={selectedWallet}
        paymentProvider={selectedPaymentProvider}
        onCancel={() => setIsOpenTermsCondition(false)}
      />
    )
  }

  return (
    <FormTemplate title={getTitle()} goBack={handleExitPageConfirmationModalOpen}>
      {isPageExitConfirmation && (
        <Modal
          closeModal={handleExitPageConfirmationModalClose}
          render={() => (
            <CancelActionModal
              onConfirm={handlePageExitConfirmation}
              onCancel={handleExitPageConfirmationModalClose}
            />
          )}
        />
      )}
      <Form>
        <FormFieldsFactory
          transactions={transactions?.items}
          onCancel={handleExitPageConfirmationModalOpen}
          onClickTermsConditions={onClickTermsConditions}
          onSetWalletPaymentProvider={handleSetWalletPaymentProvider}
        />
      </Form>
    </FormTemplate>
  )
}

interface FormFieldsFactoryProps {
  onCancel(): void

  transactions?: TransactionDto[]
  onClickTermsConditions(): void

  onSetWalletPaymentProvider(walletPaymentProvider: WalletPaymentProvider | undefined): void
}

const FormFieldsFactory: React.FC<FormFieldsFactoryProps> = (props) => {
  const { onCancel, onClickTermsConditions, onSetWalletPaymentProvider } = props
  const { values, setValues, resetForm } = useFormikContext<WalletDepositFormValues>()
  const { formatMoney } = useFormatNumber()

  const { paymentProvider } = values

  const [isWalletModalOpen, setWalletModalOpen] = useState(false)
  const [isPaymentProviderModalOpen, setPaymentProviderModalOpen] = useState(false)

  const providerCategoryId = values.paymentProvider.providerCategory.id

  const handleExitPageConfirmationModalClose = () => {
    onCancel()
  }

  const handleWallet = (wallet: Wallet) => {
    resetForm()
    setValues({
      ...initialState(),
      wallet,
      walletType: values.walletType,
    })
    setWalletModalOpen(false)
    onSetWalletPaymentProvider(undefined)
  }

  const handleWalletOpen = () => {
    setWalletModalOpen(true)
  }

  const handleWalletClose = () => {
    setWalletModalOpen(false)
  }

  const handlePaymentProvider = (
    paymentProvider: PaymentProvider,
    walletPaymentProvider: WalletPaymentProvider
  ) => {
    resetForm()
    setValues({
      ...initialState(),
      wallet: values.wallet,
      walletType: values.walletType,
      paymentProvider,
    })
    setPaymentProviderModalOpen(false)
    onSetWalletPaymentProvider(walletPaymentProvider)
  }

  const handlePaymentProviderOpen = () => {
    setPaymentProviderModalOpen(true)
  }

  const handlePaymentProviderClose = () => {
    setPaymentProviderModalOpen(false)
  }

  const { t } = useTranslation()

  return (
    <React.Fragment>
      {isWalletModalOpen && (
        <WalletDepositModal onSelectOption={handleWallet} onClose={handleWalletClose} />
      )}
      {isPaymentProviderModalOpen && (
        <WalletDepositPaymentProviderModal
          onSelectOption={handlePaymentProvider}
          onClose={handlePaymentProviderClose}
        />
      )}

      <FormField
        name='wallet.name'
        label={t('Wallet.Deposit To Wallet')}
        placeholder={t('Wallet.Deposit To Wallet')}
        value={
          values.wallet?.id &&
          t('Wallet.Wallet Currency Name', {
            walletCurrencyId: values.wallet.currency.id,
            walletName: values.wallet.name,
          })
        }
        rightIcon={<DropArrowDownIcon />}
        hint={
          values.wallet?.id &&
          `${t('Wallet.Balance')} ${formatMoney(values.wallet.balance, values.wallet.currency.id)}`
        }
        required
        readOnly
        onClick={handleWalletOpen}
      />
      <FormField
        name='paymentProvider.name'
        value={values.paymentProvider.currency?.description}
        label={t('Wallet.Payment Method')}
        placeholder={t('Wallet.Payment Method')}
        rightIcon={<DropArrowDownIcon />}
        disabled={!values.wallet?.id}
        required
        readOnly
        onClick={handlePaymentProviderOpen}
        key={values.paymentProvider?.id ? 'paymentProvider' : 'no-paymentProvider'}
      />
      {paymentProvider.id && isPaymentProviderPSPType(providerCategoryId) && (
        <WalletDepositPSPFields
          minAmount={getCurrencyLimits(values.wallet, values.paymentProvider)?.minAmount}
          showAmountPresets
          onCancel={handleExitPageConfirmationModalClose}
          onClickTermsConditions={onClickTermsConditions}
        />
      )}
      {paymentProvider.id && isPaymentProviderCardProviderType(providerCategoryId) && (
        <WalletDepositPSPFields
          minAmount={getCurrencyLimits(values.wallet, values.paymentProvider)?.minAmount}
          showAmountPresets
          onCancel={handleExitPageConfirmationModalClose}
          onClickTermsConditions={onClickTermsConditions}
        />
      )}
      {paymentProvider.id && isPaymentProviderBankType(providerCategoryId) && (
        <WalletDepositPSPFields
          minAmount={getCurrencyLimits(values.wallet, values.paymentProvider)?.minAmount}
          showAmountPresets
          onCancel={handleExitPageConfirmationModalClose}
          onClickTermsConditions={onClickTermsConditions}
        />
      )}
      {paymentProvider.id && isPaymentProviderPaymentAgentType(providerCategoryId) && (
        <WalletDepositPaymentAgentFields
          onCancel={handleExitPageConfirmationModalClose}
          onClickTermsConditions={onClickTermsConditions}
        />
      )}
      {paymentProvider.id && isPaymentProviderNoneType(providerCategoryId) && (
        <WalletDepositProviderNoneFields
          onCancel={handleExitPageConfirmationModalClose}
          onClickTermsConditions={onClickTermsConditions}
        />
      )}
    </React.Fragment>
  )
}

interface OuterProps {
  wallet?: WalletDto
  walletType: WalletTypeEnum
  entity: TickmillCompaniesEnum

  onSubmit(values: WalletDepositFormValues): void
  formatMoney: FormatMoney
  onCancel(): void

  title?: string

  paymentProvider?: PaymentProvider
}

interface StateProps {
  wallet?: WalletDto
  walletType: WalletTypeEnum
  paymentProvider?: PaymentProvider
}

const initialState = (props?: StateProps): WalletDepositFormValues => {
  const { wallet, walletType, paymentProvider } = props || {}
  return {
    wallet: {
      id: wallet?.id || undefined,
      name: wallet?.name || undefined,
      currency: {
        id: wallet?.currency?.id || '',
        name: wallet?.currency?.name || '',
      },
      walletType: {
        id: wallet?.walletType?.id || 0,
        name: wallet?.walletType?.name || '',
      },
      balance: wallet?.balance || 0,
    },
    paymentProvider: paymentProvider || {
      id: undefined,
      name: undefined,
      description: '',
      providerCategory: { id: PaymentProviderType.None },
      currency: { id: '', name: '', minAmount: 0 },
      parameters: {
        currencies: [],
        currenciesLimits: [],
        messages: [{ title: [], list: [], top: [], bottom: [] }],
        termsConditions: [],
        fields: [],
        form: [],
      },
      method_name: '',
    },
    walletType: walletType,
    fields: {},
    amount: undefined,
    comment: '',
    terms: {
      responsibility: true,
      depositing: false,
      paymentOperations: false,
    },
  }
}

export const WalletDepositForm = withFormik<OuterProps, WalletDepositFormValues>({
  mapPropsToValues: ({ wallet, walletType, paymentProvider }) => {
    return initialState({ wallet, walletType, paymentProvider })
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    try {
      setSubmitting(true)
      props.onSubmit(values)
      setSubmitting(false)
    } finally {
      setSubmitting(false)
    }
  },
  validate: (values, { formatMoney }) => {
    const errors: FormikErrors<WalletDepositFormValues> = {}
    const isPaymentAgentProviderType =
      values.paymentProvider.providerCategory.id === PaymentProviderType.PaymentAgent

    if (!values.wallet) {
      errors.wallet = {
        name: t('Validation.Required'),
      }
    }

    if (!values?.paymentProvider?.name) {
      errors.paymentProvider = {}
      errors.paymentProvider.name = t('Validation.Required')
    }

    const providerCategoryId = values.paymentProvider?.providerCategory?.id
    if (
      isPaymentProviderPSPType(providerCategoryId) ||
      isPaymentProviderCardProviderType(providerCategoryId) ||
      isPaymentProviderBankType(providerCategoryId)
    ) {
      const { minAmount, maxAmount } = getErrorAmount(values)

      if (isMinAmountError(values)) {
        errors.amount = `${t('Wallet.Minimum deposit')} ${formatMoney(
          minAmount,
          values.paymentProvider.currency.id
        )}`
      }

      if (isMaxAmountError(values)) {
        errors.amount = `${t('Wallet.Maximum deposit')} ${formatMoney(
          maxAmount,
          values.paymentProvider.currency.id
        )}`
      }

      if (isValidAmount(values)) {
        if (values?.paymentProvider?.currency?.id && values?.paymentProvider?.currency?.minAmount) {
          errors.amount = t('Validation.Minimum amount is {{amount}} {{currency}}', {
            currency: values?.paymentProvider?.currency?.id,
            amount: values?.paymentProvider?.currency?.minAmount,
          })
        } else {
          errors.amount = t('Validation.USD 1000 or equivalent')
        }
      }

      if (isZero(minAmount) || isZero(minAmount)) {
        errors.amount = t('Validation.Required')
      }

      if (!values.terms.paymentOperations) {
        errors.terms = {}
        errors.terms.paymentOperations = t('Validation.Required')
      }
    }

    if (isPaymentProviderPaymentAgentType(providerCategoryId)) {
      if (!values.terms.paymentOperations) {
        errors.terms = {}
        errors.terms.paymentOperations = t('Validation.Required')
      }
    }
    if (!values.terms.responsibility) {
      errors.terms = {}
      errors.terms.responsibility = t('Validation.Required')
    }

    if (isPaymentAgentProviderType) {
      if (!values.terms.powerOfAttorney) {
        errors.terms = {}
        errors.terms.powerOfAttorney = t('Validation.Required')
      }
    }

    if (values.comment.length > 100) {
      errors.comment = t('Validation.The character limit is 100 characters')
    }

    if (
      values.paymentProvider.id &&
      isChinaUnionPayPSP(values.paymentProvider.id) &&
      !values.terms.cupResponsibility
    ) {
      errors.terms = errors.terms || {}
      errors.terms.cupResponsibility = t('Validation.Required')
    }
    return errors
  },
  enableReinitialize: true,
})(WalletDepositFormUI)

const getErrorAmount = (values: WalletDepositFormValues) => {
  const currencyLimit = getCurrencyLimits(values.wallet, values.paymentProvider)
  const minAmount = currencyLimit?.minAmount || 0
  const maxAmount = currencyLimit?.maxAmount || 0

  return { minAmount, maxAmount }
}

const isMinAmountError = (values: WalletDepositFormValues): boolean => {
  const amount = values?.amount || 0
  const { minAmount } = getErrorAmount(values)

  return amount < minAmount || isZero(amount)
}

const isMaxAmountError = (values: WalletDepositFormValues): boolean => {
  const amount = values?.amount || 0
  const { maxAmount } = getErrorAmount(values)

  return amount > maxAmount
}

const isValidAmount = (values: WalletDepositFormValues): boolean => {
  const minAmount = values?.paymentProvider?.currency?.minAmount || 0
  const amount = values?.amount || 0

  return amount < minAmount
}

export const isChinaUnionPayPSP = (providerId: string): boolean =>
  [
    {
      id: '9599bfdb-825e-11eb-a2ad-06e70b382a0c',
      name: 'China UnionPay - Channel Z (SC Zotapay CNY (CUP))',
    },
    {
      id: '9017bcc6-939e-11ea-9c68-000c29df7a17',
      name: 'China UnionPay - Channel T (SC Transact365 CNY)',
    },
    {
      id: 'abfb42e9-9d79-11ed-8b0c-0a51dcb142a4',
      name: 'China Online Bank Transfer (SC Xpay CNY)',
    },
  ]
    .map((psp) => psp.id)
    .includes(providerId)
