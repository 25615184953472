import React, { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { format } from 'date-fns'
import pdfMake from 'pdfmake/build/pdfmake'

import { Loading } from '../../global/Loading/Loading'
import { Button } from '../../global/button/Button'
import { useSessionEntity } from '../../global/context/EntityContext'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { InformationModal } from '../../global/modal/InformationModal'
import { Modal } from '../../global/modal/Modal'
import { DownloadIcon } from '../../icons/DownloadIcon'
import { HistoryIcon } from '../../icons/HistoryIcon'
import { TMDocLogo } from '../../icons/Logos/TMDocLogo'
import { AccountDetailedDto } from '../../model/AccountDetailedDto'
import { TestAnswersDto, TestStatusEnum } from '../../model/ClientTestAnswerDto'
import { ClientTestDto } from '../../model/ClientTestDto'
import { ClientTestSubcategoryEnum } from '../../model/ClientTestSubcategoryEnum'
import { RegulatorOptionDto } from '../../model/RegulatorOptionDto'
import { TaxHistoryDto } from '../../model/TaxHistoryDto'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { useApiClient } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { ClientApiClient } from '../../utils/clientApi'
import { formatDate } from '../../utils/date.utils'
import { useFetchOne } from '../../utils/useFetch'
import { W8BeneficialForm, W8BeneficialFormValues, getQuestionAnswer } from './W8BeneficialForm'

import styles from './W8BeneficialPage.module.scss'

pdfMake.fonts = {
  ArialUnicodeMs: {
    normal: `${window.location.origin}/fonts/arial-unicode-ms.ttf`,
    bold: `${window.location.origin}/fonts/arial-unicode-ms.ttf`,
    italics: `${window.location.origin}/fonts/arial-unicode-ms.ttf`,
  },
}

const CheckBoxOnSVG = `
  <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 0C0.895431 0 0 0.895431 0 2V10C0 11.1046 0.895431 12 2 12H10C11.1046 12 12 11.1046 12 10V2C12 0.895431 11.1046 0 10 0H2ZM9.4 2.66667L10.3333 3.6L5.93333 8L5 8.93333L4.06667 8L1.66667 5.6L2.6 4.66667L5 7.06667L9.4 2.66667Z'
      fill='#3C3C3C'
    />
  </svg>
`

const CheckBoxOffSVG = `
  <svg
    viewBox='175.581 124.419 11.628 11.628'
    width='11.628'
    height='11.628'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect
      x='175.581'
      y='124.419'
      width='11.628'
      height='11.628'
      style='fill: rgb(216, 216, 216); stroke: rgb(0, 0, 0);'
    />
  </svg>
`

export const W8BeneficialPage: React.FC = () => {
  const apiClient = useApiClient(ClientApiClient)
  const { account } = useAccountReadContext()
  const entity = useSessionEntity()
  const locale = useSessionLanguage()

  const testHistoryCallback = useCallback(async () => {
    return apiClient.getTaxHistory(locale)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const { data: testHistory = [], isLoading: isLoadingTaxHistory } =
    useFetchOne(testHistoryCallback)

  const regulatorsCallback = useCallback(async () => {
    return apiClient.getRegulators(entity, locale)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])
  const { data: regulators = [], isLoading } = useFetchOne(regulatorsCallback)

  const taxFormQuestionsCallback = useCallback(async () => {
    return await apiClient.getTaxFormQuestions({
      subcategoryId: ClientTestSubcategoryEnum.W8BENTaxForm,
      languageId: locale,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const { data: taxFormQuestions, isLoading: isLoadingTaxFormQuestions } =
    useFetchOne(taxFormQuestionsCallback)

  const taxFormAnswersCallback = useCallback(async () => {
    return await apiClient.getTaxFormAnswers({
      subcategoryId: 5,
      languageId: locale,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const { data: taxFormAnswers, isLoading: isLoadingTaxFormAnswers } =
    useFetchOne(taxFormAnswersCallback)

  return (
    <Loading
      showLoadingIcon
      isLoading={
        isLoadingTaxFormAnswers && isLoadingTaxFormQuestions && isLoading && isLoadingTaxHistory
      }
    >
      <W8Beneficial
        account={account}
        taxFormAnswers={taxFormAnswers}
        taxFormQuestions={taxFormQuestions}
        testHistory={testHistory}
        regulators={regulators}
      />
    </Loading>
  )
}

interface Props {
  taxFormAnswers: TestAnswersDto | undefined
  taxFormQuestions: ClientTestDto | undefined
  regulators: RegulatorOptionDto[]
  testHistory: TaxHistoryDto[]
  account: AccountDetailedDto | undefined
}

export const W8Beneficial: React.FC<Props> = ({
  taxFormAnswers,
  taxFormQuestions,
  regulators,
  testHistory,
  account,
}) => {
  const { t } = useTranslation()
  const apiClient = useApiClient(ClientApiClient)
  const navigate = useNavigate()
  const locale = useSessionLanguage()
  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name

  const [submitted, setSubmitted] = useState(false)

  const handleFormSubmit = async (values: W8BeneficialFormValues) => {
    const clientId = account?.id || ''
    try {
      await apiClient.createClientTests({
        testId: taxFormQuestions?.id || '',
        category: taxFormQuestions?.category || '',
        clientId,
        dateTaken: new Date().toISOString(),
        freeAnswers: values.freeAnswers.filter((item) => item.answer),
        selectedAnswers: values.selectedAnswers.map((x) => x.answer),
      })
      setSubmitted(true)
    } catch (error: unknown) {
      console.error(error)
    }
  }
  const [actionModal, setActionModal] = useState(false)
  const [historyModal, setHistoryModal] = useState(false)

  const handleCloseActionModal = () => setActionModal(false)
  const handleOpenActionModal = () => setActionModal(true)

  const handleOpenHistoryModal = () => {
    handleCloseActionModal()
    setHistoryModal(true)
  }
  const handleCloseHistoryModal = () => setHistoryModal(false)

  const handleClickBack = () => {
    handleOpenActionModal()
    handleCloseHistoryModal()
  }

  const getMarginBottom = () => {
    return locale === 'zh' ? 160 : 140
  }

  const getCountryName = (id: string) => {
    return regulators.find((r) => r.country.id === id)?.country.name
  }

  const qualifyTaxBenefit = () =>
    getQuestionAnswer(taxFormAnswers, 'certify_no_us_tax_benefits') === '1'

  const isCapacityToSign = () =>
    getQuestionAnswer(taxFormAnswers, 'certify_capacity_to_sign') === '1'

  const handleDownloadForm = async () => {
    const docDefinition = {
      content: [
        {
          marginBottom: 5,
          columns: [
            {
              text: `${t('W8.W-8BEN Form')}`,
              fontSize: 20,
              width: '80%',
            },
            {
              svg: TMDocLogo,
            },
          ],
        },
        {
          svg: `<svg width="530" height="4" viewBox="0 0 547 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="1.74846e-07" y1="2" x2="547" y2="2.00005" stroke="#ECECEC" strokeWidth="4"/>
                </svg>
                `,
        },
        {
          text: `${t('W8.Identification of Beneficial Owner')}`,
          style: 'textStrong',
        },
        {
          columns: [
            {
              text: `${t('W8.Name of Individual')}:`,
              style: 'textGray',
              width: '20%',
            },
            {
              text: getQuestionAnswer(taxFormAnswers, 'name_of_individual'),
              width: '30%',
            },
            {
              text: `${t('W8.Country of Citizenship')}:`,
              style: 'textGray',
            },
            {
              text: getCountryName(getQuestionAnswer(taxFormAnswers, 'residence_country')),
            },
          ],
        },
        {
          columns: [
            {
              text: `${t('W8.Permanent Residence Address')}`,
              width: '50%',
              style: 'textStrong',
            },
            {
              text: `${t('W8.Mailing Address')}`,
              width: '50%',
              style: 'textStrong',
            },
          ],
        },
        {
          columns: [
            {
              text: `${t('W8.Country')}:`,
              style: 'textGray',
              width: '10%',
            },
            {
              text: getCountryName(getQuestionAnswer(taxFormAnswers, 'residence_country')),
            },
            {
              text: `${t('W8.Country')}:`,
              style: 'textGray',
              width: '10%',
            },
            {
              text: getCountryName(getQuestionAnswer(taxFormAnswers, 'mailing_country')),
            },
          ],
        },
        {
          columns: [
            {
              text: `${t('W8.Address Line')}:`,
              style: 'textGray',
              width: '15%',
            },
            {
              text:
                getQuestionAnswer(taxFormAnswers, 'mailing_city') +
                ' ' +
                getQuestionAnswer(taxFormAnswers, 'mailing_street'),
            },
            {
              text: `${t('W8.Address Line')}:`,
              style: 'textGray',
              width: '15%',
            },
            {
              text:
                getQuestionAnswer(taxFormAnswers, 'residence_city') +
                ' ' +
                getQuestionAnswer(taxFormAnswers, 'residence_street'),
            },
          ],
        },

        {
          columns: [
            {
              text: `${t('W8.State/Region')}:`,
              style: 'textGray',
              width: '15%',
            },
            {
              text: getQuestionAnswer(taxFormAnswers, 'residence_county'),
            },
            {
              text: `${t('W8.State/Region')}:`,
              style: 'textGray',
              width: '15%',
            },
            {
              text: getQuestionAnswer(taxFormAnswers, 'mailing_county'),
            },
          ],
        },
        {
          columns: [
            {
              text: `${t('W8.Postal Code')}:`,
              style: 'textGray',
              width: '15%',
            },
            {
              text: getQuestionAnswer(taxFormAnswers, 'residence_postal_code'),
            },
            {
              text: `${t('W8.Postal Code')}:`,
              style: 'textGray',
              width: '15%',
            },
            {
              text: getQuestionAnswer(taxFormAnswers, 'mailing_postal_code'),
            },
          ],
        },
        { text: `${t('W8.Tax Info')}`, style: 'textStrong' },
        {
          columns: [
            {
              text: `${t('W8.Country of Tax Residence')}:`,
              style: 'textGray',
              width: '30%',
            },
            {
              text: getCountryName(getQuestionAnswer(taxFormAnswers, 'tax_country_id')),
            },
          ],
        },
        {
          columns: [
            {
              text: `${t('W8.Country of Tax Residence TIN')}:`,
              style: 'textGray',
              width: '35%',
            },
            {
              text: getQuestionAnswer(taxFormAnswers, 'tax_country_tin'),
            },
          ],
        },
        {
          columns: [
            {
              text: `${t('W8.US TIN')}:`,
              style: 'textGray',
              width: '10%',
            },
            {
              text: getQuestionAnswer(taxFormAnswers, 'us_tin'),
            },
          ],
        },
        {
          columns: [
            {
              text: `${t('W8.US TIN Type')}:`,
              style: 'textGray',
              width: '17%',
            },
            {
              text: getTinType(getQuestionAnswer(taxFormAnswers, 'us_tin_type')),
            },
          ],
        },
        {
          columns: [
            {
              text: `${t('Profile.Date Of Birth')}:`,
              style: 'textGray',
              width: '17%',
            },
            {
              text: formatDate(getQuestionAnswer(taxFormAnswers, 'birthdate'), dateFormat),
            },
          ],
        },
        {
          text: `${t('W8.Claim of Treaty Benefits')}`,
          style: 'textStrong',
        },
        {
          text: `${t('W8.I certify that the beneficial')}`,

          marginBottom: 20,
        },
        {
          columns: [
            {
              text: `${t('W8.Country of Tax Residence')}:`,
              style: 'textGray',
              width: '30%',
            },
            {
              text: getCountryName(getQuestionAnswer(taxFormAnswers, 'certify_country_id')),
            },
          ],
          marginBottom: 20,
        },
        {
          columns: [
            {
              svg: qualifyTaxBenefit() ? CheckBoxOnSVG : CheckBoxOffSVG,
            },
            {
              text: `${t('W8.I do not qualify for US tax treaty benefits')}`,
              style: 'textTiny',
              width: '96%',
            },
          ],
          marginBottom: getMarginBottom(),
        },

        {
          text: `${t('W8.Certification')}`,
          style: 'textStrong',
          marginBottom: 20,
        },
        {
          text: `${t('W8.Under penalties of perjury')}`,
          style: 'textTiny',
          marginBottom: 20,
        },
        {
          marginLeft: 10,
          style: 'textTiny',
          ul: [
            {
              text: `${t('W8.I am the individual')}`,
              marginBottom: 15,
            },
            {
              text: `${t('W8.The beneficial owner is not a US person;')}`,
              marginBottom: 15,
            },
            `${t('W8.This form relates to')}`,
            {
              ul: [
                {
                  text: `${t('W8.income not effectively connected')}`,
                  listType: 'none',
                },
                {
                  text: `${t('W8.income effectively connected')}`,
                  listType: 'none',
                },
                {
                  text: `${t("W8.the partner's share")}`,
                  listType: 'none',
                },
                {
                  text: `${t("W8.the partner's amount realized")}`,
                  listType: 'none',
                },
              ],
            },
            {
              text: `${t('W8.The beneficial owner listed on this form is a')}`,
              marginBottom: 15,
              marginTop: 15,
            },
            {
              text: `${t('W8.For broker transactions')}`,
              marginBottom: 15,
            },
          ],
        },
        {
          text: `${t('W8.Furthermore, I authorize')}`,
          style: 'textTiny',
          marginBottom: 20,
        },
        {
          columns: [
            {
              svg: isCapacityToSign() ? CheckBoxOnSVG : CheckBoxOffSVG,
            },
            {
              text: `${t('W8.I certify that I have the capacity to sign for')}`,
              style: 'textTiny',
              width: '96%',
              marginBottom: 20,
            },
          ],
        },
        {
          columns: [
            {
              text: `${t('W8.Name of Signer')}`,
              style: 'textGray',
              width: '33%',
            },
            {
              text: `${t('W8.Date of Signing')}`,
              style: 'textGray',
              width: '33%',
            },
            {
              text: `${t('W8.Signature')}`,
              style: 'textGray',
              width: '33%',
            },
          ],
        },
        {
          columns: [
            {
              text: getQuestionAnswer(taxFormAnswers, 'name_of_individual'),

              width: '33%',
            },
            {
              text: formatDate(getQuestionAnswer(taxFormAnswers, 'certification_date'), dateFormat),

              width: '33%',
            },
            {
              text: getQuestionAnswer(taxFormAnswers, 'certification_signature'),

              width: '33%',
            },
          ],
        },
      ],

      styles: {
        textStrong: {
          fontSize: 16,
          marginTop: 20,
          marginBottom: 10,
        },
        textGray: {
          color: '#868686',
          fontSize: 12,
          marginBottom: 10,
        },
        textTiny: {
          fontSize: 10,
        },
      },
      defaultStyle: {
        font: 'ArialUnicodeMs',
        fontSize: 12,
      },
    }

    if (!taxFormAnswers) {
      return null
    }

    pdfMake.createPdf(docDefinition as any).download(t('W8.W-8BEN FORM'))
  }

  const getQuestion = (widgetName: string) => {
    const questions = taxFormQuestions?.sections.map((section) => section.questions).flat()

    return questions?.find((question) => question.widget.name === widgetName)
  }
  const getTinType = (id: string) => {
    const usTinTypes = getQuestion('us_tin_type')
    return usTinTypes?.answers.find((a) => a.id === id)?.name
  }

  return (
    <>
      <PageHeader
        title={t('W-8BEN FORM')}
        subtitle={t('W8.To be able to view and trade the CFD Stocks that are available')}
        optionsToggle={taxFormAnswers ? handleOpenActionModal : undefined}
      />
      {submitted && (
        <div className={styles.card}>
          <h1>{t('W8.Thank You!')}</h1>
          <p className={styles.text}>{t('W8.W-8BEN Form successfully submitted')}</p>
          <Button
            appearance='secondary'
            size='S'
            onClick={() => navigate('/dashboard/traders-room/wallets')}
          >
            {t('W8.Go To Dashboard')}
          </Button>
        </div>
      )}
      {!submitted && (
        <>
          {historyModal && (
            <Modal
              closeModal={handleCloseHistoryModal}
              render={() => (
                <InformationModal
                  title={t('W-8BEN Form History')}
                  onCancel={handleCloseHistoryModal}
                  onBack={handleClickBack}
                  buttonText={t('Cancel')}
                  className={'p-0'}
                >
                  <ul className={styles.modalContent}>
                    {testHistory.map((x) => (
                      <li className={styles.hasBorderBottom}>
                        <div className='py-5 px-5'>
                          <div className='is-flex mb-2'>
                            <div className={styles.name}>{t('Date')}</div>
                            <div>{format(new Date(x.dateTaken), 'dd.MM.yyyy HH:mm')}</div>
                          </div>
                          <div className='is-flex'>
                            <div className={styles.name}>{t('Status')}</div>
                            <div
                              className={classNames({
                                'has-text-danger-dark': x.testStatus.id === TestStatusEnum.Declined,
                                'has-text-warning': x.testStatus.id === TestStatusEnum.Pending,
                              })}
                            >
                              {t(x.testStatus.name)}
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </InformationModal>
              )}
            />
          )}
          {actionModal && (
            <Modal
              closeModal={handleCloseActionModal}
              render={() => (
                <InformationModal
                  title={t('W-8BEN Form')}
                  onCancel={handleCloseActionModal}
                  buttonText={t('Cancel')}
                  className={'p-0'}
                >
                  <ul>
                    <>
                      <li className='is-flex is-clickable' onClick={handleDownloadForm}>
                        <div className='p-5 is-flex is-align-items-center'>
                          <DownloadIcon />
                          <span className='ml-3'>{t('Download form')}</span>
                        </div>
                      </li>
                      <li
                        className={classNames('is-flex is-clickable', styles.hasBorderBottom)}
                        onClick={handleOpenHistoryModal}
                      >
                        <div className='p-5 is-flex is-align-items-center'>
                          <HistoryIcon />
                          <span className='ml-3'>{t('History')}</span>
                        </div>
                      </li>
                    </>
                  </ul>
                </InformationModal>
              )}
            />
          )}
          {taxFormQuestions && account && (
            <W8BeneficialForm
              regulators={regulators}
              taxFormQuestions={taxFormQuestions}
              taxFormAnswers={taxFormAnswers}
              account={account}
              handelFormSubmit={handleFormSubmit}
              isFormEnabled={!taxFormAnswers || taxFormAnswers?.allowRetake}
            />
          )}
        </>
      )}
    </>
  )
}
