import React, { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { Loading } from '../../global/Loading/Loading'
import { Badge } from '../../global/badge/Badge'
import { Button } from '../../global/button/Button'
import IconButton from '../../global/iconButton/IconButton'
import { CancelActionModal } from '../../global/modal/CancleActionModal'
import { InfoModal } from '../../global/modal/InfoModal'
import { InformationModal } from '../../global/modal/InformationModal'
import { Modal } from '../../global/modal/Modal'
import { ProductServiceModal } from '../../global/modal/ProductServiceModal'
import {
  Table,
  TableBody,
  TableCell,
  TableDivider,
  TableHead,
  TableHeadRow,
  TableRow,
} from '../../global/table/Table'
import { ChevronDownIcon } from '../../icons/ChevronDownIcon'
import { ChevronUpIcon } from '../../icons/ChevronUpIcon'
import { ClockIcon } from '../../icons/ClockIcon'
import { InfoIcon } from '../../icons/InfoIcon'
import { SuccessIcon } from '../../icons/SuccessIcon'
import { SubscriptionCartItem } from '../../model/SubscriptionCart'
import { SubscriptionRequestState } from '../../model/SubscriptionRequestState'
import {
  isCancellationRequested,
  isSubscriptionCancel,
  isSubscriptionItemActive,
  isSubscriptionItemCompleted,
  isSubscriptionItemNotActive,
  isSubscriptionItemNotCompleted,
} from '../../model/SubscriptionTypes'
import { SubscriptionStatus } from '../../model/SubscriptionsDto'
import {
  InfoModalProps,
  SubscriptionDetailType,
  SubscriptionRequestBadgeAppearance,
  SubscriptionRequestBadgeState,
  SubscriptionRequestBadgeStatusProps,
  SubscriptionRequestBadgeStatusText,
  SubscriptionRequestButtonAppearance,
  SubscriptionRequestButtonStateKeys,
  SubscriptionRequestButtonStatusKeys,
  TradingPlatformsItemProps,
  TradingPlatformsPackagesProps,
} from '../../model/TradingPlatformsDto'
import {
  SubscriptionRequestType,
  SubscriptionTimeInForce,
  UpdateSubscriptionRequest,
} from '../../model/UpdateSubscriptionRequest'
import { NoResults } from '../../ui/Table/NoResults/NoResults'
import { Text, TextSmall, TextSmallStrong, TextTiny } from '../../ui/Typography/Typography'
import { PropertyDetailResponseType, ResponseError, useApiClient } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { ClientApiClient } from '../../utils/clientApi'
import { formatDate } from '../../utils/date.utils'
import { useWindowResize } from '../../utils/domUtils'
import { isZero } from '../../utils/validations'
import { PendingUnsubscriptionModal } from '../MarketData/PendingUnsubscriptionModal'
import { ResubscribeModal } from '../MarketData/ResubscribeModal'
import {
  hasLevelInCart,
  hasPendingUnsubscriptionRequest,
  hasStartingMessage,
} from '../MarketData/subscriptionUtils'
import { SubscriptionCQGConfirmationModal } from '../SubscriptionCQGConfirmationModal'
import { SubscriptionTimeInForceModal } from '../SubscriptionTimeInForceTypeModal'
import { PriceRange } from '../SubscriptionsPriceRange'
import { PLATFORM_TYPE_CQG } from './SubscriptionsTradingPlatformsETDPage'

import styles from './SubscriptionsTradingPlatforms.module.scss'

const SUBSCRIBE_DEFAULT = 'Subscribe'
const PRIMARY_DEFAULT = 'primary'
const ACTIVE_VIA_PACKAGE_DEFAULT = 'Active Via Bundle'

interface TradingPlatformsTableRowProps {
  item: TradingPlatformsItemProps
  isMobile?: boolean
  expandItem: (item: TradingPlatformsItemProps, type: string) => void
  type: string
  handleDataRefresh?: boolean
  setShowProductServiceModal: (item: TradingPlatformsItemProps) => void
  setShowInfoModal: React.Dispatch<React.SetStateAction<InfoModalProps | undefined>>
  isOpen?: boolean
  noneActiveSubscriptions: SubscriptionCartItem[]
}

interface SelectedSubscriptionDetailType extends TradingPlatformsPackagesProps {
  selectedSubscriptionDetail?: SubscriptionDetailType
}
const convertToDecimal = (value?: number) => (value || 0).toFixed(2)

const getExpandedRowStatus = (item: TradingPlatformsItemProps | TradingPlatformsPackagesProps) => {
  switch (true) {
    case isCancellationRequested(item?.subscriptionRequest):
      return SubscriptionRequestButtonStatusKeys.Resubscribe
    case isSubscriptionCancel(item?.subscriptionRequest):
      return SubscriptionRequestButtonStatusKeys.Cancel
    case isSubscriptionItemNotCompleted(item as TradingPlatformsItemProps) ||
      isSubscriptionItemNotActive(item as TradingPlatformsItemProps):
      return SubscriptionRequestButtonStatusKeys.Subscribe
    case !!item?.activeSubscriptionRequest:
      return SubscriptionRequestButtonStatusKeys.Unsubscribe
    default:
      return null
  }
}

const getNonExpandedRowStatus = (
  item: TradingPlatformsItemProps | TradingPlatformsPackagesProps,
  isOpen?: boolean
) => {
  const statusId = item?.subscriptionRequest?.state?.id

  switch (true) {
    case isOpen &&
      (statusId === SubscriptionStatus.IN_PROCESS ||
        statusId === SubscriptionStatus.DEDUCTING_FUNDS ||
        statusId === SubscriptionStatus.IN_PROCESS_CANCEL):
      return null

    default:
      return item?.subscriptionRequest?.state?.name
  }
}

interface StatusButtonProps {
  item: TradingPlatformsItemProps | TradingPlatformsPackagesProps
  isOpen?: boolean
  isNonExpandedRow?: boolean
  setShowInfoModal: ({ title, body }: { title: string; body: string }) => void
  setIsSubmitting?: (val: boolean) => void
  handleSubscriptionAction?: (
    item: TradingPlatformsItemProps | TradingPlatformsPackagesProps
  ) => void
  handleDataRefresh?: () => void
  buttonClassName?: string
  containerClassName?: string
  expandedButtonSize?: 'XS' | 'S' | 'M' | 'L'
  onUnsubscribeConfirmationModal?: (
    item: TradingPlatformsItemProps | TradingPlatformsPackagesProps
  ) => void

  isSubscriptionInCart?: boolean
}

export const StatusButton: React.FC<StatusButtonProps> = ({
  item,
  setShowInfoModal,
  buttonClassName,
  isOpen,
  isNonExpandedRow,
  setIsSubmitting,
  handleSubscriptionAction,
  containerClassName = 'is-justify-content-center',
  expandedButtonSize = 'XS',
  handleDataRefresh,
  onUnsubscribeConfirmationModal,
  isSubscriptionInCart,
}) => {
  const { t } = useTranslation()

  const clientApiClient = useApiClient(ClientApiClient)

  const [isCancelModalOpen, setCancelModalOpen] = useState(false)
  const [resubscribeModal, setResubscribeModal] = useState(false)

  const handleStatusClick = async (status?: string | null) => {
    switch (status) {
      case SubscriptionRequestButtonStatusKeys.Cancel:
        setCancelModalOpen(true)
        break
      case SubscriptionRequestButtonStatusKeys.Resubscribe:
        setResubscribeModal(true)
        break
      case SubscriptionRequestButtonStatusKeys.Unsubscribe:
        onUnsubscribeConfirmationModal?.(item)
        break
      default:
        handleSubscriptionAction?.(item)
    }
  }

  const handleOnClickResubscribeConfirm = async () => {
    setIsSubmitting?.(true)
    try {
      await clientApiClient.updateSubscriptionRequest({
        subscriptions: [
          {
            subscriptionDetailId: item?.subscriptionRequest?.subscriptionDetailId,
            subscriptionRequestTypeId: SubscriptionRequestType.Resubscribe,
          },
        ],
        subscriptionTimeInForceId: SubscriptionTimeInForce.Immediate,
      })

      handleDataRefresh?.()
    } finally {
      setIsSubmitting?.(false)
    }
  }

  const handleCancelAction = async (
    item: TradingPlatformsItemProps | TradingPlatformsPackagesProps
  ) => {
    setIsSubmitting?.(true)

    try {
      const getSubscriptionsRequestData =
        'subscriptions' in item
          ? item?.subscriptions?.map(({ subscriptionRequest }) => {
              return {
                subscriptionDetailId: subscriptionRequest?.subscriptionDetailId,
                subscriptionRequestTypeId: SubscriptionRequestType.Cancel,
              }
            })
          : []

      const data: UpdateSubscriptionRequest =
        'subscriptions' in item
          ? {
              subscriptions: getSubscriptionsRequestData,
              subscriptionTimeInForceId: SubscriptionTimeInForce.Immediate,
              overrideOpenPositionsForCancel: true,
            }
          : {
              subscriptions: [
                {
                  subscriptionDetailId: item?.subscriptionRequest?.subscriptionDetailId,
                  subscriptionRequestTypeId: SubscriptionRequestType.Cancel,
                },
              ],
              subscriptionTimeInForceId: SubscriptionTimeInForce.Immediate,
              overrideOpenPositionsForCancel: true,
            }

      await clientApiClient.updateSubscriptionRequest(data)
      handleDataRefresh?.()
    } finally {
      setCancelModalOpen(false)
      setIsSubmitting?.(false)
    }
  }

  const statusName:
    | SubscriptionRequestBadgeState
    | SubscriptionRequestButtonStateKeys
    | undefined
    | null = item?.isActiveViaPackage
    ? ACTIVE_VIA_PACKAGE_DEFAULT
    : getNonExpandedRowStatus(item, isOpen)

  const expandedRowStatus = !isNonExpandedRow ? getExpandedRowStatus(item) : null

  const buttonStatusText = statusName
    ? SubscriptionRequestButtonStatusKeys?.[expandedRowStatus || statusName]
    : SUBSCRIBE_DEFAULT

  const buttonAppearance = statusName
    ? SubscriptionRequestButtonAppearance?.[expandedRowStatus || statusName]
    : PRIMARY_DEFAULT

  const badgeStatusText = statusName && SubscriptionRequestBadgeStatusText?.[statusName]

  const badgeAppearance = statusName && SubscriptionRequestBadgeAppearance?.[statusName]

  return (
    <>
      {isCancelModalOpen && (
        <Modal
          size='small'
          closeModal={() => setCancelModalOpen(false)}
          render={() => (
            <CancelActionModal
              title={t('Subscriptions.Cancel Order?')}
              cancelLabel={t('Subscriptions.Keep Order')}
              confirmLabel={t('Subscriptions.Yes, cancel')}
              onConfirm={() => handleCancelAction(item)}
              onCancel={() => setCancelModalOpen(false)}
            >
              <p className='text'>{t('Subscriptions.Do you really like to cancel the order?')}</p>
            </CancelActionModal>
          )}
        />
      )}
      {resubscribeModal && (
        <ResubscribeModal
          handleOnClickResubscribeConfirm={handleOnClickResubscribeConfirm}
          setResubscribeModal={setResubscribeModal}
          amount={item?.price}
        />
      )}

      {buttonStatusText ? (
        isOpen ? (
          isNonExpandedRow &&
          buttonStatusText !== SubscriptionRequestButtonStatusKeys.Subscribe && (
            <Button
              appearance={buttonAppearance}
              size='XS'
              onClick={() => handleStatusClick(statusName)}
              className={buttonClassName}
            >
              {t(`Subscriptions.${buttonStatusText}`)}
            </Button>
          )
        ) : (
          <Button
            appearance={buttonAppearance}
            size={expandedButtonSize}
            onClick={() => handleStatusClick(expandedRowStatus || statusName)}
            className={buttonClassName}
            disabled={isSubscriptionInCart}
          >
            {t(`Subscriptions.${buttonStatusText}`)}
          </Button>
        )
      ) : (
        <div className={classNames('is-flex', containerClassName)}>
          {badgeStatusText && badgeAppearance && (
            <Badge text={t(`Subscriptions.${badgeStatusText}`)} appearance={badgeAppearance} />
          )}
          {item?.isActiveViaPackage && (
            <IconButton
              className='is-flex ml-2 is-align-items-center'
              onClick={() =>
                setShowInfoModal({
                  title: t('Subscriptions.Included in Active Package'),
                  body: t('Subscriptions.The product is already included'),
                })
              }
            >
              <InfoIcon />
            </IconButton>
          )}
        </div>
      )}
    </>
  )
}

const TradingPlatformsTableRow: React.FC<TradingPlatformsTableRowProps> = ({
  item,
  isOpen,
  expandItem,
  setShowProductServiceModal,
  setShowInfoModal,
  type,
  noneActiveSubscriptions,
}) => {
  const isSubscriptionInCart = useMemo(
    () => hasLevelInCart(item, noneActiveSubscriptions),
    [item, noneActiveSubscriptions]
  )

  return (
    <>
      <TableCell colSpan={3} align='left'>
        <div className={styles.titleWrapper}>
          <TextSmallStrong>
            <Link className='is-link' to='#' onClick={() => setShowProductServiceModal(item)}>
              {item?.name}
            </Link>
          </TextSmallStrong>
          <p className='text-secondary has-text-weight-normal'>{item?.subName}</p>
        </div>
      </TableCell>
      <TableCell align='left' colSpan={2}>
        {!isOpen && (
          <TextSmall>
            <PriceRange minAmount={item?.minAmount} maxAmount={item?.maxAmount} />
          </TextSmall>
        )}
      </TableCell>
      <TableCell align='center' colSpan={2}>
        <StatusButton
          isNonExpandedRow
          item={item}
          isOpen={isOpen}
          setShowInfoModal={setShowInfoModal}
          handleSubscriptionAction={() => expandItem(item, type)}
          isSubscriptionInCart={isSubscriptionInCart}
        />
      </TableCell>

      <TableCell align='right' cellClassName={styles.transparent}>
        <IconButton onClick={() => expandItem(item, type)}>
          {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </IconButton>
      </TableCell>
    </>
  )
}

interface TradingPlatformsTableExpandedRowProps {
  item: TradingPlatformsPackagesProps
  type: string
  setShowInfoModal: React.Dispatch<React.SetStateAction<InfoModalProps | undefined>>
  setIsCancelSubmitting: React.Dispatch<React.SetStateAction<boolean>>
  handleSubscriptionAction: (itemPackage: TradingPlatformsPackagesProps) => void
  handleDataRefresh?: () => void
  onUnsubscribeConfirmationModal: (
    item: TradingPlatformsItemProps | TradingPlatformsPackagesProps
  ) => void
  onSubscriptionInfoModalOpen(): void
  nonActiveSubscriptions: SubscriptionCartItem[]
}

const TradingPlatformsTableExpandedRow: React.FC<TradingPlatformsTableExpandedRowProps> = ({
  item,
  type,
  setShowInfoModal,
  handleSubscriptionAction,
  setIsCancelSubmitting,
  handleDataRefresh,
  onUnsubscribeConfirmationModal,
  onSubscriptionInfoModalOpen,
  nonActiveSubscriptions,
}) => {
  const [pendingUnsubscriptionInfoModal, setPendingUnsubscriptionInfoModal] = useState(false)

  const { t } = useTranslation()
  const status = (
    item?.isActiveViaPackage ? ACTIVE_VIA_PACKAGE_DEFAULT : item?.subscriptionRequest?.state?.name
  ) as SubscriptionRequestBadgeStatusProps
  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name

  const isActive =
    isSubscriptionItemCompleted(item as TradingPlatformsItemProps) ||
    isSubscriptionItemActive(item as TradingPlatformsItemProps)

  const badgeStatusText = status && SubscriptionRequestBadgeStatusText?.[status]

  const dueDate = item?.subscriptionRequest?.dueDate
  const [details] = item.details || []

  const isSubscriptionInCart = useMemo(
    () => hasLevelInCart(item, nonActiveSubscriptions),
    [item, nonActiveSubscriptions]
  )

  return (
    <>
      {pendingUnsubscriptionInfoModal && (
        <PendingUnsubscriptionModal
          handleOnClosePendingUnsubscriptionModal={() => setPendingUnsubscriptionInfoModal(false)}
          dueDate={item?.subscriptionRequest?.dueDate}
        />
      )}
      <TableCell colSpan={3} align='left'>
        <div className='columns'>
          <div className='column is-2 pr-2'>
            <div className='is-flex is-justify-content-flex-end'>
              {isActive && (
                <IconButton>
                  <SuccessIcon />
                </IconButton>
              )}

              {badgeStatusText === SubscriptionRequestBadgeStatusText.Pending && (
                <IconButton>
                  <ClockIcon color='warning' />
                </IconButton>
              )}
            </div>
          </div>

          <div className='column pl-0'>
            <div className='is-flex is-align-items-end pr-2'>
              <div className='is-flex is-flex-direction-column'>
                <TextSmallStrong>
                  <span className='is-flex is-align-items-center'>
                    {details?.name}
                    {item?.name === 'CQG INTEGRATED CLIENT' && (
                      <span className='ml-1 is-flex is-align-items-center'>
                        <IconButton onClick={onSubscriptionInfoModalOpen}>
                          <InfoIcon />
                        </IconButton>
                      </span>
                    )}
                  </span>
                </TextSmallStrong>
                <p className='text-secondary has-text-weight-normal pb-2'>{details?.description}</p>
              </div>
            </div>
          </div>
        </div>
      </TableCell>
      <TableCell align='left' colSpan={2}>
        <div className='is-flex is-flex-direction-column'>
          <TextSmall>
            {isZero(item?.price) && type === PLATFORM_TYPE_CQG ? (
              <span> {t('Subscriptions.Price on Application')}</span>
            ) : (
              `$${convertToDecimal(item?.price)}/${t('Subscriptions.mth')}`
            )}
          </TextSmall>

          {hasPendingUnsubscriptionRequest(item) ? (
            <>
              <p className='has-text-success'>
                {dueDate &&
                  t('Subscriptions.Active until', {
                    date: formatDate(dueDate, dateFormat),
                  })}
              </p>
              <div className='is-flex'>
                <p className='has-text-warning'>{t('Subscriptions.Pending Unsubscription')}</p>
                <p
                  className='pl-1'
                  onClick={() => {
                    setPendingUnsubscriptionInfoModal(true)
                  }}
                >
                  <InfoIcon />
                </p>{' '}
              </div>
            </>
          ) : (
            <>
              {hasStartingMessage(item) && (
                <p className='has-text-warning'>
                  {dueDate &&
                    t('Subscriptions.Starting', {
                      firstDateOfNextMonth: formatDate(dueDate, dateFormat),
                    })}
                </p>
              )}
            </>
          )}
        </div>
      </TableCell>
      <TableCell align='center' colSpan={2}>
        <StatusButton
          item={item}
          handleSubscriptionAction={handleSubscriptionAction}
          handleDataRefresh={handleDataRefresh}
          setIsSubmitting={setIsCancelSubmitting}
          setShowInfoModal={setShowInfoModal}
          onUnsubscribeConfirmationModal={onUnsubscribeConfirmationModal}
          isSubscriptionInCart={isSubscriptionInCart}
        />
      </TableCell>

      <TableCell align='right' cellClassName={styles.transparent} />
    </>
  )
}

const TradingPlatformsTableRowMobile: React.FC<TradingPlatformsTableRowProps> = ({
  item,
  expandItem,
  setShowProductServiceModal,
  setShowInfoModal,
  type,
  noneActiveSubscriptions,
}) => {
  const isSubscriptionInCart = useMemo(
    () => hasLevelInCart(item, noneActiveSubscriptions),
    [item, noneActiveSubscriptions]
  )
  return (
    <div className='is-flex is-flex-direction-column container p-0'>
      <div className='is-flex is-align-items-center is-justify-content-space-between'>
        <TextSmallStrong>
          <Link className='is-link' to='#' onClick={() => setShowProductServiceModal(item)}>
            {item?.name}
          </Link>
        </TextSmallStrong>
        <div className='is-flex is-align-items-center'>
          <StatusButton
            isNonExpandedRow
            item={item}
            isOpen={item?.isOpen}
            setShowInfoModal={setShowInfoModal}
            handleSubscriptionAction={() => expandItem(item, type)}
            isSubscriptionInCart={isSubscriptionInCart}
          />
          <div className='pl-2 pr-0 pt-1'>
            <Button appearance='plain' onClick={() => expandItem(item, type)}>
              {item?.isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </Button>
          </div>
        </div>
      </div>

      {item?.subName && (
        <p className='has-text-grey pb-2'>
          <TextSmall>{item?.subName}</TextSmall>
        </p>
      )}

      {!item?.isOpen && (
        <TextSmall className={styles.text}>
          <PriceRange minAmount={item?.minAmount} maxAmount={item?.maxAmount} />
        </TextSmall>
      )}
    </div>
  )
}

interface TradingPlatformsExpandedRowMobileProps {
  item: TradingPlatformsPackagesProps
  type: string
  setShowInfoModal: React.Dispatch<React.SetStateAction<InfoModalProps | undefined>>
  handleSubscriptionAction: (itemPackage: TradingPlatformsPackagesProps) => void
  setIsCancelSubmitting: React.Dispatch<React.SetStateAction<boolean>>
  handleDataRefresh?: () => void
  scrollToCart?: () => void
  onUnsubscribeConfirmationModal: (
    item: TradingPlatformsPackagesProps | TradingPlatformsItemProps
  ) => void

  noneActiveSubscriptions: SubscriptionCartItem[]
}
const TradingPlatformsExpandedRowMobile: React.FC<TradingPlatformsExpandedRowMobileProps> = ({
  item,
  type,
  setShowInfoModal,
  setIsCancelSubmitting,
  handleDataRefresh,
  handleSubscriptionAction,
  scrollToCart,
  onUnsubscribeConfirmationModal,
  noneActiveSubscriptions,
}) => {
  const { t } = useTranslation()
  const [pendingUnsubscriptionInfoModal, setPendingUnsubscriptionInfoModal] = useState(false)

  const status = (
    item?.isActiveViaPackage ? ACTIVE_VIA_PACKAGE_DEFAULT : item?.subscriptionRequest?.state?.name
  ) as SubscriptionRequestBadgeStatusProps
  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name
  const isActive =
    isSubscriptionItemCompleted(item as TradingPlatformsItemProps) ||
    isSubscriptionItemActive(item as TradingPlatformsItemProps)

  const badgeStatusText = status && SubscriptionRequestBadgeStatusText?.[status]

  const dueDate = item?.subscriptionRequest?.dueDate

  const isSubscriptionInCart = useMemo(
    () => hasLevelInCart(item, noneActiveSubscriptions),
    [item, noneActiveSubscriptions]
  )

  return (
    <>
      {pendingUnsubscriptionInfoModal && (
        <PendingUnsubscriptionModal
          handleOnClosePendingUnsubscriptionModal={() => setPendingUnsubscriptionInfoModal(false)}
          dueDate={item?.subscriptionRequest?.dueDate}
        />
      )}

      <div className='my-4'>
        <TableDivider />
      </div>

      <div className='card-content py-0 pl-0 is-clickable'>
        <div className='is-flex is-align-items-start'>
          {isActive && (
            <IconButton className='mr-2'>
              <SuccessIcon />
            </IconButton>
          )}

          {badgeStatusText === 'Pending' && (
            <IconButton className='mr-2'>
              <ClockIcon color='warning' />
            </IconButton>
          )}
          {badgeStatusText !== 'Pending' && badgeStatusText !== 'Active' && (
            <IconButton className='mr-2'>
              <ClockIcon color='backgroundSecondary' />
            </IconButton>
          )}
          <div className='is-flex-grow-1 is-flex-shrink-1'>
            <TextSmallStrong className={styles.text}>{item?.name}</TextSmallStrong>

            {item?.description && (
              <p className='text-secondary has-text-weight-normal py-3'>
                <TextSmall>{item?.description}</TextSmall>
              </p>
            )}

            <Text className={styles.text}>
              {isZero(item?.price) && type === PLATFORM_TYPE_CQG ? (
                <span> {t('Subscriptions.Price on Application')}</span>
              ) : (
                `$${convertToDecimal(item?.price)}/${t('Subscriptions.mth')}`
              )}
            </Text>

            {hasPendingUnsubscriptionRequest(item) ? (
              <>
                <p className='has-text-success pt-3'>
                  <TextSmall>
                    {dueDate &&
                      t('Subscriptions.Active until', {
                        date: formatDate(dueDate, dateFormat),
                      })}
                  </TextSmall>
                </p>
                <p className='has-text-warning pt-3'>
                  <TextSmall>{t('Subscriptions.Pending Unsubscription')}</TextSmall>
                  <span
                    className='pl-1'
                    onClick={() => {
                      setPendingUnsubscriptionInfoModal(true)
                    }}
                  >
                    <InfoIcon />
                  </span>
                </p>
              </>
            ) : (
              <>
                {hasStartingMessage(item) && (
                  <p className='has-text-warning pt-3'>
                    <TextSmall>
                      {dueDate &&
                        t('Subscriptions.Starting', {
                          firstDateOfNextMonth: formatDate(dueDate, dateFormat),
                        })}
                    </TextSmall>
                  </p>
                )}
              </>
            )}

            {getExpandedRowStatus(item) === SubscriptionRequestButtonStatusKeys.Cancel ? (
              <div className='is-flex is-1'>
                <span className='column  is-5 pl-0'>
                  <StatusButton
                    expandedButtonSize='S'
                    buttonClassName='px-5 py-4 mb-1 is-fullwidth'
                    setIsSubmitting={setIsCancelSubmitting}
                    handleDataRefresh={handleDataRefresh}
                    setShowInfoModal={setShowInfoModal}
                    handleSubscriptionAction={handleSubscriptionAction}
                    item={item}
                  />
                </span>
                <span className='column px-0 is-5'>
                  <Button
                    appearance='primary'
                    size='S'
                    onClick={() => scrollToCart?.()}
                    className='px-5 py-4 mb-1 is-fullwidth'
                  >
                    {t('Subscriptions.Go to cart')}
                  </Button>
                </span>
              </div>
            ) : (
              <div className='mt-4 is-flex'>
                <StatusButton
                  containerClassName='is-align-items-start mb-1'
                  expandedButtonSize='S'
                  buttonClassName={classNames('px-6 py-4 mb-1 is-fullwidth', styles.buttonWidth)}
                  onUnsubscribeConfirmationModal={onUnsubscribeConfirmationModal}
                  handleDataRefresh={handleDataRefresh}
                  setShowInfoModal={setShowInfoModal}
                  handleSubscriptionAction={handleSubscriptionAction}
                  item={item}
                  isSubscriptionInCart={isSubscriptionInCart}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

interface SubscriptionsTradingPlatformsTableProps {
  subscriptions?: TradingPlatformsPackagesProps[]
  productsTradingPlatformsData?: TradingPlatformsItemProps[]
  expandItem: (item: TradingPlatformsItemProps, type: string) => void
  type: string
  forceRefresh?: () => void
  forceRefreshCartItems?: () => void
  isLoading?: boolean
  scrollToCart?: () => void
  onUnsubscribeConfirmationModal: (
    item: TradingPlatformsItemProps | TradingPlatformsPackagesProps
  ) => void
  onSubscriptionInfoModalOpen(): void
  noneActiveSubscriptions: SubscriptionCartItem[]
}
export const SubscriptionsTradingPlatformsTable: React.FC<
  SubscriptionsTradingPlatformsTableProps
> = ({
  subscriptions,
  productsTradingPlatformsData,
  expandItem,
  type,
  isLoading = false,
  forceRefresh,
  forceRefreshCartItems,
  scrollToCart,
  onUnsubscribeConfirmationModal,
  onSubscriptionInfoModalOpen,
  noneActiveSubscriptions,
}) => {
  const [showProductServiceModal, setShowProductServiceModal] =
    useState<TradingPlatformsItemProps>()
  const [showInfoModal, setShowInfoModal] = useState<InfoModalProps>()
  const clientApiClient = useApiClient(ClientApiClient)
  const isMobile = useWindowResize()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [selectedSubscription, setSelectedSubscription] = useState<
    SelectedSubscriptionDetailType | undefined
  >(undefined)
  const [selectedSubscriptionConfirm, setSelectedSubscriptionConfirm] = useState<
    TradingPlatformsPackagesProps | undefined
  >(undefined)
  const [subscriptionTimeInForceType, setSubscriptionTimeInForceType] = useState<
    number | undefined
  >()

  const [isMutuallyExclusiveModal, setIsMutuallyExclusiveModal] = useState<
    Record<string, PropertyDetailResponseType> | undefined
  >(undefined)
  const [isAlreadySubscribedModal, setIsAlreadySubscribedModal] = useState<
    Record<string, PropertyDetailResponseType> | undefined
  >(undefined)

  const { t } = useTranslation()

  const getSubscriptionRequestType = () =>
    selectedSubscription?.activeSubscriptionRequest ||
    selectedSubscription?.subscriptionRequest?.state.name === SubscriptionRequestState.Pending
      ? SubscriptionRequestType.Update
      : SubscriptionRequestType.Add

  const handleDataRefresh = () => {
    forceRefresh?.()
    forceRefreshCartItems?.()
  }

  const handleSelectSubscriptionTimeInForceType = async (id: number) => {
    setSubscriptionTimeInForceType(id)
    try {
      await addSubscriptionItemToCart(id, selectedSubscription)
      await updateSubscriptionRequest(id, selectedSubscription)
    } catch (error: unknown) {
      if (
        error instanceof ResponseError &&
        error.response.response?.data.code === 'subscription_detail_is_excluded'
      ) {
        return setIsMutuallyExclusiveModal(error.response.response.data?.properties)
      }

      if (
        error instanceof ResponseError &&
        error.response.response?.data.code === 'subscription_detail_is_excluded_by_bundle'
      ) {
        return setIsAlreadySubscribedModal(error.response.response.data?.properties)
      }
    } finally {
      setSelectedSubscription(undefined)
      setSubscriptionTimeInForceType(undefined)
    }
  }

  const addSubscriptionItemToCart = async (
    id: number,
    selectedSubscription: SelectedSubscriptionDetailType | undefined
  ) => {
    if (
      id === SubscriptionTimeInForce.Immediate &&
      selectedSubscription?.selectedSubscriptionDetail?.id
    ) {
      await clientApiClient.addSubscriptionItemToCart({
        subscriptionDetailId: selectedSubscription?.selectedSubscriptionDetail?.id,
        subscriptionTimeInForceId: id,
        subscriptionRequestTypeId: SubscriptionRequestType.Add,
      })
      handleDataRefresh()
    }
  }

  const updateSubscriptionRequest = async (
    id: number,
    selectedSubscription: SelectedSubscriptionDetailType | undefined
  ) => {
    if (
      id === SubscriptionTimeInForce.BeginningOfNextMonth &&
      selectedSubscription?.selectedSubscriptionDetail?.id
    ) {
      await clientApiClient.updateSubscriptionRequest({
        subscriptions: [
          {
            subscriptionDetailId: selectedSubscription?.selectedSubscriptionDetail?.id,
            subscriptionRequestTypeId: getSubscriptionRequestType(),
          },
        ],
        subscriptionTimeInForceId: id,
      })
      handleDataRefresh()
    }
  }

  const getActiveOrPendingSubscriptionLevel = (itemPackage: TradingPlatformsPackagesProps) => {
    return itemPackage?.details?.find(
      (detail: SubscriptionDetailType) =>
        detail.activeSubscriptionRequest ||
        detail?.subscriptionRequest?.state.name === SubscriptionRequestState.Pending
    )
  }

  const handleSetSelectedSubscription = (
    itemPackage: TradingPlatformsPackagesProps | undefined
  ) => {
    if (itemPackage) {
      setSelectedSubscription(getSelectedSubscriptionDetail(itemPackage))
    }
  }

  const handleSubscriptionConfirmationAction = (
    itemPackage: TradingPlatformsPackagesProps,
    type: string
  ) => {
    if (type === PLATFORM_TYPE_CQG && isZero(itemPackage?.price)) {
      setSelectedSubscriptionConfirm(itemPackage)
    } else {
      handleSetSelectedSubscription(itemPackage)
    }
  }

  const getSelectedSubscriptionDetail = (itemPackage: TradingPlatformsPackagesProps) => {
    const selectedSubscriptionDetail =
      getActiveOrPendingSubscriptionLevel(itemPackage) || itemPackage?.details?.[0]
    return {
      ...itemPackage,
      selectedSubscriptionDetail,
    }
  }

  return (
    <Loading showLoadingIcon isModal isLoading={isLoading || isSubmitting}>
      <ProductServiceModal
        showModal={showProductServiceModal}
        setShowModal={setShowProductServiceModal}
      />

      {isAlreadySubscribedModal && (
        <Modal
          closeModal={() => setIsAlreadySubscribedModal(undefined)}
          render={({ closeModal }) => (
            <InformationModal
              title={t('Subscriptions.Already Subscribed')}
              onCancel={closeModal}
              buttonText={t('Got it!')}
            >
              <p className='text'>
                {t('Subscriptions.Bundle is is already subscribed', {
                  parent: isAlreadySubscribedModal?.BundleParentSubscriptionDetail?.Name,
                  child: isAlreadySubscribedModal?.BundleChildSubscriptionDetail?.Name,
                })}
              </p>
            </InformationModal>
          )}
        />
      )}

      {isMutuallyExclusiveModal && (
        <Modal
          closeModal={() => setIsMutuallyExclusiveModal(undefined)}
          render={({ closeModal }) => (
            <InformationModal
              title={t('Subscriptions.Already Subscribed')}
              onCancel={closeModal}
              buttonText={t('Got it!')}
            >
              <p className='text'>
                {t('Subscriptions.Bundle is mutually exclusive', {
                  parent: isMutuallyExclusiveModal?.ExcludedBySubscriptionDetail?.Name,
                  child: isMutuallyExclusiveModal?.SubscriptionDetail?.Name,
                })}
              </p>
            </InformationModal>
          )}
        />
      )}

      {showInfoModal?.title && (
        <Modal
          size='small'
          closeModal={() => setShowInfoModal({})}
          render={() => (
            <InfoModal
              onCancel={() => setShowInfoModal({})}
              title={showInfoModal?.title || ''}
              renderBody={() => (
                <section className='modal-card-body'>
                  <Text>{showInfoModal?.body}</Text>
                </section>
              )}
              renderFooter={() => (
                <button className='button' onClick={() => setShowInfoModal({})} type='button'>
                  <b>{t('Got It')}</b>
                </button>
              )}
              onConfirm={() => setShowInfoModal({})}
            />
          )}
        />
      )}
      {selectedSubscription?.id && (
        <SubscriptionTimeInForceModal
          subscriptionTimeInForceType={subscriptionTimeInForceType}
          handleOnSelectSubscriptionTimeInForceType={handleSelectSubscriptionTimeInForceType}
          cancelTimeInForceSelection={() => setSelectedSubscription(undefined)}
        />
      )}
      {selectedSubscriptionConfirm?.id && (
        <SubscriptionCQGConfirmationModal
          onConfirm={async () => {
            await addSubscriptionItemToCart(
              SubscriptionTimeInForce.Immediate,
              getSelectedSubscriptionDetail(selectedSubscriptionConfirm)
            )
            setSelectedSubscriptionConfirm(undefined)
          }}
          onClose={() => {
            setSelectedSubscriptionConfirm(undefined)
          }}
        />
      )}

      {!isMobile && (
        <Table
          spacing='none'
          className={classNames(styles.subscriptionsTable, 'table is-fullwidth')}
        >
          <TableHead>
            <TableHeadRow>
              <th align='left' className='pl-5' colSpan={3}>
                <TextTiny className={styles.headerTextStyles}>
                  {t('Subscriptions.Product')}
                </TextTiny>
              </th>
              <th align='left' className=' mr-6' colSpan={2}>
                <TextTiny className={styles.headerTextStyles}>{t('Subscriptions.Price')}</TextTiny>
              </th>
              <th align='center' className='pl-0 pr-0' colSpan={2}>
                <TextTiny className={styles.headerTextStyles}>{t('Subscriptions.Status')}</TextTiny>
              </th>
              <th align='center' />
            </TableHeadRow>
          </TableHead>

          {productsTradingPlatformsData?.length ? (
            productsTradingPlatformsData.map((item) => {
              const getProductPackages = subscriptions?.filter(
                (packageItem) => packageItem?.productType === item?.type
              )

              return (
                <TableBody key={item.id}>
                  <TableRow className={styles.isNotExpandedRow}>
                    <TradingPlatformsTableRow
                      item={item}
                      isOpen={Boolean(item?.isOpen && getProductPackages?.length)}
                      setShowInfoModal={setShowInfoModal}
                      type={type}
                      expandItem={expandItem}
                      setShowProductServiceModal={setShowProductServiceModal}
                      noneActiveSubscriptions={noneActiveSubscriptions}
                    />
                  </TableRow>
                  {item?.isOpen && getProductPackages?.length ? (
                    <>
                      {getProductPackages.map((itemPackage: TradingPlatformsPackagesProps) => (
                        <React.Fragment key={itemPackage.id}>
                          <TableCell colSpan={8} cellClassName='divider'>
                            <div className='mx-5'>
                              <TableDivider />
                            </div>
                          </TableCell>
                          <TableRow className={classNames(styles.tableExpandedRow)}>
                            <TradingPlatformsTableExpandedRow
                              handleDataRefresh={handleDataRefresh}
                              handleSubscriptionAction={(
                                itemPackage: TradingPlatformsPackagesProps
                              ) => {
                                handleSubscriptionConfirmationAction(itemPackage, type)
                              }}
                              onSubscriptionInfoModalOpen={onSubscriptionInfoModalOpen}
                              setShowInfoModal={setShowInfoModal}
                              item={itemPackage}
                              type={type}
                              setIsCancelSubmitting={setIsSubmitting}
                              onUnsubscribeConfirmationModal={onUnsubscribeConfirmationModal}
                              nonActiveSubscriptions={noneActiveSubscriptions}
                            />
                          </TableRow>
                        </React.Fragment>
                      ))}
                    </>
                  ) : null}
                </TableBody>
              )
            })
          ) : (
            <td align='center' colSpan={8}>
              <NoResults hideLink subtitle={t('Subscriptions.No products list')} />
            </td>
          )}
        </Table>
      )}
      {isMobile &&
        (productsTradingPlatformsData?.length ? (
          productsTradingPlatformsData.map((item) => {
            const getProductPackages = subscriptions?.filter(
              (packageItem) => packageItem?.productType === item?.type
            )
            return (
              <div
                key={item.id}
                className={classNames('card mt-3 mx-2 mb-0 is-shadowless', styles.box)}
              >
                <header className='card-header p-0 is-shadowless'>
                  <TradingPlatformsTableRowMobile
                    item={item}
                    isMobile={isMobile}
                    isOpen={Boolean(item?.isOpen && getProductPackages?.length)}
                    setShowInfoModal={setShowInfoModal}
                    type={type}
                    expandItem={expandItem}
                    setShowProductServiceModal={setShowProductServiceModal}
                    noneActiveSubscriptions={noneActiveSubscriptions}
                  />
                </header>

                {item?.isOpen && getProductPackages?.length
                  ? getProductPackages.map((itemPackage: TradingPlatformsPackagesProps) => (
                      <React.Fragment key={itemPackage.id}>
                        <TradingPlatformsExpandedRowMobile
                          scrollToCart={scrollToCart}
                          handleDataRefresh={handleDataRefresh}
                          handleSubscriptionAction={(
                            itemPackage: TradingPlatformsPackagesProps
                          ) => {
                            handleSubscriptionConfirmationAction(itemPackage, type)
                          }}
                          setShowInfoModal={setShowInfoModal}
                          item={itemPackage}
                          type={type}
                          setIsCancelSubmitting={setIsSubmitting}
                          onUnsubscribeConfirmationModal={onUnsubscribeConfirmationModal}
                          noneActiveSubscriptions={noneActiveSubscriptions}
                        />
                      </React.Fragment>
                    ))
                  : null}
              </div>
            )
          })
        ) : (
          <NoResults hideLink subtitle={t('Subscriptions.No products list')} />
        ))}
    </Loading>
  )
}
