import { ReactNode, useState } from 'react'

import { ProductServiceModal } from '../../global/modal/ProductServiceModal'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { useScrollToTop } from '../../utils/useScrollToTop'

import styles from './Vendors.module.scss'

export type VendorType = {
  name?: string
  imageUrl?: string
  description?: ReactNode
  url?: string
}

interface VendorsProps {
  vendors: VendorType[]
  title: string
}

export const Vendors = ({ vendors, title }: VendorsProps): JSX.Element => {
  useScrollToTop()
  const [showModal, setShowModal] = useState<VendorType>()

  return (
    <>
      <ProductServiceModal showModal={showModal} setShowModal={setShowModal} />
      <PageHeader title={title} />
      <div className={styles.vendors}>
        {vendors?.map((platfomVendor) => (
          <div onClick={() => setShowModal(platfomVendor)} className={styles.card}>
            <div className={styles.cardImage}>
              <img src={platfomVendor.imageUrl} alt={platfomVendor.name} />
            </div>
            <span className={styles.title}>{platfomVendor.name}</span>
          </div>
        ))}
      </div>
    </>
  )
}
