import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button } from '../../global/button/Button'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { useAccountReadContext, useAccountWriteContext } from '../../utils/AccountContextContext'
import { isTradersRoomRouteAllowed } from '../../utils/permission.utils'
import { SignupFormWrapper, SignupStep, SignupWrapper, hasAdditionalId } from '../SignupPage'

import styles from './ConfirmPage.module.scss'

export const ConfirmPage: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const locale = useSessionLanguage()

  const { refreshAccount } = useAccountWriteContext()

  const { account } = useAccountReadContext()

  useEffect(() => {
    return () => localStorage.removeItem(hasAdditionalId)
  }, [])

  const handleGoToDashboard = async () => {
    await refreshAccount(locale)
    const path = isTradersRoomRouteAllowed(account)
      ? '/dashboard/traders-room/wallets'
      : '/dashboard/introducing-broker/wallets'
    navigate(path)
  }

  return (
    <SignupFormWrapper label={t('Sign up.Sign Up')}>
      <SignupStep section={6} />
      <SignupWrapper className={styles.wrapper}>
        <h1>{t('Sign up.Thank you for Registering!')}</h1>
        <span>{t('Sign up.Give us max 24 hours to verify your identity.')}</span>
        <div className={styles.buttonWrapper}>
          <Button
            type='button'
            appearance='primary'
            className={styles.button}
            size='L'
            onClick={handleGoToDashboard}
          >
            {t('Sign up.Go to Dashboard')}
          </Button>
        </div>
      </SignupWrapper>
    </SignupFormWrapper>
  )
}
