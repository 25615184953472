import React, { Suspense, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import { Loading } from '../global/Loading/Loading'
import { LocaleModule } from '../global/LocaleModule'
import { AppFooter } from '../global/footer/Footer'
import { MaintenanceMessage } from '../global/maintenanceMessage/MaintenanceMessage'
import { MobileTabs } from '../global/mobileTabs/MobileTabs'
import { Nav } from '../global/nav/Nav'
import { NotificationBlock } from '../global/notification/NotificationBlock'
import { PlatformNotification } from '../global/platformNotification/PlatformNotification'
import { Toast, ToastBanner } from '../global/toast/Toast'
import { useModalScrollHandler } from '../hooks/useModalScrollHandler'
import { SnackbarContainer } from '../ui/Popups/Snackbar/Snackbar'
import { PathHandler } from '../utils/PathHandler'
import { useLiveChatWidget } from '../utils/useLiveChatWidget'
import { GlobalModals } from './GlobalModals'

interface LayoutProps {
  toast?: Toast
  setCurrentPath: (path: string) => void
}

export const Layout: React.FC<LayoutProps> = ({ toast, setCurrentPath }) => {
  useModalScrollHandler()
  const { t } = useTranslation()

  return (
    <div className='expander'>
      {useLiveChatWidget()}
      <SnackbarContainer />
      <GlobalModals />
      <MaintenanceMessage />
      <PlatformNotification />
      <Nav />
      <main id='mainContainer' className='container'>
        <Suspense fallback={<Loading isLoading showLoadingIcon />}>
          <NotificationBlock />
          <ToastBanner of={toast} />
          <PathHandler setCurrentPath={setCurrentPath} />
          <LocaleModule />
          <MobileTabs />
          <Outlet />
        </Suspense>
      </main>
      <AppFooter />
    </div>
  )
}
