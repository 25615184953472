import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { useSessionEntity } from '../../global/context/EntityContext'
import { useModalsContext } from '../../global/context/ModalsContext'
import { useProductReadContext, useProductWriteContext } from '../../global/context/ProductContext'
import { InfoIcon } from '../../icons/InfoIcon'
import { isClientOnCoolOff, isTargetProductRegistered } from '../../model/AccountDetailedDto'
import { GlobalModalsType } from '../../routing/GlobalModals'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { TickmillCompaniesEnum } from '../../utils/companyName.utils'
import { useActionPage, useProductSwitchPath } from '../../utils/path'
import { useLocallyPersistedState } from '../../utils/useStorage'
import { InfoCard } from '../Popups/InfoCard/InfoCard'

import styles from './ProductSwitch.module.scss'

export const ProductSwitchKey = 'ProductSwitchKey'

export const useProductSwitchClick = (): (() => void) => {
  const { setActiveModal } = useModalsContext()
  const { isDefaultCFDProductType } = useProductReadContext()
  const { account } = useAccountReadContext()
  const { changeProductContext } = useProductWriteContext()
  const isProductTypeCFD = isDefaultCFDProductType()
  const [quietSwitch] = useLocallyPersistedState(ProductSwitchKey, false)
  const { navigateToNextPath } = useProductSwitchPath()

  const handleProductSwitch = () => {
    const nextProduct = changeProductContext()
    navigateToNextPath(nextProduct)
  }

  const productSwitchClick = () => {
    if (isClientOnCoolOff(account, isProductTypeCFD)) {
      setActiveModal(GlobalModalsType.ClientCooolOff)
    } else if (!isTargetProductRegistered(account, isProductTypeCFD)) {
      setActiveModal(GlobalModalsType.RegisterProduct)
    } else if (quietSwitch) {
      handleProductSwitch()
    } else {
      setActiveModal(GlobalModalsType.ProductSwitch)
    }
  }

  return () => productSwitchClick()
}

interface ProductSwitchProps {
  onChange?: (value: boolean) => void
  className?: string
}

export const ProductSwitch: React.FC<ProductSwitchProps> = ({ onChange, className }) => {
  const click = useProductSwitchClick()
  const { account } = useAccountReadContext()
  const { isDefaultCFDProductType } = useProductReadContext()
  const isProductTypeCFD = isDefaultCFDProductType()
  const { setActiveModal } = useModalsContext()
  const isActionPage = useActionPage()
  const entity = useSessionEntity()

  const { t } = useTranslation()

  const name = 'product'

  const showProductSelector = account?.companyConfiguration.showProductSwitcher

  const showCancelActionModal = () => setActiveModal(GlobalModalsType.CancelAction)

  if (
    entity !== TickmillCompaniesEnum.TICKMILL_UK ||
    (entity === TickmillCompaniesEnum.TICKMILL_UK && !showProductSelector)
  ) {
    return null
  }

  return (
    <div className={styles.productSwitch}>
      <div className={classNames(styles.wrapper, className)}>
        <span className={styles.checkbox}>
          <input
            type='checkbox'
            id={name}
            className={isProductTypeCFD ? 'checked' : ''}
            checked={isProductTypeCFD}
            onChange={(e) => {
              if (isActionPage) return showCancelActionModal()
              click()
              if (onChange) onChange(e.target.checked)
            }}
          />
          <label
            className={classNames(styles.labelLeft, {
              [styles.isEnabled]: isProductTypeCFD,
            })}
            htmlFor={name}
          >
            CFD
          </label>
          <label
            className={classNames(styles.labelRight, {
              [styles.isEnabled]: !isProductTypeCFD,
            })}
            htmlFor={name}
          >
            Futures
          </label>
          <span
            className={classNames(styles.selector, {
              [styles.ETD]: !isProductTypeCFD,
            })}
          />
        </span>
      </div>
      <InfoCard
        direction='bottomRight'
        title={t('General info about CFD and ETD')}
        text={
          <>
            <p>{t('CFDs (Contracts for difference) and Futures (Exchange traded derivatives)')}</p>
            <p>
              {t('Although they share many similarities, they also present several differences')}
            </p>
          </>
        }
      >
        <InfoIcon color={isProductTypeCFD ? 'textInteractive' : 'alwaysWhite'} />
      </InfoCard>
    </div>
  )
}
