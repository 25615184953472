import { useLocation } from 'react-router-dom'

import { isZero } from '../utils/validations'
import {
  ReferralParams,
  getReferralParamsOrCookie,
  isAffiliatesParams,
  isReferralCode,
  isReferralCodeIB,
  isSGLLink,
} from './helpers'

interface RegistrationData {
  id: string
  token: string
  type: 'register' | 'register2'
}

export const useDeviceReferral = (): {
  referralAppLink: (registrationData?: RegistrationData) => string
  referralAppLeadLink: (affToken?: string, referralCode?: string) => string
} => {
  const location = useLocation()

  const search = location.search

  return {
    referralAppLink: (registrationData) => {
      const referralParams = getReferralParamsOrCookie(search)
      return createDeviceReferralApplicationLink(referralParams, registrationData)
    },
    referralAppLeadLink: (affToken, referralCode) => {
      return createDeviceReferralApplicationLink({
        aff_token: affToken,
        referral_code: referralCode,
      })
    },
  }
}

const createDeviceReferralApplicationLink = (
  referralParams: Partial<ReferralParams>,
  registrationData?: RegistrationData
) => {
  const appOneLinkBase = window.env.APP_ONE_LINK_BASE
  const params = new URLSearchParams()

  if (registrationData) {
    params.append('deep_link_sub7', registrationData.type)
    params.append('deep_link_sub8', registrationData.id)
    params.append('deep_link_sub9', registrationData.token)
  }

  if (isNotInfected(referralParams)) {
    if (registrationData) {
      params.append('deep_link_value', 'referral')
      return createAppOneLink(params)
    } else return appOneLinkBase
  }

  if (isAffiliateAndSGLInfected(referralParams)) {
    params.append('deep_link_value', 'referral')
    params.append('deep_link_sub3', 'afftoken')
    params.append('deep_link_sub4', referralParams.aff_token)
    params.append('deep_link_sub5', 'sgl_link')
    params.append('deep_link_sub6', referralParams.referral_code)
  } else if (isSGLInfected(referralParams)) {
    params.append('deep_link_value', 'referral')
    params.append('deep_link_sub5', 'sgl_link')
    params.append('deep_link_sub6', referralParams.referral_code)
  } else if (isAffiliateInfected(referralParams)) {
    params.append('deep_link_value', 'referral')
    params.append('deep_link_sub3', 'afftoken')
    params.append('deep_link_sub4', referralParams.aff_token)
  } else if (isIBCodeInfected(referralParams)) {
    params.append('deep_link_value', 'referral')
    params.append('deep_link_sub1', 'ib_link')
    params.append('deep_link_sub2', referralParams.referral_code)
  }
  if (isZero(params.size)) return appOneLinkBase
  else return createAppOneLink(params)
}

const isNotInfected = (params: Partial<ReferralParams>): params is ReferralParams => {
  return !isAffiliatesParams(params) && !isReferralCode(params)
}

const isIBCodeInfected = (params: Partial<ReferralParams>): params is ReferralParams => {
  return !isAffiliatesParams(params) && isReferralCode(params) && isReferralCodeIB(params)
}

const isAffiliateInfected = (params: Partial<ReferralParams>): params is ReferralParams => {
  return isAffiliatesParams(params) && !isReferralCode(params)
}

const isSGLInfected = (params: Partial<ReferralParams>): params is ReferralParams => {
  return !isAffiliatesParams(params) && isSGLLink(params) && isReferralCode(params)
}

const isAffiliateAndSGLInfected = (params: Partial<ReferralParams>): params is ReferralParams => {
  return isAffiliatesParams(params) && isReferralCode(params)
}

const createAppOneLink = (params: URLSearchParams) => {
  const appOneLinkBase = window.env.APP_ONE_LINK_BASE
  return `${appOneLinkBase}/?${params.toString()}`
}
