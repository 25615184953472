import React from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'

import { LearningETD } from '../Learning/LearningETD'
import {
  DocumentManagementNotification,
  isDocumentManagementNotificationNeeded,
} from '../Profile/DocumentManagement/DocumentManagementPage'
import {
  YearlyKycUpdateDetailsNotification,
  isKycUpdateDetailsNotificationNeeded,
} from '../Profile/YearlyKycUpdateDetails/YearlyKycUpdateDetailsPage'
import { StatementsETD } from '../Statements/StatementsETD'
import { SubscriptionsETD } from '../Subscriptions/SubscriptionsETD'
import { ToolsETD } from '../Tools/ToolsETD'
import { TradersRoomETD } from '../Traders-Room/TradersRoomETD'
import { SessionTimeout } from '../global/SessionTimeout/SessionTimeout'
import { useProductReadContext } from '../global/context/ProductContext'
import { Tabs, defineTab } from '../global/tabs/Tabs'
import {
  hasProductAppropriatenessTestSubmittedStatus,
  hasProductPendingDocumentVerificationStatus,
} from '../model/AccountDetailedDto'
import { useAccountReadContext } from '../utils/AccountContextContext'
import { isNoTabsPath } from '../utils/path'
import { isLearningRouteAllowed } from '../utils/permission.utils'
import { useLandingPage } from '../utils/useLandingPage'

import styles from './Dashboard.module.scss'

export const DashboardRoutesETD: React.FC = () => {
  const { tab } = useParams<{ tab?: string }>()
  const { account } = useAccountReadContext()
  const location = useLocation()
  const { t } = useTranslation()
  const { getLandingPage } = useLandingPage()
  const { product } = useProductReadContext()

  const navigate = useNavigate()
  const isAccountPending =
    hasProductAppropriatenessTestSubmittedStatus(account, product) ||
    hasProductPendingDocumentVerificationStatus(account, product)

  function* getTabs() {
    yield {
      ...defineTab(
        'traders-room',
        t('Tabs.Traders room'),
        false,
        false,
        'traders-room/balances',
        classNames({ 'is-hidden': isAccountPending })
      ),
    }
    yield {
      ...defineTab(
        'subscriptions',
        t('Tabs.Subscriptions'),
        undefined,
        false,
        'subscriptions/trading-platforms',
        classNames({ 'is-hidden': isAccountPending })
      ),
    }
    yield {
      ...defineTab(
        'statements',
        t('Tabs.Statements'),
        undefined,
        false,
        'statements/daily',
        classNames({ 'is-hidden': isAccountPending })
      ),
    }
    yield {
      ...defineTab(
        'tools',
        t('Tabs.Tools'),
        undefined,
        false,
        'tools/platform-vendors',
        classNames({
          'is-hidden': isAccountPending,
        })
      ),
    }
    yield {
      ...defineTab(
        'learning',
        t('Tabs.Learning'),
        undefined,
        false,
        'learning/getting-started',
        classNames({ 'is-hidden': isAccountPending })
      ),
    }
  }

  const tabs = [...getTabs()]

  if (!account) {
    return null
  }

  return (
    <div>
      <SessionTimeout />
      {isKycUpdateDetailsNotificationNeeded(account) && <YearlyKycUpdateDetailsNotification />}
      {isDocumentManagementNotificationNeeded(account) && <DocumentManagementNotification />}
      {tab && !isNoTabsPath(location.pathname) && (
        <div className={classNames('section', styles.adminRoutes)}>
          <Tabs
            tabs={tabs}
            currentTab={tab}
            onTabChanged={(tab) => {
              const path = tabs.find((x) => x.id === tab)
              if (path) {
                return navigate(`/dashboard/${path.path}`)
              }
              return navigate(`/dashboard/${tab}`)
            }}
          />
        </div>
      )}
      <Routes>
        {tab === 'traders-room' && <Route path='/:tab/*' element={<TradersRoomETD />} />}
        {tab === 'subscriptions' && <Route path='/:tab/*' element={<SubscriptionsETD />} />}
        {tab === 'statements' && <Route path='/:tab/*' element={<StatementsETD />} />}
        {isLearningRouteAllowed(account) && tab === 'learning' && (
          <Route path='/:tab/*' element={<LearningETD />} />
        )}
        {tab === 'tools' && <Route path='/:tab/*' element={<ToolsETD />} />}
        <Route path='*' element={<Navigate to={getLandingPage()} />} />
      </Routes>
    </div>
  )
}
