import React, { FormEventHandler, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Form, FormikErrors, FormikProps, withFormik } from 'formik'

import { Loading } from '../../global/Loading/Loading'
import { Button } from '../../global/button/Button'
import { createFormField } from '../../global/formField/FormField'
import { CheckCircledIcon } from '../../icons/CheckCircledIcon'
import { FailureExpressionIcon } from '../../icons/FailureExpressionIcon'
import { LeadDto } from '../../model/LeadDto'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { preventPaste } from '../../utils/input.utils'
import { useFetchOne } from '../../utils/useFetch'
import { useScrollToTop } from '../../utils/useScrollToTop'
import { UpdatePhoneForm } from './UpdatePhoneForm'

import styles from './PersonalInfoPage.module.scss'

const FormField = createFormField<FormValuesStepMobileVerification>()

export interface FormValuesStepMobileVerification {
  phoneCode1st: string
  phoneCode2nd: string
  phoneCode3rd: string
  phoneCode4th: string
}

const fieldKeys: (keyof FormValuesStepMobileVerification)[] = [
  'phoneCode1st',
  'phoneCode2nd',
  'phoneCode3rd',
  'phoneCode4th',
]

export interface OuterProps {
  lead: LeadDto
  phoneVerificationId: string
  handleNextStep(): void
  resendCode(): void
  onReceivePhoneCall(): void
  refreshLead(): void
}

export const INITIAL_COUNTER_SECONDS = 15

const PersonalInfoStepMobileValidationFormUI: React.FC<
  FormikProps<FormValuesStepMobileVerification> & OuterProps
> = (props) => {
  const { handleSubmit, values, refreshLead } = props
  useScrollToTop()
  const { t } = useTranslation()
  const apiClient = useApiClient(ClientApiClient)

  const [validationError, setValidationError] = useState('')
  const [verificationCodeError, setVerificationCodeError] = useState('')
  const [verificationSuccess, setVerificationSuccess] = useState(false)
  const [counter, setCounter] = useState(INITIAL_COUNTER_SECONDS)
  const [disabled, setDisabled] = useState(false)
  const [borderClass, setBorderClass] = useState<string>('')
  const [editPhone, setEditPhone] = useState<boolean>(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callback = useCallback(async () => await apiClient.getCountries(), [])
  const { data: countries = [], isLoading } = useFetchOne(callback)

  const handleUpdatePhone = async (phoneNumber: string, countryCode: string) => {
    await apiClient.updateLeadPhoneNumber(props.lead.id, phoneNumber, countryCode)
    refreshLead()
    setEditPhone(false)
  }

  const handleCancelUpdatePhone = () => {
    setEditPhone(false)
  }

  const onSkip: FormEventHandler = (event) => {
    event.preventDefault()
    handleSubmit()
  }

  const onResend = (e: React.FormEvent<Element>) => {
    e.preventDefault()
    setCounter(15)
    props.resendCode()
  }

  const onReceivePhoneCall = (e: React.FormEvent<Element>) => {
    e.preventDefault()
    setCounter(15)
    props.onReceivePhoneCall()
  }

  useEffect(() => {
    const timer = setInterval(() => {
      if (counter > 0) {
        setCounter(counter - 1)
      }
    }, 1000)
    return () => clearInterval(timer)
  }, [counter])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    props.setFieldValue(name, value)
    let nextField: string | undefined
    if (name === 'phoneCode1st') {
      nextField = 'phoneCode2nd'
    } else if (name === 'phoneCode2nd') {
      nextField = 'phoneCode3rd'
    } else {
      nextField = 'phoneCode4th'
    }
    const next = document.getElementById(nextField)
    if (next && !!value) next.focus()
  }

  const handleStepBack = (
    e: {
      key: string
      preventDefault: () => void
    },
    name: string,
    value: string
  ) => {
    e.preventDefault()
    let previousField: string | undefined
    if (name === 'phoneCode2nd') {
      previousField = 'phoneCode1st'
    } else if (name === 'phoneCode3rd') {
      previousField = 'phoneCode2nd'
    } else if (name === 'phoneCode4th') {
      previousField = 'phoneCode3rd'
    }
    if (previousField) {
      const previous = document.getElementById(previousField)
      if (previous && !value) {
        props.setFieldValue(previousField, '')
        previous?.focus()
      }
    }
  }

  useEffect(() => {
    let delayDebounceFn: NodeJS.Timeout
    if (values.phoneCode1st && values.phoneCode2nd && values.phoneCode3rd && values.phoneCode4th) {
      setValidationError('')
      const code =
        values.phoneCode1st + values.phoneCode2nd + values.phoneCode3rd + values.phoneCode4th
      delayDebounceFn = setTimeout(() => {
        apiClient
          .validatePhoneCode(props.lead.id, props.phoneVerificationId, code)
          .then(() => {
            setVerificationSuccess(true)
            setBorderClass(styles.verificationBoxSuccess)
            setDisabled(true)
            setTimeout(() => {
              setBorderClass('')
              props.handleNextStep()
            }, 1000)
            setValidationError('')
            // todo handleNextStep()
          })
          .catch((error) => {
            if (error.response.response.data.code === 'phone_verification_max_attempts_reached') {
              setBorderClass(styles.verificationBoxError)
              setTimeout(() => {
                setBorderClass('')
              }, 1000)
              setValidationError(t('Sign up.Wrong code/Phone Verification failed'))
              setVerificationCodeError(error.response.response.data.code)
              setDisabled(true)
              setTimeout(() => {
                props.resetForm()
                handleSubmit()
              }, 3000)
            } else {
              props.resetForm()
              setValidationError(t('Sign up.Wrong code/Phone Verification failed'))
              setVerificationCodeError(error.response.response.data.code)
            }
          })
      }, 1000)
    }
    return () => clearTimeout(delayDebounceFn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.phoneCode1st, values.phoneCode2nd, values.phoneCode3rd, values.phoneCode4th])

  if (editPhone) {
    return (
      <Loading showLoadingIcon isLoading={isLoading}>
        <UpdatePhoneForm
          countries={countries}
          onSubmit={handleUpdatePhone}
          onCancel={handleCancelUpdatePhone}
          countryCode={props.lead.countryCode}
        />
      </Loading>
    )
  }

  return (
    <React.Fragment>
      <Form className={styles.mobileVerificationWrapper} autoComplete='off' onSubmit={onSkip}>
        <div className='is-flex is-flex-direction-row is-align-items-center'>
          <h3 className='mr-1 mb-4'>{t('Sign up.Enter 4-digit code')}</h3>
          {verificationSuccess && <CheckCircledIcon circleColor={'success'} checkColor={'white'} />}
          {(verificationCodeError === 'phone_verification_max_attempts_reached' ||
            verificationCodeError === 'max_new_verification_code_requests_reached') && (
            <FailureExpressionIcon />
          )}
        </div>
        <span className={styles.text}>
          {t('Sign up.Code was sent', {
            phoneNumber: `${props.lead.countryCode || ''} ${props.lead.phoneNumber || ''}`,
          })}{' '}
          <Button onClick={() => setEditPhone(true)} appearance='link'>
            {t('change')}
          </Button>
        </span>
        <div className='mt-4'>
          <div className={'is-flex'}>
            {fieldKeys.map((fk: keyof FormValuesStepMobileVerification) => (
              <span className={styles.fieldBlock}>
                <FormField
                  id={fk}
                  name={fk}
                  type='text'
                  value={props.values[fk] ? '*' : ''}
                  disabled={disabled}
                  fullWidth
                  onPaste={preventPaste}
                  onChange={onChange}
                  onKeyDown={(e) => {
                    if (e.key === 'Backspace' && !props.values[fk])
                      handleStepBack(e, fk, props.values[fk])
                    if (['e', 'E', '+', '-'].includes(e.key)) return e.preventDefault()
                  }}
                  className={`${styles.verificationBox} ${borderClass}`}
                />
              </span>
            ))}
          </div>
          {!!validationError && <div className={styles.validationError}>{validationError}</div>}
        </div>
        {counter === 0 ? (
          <React.Fragment>
            <div className='is-flex text-align-center'>
              <span className={classNames(styles.text, 'is-flex is-align-items-center')}>
                {t('Sign up.Not get code')}{' '}
                <Button
                  className='pl-4'
                  type='button'
                  onClick={onResend}
                  appearance='plain'
                  size='S'
                >
                  {t('Sign up.Resend')}
                </Button>
              </span>
            </div>

            <div className='is-flex'>
              <Button onClick={onReceivePhoneCall} type='button' size='S' appearance='plain'>
                {t('Sign up.Receive phone call')}
              </Button>
            </div>
          </React.Fragment>
        ) : (
          <span className={styles.textSmall}>
            {t('Sign up.Please allow at least 15 seconds for code to reach your phone...', {
              counter: `0:${String(counter).padStart(2, '0')}`,
            })}
          </span>
        )}

        <Button
          className={styles.submitBtn}
          type='submit'
          size='S'
          appearance='secondary'
          disabled={counter > 0}
        >
          {t('Skip')}
        </Button>
      </Form>
    </React.Fragment>
  )
}

export const PersonalInfoStepMobileValidationForm = withFormik<
  OuterProps,
  FormValuesStepMobileVerification
>({
  mapPropsToValues: () => {
    return {
      phoneCode4th: '',
      phoneCode1st: '',
      phoneCode3rd: '',
      phoneCode2nd: '',
    }
  },
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      setSubmitting(true)
      await props.handleNextStep()
    } finally {
      setSubmitting(false)
    }
  },
  validate: () => {
    const errors: FormikErrors<FormValuesStepMobileVerification> = {}
    return errors
  },
})(PersonalInfoStepMobileValidationFormUI)
