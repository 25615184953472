import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikProps, withFormik } from 'formik'

import { InfoIconWrapper } from '../../global/InfoIconWrapper/InfoIconWrapper'
import { Button } from '../../global/button/Button'
import { SelectField } from '../../global/field/SelectField'
import { createFormField } from '../../global/formField/FormField'
import { InformationModal } from '../../global/modal/InformationModal'
import { Modal } from '../../global/modal/Modal'
import { IBTestSubCategory } from '../../model/TestSectionsDto'
import { useWindowResize } from '../../utils/domUtils'
import { useIBPrograms } from '../../utils/useIBPrograms'
import { useIBReferralCodes } from '../../utils/useIBReferralCodes'

import styles from './SignUpForm.module.scss'

export interface SignUpFormValues {
  selectedAnswers: string[]
  currency: string
}

interface OuterProps {
  subCategoryId?: IBTestSubCategory
  setSubCategoryId: Dispatch<SetStateAction<IBTestSubCategory | undefined>>

  handleSignUp: (selectedAnswers: string[]) => void
}

const FormField = createFormField<SignUpFormValues>()

const IBSignUpFormUI: React.FC<FormikProps<SignUpFormValues> & OuterProps> = ({
  isValid,
  subCategoryId,
  setSubCategoryId,
  handleSubmit,
  setValues,
  values,
}) => {
  const [currencyModal, setCurrencyModal] = useState(false)
  const [iBProgramModal, setIBProgramModal] = useState(false)
  const { t } = useTranslation()
  const { defaultProgram, multiTierProgram } = useIBPrograms()
  const { mtCode, ibCode, isLoading } = useIBReferralCodes()

  const handleSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleSubmit()
  }

  const isMobile = useWindowResize()

  useEffect(() => {
    setValues({
      ...values,
      selectedAnswers: [],
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategoryId])

  const programOptions = useMemo(
    () => [
      ...(defaultProgram && !ibCode
        ? [
            {
              value: IBTestSubCategory.Default,
              label: t('IB.Introducing Broker Program'),
            },
          ]
        : []),
      ...(multiTierProgram && !mtCode
        ? [
            {
              value: IBTestSubCategory.MultiTier,
              label: t('IB.Multi-tier Program'),
            },
          ]
        : []),
    ],
    [defaultProgram, ibCode, multiTierProgram, mtCode, t]
  )

  return (
    <>
      {currencyModal && (
        <Modal
          closeModal={() => setCurrencyModal(false)}
          render={() => (
            <InformationModal
              title={t('Currency')}
              buttonText={t('Got It')}
              onCancel={() => setCurrencyModal(false)}
            >
              <span>{t('IB.At the moment IB account is available only in USD.')}</span>
            </InformationModal>
          )}
        />
      )}
      {iBProgramModal && (
        <Modal
          closeModal={() => setIBProgramModal(false)}
          render={() => (
            <InformationModal
              title={t('IB.IB Program')}
              buttonText={t('Got It')}
              onCancel={() => setIBProgramModal(false)}
            >
              <span>
                {t(
                  'IB.Our current IB Program imply rewards based on the lots traded by your clients.'
                )}
              </span>
            </InformationModal>
          )}
        />
      )}
      <Form className={styles.wrapper} onSubmit={handleSubmitForm}>
        <SelectField
          value={subCategoryId}
          disabled={isLoading || programOptions.length < 2}
          onChange={(e) => setSubCategoryId(e.target.value as IBTestSubCategory)}
          label={t('IB.IB Program')}
          options={programOptions}
        />
        <FormField
          name='currency'
          label={t('Currency')}
          placeholder={t('Currency')}
          rightIcon={<InfoIconWrapper onClick={() => setCurrencyModal(true)} />}
          disabled
          value={'USD'}
          wrapperClassname={styles.field}
        />
        {!!subCategoryId && (
          <div className={styles.action}>
            <Button
              disabled={!isValid}
              type='submit'
              appearance='primary'
              className={styles.submitButton}
              size={isMobile ? 'S' : 'L'}
            >
              {t('Submit')}
            </Button>
          </div>
        )}
      </Form>
    </>
  )
}
export const IBSignUpForm = withFormik<OuterProps, SignUpFormValues>({
  mapPropsToValues: () => ({
    selectedAnswers: [],
    currency: 'USD',
  }),
  handleSubmit: ({ selectedAnswers }, { props }) => {
    props.handleSignUp(selectedAnswers)
  },
  enableReinitialize: true,
})(IBSignUpFormUI)
