import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button } from '../../global/button/Button'
import { DocumentCategoryLinkMap, DocumentCategoryType } from '../../model/DocumentCategories'
import { NameDto } from '../../model/NameDto'
import { Text, TextLargeStrong, TextSmall } from '../../ui/Typography/Typography'
import { useWindowResize } from '../../utils/domUtils'
import { DocumentManagementCategoryModal } from './DocumentManagementCategoryModal'
import { useAdditionalDocuments } from './useAdditionalDocuments'

import styles from './DocumentManagementPage.module.scss'

export const UploadAdditionalDocuments = (): JSX.Element => {
  const navigate = useNavigate()

  const { t } = useTranslation()
  const [isAdditionalDocumentsModalOpen, setAdditionalDocumentsModalOpen] =
    useState<DocumentCategoryType>()
  const isMobile = useWindowResize()
  const docData = useAdditionalDocuments()

  const closeModal = () => setAdditionalDocumentsModalOpen(undefined)

  const handleSelectDoc = (
    documentCategory: NameDto<DocumentCategoryType>,
    documentCategoryType: NameDto
  ) => {
    navigate(`/profile/document-management/${DocumentCategoryLinkMap[documentCategory.id]}`, {
      state: {
        documentCategory,
        documentCategoryType,
      },
    })
  }
  console.log({ docData })
  return (
    <>
      {docData.map((doc) =>
        doc ? (
          <div className={styles.additionalCard}>
            {isAdditionalDocumentsModalOpen === doc.category && (
              <DocumentManagementCategoryModal
                documentCategoryType={doc.category}
                onSelectOption={handleSelectDoc}
                onClose={closeModal}
              />
            )}
            <div className={styles.textBox}>
              <TextLargeStrong>{doc.title}</TextLargeStrong>
              <div className={styles.descriptionBox}>
                {doc.text && <Text className={styles.description}>{doc.text}</Text>}
                {doc.instructions && (
                  <Text className={styles.instructions}>{doc.instructions}</Text>
                )}
              </div>
            </div>
            <Button
              appearance='primary'
              size={isMobile ? 'M' : 'S'}
              type='button'
              onClick={() => setAdditionalDocumentsModalOpen(doc.category)}
            >
              {t('Profile.Upload')}
            </Button>
          </div>
        ) : (
          <></>
        )
      )}
    </>
  )
}
