import { useMemo } from 'react'

import { useSessionEntity } from '../../global/context/EntityContext'
import { isTickmillSCType } from '../../utils/companyName.utils'

export const usePersonalInfoTotalSteps = (tickmillCompanyId?: number, subStep?: number): number => {
  const entity = useSessionEntity()
  const RegulatorSelectionStep = 2

  const steps = useMemo(() => {
    const isTickmillSC = isTickmillSCType(entity) || isTickmillSCType(tickmillCompanyId)

    if (subStep && subStep < RegulatorSelectionStep) {
      return 6
    }

    return isTickmillSC ? 5 : 6
  }, [entity, tickmillCompanyId, subStep])

  return steps
}
