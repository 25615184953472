import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { ChevronDownIcon } from '../../icons/ChevronDownIcon'
import { ChevronUpIcon } from '../../icons/ChevronUpIcon'
import { SortOrder } from '../../utils/ApiClient'

import styles from './SortByModal.module.scss'

interface Props {
  title?: string
  options: (string | { id: string; name: string })[]
  onConfirm(option: string, sortBy: SortOrder, sortName: string): void
  onCancel(): void
}

export function SortByModal(props: Props) {
  const { t } = useTranslation()
  return (
    <Fragment>
      <header className={classNames('modal-card-head', styles.header)}>
        <p className='modal-card-title'>{props.title || t('SortBy')}</p>
      </header>
      <section className={classNames('modal-card-body', styles.body)}>
        {props.options.map((option) => {
          let id: string
          let name: string
          if (typeof option === 'string') {
            id = option
            name = option
          } else {
            id = option.id
            name = option.name
          }
          return (
            <div className={styles.option} key={id}>
              <span>{name}</span>
              <div className={styles.sort}>
                <span className={styles.sortBy} onClick={() => props.onConfirm(id, 'DESC', name)}>
                  <ChevronDownIcon />
                </span>
                <span className={styles.sortBy} onClick={() => props.onConfirm(id, 'ASC', name)}>
                  <ChevronUpIcon />
                </span>
              </div>
            </div>
          )
        })}
      </section>
      <footer className={classNames('modal-card-foot', styles.footer)}>
        <button className='button' onClick={props.onCancel} type='button'>
          {t('Cancel')}
        </button>
      </footer>
    </Fragment>
  )
}
