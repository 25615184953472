import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { useArabicSessionLanguage } from '../../global/context/SessionSettingsContext'
import { ExportModal } from '../../global/modal/ExportModal'
import { Modal } from '../../global/modal/Modal'
import { SortHeader } from '../../global/sortHeader/SortHeader'
import { Table, TableBody, TableCell } from '../../global/table/Table'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { SubscriptionHistoryItem, showSubLevel } from '../../model/SubscriptionsDto'
import { PageQuery } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { formatDate } from '../../utils/date.utils'
import { SubscriptionBadgeStatus } from './SubscriptionBadgeStatus'
import { SubscriptionStatusHistoryModal } from './SubscriptionStatusHistoryModal'

import styles from './SubscriptionsHistoryTable.module.scss'

interface Props {
  data: SubscriptionHistoryItem[]
  pageQuery?: PageQuery
  setPageQuery: ((pageQuery?: PageQuery | undefined) => void) | undefined
}

export const SubscriptionsHistoryTable: React.FC<Props> = ({ data, pageQuery, setPageQuery }) => {
  const { formatMoney } = useFormatNumber()
  const isArabic = useArabicSessionLanguage()
  const { t } = useTranslation()

  const [expandedRow, setExpandedRow] = useState('')
  const [optionsModal, setOptionsModal] = useState<boolean>(false)
  const [historyStatusModal, setHistoryStatusModal] = useState<boolean>(false)
  const [selectedSubscription, setSelectedSubscription] = useState('')
  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name
  const exportFilename = 'subscriptions_history_report'

  const handleSetExpandedRow = (id: string) => {
    if (id === expandedRow) {
      id = ''
    }
    setExpandedRow(id)
  }

  return (
    <React.Fragment>
      {optionsModal && (
        <Modal
          render={({ closeModal }) => (
            <ExportModal
              onCloseModal={closeModal}
              csvData={[]}
              onExportToCSV={() => console.log('TODO Export to CSV')}
              onExportToPdf={() => console.log('TODO Export to PDF')}
              onExportToExcel={() => console.log('TODO Export to EXCEL')}
              fileName={exportFilename}
            />
          )}
          closeModal={() => setOptionsModal(false)}
        />
      )}
      {historyStatusModal && (
        <Modal
          closeModal={() => setHistoryStatusModal(false)}
          render={({ closeModal }) => (
            <SubscriptionStatusHistoryModal id={selectedSubscription} onCancel={closeModal} />
          )}
        />
      )}
      <Table spacing='none'>
        <thead>
          <tr>
            <SortHeader
              id='CreatedDate'
              sort={(pageQuery && pageQuery!.sort) || undefined}
              sortOrder={(pageQuery && pageQuery!.sortOrder) || 'DESC'}
              setSort={(sort, sortOrder) =>
                setPageQuery!({
                  ...pageQuery,
                  sort,
                  sortOrder,
                })
              }
            >
              {t('Subscriptions.Date submitted')}
            </SortHeader>
            <SortHeader
              id='ClientSubscriptionDetail.SubscriptionDetail.Subscription.Name'
              sort={(pageQuery && pageQuery!.sort) || undefined}
              sortOrder={(pageQuery && pageQuery!.sortOrder) || 'DESC'}
              setSort={(sort, sortOrder) =>
                setPageQuery!({
                  ...pageQuery,
                  sort,
                  sortOrder,
                })
              }
            >
              {t('Subscriptions.Product')}
            </SortHeader>
            <th>{t('Subscriptions.Request Type')}</th>
            <SortHeader
              id='ClientSubscriptionDetail.SubscriptionDetail.Amount'
              sort={(pageQuery && pageQuery!.sort) || undefined}
              sortOrder={(pageQuery && pageQuery!.sortOrder) || 'DESC'}
              setSort={(sort, sortOrder) =>
                setPageQuery!({
                  ...pageQuery,
                  sort,
                  sortOrder,
                })
              }
            >
              {t('Subscriptions.Price')}
            </SortHeader>
            <SortHeader
              id='state.Order'
              sort={(pageQuery && pageQuery!.sort) || undefined}
              sortOrder={(pageQuery && pageQuery!.sortOrder) || 'DESC'}
              setSort={(sort, sortOrder) =>
                setPageQuery!({
                  ...pageQuery,
                  sort,
                  sortOrder,
                })
              }
            >
              {t('Subscriptions.Status')}
            </SortHeader>
          </tr>
        </thead>
        {data.map((x) => (
          <TableBody margined spacing='none' key={x.id}>
            <tr
              className={classNames({
                [styles.expandableParent]: expandedRow === x.id,
              })}
            >
              <td>{formatDate(x.createdDate, dateFormat)}</td>
              <td className='has-cursor-pointer' onClick={() => handleSetExpandedRow(x.id)}>
                <strong>{x.subscription.name}</strong>
              </td>
              <td>{x.type.name}</td>
              <td>{formatMoney(x.amount)}</td>
              <td className='has-cursor-pointer' onClick={() => setSelectedSubscription(x.id)}>
                <span onClick={() => setHistoryStatusModal(true)}>
                  <SubscriptionBadgeStatus {...x.state} />
                </span>
              </td>
            </tr>
            {expandedRow === x.id && (
              <tr className={styles.expandable}>
                <TableCell spacing='none' expanded colSpan={5}>
                  <div
                    dir={isArabic ? 'rtl' : ''}
                    className={classNames('px-4 py-4', styles.container)}
                  >
                    <div className={styles.leftContainer}>
                      <div>
                        <span dir={isArabic ? 'rtl' : ''} className={styles.secondaryTextSmall}>
                          {t('Subscriptions.Product Group')}:
                        </span>
                        <span dir={isArabic ? 'rtl' : ''} className={styles.textSmall}>
                          {' '}
                          {x.subscriptionGroupType.name}
                        </span>
                      </div>
                    </div>
                    {showSubLevel(x.subscriptionGroupType.id, x.subscriptionDetailType.id) && (
                      <div>
                        <span className={styles.secondaryTextSmall}>
                          {t('Subscriptions.Level')}:{' '}
                        </span>
                        <span className={styles.textSmall}>{x.subscriptionDetailType.id}</span>
                      </div>
                    )}
                  </div>
                </TableCell>
              </tr>
            )}
          </TableBody>
        ))}
      </Table>
    </React.Fragment>
  )
}
