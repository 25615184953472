import React, { PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Field, Form, FormikErrors, FormikProps, getIn, withFormik } from 'formik'
import { t } from 'i18next'

import { ExternalLink } from '../../../global/ExternalLink'
import { Button } from '../../../global/button/Button'
import { DocumentFileUploads } from '../../../global/fileUpload/DocumentFileUploads'
import { FileData } from '../../../global/fileUpload/FileUpload'
import { DocumentFileExtensionType } from '../../../global/fileUpload/types'
import { createFormField } from '../../../global/formField/FormField'
import { ChevronDownIcon } from '../../../icons/ChevronDownIcon'
import { InfoIcon } from '../../../icons/InfoIcon'
import { TestAnswerDto, TestAnswersDto } from '../../../model/ClientTestAnswerDto'
import { ClientTestDto } from '../../../model/ClientTestDto'
import { DocumentCategoryType } from '../../../model/DocumentCategories'
import { FreeTextAnswer } from '../../../model/TestSectionsDto'
import { useAccountReadContext } from '../../../utils/AccountContextContext'
import { TickmillCompaniesEnum, isTickmillEU } from '../../../utils/companyName.utils'
import { isZero } from '../../../utils/validations'
import { ClassificationText } from './ClassficiationText'

import styles from './ProfessionalClassificationTestForm.module.scss'

enum RadioCodeEnum {
  SELECTED = '1',
}

interface Props {
  title: string
  text: string
  hasIcon?: boolean
  handelInfoOnClick?: () => void
}

const OverviewCard: React.FC<PropsWithChildren<Props>> = ({
  title,
  text,
  hasIcon = true,
  handelInfoOnClick,
  children,
}) => {
  return (
    <div className={classNames(styles.overview)}>
      <div className={styles.overviewWrap}>
        <div>
          <p className={styles.textStrong}>{title}</p>
        </div>
        {hasIcon && (
          <div className={styles.icon} onClick={handelInfoOnClick}>
            <InfoIcon />
          </div>
        )}
      </div>
      <p className={styles.text}>{text}</p>
      {children}
    </div>
  )
}

export interface ProfessionalClassificationTestValues {
  significantTradeSize: string
  significantPortfolio: string
  professionalExperience: string

  employerName: string

  jobTitle: string

  jobDuration: string

  professionalExperienceDetail: string

  tradeSizeProofs: FileData[]
  portfolioProofs: FileData[]

  classificationDeclaration1: boolean
  classificationDeclaration2: boolean
  classificationDeclaration3: boolean

  freeAnswers: FreeTextAnswer[]
  selectedAnswers: { question: string; answer: string }[]
}

interface OuterProps {
  handleTradeSizeModalOpen: () => void
  handlePortfolioModalOpen: () => void

  testData: ClientTestDto

  testAnswers: TestAnswersDto | undefined
  clientCategorizationPolicy: string | undefined

  handleFormSubmit: (values: ProfessionalClassificationTestValues) => void

  isFormEnabled: boolean

  classificationName: string | undefined
}

const FormTextField = createFormField<ProfessionalClassificationTestValues>()

const ProfessionalClassificationTestFormUI: React.FC<
  FormikProps<ProfessionalClassificationTestValues> & OuterProps
> = (props) => {
  const { clientCategorizationPolicy, handlePortfolioModalOpen, handleTradeSizeModalOpen } = props
  const { testData, values, setFieldValue, setFieldTouched, handleSubmit, isFormEnabled } = props
  const { classificationName, errors, isValid, isSubmitting, setSubmitting, testAnswers } = props

  const { significantPortfolio, professionalExperience, significantTradeSize } = values
  const { selectedAnswers, freeAnswers } = values

  const { account } = useAccountReadContext()

  const { t } = useTranslation()

  const getAffirmativeAnswers = () => {
    const hasProfessionalExperienceValue = professionalExperience === RadioCodeEnum.SELECTED ? 1 : 0
    const hasSignificantTradeSizeValue = significantTradeSize === RadioCodeEnum.SELECTED ? 1 : 0
    const hasSignificantPortfolioValue = significantPortfolio === RadioCodeEnum.SELECTED ? 1 : 0

    return (
      hasProfessionalExperienceValue + hasSignificantPortfolioValue + hasSignificantTradeSizeValue
    )
  }

  const canSubmit = (): boolean => getAffirmativeAnswers() > 1

  const handleTradeSizeProofOnChange = async (files: FileData[]) => {
    setFieldValue('tradeSizeProofs', [...files])
  }
  const handlePortfolioProofOnChange = async (files: FileData[]) => {
    setFieldValue('portfolioProofs', [...files])
  }

  const handleSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    setSubmitting(true)
    event.preventDefault()
    handleSubmit()
  }
  const getFieldName = (index: number) => {
    switch (index) {
      case 1:
        return 'employerName'
      case 2:
        return 'jobTitle'
      case 3:
        return 'jobDuration'
      default:
        return 'professionalExperienceDetail'
    }
  }
  const getConfirmationFieldName = (index: number) => {
    switch (index) {
      case 0:
        return 'classificationDeclaration1'
      case 1:
        return 'classificationDeclaration2'
      default:
        return 'classificationDeclaration3'
    }
  }

  const mapSectionIndex = (index: any): boolean => {
    switch (index) {
      case 0:
        return significantTradeSize === RadioCodeEnum.SELECTED
      case 1:
        return significantPortfolio === RadioCodeEnum.SELECTED
      default:
        return professionalExperience === RadioCodeEnum.SELECTED
    }
  }
  const mapQuestionName = (name: string): string => {
    switch (name) {
      case 'classification_question_1':
        return 'significantTradeSize'
      case 'classification_question_2':
        return 'significantPortfolio'
      default:
        return 'professionalExperience'
    }
  }
  return (
    <Form onSubmit={handleSubmitForm}>
      <div className={styles.headerWhite}>
        <p className={styles.textStrong}>
          <span className={styles.textGray}>{t('Profile.Current classification status:')}</span>{' '}
          <span className={styles.textSuccess}>{classificationName}</span>
        </p>
      </div>
      <div className={styles.card}>
        <h3>{t('Profile.Application Form to be Classified as a Professional Client')}</h3>
        <p />
        <p className={styles.text}>
          {t('Profile.Please fill in the below form if you wish to apply for')}
        </p>
        <p className={styles.text}>{t('Profile.You may request a different categorisation')}</p>
        <p className={styles.text}>
          {t('Profile.Before you complete the Assessment Form, please read the')}{' '}
          <ExternalLink
            className={styles.link}
            title={t('Client Categorisation Policy')}
            url={clientCategorizationPolicy}
          >
            {' '}
            {t('Profile.Client Categorisation Policy')}
          </ExternalLink>{' '}
          {t(
            'Profile.to understand the rights, protections and loss of protections of being classified as a professional client.'
          )}
        </p>
        <p className={styles.textStrong}>
          {t('Profile.Confirmation of the consequences of changes to your FCA protection', {
            organization: isTickmillEU(account) ? 'CySEC' : 'FCA',
          })}
        </p>
        <p className={styles.text}>{t('Profile.We have highlighted the protections that you')}</p>
      </div>
      <ClassificationText />
      <div className={classNames(styles.card, 'is-flex align-content-center')}>
        <p className={styles.text}>
          {t(
            'Profile.To qualify as a Professional client, you need to meet 2 out of the 3 criteria stipulated below'
          )}
        </p>

        <div className={styles.overviewSection}>
          {testData.sections.map((section, sectionIndex) => {
            const selected = mapSectionIndex(sectionIndex)
            if (sectionIndex > 2) return null
            return (
              <div className={styles.cardWrapper} key={sectionIndex}>
                <OverviewCard
                  title={`${sectionIndex + 1}. ${section.name}`}
                  text={(!!section.questions.length && section.questions[0].question.name) || ''}
                  handelInfoOnClick={
                    isZero(sectionIndex) ? handleTradeSizeModalOpen : handlePortfolioModalOpen
                  }
                  hasIcon={sectionIndex <= 1}
                >
                  {section.questions.map((question, questionIndex) => {
                    const fieldName = mapQuestionName(question.widget.name || '')
                    const error = getIn(errors, fieldName)

                    if (question.widget.type === 'radio') {
                      return (
                        <React.Fragment key={questionIndex}>
                          <div className={classNames('is-flex mb-4')}>
                            {question.answers.map((answer) => {
                              return (
                                <div className='mr-6' key={answer.id}>
                                  <Field
                                    disabled={!isFormEnabled}
                                    className={classNames('radio')}
                                    type='radio'
                                    required={true}
                                    value={answer.code}
                                    name={fieldName}
                                    onChange={async () => {
                                      const filteredArray = selectedAnswers.filter(
                                        (x) => x.question !== question.question.id
                                      )
                                      const newValue = {
                                        question: question.question.id,
                                        answer: answer.id,
                                      }
                                      setFieldValue('selectedAnswers', [...filteredArray, newValue])
                                      setFieldValue(
                                        mapQuestionName(question.widget.name || ''),
                                        answer.code
                                      )
                                    }}
                                  />
                                  <label className={classNames(styles.textSmall, 'pl-2')}>
                                    {answer.name}
                                  </label>
                                </div>
                              )
                            })}
                          </div>

                          {error !== t('Validation.Required') && (
                            <div className='help is-danger'>{error}</div>
                          )}

                          {isFormEnabled && sectionIndex < 2 && (
                            <div
                              className={classNames(
                                {
                                  'is-hidden': !selected,
                                },
                                'mt-4'
                              )}
                            >
                              {
                                <DocumentFileUploads
                                  acceptType={[DocumentFileExtensionType.PDF]}
                                  documentCategory={DocumentCategoryType.Additional}
                                  onChange={
                                    isZero(sectionIndex)
                                      ? handleTradeSizeProofOnChange
                                      : handlePortfolioProofOnChange
                                  }
                                />
                              }
                            </div>
                          )}
                        </React.Fragment>
                      )
                    } else if (isFormEnabled && question.widget.type === 'short_text' && selected) {
                      if (question.question.name.length > 30) {
                        return (
                          <FormTextField
                            as={'textarea'}
                            placeholder={question.question.name}
                            key={question.question.id}
                            name={getFieldName(questionIndex)}
                            onChange={async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
                              const { value } = e.target
                              const filteredArray = freeAnswers.filter(
                                (x) => x.testQuestionId !== question.question.id
                              )
                              const newValue = {
                                testQuestionId: question.question.id,
                                answer: value,
                              }
                              await setFieldValue('freeAnswers', [...filteredArray, newValue])
                              setFieldValue(getFieldName(questionIndex), value)
                            }}
                          />
                        )
                      }
                      return (
                        <div className={'mb-4'} key={questionIndex}>
                          <FormTextField
                            type='text'
                            key={question.question.id}
                            required={question.isMandatory}
                            name={getFieldName(questionIndex)}
                            label={question.question.name}
                            placeholder={question.question.name}
                            onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                              const { value } = e.target
                              const filteredArray = freeAnswers.filter(
                                (x) => x.testQuestionId !== question.question.id
                              )
                              const newValue = {
                                testQuestionId: question.question.id,
                                answer: value,
                              }
                              await setFieldValue('freeAnswers', [...filteredArray, newValue])
                              setFieldValue(getFieldName(questionIndex), value)
                            }}
                          />
                        </div>
                      )
                    } else if (isFormEnabled && question.widget.type === 'text' && selected) {
                      return (
                        <React.Fragment key={questionIndex}>
                          <p className={styles.text}>{question.question.name}</p>
                          <FormTextField
                            className={styles.hasIncreasedHeight}
                            as={'textarea'}
                            placeholder={question.question.name}
                            key={question.question.id}
                            name={getFieldName(questionIndex)}
                            onChange={async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
                              const { value } = e.target
                              const filteredArray = freeAnswers.filter(
                                (x) => x.testQuestionId !== question.question.id
                              )
                              const newValue = {
                                testQuestionId: question.question.id,
                                answer: value,
                              }
                              await setFieldValue('freeAnswers', [...filteredArray, newValue])
                              setFieldValue(getFieldName(questionIndex), value)
                            }}
                          />
                        </React.Fragment>
                      )
                    }
                    return null
                  })}

                  {!isFormEnabled &&
                    professionalExperience === RadioCodeEnum.SELECTED &&
                    testAnswers &&
                    testAnswers?.sections.length > 2 &&
                    testAnswers?.sections[sectionIndex].answers.map((answer) => {
                      if (
                        answer.question.widgetType === 'short_text' ||
                        (answer.question.widgetType === 'text' && answer.answer.name)
                      ) {
                        return (
                          <div key={answer.question.id}>
                            <p className={classNames(styles.textTiny, styles.textGray)}>
                              {answer.question.name}
                            </p>
                            <p className={styles.textStrong}>{answer.answer.name}</p>
                          </div>
                        )
                      }
                      return null
                    })}
                </OverviewCard>
              </div>
            )
          })}
        </div>
        <div className='my-2 pl-4'>
          {!isFormEnabled && (
            <p className={styles.text}>
              {t('Profile.Uploaded documents')}{' '}
              {testAnswers?.documents.map((doc) => doc.name).join(', ') || ''}
            </p>
          )}
        </div>
      </div>

      <div className='mt-5 pl-4'>
        {testData.sections.map((section, sectionIndex) => {
          if (sectionIndex === 3) {
            return section.questions.map((question, questionIndex) => {
              const fieldName = getConfirmationFieldName(questionIndex)
              const error = getIn(errors, fieldName)
              return (
                <div className={styles.confirm} key={question.question.id}>
                  <Field
                    disabled={!isFormEnabled}
                    className={classNames('radio')}
                    type='checkbox'
                    required={true}
                    name={fieldName}
                    onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                      const { checked } = e.target
                      const filteredArray = freeAnswers.filter(
                        (x) => x.testQuestionId !== question.question.id
                      )
                      const newValue = {
                        testQuestionId: question.question.id,
                        answer: checked ? '1' : '0',
                      }
                      setFieldValue('freeAnswers', [...filteredArray, newValue])
                      await setFieldValue(fieldName, checked)
                      setFieldTouched(fieldName)
                    }}
                    checked={getIn(values, fieldName)}
                  />
                  <label className={styles.questionName}>{question.question.name}</label>
                  <p className={styles.questionError}>{error}</p>
                </div>
              )
            })
          }
          return null
        })}
        <div className={classNames(styles.buttonWrapper, 'is-flex is-justify-content-center mt-6')}>
          {isFormEnabled && (
            <Button
              appearance='primary'
              size='L'
              disabled={!canSubmit() || !isValid || isSubmitting}
            >
              {t('Confirm')}
            </Button>
          )}
        </div>
      </div>
    </Form>
  )
}

const findDuplicateFile = (proofs: FileData[], otherProofs: FileData[]) =>
  proofs.filter((firstItem: FileData) => {
    return [...proofs, ...otherProofs].some((secondItem: FileData) => {
      return (
        firstItem.id !== secondItem.id &&
        firstItem?.fileName &&
        secondItem?.fileName &&
        firstItem.fileName === secondItem.fileName &&
        firstItem.fileSize === secondItem.fileSize
      )
    })
  })

export const ProfessionalClassificationTestForm = withFormik<
  OuterProps,
  ProfessionalClassificationTestValues
>({
  mapPropsToValues: ({ testAnswers }) => {
    const answers: TestAnswerDto[] =
      testAnswers?.sections.map((section) => section.answers).flat() || []

    return {
      freeAnswers: [],
      selectedAnswers: [],
      significantTradeSize:
        (!testAnswers?.allowRetake &&
          answers.find((a) => a.question.widgetName === 'classification_question_1')?.answer
            .code) ||
        '',
      significantPortfolio:
        (!testAnswers?.allowRetake &&
          answers.find((a) => a.question.widgetName === 'classification_question_2')?.answer
            .code) ||
        '',
      professionalExperience:
        (!testAnswers?.allowRetake &&
          answers.find((a) => a.question.widgetName === 'classification_question_3')?.answer
            .code) ||
        '',
      portfolioProofs: [],
      tradeSizeProofs: [],
      employerName: '',
      jobTitle: '',
      jobDuration: '',
      professionalExperienceDetail: '',
      classificationDeclaration1:
        !testAnswers?.allowRetake &&
        answers.find((a) => a.question.widgetName === 'classification_declaration_1')?.answer
          .name === RadioCodeEnum.SELECTED,
      classificationDeclaration2:
        !testAnswers?.allowRetake &&
        answers.find((a) => a.question.widgetName === 'classification_declaration_2')?.answer
          .name === RadioCodeEnum.SELECTED,
      classificationDeclaration3:
        !testAnswers?.allowRetake &&
        answers.find((a) => a.question.widgetName === 'classification_declaration_3')?.answer
          .name === RadioCodeEnum.SELECTED,
    }
  },
  validate: (values) => {
    const errors: FormikErrors<ProfessionalClassificationTestValues> = {}

    const tradeSizeProofDuplicates = findDuplicateFile(
      values?.tradeSizeProofs,
      values?.portfolioProofs
    )
    const portfolioProofsDuplicates = findDuplicateFile(
      values?.portfolioProofs,
      values?.tradeSizeProofs
    )

    if (tradeSizeProofDuplicates?.length) {
      errors.significantTradeSize = t('Validation.Error: Document is already uploaded')
    }

    if (portfolioProofsDuplicates?.length) {
      errors.significantPortfolio = t('Validation.Error: Document is already uploaded')
    }

    if (!values.classificationDeclaration1) {
      errors.classificationDeclaration1 = t('Validation.Required')
    }
    if (!values.classificationDeclaration2) {
      errors.classificationDeclaration2 = t('Validation.Required')
    }
    if (!values.classificationDeclaration3) {
      errors.classificationDeclaration3 = t('Validation.Required')
    }
    if (values.significantPortfolio === '') {
      errors.significantPortfolio = t('Validation.Required')
    }
    if (values.significantTradeSize === '') {
      errors.significantTradeSize = t('Validation.Required')
    }
    if (values.professionalExperience === '') {
      errors.professionalExperience = t('Validation.Required')
    }
    if (values.professionalExperience === RadioCodeEnum.SELECTED && !values.employerName) {
      errors.employerName = t('Validation.Employer name is required')
    }
    if (values.professionalExperience === RadioCodeEnum.SELECTED && !values.jobTitle) {
      errors.jobTitle = t('Validation.Job title is required')
    }
    if (values.professionalExperience === RadioCodeEnum.SELECTED && !values.jobDuration) {
      errors.jobDuration = t('Validation.Working period is required')
    }
    if (
      values.significantPortfolio === RadioCodeEnum.SELECTED &&
      (values.portfolioProofs.filter((f) => f.error).length ||
        !values.portfolioProofs.filter((f) => f.fileName).length)
    ) {
      errors.portfolioProofs = t('Validation.Portfolio proof must be uploaded')
    }
    if (
      values.significantTradeSize === RadioCodeEnum.SELECTED &&
      (values.tradeSizeProofs.filter((f) => f.error).length ||
        !values.tradeSizeProofs.filter((f) => f.fileName).length)
    ) {
      errors.tradeSizeProofs = t('Validation.Trade proof must be uploaded')
    }
    return errors
  },
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.handleFormSubmit(values)
    } catch {
      setSubmitting(false)
    }
  },
  enableReinitialize: true,
  isInitialValid: false,
})(ProfessionalClassificationTestFormUI)
