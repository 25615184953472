import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { AppMarketQRCode } from '../global/appMarketLink/desktop/AppMarketQRCode'
import { AppMarketLink } from '../global/appMarketLink/mobile/AppMarketLink'
import { useSessionEntity } from '../global/context/EntityContext'
import { useSessionLanguage } from '../global/context/SessionSettingsContext'
import { Modal } from '../global/modal/Modal'
import { TickmillLogoIcon } from '../icons/TickmillLogoIcon'
import { Text } from '../ui/Typography/Typography'
import { useApiClient } from '../utils/ApiClient'
import { ClientApiClient } from '../utils/clientApi'
import { ForgotPasswordForm, ForgotPasswordFormValues } from './ForgotPasswordForm'
import { PasswordResetConfirmation } from './PasswordResetConfirmation'

import styles from './ForgotPasswordPage.module.scss'

export interface ForgotPasswordPageProps {
  storybook?: boolean
}

export const ForgotPasswordPage: React.FC<ForgotPasswordPageProps> = (props) => {
  const [passwordSentModal, setPasswordSendModal] = useState<{
    visible: boolean
    data?: { email: string; attempts?: number; attemptsLeft: number }
  }>({
    visible: false,
  })
  const { t } = useTranslation()
  const apiClient = useApiClient(ClientApiClient)
  const navigate = useNavigate()
  const locale = useSessionLanguage()
  const entity = useSessionEntity()

  const location = useLocation()

  const onSubmit = async (values: ForgotPasswordFormValues) => {
    await apiClient.resetPassword({
      ...values,
      communicationLanguageId: locale,
    })
    const params = new URLSearchParams()
    if (!props.storybook) params.append('email', values.email)
    const response = await apiClient.getResetPasswordAttempts(params)
    setPasswordSendModal({
      visible: true,
      data: {
        email: values.email,
        attempts: response.attempts,
        attemptsLeft: response.attemptsLeft,
      },
    })
  }
  return (
    <React.Fragment>
      {passwordSentModal.visible && (
        <Modal
          closeModal={() =>
            setPasswordSendModal({
              visible: false,
              data: undefined,
            })
          }
          render={({ closeModal }) => (
            <PasswordResetConfirmation
              onCancel={closeModal}
              email={passwordSentModal.data?.email}
              attemptsLeft={passwordSentModal.data?.attemptsLeft || 0}
              attempts={passwordSentModal.data?.attempts || 0}
              onConfirm={() => {
                setPasswordSendModal({
                  visible: false,
                  data: undefined,
                })
                navigate('/login')
              }}
            />
          )}
        />
      )}
      <div className={styles.topWrapper}>
        <div className={styles.wrapper}>
          <div className={classNames(styles.qrCode, 'is-hidden-mobile')}>
            <AppMarketQRCode />
          </div>
          <div className={styles.inner}>
            <TickmillLogoIcon />
            <h2>{t('Forgot Password.Forgot Password')}</h2>
            <Text className={styles.helpText}>{t('Forgot Password.Sent Link')}</Text>
            <ForgotPasswordForm
              entity={entity}
              onSubmit={onSubmit}
              email={location?.state?.email}
            />
          </div>
        </div>
        <div className='mt-4 is-hidden-desktop is-hidden-tablet'>
          <AppMarketLink />
        </div>
      </div>
    </React.Fragment>
  )
}
