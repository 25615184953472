import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, useFormikContext } from 'formik'

import { ReadAndAgree } from '../../SignUp/PersonalInfo/PersonalInfoStep6Page/DocumentItemFactory'
import { validateIsNumberPositive } from '../../utils/formValidation'
import { LegalDocumentsValues } from './LegalDocumentsForm'

export const DocumentMarketDataClassifications: React.FC = () => {
  const { t } = useTranslation()

  const { values, setFieldValue, validateForm } = useFormikContext<LegalDocumentsValues>()

  useEffect(() => {
    validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.readDocumentMarketData?.marketDataClassificationId])

  const handleMarketDataAgreementAccept = () => {
    setFieldValue('readDocumentMarketData', {
      marketDataClassificationId: 0,
      isDocumentMarketDataRead: true,
      isDocumentMarketDataAccepted: false,
    })
  }

  return (
    <div className='mb-5'>
      <Field
        name='readDocumentMarketData.marketDataClassificationId'
        validate={validateIsNumberPositive(t('Validation.Required'))}
      >
        {() => (
          <ReadAndAgree
            title={t('Sign up.Tickmill UK Ltd. Exchange Market Data Agreement')}
            isAccepted={values.readDocumentMarketData.isDocumentMarketDataAccepted}
            documents={[]}
            onClick={handleMarketDataAgreementAccept}
          />
        )}
      </Field>
    </div>
  )
}
