import React from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Routes, useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'

import { DateFilterContextWrapper } from '../IntroducingBroker/DateFilterContextWrapper'
import { Tabs, defineTab } from '../global/tabs/Tabs'
import { MarketDataHelpPage } from './MarketData/MarketDataHelpPage'
import { MarketDataPage } from './MarketData/MarketDataPage'
import { SubscriptionsHistoryPageETD } from './SubscriptionsHistory/SubscriptionsHistoryPageETD'
import { SubscriptionsTradingPlatformsETDPage } from './SubscriptionsTradingPlatformsETD/SubscriptionsTradingPlatformsETDPage'
import { SubscriptionsTradingPlatformsHelpETDPage } from './SubscriptionsTradingPlatformsETD/SubscriptionsTradingPlatformsHelpETDPage'

import styles from './Subscriptions.module.scss'

export const SubscriptionsETD: React.FC = () => {
  const { t } = useTranslation()
  const { tab } = useParams<{ tab?: string }>()
  const navigate = useNavigate()

  function* getTabs() {
    yield {
      ...defineTab('trading-platforms', t('Subscriptions.Trading Platforms')),
    }
    yield {
      ...defineTab('market-data', t('Market Data.title')),
    }
    yield {
      ...defineTab('history', t('History')),
    }
  }

  const TabsDefinition = [...getTabs()]

  return (
    <div>
      <div className={classNames('section', styles.adminRoutes)}>
        <Tabs
          tabs={TabsDefinition}
          currentTab={tab || ''}
          appearance='light'
          onTabChanged={(tab) => navigate(`/dashboard/subscriptions/${tab}`)}
        />
      </div>
      {tab === 'market-data' && (
        <Routes>
          <Route path='/why-market-data' element={<MarketDataHelpPage />} />
          <Route index element={<MarketDataPage />} />
        </Routes>
      )}
      {tab === 'trading-platforms' && (
        <Routes>
          <Route
            path='/why-trading-platforms'
            element={
              <SubscriptionsTradingPlatformsHelpETDPage
                title={t('Subscriptions.Trading Platforms')}
                description={t(
                  'Subscriptions.A Trading Platform is the software that enables investors and traders'
                )}
              />
            }
          />
          <Route
            path='/why-third-party-trading-platforms'
            element={
              <SubscriptionsTradingPlatformsHelpETDPage
                title={t('Subscriptions.What is a Third Party Trading Platform')}
                description={t(
                  'Subscriptions.Tickmill gives you the possibility to connect your trading account'
                )}
              />
            }
          />
          <Route index element={<SubscriptionsTradingPlatformsETDPage />} />
        </Routes>
      )}
      {tab === 'history' && (
        <DateFilterContextWrapper>
          <SubscriptionsHistoryPageETD />
        </DateFilterContextWrapper>
      )}
    </div>
  )
}
