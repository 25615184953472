import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Loading } from '../../../global/Loading/Loading'
import { Button } from '../../../global/button/Button'
import { useSessionLanguage } from '../../../global/context/SessionSettingsContext'
import { SelectField } from '../../../global/field/SelectField'
import { LandingPageDto } from '../../../model/LandingPageDto'
import { PromoMaterialDto } from '../../../model/PromoMaterialDto'
import { PromoOptionCategoryEnum } from '../../../model/PromoOptionCategoryEnum'
import { LandingPagePromoOptionDto } from '../../../model/PromoOptionDto'
import { PromoOptionTypeEnum } from '../../../model/PromoOptionTypeEnum'
import { useAccountReadContext } from '../../../utils/AccountContextContext'
import { PageData, useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import { isTickmillPartner } from '../../../utils/companyName.utils'
import { getDomainName } from '../../../utils/cookie.utils'
import { copyToClipboard } from '../../../utils/navigator.utils'
import { useFetchOne } from '../../../utils/useFetch'
import { useIBReferralCodes } from '../../../utils/useIBReferralCodes'
import { Header } from '../ReferralParts'

import styles from './PromoMaterialsPage.module.scss'

interface Props {
  promoCategoryType: number
  name: string
}

export const PromoMaterialsPage: React.FC<Props> = ({ promoCategoryType, name }) => {
  const { account } = useAccountReadContext()
  const pageSizes = [10, 25, 50, 75, 100]
  const apiClient = useApiClient(ClientApiClient)
  const [language, setLanguage] = useState('en')
  const [selectedLandingPage, setSelectedLandingPage] = useState<LandingPageDto>()
  const [pageSize, setPageSize] = useState(10)
  const [promoMaterialTypeId, setPromoMaterialTypeId] = useState<string>()
  const [promoMaterialSizeId, setPromoMaterialSizeId] = useState<string>()
  const { t } = useTranslation()
  const locale = useSessionLanguage()
  const [landingPagePromoOptions, setLandingPagePromoOptions] =
    useState<LandingPagePromoOptionDto>()
  const [promoMaterials, setPromoMaterials] = useState<PageData<PromoMaterialDto>>()
  const { isLoading: referralCodesLoading, ibCode, mtCode, referralCodes } = useIBReferralCodes()

  const [referralCode, setReferralCode] = useState(ibCode || mtCode)

  const landingPageCallback = useCallback(async () => {
    return apiClient.getLandingPages({
      promoMaterialCategoryId: promoCategoryType,
      query: {
        pageSize: 100,
        Domain: getDomainName(true),
        search: {
          LanguageId: language,
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])

  const { data: landingPages, isLoading: isLoadingLandingPages } = useFetchOne(landingPageCallback)

  const languagesCallback = useCallback(async () => {
    return apiClient.getClientAreaLanguages({
      promoMaterialCategoryId: promoCategoryType,
      clientAreaVisible: true,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: languages = [], isLoading: isLoadingLanguages } = useFetchOne(languagesCallback)

  useEffect(() => {
    ;(async () => {
      if (!selectedLandingPage) return

      const options = await apiClient.getPromoOptionsByLandingPageAndCategory(
        selectedLandingPage?.id,
        promoCategoryType
      )
      setLandingPagePromoOptions(options)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLandingPage])

  const getSizes = (size: string) => {
    return {
      width: size.split('x')[0],
      height: size.split('x')[1],
    }
  }

  useEffect(() => {
    setSelectedLandingPage(undefined)
    setPromoMaterials(undefined)
  }, [referralCode, language])

  const handleFilterPromoMaterials = async () => {
    if (!selectedLandingPage) return

    const options = await apiClient.getPromoMaterials(selectedLandingPage.id, {
      pageSize: pageSize,
      Domain: getDomainName(true),
      search: {
        PromoMaterialTypeId: promoMaterialTypeId,
        PromoMaterialSizeId: promoMaterialSizeId,
        PromoMaterialCategoryId: promoCategoryType,
        LanguageId: language,
      },
    })
    setPromoMaterials(options)
  }

  const generateUrl = (promoMaterial: PromoMaterialDto) => {
    const params = new URLSearchParams()
    params.set('utm_campaign', 'ib_link')
    params.set('utm_content', `${referralCode || account?.clientIntroducingBroker?.name}`)
    params.set('utm_medium', `${promoMaterial?.name}`)
    params.set('utm_source', 'link')
    params.set('lp', `${selectedLandingPage?.promoMaterialBaseUrl.name}${selectedLandingPage?.url}`)

    if (isTickmillPartner(account)) {
      return `https://secure.tickmill.co.uk/?${params.toString()}`
    }

    return `${window.location.origin}?${params.toString()}`
  }

  const handleCopyToClipboard = (promoMaterial: PromoMaterialDto) => {
    const isHtml5 = promoMaterial.promoMaterialType.id === PromoOptionTypeEnum.HTML5
    const url = generateUrl(promoMaterial)
    let htmlCode: string
    const { width, height } = getSizes(promoMaterial.promoMaterialSize.name)

    if (isHtml5) {
      htmlCode = `<a style="display: inline-block; position: relative; z-index: 1;" href="${url}" target="_blank">
            <object style="pointer-events: none; z-index: -1"
             width="${width}"
             height = "${height}"
             data="${promoMaterial.imagePath}"></object></a>`
    } else {
      htmlCode = `<a href="${url}" target="_blank"><img alt="path" src="${promoMaterial.imagePath}" width="${width}" height="${height}"/></a>`
    }

    copyToClipboard(htmlCode)
  }

  useEffect(() => {
    if (languages.find((x) => x.id === locale)) setLanguage(locale)
    else setLanguage('en')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languages, locale])

  useEffect(() => {
    setReferralCode(ibCode || mtCode)
  }, [ibCode, mtCode])

  return (
    <Loading isLoading={isLoadingLandingPages || isLoadingLanguages || referralCodesLoading}>
      <Header name={name} />
      <div className={styles.selectorWrapper}>
        <SelectField
          className={styles.selector}
          name='language'
          label={t('Referral Materials.Select Landing Page Language')}
          onChange={({ target }) => setLanguage(target.value)}
          value={language}
          options={languages.map(({ id, name }) => ({
            value: id,
            label: name,
          }))}
        />
        <SelectField
          className={styles.selector}
          onChange={({ target }) => setReferralCode(target.value)}
          value={referralCode}
          disabled={referralCodes.length < 2}
          label={t('IB.Your Referral Code')}
          options={referralCodes.map(({ code }) => ({
            value: code,
            label: code,
          }))}
        />
      </div>
      {landingPages && landingPages.items.length > 0 && (
        <>
          <h3>{t('Referral Materials.Select Landing Page')}</h3>
          <div className={styles.landingPages}>
            {landingPages.items.map((page) => (
              <div
                className={classNames(styles.landingPageWrapper)}
                onClick={() => setSelectedLandingPage(page)}
                key={page.id}
              >
                <div
                  className={classNames(styles.page, {
                    [styles.selected]: selectedLandingPage === page,
                  })}
                >
                  <img
                    src={page.imagePath}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null
                      currentTarget.remove()
                    }}
                    alt=''
                  />
                  <p>{page.name}</p>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {selectedLandingPage && (
        <div className={styles.filterWrapper}>
          <h3>
            {t('Referral Materials.Set')} {name}:
          </h3>
          <div className={styles.filter}>
            <div className='column is-3'>
              <SelectField
                name='size'
                placeholder={t('Referral Materials.All Sizes')}
                label={t('Referral Materials.Sizes')}
                allowEmpty
                onChange={({ target }) => setPromoMaterialSizeId(target.value)}
                value={promoMaterialSizeId}
                options={landingPagePromoOptions?.sizes.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
              />
            </div>
            <div className='column is-3'>
              <SelectField
                name='type'
                placeholder={t('Referral Materials.All Types')}
                label={t('Referral Materials.Types')}
                allowEmpty
                onChange={({ target }) => setPromoMaterialTypeId(target.value)}
                value={promoMaterialTypeId}
                options={landingPagePromoOptions?.types.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
              />
            </div>
            <div className='column is-3'>
              <SelectField
                name='pageSize'
                label={t('Referral Materials.Show')}
                onChange={({ target }) => setPageSize(Number(target.value))}
                value={pageSize}
                options={pageSizes?.map((pageSize) => ({
                  value: pageSize,
                  label: pageSize,
                }))}
              />
            </div>
            <div className='column is-3'>
              <Button
                appearance='secondary'
                size='M'
                className='is-fullwidth'
                onClick={handleFilterPromoMaterials}
              >
                {t('Filter')}
              </Button>
            </div>
          </div>
        </div>
      )}
      <div className={styles.promoMaterials}>
        {promoMaterials?.items.map((promo) => {
          const isHtml5 = promo.promoMaterialType.id === PromoOptionTypeEnum.HTML5
          const { width, height } = getSizes(promo.promoMaterialSize.name)

          return (
            <div className={styles.promoMaterialWrapper} key={promo.id}>
              <div
                className={classNames(styles.promoMaterial, {
                  [styles.grayLogo]: promoCategoryType === PromoOptionCategoryEnum.LOGO,
                })}
              >
                {!isHtml5 && <img alt='path' src={promo.imagePath} width={width} height={height} />}
                {isHtml5 && (
                  <iframe
                    title='imagePathFrame'
                    scrolling='no'
                    src={promo.imagePath}
                    width={width}
                    height={height}
                  />
                )}
                <Button
                  appearance='secondary'
                  size='S'
                  onClick={() => handleCopyToClipboard(promo)}
                >
                  {t('Referral Materials.Copy To Clipboard')}
                </Button>
              </div>
            </div>
          )
        })}
      </div>
    </Loading>
  )
}
