import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useFormikContext } from 'formik'

import { AppMarketLink } from '../../../global/appMarketLink/mobile/AppMarketLink'
import { AccountType } from '../../../model/AccountType'
import { TickmillProductType } from '../../../model/TickmillProductType'
import { LeftSection, SignupFormWrapper, SignupStep, SignupWrapper } from '../../SignupPage'
import { FormValuesStep1 } from '../PersonalInfoStep1Page/PersonalInfoDetailsStep1Form'
import { FormValuesStep2 } from '../PersonalInfoStep2Page/PersonalInfoStep2Form'
import { usePersonalInfoTotalSteps } from '../usePersonalInfoTotalSteps'
import { FormValuesStep0 } from './PersonalInfoStepAccountTypePage'

import styles from '../PersonalInfoPage.module.scss'

export interface PersonalInfoTemplateProps {
  step: number
  renderDescription?(): React.ReactNode
  hasQRCodeSection?: boolean
  goBack(): void
  selectedProductIds?: TickmillProductType[]
  reversed?: boolean

  hideTitle?: boolean
}

export type FormValues = FormValuesStep0 & FormValuesStep1

export const PersonalDetailsTemplate: React.FC<PropsWithChildren<PersonalInfoTemplateProps>> = (
  props
) => {
  const {
    step,
    hasQRCodeSection,
    goBack,
    renderDescription,
    children,
    selectedProductIds,
    reversed,
    hideTitle,
  } = props

  const formik = useFormikContext<FormValuesStep2>()
  const totalSteps = usePersonalInfoTotalSteps(formik?.values?.tickmillCompanyId, step)

  const { t } = useTranslation()

  return (
    <SignupFormWrapper
      label={t('Sign up.Sign up')}
      onBackToPreviousStep={goBack}
      selectedProductIds={selectedProductIds}
    >
      <SignupStep
        subStep={{
          current: step,
          total: totalSteps,
        }}
        section={2}
        accountTypeId={AccountType.Individual}
      />
      <SignupWrapper>
        <LeftSection
          hideTitle={hideTitle}
          title={t('Sign up.Personal Details')}
          description={() => (
            <React.Fragment>
              {renderDescription && (
                <React.Fragment>
                  {renderDescription()}
                  <br />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          hasQRCodeSection={hasQRCodeSection || step < 4}
        />

        <section
          className={classNames(styles.formSection, styles.wrapper, {
            [styles.reversed]: reversed,
          })}
        >
          {children}
        </section>
      </SignupWrapper>
      <div className='mt-4 is-hidden-desktop'>
        <AppMarketLink />
      </div>
    </SignupFormWrapper>
  )
}
