import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { useLinksConfig } from '../../configs/configs'
import { useHomePageUrl } from '../../hooks/useHomePageUrl'
import {
  BankTransferPaymentIcon,
  DotPayPaymentIcon,
  IndiaPayPaymentIcon,
  MasterCardPaymentIcon,
  NetellerPaymentIcon,
  PaySafePaymentIcon,
  PaypalPaymentIcon,
  RapidPaymentIcon,
  SkrillPaymentIcon,
  SofortPaymentIcon,
  VisaPaymentIcon,
} from '../../icons/PaymentIcons'
import { isProductRegistered } from '../../model/AccountDetailedDto'
import { TickmillProductType } from '../../model/TickmillProductType'
import { TextTiny } from '../../ui/Typography/Typography'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { TickmillCompaniesEnum, isTickmillUKType } from '../../utils/companyName.utils'
import { useWindowResize } from '../../utils/domUtils'
import { useLocallyPersistedState } from '../../utils/useStorage'
import { ExternalLink } from '../ExternalLink'
import { useSessionEntity } from '../context/EntityContext'
import { useLegalLinksContext } from '../context/LegalLinksContext'
import { useLossPercentagesReadContext } from '../context/LossPercentagesContext'
import { useProductReadContext } from '../context/ProductContext'
import {
  overrideForZHToCNLanguage,
  useArabicSessionLanguage,
  useOverrideLocaleForENorTR,
  useSessionLanguage,
} from '../context/SessionSettingsContext'

import styles from './Footer.module.scss'

const FooterTextPerEntity: React.FC = () => {
  const tickmillCompany = useSessionEntity()
  const year = new Date().getFullYear()
  const { t } = useTranslation()
  const { percentages } = useLossPercentagesReadContext()
  const { links } = useLinksConfig()
  const { account } = useAccountReadContext()
  const { docLinks } = useLegalLinksContext()

  const [selectedProductIds] = useLocallyPersistedState<TickmillProductType[]>(
    'selectedProductIdsKey',
    []
  )

  const isETDSelectedOnSignUp = selectedProductIds.includes(TickmillProductType.ETD)
  const isCFDSelectedOnSignUp = selectedProductIds.includes(TickmillProductType.CFD)
  const isETDRegistered = isProductRegistered(account, TickmillProductType.ETD)
  const isCFDRegistered = isProductRegistered(account, TickmillProductType.CFD)
  const isETDWarningShown =
    (isETDSelectedOnSignUp && !isCFDSelectedOnSignUp) || (isETDRegistered && !isCFDRegistered)
  const isCFDWarningShown =
    (isCFDSelectedOnSignUp && !isETDSelectedOnSignUp) || (isCFDRegistered && !isETDRegistered)

  if (tickmillCompany === TickmillCompaniesEnum.GENERIC) {
    return (
      <Fragment>
        <div className={'level-left'}>
          <TextTiny isParagraph className={classNames('level-item', styles.copyright, styles.text)}>
            {t('Footer.Tickmill is the trading name of Tickmill Group of companies.')}
          </TextTiny>
        </div>
        <br />
        <TextTiny isParagraph className={styles.text}>
          {t('Footer.First Desc AS')}
        </TextTiny>
        <br />
        <TextTiny isParagraph className={styles.text}>
          {t('Footer.Clients')}
        </TextTiny>
        <br />
        <TextTiny isParagraph className={styles.text}>
          <strong>{t('Footer.High Risk Warning')}:</strong>{' '}
          {t(
            'Footer.Trading Contracts for Difference (CFDs) on margin carries a high level of risk'
          )}{' '}
        </TextTiny>
        <br />
        <TextTiny isParagraph className={styles.text}>
          {t('Footer.Desc Generic')}
        </TextTiny>
        <br />
        <TextTiny isParagraph className={styles.text}>
          {t('Footer.Advise Generic')}
        </TextTiny>
        <br />
        <TextTiny isParagraph className={styles.text}>
          {t('Footer.US citizen Generic')}
        </TextTiny>
        <br />
        <TextTiny isParagraph className={styles.text}>
          {t('Footer.Copyright', {
            year,
          })}
        </TextTiny>
      </Fragment>
    )
  }

  if (tickmillCompany === TickmillCompaniesEnum.TICKMILL_SC) {
    return (
      <Fragment>
        <TextTiny isParagraph className={classNames('mt-4', styles.text)}>
          {t('Footer.First Desc SC')}
        </TextTiny>
        <br />
        <TextTiny isParagraph className={styles.text}>
          <strong>{t('Footer.Risk Warning')}:</strong>{' '}
          {t(
            'Footer.Trading Contracts for Difference (CFDs) on margin carries a high level of risk'
          )}{' '}
          {t('Footer.See our')}{' '}
          <FooterLink
            url={docLinks?.riskDisclosure}
            fallback={links.footer.TICKMILL_SC.riskDisclosure}
            label={t('Footer.Risk Disclosure')}
            noDivider
          />
          .
        </TextTiny>
        <br />
        <TextTiny isParagraph className={styles.text}>
          {t('Footer.Desc SC')}
        </TextTiny>
        <br />
        <TextTiny isParagraph className={styles.text}>
          {t('Footer.Copyright', {
            year,
          })}
        </TextTiny>
      </Fragment>
    )
  }

  if (tickmillCompany === TickmillCompaniesEnum.TICKMILL_EU) {
    return (
      <Fragment>
        <TextTiny isParagraph className={classNames('mt-4', styles.text)}>
          {t('Footer.Tickmill Europe Ltd is')}{' '}
          <FooterLink
            url={docLinks?.cysec}
            fallback={links.footer.TICKMILL_EU.CySEC}
            label={'CySEC'}
            noDivider
          />{' '}
          {t('Footer.CIF Licence number 278/15')}
          <br />
          <br />
          <strong>{t('Footer.High Risk Warning')}:</strong>{' '}
          {t('Footer.CFDs are complex instruments and come with a high risk of', {
            risk: percentages?.percentage ?? '',
            entity: 'Tickmill Europe Ltd',
          })}{' '}
          <FooterLink
            url={docLinks?.riskDisclosure}
            fallback={links.footer.TICKMILL_EU.riskDisclosure}
            label={t('Footer.Risk Disclosure')}
            noDivider
          />
          .
        </TextTiny>
        <br />
        <div className='level-left'>
          <div className={classNames('level-item', styles.copyright)}>
            {t('Footer.Copyright EU', {
              year,
            })}
          </div>
        </div>
      </Fragment>
    )
  }

  if (tickmillCompany === TickmillCompaniesEnum.TICKMILL_AS) {
    return (
      <Fragment>
        <TextTiny isParagraph className={classNames('mt-4', styles.text)}>
          <strong>{t('Footer.Risk Warning')}:</strong>{' '}
          {t('Footer.All financial products traded on margin carry')}{' '}
          <FooterLink
            url={docLinks?.riskDisclosure}
            fallback={links.footer.TICKMILL_AS.riskDisclosure}
            label={t('Footer.Risk Disclosure')}
            noDivider
          />
          .
        </TextTiny>
        <br />
        <TextTiny isParagraph className={styles.text}>
          {t('Footer.Second Desc AS')}
        </TextTiny>
        <br />
        <div className='level-left'>
          <div className={classNames('level-item', styles.copyright)}>
            {t('Footer.Copyright', {
              year,
            })}
          </div>
        </div>
      </Fragment>
    )
  }

  if (tickmillCompany === TickmillCompaniesEnum.TICKMILL_PA) {
    return (
      <Fragment>
        <TextTiny isParagraph className={classNames('mt-4', styles.text)}>
          <strong>{t('Footer.Risk Warning')}:</strong>{' '}
          {t('Footer.All financial products traded on margin carry')}{' '}
          <FooterLink
            url={docLinks?.riskDisclosure}
            fallback={links.footer.TICKMILL_PA.riskDisclosure}
            label={t('Footer.Risk Disclosure')}
            noDivider
          />
          .
        </TextTiny>
        <br />
        <TextTiny isParagraph className={styles.text}>
          {t('Footer.Second Desc PA')}
        </TextTiny>
        <br />
        <div className='level-left'>
          <div className={classNames('level-item', styles.copyright)}>
            {t('Footer.Copyright', {
              year,
            })}
          </div>
        </div>
      </Fragment>
    )
  }

  if (tickmillCompany === TickmillCompaniesEnum.TICKMILL_UK && isCFDWarningShown) {
    return (
      <Fragment>
        <TextTiny isParagraph className={classNames('mt-4', styles.text)}>
          {t('Footer.First Desc UK CFD.1')}{' '}
          <FooterLink
            url={docLinks?.financialServicesRegister}
            fallback={links.footer.TICKMILL_UK.financialConductAuthority}
            label={t('Footer.Financial Conduct Authority')}
            noDivider
          />
          {', '}
          {t('Footer.First Desc UK CFD.2')}
        </TextTiny>
        <br />
        <TextTiny isParagraph className={styles.text}>
          <strong>{t('Footer.High Risk Warning')}:</strong>{' '}
          {t('highRiskWarning.CFD', {
            risk: percentages?.percentage ?? '',
            entity: 'Tickmill UK Ltd',
          })}{' '}
          <FooterLink
            url={docLinks?.riskDisclosure}
            fallback={links.footer.TICKMILL_UK.riskDisclosure}
            label={t('Footer.Risk Disclosure')}
            noDivider
          />
          .
        </TextTiny>
        <br />
        <TextTiny isParagraph className={styles.text}>
          {t('Footer.Second Desc UK')}
        </TextTiny>
        <br />
        <div className='level-left'>
          <div className={classNames('level-item', styles.copyright)}>
            {t('Footer.Copyright UK', {
              year,
            })}
          </div>
        </div>
      </Fragment>
    )
  }

  if (tickmillCompany === TickmillCompaniesEnum.TICKMILL_UK && isETDWarningShown) {
    return (
      <Fragment>
        <TextTiny isParagraph className={classNames('mt-4', styles.text)}>
          {t('Footer.First Desc UK CFD.1')}{' '}
          <FooterLink
            url={docLinks?.financialConductAuthority}
            fallback={links.footer.TICKMILL_UK_ETD.financialConductAuthority}
            label={t('Footer.UK Financial Conduct Authority')}
            noDivider
          />
          {', '}
          {t('Footer.First Desc UK CFD.2')}
        </TextTiny>
        <br />
        <TextTiny isParagraph className={styles.text}>
          <strong>{t('Footer.High Risk Warning')}:</strong>{' '}
          {t('highRiskWarning.ETD', {
            risk: percentages?.percentage ?? '',
            entity: 'Tickmill UK Ltd',
          })}{' '}
          <FooterLink
            url={docLinks?.riskDisclosure}
            fallback={links.footer.TICKMILL_UK_ETD.riskDisclosure}
            label={t('Footer.Risk Disclosure')}
            noDivider
          />{' '}
          {t('highRiskWarning.document')}.
        </TextTiny>
        <br />
        <TextTiny isParagraph className={styles.text}>
          {t('Footer.Second Desc UK')}
        </TextTiny>
        <br />
        <div className='level-left'>
          <div className={classNames('level-item', styles.copyright)}>
            {t('Footer.Copyright UK', {
              year,
            })}
          </div>
        </div>
      </Fragment>
    )
  }

  if (tickmillCompany === TickmillCompaniesEnum.TICKMILL_UK) {
    return (
      <Fragment>
        <TextTiny isParagraph className={classNames('mt-4', styles.text)}>
          {t('Footer.First Desc UK CFD.1')}{' '}
          <FooterLink
            url={docLinks?.financialServicesRegister}
            fallback={links.footer.TICKMILL_UK.financialConductAuthority}
            label={t('Footer.Financial Conduct Authority')}
            noDivider
          />
          {', '}
          {t('Footer.First Desc UK CFD.2')}
        </TextTiny>
        <br />
        <TextTiny isParagraph className={styles.text}>
          <strong>{t('Footer.High Risk Warning')}:</strong>{' '}
          {t('highRiskWarning.CFD', {
            risk: percentages?.percentage ?? '',
            entity: 'Tickmill UK Ltd',
          })}{' '}
          <FooterLink
            url={docLinks?.riskDisclosure}
            fallback={links.footer.TICKMILL_UK.riskDisclosure}
            label={t('Footer.Risk Disclosure')}
            noDivider
          />
          .
          <br />
          <br />
          {t('highRiskWarning.ETD', {
            risk: percentages?.percentage ?? '',
            entity: 'Tickmill UK Ltd',
          })}{' '}
          <FooterLink
            url={docLinks?.riskDisclosure}
            fallback={links.footer.TICKMILL_UK_ETD.riskDisclosure}
            label={t('Footer.Risk Disclosure')}
            noDivider
          />{' '}
          {t('highRiskWarning.document')}.
        </TextTiny>
        <br />
        <TextTiny isParagraph className={styles.text}>
          {t('Footer.Second Desc UK')}
        </TextTiny>
        <br />
        <div className='level-left'>
          <div className={classNames('level-item', styles.copyright)}>
            {t('Footer.Copyright UK', {
              year,
            })}
          </div>
        </div>
      </Fragment>
    )
  }

  return null
}

const AppLinksPerEntity: React.FC = () => {
  const { t } = useTranslation()
  const tickmillCompany = useSessionEntity()
  const { isDefaultCFDProductType } = useProductReadContext()
  const isProductTypeCFD = isDefaultCFDProductType()
  const { links } = useLinksConfig()
  const year = new Date().getFullYear()
  const locale = useSessionLanguage()
  const zhToCNLanguage = overrideForZHToCNLanguage(locale)
  const isLocaleENorTR = useOverrideLocaleForENorTR(locale)
  const homePageUrl = useHomePageUrl()
  const { docLinks } = useLegalLinksContext()
  if (tickmillCompany === TickmillCompaniesEnum.GENERIC) {
    return <FooterTextPerEntity />
  }
  if (tickmillCompany === TickmillCompaniesEnum.TICKMILL_SC) {
    return (
      <Fragment>
        <div className={classNames('level-left')}>
          <FooterLink
            url={docLinks?.websiteTermsAndConditions}
            fallback={homePageUrl + '/' + zhToCNLanguage + '/terms-conditions/tre'}
            label={t('Footer.Website Terms & Conditions')}
          />
          <FooterLink
            url={docLinks?.termsOfBusinessWebsite}
            fallback={links.footer.TICKMILL_SC.termsOfBusiness}
            label={t('Terms of Business')}
          />
          <FooterLink
            url={docLinks?.riskDisclosure}
            fallback={links.footer.TICKMILL_SC.riskDisclosure}
            label={t('Footer.Risk Disclosure')}
            isLast
          />
        </div>
        <FooterTextPerEntity />
      </Fragment>
    )
  }
  if (tickmillCompany === TickmillCompaniesEnum.TICKMILL_EU) {
    const termsAndConditionsLink = isLocaleENorTR
      ? `${homePageUrl}/terms-conditions`
      : `${homePageUrl}/${overrideForZHToCNLanguage(locale)}/terms-conditions`

    return (
      <Fragment>
        <div className='level-left'>
          <FooterLink
            url={docLinks?.websiteTermsAndConditions}
            fallback={termsAndConditionsLink}
            label={t('Footer.Website Terms & Conditions')}
          />
          <FooterLink
            url={docLinks?.clientAgreement}
            fallback={links.footer.TICKMILL_EU.clientAgreement}
            label={t('Footer.Client Agreement')}
          />
          <FooterLink url={docLinks?.privacyPolicy} label={t('Footer.Privacy Policy')} />
          <FooterLink
            url={docLinks?.riskDisclosure}
            fallback={links.footer.TICKMILL_EU.riskDisclosure}
            label={t('Footer.Risk Disclosure')}
          />
          <FooterLink
            fallback={links.footer.TICKMILL_EU.CySEC}
            url={docLinks?.cysec}
            label='CySEC'
            isLast
          />
        </div>
        <FooterTextPerEntity />
      </Fragment>
    )
  }
  if (tickmillCompany === TickmillCompaniesEnum.TICKMILL_AS) {
    return (
      <Fragment>
        <div className='level-left'>
          <div className={classNames('level-item', 'is-hidden-touch', styles.copyright)}>
            {t('Footer.Copyright', {
              year,
            })}
          </div>
        </div>
        <div className='level-left'>
          <FooterLink
            url={docLinks?.websiteTermsAndConditions}
            fallback={homePageUrl + '/' + zhToCNLanguage + '/terms-conditions/'}
            label={t('Footer.Website Terms & Conditions')}
          />
          <FooterLink
            url={docLinks?.termsOfBusinessWebsite}
            fallback={links.footer.TICKMILL_AS.termsOfBusiness}
            label={t('Terms of Business')}
          />
          <FooterLink
            url={docLinks?.riskDisclosure}
            fallback={links.footer.TICKMILL_AS.riskDisclosure}
            label={t('Footer.Risk Disclosure')}
            isLast
          />
        </div>
        <div className={styles.text}>{t('Footer.First Desc AS')}</div>
        <br />
        <FooterTextPerEntity />
      </Fragment>
    )
  }
  if (tickmillCompany === TickmillCompaniesEnum.TICKMILL_PA) {
    return (
      <Fragment>
        <div className='level-left'>
          <FooterLink
            url={docLinks?.websiteTermsAndConditions}
            fallback={homePageUrl + '/' + zhToCNLanguage + '/terms-conditions/'}
            label={t('Footer.Website Terms & Conditions')}
          />
          <FooterLink
            url={docLinks?.termsOfBusinessWebsite}
            fallback={links.footer.TICKMILL_PA.termsOfBusiness}
            label={t('Terms of Business')}
          />
          <FooterLink
            url={docLinks?.riskDisclosure}
            fallback={links.footer.TICKMILL_PA.riskDisclosure}
            label={t('Footer.Risk Disclosure')}
            isLast
          />
        </div>
        <TextTiny isParagraph className={styles.text}>
          {t('Footer.First Desc PA')}
        </TextTiny>
        <br />
        <FooterTextPerEntity />
      </Fragment>
    )
  }
  // TM UK
  return (
    <>
      {isProductTypeCFD ? (
        // CFD Footer
        <Fragment>
          <div className='level-left'>
            <FooterLink
              url={docLinks?.websiteTermsAndConditions}
              fallback={homePageUrl + '/' + zhToCNLanguage + '/terms-conditions/'}
              label={t('Footer.Website Terms & Conditions')}
            />
            <FooterLink
              url={docLinks?.termsOfBusinessWebsite}
              fallback={links.footer.TICKMILL_UK.termsOfBusiness}
              label={t('Terms of Business')}
            />
            <FooterLink
              url={docLinks?.riskDisclosure}
              fallback={links.footer.TICKMILL_UK.riskDisclosureAppLinks}
              label={t('Footer.Risk Disclosure')}
            />
            <FooterLink
              url={docLinks?.bestExecutionPolicy}
              fallback={links.footer.TICKMILL_UK.bestExecutionPolicy}
              label={t('Footer.Best Execution Policy')}
            />
            <FooterLink
              url={docLinks?.financialConductAuthority}
              fallback={links.footer.TICKMILL_UK.UKFinancialConductAuthority}
              label={t('Footer.UK Financial Conduct Authority')}
              isLast
            />
          </div>
          <FooterTextPerEntity />
        </Fragment>
      ) : (
        // ETD Footer
        <Fragment>
          <div className='level-left'>
            <FooterLink
              url={docLinks?.websiteTermsAndConditions}
              fallback={homePageUrl + '/' + zhToCNLanguage + '/terms-conditions/'}
              label={t('Footer.Website Terms & Conditions')}
            />
            <FooterLink
              url={docLinks?.termsOfBusinessWebsite}
              fallback={links.footer.TICKMILL_UK_ETD.termsOfBusiness}
              label={t('Terms of Business')}
            />
            <FooterLink
              url={docLinks?.riskDisclosure}
              fallback={links.footer.TICKMILL_UK_ETD.riskDisclosure}
              label={t('Footer.Risk Disclosure')}
            />
            <FooterLink
              url={docLinks?.bestExecutionPolicy}
              fallback={links.footer.TICKMILL_UK_ETD.bestExecutionPolicy}
              label={t('Footer.Best Execution Policy')}
              isLast
            />
          </div>
          <FooterTextPerEntity />
        </Fragment>
      )}
    </>
  )
}

const FooterLink: React.FC<{
  url?: string | null
  fallback?: string
  label: string
  isLast?: boolean
  noDivider?: boolean
}> = ({ url, label, isLast, fallback, noDivider }) => {
  const isMobile = useWindowResize()
  if (!url && !fallback) return null
  if (!url) {
    // Enable if you need to debug missing URLs
    // console.log('Fallback URL is beeing used for footer links for: ' + label, { url, fallback })
  }
  return (
    <>
      <ExternalLink url={url || fallback} className='is-link'>
        {label}
      </ExternalLink>
      {!noDivider && (
        <>
          {isMobile && <br />}
          {isMobile && <br />}
          {isLast ? null : <>{!isMobile && <span className={styles.divider}>|</span>}</>}
        </>
      )}
    </>
  )
}

const PaymentProviderLogoPerEntity = () => {
  const tickmillEntity = useSessionEntity()
  if (tickmillEntity === TickmillCompaniesEnum.TICKMILL_EU) {
    return (
      <Fragment>
        <BankTransferPaymentIcon />
        <VisaPaymentIcon />
        <MasterCardPaymentIcon />
        <NetellerPaymentIcon />
        <DotPayPaymentIcon />
        <SkrillPaymentIcon />
      </Fragment>
    )
  }
  if (tickmillEntity === TickmillCompaniesEnum.TICKMILL_AS) {
    // thailand internet banking
    return (
      <Fragment>
        <BankTransferPaymentIcon />
      </Fragment>
    )
  }
  if (tickmillEntity === TickmillCompaniesEnum.TICKMILL_SC) {
    // fasa pay
    // union pay
    // ngan luong
    return (
      <Fragment>
        <BankTransferPaymentIcon />
        <VisaPaymentIcon />
        <MasterCardPaymentIcon />
        <DotPayPaymentIcon />
        <NetellerPaymentIcon />
        <SkrillPaymentIcon />
        <PaySafePaymentIcon />
        <SofortPaymentIcon />
        <RapidPaymentIcon />
      </Fragment>
    )
  }
  if (tickmillEntity === TickmillCompaniesEnum.TICKMILL_PA) {
    return (
      <Fragment>
        <BankTransferPaymentIcon />
        <NetellerPaymentIcon />
        <SkrillPaymentIcon />
      </Fragment>
    )
  }
  if (tickmillEntity === TickmillCompaniesEnum.TICKMILL_UK) {
    return (
      <Fragment>
        <BankTransferPaymentIcon />
        <VisaPaymentIcon />
        <MasterCardPaymentIcon />
        <DotPayPaymentIcon />
        <NetellerPaymentIcon />
        <SkrillPaymentIcon />
        <PaySafePaymentIcon />
        <SofortPaymentIcon />
        <RapidPaymentIcon />
      </Fragment>
    )
  }
  if (tickmillEntity === TickmillCompaniesEnum.GENERIC) {
    return null
  }
  return (
    <Fragment>
      <BankTransferPaymentIcon />
      <VisaPaymentIcon />
      <MasterCardPaymentIcon />
      <DotPayPaymentIcon />
      <NetellerPaymentIcon />
      <SkrillPaymentIcon />
      <PaySafePaymentIcon />
      <SofortPaymentIcon />
      <RapidPaymentIcon />
      <PaypalPaymentIcon />
      <IndiaPayPaymentIcon />
    </Fragment>
  )
}

export const SignupFooter: React.FC = () => {
  const entity = useSessionEntity()
  const isArabic = useArabicSessionLanguage()
  return (
    <footer
      dir={isArabic ? 'rtl' : ''}
      className={classNames('footer', 'level', styles.signupFooter, styles.appFooter)}
      role='navigation'
      id='appFooter'
      aria-label='footer navigation'
    >
      {!isTickmillUKType(entity) && (
        <>
          <div className={styles.wrapper}>
            <div className='level-item'>
              <PaymentProviderLogoPerEntity />
            </div>
          </div>
        </>
      )}
      <RenderAppLinks />
    </footer>
  )
}

const RenderAppLinks: React.FC = () => {
  const entity = useSessionEntity()
  return (
    <div
      className={classNames('container', !isTickmillUKType(entity) ? 'pt-0' : '', styles.wrapper)}
    >
      <AppLinksPerEntity />
    </div>
  )
}

export const AppFooter: React.FC = () => {
  const isArabic = useArabicSessionLanguage()
  const location = useLocation()

  if (location.pathname.includes('/sign-up')) return <SignupFooter />

  return (
    <footer
      dir={isArabic ? 'rtl' : ''}
      id='appFooter'
      className={classNames('footer', 'level', styles.appFooter)}
      role='navigation'
      aria-label='footer navigation'
    >
      <div className={classNames('container', styles.wrapper)}>
        <AppLinksPerEntity />
      </div>
    </footer>
  )
}
