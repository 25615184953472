import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { v4 as uuid } from 'uuid'

import { useWindowResize } from '../../utils/domUtils'
import { FileData, FileUpload } from './FileUpload'
import { FileExtension, getFileExtensions } from './types'

import styles from './FileUploads.module.scss'

export interface FileUploadsProps {
  title?: string | string[]
  files?: FileData[]
  isLoading?: boolean
  acceptType?: FileExtension[]
  maxFiles?: number
  maxPlaceholders?: number
  disabled?: boolean

  documentCategory: number

  onChange(files: FileData[], file?: FileData): void

  onUpload?(files: FileData[], file?: FileData): void

  onRemove?(files: FileData[], file?: FileData): void

  onBeforeRemove?(file: FileData): void

  buttonText?: string

  needRefresh?: boolean
}

const initialState = (files: FileData[], maxPlaceholders?: number): FileData[] => {
  return files.length > 0 ? files : initialPlaceholdersState(maxPlaceholders)
}

const initialPlaceholdersState = (maxPlaceholders: number = 1): FileData[] => {
  return Array.from({ length: maxPlaceholders }).map(() => initialPlaceholderFile())
}

export const FileUploads: React.FC<FileUploadsProps> = (props) => {
  const { t } = useTranslation()
  const {
    isLoading = false,
    acceptType = getFileExtensions(),
    maxFiles = 20,
    maxPlaceholders = 1,
    disabled = false,
    onChange,
    onUpload,
    onRemove,
    onBeforeRemove,
    title,
    files = [],
    buttonText,
    needRefresh,
    documentCategory,
  } = props

  const [addedFiles, setAddedFiles] = useState<FileData[]>(initialState(files, maxPlaceholders))

  const isMobile = useWindowResize()

  useEffect(() => {
    setAddedFiles(initialState(files, maxPlaceholders))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needRefresh])

  useEffect(() => {
    if (files) setAddedFiles(initialState(files, maxPlaceholders))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(files)])

  const handleAddMore = () => {
    setAddedFiles(addedFiles.concat(initialPlaceholderFile()))
  }

  const handleUpload = async (file: FileData) => {
    const randomNum = Math.floor(Math.random() * 9) + 1
    const isFileNameDuplicated = isFileDuplicated(addedFiles, file, true, true)
    const data: FileData[] = addedFiles.map((x) =>
      x.id === file.id
        ? isMobile && isFileNameDuplicated
          ? {
              ...x,
              ...file,
              fileName: randomNum + '_' + file.fileName,
            }
          : { ...x, ...file }
        : x
    )
    setAddedFiles(data)
    onUpload?.(data, file)
    onChange(data, file)
  }

  const handleRemove = (file: FileData) => {
    const data: FileData[] = removeUploadedFile(addedFiles, file)
    setAddedFiles(data)
    onRemove?.(data, file)
    onChange(data, file)
  }

  const onRemovePlaceholder = (file: FileData) => {
    if (addedFiles.length > 1) {
      const newPlaceholderFile = initialPlaceholderFile()
      const data: FileData[] = addedFiles
        .map((x) => (x.id === file.id ? newPlaceholderFile : x))
        .filter((x) => x.id !== newPlaceholderFile.id)
      setAddedFiles(data)
    }
  }

  const getTitle = (index: number): string => {
    if (Array.isArray(title)) {
      return title[index]
    }

    if (typeof title === 'string') {
      return title
    }

    return ''
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.fileUploads}>
        {addedFiles.map((x, index) => {
          return (
            <div className={styles.fileUploadItem} key={index}>
              <FileUpload
                file={x}
                acceptType={acceptType}
                documentCategory={documentCategory}
                onUpload={handleUpload}
                onRemove={handleRemove}
                onBeforeRemove={onBeforeRemove}
                onRemovePlaceholder={onRemovePlaceholder}
                buttonText={buttonText}
                disabled={disabled}
                isLoading={isLoading}
                title={getTitle(index)}
              />
            </div>
          )
        })}
      </div>
      {!isMaxFileSize(maxFiles, addedFiles.length) && (
        <button
          type='button'
          onClick={handleAddMore}
          className={classNames('is-link', styles.addMore)}
        >
          + {t('Upload additional proof')}
        </button>
      )}
    </div>
  )
}

export const isFileDuplicated = (
  files: FileData[],
  file: FileData,
  checkByName: boolean = false,
  immediateReturn: boolean = false
) => {
  const isDuplicate = (x: FileData) => {
    if (checkByName) {
      return (
        x.fileName === file.fileName &&
        (x.fileSize !== file.fileSize || x.base64Content !== file.base64Content)
      )
    } else {
      return (
        x.fileName === file.fileName &&
        x.fileSize === file.fileSize &&
        x.base64Content === file.base64Content
      )
    }
  }
  return immediateReturn ? files.some(isDuplicate) : files.filter(isDuplicate).length >= 2
}

export const removeUploadedFile = (addedFiles: FileData[], file?: FileData) => {
  return addedFiles.map((x) => (x.id === file?.id ? initialPlaceholderFile() : x))
}

export const initialPlaceholderFile = (): FileData => {
  return {
    id: uuid(),
    fileName: '',
    fileType: '',
    fileSize: 0,
    fileSizeBytes: 0,
    fileSizeKb: '0',
    fileSizeMb: '0',
    base64Content: '',
    data: new File([''], ''),
    createdAt: new Date().toISOString(),
    error: false,
  }
}

const isMaxFileSize = (maxFiles: number, size: number) => {
  return size >= maxFiles
}

export default FileUploads
