import ReactCountryFlag from 'react-country-flag'
import { motion } from 'framer-motion'

import { useSessionSettings } from '../context/SessionSettingsContext'
import { Locale, localeFlagMap, useLabeledLocales } from '../locale/Locale'

import styles from './LocaleSelector.module.scss'
import 'react-dropdown/style.css'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { useDisableMainScroll } from '../../hooks/useDisableMainScroll'
import { SortDownIcon } from '../../icons/SortDownIcon'
import { isTickmillProductTypeETD } from '../../model/TickmillProductType'
import { TextSmallStrong } from '../../ui/Typography/Typography'
import { useWindowResize } from '../../utils/domUtils'
import { isSignUpPath } from '../../utils/path'
import { useKeyPressed } from '../../utils/useKeyPress'
import { useOnClickedOutside } from '../../utils/useOnClickedOutside'
import { useLocallyPersistedState } from '../../utils/useStorage'
import { useProductReadContext } from '../context/ProductContext'

const CountryFlag = ({ locale }: { locale?: Locale }) =>
  locale && (
    <ReactCountryFlag className={styles.countryFlag} countryCode={localeFlagMap[locale]} svg />
  )

const getAnimationVariants = () => ({
  arrow: {
    open: { rotate: 180 },
    closed: { rotate: 0 },
  },
})

export const languageSelectorKey = 'languageSelector'

export const LocaleSelector: React.FC = () => {
  const { enableMainScroll, disableMainScroll } = useDisableMainScroll()
  const [settings] = useSessionSettings()
  const isMobile = useWindowResize()
  const [showVariants, setShowVariants] = useLocallyPersistedState<boolean>(
    languageSelectorKey,
    false
  )

  const hideVariants = () => {
    enableMainScroll()
    setShowVariants(false)
  }

  const toggleVariants = () => {
    if (showVariants) enableMainScroll()
    else if (isMobile) disableMainScroll()
    setShowVariants(!showVariants)
  }

  useKeyPressed('Escape', hideVariants)

  const onClickOutside = useOnClickedOutside<HTMLDivElement>(showVariants && toggleVariants)

  return (
    <div className={styles.localeSelector} ref={onClickOutside}>
      <CurrentLanguage isOpen={showVariants} onClick={toggleVariants} lang={settings.locale} />
    </div>
  )
}

interface CurrentLanguageProps {
  lang: Locale
  onClick(): void
  isOpen?: boolean
}

const CurrentLanguage: React.FC<CurrentLanguageProps> = (props) => {
  const { lang, onClick, isOpen } = props
  const labeledLocales = useLabeledLocales()
  const { product } = useProductReadContext()

  const location = useLocation()
  const isSignUp = isSignUpPath(location.pathname)

  const isProductTypeETD = isTickmillProductTypeETD(product) && !isSignUp

  const language = labeledLocales.find((l) => l.value === lang)

  return (
    <div onClick={onClick} className={styles.selectedLanguage}>
      <div
        className={classNames(styles.labelBox, {
          [styles.isETDSelected]: isProductTypeETD,
        })}
      >
        <CountryFlag locale={language?.value} />
        <div className={styles.languageArrowBox}>
          <TextSmallStrong>{language?.label}</TextSmallStrong>
          <motion.div
            transition={{ duration: 0.2 }}
            variants={getAnimationVariants().arrow}
            animate={isOpen ? 'open' : 'closed'}
            className={styles.arrow}
          >
            <SortDownIcon size={24} color={isProductTypeETD ? 'alwaysWhite' : 'text'} />
          </motion.div>
        </div>
      </div>
    </div>
  )
}
