import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { InfoModal } from '../../global/modal/InfoModal'
import { Modal } from '../../global/modal/Modal'
import { InfoIcon } from '../../icons/InfoIcon'
import { NotificationPreferences } from '../../model/Notification'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useNotificationUnread } from '../../utils/notifications'
import { useFetchOne } from '../../utils/useFetch'
import { initialNotificationPreferences, notificationOptions } from './SettingConsts'

import styles from './AccountSettingsPage.module.scss'

interface NotificationModalPropos {
  title: string
  text: string
}

export const NotificationPreferencesBlock: React.FC = () => {
  const { t } = useTranslation()
  const apiClient = useApiClient(ClientApiClient)
  const { account } = useAccountReadContext()
  const [loading, setLoading] = useState(false)
  const [notificationModal, setNotificationModal] = useState<NotificationModalPropos>()
  const { refreshAllNotifications } = useNotificationUnread()
  const [preferences, setPreferences] = useState<NotificationPreferences>({
    ...initialNotificationPreferences,
    clientId: account?.id,
  })

  const notificationPreferencesCallback = useCallback(async () => {
    return apiClient.getNotificationPreferences()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: notificationPreferencesFetch } = useFetchOne(notificationPreferencesCallback)

  useEffect(() => {
    if (notificationPreferencesFetch) setPreferences(notificationPreferencesFetch)
  }, [notificationPreferencesFetch])

  const updateNotificationPreferences = async (
    id: keyof NotificationPreferences,
    value: boolean
  ) => {
    setLoading(true)
    if (!preferences) return
    if (preferences[id] === value) return
    const newPreferences = {
      ...preferences,
      [id]: value,
    }
    setPreferences((prev) => ({
      ...prev,
      [id]: value,
    }))
    await apiClient.updateNotificationPreferences(newPreferences)
    refreshAllNotifications()
    setLoading(false)
  }

  return (
    <div className={styles.notifications}>
      {!!notificationModal && (
        <Modal
          closeModal={() => setNotificationModal(undefined)}
          render={({ closeModal }) => (
            <InfoModal
              onCancel={closeModal}
              title={notificationModal.title}
              renderFooter={() => (
                <React.Fragment>
                  <button
                    className='button'
                    data-test='account-settings-notification-modal-close'
                    onClick={closeModal}
                    type='button'
                  >
                    {t('Got It')}
                  </button>
                </React.Fragment>
              )}
              renderBody={() => (
                <section className={'modal-card-body'}>
                  <p className={styles.text}>{notificationModal.text}</p>
                </section>
              )}
              onConfirm={closeModal}
            />
          )}
        />
      )}
      <table>
        <thead className={styles.header}>
          <tr>
            <th />
            <th>
              <p className={styles.label}>
                {t('Notifications.Settings.Silent')}
                <span
                  data-test='account-settings-notification-silent-info'
                  className={classNames('ml-1', styles.infoIcon)}
                  onClick={() =>
                    setNotificationModal({
                      title: t('Notifications.Settings.Silent Notification'),
                      text: t('Notifications.Settings.Silent notification does not interrupt you'),
                    })
                  }
                >
                  <InfoIcon />
                </span>
              </p>
            </th>
            <th>
              <p className={styles.label}>
                {t('Notifications.Settings.Regular')}
                <span
                  data-test='account-settings-notification-silent-info'
                  className={classNames('ml-1', styles.infoIcon)}
                  onClick={() =>
                    setNotificationModal({
                      title: t('Notifications.Settings.Regular Notification'),
                      text: t('Notifications.Settings.Regular notifications are'),
                    })
                  }
                >
                  <InfoIcon />
                </span>
              </p>
            </th>
          </tr>
        </thead>
        <tbody>
          {notificationOptions(t, preferences).map((notificationOption) => (
            <tr className={styles.notificationItem}>
              <td className={styles.name}>
                {notificationOption.name}
                <br />
                <span className={styles.info}>{notificationOption.label}</span>
              </td>
              <td className={styles.options}>
                <div className={classNames('mt-4', styles.optionWrap)}>
                  <input
                    disabled={loading}
                    type='radio'
                    className={styles.option}
                    checked={!!notificationOption.value}
                    data-test={`account-settings-notification-${notificationOption.id}-silent`}
                    onClick={() => updateNotificationPreferences(notificationOption.id, true)}
                    name={notificationOption.id}
                  />
                </div>
              </td>
              <td className={styles.options}>
                <div className={classNames('mt-4', styles.optionWrap)}>
                  <input
                    type='radio'
                    className={styles.option}
                    checked={!notificationOption.value}
                    data-test={`account-settings-notification-${notificationOption.id}-regular`}
                    onClick={() => updateNotificationPreferences(notificationOption.id, false)}
                    name={notificationOption.id}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
