import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { useSnackbar } from '../../global/context/SnackbarContext'
import { Text } from '../../ui/Typography/Typography'
import { copyToClipboard } from '../../utils/navigator.utils'
import { useIBLink } from '../../utils/useIBLink'

import styles from './HowIBWorksModal.module.scss'

interface Props {
  ibCode?: string
  onCancel: () => void
}

export const HowMultiTierWorksModal: React.FC<Props> = ({ onCancel, ibCode }) => {
  const { t } = useTranslation()
  const { addSnackbar } = useSnackbar()

  const ibLink = useIBLink(ibCode)

  return (
    <Fragment>
      <header className={classNames('modal-card-head', styles.header)}>
        <p className='modal-card-title'>{t('IB.MultiTier.How Multi-tier works')}?</p>
      </header>
      <section className={classNames('modal-card-body', styles.body)}>
        <div>
          <h3>1.</h3>
          <Text>
            {ibCode && (
              <span
                className='is-link'
                onClick={() => {
                  copyToClipboard(ibLink)
                  addSnackbar.success({ message: t('Copied to Clipboard!') })
                }}
              >
                {t('Profile.Copy your URL')}
              </span>
            )}
            {!ibCode && <span>{t('Profile.Copy your URL')}</span>}{' '}
            {t(
              'Profile.and share it with your network. Every trader who registers with Tickmill via your link is automatically counted as your invitee.  '
            )}
          </Text>
        </div>
        <div>
          <h3>2.</h3>
          <Text>
            {t('Profile.Pass your unique Referral Code')}{' '}
            <span
              onClick={() => {
                copyToClipboard(ibCode)
                addSnackbar.success({ message: t('Copied to Clipboard!') })
              }}
              className='is-link has-cursor-pointer'
            >
              {ibCode}
            </span>{' '}
            {t(
              'Profile.to your invitee and ask them to submit it when filling in their personal details during registration.'
            )}
          </Text>
        </div>
        <div>
          <h3>3.</h3>
          <Text>
            {t(
              'Profile.Recommend your clients open an MT5 or Tickmill Trader Raw accounts with us and they’ll get a 5% discount on their commissions'
            )}
          </Text>
        </div>
      </section>
      <footer className={classNames('modal-card-foot', styles.footer)}>
        <button className='button' onClick={onCancel} type='button'>
          {t('Got It')}
        </button>
      </footer>
    </Fragment>
  )
}
