import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../global/button/Button'
import { CheckIcon } from '../icons/CheckIcon'

import styles from './Statement.module.scss'

interface StatementProps {
  goBack: () => void
}

export const DownloadedStatement: React.FC<StatementProps> = ({ goBack }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.statement}>
      <div className={styles.reportIconWrapper}>
        <CheckIcon size={64} color='success' />
      </div>
      <h3 className={styles.title}>{t('Statements.Statement successfully downloaded')}</h3>
      <Button className={styles.button} appearance='primary' size='L' onClick={goBack}>
        {t('Statements.Download another statement')}
      </Button>
    </div>
  )
}
