import React, { useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import { Loading } from '../../global/Loading/Loading'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { NameDto } from '../../model/NameDto'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { getTickmillCompanyByHostname } from '../../utils/companyName.utils'
import { getLocationCountry } from '../../utils/geolocation'
import { useFetchOne } from '../../utils/useFetch'
import { CompanyDetailsStep2Form, CompanyDetailsStep2FormValues } from './CompanyDetailsStep2Form'

interface OuterProps {
  legalBases: NameDto[]
  ownershipStructures: NameDto[]
  initialValues: CompanyDetailsStep2FormValues
  onSubmit(values: CompanyDetailsStep2FormValues): void
}

export const CompanyDetailsStep2Page: React.FC<OuterProps> = (props) => {
  const apiClient = useApiClient(ClientApiClient)

  const location = useLocation()
  const locale = useSessionLanguage()
  const country = getLocationCountry(location)

  const callback = useCallback(async () => {
    return apiClient.getRegulators(getTickmillCompanyByHostname(true), locale, country)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const { data: regulators = [], isLoading } = useFetchOne(callback)

  return (
    <Loading isLoading={isLoading}>
      {regulators && <CompanyDetailsStep2Form {...props} regulators={regulators} />}
    </Loading>
  )
}
