import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'

import { Tabs } from '../global/tabs/Tabs'
import { ScrollToIds } from '../hooks/useScrollToElementIds'
import { PageHeader } from '../ui/Table/Header/PageHeader'
import { useAccountReadContext } from '../utils/AccountContextContext'
import { useIBCampaignsResultsReadContext } from '../utils/IBCampaignsResultsContext'
import { isTickmillPartner } from '../utils/companyName.utils'
import { isNoTabsPath } from '../utils/path'
import { useIBPrograms } from '../utils/useIBPrograms'
import { IntroducingBrokerRoutes } from './IntroducingBrokerRoutes'
import { IBSignUpPage } from './SignUp/IBSignUpPage'

import styles from './IntroducingBroker.module.scss'

export const IntroducingBroker: React.FC = () => {
  const location = useLocation()
  const { tab = '' } = useParams<{ tab: string }>()
  const navigate = useNavigate()
  const { account } = useAccountReadContext()
  const { multiTierProgram } = useIBPrograms()

  const { t } = useTranslation()
  const { ibCampaignsResults } = useIBCampaignsResultsReadContext()

  const TabsDefinition = [
    { name: t('Tabs.Wallets'), id: 'wallets', disabled: false },
    { name: t('Tabs.Clients'), id: 'clients', disabled: false },
    { name: t('Tabs.Income'), id: 'income', disabled: false },
    {
      name: t('Tabs.Reports'),
      id: 'reports',
      disabled: false,
    },
    {
      name: t('Tabs.Transaction History'),
      id: 'transaction-history',
      disabled: false,
    },
    ...((!isTickmillPartner(account) && [
      {
        name: t('Tabs.Referral Materials'),
        id: 'referral-materials',
        disabled: false,
      },
    ]) ||
      []),
    {
      name: t('Tabs.Loyalty Program'),
      id: 'loyalty-program',
      disabled: false,
    },
    ...((multiTierProgram &&
      account?.companyConfiguration.hasMultiTier && [
        {
          name: t('Tabs.Multi-Tier'),
          id: 'multi-tier',
        },
      ]) ||
      []),
    {
      name: t('Tabs.Legal Documents'),
      id: 'legal-documents',
      disabled: false,
    },
  ]
  if (ibCampaignsResults.length) {
    TabsDefinition.push({
      name: t('Tabs.IB Contest'),
      id: 'ib-contest',
      disabled: false,
    })
  }

  if (!account?.clientIntroducingBroker?.id)
    return (
      <div className='pt-5'>
        <PageHeader title={t('IB.Introducing Broker Program')} id={ScrollToIds.IBSignupHeader} />
        <IBSignUpPage />
      </div>
    )

  return (
    <React.Fragment>
      {!isNoTabsPath(location.pathname) && (
        <div className={classNames('section', styles.adminRoutes)}>
          <Tabs
            tabs={TabsDefinition}
            currentTab={tab}
            appearance='light'
            onTabChanged={(tab) => navigate(`/dashboard/introducing-broker/${tab}`)}
          />
        </div>
      )}
      <IntroducingBrokerRoutes tab={tab} />
    </React.Fragment>
  )
}
