import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { getIn, useFormikContext } from 'formik'
import { FieldValidator } from 'formik/dist/types'

import { AppropriatenessTestUploadModal } from '../../../SignUp/FinancialInfo/AppropriatenessTestUploadModal'
import { SelectField } from '../../../global/field/SelectField'
import { createFormField } from '../../../global/formField/FormField'
import { createFormRadioField } from '../../../global/formField/RadioGroupField'
import { Modal } from '../../../global/modal/Modal'
import { RadioButton } from '../../../global/radioButton/RadioButton'
import { InfoIcon } from '../../../icons/InfoIcon'
import { AppropriatenessTestTakeTestFormValues } from '../../../model/Test'
import {
  isTestQuestionWidgetRadio,
  isTestQuestionWidgetSelect,
} from '../../../model/TestQuestionWidget'
import {
  TestSectionAnswerDto,
  TestSectionDto,
  TestSectionQuestionDto,
  isAppropriatenessTestPersonalInformation,
  isAppropriatenessTestTradingKnowledge,
} from '../../../model/TestSectionsDto'
import { Tooltip } from '../../../ui/Popups/Tooltip/Tooltip'
import { OnChangeOption, WidgetOptions, getOptions } from '../../../ui/TestFields/TestFields'
import { Text } from '../../../ui/Typography/Typography'
import { useWindowResize } from '../../../utils/domUtils'
import { isOne } from '../../../utils/validations'
import { YearlyKycUpdateDetailsFormValues } from './YearlyKycUpdateDetailsForm'

import styles from './YearlyKycUpdateDetailsForm.module.scss'

const FormField = createFormField()
const FormRadioGroupField = createFormRadioField()

export const YearlyKycTestForm: React.FC = () => {
  const { values } = useFormikContext<YearlyKycUpdateDetailsFormValues>()

  return (
    <div>
      {(values.accountKycTest?.sections || []).map((x) => (
        <React.Fragment key={x.id}>
          <div className={styles.box}>
            <h3>{x.name}</h3>
            {x.questions.map((q) => (
              <QuestionFieldFactory {...q} section={x} key={q.widget.name} />
            ))}
          </div>
        </React.Fragment>
      ))}
    </div>
  )
}

interface QuestionFieldFactoryProps extends TestSectionQuestionDto {
  section: TestSectionDto
}

const QuestionFieldFactory: React.FC<QuestionFieldFactoryProps> = (props) => {
  const { question, widget } = props

  const { values } = useFormikContext<AppropriatenessTestTakeTestFormValues>()

  const options = getOptions(widget.options)

  if (isOptionsHide(options)) {
    return null
  }

  if (isTestQuestionWidgetSelect(question.widgetType)) {
    return <SelectWidget {...props} />
  }

  if (isTestQuestionWidgetRadio(question.widgetType)) {
    return <RadioWidget {...props} />
  }

  if (!isFieldShow(props, values)) {
    return null
  }

  return <TextWidget {...props} />
}

const QuestionFieldOtherFactory: React.FC<
  TestSectionQuestionDto & {
    section: TestSectionDto
  }
> = (props) => {
  const { question } = props

  if (isTestQuestionWidgetSelect(question.widgetType)) {
    return <SelectWidget {...props} />
  }

  if (isTestQuestionWidgetRadio(question.widgetType)) {
    return <RadioWidget {...props} />
  }

  return <TextWidget {...props} />
}

const SelectWidget: React.FC<
  TestSectionQuestionDto & {
    section: TestSectionDto
  }
> = (props) => {
  const { question, answers, widget, section } = props

  const { t } = useTranslation()
  const context = useFormikContext<AppropriatenessTestTakeTestFormValues>()
  const name = `selectedAnswers.${widget.name}`

  const [isDocumentUploadModalOpen, setDocumentUploadModalOpen] = useState<boolean>()
  const errorMessage = question?.errorMessage || t('Validation.Required')
  const isTouched = context.touched.selectedAnswers?.[widget.name]

  const isMobile = useWindowResize()

  const options = getOptions(widget.options)

  useEffect(() => {
    const value = getIn(context.values, name)
    const isMandatory = options.isMandatory ?? props.isMandatory
    if (isMandatory && !value) {
      context.setFieldError(name, errorMessage)
    } else {
      context.setFieldError(name, undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, question, context.values, context.errors, context.touched])

  if (widget?.name && question?.name) {
    const isMandatory = options.isMandatory ?? props.isMandatory
    const value = getIn(context.values, name)

    const isDocumentUploadAllowed = options?.upload?.values?.includes(value)
    return (
      <React.Fragment>
        <div className={classNames(styles.questionAnswerField)}>
          <label className={styles.text}>
            {question.name}
            {isMandatory ? '*' : ''}{' '}
            {question?.tooltip && (
              <React.Fragment>
                <Tooltip
                  content={question.tooltip}
                  direction={isMobile ? 'top' : 'topLeft'}
                  autoHide={2000}
                >
                  <InfoIcon size={12} />
                </Tooltip>
              </React.Fragment>
            )}
          </label>
          <SelectField
            name={name}
            error={isMandatory && !value && isTouched ? errorMessage : undefined}
            options={answers.map((x) => ({ value: x.id, label: x.name }))}
            onChange={(v) => {
              context.setFieldValue(name, v.target.value)
              context.setFieldTouched(name, true)
            }}
            value={value}
            required={isMandatory}
          />
          {isDocumentUploadAllowed && (
            <React.Fragment>
              <span
                onClick={() => setDocumentUploadModalOpen(true)}
                className='is-link has-cursor-pointer'
              >
                {t(
                  'Sign up.If available please upload any documentary evidence of trading history e.g. trading statement (Optional)'
                )}
              </span>
              {isDocumentUploadModalOpen && (
                <Modal
                  closeModal={() => setDocumentUploadModalOpen(false)}
                  render={() => (
                    <AppropriatenessTestUploadModal
                      questionId={question.questionId}
                      onCancel={() => setDocumentUploadModalOpen(false)}
                      onConfirm={() => setDocumentUploadModalOpen(false)}
                    />
                  )}
                />
              )}
            </React.Fragment>
          )}
        </div>
        {options?.onchange?.map((x) => {
          return (
            <WidgetOptionComponent
              {...props}
              section={section}
              name={name}
              option={x}
              key={x.name}
            />
          )
        })}
      </React.Fragment>
    )
  }

  return null
}

const RadioWidget: React.FC<
  TestSectionQuestionDto & {
    section: TestSectionDto
  }
> = (props) => {
  const { question, answers, widget } = props

  const { t } = useTranslation()
  const isMobile = useWindowResize()

  const context = useFormikContext<AppropriatenessTestTakeTestFormValues>()

  const [isDocumentUploadModalOpen, setDocumentUploadModalOpen] = useState<boolean>()

  if (widget?.name && question?.name) {
    const name = `selectedAnswers.${widget.name}`
    const options = getOptions(widget.options)
    const isMandatory = options.isMandatory ?? props.isMandatory
    const error = getIn(context.errors, name)
    const touched = getIn(context.touched, name)
    const value = getIn(context.values, name)
    const isDocumentUploadAllowed = isRadioWidgetCode(options, answers, value)
    return (
      <React.Fragment>
        <div
          className={classNames(styles.questionAnswerField, {
            [styles.boxError]: touched && error,
          })}
        >
          <div>
            <div className='pb-4'>
              <Text>
                {question.name} {isMandatory ? '*' : ''}
                {question?.tooltip && (
                  <React.Fragment>
                    {' '}
                    <Tooltip
                      content={question.tooltip}
                      direction={isMobile ? 'top' : 'topLeft'}
                      autoHide={2000}
                    >
                      <InfoIcon size={12} />
                    </Tooltip>
                  </React.Fragment>
                )}
              </Text>
            </div>
            <FormRadioGroupField
              name={name}
              value={value}
              required
              validate={(value: FieldValidator) => {
                if (!value) {
                  return question?.errorMessage || t('Validation.Required')
                }
                return undefined
              }}
            >
              <div className='columns'>
                {answers.map((x) => (
                  <div className='column is-flex-grow-0'>
                    <RadioButton
                      name={name}
                      label={x.name}
                      value={x.id}
                      checked={x.id === value}
                      key={x.id}
                    />
                  </div>
                ))}
              </div>
            </FormRadioGroupField>
            {isDocumentUploadAllowed && (
              <React.Fragment>
                <span
                  onClick={() => setDocumentUploadModalOpen(true)}
                  className='is-link has-cursor-pointer'
                >
                  {t(
                    'Sign up.If available please upload any documentary evidence of trading history e.g. trading statement (Optional)'
                  )}
                </span>
                {isDocumentUploadModalOpen && (
                  <Modal
                    closeModal={() => setDocumentUploadModalOpen(false)}
                    render={() => (
                      <AppropriatenessTestUploadModal
                        questionId={question.questionId}
                        onCancel={() => setDocumentUploadModalOpen(false)}
                        onConfirm={() => setDocumentUploadModalOpen(false)}
                      />
                    )}
                  />
                )}
              </React.Fragment>
            )}
          </div>
        </div>
        {options?.onchange?.map((x) => {
          return <WidgetOptionComponent {...props} name={name} option={x} key={x.name} />
        })}
      </React.Fragment>
    )
  }

  return null
}

const TextWidget: React.FC<
  TestSectionQuestionDto & {
    section: TestSectionDto
  }
> = (props) => {
  const { question, widget } = props

  const { t } = useTranslation()

  if (widget?.name && question?.name) {
    const name = `freeAnswers.${question.id}`
    const options = getOptions(widget.options)
    const isMandatory = options.isMandatory ?? props.isMandatory

    return (
      <div className={classNames(styles.questionAnswerField)}>
        <FormField
          name={name}
          label={question.name}
          placeholder={question.name}
          tooltip={widget?.tooltip || undefined}
          required={isMandatory}
          validate={(value: FieldValidator) => {
            if (isMandatory && !value) {
              return t('Validation.Required')
            }
            return undefined
          }}
        />
      </div>
    )
  }

  return null
}

const WidgetOptionComponent: React.FC<
  {
    option: OnChangeOption
  } & {
    name: string
    section: TestSectionDto
  } & TestSectionQuestionDto
> = (props) => {
  const { name, option, section, answers } = props
  const widgetName = option.name

  const { values } = useFormikContext<YearlyKycUpdateDetailsFormValues>()

  return (
    <React.Fragment>
      {option.values?.map((id) => {
        const widgetQuestion = getWidgetByWidgetName(section, widgetName)
        if (isOther(values, name, answers, id)) {
          if (widgetQuestion) {
            return (
              <QuestionFieldOtherFactory
                {...props}
                {...{
                  ...widgetQuestion,
                  isMandatory: true,
                  widget: {
                    ...widgetQuestion.widget,
                  },
                }}
                key={widgetQuestion.widget.name}
              />
            )
          }
        }

        if (isDepend(values, name, id)) {
          if (widgetQuestion) {
            return (
              <QuestionFieldOtherFactory
                {...props}
                {...{
                  ...widgetQuestion,
                  widget: {
                    ...widgetQuestion.widget,
                  },
                }}
                key={widgetQuestion.widget.name}
              />
            )
          }
        }

        if (isRadioWidgetType(values, props)) {
          if (widgetQuestion) {
            return (
              <QuestionFieldOtherFactory
                {...props}
                {...{
                  ...widgetQuestion,
                  widget: {
                    ...widgetQuestion.widget,
                  },
                }}
                key={widgetQuestion.widget.name}
              />
            )
          }
        }

        return null
      })}
    </React.Fragment>
  )
}

const isOther = (
  values: YearlyKycUpdateDetailsFormValues,
  name: string,
  arrayAnswers: TestSectionAnswerDto[],
  id: string
) => {
  const otherKey = 'other'
  const value = getIn(values, name)
  const answer = arrayAnswers.find((x) => x.id === id)

  const isSelected = value === id
  const isOtherInQuestion = answer?.key === otherKey

  return isOtherInQuestion && isSelected
}

const isFieldShow = (
  props: QuestionFieldFactoryProps,
  values: AppropriatenessTestTakeTestFormValues
) => {
  const { section, answers: arrayAnswers = [], widget } = props

  const options = getOptions(widget.options)
  const answerWidgetKeys = Object.keys(values.selectedAnswers)

  if (isAppropriatenessTestPersonalInformation(section.code)) {
    const foundPersonalInfo = answerWidgetKeys.find((x) => x === options?.depend)
    return foundPersonalInfo
  }

  if (isAppropriatenessTestTradingKnowledge(section.code)) {
    const foundTradingKnowledgeKey = answerWidgetKeys.find((x) => x === options?.depend)

    const foundTradingKnowledgeValue =
      foundTradingKnowledgeKey && values.selectedAnswers?.[foundTradingKnowledgeKey]

    const foundTradingKnowledgeCode =
      foundTradingKnowledgeKey &&
      foundTradingKnowledgeValue &&
      arrayAnswers.find((x) => x?.id === foundTradingKnowledgeValue)?.code === '1'

    return foundTradingKnowledgeCode
  }

  return false
}

const isDepend = (values: YearlyKycUpdateDetailsFormValues, name: string, id: string) => {
  const value = getIn(values, name)
  const isSelected = value === id

  return isSelected
}

const isRadioWidgetType = (
  values: YearlyKycUpdateDetailsFormValues,
  props: {
    option: OnChangeOption
  } & {
    name: string
    section: TestSectionDto
  } & TestSectionQuestionDto
) => {
  const { name, answers, widget } = props
  const value = getIn(values, name)

  if (isTestQuestionWidgetRadio(widget?.type)) {
    const selectedRadioCode = getRadioWidgetCode(answers, value)
    return isOne(selectedRadioCode)
  }

  return false
}

const isRadioWidgetCode = (
  options: WidgetOptions,
  answers: TestSectionAnswerDto[],
  value: string
) => {
  const radioWidgetCode = getRadioWidgetCode(answers, value)
  const isDocumentUploadAllowed = options?.upload?.values
    ?.map((x) => Number.parseInt(x.toString(), 10))
    ?.includes(radioWidgetCode)
  return isDocumentUploadAllowed
}

const getRadioWidgetCode = (answers: TestSectionAnswerDto[], value: string) => {
  const answer = answers.find((x) => x?.id === value)
  const selectedRadioCode = answer ? Number.parseInt(answer.code, 10) : -1

  return selectedRadioCode
}

const isOptionsHide = (options: WidgetOptions) => {
  return options && 'hide' in options && 'depend' in options && options?.hide === true
}

const getWidgetByWidgetName = (
  section: TestSectionDto,
  widgetName?: string
): TestSectionQuestionDto | undefined => {
  return (section?.questions || [])?.find((x) => x.question.widgetName === widgetName)
}
