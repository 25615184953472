import { NavigateFunction } from 'react-router-dom'

export const navigateBasedOnProductType = (
  isProductTypeCFD: boolean,
  navigate: NavigateFunction,
  isPartnerAccount?: boolean
): void => {
  const walletsPath = '/dashboard/traders-room/wallets'
  const balancesPath = '/dashboard/traders-room/balances'
  const partnersPath = '/dashboard/introducing-broker/wallets'

  const selectedPath = isProductTypeCFD
    ? isPartnerAccount
      ? partnersPath
      : walletsPath
    : balancesPath

  navigate(selectedPath)
}
