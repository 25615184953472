import { IconProps, iconColor } from './IconProps'

import styles from './Icons.module.scss'

export function DotsIcon(props: IconProps) {
  return (
    <svg width='16' height='4' viewBox='0 0 16 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 2C12 2.39556 12.1173 2.78224 12.3371 3.11114C12.5568 3.44004 12.8692 3.69639 13.2346 3.84776C13.6001 3.99914 14.0022 4.03874 14.3902 3.96157C14.7781 3.8844 15.1345 3.69392 15.4142 3.41421C15.6939 3.13451 15.8844 2.77814 15.9616 2.39018C16.0387 2.00222 15.9991 1.60009 15.8478 1.23463C15.6964 0.869182 15.44 0.556825 15.1111 0.337062C14.7822 0.117299 14.3956 1.00766e-06 14 9.90367e-07C13.4701 0.00158369 12.9623 0.212806 12.5875 0.587536C12.2128 0.962266 12.0016 1.47005 12 2ZM10 2C10 1.60444 9.8827 1.21776 9.66294 0.888861C9.44318 0.559962 9.13082 0.303617 8.76537 0.152242C8.39991 0.00086623 7.99778 -0.0387405 7.60982 0.03843C7.22186 0.1156 6.86549 0.306082 6.58579 0.585787C6.30608 0.865492 6.1156 1.22186 6.03843 1.60982C5.96126 1.99778 6.00086 2.39992 6.15224 2.76537C6.30361 3.13082 6.55996 3.44318 6.88886 3.66294C7.21776 3.8827 7.60444 4 8 4C8.52995 3.99842 9.03773 3.7872 9.41246 3.41247C9.78719 3.03774 9.99842 2.52995 10 2ZM4 2C4 1.60444 3.8827 1.21776 3.66294 0.88886C3.44318 0.559962 3.13082 0.303617 2.76537 0.152241C2.39991 0.000865968 1.99778 -0.0387407 1.60982 0.0384297C1.22186 0.1156 0.865491 0.306082 0.585786 0.585787C0.306081 0.865492 0.115599 1.22186 0.0384282 1.60982C-0.0387422 1.99778 0.000864878 2.39992 0.15224 2.76537C0.303614 3.13082 0.559959 3.44318 0.888858 3.66294C1.21776 3.8827 1.60444 4 2 4C2.52995 3.99842 3.03773 3.7872 3.41246 3.41247C3.7872 3.03774 3.99842 2.52995 4 2Z'
        fill={props.color ? styles[props.color] : iconColor(props)}
      />
    </svg>
  )
}
