import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Button } from '../../global/button/Button'
import { CloseIcon } from '../../icons/CloseIcon'
import { Text } from '../../ui/Typography/Typography'
import { Modal } from './Modal'

import styles from './ProductServiceModal.module.scss'

type ProductServiceModalProps = {
  name?: string
  imageUrl?: string
  imageUrlMobile?: string
  description?: ReactNode
  url?: string
}

export const ProductServiceModal = ({
  showModal,
  setShowModal,
}: {
  showModal?: ProductServiceModalProps
  setShowModal: Function
}) => {
  const { t } = useTranslation()

  return showModal?.name ? (
    <Modal
      size='large'
      closeModal={() => setShowModal({})}
      render={() => (
        <div className={styles.modal}>
          <div className='columns is-hidden-mobile'>
            <div className='column is-one-third p-0'>
              <div
                className={classNames(
                  'is-flex is-justify-content-center is-align-items-center px-0 py-5',
                  styles.image
                )}
              >
                <img src={showModal.imageUrl} alt={showModal.name} />
              </div>
            </div>
            <div className='column p-6 is-flex is-flex-direction-column is-justify-content-space-between'>
              <div>
                <div className='has-text-right mb-2 is-clickable' onClick={() => setShowModal({})}>
                  <CloseIcon size={12} />
                </div>
                <div className='my-4'>
                  <h3>{showModal.name}</h3>
                </div>

                <div className={classNames('py-2', styles.description)}>
                  <Text>{showModal.description}</Text>
                </div>
              </div>
              <div className='mt-4'>
                <Button
                  appearance='primary'
                  size='L'
                  onClick={() => window.open(showModal.url)}
                  type='button'
                >
                  {t('Platform Vendors.action')}
                </Button>
              </div>
            </div>
          </div>

          <div className='is-hidden-tablet'>
            <div
              className={classNames('mb-2 is-clickable p-2', styles.icon)}
              onClick={() => setShowModal({})}
            >
              <CloseIcon size={12} />
            </div>
            <div
              className={classNames(
                'is-flex is-justify-content-center is-align-items-center py-5 px-5',
                styles.imageMobileContainer
              )}
            >
              <img
                className={classNames(styles.imageMobile, 'mt-5')}
                src={showModal?.imageUrlMobile || showModal.imageUrl}
                alt={showModal.name}
              />
            </div>

            <div className='p-6'>
              <div className='mt-4'>
                <h3>{showModal.name}</h3>
              </div>

              <div className={classNames('py-2', styles.description)}>
                <Text>{showModal.description}</Text>
              </div>

              <Button
                appearance='primary'
                size='L'
                className={classNames('mt-4', styles.button)}
                onClick={() => window.open(showModal.url)}
                type='button'
              >
                {t('Platform Vendors.action')}
              </Button>
            </div>
          </div>
        </div>
      )}
    />
  ) : null
}
