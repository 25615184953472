import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'

import { useAccountInfo } from '../global/context/AccountInfoContext/AccountInfoContext.Provider'
import { useModalsContext } from '../global/context/ModalsContext'
import { useSessionLanguage } from '../global/context/SessionSettingsContext'
import IconButton from '../global/iconButton/IconButton'
import { TabProps, Tabs } from '../global/tabs/Tabs'
import { InfoIcon } from '../icons/InfoIcon'
import { ClientCampaignDto } from '../model/ClientCampaignDto'
import { LuckyDrawCampaign } from '../model/LuckyDrawDto'
import { TextStrong } from '../ui/Typography/Typography'
import { useApiClient } from '../utils/ApiClient'
import { ClientApiClient } from '../utils/clientApi'
import { isNoTabsPath } from '../utils/path'
import { useLocallyPersistedState } from '../utils/useStorage'
import {
  TradersRoomModalsType,
  isTradersRoomConfirmationModalOpen,
  isTradersRoomLuckyDrawModalOpen,
} from './TradersRoomModals'

import styles from './TradersRoom.module.scss'

interface LuckyDrawResponseProps {
  luckyDrawCampaign: LuckyDrawCampaign | undefined
  setShowLuckyDraw(value: boolean): void
}

const useLuckyDraw = (): LuckyDrawResponseProps => {
  const location = useLocation()
  const apiClient = useApiClient(ClientApiClient)
  const { activeModal, setActiveModal } = useModalsContext()

  const [, setLuckyDrawEligible] = useLocallyPersistedState('luckyDrawEligible', false)
  const [luckyDrawViewed, setLuckyDrawViewed] = useLocallyPersistedState('luckyDrawViewed', false)

  const [isLuckyDrawModalOpen, setLuckyDrawModalOpen] = useState<boolean>(!luckyDrawViewed)
  const [luckyDrawCampaign, setLuckyDrawCampaign] = useState<LuckyDrawCampaign>()

  useEffect(() => {
    if (isLuckyDrawModalOpen && luckyDrawCampaign) {
      setActiveLuckyDrawModalOpen(luckyDrawCampaign)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLuckyDrawModalOpen, luckyDrawCampaign])

  useEffect(() => {
    if (!isTradersRoomLuckyDrawModalOpen(activeModal)) {
      setLuckyDrawModalOpen(false)
    }
  }, [activeModal])

  useEffect(() => {
    checkLuckyDrawEligible()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (location?.state?.luckyDrawModalActive) {
      setLuckyDrawModalOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state])

  const checkLuckyDrawEligible = async () => {
    const luckyCampaignFetch = await fillLuckyCampaign()
    if (!luckyCampaignFetch) {
      setLuckyDrawModalOpen(false)
      setLuckyDrawViewed(true)
    }
  }

  const fillLuckyCampaign = async (): Promise<LuckyDrawCampaign | null> => {
    const eligibility = await apiClient.getLuckyDrawCampaign()

    let pickedCampaign: LuckyDrawCampaign | null = null
    for (let i = 0; i < eligibility.length; i++) {
      if (eligibility[i].canGenerateMoreChances || eligibility[i].hasAvailableChancesToUse) {
        pickedCampaign = eligibility[i]
        break
      }
    }

    if (eligibility.length > 0) {
      setLuckyDrawEligible(true)
    }

    if (pickedCampaign) {
      setLuckyDrawCampaign(pickedCampaign)
    } else {
      setLuckyDrawCampaign(eligibility[0])
    }

    return pickedCampaign
  }

  const setActiveLuckyDrawModalOpen = (campaign: LuckyDrawCampaign) => {
    setActiveModal(TradersRoomModalsType.LuckyDraw, {
      campaign,
    })
  }

  return {
    luckyDrawCampaign,
    setShowLuckyDraw: setLuckyDrawModalOpen,
  }
}

const useTabs = (clientCampaignsProps: TradersRoomTabsProps) => {
  const { clientCampaigns, clientCampaignsList } = clientCampaignsProps
  const luckyDraw = useLuckyDraw()

  const { luckyDrawCampaign, setShowLuckyDraw } = luckyDraw

  const { t } = useTranslation()
  const locale = useSessionLanguage()

  const { accountInfoLimits } = useAccountInfo()

  const [tabsDefinition, setTabsDefinition] = useState<TabProps<string>[]>([])

  useEffect(() => {
    setNewTabs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    luckyDrawCampaign,
    clientCampaigns?.length,
    clientCampaignsList?.length,
    locale,
    accountInfoLimits?.hasReachedMaxTradingAccounts,
  ])

  const addCampaignAccTab = (): TabProps<string> => {
    return {
      name: t('Tabs.Add Campaign Account'),
      id: 'add-campaign-account',
      disabled: accountInfoLimits?.hasReachedMaxTradingAccounts,
      highlight: true,
    }
  }

  const addLuckyTab = (): TabProps<string> => {
    return {
      name: t('Tabs.Lucky Draw'),
      id: 'lucky-draw',
      disabled: false,
      onTabClicked: () => setShowLuckyDraw(true),
      highlight: true,
    }
  }

  const setNewTabs = () => {
    const newTabs: TabProps<string>[] = [
      {
        id: 'wallets',
        name: t('Tabs.Wallets'),
        disabled: false,
      },
      {
        id: 'trading-accounts',
        name: t('Tabs.Trading Accounts'),
        disabled: false,
      },
      {
        id: 'transactions',
        name: t('Tabs.Transactions'),
        disabled: false,
      },
      {
        id: 'transaction-history',
        name: t('Tabs.Transaction History'),
        disabled: false,
      },
      {
        id: 'stock-dividends',
        name: t('Tabs.Stock Dividends'),
        disabled: false,
      },
      {
        id: 'campaign-results',
        name: t('Tabs.Campaign Results'),
        disabled: false,
        className: classNames({
          'is-hidden': !clientCampaignsList?.length,
        }),
      },
    ]
    if (luckyDrawCampaign) {
      newTabs.push(addLuckyTab())
    }

    if (clientCampaigns?.length) {
      newTabs.push(addCampaignAccTab())
    }
    setTabsDefinition(newTabs)
  }

  return { tabsDefinition, setTabsDefinition }
}

interface TradersRoomTabsProps {
  clientCampaigns?: ClientCampaignDto[]
  clientCampaignsList?: ClientCampaignDto[]
}

export const TradersRoomTabs: React.FC<TradersRoomTabsProps> = (props) => {
  const { tab } = useParams<{ tab?: string }>()
  const navigate = useNavigate()
  const location = useLocation()

  const { activeModal, setActiveModal } = useModalsContext()

  const tabs = useTabs(props)

  const handleTabChange = (tab: string) => {
    // TODO improve that IF
    if (tab === 'add-campaign-account') {
      if (!isTradersRoomConfirmationModalOpen(activeModal)) {
        return navigate(`/dashboard/traders-room/trading-accounts/new?campaign=true`)
      }
    } else {
      return navigate(`/dashboard/traders-room/${tab}`)
    }
  }

  if (isNoTabsPath(location.pathname)) {
    return null
  }

  return (
    <div className={classNames('section', styles.adminRoutes)}>
      <Tabs
        tabs={tabs.tabsDefinition}
        currentTab={tab || ''}
        appearance='light'
        renderIcon={(id) => (
          <TradersRoomIcon
            id={id}
            onClick={() => setActiveModal(TradersRoomModalsType.Confirmation)}
          />
        )}
        renderTab={(name, highlight) => <TradersRoomTab name={name} highlight={highlight} />}
        onTabChanged={handleTabChange}
      />
    </div>
  )
}

interface TradersRoomTabProps {
  name: string
  highlight?: boolean
}

const TradersRoomTab: React.FC<TradersRoomTabProps> = (props) => {
  const { name, highlight } = props

  if (highlight) {
    return (
      <TextStrong isParagraph className={styles.highlight}>
        {name}
      </TextStrong>
    )
  }
  return <span>{name}</span>
}

interface TradersRoomIconProps {
  id: string
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

const TradersRoomIcon: React.FC<TradersRoomIconProps> = (props) => {
  const { id, onClick } = props

  if (id === 'add-campaign-account') {
    return (
      <IconButton onClick={onClick}>
        <InfoIcon />
      </IconButton>
    )
  }

  return null
}
