import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { PageHeader } from '../../ui/Table/Header/PageHeader'

import styles from './MarketDataPage.module.scss'

export const MarketDataHelpPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleGoBack = () => {
    navigate('/dashboard/subscriptions/market-data')
  }

  return (
    <>
      <PageHeader title={t('Market Data.title')} backButton={handleGoBack} />
      <div className={classNames('mt-3', styles.card)}>
        <h3> {t('Market Data.Why do I need Market Data')}</h3>
        <p className='text mt-4'>
          {t('Market Data.Market Data is what provides you with real-time')}
        </p>
      </div>
    </>
  )
}
