import { useTranslation } from 'react-i18next'

import {
  AccountDetailedDto,
  isAccountAdditionalInfoDocumentAllowedUpload,
  isAccountAddressInfoDocumentAllowedUpload,
  isAccountCompanyInfoDocumentAllowedUpload,
  isAccountPaymentInfoDocumentAllowedUpload,
  isAccountPersonalInfoDocumentAllowedUpload,
} from '../../model/AccountDetailedDto'
import { DocumentCategoryType } from '../../model/DocumentCategories'
import { Text } from '../../ui/Typography/Typography'
import { useAccountReadContext } from '../../utils/AccountContextContext'

interface AdditionalDocument {
  category: DocumentCategoryType
  title?: string
  text?: JSX.Element
  instructions?: string
  checker?: (accountDetailed: AccountDetailedDto | undefined) => boolean
}

type Texts = Omit<AdditionalDocument, 'category'>

export const useAdditionalDocuments = (): (AdditionalDocument | null)[] => {
  const { account } = useAccountReadContext()
  const { t } = useTranslation()

  const texts: {
    [key in DocumentCategoryType]?: Texts
  } = {
    [DocumentCategoryType.Additional]: {
      title: t('Profile.Additional Documents'),
      checker: isAccountAdditionalInfoDocumentAllowedUpload,
      instructions: t(
        'Profile.Please upload any additional documents requested by our Support Team.'
      ),
    },
    [DocumentCategoryType.Payment]: {
      title: t('Profile.Payments Documents'),
      checker: isAccountPaymentInfoDocumentAllowedUpload,
      instructions: t('Profile.Please upload any documents requested by our team'),
    },
    [DocumentCategoryType.Personal]: {
      title: t('Profile.Identification'),
      checker: isAccountPersonalInfoDocumentAllowedUpload,
      text: (
        <Text>
          <ul>
            <li>{t('Profile.Passport')}</li>
            <li>{t('Profile.National ID')}</li>
            <li>{t('Profile.Government-issued')}</li>
          </ul>
        </Text>
      ),
      instructions: t(
        'Profile.Please provide a valid copy of your National Identification or Passport with at least',
        {
          months: 6,
        }
      ),
    },
    [DocumentCategoryType.Address]: {
      title: t('Profile.Proof Of Address'),
      checker: isAccountAddressInfoDocumentAllowedUpload,
      text: (
        <Text>
          <ul>
            <li>{t('Profile.Latest utility bill (gas, electricity or landline phone)')}</li>
            <li>{t('Profile.Bank account statement')}</li>
            <li>{t('Profile.Any other official document that includes your name and address')}</li>
          </ul>
        </Text>
      ),
      instructions: t(
        'Profile.Please provide us with a recent proof of address document, dated within',
        {
          months: 6,
        }
      ),
    },
    [DocumentCategoryType.Corporate]: {
      title: t('Profile.Company Proof'),
      checker: isAccountCompanyInfoDocumentAllowedUpload,
      text: (
        <ul>
          <li>{t('Profile.Certificate of incorporation')}</li>
          <li>{t('Profile.Articles of Association')}</li>
          <li>{t('Profile.Memorandum of Association')}</li>
          <li>{t('Profile.Certificate of Good Standing not older than 1 year')}</li>
          <li>{t('Profile.Certificate of Incumbency not older than 1 year')}</li>
          <li>
            {t(
              'Profile.Document confirming the corporate address not older than 180 days (a bank statement, bill, etc)',
              {
                length: 180,
              }
            )}
          </li>
        </ul>
      ),
    },
  }

  const generateDocument = (category: DocumentCategoryType) => {
    if (account && texts[category]?.checker?.(account)) {
      return {
        ...texts[category],
        category,
      }
    }
    return null
  }

  const additionalDocuments = [
    DocumentCategoryType.Additional,
    DocumentCategoryType.Payment,
    DocumentCategoryType.Personal,
    DocumentCategoryType.Address,
    DocumentCategoryType.Corporate,
  ]
    .map((category) => generateDocument(category))
    .filter(Boolean)

  return additionalDocuments
}
