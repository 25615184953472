import React from 'react'
import { useTranslation } from 'react-i18next'

import { Text } from '../../../ui/Typography/Typography'
import { PersonalDetailsTemplate } from '../PersonalInfoPage/PersonalDetailsTemplate'
import { FormValuesStep0 } from '../PersonalInfoPage/PersonalInfoStepAccountTypePage'
import { FormValuesStep2 } from '../PersonalInfoStep2Page/PersonalInfoStep2Form'
import { FormValuesStep3 } from '../PersonalInfoStep3Page/PersonalInfoStep3Form'
import { FormValuesStep1, PersonalDetailsStep1Form } from './PersonalInfoDetailsStep1Form'

import 'react-datepicker/dist/react-datepicker.css'
import { SignupWarningMessages } from '../SignupWarningMessages'

type FormValues = FormValuesStep0 & FormValuesStep1

interface PersonalInfoDetailsStep1Props {
  step: number
  setStep(n: number): void
  values?: Partial<FormValues>
  onSubmit(values: FormValuesStep0 | FormValuesStep1 | FormValuesStep2 | FormValuesStep3): void
}

export const PersonalInfoDetailsStep1Page: React.FC<PersonalInfoDetailsStep1Props> = (props) => {
  const { step, setStep, values: formValues, onSubmit } = props

  const { t } = useTranslation()

  const goBack = () => {
    setStep(step - 1)
  }

  return (
    <PersonalDetailsTemplate
      step={step}
      renderDescription={() => (
        <Text isParagraph>
          {t(
            'Sign up.Your personal information is required in the next step to complete the application process. Your ID documents are needed for this step.'
          )}
        </Text>
      )}
      goBack={goBack}
    >
      <SignupWarningMessages />

      <PersonalDetailsStep1Form
        values={{
          middleName: formValues?.middleName,
          firstName: formValues?.firstName,
          lastName: formValues?.lastName,
          nativeName: formValues?.nativeName,
          genderId: formValues?.genderId,
          monthOfBirth: formValues?.monthOfBirth,
          dayOfBirth: formValues?.dayOfBirth,
          yearOfBirth: formValues?.yearOfBirth,
        }}
        onSubmit={onSubmit}
      />
    </PersonalDetailsTemplate>
  )
}
