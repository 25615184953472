import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { SelectModal, SelectModalOption } from '../../global/field/SelectModal'
import { Modal } from '../../global/modal/Modal'
import { CompanyDetailsStep2FormValues } from './CompanyDetailsStep2Form'

interface OwnershipStructureShareholdersModalProps {
  onSelectOption(option: number): void
  onClose(): void
}

export const OwnershipStructureShareholdersModal: React.FC<
  OwnershipStructureShareholdersModalProps
> = (props) => {
  const { onClose, onSelectOption } = props

  const { t } = useTranslation()

  const { values, setValues } = useFormikContext<CompanyDetailsStep2FormValues>()

  const handleSelectOption = (value: number) => {
    setValues({ ...values, numberOfOwners: value })
    onSelectOption(value)
  }

  return (
    <Modal
      closeModal={onClose}
      render={({ closeModal }) => (
        <SelectModal
          onCancel={closeModal}
          title={t('Sign up.Number of Owners / Shareholders')}
          renderOptions={() => (
            <div className='control'>
              {Array.from({ length: 10 }, (x, i) => i + 1).map((value) => (
                <SelectModalOption
                  label={value.toString()}
                  value={values.numberOfOwners === value}
                  onClick={() => handleSelectOption(value)}
                  key={value}
                />
              ))}
            </div>
          )}
        />
      )}
    />
  )
}
