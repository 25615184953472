import React, {
  FormEvent,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { ExternalLink } from '../global/ExternalLink'
import { StepCircle } from '../global/StepCircle/StepCircle'
import { AppMarketQRCode } from '../global/appMarketLink/desktop/AppMarketQRCode'
import { Button } from '../global/button/Button'
import { useSessionEntity } from '../global/context/EntityContext'
import { useLegalLinksContext } from '../global/context/LegalLinksContext'
import { useLossPercentagesReadContext } from '../global/context/LossPercentagesContext'
import {
  useArabicSessionLanguage,
  useSessionLanguage,
} from '../global/context/SessionSettingsContext'
import { CancelActionModal } from '../global/modal/CancleActionModal'
import { InfoModal } from '../global/modal/InfoModal'
import { Modal } from '../global/modal/Modal'
import { BackIcon } from '../icons/BackIcon'
import { CloseIcon } from '../icons/CloseIcon'
import { ForwardIcon } from '../icons/ForwardIcon'
import { WarningIcon } from '../icons/WarningIcon'
import { requiresAppTestImprovement } from '../model/AccountDetailedDto'
import { AccountType } from '../model/AccountType'
import { NameDto } from '../model/NameDto'
import { TickmillProductType } from '../model/TickmillProductType'
import { Text, TextSmall, TextStrong } from '../ui/Typography/Typography'
import { useAccountReadContext, useAccountWriteContext } from '../utils/AccountContextContext'
import { AuthSessionContext } from '../utils/AuthContext'
import {
  TickmillCompaniesEnum,
  getTickmillCompanyByHostname,
  isTickmillPartnerType,
  isTickmillUKType,
} from '../utils/companyName.utils'
import { useWindowResize } from '../utils/domUtils'
import { isRegisterPath, isSignUpFirstStepsPath, isVerifyPhoneEmailPath } from '../utils/path'
import { isTradersRoomRouteAllowed } from '../utils/permission.utils'
import { useLocallyPersistedState } from '../utils/useStorage'
import { isOne, isZero } from '../utils/validations'

import styles from './SignupPage.module.scss'

enum SignupSections {
  AccountType = 1,
  PersonalDetails = 2,
  CompanyDetails = 3,
  FinancialInfo = 4,
  Documents = 5,
  ReviewConfirm = 6,
}

const TitlePerSection = {
  [SignupSections.AccountType]: 'Sign up.Account Type',
  [SignupSections.PersonalDetails]: 'Sign up.Personal Details',
  [SignupSections.CompanyDetails]: 'Sign up.Company Details',
  [SignupSections.FinancialInfo]: 'Sign up.Financial Info & Experience',
  [SignupSections.Documents]: 'Sign up.Documents',
  [SignupSections.ReviewConfirm]: 'Sign up.Review & Confirm',
  ConfirmRegistration: 'Sign up.Confirm Registration',
  AppropriatenessTest: 'Sign up.Appropriateness Test',
}

interface SignupStepProps {
  section: number
  accountTypeId?: number
  products?: NameDto[]
  subStep?: {
    current: number
    total: number
  }
  isFailed?: boolean
  isCompleted?: boolean
  isHidden?: boolean
}

interface LeftSectionProps {
  title: string
  description: () => ReactNode
  hasQRCodeSection?: boolean
  className?: string

  hideTitle?: boolean

  hideInfo?: boolean
}

export const hasAdditionalId = 'hasAdditionalId'

export const LeftSection: React.FC<LeftSectionProps> = ({
  title,
  description,
  hasQRCodeSection,
  className,
  hideTitle,
  hideInfo,
}) => {
  const { t } = useTranslation()
  const entity = useSessionEntity()
  const location = useLocation()
  const isSignupFirstSteps = isSignUpFirstStepsPath(location.pathname)

  return (
    <section
      className={classNames(styles.detailsSection, styles.start, className, {
        [styles.hasAdditionalGap]: !isSignupFirstSteps,
      })}
    >
      {!hideTitle && <h1 className={styles.title}>{title}</h1>}
      {entity === TickmillCompaniesEnum.TICKMILL_UK && !hideInfo && (
        <p className={classNames('pb-4', styles.textStrong)}>
          {t('Sign up.Please answer all questions with accurate information that reflect')}
        </p>
      )}
      {description()}
      {hasQRCodeSection && (
        <div className='my-5 is-hidden-mobile'>
          <AppMarketQRCode />
        </div>
      )}
    </section>
  )
}

export const SignupStep: React.FC<SignupStepProps> = (props) => {
  const { section, subStep, isCompleted, isFailed, isHidden } = props
  const { t } = useTranslation()
  const isMobile = useWindowResize()
  const tickmillEntity = useSessionEntity()
  const location = useLocation()
  const isRegisterPathStep = isRegisterPath(location.pathname)
  const isVerifyPhoneEmailPathStep = isVerifyPhoneEmailPath(location.pathname)

  const sectionTitle = requiresAppTestImprovement(tickmillEntity)
    ? t(TitlePerSection.AppropriatenessTest)
    : t(TitlePerSection[SignupSections.FinancialInfo])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  type SignupType = keyof typeof AccountType

  const showSteps = (signupType: SignupType) => {
    const skipDocumentStep = localStorage.getItem(hasAdditionalId) ? 1 : 0
    const baseStepsNumber = signupType === 'Corporate' ? 6 : 5
    const stepsNumber = baseStepsNumber - skipDocumentStep

    const showConfirmRegistration =
      section === SignupSections.PersonalDetails &&
      subStep?.current === 4 &&
      !isVerifyPhoneEmailPathStep &&
      !isRegisterPathStep

    const stepNames = {
      Corporate: [
        t(TitlePerSection[SignupSections.AccountType]),
        t(TitlePerSection[SignupSections.PersonalDetails]),
        t(TitlePerSection[SignupSections.CompanyDetails]),
        sectionTitle,
        skipDocumentStep
          ? t(TitlePerSection[SignupSections.ReviewConfirm])
          : t(TitlePerSection[SignupSections.Documents]),
        t(TitlePerSection[SignupSections.ReviewConfirm]),
      ],
      Individual: [
        t(TitlePerSection[SignupSections.AccountType]),
        showConfirmRegistration
          ? t(TitlePerSection.ConfirmRegistration)
          : t(TitlePerSection[SignupSections.PersonalDetails]),
        sectionTitle,
        skipDocumentStep
          ? t(TitlePerSection[SignupSections.ReviewConfirm])
          : t(TitlePerSection[SignupSections.Documents]),
        t(TitlePerSection[SignupSections.ReviewConfirm]),
      ],
    }

    return (
      <>
        {Array.from({ length: stepsNumber }, (_, i) => (
          <TextSmall
            key={i}
            className={classNames(styles.step, {
              [styles.active]: section === i + 1 && !isFailed && !isCompleted,
            })}
          >
            <StepCircle
              isActive={section === i + 1}
              isCompleted={(isCompleted && section === i + 1) || section > i + 1}
              section={i + 1}
              subStep={subStep}
              isFailed={isFailed && section === i + 1}
              isHidden={isHidden}
            />
            {!isMobile && stepNames[signupType][i]}
          </TextSmall>
        ))}
      </>
    )
  }

  if (props.accountTypeId === AccountType.Corporate && section !== 1) {
    return <div className={styles.stepsWrapper}>{showSteps('Corporate')}</div>
  }

  return (
    <section className={styles.stepsBox}>
      <div className={styles.stepsWrapper}>{showSteps('Individual')}</div>
    </section>
  )
}

interface SignupErrorStepProps {
  goBack(): void
}

export const SignupErrorStep: React.FC<SignupErrorStepProps> = (props) => {
  const { goBack } = props

  const isArabic = useArabicSessionLanguage()

  return (
    <section className={styles.stepsBox}>
      <span className={styles.backArrow} onClick={goBack}>
        {isArabic ? <ForwardIcon /> : <BackIcon />}
      </span>
    </section>
  )
}

interface Props {
  className?: string
  reversed?: boolean
  noBackground?: boolean
}

export const SignupWrapper: React.FC<PropsWithChildren<Props>> = ({
  children,
  className,
  reversed,
  noBackground,
}) => {
  return (
    <div
      className={classNames(styles.signupWrapper, className, {
        [styles.reversed]: reversed,
        [styles.noBackground]: noBackground,
      })}
    >
      {children}
    </div>
  )
}

interface FormValuesStep0 {
  typeId: number
}

interface AccountTypeStepProps {
  values: Partial<FormValuesStep0>
  accountType: number
  onAccountChange(value: number): void
  onSubmit(accountType: number): void
}

interface FewStepProps {
  accountType: number
}

export const AccountTypeStep: React.FC<AccountTypeStepProps> = (props) => {
  const { accountType, onSubmit, onAccountChange } = props

  const { t } = useTranslation()

  useEffect(() => {
    localStorage.removeItem('selectedProductIdsKey')
  }, [])

  const handleSubmitStep = (event: FormEvent) => {
    event.preventDefault()
    onSubmit(accountType)
  }

  return (
    <section className={styles.formSection}>
      <form className={styles.form}>
        <h3>{t('Sign up.Select Account type')}</h3>
        {[
          {
            id: AccountType.Individual,
            name: t('Sign up.Individual'),
          },
          {
            id: AccountType.Corporate,
            name: t('Sign up.Corporate'),
          },
        ].map((type) => (
          <div
            key={type.id}
            className='field has-cursor-pointer'
            onClick={() => onAccountChange(type.id)}
          >
            <div
              className={classNames('control', {
                [styles.isActive]: accountType === type.id,
              })}
            >
              <input
                className={classNames('radio')}
                type='radio'
                checked={accountType === type.id}
                onChange={() => onAccountChange(type.id)}
              />
              <label>{type.name}</label>
            </div>
          </div>
        ))}
        <Button
          onClick={handleSubmitStep}
          className={styles.button}
          type='submit'
          appearance='primary'
          size='L'
        >
          {t('Sign up.Proceed')}
        </Button>
      </form>
    </section>
  )
}

export const FewStepLeftSection: React.FC<FewStepProps> = (props) => {
  const { accountType } = props

  const { t } = useTranslation()

  const entity = getTickmillCompanyByHostname()

  return (
    <LeftSection
      title={
        isTickmillPartnerType(entity) ? t('Sign up.Few steps Partners') : t('Sign up.Few steps')
      }
      description={() => (
        <React.Fragment>
          {isTickmillUKType(entity) && (
            <Text isParagraph>
              {t('Sign up.Here is what you need to open a Tickmill CFD or Futures account:')}
            </Text>
          )}
          {isTickmillPartnerType(entity) && (
            <Text isParagraph>
              {t('Sign up.In order to open a Tickmill Partner account you will need:')}
            </Text>
          )}
          {!isTickmillUKType(entity) && !isTickmillPartnerType(entity) && (
            <Text isParagraph>
              {t('Sign up.In order to open a Tickmill CFD account you will need:')}
            </Text>
          )}
          <Text isParagraph>
            <b>{t('Sign up.Personal ID')}:</b>{' '}
            <span className='text-secondary'>{t(`Sign up.Passport`)}</span>
          </Text>
          <Text isParagraph>
            <b>{t('Sign up.Proof of Address')}:</b>{' '}
            <span className='text-secondary'>{t('Sign up.Bill')}</span>
          </Text>
          {accountType === AccountType.Corporate && (
            <Text isParagraph>
              <b>{t('Sign up.Company ID')}:</b>{' '}
              <span className='text-secondary'>{t('Sign up.Corporate Certificates')}</span>
            </Text>
          )}
        </React.Fragment>
      )}
      hasQRCodeSection={true}
    />
  )
}

export const FewStepSection: React.FC<FewStepProps> = (props) => {
  const { accountType } = props

  const { t } = useTranslation()

  const entity = useSessionEntity()

  if (
    !isTickmillUKType(entity) &&
    !isTickmillPartnerType(entity) &&
    accountType !== AccountType.Corporate
  ) {
    return null
  }

  return (
    <section className={classNames('styles.formSection', 'mb-4')}>
      <div className={styles.form}>
        {isTickmillUKType(entity) && (
          <>
            <h3>
              {isTickmillPartnerType(entity)
                ? t('Sign up.Few steps Partners')
                : t('Sign up.Few steps')}
            </h3>
            <Text isParagraph>
              {t('Sign up.Here is what you need to open a Tickmill CFD or Futures account:')}
            </Text>
          </>
        )}

        {isTickmillPartnerType(entity) && (
          <Text isParagraph>
            {t('Sign up.In order to open a Tickmill Partner account you will need:')}
          </Text>
        )}

        {isTickmillUKType(entity) && (
          <>
            <div className='mt-4'>
              <Text isParagraph>
                <b className='is-flex'>{t('Sign up.Personal ID')}:</b>{' '}
                <span className='text-secondary'>{t(`Sign up.Passport`)}</span>
              </Text>
            </div>
            <div className='mt-4'>
              <Text isParagraph>
                <b className='is-flex'>{t('Sign up.Proof of Address')}:</b>{' '}
                <span className='text-secondary'>{t('Sign up.Bill')}</span>
              </Text>
            </div>
          </>
        )}

        {accountType === AccountType.Corporate && (
          <div className='mt-4'>
            <Text isParagraph>
              <b className='is-flex'>{t('Sign up.Company ID')}:</b>{' '}
              <span className='text-secondary'>{t('Sign up.Corporate Certificates')}</span>
            </Text>
          </div>
        )}
      </div>
    </section>
  )
}

interface SignupFormWrapperProps {
  products?: NameDto[]
  label: string
  shouldHideBackArrow?: boolean
  selectedProductIds?: TickmillProductType[]

  onBackToPreviousStep?(): void
}

export const SignupFormWrapper: React.FC<PropsWithChildren<SignupFormWrapperProps>> = ({
  label,
  children,
  products,
  ...props
}) => {
  const navigate = useNavigate()

  const [auth] = useContext(AuthSessionContext)
  const locale = useSessionLanguage()
  const { account } = useAccountReadContext()
  const { refreshAccount } = useAccountWriteContext()
  const isArabic = useArabicSessionLanguage()
  const redirectLink = isTradersRoomRouteAllowed(account)
    ? '/dashboard/traders-room/wallets'
    : '/dashboard/introducing-broker/wallets'

  const [isHighRiskModalVisible, setHighRiskModalVisible] = useState(false)
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [closeIconVisible, setCloseIconVisible] = useState(false)
  const [selectedProductIds, setSelectedProductIds] = useLocallyPersistedState<
    TickmillProductType[]
  >('selectedProductIdsKey', [])

  const { percentages } = useLossPercentagesReadContext()

  useEffect(() => {
    if (Array.isArray(props.selectedProductIds)) {
      setSelectedProductIds(props.selectedProductIds || [])
    }
  }, [props.selectedProductIds])

  useEffect(() => {
    if (!props.shouldHideBackArrow && props.onBackToPreviousStep) {
      setCloseIconVisible(false)
    } else {
      setCloseIconVisible(true)
    }
  }, [props.onBackToPreviousStep, props.shouldHideBackArrow])

  const handleCancelAction = () => {
    if (!auth) {
      navigate('/login')
    } else {
      refreshAccount(locale)
      navigate(redirectLink)
    }
  }

  const productNames = products?.map((product) => product.name)

  return (
    <React.Fragment>
      {isHighRiskModalVisible && (
        <Modal
          closeModal={() => setHighRiskModalVisible(false)}
          render={({ closeModal }) => (
            <RiskWarningModal
              onCancel={closeModal}
              products={productNames}
              selectedProductIds={selectedProductIds}
              risk={percentages?.percentage}
            />
          )}
        />
      )}
      {confirmationModal && (
        <Modal
          closeModal={() => setConfirmationModal(false)}
          render={({ closeModal }) => (
            <CancelActionModal onConfirm={handleCancelAction} onCancel={closeModal} />
          )}
        />
      )}
      <div className={styles.wrapper}>
        <div>
          {!props.shouldHideBackArrow && props.onBackToPreviousStep && (
            <button
              id='signup_backarrow'
              onClick={props.onBackToPreviousStep}
              className={classNames('button', styles.closeButton)}
            >
              {isArabic ? <ForwardIcon /> : <BackIcon />}
            </button>
          )}
          <button
            type='button'
            className={classNames('button', styles.closeButton, {
              'is-hidden': !closeIconVisible,
            })}
            onClick={() => setConfirmationModal(true)}
          >
            <CloseIcon size={14} />
          </button>
          <h3>{label}</h3>
          <span className='has-cursor-pointer pt-3' onClick={() => setHighRiskModalVisible(true)}>
            <WarningIcon size={20} color={'warning'} />
          </span>
        </div>
        {children}
      </div>
    </React.Fragment>
  )
}

interface RiskWarningModalProps {
  products?: string[] | undefined
  selectedProductIds?: TickmillProductType[]
  risk: number | undefined

  onCancel(): void
}

export const RiskWarningModal: React.FC<RiskWarningModalProps> = (props) => {
  const { onCancel, risk, selectedProductIds } = props
  const entity = useSessionEntity()

  const { docLinks } = useLegalLinksContext()

  const nameToIdMapping: { [key: string]: number } = {
    'FX & CFDs': 1,
    CFD: 1,
    ETD: 2,
  }
  const productsAsIds = props.products?.map((product) => nameToIdMapping[product])
  const effectiveProductIds = productsAsIds ?? selectedProductIds

  const isProductTypeCFD =
    isOne(effectiveProductIds?.length) && effectiveProductIds?.includes(TickmillProductType.CFD)
  const isProductTypeETD =
    isOne(effectiveProductIds?.length) && effectiveProductIds?.includes(TickmillProductType.ETD)
  const isProductTypeCFDETD =
    isZero(effectiveProductIds?.length) ||
    (effectiveProductIds?.length === 2 &&
      effectiveProductIds?.includes(TickmillProductType.CFD) &&
      effectiveProductIds?.includes(TickmillProductType.ETD))

  const { t } = useTranslation()

  return (
    <InfoModal
      onCancel={onCancel}
      title={t('Sign up.Risk Warning')}
      renderBody={() => (
        <section className='modal-card-body'>
          <Text isParagraph>
            {entity === TickmillCompaniesEnum.TICKMILL_EU &&
              t('Sign up.Risk Warning Desc EU', {
                risk,
              })}
            {entity === TickmillCompaniesEnum.TICKMILL_AS &&
              !!risk &&
              t('Sign up.Risk Warning Desc Consolidated', {
                risk,
              })}
            {entity === TickmillCompaniesEnum.TICKMILL_AS &&
              !risk &&
              t('Sign up.Risk Warning Desc')}
            {entity === TickmillCompaniesEnum.TICKMILL_SC &&
              !!risk &&
              t('Sign up.Risk Warning Desc Consolidated', {
                risk,
              })}
            {entity === TickmillCompaniesEnum.TICKMILL_SC &&
              !risk &&
              t('Sign up.Risk Warning Desc')}
            {entity === TickmillCompaniesEnum.TICKMILL_PA &&
              !!risk &&
              t('Sign up.Risk Warning Desc Consolidated', {
                risk,
              })}
            {entity === TickmillCompaniesEnum.TICKMILL_PA &&
              !risk &&
              t('Sign up.Risk Warning Desc')}
            {entity === TickmillCompaniesEnum.GENERIC && t('Sign up.Risk Warning Desc')}
            {entity === TickmillCompaniesEnum.TICKMILL_UK && isProductTypeCFDETD && (
              <>
                {t('highRiskWarning.CFD', {
                  risk: risk,
                  entity: 'Tickmill UK Ltd',
                })}{' '}
                <ExternalLink className='is-link' url={docLinks?.riskDisclosure}>
                  {t('Footer.Risk Disclosure')}
                </ExternalLink>
                .
                <br />
                <br />
                {t('highRiskWarning.ETD', {
                  risk: risk,
                  entity: 'Tickmill UK Ltd',
                })}{' '}
                <ExternalLink className='is-link' url={docLinks?.riskDisclosure}>
                  {t('Footer.Risk Disclosure')}
                </ExternalLink>{' '}
                {t('highRiskWarning.document')}.
              </>
            )}
            {entity === TickmillCompaniesEnum.TICKMILL_UK &&
              isProductTypeETD &&
              !isProductTypeCFD && (
                <>
                  <TextStrong>
                    {t('Sign up.High Risk Warning', {
                      risk,
                    })}
                    :
                  </TextStrong>{' '}
                  {t('highRiskWarning.ETD', {
                    risk,
                  })}{' '}
                  <ExternalLink className='is-link' url={docLinks?.riskDisclosure}>
                    {t('Footer.Risk Disclosure')}
                  </ExternalLink>{' '}
                  {t('highRiskWarning.document')}.
                </>
              )}
            {entity === TickmillCompaniesEnum.TICKMILL_UK &&
              isProductTypeCFD &&
              !isProductTypeETD && (
                <>
                  {t('highRiskWarning.CFD', {
                    risk: risk,
                    entity: 'Tickmill UK Ltd',
                  })}{' '}
                  <ExternalLink className='is-link' url={docLinks?.riskDisclosure}>
                    {t('Footer.Risk Disclosure')}
                  </ExternalLink>
                  .
                </>
              )}
          </Text>
        </section>
      )}
      renderFooter={() => (
        <React.Fragment>
          <button className='button' onClick={onCancel} type='button'>
            <b>{t('Got It')}</b>
          </button>
        </React.Fragment>
      )}
      onConfirm={onCancel}
    />
  )
}
