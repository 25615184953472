import React from 'react'
import { useTranslation } from 'react-i18next'

import { ArrowLeftIcon } from '../../icons/ArrowLeftIcon'
import { ArrowRightIcon } from '../../icons/ArrowRightIcon'
import { Button } from '../button/Button'
import { useProductReadContext } from '../context/ProductContext'
import { useArabicSessionLanguage } from '../context/SessionSettingsContext'

import styles from './CompletionBlock.module.scss'

interface Props {
  onButtonClick: () => void
}
export const CompletionBlock: React.FC<Props> = (props) => {
  const { onButtonClick } = props
  const { isDefaultCFDProductType } = useProductReadContext()

  const isCFDProduct = isDefaultCFDProductType()

  const { t } = useTranslation()
  const isArabic = useArabicSessionLanguage()

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>{t('Congratulations') + '!'}</h1>
      <p className={styles.paragraph}>
        {isCFDProduct
          ? t('Your details are successfully updated')
          : t('You have successfully passed the test.')}
      </p>
      <p className={styles.paragraph}>
        {isCFDProduct
          ? t('You have now completed registration for the CFD product')
          : t('You have now completed the registration for your Futures account!')}
      </p>
      <Button
        className={styles.button}
        appearance='primary'
        size='L'
        type='button'
        onClick={onButtonClick}
      >
        {t('Go To Dashboard')}
        <span className='ml-2'>
          {isArabic ? <ArrowLeftIcon color='white' /> : <ArrowRightIcon color='white' />}
        </span>
      </Button>
    </div>
  )
}
