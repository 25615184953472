import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { Button } from '../../../../global/button/Button'
import { CopyButton } from '../../../../global/button/CopyButton'
import { useProductReadContext } from '../../../../global/context/ProductContext'
import { FormTemplate } from '../../../../global/templates/FormTemplate'
import { MasterTransaction } from '../../../../model/MasterTransaction'
import { TextStrong } from '../../../../ui/Typography/Typography'
import { useWindowResize } from '../../../../utils/domUtils'

import styles from './WalletDepositTransactionSuccessBankPage.module.scss'

interface WalletDepositTransactionSuccessProps {
  transaction: MasterTransaction
  onCancel(): void
}

export const WalletDepositTransactionSuccessBankPage: React.FC<
  WalletDepositTransactionSuccessProps
> = ({ transaction, onCancel }) => {
  const [walletTransaction] = transaction.walletTransactions
  const data = walletTransaction.paymentProviderTransaction.response.redirectData.data
  const reference = transaction.referenceId
  const navigate = useNavigate()
  const { isDefaultCFDProductType } = useProductReadContext()

  const handleClose = () => {
    navigate(
      isDefaultCFDProductType()
        ? '/dashboard/traders-room/wallets'
        : '/dashboard/traders-room/balances'
    )
  }

  const { t } = useTranslation()

  return (
    <FormTemplate title={t('Wallet.Deposit')} goBack={handleClose}>
      <div>
        <div className='field'>
          <label className={styles.label}>{t('Wallet.Deposit To Wallet')}</label>
          <div className='control'>
            <TextStrong>{walletTransaction.wallet.currency.id} Wallet</TextStrong>
          </div>
        </div>

        <div className='field'>
          <label className={styles.label}>{t('Wallet.Payment Method')}</label>
          <div className='control'>
            <TextStrong>
              {walletTransaction.paymentProviderTransaction.paymentProvider.name}
            </TextStrong>
          </div>
        </div>

        <div className='pt-4 pb-4'>
          <TextStrong isParagraph>
            {t(
              'Wallet.Please use the information below to make a bank transfer and fund your account'
            )}
          </TextStrong>
        </div>
        {Object.entries(data).map(([key, value = '']) => (
          <TransactionInformationField label={key} value={value} />
        ))}
        {reference && (
          <TransactionInformationField label={t('Common.Reference')} value={reference} />
        )}
        <div className='columns'>
          <div className='column'>
            <div className='buttons is-right'>
              <Button type='submit' appearance='secondary' onClick={onCancel} size='L'>
                {t('Wallet.Back to Dashboard')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </FormTemplate>
  )
}

interface TransactionInformationFieldProps {
  label: string
  value: string
}

const TransactionInformationField: React.FC<TransactionInformationFieldProps> = (props) => {
  const { label, value } = props

  const isMobile = useWindowResize()

  return (
    <div className='field'>
      <label className={styles.label}>{label}</label>
      <div className='control'>
        <div
          className={classNames('columns', {
            'is-flex': isMobile,
          })}
        >
          <div className='column'>
            <TextStrong>{value}</TextStrong>
          </div>
          <div className='column has-text-right is-flex-grow-0'>
            <CopyButton value={value} />
          </div>
        </div>
      </div>
    </div>
  )
}
