import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { ConversionIcon } from '../../../icons/ConversionIcon'
import { TradingAccount } from '../../../model/TradingAccount'
import { CurrencyType } from '../../../model/WalletDto'

import styles from './TradingAccountOptionsModal.module.scss'

interface Props {
  onCancel: () => void
  tradingAccount: TradingAccount
  currency: CurrencyType
}

export const TradingAccountOptionsModal: React.FC<Props> = ({
  onCancel,
  tradingAccount,
  currency,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const intiateConversion = ({
    tradingAccountId,
    currency,
    balance,
  }: {
    tradingAccountId: string
    currency?: CurrencyType
    balance?: number
  }) => {
    if (Number(balance) >= 0) {
      navigate(`/dashboard/traders-room/transactions/conversion`, {
        state: {
          tradingAccountId: tradingAccount.id,
          currency,
        },
      })
    } else {
      navigate('/dashboard/traders-room/transactions/conversion')
    }
  }

  return (
    <>
      <header className='modal-card-head'>
        <p className='modal-card-title'>
          {currency} {t('Trading Account.Trading Account')}
        </p>
      </header>
      <section className={classNames(styles.body, 'modal-card-body')}>
        <ul>
          <li
            onClick={() =>
              intiateConversion({
                tradingAccountId: tradingAccount.id,
                currency: currency,
                balance: tradingAccount.platformOverview.balances.find(
                  (b) => b.currency === currency
                )?.balance,
              })
            }
          >
            <div className={styles.wrapper}>
              <ConversionIcon color='text' strokeWidth={1.5} size={24} />
              <div>{t('Trading Account.Convert currencies between trading accounts')}</div>
            </div>
          </li>
        </ul>
      </section>
      <footer className='modal-card-foot'>
        <button className='button' onClick={onCancel}>
          {t('Cancel')}
        </button>
      </footer>
    </>
  )
}
