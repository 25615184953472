import { NameDto } from './NameDto'

export interface CompanyLegalDocumentDto {
  tickmillCompanyId: number
  productCountryLegalDocuments: ProductCountryLegalDocumentDto[]
}

interface ProductCountryLegalDocumentDto {
  tickmillProductId: number
  legalDocuments: LegalDocumentDto[]
}

export interface LegalDocumentDto {
  id: string
  code: string | null
  title: string | null
  url: string | null
  step: string | null
  type: NameDto
  showForAllCountries: boolean
  requiredForAllCountries: boolean
  availableCountries: LegalDocumentCountryDto[]
  groupReference: string | null
  order: number
}

export enum LegalDocumentWidget {
  CHECKBOX = 1,
  READ_AND_AGREE = 2,
}

export const isLegalDocumentWidgetCheckbox = (
  documents: LegalDocumentDto[] | LegalDocumentDto | undefined | null = []
) => {
  if (Array.isArray(documents)) {
    const [document] = documents
    if (!document) return false
    return LegalDocumentWidget.CHECKBOX === document?.type?.id
  } else {
    if (!documents) return false
    return LegalDocumentWidget.CHECKBOX === documents?.type?.id
  }
}

export const isLegalDocumentWidgetReadAndAgree = (
  documents: LegalDocumentDto[] | LegalDocumentDto | undefined | null
) => {
  if (Array.isArray(documents)) {
    const [document] = documents
    if (!document) return false
    return LegalDocumentWidget.READ_AND_AGREE === document?.type?.id
  } else {
    if (!documents) return false
    return LegalDocumentWidget.READ_AND_AGREE === documents?.type?.id
  }
}

export const isLegalDocumentsWidgetCheckbox = (documents: LegalDocumentDto[] = []) => {
  return documents.some(isLegalDocumentWidgetCheckbox)
}

export const isLegalDocumentsWidgetReadAndAgree = (documents: LegalDocumentDto[] = []) => {
  return documents.some(isLegalDocumentWidgetReadAndAgree)
}

interface LegalDocumentCountryDto {
  country: NameDto<string>
  required: boolean
}
