import React from 'react'
import { useTranslation } from 'react-i18next'

import { TransactionSuccessDialogueTemplate } from '../../../../global/templates/TransactionSuccessDialogueTemplate'
import { MasterTransaction } from '../../../../model/MasterTransaction'

import styles from './WalletDepositTransactionSuccessPaymentAgentPage.module.scss'

interface WalletDepositTransactionSuccessProps {
  transaction: MasterTransaction
  onCancel(): void
}

export const WalletDepositTransactionSuccessPaymentAgentPage: React.FC<
  WalletDepositTransactionSuccessProps
> = ({ transaction, onCancel }) => {
  const [walletTransaction] = transaction.walletTransactions
  const { t } = useTranslation()

  return (
    <TransactionSuccessDialogueTemplate title={t('Deposit Completed')} goBack={onCancel}>
      <div className='columns is-gapless is-mobile'>
        <div className='column'>{t('Wallet.To')}</div>
        <div className='column has-text-right'>
          <span className={styles.strong}>
            {walletTransaction.wallet.currency.id} {t('Wallet.Wallet')}
          </span>
        </div>
      </div>

      <div className='columns is-gapless is-mobile'>
        <div className='column'>{t('Wallet.Amount')}</div>
        <div className='column has-text-right'>
          <span className={styles.strong}>
            {walletTransaction.otherAmount} {walletTransaction.otherCurrency.id}
          </span>
        </div>
      </div>

      <div className='columns is-gapless is-mobile'>
        <div className='column'>{t('Wallet.Payment Method')}</div>
        <div className='column has-text-right'>
          <span className={styles.strong}>
            {walletTransaction.paymentProviderTransaction.paymentProvider.name}
          </span>
        </div>
      </div>
    </TransactionSuccessDialogueTemplate>
  )
}
