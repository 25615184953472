import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { useArabicSessionLanguage } from '../../../global/context/SessionSettingsContext'
import IconButton from '../../../global/iconButton/IconButton'
import { ThrottleSearchInput } from '../../../global/searchInput/ThrottleSearchInput'
import { BackIcon } from '../../../icons/BackIcon'
import { ChevronDownIcon } from '../../../icons/ChevronDownIcon'
import { ChevronUpIcon } from '../../../icons/ChevronUpIcon'
import { DotsIcon } from '../../../icons/DotsIcon'
import { ForwardIcon } from '../../../icons/ForwardIcon'
import { InfoIcon } from '../../../icons/InfoIcon'
import { SearchIcon } from '../../../icons/SearchIcon'
import { useWindowResize } from '../../../utils/domUtils'
import { isFunction } from '../../../utils/isFunction'
import { TextSmall, TextSmallStrong } from '../../Typography/Typography'
import {
  Action,
  ActionToggles,
  Calculation,
  Calculations,
  FilterToggles,
  FilterTogglesProps,
  MobileFilterToggles,
  Search,
  SearchProps,
  Tabs,
  TabsProps,
} from './PageHeaderParts'

import styles from './PageHeader.module.scss'

export interface TableHeaderProps {
  id?: string
  title?: string | React.ReactNode
  headTitle?: string | React.ReactNode
  subtitle?: string | React.ReactNode
  darkTitle?: string
  backButton?: () => void
  titleInfoToggle?: () => void
  actions?: Action[]
  filterToggles?: FilterTogglesProps
  optionsToggle?: () => void
  calculations?: Calculation[]
  search?: SearchProps
  searchInput?: {
    value: string
    onChange: (value: string) => void
  }
  renderHeadTitle?: () => void
  renderTitle?: RenderTitleType | React.ReactNode
  renderSubtitle?: () => void
  renderInfo?: () => React.ReactNode
  hideActions?: boolean
  className?: string
  tabsControl?: TabsProps
  middleRender?: React.ReactNode
  rightRender?: React.ReactNode
  showDetails?: boolean
  detailsContent?: React.ReactNode
  hasExtraMarginTop?: boolean
  wrapOnMobile?: boolean
}

type RenderTitleType = () => React.ReactNode

export const PageHeader: React.FC<TableHeaderProps> = (props) => {
  const { filterToggles, calculations, search, tabsControl, middleRender, showDetails } = props

  const isMobile = useWindowResize()

  const [detailsExpanded, setDetailsExpanded] = React.useState(false)

  return (
    <>
      <PageHeaderWrapper {...props}>
        <Header {...props} />
        {calculations && !isMobile && <Calculations calculations={calculations} />}
        {middleRender && middleRender}
        {isRenderRightSide(props) && <RightSide {...props} />}
      </PageHeaderWrapper>
      {showDetails && isMobile && (
        <ShowDetails
          {...props}
          detailsExpanded={detailsExpanded}
          setDetailsExpanded={setDetailsExpanded}
        />
      )}
      {search && <Search {...search} />}
      {calculations && isMobile && <Calculations calculations={calculations} />}
      {tabsControl && isMobile && <Tabs {...tabsControl} />}
      {filterToggles && isMobile && <MobileFilterToggles {...filterToggles} />}
    </>
  )
}

const Header: React.FC<TableHeaderProps> = (props) => {
  const { backButton, titleInfoToggle, renderTitle } = props
  const isArabic = useArabicSessionLanguage()

  return (
    <div className={styles.titleBlock}>
      <>
        {renderTitle ? (
          <React.Fragment>
            <MainTitle {...props} />
            <SubTitle {...props} />
          </React.Fragment>
        ) : (
          <div className={styles.title}>
            {backButton && (
              <IconButton onClick={backButton} className={styles.back}>
                {isArabic ? <ForwardIcon /> : <BackIcon />}
              </IconButton>
            )}

            <div>
              <HeadTitle {...props} />
              <div className={styles.titleBox}>
                <Title {...props} />
                {titleInfoToggle && (
                  <IconButton onClick={titleInfoToggle} className={styles.infoIcon}>
                    <InfoIcon />
                  </IconButton>
                )}
              </div>
              <SubTitle {...props} />
            </div>
          </div>
        )}
      </>
    </div>
  )
}

const RightSide: React.FC<TableHeaderProps> = (props) => {
  const {
    actions,
    filterToggles,
    optionsToggle,
    search,
    searchInput,
    hideActions,
    tabsControl,
    rightRender,
    renderInfo,
  } = props

  const isMobile = useWindowResize()
  const { t } = useTranslation()

  return (
    <div className={styles.modulesWrapper}>
      {rightRender}
      {search && (
        <div>
          <IconButton onClick={() => search.setShow(true)} className={styles.search}>
            <SearchIcon />
          </IconButton>
        </div>
      )}
      {tabsControl && !isMobile && <Tabs {...tabsControl} />}
      {!hideActions && actions && <ActionToggles renderInfo={renderInfo} actions={actions} />}
      {filterToggles && !isMobile && <FilterToggles {...filterToggles} />}
      {optionsToggle && (
        <IconButton className={styles.dots} onClick={optionsToggle}>
          <DotsIcon />
        </IconButton>
      )}
      {searchInput && (
        <ThrottleSearchInput
          throttle={300}
          onRequestAutocomplete={searchInput.onChange}
          initialValue={searchInput.value}
          placeholder={t('Search')}
        />
      )}
    </div>
  )
}

interface ShowDetailsProps extends TableHeaderProps {
  detailsExpanded: boolean
  setDetailsExpanded(value: boolean): void
}

const ShowDetails: React.FC<ShowDetailsProps> = (props) => {
  const { detailsContent, detailsExpanded, setDetailsExpanded } = props

  const { t } = useTranslation()

  return (
    <div>
      {detailsExpanded && <div className={styles.detailsContent}>{detailsContent}</div>}
      <TextSmallStrong
        onClick={() => setDetailsExpanded(!detailsExpanded)}
        className={styles.details}
      >
        {t('Details')} {detailsExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </TextSmallStrong>
    </div>
  )
}

const PageHeaderWrapper: React.FC<PropsWithChildren<TableHeaderProps>> = (props) => {
  const {
    id,
    actions,
    className,
    showDetails,
    hasExtraMarginTop = false,
    wrapOnMobile = false,
    children,
  } = props

  const isMobile = useWindowResize()

  return (
    <div
      id={id}
      className={classNames(styles.tableHeader, className, {
        [styles.expandedDetails]: showDetails && isMobile,
        [styles.hasExtraMarginTop]: hasExtraMarginTop,
        [styles.wrapOnMobile]:
          (isMobile && (actions?.filter(({ hidden }) => !hidden).length ?? 0) > 1) || wrapOnMobile,
      })}
    >
      {children}
    </div>
  )
}

const Title: React.FC<TableHeaderProps> = (props) => {
  const { darkTitle, title } = props

  return (
    <h3 className={styles.mainTitle}>
      {darkTitle && (
        <span
          className={classNames({
            [styles.gray]: darkTitle,
          })}
        >
          {darkTitle}
        </span>
      )}
      {title}
    </h3>
  )
}

const HeadTitle: React.FC<TableHeaderProps> = (props) => {
  if (props?.renderHeadTitle) {
    return <>{props.renderHeadTitle()}</>
  }

  if (props.headTitle) {
    return (
      <TextSmall isParagraph className={styles.subtitle}>
        {props.headTitle}
      </TextSmall>
    )
  }

  return null
}

const MainTitle: React.FC<TableHeaderProps> = (props) => {
  if (props?.renderTitle && isFunction(props?.renderTitle)) {
    return <>{props.renderTitle()}</>
  }

  if (props?.renderTitle) {
    return <>{props.renderTitle}</>
  }

  return null
}

const SubTitle: React.FC<TableHeaderProps> = (props) => {
  if (props?.renderSubtitle) {
    return <>{props.renderSubtitle()}</>
  }

  if (props.subtitle) {
    return (
      <TextSmall isParagraph className={styles.subtitle}>
        {props.subtitle}
      </TextSmall>
    )
  }

  return null
}

const isRenderRightSide = (props: TableHeaderProps) => {
  const { actions, filterToggles, optionsToggle, search, searchInput, rightRender } = props

  return actions || filterToggles || optionsToggle || search || searchInput || rightRender
}
