import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'

import { AuthSessionContext } from '../../utils/AuthContext'
import { useWindowResize } from '../../utils/domUtils'
import { isSignUpPath } from '../../utils/path'
import { DesktopLoginNav } from './DesktopNav/DesktopLoginNav'
import { DesktopNav } from './DesktopNav/DesktopNav'
import { MobileLoginNav } from './MobileNav/MobileLoginNav'
import { MobileNav } from './MobileNav/MobileNav'

interface NavProps {
  overrideLogin?: boolean
}

export const Nav = React.memo(({ overrideLogin }: NavProps) => {
  const [auth] = useContext(AuthSessionContext)
  const location = useLocation()
  const isMobile = useWindowResize()

  if (isSignUpPath(location.pathname) || overrideLogin) {
    if (isMobile) return <MobileLoginNav />
    return <DesktopLoginNav />
  } else if (auth) {
    if (isMobile) return <MobileNav />
    return <DesktopNav />
  } else {
    if (isMobile) return <MobileLoginNav />
    return <DesktopLoginNav />
  }
})
