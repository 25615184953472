/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import WheelFrameAR from '../../images/wheel-frame-ar.png'
import WheelFrameEN from '../../images/wheel-frame-en.png'
import WheelFramePT from '../../images/wheel-frame-pt.png'
import WheelFrameVI from '../../images/wheel-frame-vi.png'
import WheelFrameZH from '../../images/wheel-frame-zh.png'
import { Sector } from '../../model/LuckyDrawDto'
import { useSessionLanguage } from '../context/SessionSettingsContext'

import styles from './FortuneWheel.module.scss'

interface FortuneWheelProps {
  winnerIndex: number
  sectors: Sector[]
  setWon: () => void
}

export interface WheelRef {
  startSpinning(): void
}

interface SpinData {
  tot: number
  dia: number
  rad: number
  pi: number
  tau: number
  arc: number
  friction: number
  angVelMin: number
  angVelMax: number
  angVel: number
  ang: number
  rounds: number
}

export const FortuneWheel = forwardRef<WheelRef, FortuneWheelProps>(
  ({ winnerIndex, sectors, setWon }, ref) => {
    const { t } = useTranslation()
    const locale = useSessionLanguage()

    const wheelFrame = useMemo(() => {
      switch (locale) {
        case 'zh':
          return WheelFrameZH
        case 'vi':
          return WheelFrameVI
        case 'pt':
          return WheelFramePT
        case 'ar':
          return WheelFrameAR
        default:
          return WheelFrameEN
      }
    }, [locale])

    const spinConfig = {
      tot: sectors.length,
      dia: 0,
      rad: 0,
      pi: Math.PI,
      tau: 2 * Math.PI,
      arc: (2 * Math.PI) / sectors.length,
      friction: 0.995,
      angVelMin: 0.002,
      angVelMax: 0,
      angVel: 0,
      ang: 0,
      rounds: 8,
    }
    const wheelRef = useRef<HTMLCanvasElement>(null)
    const [spinData, setSpinData] = useState<SpinData>(spinConfig)
    const [started, setStarted] = useState<boolean>(false)
    let isAccelerating: boolean = false
    let isSpinning: boolean = false
    let onWinner: boolean = false

    const getIndex = () => {
      const { tot, ang, tau } = spinData
      if (tot && ang && tau) return Math.floor(tot - (ang / tau) * tot) % tot
      else return 0
    }

    const drawSector = (sector: Sector, i: number, ctx: CanvasRenderingContext2D) => {
      if (!ctx || !spinData) return
      const { arc, rad } = spinData
      const ang = arc * i
      ctx.save()
      // COLOR
      ctx.beginPath()
      ctx.fillStyle = i % 2 ? '#bb0000' : '#fdfefe'
      ctx.moveTo(rad, rad)
      ctx.arc(rad, rad, rad, ang, ang + arc)
      ctx.lineTo(rad, rad)
      ctx.fill()
      // TEXT
      ctx.translate(rad, rad)
      ctx.rotate(ang + arc / 2)
      ctx.textAlign = 'right'
      ctx.fillStyle = i % 2 ? '#fdfefe' : '#c70009'
      ctx.font = 'bold 20px sans-serif'
      const prizeText = sector?.currency?.id
        ? t(sector?.currency?.id, { amount: sector?.amount.toString() })
        : sector?.amount.toString()
      ctx.fillText(prizeText, rad - 10, 10)

      ctx.restore()
    }

    const rotate = (ctx: CanvasRenderingContext2D) => {
      if (!ctx || !spinData) return
      const { ang, pi } = spinData
      ctx.canvas.style.transform = `rotate(${ang - pi / 2}rad)`
    }

    const frame = (ctx: CanvasRenderingContext2D) => {
      if (!spinData || !isSpinning) return
      let newSpinData = spinData
      if (newSpinData.angVel >= newSpinData.angVelMax) isAccelerating = false
      if (getIndex() !== winnerIndex) onWinner = false
      if (getIndex() === winnerIndex) {
        if (!onWinner) newSpinData.rounds--
        onWinner = true
        if (newSpinData.rounds < 1) {
          isAccelerating = false
          newSpinData.angVel *= 0.94
        }
      }

      // Accelerate
      if (isAccelerating) {
        newSpinData.angVel ||= newSpinData.angVelMin // Initial velocity kick
        newSpinData.angVel *= 1.06 // Accelerate
      } else {
        isAccelerating = false
        newSpinData.angVel *= newSpinData.friction // Decelerate by friction

        // SPIN END:
        if (newSpinData.angVel < newSpinData.angVelMin) {
          if (started) setWon()
          isSpinning = false
          newSpinData.angVel = 0
          newSpinData.rounds = 8
        }
      }

      newSpinData.ang += newSpinData.angVel // Update angle
      newSpinData.ang %= newSpinData.tau // Normalize angle
      if (Object.entries(spinData).toString() !== Object.entries(newSpinData).toString())
        setSpinData(newSpinData)
      rotate(ctx) // CSS rotate!
    }

    useImperativeHandle(ref, () => ({
      startSpinning() {
        if (isSpinning) return
        setStarted(true)
        isSpinning = true
        isAccelerating = true
        setSpinData({ ...spinData, angVelMax: 0.25 })
      },
    }))

    const renderPrizeSectors = (ctx: CanvasRenderingContext2D) => {
      sectors.forEach((sector, i) => drawSector(sector, i, ctx))
    }

    useEffect(() => {
      setSpinData(spinConfig)
    }, [winnerIndex])

    const handleWheel = async () => {
      if (!wheelRef.current) return
      const elWheel: HTMLCanvasElement = wheelRef.current
      const ctx = elWheel.getContext('2d')!
      const newSpinData = {
        ...spinData,
        dia: ctx.canvas.width,
        rad: ctx.canvas.width / 2,
      }
      if (JSON.stringify(spinData) !== JSON.stringify(newSpinData)) setSpinData(newSpinData)
      renderPrizeSectors(ctx)
      isAccelerating = true
      isSpinning = true
      const engine = () => {
        frame(ctx)
        requestAnimationFrame(engine)
      }

      // INIT!
      rotate(ctx) // Initial rotation
      engine() // Start engine!
    }

    useEffect(() => {
      handleWheel()
    }, [sectors, isSpinning, winnerIndex, wheelRef.current])

    return (
      <div className={styles.wheelOfFortune} id='wheelOfFortune'>
        <canvas
          ref={wheelRef}
          id='wheel'
          className={styles.wheel}
          width='300'
          height='300'
        ></canvas>
        <img src={wheelFrame} alt='Wheel frame' className={styles.wheelFrame} />
      </div>
    )
  }
)
