import React, { useEffect } from 'react'

import { usePublicDomainsReadContext } from '../global/context/PublicDomainsContext'
import {
  overrideForZHToCNLanguage,
  useSessionLanguage,
} from '../global/context/SessionSettingsContext'
import { useTraderUrlByPlatform } from '../hooks/useTraderUrlByPlatform'

interface SearchParamsProps {
  homePageUrl?: string
  locale?: string
  languageId?: string
  server?: string
  version?: string
  login?: string
}

const useSearchParams = (): SearchParamsProps => {
  const url = new URL(window.location.href)
  const searchParams = url.searchParams

  const { domains } = usePublicDomainsReadContext()
  const locale = useSessionLanguage()
  const languageId = overrideForZHToCNLanguage(locale)

  const homePageUrl = domains?.homePageUrl

  const server = searchParams.get('server') || undefined
  const version = searchParams.get('version') || undefined
  const login = searchParams.get('login') || undefined

  return { homePageUrl, locale, languageId, server, version, login }
}

export const TradingAccountTradeRedirect: React.FC = () => {
  const searchParams = useSearchParams()
  const url = useTraderUrlByPlatform()

  useEffect(() => {
    if (url) {
      setTimeout(() => {
        window.location.href = url
      }, 1000)
    }
  }, [searchParams])

  return null
}
