import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { createFormField } from '../../../../global/formField/FormField'
import { createFormRadioField } from '../../../../global/formField/RadioGroupField'
import { RadioButton } from '../../../../global/radioButton/RadioButton'
import { ClientNciPriorityDto } from '../../../../model/ClientNciPriorityDto'
import { isOne } from '../../../../utils/validations'
import { DocumentManagementNCIFormValues } from './DocumentManagementNCIForm'

import styles from './DocumentNumberFieldFactory.module.scss'

const FormRadioGroupField = createFormRadioField<DocumentManagementNCIFormValues>()
const FormField = createFormField<DocumentManagementNCIFormValues>()

interface DocumentNumberFieldFactoryProps {
  handleChooseAnotherDocument?: () => void
  showChooseAnotherDoc?: boolean
}

export const DocumentNumberFieldFactory: React.FC<DocumentNumberFieldFactoryProps> = (props) => {
  const { values } = useFormikContext<DocumentManagementNCIFormValues>()
  return (
    <div className='pt-5'>
      {isOneDocumentType(values.accountNciAvailablePriorities) && (
        <DocumentNumberField {...props} />
      )}
      {isMultipleDocumentTypes(values.accountNciAvailablePriorities) && (
        <DocumentRadioField {...props} />
      )}
    </div>
  )
}

const DocumentNumberField: React.FC<DocumentNumberFieldFactoryProps> = (props) => {
  const { values } = useFormikContext<DocumentManagementNCIFormValues>()
  const { handleChooseAnotherDocument, showChooseAnotherDoc } = props
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <div className='pb-4'>
        <h3>{t('Profile.ID-Document:')}</h3>
      </div>
      <FormField
        name='nciHistory.nciDocumentNumber'
        label={values.accountNciAvailablePriorities.nciRule.shortName}
        placeholder={values.accountNciAvailablePriorities.nciRule.shortName}
        required
      />
      {showChooseAnotherDoc && (
        <>
          <div className='pt-2 pb-2'>
            <p className={styles.text}>
              {t("Profile.Can't provide")} {values.accountNciAvailablePriorities.nciRule.shortName}?
            </p>
          </div>
          <div className='pt-2 pb-2'>
            <p className={styles.text}>
              <span onClick={handleChooseAnotherDocument} className='is-link'>
                {t('Sign up.Choose another document')}
              </span>
            </p>
          </div>
        </>
      )}
    </React.Fragment>
  )
}

const DocumentRadioField: React.FC<DocumentNumberFieldFactoryProps> = (props) => {
  const { values } = useFormikContext<DocumentManagementNCIFormValues>()
  const { handleChooseAnotherDocument, showChooseAnotherDoc } = props
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <FormRadioGroupField
        name='nciHistory.documentTypeId'
        value={values.nciHistory.documentTypeId}
      >
        {values.accountNciAvailablePriorities.documentTypes.map((x) => (
          <React.Fragment>
            <RadioButton
              name='nciHistory.documentTypeId'
              label={x.name}
              value={x.documentTypeId}
              key={x.name}
              checked={x.documentTypeId === Number(values.nciHistory.documentTypeId)}
            />
          </React.Fragment>
        ))}
      </FormRadioGroupField>
      <FormField
        name='nciHistory.nciDocumentNumber'
        label={values.accountNciAvailablePriorities.nciRule.shortName}
        placeholder={values.accountNciAvailablePriorities.nciRule.shortName}
        required
      />
      {showChooseAnotherDoc && (
        <div className='pt-2 pb-2'>
          <p className={styles.text}>
            <span onClick={handleChooseAnotherDocument} className='is-link'>
              {t('Sign up.Choose another document')}
            </span>
          </p>
        </div>
      )}
    </React.Fragment>
  )
}

const isOneDocumentType = (value: ClientNciPriorityDto) => {
  return isOne(value.documentTypes.length)
}
const isMultipleDocumentTypes = (value: ClientNciPriorityDto) => {
  return value.documentTypes.length > 1
}
