import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, isString } from 'formik'

import { DocumentFileUploads } from '../../global/fileUpload/DocumentFileUploads'
import { DoubleSidedDocumentFileUploads } from '../../global/fileUpload/DoubleSidedDocumentFileUploads'
import { FileData } from '../../global/fileUpload/FileUpload'
import { isFileDuplicated, removeUploadedFile } from '../../global/fileUpload/FileUploads'
import { InformationModal } from '../../global/modal/InformationModal'
import { Modal } from '../../global/modal/Modal'
import { isDoubleSidedDocument } from '../../utils/uploadDocument'

interface DocumentFileUploadsFactoryProps {
  buttonText?: string
  files?: FileData[]
  documentTypeId: number | string
  documentCategory: number
  onChange(fileData: FileData[], currentFile?: FileData): void
  onBeforeRemove?(file: FileData): void
  needFileUploadRefresh?: boolean
}

interface DocumentManagementDocument {
  filename: string
  error: boolean
}

export const DocumentFileUploadsFactory: React.FC<DocumentFileUploadsFactoryProps> = (props) => {
  const { files = [], documentTypeId, onChange } = props

  const { t } = useTranslation()

  const [documents, setDocuments] = useState<FileData[]>(files)
  const [isFileDuplicatedModal, setFileDuplicatedModal] = useState(false)

  useEffect(() => {
    setDocuments([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentTypeId])

  const handleFileUpload = (data: FileData[], currentFile: FileData) => {
    if (isFileDuplicated(data, currentFile)) {
      const newDocuments = removeUploadedFile(data, currentFile)
      setFileDuplicatedModal(true)
      setDocuments(newDocuments)
      onChange(documents)
    } else {
      setDocuments(data)
      onChange(data)
    }
  }

  return (
    <>
      {isFileDuplicatedModal && (
        <Modal
          closeModal={() => setFileDuplicatedModal(false)}
          render={() => (
            <InformationModal
              title={t('Upload failed')}
              buttonText={t('Got It')}
              onCancel={() => setFileDuplicatedModal(false)}
            >
              <span>{t('errors.Document is already uploaded!')}</span>
            </InformationModal>
          )}
        />
      )}
      <DocumentFileUploadsComponent {...props} files={documents} onChange={handleFileUpload} />
    </>
  )
}

const DocumentFileUploadsComponent: React.FC<DocumentFileUploadsFactoryProps> = (props) => {
  const {
    documentTypeId,
    documentCategory,
    buttonText,
    files = [],
    onChange,
    onBeforeRemove,
    needFileUploadRefresh,
  } = props

  const { t } = useTranslation()

  const documentType = getDocumentType(documentTypeId)
  const isDoubleSided = isDoubleSidedDocument(documentCategory, documentType)
  if (isDoubleSided) {
    return (
      <Field name='documents' validate={validateDocuments(t('Validation.Required'))}>
        {() => (
          <DoubleSidedDocumentFileUploads
            documentCategory={documentCategory}
            files={files}
            buttonText={buttonText}
            onChange={onChange}
            onBeforeRemove={onBeforeRemove}
            needRefresh={needFileUploadRefresh}
          />
        )}
      </Field>
    )
  }

  return (
    <DocumentFileUploads
      documentCategory={documentCategory}
      files={files}
      buttonText={buttonText}
      onChange={onChange}
      onBeforeRemove={onBeforeRemove}
      needRefresh={needFileUploadRefresh}
    />
  )
}

const validateDocuments = (message: string) => (value: DocumentManagementDocument[]) => {
  const documents = value.filter((x) => !!x?.filename).filter((x) => !x.error)

  if (documents.length <= 1) {
    return message
  }

  return undefined
}

const getDocumentType = (documentTypeId: string | number) => {
  return isString(documentTypeId) ? Number.parseInt(documentTypeId, 10) : documentTypeId
}
