import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Button } from '../../../global/button/Button'
import { useFormatNumber } from '../../../hooks/useFormatNumber'
import { DotsIcon } from '../../../icons/DotsIcon'
import { CurrencyBalance } from '../../../model/TradingAccount'

import styles from './TradingAccountBalanceCard.module.scss'

interface TradingAccountBalanceCardProps {
  balance: CurrencyBalance
  handleSetSelectedTradingAccount(): void
  initiateTransfer: () => void
}

export const TradingAccountBalanceCard: React.FC<TradingAccountBalanceCardProps> = ({
  balance,
  handleSetSelectedTradingAccount,
  initiateTransfer,
}) => {
  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()

  return (
    <div className={styles.card}>
      <span className={classNames(styles.textSmall, 'text-secondary')}>
        {balance.currency}
        <span className='is-pulled-right'>
          <span onClick={handleSetSelectedTradingAccount} className='has-cursor-pointer'>
            <DotsIcon />
          </span>
        </span>
      </span>
      <h2 className='mb-4 mt-4 has-text-weight-semibold'>
        {formatMoney(balance.balance, balance.currency)}
      </h2>

      <div className='mb-4'>
        <Button
          onClick={initiateTransfer}
          size='M'
          className={styles.button}
          appearance='secondary'
        >
          {t('Transfer')}
        </Button>
      </div>
    </div>
  )
}
