import { useEffect } from 'react'

export enum ScrollToIds {
  IBSignupHeader = 'ib-signup-header',
  IBProfileHeader = 'ib-profile-header',
  IBSignupContainer = 'ib-signup-container',
  ToolsPlatformsHeader = 'tools-platforms-header',
}

export const useScrollToElementIds = (): {
  scrollIntoView: (elementIds: ScrollToIds[]) => void
  useScrollIntoViewEffect: (elementIds: ScrollToIds[]) => void
} => {
  const scrollIntoView = (elementIds: ScrollToIds[]) => {
    elementIds.some((elementId) => {
      const element = document.getElementById(elementId)
      if (!element) return false
      const rect = element.getBoundingClientRect()
      if (rect.top < 0 || rect.bottom > document.documentElement.clientHeight) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
      return true
    })
  }

  const useScrollIntoViewEffect = (elementIds: ScrollToIds[]) => {
    const stringifiedIds = JSON.stringify(elementIds)

    useEffect(() => {
      if (stringifiedIds) scrollIntoView(JSON.parse(stringifiedIds) as ScrollToIds[])
    }, [stringifiedIds])
  }

  return { scrollIntoView, useScrollIntoViewEffect }
}
