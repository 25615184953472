import React, { PropsWithChildren } from 'react'

import { LoadingOverlay } from './LoadingOverlay'
import { LoadingWrapper } from './LoadingWrapper'

export interface LoadingProps {
  isLoading: boolean
  text?: string
  className?: string
  showLoadingIcon?: boolean
  isModal?: boolean
  asOverlay?: boolean
}

export const Loading: React.FunctionComponent<PropsWithChildren<LoadingProps>> = (props) => {
  const { isLoading, children, asOverlay } = props

  if (asOverlay && isLoading) return <LoadingOverlay {...props} />

  if (isLoading) return <LoadingWrapper {...props} />

  return <React.Fragment>{children}</React.Fragment>
}
