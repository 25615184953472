import React from 'react'

import { InfoIcon } from '../../icons/InfoIcon'

interface InfoIconWrapperProps {
  onClick: () => void
}

export const InfoIconWrapper: React.FC<InfoIconWrapperProps> = ({ onClick }) => {
  return (
    <div onClick={onClick} className='is-flex is-clickable'>
      <InfoIcon />
    </div>
  )
}
