import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Loading } from '../../../global/Loading/Loading'
import { useSessionLanguage } from '../../../global/context/SessionSettingsContext'
import { InformationModal } from '../../../global/modal/InformationModal'
import { Modal } from '../../../global/modal/Modal'
import { ToastContext, successToast } from '../../../global/toast/Toast'
import { NameDto } from '../../../model/NameDto'
import { Text } from '../../../ui/Typography/Typography'
import { useAccountWriteContext } from '../../../utils/AccountContextContext'
import { useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import { useFetchOne } from '../../../utils/useFetch'
import { getDocUploadFailMessage } from '../documentManagementUploadStatuses'
import {
  DocumentManagementNonNCIForm,
  DocumentManagementNonNCIFormValues,
} from './DocumentManagementNonNCIForm'

export const useDocumentManagementNonNCIFetch = () => {
  const location = useLocation()

  const locale = useSessionLanguage()

  const apiClient = useApiClient(ClientApiClient)

  const documentCategoryId = location.state?.documentCategory?.id
  const documentCategoryTypeId = location.state?.documentCategoryType?.id

  const callback = useCallback(async () => {
    if (documentCategoryId) return apiClient.getDocumentCategories(documentCategoryId, locale)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentCategoryId, locale])

  const { data, isLoading } = useFetchOne(callback)

  const documentCategoryTypeName =
    data?.types.find((x) => x.id === documentCategoryTypeId)?.name || ''

  const documentId = data?.types.find((x) => x.id === documentCategoryTypeId)?.id

  return {
    data,
    documentId,
    documentCategoryTypeName,
    isLoading,
  }
}

export const useDocumentManagementNonNCIMutate = () => {
  const navigate = useNavigate()

  const apiClient = useApiClient(ClientApiClient)
  const setToast = useContext(ToastContext)
  const { t } = useTranslation()

  const [isLoading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const mutate = async (
    values: DocumentManagementNonNCIFormValues,
    setUploadErrorModal: (visible: boolean) => void
  ) => {
    try {
      setLoading(true)
      await apiClient.uploadDocuments({ documents: values.documents })
      navigate('/profile/document-management')
      setToast(successToast(t('Profile.Documents submitted and will be reviewed within 1 workday')))
    } catch (error: unknown) {
      setUploadErrorModal(true)
      setErrorMessage((error as any).response?.response?.data?.code)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  return {
    mutate,
    isLoading,
    errorMessage,
    setErrorMessage,
  }
}

interface DocumentManagementNonNCIProps {
  documentTitle: string
  showTerms?: boolean
}

export const DocumentManagementNonNCIPage: React.FC<DocumentManagementNonNCIProps> = (props) => {
  const { documentTitle, showTerms = true } = props

  const [uploadErrorModal, setUploadErrorModal] = useState(false)

  const navigate = useNavigate()

  const { t } = useTranslation()
  const { refreshAccount } = useAccountWriteContext()
  const locale = useSessionLanguage()

  const location = useLocation()

  const [values] = useState<{
    documentCategory: NameDto
    documentCategoryType: NameDto
  }>(location.state)

  const { documentCategoryTypeName, documentId } = useDocumentManagementNonNCIFetch()
  const mutation = useDocumentManagementNonNCIMutate()

  useEffect(() => {
    if (!values) {
      navigate(`/profile/document-management`)
    }
  }, [navigate, values])

  const handleSubmit = async (values: DocumentManagementNonNCIFormValues) => {
    try {
      await mutation.mutate(values, setUploadErrorModal)
      await refreshAccount(locale)
    } catch (e: unknown) {
      console.error(e)
      throw e
    }
  }

  const handlePageExitConfirmation = () => {
    navigate(`/profile/document-management`)
  }
  const handleCloseErrorModal = () => {
    setUploadErrorModal(false)
    mutation.setErrorMessage('')
  }

  return (
    <React.Fragment>
      {uploadErrorModal && (
        <Modal
          closeModal={handleCloseErrorModal}
          render={() => (
            <InformationModal
              onCancel={handleCloseErrorModal}
              buttonText={t('Got It')}
              title={t('Upload failed')}
            >
              <Text isParagraph>{t(getDocUploadFailMessage(mutation.errorMessage))}</Text>
            </InformationModal>
          )}
        />
      )}
      {!mutation.isLoading && values && (
        <DocumentManagementNonNCIForm
          documentId={documentId}
          documentTitle={documentTitle}
          documentCategoryTypeName={documentCategoryTypeName}
          documentCategory={values.documentCategory}
          documentCategoryType={values.documentCategoryType}
          showTerms={showTerms}
          onSubmit={handleSubmit}
          onCancel={handlePageExitConfirmation}
        />
      )}
      {mutation.isLoading && (
        <Loading isLoading={mutation.isLoading} showLoadingIcon text={t('Wallet.Submitting')} />
      )}
    </React.Fragment>
  )
}
