import React, { ReactNode, useCallback, useEffect, useLayoutEffect } from 'react'
import classNames from 'classnames'

import { dispatchCloseModalEvent, dispatchOpenModalEvent } from '../../utils/cookie.utils'

import styles from './Modal.module.scss'

interface HasCloseModal {
  closeModal: () => void
}

interface Props extends HasCloseModal {
  size?: 'small' | 'large' | 'xsmall'
  cardClassName?: string
  modalBackgroundClass?: string
  isBackgroundLess?: boolean
  pullDown?: boolean
  render: (props: HasCloseModal) => ReactNode
}

export const Modal: React.FC<Props> = ({
  closeModal,
  size,
  isBackgroundLess,
  cardClassName,
  pullDown = false,
  modalBackgroundClass,
  render,
}) => {
  useLayoutEffect(() => {
    if (document.activeElement) {
      ;(document.activeElement as HTMLElement).blur()
    }
    document.documentElement.classList.add('is-clipped')
    dispatchOpenModalEvent()
    return () => {
      dispatchCloseModalEvent()
      return document.documentElement.classList.remove('is-clipped')
    }
  }, [])

  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeModal()
      }
    },
    [closeModal]
  )

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp)
    return () => {
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [handleKeyUp])

  return (
    <div
      id='modal'
      className={classNames('modal', 'is-active', styles.modal, {
        ['is-' + size]: !!size,
        [styles.pullDown]: pullDown,
      })}
      onMouseDown={(e) => {
        e.stopPropagation()
        closeModal()
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {!isBackgroundLess && (
        <div
          className={classNames('modal-background', styles.modalBackground, modalBackgroundClass)}
        />
      )}
      <div
        className={classNames('modal-card', styles.modalCard, cardClassName)}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <div className={styles.modalWrapper}>{render({ closeModal })}</div>
      </div>
    </div>
  )
}
