import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { Loading } from '../../global/Loading/Loading'
import { CheckBox } from '../../global/checkBox/CheckBox'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { InformationModal } from '../../global/modal/InformationModal'
import { Modal } from '../../global/modal/Modal'
import { ScrollToIds, useScrollToElementIds } from '../../hooks/useScrollToElementIds'
import {
  PlatformInfoDto,
  isPlatformExternalApplicationACUITY,
  isPlatformExternalApplicationFXBLUE,
} from '../../model/PlatformInfoDto'
import {
  OperatingSystemEnum,
  PlatformTypeEnum,
  isMT4PlatformType,
  isMT5PlatformType,
  isTMTPlatformType,
} from '../../model/PlatformTypeEnum'
import { SwitcherTabs } from '../../ui/SwitcherTabs/SwitcherTabs'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { getDomainName } from '../../utils/cookie.utils'
import { useFetchOne } from '../../utils/useFetch'
import { useScrollToTop } from '../../utils/useScrollToTop'
import {
  MTDownloadIcon,
  ManualIconFactory,
  PlatformDownloadButton,
  PlatformIconFactory,
} from './PlatformIcon'

import styles from './Platforms.module.scss'

enum ToolkitType {
  ACUITY = 'acuity',
  MT = 'mt',
}

export const PlatformsPage = (): JSX.Element => {
  const { scrollIntoView } = useScrollToElementIds()
  const apiClient = useApiClient(ClientApiClient)
  const locale = useSessionLanguage()
  const { t } = useTranslation()
  const location = useLocation()
  const path = location.pathname

  const [selectedPlatform, setSelectedPlatform] = useState<PlatformInfoDto>()
  const [acuitySelected, setAcuitySelected] = useState(false)
  const [mtSelected, setMTSelected] = useState(false)
  const [whichMTModal, setWhichMTModal] = useState(false)

  useEffect(() => {
    if (location.state?.tmtAccountTradeBtnClick) {
      scrollIntoView([ScrollToIds.ToolsPlatformsHeader])
    } else {
      window.scrollTo(0, 0)
    }
  }, [location])

  const platformInfoCallback = useCallback(async () => {
    return apiClient.getPlatformInfo({
      domain: getDomainName(true),
      languageId: locale,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const { data: platformInfo, isLoading } = useFetchOne(platformInfoCallback)

  useEffect(() => console.log({ selectedPlatform }), [selectedPlatform])
  useEffect(() => {
    if (!isLoading) {
      setSelectedPlatform(platformInfo?.find((p) => p.platfromType.id === PlatformTypeEnum.MT5))
      platformInfo?.reverse()
    }
    if (path.includes('mt4')) {
      setSelectedPlatform(platformInfo?.find((p) => p.platfromType.id === PlatformTypeEnum.MT4))
    }
    if (path.includes('mt5')) {
      setSelectedPlatform(platformInfo?.find((p) => p.platfromType.id === PlatformTypeEnum.MT5))
    }
    if (path.includes('tickmilltrader')) {
      setSelectedPlatform(
        platformInfo?.find((p) => p.platfromType.id === PlatformTypeEnum.TickmillTrader)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platformInfo, path])

  const handleSetToolkitType = (
    event: React.ChangeEvent<HTMLInputElement>,
    toolkitType: ToolkitType
  ) => {
    const { checked } = event.target
    if (toolkitType === ToolkitType.MT) {
      setMTSelected(checked)
    } else {
      setAcuitySelected(checked)
    }
  }

  const handleSetSelectedPlatform = (platformId: string | number) => {
    const platform = platformInfo?.find(
      (platform) => platform.platfromType.id.toString() === platformId.toString()
    )
    setSelectedPlatform(platform)
    setAcuitySelected(false)
    setMTSelected(false)
  }

  const getTitle = () => {
    if (isTMTPlatformType(selectedPlatform?.platfromType.id)) {
      return t('ToolsPlatforms.Download Your Tickmill Trader')
    }
    return `${t('MetaTrader.Download Your MetaTrader')} ${
      selectedPlatform?.platfromType.id === PlatformTypeEnum.MT4 ? '4' : '5'
    }`
  }

  const getText = () => {
    if (isMT4PlatformType(selectedPlatform?.platfromType.id)) return t('MetaTrader.MT4')
    if (isMT5PlatformType(selectedPlatform?.platfromType.id)) return t('MetaTrader.MT5')
    if (isTMTPlatformType(selectedPlatform?.platfromType.id)) {
      return t('ToolsPlatforms.Trade on the go with Tickmill Trader (for iOS and Android)')
    }
  }

  const getQuestion = () => {
    return t('MetaTrader.Which one suits you best')
  }

  const getNote = () => {
    if (isTMTPlatformType(selectedPlatform?.platfromType.id)) {
      return t('ToolsPlatforms.One of the most powerful trading platforms for mobile devices')
    }
    if (isMT4PlatformType(selectedPlatform?.platfromType.id)) {
      t('MetaTrader.Get access')
    }
    if (isMT5PlatformType(selectedPlatform?.platfromType.id)) {
      return t('MetaTrader.Explore more')
    }
  }

  return (
    <>
      {whichMTModal && (
        <Modal
          closeModal={() => setWhichMTModal(false)}
          render={() => (
            <InformationModal
              onCancel={() => setWhichMTModal(false)}
              title={t('MetaTrader.MetaTrader5vs4')}
              buttonText={t('Got It')}
            >
              <p>{t('MetaTrader.Backed')}</p>
            </InformationModal>
          )}
        />
      )}
      <PageHeader title={t('Platforms')} id='tools-platforms-header' />
      <Loading showLoadingIcon isLoading={isLoading}>
        <div className={styles.wrapper}>
          <p className={styles.text}>{getText()}</p>
          <div className={styles.buttonWrapper}>
            <SwitcherTabs
              initialTab={selectedPlatform?.platfromType.id}
              handleChangeTab={handleSetSelectedPlatform}
              tabs={platformInfo?.map((platform) => platform.platfromType)}
            />
          </div>
          <p className='has-text-centered is-link' onClick={() => setWhichMTModal(true)}>
            {getQuestion()}
          </p>
          <div className={classNames(styles.downloadSection, 'has-text-centered')}>
            <h2 className={styles.title}>{getTitle()}</h2>
            <p className={styles.subtitle}>{getNote()}</p>
            <div className={styles.icons}>
              {selectedPlatform?.platformLinks
                .sort((a, b) => a.order - b.order)
                .map((link, linkIndex) => (
                  <div className={styles.icon} key={linkIndex}>
                    {link.operatingSystem.id === 5 ? (
                      <PlatformDownloadButton to={link.productLink}>
                        <PlatformIconFactory os={link.operatingSystem.id} />
                      </PlatformDownloadButton>
                    ) : (
                      <PlatformDownloadButton to={link.productLink}>
                        <PlatformIconFactory os={link.operatingSystem.id} />
                      </PlatformDownloadButton>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className={styles.toolKits}>
          {selectedPlatform?.externalPlatformApplications.map((application, applicationIndex) => {
            const isSelected = isPlatformExternalApplicationFXBLUE(application)
              ? mtSelected
              : acuitySelected

            return (
              <div className={classNames(styles.toolkit)} key={applicationIndex}>
                <h2 className={styles.title}>
                  {isPlatformExternalApplicationFXBLUE(application) && (
                    <span>{t('MetaTrader.FxBlue')}</span>
                  )}
                  {isPlatformExternalApplicationACUITY(application) && (
                    <span>{t('MetaTrader.Acuity')}</span>
                  )}
                </h2>
                <p className={classNames(styles.text, styles.subtitle, styles.textGray)}>
                  {isPlatformExternalApplicationFXBLUE(application) && (
                    <span>{t('MetaTrader.FXBlueDesc')}</span>
                  )}
                  {isPlatformExternalApplicationACUITY(application) && (
                    <span>{t('MetaTrader.AcuityDesc')}</span>
                  )}
                </p>

                <div className={classNames(styles.icons)}>
                  {isSelected && (
                    <div className={styles.icon}>
                      <PlatformDownloadButton to={application.productLink}>
                        <PlatformIconFactory os={OperatingSystemEnum.WINDOWS} />
                      </PlatformDownloadButton>
                    </div>
                  )}
                  {!isSelected && (
                    <div className={styles.icon}>
                      <PlatformDownloadButton disabled>
                        <PlatformIconFactory os={OperatingSystemEnum.WINDOWS} />
                      </PlatformDownloadButton>
                    </div>
                  )}

                  {application.guides.map((guid, guideIndex) =>
                    isSelected ? (
                      <div className={styles.icon} key={guideIndex}>
                        <PlatformDownloadButton to={guid.guideLink} disabled={!isSelected}>
                          <MTDownloadIcon
                            title={guid.name}
                            icon={<ManualIconFactory icon={guid.icon} />}
                          />
                        </PlatformDownloadButton>
                      </div>
                    ) : (
                      <div className={styles.icon} key={guideIndex}>
                        <PlatformDownloadButton key={guideIndex} disabled>
                          <MTDownloadIcon
                            title={guid.name}
                            icon={<ManualIconFactory icon={guid.icon} />}
                          />
                        </PlatformDownloadButton>
                      </div>
                    )
                  )}
                </div>
                <p className={classNames(styles.textSmall, styles.textGray)}>
                  {t('MetaTrader.More')}{' '}
                  <a
                    className='is-link'
                    href={application.infoLink}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {t('here')}
                  </a>
                </p>

                <div className={styles.consent}>
                  <div className={styles.checkbox}>
                    <CheckBox
                      value={
                        isPlatformExternalApplicationFXBLUE(application)
                          ? mtSelected
                          : acuitySelected
                      }
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        handleSetToolkitType(
                          event,
                          isPlatformExternalApplicationFXBLUE(application)
                            ? ToolkitType.MT
                            : ToolkitType.ACUITY
                        )
                      }}
                    />
                  </div>
                  <p className={styles.textSmall}>
                    {isPlatformExternalApplicationFXBLUE(application) && (
                      <span>{t('MetaTrader.FXBlueDownload')}</span>
                    )}
                    {isPlatformExternalApplicationACUITY(application) && (
                      <span>{t('MetaTrader.AcuityDownload')}</span>
                    )}
                  </p>
                </div>
              </div>
            )
          })}
        </div>
      </Loading>
    </>
  )
}
