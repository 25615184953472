import { useCallback } from 'react'

import { useSessionLanguage } from '../../../global/context/SessionSettingsContext'
import { PaymentProvider } from '../../../model/PaymentProviderDto'
import { WalletDto } from '../../../model/WalletDto'
import { WalletPaymentProvider } from '../../../model/WalletPaymentProvider'
import { WalletPaymentProviderParameterMessage } from '../../../model/WalletPaymentProviderParameters'
import { useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import { Wallet, getCurrencyLimits } from './WalletDepositForm/WalletDepositForm'

export const usePaymentProviderFactory = (): {
  createPaymentProvider: (
    walletPaymentProvider: WalletPaymentProvider,
    wallet: Wallet | WalletDto
  ) => Promise<PaymentProvider>
} => {
  const locale = useSessionLanguage()
  const apiClient = useApiClient(ClientApiClient)

  const createPaymentProvider = useCallback(
    async (
      walletPaymentProvider: WalletPaymentProvider,
      wallet: Wallet | WalletDto
    ): Promise<PaymentProvider> => {
      const walletPaymentProviderParameters = await apiClient.getWalletDepositMethodParameters(
        locale,
        wallet.id || '',
        walletPaymentProvider.method.id
      )

      const paymentProvider: PaymentProvider = {
        id: walletPaymentProvider.method.id,
        name: walletPaymentProvider.name,
        description: walletPaymentProvider.description,
        providerCategory: walletPaymentProvider.providerCategory,
        currency: {
          id: walletPaymentProvider.currency.id,
          ...walletPaymentProvider,
        },
        parameters: walletPaymentProviderParameters,
        method_name: walletPaymentProvider.method_name,
      }
      return {
        ...paymentProvider,
        parameters: {
          ...walletPaymentProviderParameters,
          messages: walletPaymentProviderParameters.messages.map((x) =>
            formatWalletWarningMessage(x, wallet, paymentProvider)
          ),
        },
      }
    },
    [locale, apiClient]
  )

  return { createPaymentProvider }
}

const formatWalletWarningMessage = (
  message: WalletPaymentProviderParameterMessage,
  wallet: Wallet,
  paymentProvider: PaymentProvider
): WalletPaymentProviderParameterMessage => {
  const currencyLimit = getCurrencyLimits(wallet, paymentProvider)
  const minAmount = currencyLimit?.minAmount?.toString() || '0'
  const maxAmount = currencyLimit?.maxAmount?.toString() || '0'
  const currency = paymentProvider.currency.id || ''

  return {
    ...message,
    list: message.list.map((x) =>
      x
        .replace(/{minAmount}/g, minAmount)
        .replace(/{maxAmount}/g, maxAmount)
        .replace(/{paymentProviderCurrency}/g, currency)
    ),
  }
}
