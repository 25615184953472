import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { WalletWithdrawFormValues } from '../../Traders-Room/Wallets/WalletWithdraw/WalletWithdrawForm/WalletWithdrawForm'
import {
  WalletWithdrawPage,
  useWalletWithdrawFetch,
} from '../../Traders-Room/Wallets/WalletWithdraw/WalletWithdrawPage/WalletWithdrawPage'
import { Loading } from '../../global/Loading/Loading'
import { MasterTransaction } from '../../model/MasterTransaction'
import { WalletTypeEnum } from '../../model/WalletDto'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { isTickmillPartner } from '../../utils/companyName.utils'

export const IBWalletWithdrawPage: React.FC = () => {
  const navigate = useNavigate()

  const handlePageExitConfirmation = () => {
    navigate(`/dashboard/introducing-broker/wallets`)
  }

  const { data, isLoading } = useWalletWithdrawFetch({
    onCancel: handlePageExitConfirmation,
  })

  const handleSubmit = (data: MasterTransaction, values: WalletWithdrawFormValues) => {
    navigate(
      `/dashboard/introducing-broker/wallets/${values.wallet.id}/withdraw/transaction/succeed`,
      {
        state: {
          data,
          providerCategoryId: values.paymentProvider.providerCategory.id,
        },
      }
    )
  }
  const { t } = useTranslation()
  const { account } = useAccountReadContext()

  const ibOrPartnerTitle = isTickmillPartner(account)
    ? t('Wallet.Partner')
    : t('Wallet.Introducing Broker')

  return (
    <Loading isLoading={isLoading}>
      {data && (
        <WalletWithdrawPage
          wallet={data}
          walletType={WalletTypeEnum.IB}
          onSubmit={handleSubmit}
          onCancel={handlePageExitConfirmation}
          title={ibOrPartnerTitle}
        />
      )}
    </Loading>
  )
}
