import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Loading } from '../../../global/Loading/Loading'
import { useSessionLanguage } from '../../../global/context/SessionSettingsContext'
import { InformationModal } from '../../../global/modal/InformationModal'
import { Modal } from '../../../global/modal/Modal'
import { ClientNciPriorityDto } from '../../../model/ClientNciPriorityDto'
import { ClientNciHistoryStatus } from '../../../model/CreateNciHistory'
import { NameDto } from '../../../model/NameDto'
import { UploadDocumentCategoryEnum } from '../../../model/UploadDocumentCategoryEnum'
import { Text } from '../../../ui/Typography/Typography'
import { useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import { useFetchOne } from '../../../utils/useFetch'
import { getDocUploadFailMessage } from '../documentManagementUploadStatuses'
import {
  DocumentManagementNCIForm,
  DocumentManagementNCIFormValues,
} from './DocumentManagementNCIForm/DocumentManagementNCIForm'

export const useDocumentManagementNCIMutate = () => {
  const navigate = useNavigate()

  const apiClient = useApiClient(ClientApiClient)
  const [isLoading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const mutate = async (
    values: DocumentManagementNCIFormValues,
    setUploadErrorModal: (visible: boolean) => void
  ) => {
    try {
      setLoading(true)
      await apiClient.accountAcceptFinalNciHistory({
        statusId: ClientNciHistoryStatus.PENDING,
        nciPriorityId: values.accountNciAvailablePriorities.id,
        documentTypeId: values.nciHistory.documentTypeId,
        nciDocumentNumber: values.nciHistory.nciDocumentNumber,
        countryId: values.accountNciAvailablePriorities.country.id,
      })
      await apiClient.uploadDocuments({ documents: values.documents })
      navigate('/profile/document-management')
    } catch (error: unknown) {
      setUploadErrorModal(true)
      setErrorMessage((error as any).response?.response?.data?.code)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  return {
    mutate,
    isLoading,
    errorMessage,
    setErrorMessage,
  }
}

export const DocumentManagementNCIPage: React.FC = () => {
  const navigate = useNavigate()

  const location = useLocation()

  const [values] = useState<{
    documentCategory: NameDto
    documentCategoryType: NameDto
  }>(location.state)
  const [accountNciAvailablePriorities, setAccountNciAvailablePriorities] =
    useState<ClientNciPriorityDto>()
  const apiClient = useApiClient(ClientApiClient)

  const locale = useSessionLanguage()

  const callback = useCallback(async () => {
    return Promise.all([
      apiClient.getAccountNciPriorities(),
      apiClient.getAccountNciAvailablePriorities(locale),
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const { data = [], isLoading } = useFetchOne(callback)
  const [accountNciPriorities = [], accountNciAvailablePrioritiesFetch] = data
  const [uploadErrorModal, setUploadErrorModal] = useState(false)

  const mutation = useDocumentManagementNCIMutate()

  const handleSubmit = async (values: DocumentManagementNCIFormValues) => {
    await mutation.mutate(values, setUploadErrorModal)
  }

  const handlePageExitConfirmation = () => {
    navigate(`/profile/document-management`)
  }
  const { t } = useTranslation()
  useEffect(() => {
    setAccountNciAvailablePriorities(accountNciAvailablePrioritiesFetch)
  }, [accountNciAvailablePrioritiesFetch])

  const handleCloseErrorModal = () => {
    setUploadErrorModal(false)
    mutation.setErrorMessage('')
  }

  const handleChooseAnotherDoc = (doc: ClientNciPriorityDto) => {
    setAccountNciAvailablePriorities(doc)
  }

  return (
    <Loading isLoading={isLoading} showLoadingIcon>
      {uploadErrorModal && (
        <Modal
          closeModal={handleCloseErrorModal}
          render={() => (
            <InformationModal
              onCancel={handleCloseErrorModal}
              buttonText={t('Got It')}
              title={t('Upload failed')}
            >
              <Text isParagraph>{t(getDocUploadFailMessage(mutation.errorMessage))}</Text>
            </InformationModal>
          )}
        />
      )}
      {!mutation.isLoading && (
        <React.Fragment>
          {accountNciAvailablePriorities && (
            <DocumentManagementNCIForm
              documentCategory={
                values?.documentCategory || {
                  id: UploadDocumentCategoryEnum.Personal,
                }
              }
              handleChooseAnotherDoc={handleChooseAnotherDoc}
              documentCategoryType={values?.documentCategoryType}
              accountNciPriorities={accountNciPriorities}
              accountNciAvailablePriorities={accountNciAvailablePriorities}
              onSubmit={handleSubmit}
              onCancel={handlePageExitConfirmation}
            />
          )}
        </React.Fragment>
      )}
      {mutation.isLoading && (
        <Loading isLoading={mutation.isLoading} showLoadingIcon text={t('Wallet.Submitting')} />
      )}
    </Loading>
  )
}
