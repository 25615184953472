import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { Button } from '../../../global/button/Button'
import IconButton from '../../../global/iconButton/IconButton'
import { CloseIcon } from '../../../icons/CloseIcon'
import { InfoIcon } from '../../../icons/InfoIcon'
import { useWindowResize } from '../../../utils/domUtils'
import { isOne } from '../../../utils/validations'
import { TooltipDirection } from '../../Popups/Bubble/Bubble'
import { InfoCard } from '../../Popups/InfoCard/InfoCard'
import { TextSmall, TextSmallStrong } from '../../Typography/Typography'

import styles from './PageHeader.module.scss'

export interface Calculation {
  title: string
  value?: string | number
  link?: string
  currency?: string
}

export interface CalculationsProps {
  calculations: Calculation[]
}

export const Calculations: React.FC<CalculationsProps> = (props) => {
  const { calculations } = props
  return (
    <div
      className={classNames(styles.calculations, {
        [styles.calculation]: isOne(calculations.length),
      })}
    >
      {calculations.map((calculation) => (
        <div className={styles.calculationsItem}>
          <TextSmall isParagraph className={styles.title}>
            {calculation.title}
          </TextSmall>
          <Link onClick={() => !!calculation.link} to={calculation.link || '#'}>
            <TextSmallStrong
              isParagraph
              className={classNames(styles.value, {
                [styles.link]: !!calculation.link,
              })}
            >
              {calculation.value || 0} {calculation.currency}
            </TextSmallStrong>
          </Link>
        </div>
      ))}
    </div>
  )
}

export interface Action {
  disabled?: boolean
  hidden?: boolean
  onClick: () => void
  label: string
  appearance?: 'primary' | 'secondary' | 'tertiary'
  infoHandler?: () => void
  size?: 'XS' | 'S' | 'M' | 'L'
  tooltipText?: string | null
}

export interface ActionTogglesProps {
  actions: Action[]
  renderInfo?: () => React.ReactNode
}

export const ActionToggles: React.FC<ActionTogglesProps> = (props) => {
  const { actions, renderInfo } = props
  const isMobile = useWindowResize()
  const [tooltipPosition, setTooltipPosition] = useState<TooltipDirection>()

  return (
    <div className={styles.actionToggles}>
      <div className={styles.actionWrapper}>
        {actions
          .filter((x) => !x.hidden)
          .map((action, index) => (
            <div key={index} className={styles.actionButtonWrapper}>
              <InfoCard
                disabled={!action.tooltipText}
                direction={tooltipPosition}
                text={action.tooltipText}
              >
                <Button
                  onClick={action.onClick}
                  onMouseOver={(event) => {
                    if (action.tooltipText) {
                      const top = (event.target as HTMLButtonElement).getBoundingClientRect().top

                      setTooltipPosition(top < window.innerHeight / 2 ? 'bottomLeft' : 'topLeft')
                    }
                  }}
                  appearance={action.appearance || 'secondary'}
                  size={action.size || 'M'}
                  disabled={action.disabled}
                  fullWidth
                  className={styles.action}
                >
                  <TextSmallStrong>{action.label}</TextSmallStrong>
                </Button>
              </InfoCard>

              {action.disabled && action.infoHandler && (
                <IconButton className={styles.infoIcon} onClick={action.infoHandler}>
                  <InfoIcon />
                </IconButton>
              )}
            </div>
          ))}
      </div>
      {isMobile && renderInfo && <div className={styles.infoBlock}>{renderInfo?.()}</div>}
    </div>
  )
}

export interface FilterTogglesProps {
  openFilterModal?: () => void
  openSortModal?: () => void
  resetFilters?: () => void
  activeFilters?: number
  sortLabel?: string
  className?: string
}

export const FilterToggles: React.FC<FilterTogglesProps> = (props) => {
  const { openFilterModal, resetFilters, activeFilters } = props

  const { t } = useTranslation()
  if (!openFilterModal) return null
  return (
    <>
      <Button
        size='S'
        appearance='secondary'
        state={activeFilters ? 'focus' : 'normal'}
        onClick={openFilterModal}
      >
        <TextSmallStrong>{t('Filter')} </TextSmallStrong>
        {!!activeFilters && `(${activeFilters})`}
      </Button>
      <TextSmall onClick={resetFilters} className={styles.reset}>
        {t('Reset')}
      </TextSmall>
    </>
  )
}

export interface TabsProps {
  tabs: { label: string; id: string }[]
  selectedTab: string
  setSelectedTab: (value: string) => void
}

export const Tabs: React.FC<TabsProps> = (props) => {
  const { tabs, selectedTab, setSelectedTab } = props
  return (
    <div className={styles.tabs}>
      {tabs.map((tab) => (
        <div
          key={tab.id}
          className={classNames(styles.tab, {
            [styles.active]: selectedTab === tab.id,
          })}
          onClick={() => setSelectedTab(tab.id)}
        >
          {tab.label}
        </div>
      ))}
    </div>
  )
}

export const MobileFilterToggles: React.FC<FilterTogglesProps> = (props) => {
  const { openFilterModal, openSortModal, resetFilters, activeFilters, sortLabel, className } =
    props

  const { t } = useTranslation()
  const showSort = !!sortLabel && !!openSortModal

  return (
    <div className={classNames(styles.mobileFilterToggles, className)}>
      {openFilterModal && (
        <div>
          <Button
            appearance='secondary'
            className={styles.filterButton}
            size='XS'
            onClick={openFilterModal}
          >
            {t('Filter')} {!!activeFilters && `(${activeFilters})`}
          </Button>
          <TextSmall onClick={resetFilters} className={styles.reset}>
            {t('IB.Reset')}
          </TextSmall>
        </div>
      )}
      {showSort && (
        <Button
          appearance='secondary'
          className={styles.sortButton}
          size='XS'
          onClick={openSortModal}
        >
          {sortLabel}
        </Button>
      )}
    </div>
  )
}

export interface SearchTermState {
  show: boolean
  searchTerm?: string
}

export interface SearchProps extends SearchTermState {
  placeholder?: string
  setSearchTerm: (value: string) => void
  setShow: (value: boolean) => void
}

export const Search: React.FC<SearchProps> = (props) => {
  const { show, searchTerm, setSearchTerm, setShow, placeholder } = props
  const { t } = useTranslation()
  const [input, setInput] = useState(searchTerm)

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value)

    if (event.target.value.length < 30) {
      setSearchTerm(event.target.value.trim())
    }
  }

  const handleInputClear = () => {
    setInput('')

    setSearchTerm('')
  }

  return (
    <div
      className={classNames(styles.searchWrapper, {
        [styles.hide]: !show,
      })}
    >
      <p className={classNames('control has-icons-right', styles.inputWrapper)}>
        <input
          className={classNames(styles.input, 'input')}
          type='text'
          placeholder={placeholder || t('Search name, account number ...')}
          value={input}
          onChange={handleInputChange}
        />
        <span className='icon is-small is-right is-clickable' onClick={handleInputClear}>
          <CloseIcon />
        </span>
      </p>
      <span
        className='ml-3 is-clickable'
        onClick={() => {
          setShow(false)
        }}
      >
        {t('Close')}
      </span>
    </div>
  )
}
