import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import {
  overrideForZHToCNLanguage,
  useSessionLanguage,
} from '../../global/context/SessionSettingsContext'
import { Modal } from '../../global/modal/Modal'
import { VideoSelectorModal } from '../../global/modal/VideoSelectorModal'
import { ChevronDownIcon } from '../../icons/ChevronDownIcon'
import placeholder from '../../images/placeholder.svg'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useWindowResize } from '../../utils/domUtils'
import { useFetchOne } from '../../utils/useFetch'

import styles from './CQGEducationVideos.module.scss'

export interface VideoItem {
  id: string
  title: string
  image?: string
  video: string
  duration?: string
}

export const CQGEducationVideosPage = () => {
  const { t } = useTranslation()
  const videoRef = useRef<HTMLDivElement>(null)
  const locale = useSessionLanguage()
  const isMobile = useWindowResize()
  const languageId = overrideForZHToCNLanguage(locale)

  const [selectedVideoId, setSelectedVideoId] = useState<string | undefined>()
  const [modal, setModal] = useState(false)
  const apiClient = useApiClient(ClientApiClient)

  const getCGQVideosCallback = useCallback(async () => {
    return await apiClient.getCGQVideos(languageId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageId])

  const { data: getCGQVideosData = [] } = useFetchOne(getCGQVideosCallback)

  const getTutorials = useMemo(
    () => getCGQVideosData?.find((response) => response?.tutorials?.length),
    [getCGQVideosData]
  )

  const videos: VideoItem[] = useMemo(
    () =>
      getTutorials?.tutorials?.map(({ title, tutorialLink }, index) => {
        return {
          id: `${index}`,
          title,
          video: tutorialLink,
        }
      }) || [],
    [getTutorials]
  )

  const selectedVideo = useMemo(
    () => videos.find((video) => video.id === selectedVideoId),
    [selectedVideoId, videos]
  )

  useEffect(() => {
    if (videoRef.current) {
      const elementRect = videoRef.current.getBoundingClientRect()
      const absoluteElementTop = elementRect.top + window.scrollY
      if (isMobile) {
        window.scrollTo({
          top: absoluteElementTop - 80,
          behavior: 'smooth',
        })
      } else {
        videoRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [selectedVideo, isMobile])

  return (
    <>
      {modal && (
        <Modal
          closeModal={() => setModal(false)}
          render={() => {
            return (
              <VideoSelectorModal
                onCancel={() => setModal(false)}
                items={videos}
                onSelect={(item) => setSelectedVideoId(item?.id)}
                selectedItem={selectedVideo}
              />
            )
          }}
        />
      )}
      {!selectedVideo && (
        <>
          <PageHeader title={t('Learning.CQG Education Videos')} />
          <div className='container'>
            <div className='columns is-multiline is-flex mt-3 is-variable is-2'>
              {videos.map((item, idx) => (
                <div
                  key={idx}
                  className='column is-flex is-half-mobile is-one-quarter-desktop is-one-quarter-tablet is-clickable'
                  onClick={() => setSelectedVideoId(item?.id)}
                >
                  <div className={classNames(styles.card, 'is-clickable')}>
                    <img src={placeholder} alt='alt' />
                    <p className={classNames(styles.textStrong, 'mt-3 mb-4')}>{item.title}</p>
                    <p className={classNames(styles.textSmall, styles.textSecondary)}>
                      {item.duration}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {selectedVideo && !isMobile && (
        <div ref={videoRef}>
          <PageHeader
            title={
              <>
                <span className={styles.textSecondary}>{t('Learning.CQG Videos')} / </span>
                <span>{selectedVideo.title}</span>
              </>
            }
            backButton={() => setSelectedVideoId(undefined)}
          />
          <div className={classNames(styles.card, 'mt-4')}>
            <div className='columns'>
              <div className='column is-3 mt-5'>
                {videos.map((item) => {
                  return (
                    <div
                      onClick={() => setSelectedVideoId(item?.id)}
                      key={item.id}
                      className={classNames(styles.itemWrapper, 'mt-3 is-clickable', {
                        [styles.selected]: item.id === selectedVideo.id,
                      })}
                    >
                      <div className={classNames('is-flex  is-justify-content-space-between')}>
                        <div className={styles.textSmall}>{item.title}</div>
                        <div
                          className={classNames(
                            styles.textSmall,
                            styles.textSecondary,
                            'has-text-right'
                          )}
                        >
                          {item.duration}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className='column is-9'>
                <h3 className='mb-3'>{selectedVideo.title}</h3>
                {selectedVideo?.video && (
                  <video preload='metadata' controls autoPlay src={selectedVideo.video} />
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {selectedVideo && isMobile && (
        <div ref={videoRef}>
          <PageHeader
            title={
              <>
                <span className={styles.textSecondary}>{'.../ '}</span>
                <span>{selectedVideo.title}</span>
              </>
            }
            backButton={() => setSelectedVideoId(undefined)}
          />
          <div className={classNames(styles.card, 'mt-4')}>
            {
              <div className='is-flex  is-justify-content-space-between my-3 is-clickable'>
                <h3 className='mb-3'>{selectedVideo.title}</h3>
                <div className='is-flex'>
                  <div
                    className={classNames(
                      styles.textSmall,
                      styles.textSecondary,
                      'has-text-right mr-2'
                    )}
                  >
                    {selectedVideo.duration}
                  </div>
                  <span className='is-clickable' onClick={() => setModal(true)}>
                    <ChevronDownIcon />
                  </span>
                </div>
              </div>
            }

            <div>
              {selectedVideo?.video && (
                <video preload='metadata' controls autoPlay src={selectedVideo.video} />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
