import React, { Fragment, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useFormatNumber } from '../../hooks/useFormatNumber'
import { AccountSummaryDto } from '../../model/AccountSummaryDto'
import { AuthSessionContext } from '../../utils/AuthContext'
import { getCompanyName } from '../../utils/companyName.utils'
import { formatDate } from '../../utils/date.utils'
import { SearchResponseProps } from '../Income/IBIncomePage'
import { NoData } from './IBClientsTable'

import styles from './IBClientCard.module.scss'

interface IBIncomeCardsProps {
  data: AccountSummaryDto[]
  search: SearchResponseProps
}

export const IBClientsCards: React.FC<IBIncomeCardsProps> = (props) => {
  const { search, data } = props

  if (data.length <= 0) {
    return <NoData search={search} />
  }

  return (
    <React.Fragment>
      {data.map((x) => (
        <IBClientCard key={x.clientName} data={x} />
      ))}
    </React.Fragment>
  )
}

interface WalletCardProps {
  data: AccountSummaryDto
}

export const IBClientCard: React.FC<WalletCardProps> = ({ data }) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)
  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name

  const { formatNumber } = useFormatNumber()

  return (
    <div onClick={() => setExpanded((prevState) => !prevState)} className={styles.card}>
      <div className={styles.title}>
        <span className={styles.mainLabel}>{data.clientName}</span>
        <span className={styles.mainLabel}>{data.accountType}</span>
      </div>
      <div className={styles.title}>
        <span className={styles.subLabel}>
          {t('IB.Volume')}:<span className={styles.textPrimary}> {formatNumber(data.volume)}</span>
        </span>
        <span className={styles.subLabel}>
          {t('IB.Commission')}:
          <span className={styles.textPrimary}> {formatNumber(data.amount)}</span>
        </span>
      </div>
      {expanded && (
        <Fragment>
          <div className={styles.subtitle}>
            <span className={styles.label}>{t('IB.Registration Date')}</span>
            <span className={styles.value}>{formatDate(data.registrationDate, dateFormat)}</span>
          </div>
          <div className={styles.subtitle}>
            <span className={styles.label}>{t('Trading Account.Trading Account')}</span>
            <span className={styles.value}>
              <Link
                className='is-link'
                to='/dashboard/introducing-broker/income'
                state={{
                  TradingAccountName: data.login,
                }}
              >
                {data.login}
              </Link>
            </span>
          </div>
          <div className={styles.subtitle}>
            <span className={styles.label}>{t('IB.Currency')}</span>
            <span className={styles.value}>{data.currency}</span>
          </div>
          <div className={styles.subtitle}>
            <span className={styles.label}>{t('IB.Country')}</span>
            <span className={styles.value}>{data.country}</span>
          </div>
          <div className={styles.subtitle}>
            <span className={styles.label}>{t('IB.Entity')}</span>
            <span className={styles.value}>
              {data.tickmillCompany ? getCompanyName(data.tickmillCompany?.id) : null}
            </span>
          </div>
        </Fragment>
      )}
    </div>
  )
}
