import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { useDeviceReferral } from '../../Referral/DeviceReferral'
import { Loading } from '../../global/Loading/Loading'
import { Button } from '../../global/button/Button'
import { useEntitySettings } from '../../global/context/EntityContext'
import { PhoneVerificationWorkflowEnum } from '../../model/CreatePhoneNumberVerification'
import { LeadDto, isLeadSourceMobileRegistration } from '../../model/LeadDto'
import { Text } from '../../ui/Typography/Typography'
import { ResponseError, useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'

import styles from './VerifyEmailPage.module.scss'

export const VerifyEmailPage: React.FC = () => {
  const { leadId } = useParams<{ leadId?: string }>()
  const apiClient = useApiClient(ClientApiClient)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)
  const [clientEmail, setClientEmail] = useState('')
  const [lead, setLead] = useState<LeadDto>()
  const { t } = useTranslation()
  const [, setEntity] = useEntitySettings()

  const verifyEmail = async () => {
    const hash = searchParams.get('hash')
    const token = searchParams.get('token')
    if (!hash || !token) {
      setError(t('Sign up.Something went wrong, please contact support.'))
      setLoading(false)
      return
    }

    try {
      const leadDto = await apiClient.getLeadByIdAndToken(leadId || '', token)
      setClientEmail(leadDto.email)
      await apiClient.verifyEmail({
        email: leadDto.email,
        token: hash,
      })

      setLead(leadDto)
      if (isLeadSourceMobileRegistration(leadDto?.leadSource)) return

      if (leadDto.phoneVerificationRequired) {
        try {
          const createdPhoneVerification = await apiClient.createPhoneNumberVerification(
            leadDto.id,
            {
              phoneNumber: leadDto.phoneNumber,
              leadId: leadDto.id,
              countryCode: leadDto.countryCode,
              countryId: leadDto.country.id,
              workflowId: PhoneVerificationWorkflowEnum.WorkflowSMS,
            }
          )
          return navigate(
            `/users/verify-phone/${leadId}?token=${token}&phoneVerificationId=${createdPhoneVerification.phoneVerificationId}`
          )
        } catch (e: unknown) {
          setError(t('Sign up.Something went wrong, please contact support.'))
        } finally {
          setLoading(false)
        }
      }
      if (!isLeadSourceMobileRegistration(leadDto.leadSource)) {
        navigate(`/users/register2/${leadId}?token=${token}`)
      }
      setEntity({ entity: leadDto.tickmillCompany.id })
    } catch (error: unknown) {
      setLoading(false)
      if ((error as ResponseError).response.response?.data.code) {
        const errorResponse = (error as ResponseError).response.response?.data
        if (errorResponse?.code) setError(errorResponse?.code)
      }
    }
  }

  useEffect(() => {
    verifyEmail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!lead && !loading) {
    const getErrorBody = () => {
      if (error === 'verifiable_action_unavailable') {
        return [
          t(
            'Sign up.Your link has expired and is not valid anymore. You can start a new registration right away.'
          ),
        ]
      }
      if (error === 'lead_already_verified') {
        return [
          t('Sign up.To continue your registration with Tickmill, please check your inbox at', {
            email: clientEmail,
          }) +
            ' ' +
            t(
              'Sign up.You will find an email with subject ‘Email verified’ containing a link to complete your registration.'
            ),
          t('Sign up.Should you require any assistance, please contact'),
          t('Sign up.Tickmill’s Customer Support'),
        ]
      }
      return []
    }

    const getErrorHeader = () => {
      if (error === 'verifiable_action_unavailable')
        return t('Sign up.Looks like your link has expired')
      if (error === 'lead_already_verified')
        return t('Sign up.Looks like you’ve clicked your verification link twice!')
      return error
    }

    const getErrorButton = () => {
      if (error === 'verifiable_action_unavailable')
        return {
          link: '/sign-up',
          text: t('Sign up.Start Registration'),
        }
      return { link: '/support', text: t('Contact support') }
    }

    return (
      <div className={styles.box}>
        <h1>{getErrorHeader()}</h1>
        <div>
          {getErrorBody().map((text) => (
            <Text isParagraph>{text}</Text>
          ))}
        </div>
        <Button
          appearance='primary'
          size='L'
          type='button'
          onClick={() => navigate(getErrorButton().link)}
        >
          {getErrorButton().text}
        </Button>
      </div>
    )
  }

  return (
    <React.Fragment>
      {!isLeadSourceMobileRegistration(lead?.leadSource) && <Loading isLoading={loading} />}
      {isLeadSourceMobileRegistration(lead?.leadSource) && (
        <MobileAppRedirectSuccessPage lead={lead} />
      )}
    </React.Fragment>
  )
}

interface MobileAppRedirectSuccessPageProps {
  lead?: LeadDto
}

const MobileAppRedirectSuccessPage: React.FC<MobileAppRedirectSuccessPageProps> = (props) => {
  const { lead } = props
  const { referralAppLink } = useDeviceReferral()

  const { t } = useTranslation()

  const goToMobileApp = () => {
    if (lead) {
      window.location.href = referralAppLink({
        id: lead.id,
        token: lead.token,
        type: 'register',
      })
    }
  }

  return (
    <div className={styles.box}>
      <h1>{t('Sign up.Your email has been verified!')}</h1>
      <Text isParagraph>
        {t('Sign up.Please continue your registration by going back to the mobile app.')}
      </Text>
      <Button appearance='primary' size='L' type='button' onClick={goToMobileApp}>
        {t('Sign up.Back to the app')}
      </Button>
    </div>
  )
}
