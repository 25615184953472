import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { DateFilterContextWrapper } from '../IntroducingBroker/DateFilterContextWrapper'
import { DepositRedirectToProviderPage } from '../PaymentProviderRedirect/DepositRedirectToProviderPage/DepositRedirectToProviderPage'
import { PageNotFound } from '../global/notFound/PageNotFound'
import { WalletTypeEnum } from '../model/WalletDto'
import { useEntityConfig } from '../utils/TradingAccountEntityConfigurationContext'
import { BalancesPage } from './Balances/BalancesPage'
import { CampaignResultsPage } from './Campaign-Results/CampaignResultsPage'
import { ConversionPage } from './Conversion/ConversionPage'
import { StockDividendsPage } from './Stock-Dividends/StockDividendsPage'
import { ChangeTradingAccountPasswordPage } from './Trading-Accounts/ChangeTradingAccountPasswordForm'
import { CreateDemoTradingAccountPage } from './Trading-Accounts/CreateDemoTradingAccount/CreateDemoTradingAccountPage'
import { CreateDemoTradingAccountSuccessPage } from './Trading-Accounts/CreateDemoTradingAccount/CreateDemoTradingAccountSuccessPage'
import { CreateTradingAccountCampaignPage } from './Trading-Accounts/CreateTradingAccountCampaignPage'
import { DemoTradingAccountDepositPage } from './Trading-Accounts/TradingAccountDeposit/DemoTradingAccountDeposit/DemoTradingAccountDepositPage'
import { TradersRoomTradingAccountDepositPage } from './Trading-Accounts/TradingAccountDeposit/TradingAccountDepositPage/TradersRoomTradingAccountDepositPage'
import { TradersRoomTradingAccountDepositTransactionCompletedPage } from './Trading-Accounts/TradingAccountDeposit/TradingAccountDepositTransactionPage/TradersRoomTradingAccountDepositTransactionCompletedPage'
import { TradersRoomTradingAccountDepositTransactionFailedPage } from './Trading-Accounts/TradingAccountDeposit/TradingAccountDepositTransactionPage/TradersRoomTradingAccountDepositTransactionFailedPage'
import { TradersRoomTradingAccountDepositTransactionSuccessPage } from './Trading-Accounts/TradingAccountDeposit/TradingAccountDepositTransactionPage/TradersRoomTradingAccountDepositTransactionSuccessPage'
import { TradingAccountsPage } from './Trading-Accounts/TradingAccountsPage'
import { TransactionHistoryPage } from './Transaction-History/TransactionHistoryPage'
import { TransactionsPage } from './Transactions/TransactionsPage'
import { TradersRoomWalletDepositPage } from './Wallets/WalletDeposit/WalletDepositPage/TradersRoomWalletDepositPage'
import { TradersRoomWalletStartDepositPage } from './Wallets/WalletDeposit/WalletDepositPage/TradersRoomWalletStartDepositPage'
import { TradersRoomWalletDepositTransactionCompletedPage } from './Wallets/WalletDeposit/WalletDepositTransactionPage/TradersRoomWalletDepositTransactionCompletedPage'
import { TradersRoomWalletDepositTransactionFailedPage } from './Wallets/WalletDeposit/WalletDepositTransactionPage/TradersRoomWalletDepositTransactionFailedPage'
import { TradersRoomWalletDepositTransactionSuccessPage } from './Wallets/WalletDeposit/WalletDepositTransactionPage/TradersRoomWalletDepositTransactionSuccessPage'
import {
  CampaignWalletTransferPage,
  TradersRoomWalletStartTransferPage,
  TradersRoomWalletTransferPage,
} from './Wallets/WalletTransfer/WalletTransferPage'
import { WalletTransferPageETD } from './Wallets/WalletTransfer/WalletTransferPageETD'
import { TradersRoomWalletStartWithdrawPage } from './Wallets/WalletWithdraw/WalletWithdrawPage/TradersRoomWalletStartWithdrawPage'
import { TradersRoomWalletWithdrawPage } from './Wallets/WalletWithdraw/WalletWithdrawPage/TradersRoomWalletWithdrawPage'
import { TradersRoomWalletWithdrawTransactionCompletedPage } from './Wallets/WalletWithdraw/WalletWithdrawTransactionPage/TradersRoomWalletWithdrawTransactionCompletedPage'
import { TradersRoomWalletWithdrawTransactionFailedPage } from './Wallets/WalletWithdraw/WalletWithdrawTransactionPage/TradersRoomWalletWithdrawTransactionFailedPage'
import { TradersRoomWalletWithdrawTransactionSuccessPage } from './Wallets/WalletWithdraw/WalletWithdrawTransactionPage/TradersRoomWalletWithdrawTransactionSuccessPage'
import { WalletsPage } from './Wallets/WalletsPage'

interface TradersRoomRoutesProps {
  tab?: string
}

export const TradersRoomRoutesETD: React.FunctionComponent<TradersRoomRoutesProps> = ({ tab }) => {
  if (tab === 'wallets') {
    return (
      <Routes>
        <Route
          path='deposit'
          element={<TradersRoomWalletStartDepositPage walletType={WalletTypeEnum['ETD']} />}
        />
        <Route
          path='withdrawal'
          element={<TradersRoomWalletStartWithdrawPage walletType={WalletTypeEnum['ETD']} />}
        />
        <Route path='transfer' element={<WalletTransferPageETD />} />
        <Route
          path=':walletId/deposit'
          element={<TradersRoomWalletDepositPage walletType={WalletTypeEnum['ETD']} />}
        />
        <Route
          path=':walletId/deposit/transaction/succeed'
          element={<TradersRoomWalletDepositTransactionSuccessPage />}
        />
        <Route
          path=':walletId/deposit/transaction/completed'
          element={<TradersRoomWalletDepositTransactionCompletedPage />}
        />
        <Route
          path=':walletId/deposit/transaction/failed'
          element={<TradersRoomWalletDepositTransactionFailedPage />}
        />
        <Route path=':walletId/deposit/redirect' element={<DepositRedirectToProviderPage />} />
        <Route
          path=':walletId/withdrawal'
          element={<TradersRoomWalletWithdrawPage walletType={WalletTypeEnum['ETD']} />}
        />
        <Route
          path=':walletId/withdraw/transaction/succeed'
          element={<TradersRoomWalletWithdrawTransactionSuccessPage />}
        />
        <Route
          path=':walletId/withdraw/transaction/completed'
          element={<TradersRoomWalletWithdrawTransactionCompletedPage />}
        />
        <Route
          path=':walletId/withdraw/transaction/failed'
          element={<TradersRoomWalletWithdrawTransactionFailedPage />}
        />
        <Route path=':walletId/transfer' element={<WalletTransferPageETD />} />
        <Route index element={<WalletsPage walletType={WalletTypeEnum['ETD']} />} />
      </Routes>
    )
  }
  if (tab === 'balances') {
    return (
      <Routes>
        <Route path=':tradingAccountId/transfer' element={<WalletTransferPageETD />} />
        <Route index element={<BalancesPage walletType={WalletTypeEnum['ETD']} />} />
      </Routes>
    )
  }

  if (tab === 'transactions') {
    return (
      <Routes>
        <Route path='conversion' element={<ConversionPage />} />
        <Route index element={<TransactionsPage />} />
      </Routes>
    )
  }

  if (tab === 'transaction-history') {
    return (
      <DateFilterContextWrapper>
        <Routes>
          <Route index element={<TransactionHistoryPage walletType={WalletTypeEnum['ETD']} />} />
        </Routes>
      </DateFilterContextWrapper>
    )
  }

  return <PageNotFound />
}
export const TradersRoomRoutes: React.FunctionComponent<TradersRoomRoutesProps> = ({ tab }) => {
  const entityConfig = useEntityConfig()

  if (tab === 'wallets')
    return (
      <>
        <Routes>
          <Route
            path='deposit'
            element={<TradersRoomWalletStartDepositPage walletType={WalletTypeEnum['FX/CFD']} />}
          />
          <Route
            path='withdrawal'
            element={<TradersRoomWalletStartWithdrawPage walletType={WalletTypeEnum['FX/CFD']} />}
          />
          <Route
            path='transfer'
            element={<TradersRoomWalletStartTransferPage walletType={WalletTypeEnum['FX/CFD']} />}
          />
          <Route
            path=':walletId/deposit'
            element={<TradersRoomWalletDepositPage walletType={WalletTypeEnum['FX/CFD']} />}
          />
          <Route
            path=':walletId/deposit/transaction/succeed'
            element={<TradersRoomWalletDepositTransactionSuccessPage />}
          />
          <Route
            path=':walletId/deposit/transaction/completed'
            element={<TradersRoomWalletDepositTransactionCompletedPage />}
          />
          <Route
            path=':walletId/deposit/transaction/failed'
            element={<TradersRoomWalletDepositTransactionFailedPage />}
          />
          <Route path=':walletId/deposit/redirect' element={<DepositRedirectToProviderPage />} />
          <Route
            path=':walletId/withdrawal'
            element={<TradersRoomWalletWithdrawPage walletType={WalletTypeEnum['FX/CFD']} />}
          />
          <Route
            path=':walletId/withdraw/transaction/succeed'
            element={<TradersRoomWalletWithdrawTransactionSuccessPage />}
          />
          <Route
            path=':walletId/withdraw/transaction/completed'
            element={<TradersRoomWalletWithdrawTransactionCompletedPage />}
          />
          <Route
            path=':walletId/withdraw/transaction/failed'
            element={<TradersRoomWalletWithdrawTransactionFailedPage />}
          />
          <Route
            path=':walletId/transfer'
            element={<TradersRoomWalletTransferPage walletType={WalletTypeEnum['FX/CFD']} />}
          />
          <Route index element={<WalletsPage walletType={WalletTypeEnum['FX/CFD']} />} />
        </Routes>
      </>
    )

  if (tab === 'trading-accounts')
    return (
      <Routes>
        {entityConfig?.demoAccountAllowed && entityConfig && (
          <Route
            path='new-demo/success/:platformType'
            element={<CreateDemoTradingAccountSuccessPage />}
          />
        )}
        {entityConfig?.demoAccountAllowed && (
          <Route
            path=':tradingAccountId/demo/add-balance'
            element={<DemoTradingAccountDepositPage />}
          />
        )}
        {entityConfig?.demoAccountAllowed && (
          <Route path='new-demo' element={<CreateDemoTradingAccountPage />} />
        )}

        <Route path='new' element={<CreateTradingAccountCampaignPage />} />
        <Route path=':id/change-password' element={<ChangeTradingAccountPasswordPage />} />
        <Route
          path=':tradingAccountId/transfer'
          element={<TradersRoomWalletTransferPage walletType={WalletTypeEnum['FX/CFD']} />}
        />
        <Route
          path=':tradingAccountId/transfer-from-campaign'
          element={<CampaignWalletTransferPage />}
        />
        <Route
          path=':tradingAccountId/deposit-to-campaign'
          element={<TradersRoomTradingAccountDepositPage />}
        />
        <Route
          path=':tradingAccountId/deposit/transaction/succeed'
          element={<TradersRoomTradingAccountDepositTransactionSuccessPage />}
        />
        <Route
          path=':tradingAccountId/deposit/transaction/completed'
          element={<TradersRoomTradingAccountDepositTransactionCompletedPage />}
        />
        <Route
          path=':tradingAccountId/deposit/transaction/failed'
          element={<TradersRoomTradingAccountDepositTransactionFailedPage />}
        />
        <Route
          path=':tradingAccountId/deposit/redirect'
          element={<DepositRedirectToProviderPage />}
        />
        <Route index element={<TradingAccountsPage />} />
      </Routes>
    )

  if (tab === 'transactions')
    return (
      <Routes>
        <Route index element={<TransactionsPage />} />
      </Routes>
    )

  if (tab === 'campaign-results')
    return (
      <DateFilterContextWrapper>
        <Routes>
          <Route index element={<CampaignResultsPage />} />
        </Routes>
      </DateFilterContextWrapper>
    )

  if (tab === 'transaction-history')
    return (
      <DateFilterContextWrapper>
        <Routes>
          <Route index element={<TransactionHistoryPage walletType={WalletTypeEnum['FX/CFD']} />} />
        </Routes>
      </DateFilterContextWrapper>
    )

  if (tab === 'stock-dividends')
    return (
      <DateFilterContextWrapper>
        <Routes>
          <Route index element={<StockDividendsPage />} />
        </Routes>
      </DateFilterContextWrapper>
    )

  return <PageNotFound />
}
