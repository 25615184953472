import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../global/button/Button'
import { Modal } from '../../global/modal/Modal'
import { DotsIcon } from '../../icons/DotsIcon'
import { ClientPhoneNumberDto } from '../../model/AccountDetailedDto'
import { TextSmallStrong, TextTiny } from '../../ui/Typography/Typography'
import { PhoneNumberEditModal } from './Modals/PhoneNumberEditModal'
import { PhoneNumberStatus } from './PhoneNumberStatus'

import styles from './PhoneNumbersCard.module.scss'

interface PhoneNumbersTableProps {
  phoneNumbers: ClientPhoneNumberDto[]
  deleteNumber(number: ClientPhoneNumberDto): void
  verifyNumber(number: ClientPhoneNumberDto): void
  makeNumberPrimary(number: ClientPhoneNumberDto): void
  addNewNumber(): void
}

export const PhoneNumbersCard: React.FC<PhoneNumbersTableProps> = (props) => {
  const { phoneNumbers, deleteNumber, makeNumberPrimary, addNewNumber } = props

  const { t } = useTranslation()

  const [numberToEdit, setNumberToEdit] = useState<ClientPhoneNumberDto>()

  const handleDeleteNumber = () => {
    if (numberToEdit) deleteNumber(numberToEdit)
    setNumberToEdit(undefined)
  }

  const handleMakeNumberPrimary = () => {
    if (numberToEdit) makeNumberPrimary(numberToEdit)
    setNumberToEdit(undefined)
  }

  const primaryNumbers = useMemo(
    () => phoneNumbers.filter((phoneNumber) => phoneNumber.isPrimary),
    [phoneNumbers]
  )
  const secondaryNumbers = useMemo(
    () => phoneNumbers.filter((phoneNumber) => !phoneNumber.isPrimary),
    [phoneNumbers]
  )

  return (
    <React.Fragment>
      {numberToEdit && (
        <Modal
          closeModal={() => setNumberToEdit(undefined)}
          render={({ closeModal }) => (
            <PhoneNumberEditModal
              phoneNumber={numberToEdit}
              onCancel={closeModal}
              deleteNumber={() => handleDeleteNumber()}
              makeNumberPrimary={() => handleMakeNumberPrimary()}
            />
          )}
        />
      )}
      {!!primaryNumbers.length && (
        <>
          <TextTiny isParagraph className={styles.label}>
            {t('PhoneNumbers.Primary phone')}
          </TextTiny>
          {primaryNumbers.map((phoneNumber) => (
            <PhoneCardBody
              {...props}
              phoneNumber={phoneNumber}
              setNumberToEdit={setNumberToEdit}
              key={phoneNumber.id}
            />
          ))}
        </>
      )}
      {!!secondaryNumbers.length && (
        <>
          <TextTiny isParagraph className={styles.label}>
            {t('PhoneNumbers.Secondary phone')}
          </TextTiny>
          {secondaryNumbers.map((phoneNumber) => (
            <PhoneCardBody
              {...props}
              allowEdit
              phoneNumber={phoneNumber}
              setNumberToEdit={setNumberToEdit}
              key={phoneNumber.id}
            />
          ))}
        </>
      )}
      <Button
        onClick={() => addNewNumber()}
        appearance='secondary'
        size='M'
        disabled={phoneNumbers.length >= 3}
        className={styles.action}
      >
        + {t('PhoneNumbers.Add New Phone Number')}
      </Button>
    </React.Fragment>
  )
}

interface PhoneCardBodyProps {
  phoneNumber: ClientPhoneNumberDto
  allowEdit?: boolean
  setNumberToEdit(value: ClientPhoneNumberDto): void
}

const PhoneCardBody = (props: PhoneCardBodyProps & PhoneNumbersTableProps) => {
  const { phoneNumber, allowEdit, verifyNumber, setNumberToEdit } = props

  const { t } = useTranslation()

  return (
    <div className={styles.card}>
      <div className={styles.title}>
        <div className={styles.phoneWrapper}>
          <TextSmallStrong>{`${phoneNumber.countryCode} ${phoneNumber.number}`}</TextSmallStrong>
        </div>
        {phoneNumber.isUsedIn2FA && (
          <TextSmallStrong isParagraph className={styles.twoFA}>
            <span>{t('PhoneNumbers.2FA')}</span>
          </TextSmallStrong>
        )}
        <div className={styles.actionWrapper}>
          <span className={styles.verify}>
            <PhoneNumberStatus
              shouldVerify={phoneNumber.shouldVerify}
              verify={() => verifyNumber(phoneNumber)}
              isVerified={phoneNumber.isVerified}
            />
          </span>
          {allowEdit && (
            <span onClick={() => setNumberToEdit(phoneNumber)} className={styles.dots}>
              <DotsIcon />
            </span>
          )}
        </div>
      </div>
    </div>
  )
}
