import classNames from 'classnames'

import { CheckCircledIcon } from '../../icons/CheckCircledIcon'
import { LandingPageDto } from '../../model/LandingPageDto'

import styles from './LandingPageCard.module.scss'

interface LandingPageCardProps {
  page: LandingPageDto
  selectedPage: LandingPageDto | undefined
  onClick(page: any): void
}

export const LandingPageCard: React.FC<LandingPageCardProps> = (props) => {
  const { page, selectedPage, onClick } = props

  const isSelected = selectedPage === page

  const handleLandingPageClick = () => {
    onClick(page)
  }

  return (
    <div className={styles.landingPageWrapper} onClick={handleLandingPageClick}>
      <div
        className={classNames(styles.landingPage, {
          [styles.selected]: isSelected,
        })}
      >
        <img
          src={page.imagePath}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.remove()
          }}
          alt={'img'}
        />
        <p>{page.name}</p>

        {isSelected && (
          <span className={styles.checkIconWrapper}>
            <CheckCircledIcon
              className={styles.checkIcon}
              circleColor='success'
              checkColor='white'
            />
          </span>
        )}
      </div>
    </div>
  )
}
