import React, { useCallback } from 'react'

import { Loading } from '../../global/Loading/Loading'
import { useSessionEntity } from '../../global/context/EntityContext'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useFetchOne } from '../../utils/useFetch'
import { CompanyDetailsStep1Form, CompanyDetailsStep1FormValues } from './CompanyDetailsStep1Form'

const useDataFetch = () => {
  const testQuestions = useEMIRTestQuestionsFetch()
  const companyTypes = useCompanyTypesFetch()
  const countriesTypes = useCountriesTypesFetch()

  return {
    testQuestions,
    companyTypes,
    countriesTypes,
    isLoading: countriesTypes.isLoading || companyTypes.isLoading || countriesTypes.isLoading,
  }
}

const useEMIRTestQuestionsFetch = () => {
  const locale = useSessionLanguage()
  const apiClient = useApiClient(ClientApiClient)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getEMIRTestQuestionsCallback = useCallback(
    async () => await apiClient.getEMIRTestQuestions(locale),
    [locale]
  )
  const { data: emirTestQuestions = { sections: [], id: '' }, isLoading } = useFetchOne(
    getEMIRTestQuestionsCallback
  )

  const section = emirTestQuestions?.sections?.[0]

  const reportingCounterPartyData = section?.questions?.find(
    (sectionQuestion) => sectionQuestion?.question?.widgetName === 'counterparty_above_threshold'
  )

  const leiCodeData = section?.questions?.find(
    (sectionQuestion) => sectionQuestion?.question?.widgetName === 'lei_code'
  )

  const websiteData = section?.questions?.find(
    (sectionQuestion) => sectionQuestion?.question?.widgetName === 'website'
  )

  const industryData = section?.questions?.find(
    (sectionQuestion) => sectionQuestion?.question?.widgetName === 'industry'
  )

  const otherIndustryData = industryData?.answers?.find((industry) => industry.key === 'other')

  const otherIndustryQuestion = section?.questions?.find(
    (sectionQuestion) => sectionQuestion?.question?.widgetName === 'other_industry'
  )

  return {
    data: emirTestQuestions,
    section,
    reportingCounterPartyData,
    leiCodeData,
    websiteData,
    industryData,
    otherIndustryData,
    otherIndustryQuestion,
    isLoading,
  }
}

const useCompanyTypesFetch = () => {
  const locale = useSessionLanguage()
  const apiClient = useApiClient(ClientApiClient)

  const companyTypeCallback = useCallback(async () => {
    return apiClient.getCompanyTypes(locale)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])
  const { data: companyTypes = [], isLoading } = useFetchOne(companyTypeCallback)

  return { data: companyTypes, isLoading }
}

const useCountriesTypesFetch = () => {
  const apiClient = useApiClient(ClientApiClient)

  const countriesCallback = useCallback(async () => {
    return apiClient.getCountries()
  }, [])
  const { data: countries = [], isLoading } = useFetchOne(countriesCallback)

  return { data: countries, isLoading }
}

interface OuterProps {
  initialValues: CompanyDetailsStep1FormValues
  onSubmit(values: CompanyDetailsStep1FormValues): void
}

export const CompanyDetailsStep1Page: React.FC<OuterProps> = (props) => {
  const { initialValues, onSubmit } = props

  const entity = useSessionEntity()
  const { countriesTypes, companyTypes, testQuestions, isLoading } = useDataFetch()

  return (
    <Loading isLoading={isLoading}>
      <CompanyDetailsStep1Form
        entity={entity}
        companyTypes={companyTypes.data}
        countries={countriesTypes.data}
        section={testQuestions.section}
        testId={testQuestions.data.id}
        reportingCounterPartyData={testQuestions.reportingCounterPartyData}
        industryData={testQuestions.industryData}
        otherIndustryData={testQuestions.otherIndustryData}
        otherIndustryQuestion={testQuestions.otherIndustryQuestion}
        leiCodeData={testQuestions.leiCodeData}
        websiteData={testQuestions.websiteData}
        phoneCodes={countriesTypes.data.map((x) => x.phoneCode)}
        initialValues={initialValues as CompanyDetailsStep1FormValues}
        onSubmit={onSubmit}
      />
    </Loading>
  )
}
