import { useEntitySettings } from '../global/context/EntityContext'
import { usePublicDomainsReadContext } from '../global/context/PublicDomainsContext'
import { TickmillCompaniesEnum } from '../utils/companyName.utils'

export const useHomePageUrl = (): string => {
  const { domains } = usePublicDomainsReadContext()

  const [entity] = useEntitySettings()

  if (domains?.homePageUrl) {
    return domains.homePageUrl
  }

  switch (entity.entity) {
    case TickmillCompaniesEnum.TICKMILL_UK:
      return 'https://tickmill.com/uk'
    case TickmillCompaniesEnum.TICKMILL_EU:
      return 'https://tickmill.com/eu'
    case TickmillCompaniesEnum.GENERIC:
      if (window.location.hostname.endsWith('.com')) {
        return 'https://www.tickmill.com'
      }
      return 'https://www.tickmill.net'
    case TickmillCompaniesEnum.TICKMILL_PA:
    case TickmillCompaniesEnum.TICKMILL_SC:
    case TickmillCompaniesEnum.TICKMILL_AS:
      return 'https://www.tickmill.com'
    default:
      return 'https://www.tickmill.com'
  }
}
