import { isString } from 'formik'

import { Chip } from '../chip/Chip'

import styles from './PercentageAmountChip.module.scss'

type PercentageAmountChipProps = {
  walletBalance: number | undefined
  currentAmount: number | undefined
  onAmountChange: (newAmount: number) => void
}

const options = [50, 100]

export const PercentageAmountChip: React.FC<PercentageAmountChipProps> = ({
  walletBalance,
  currentAmount,
  onAmountChange,
}) => {
  const calculateDepositAmount = (
    walletBalance: number | undefined,
    percentage: number
  ): number => {
    if (!walletBalance) return 0
    return (walletBalance * percentage) / 100
  }

  const preciseCompare = (num1: number | string | undefined, num2: number): boolean => {
    if (num1 === undefined) return false

    const parsedNum1 = isString(num1) ? Number.parseFloat(num1) : num1
    const parsedNum2 = Number.parseFloat(num2.toFixed(2))

    return Number.parseFloat(parsedNum1.toFixed(2)) === parsedNum2
  }

  return (
    <div className={styles.percentageChips}>
      {options.map((option) => (
        <Chip
          key={option}
          text={`${option}%`}
          disabled={!walletBalance || walletBalance < 0.01}
          isActive={preciseCompare(currentAmount, calculateDepositAmount(walletBalance, option))}
          onClick={() => onAmountChange(calculateDepositAmount(walletBalance, option))}
        />
      ))}
    </div>
  )
}
