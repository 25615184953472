import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CancelActionModal } from './CancleActionModal'

import styles from './SessionExpirationModal.module.scss'

interface Props {
  onStayConnected: () => void
  onLogout: () => void
  redirectAfter: number
}

export const SessionExpirationModal: React.FC<Props> = ({
  onStayConnected,
  onLogout,
  redirectAfter,
}) => {
  const [timer, setTimer] = useState(redirectAfter)
  const { t } = useTranslation()

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (timer > 0) setTimer(timer - 1000)
      else onLogout()
    }, 1000)

    return () => {
      clearInterval(timerInterval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer])

  return (
    <CancelActionModal
      title={t('Your Session is About to Expire!')}
      footer={[
        <button className='button' onClick={onLogout} type='button'>
          {t('Log Out')}
        </button>,
        <button className='button' onClick={onStayConnected} type='button'>
          {t('Stay Connected')}
        </button>,
      ]}
    >
      <p className={styles.text}>
        {t('Your session will expire in', {
          minutes: redirectAfter / 60 / 1000,
        })}
      </p>
      <p className={styles.text}>{t('Redirecting in', { seconds: timer / 1000 })}</p>
      <div className='pt-4'>
        <progress
          className='progress is-danger is-small'
          value={timer / 10 / (redirectAfter / 1000)}
          max='100'
        />
      </div>
    </CancelActionModal>
  )
}
