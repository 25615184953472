import React, { ReactNode, createContext, useCallback, useContext, useMemo } from 'react'

import { TickmillProductType } from '../../model/TickmillProductType'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useFetchOne } from '../../utils/useFetch'
import { useSessionEntity } from './EntityContext'
import { useProductReadContext } from './ProductContext'
import { useSessionLanguage } from './SessionSettingsContext'

enum LegalDocuments {
  websiteTermsAndConditions = 'terms_of_use_of_the_website',
  termsOfBusiness = 'terms_of_business',
  termsOfBusinessWebsite = 'terms_of_business_website',
  riskDisclosure = 'risk_disclosure',
  cookieConsent = 'cookie_consent_url',
  clientAgreement = 'client_agreement',
  privacyPolicy = 'privacy_policy',
  bestExecutionPolicy = 'best_execution_policy',
  cysec = 'cysec',
  financialConductAuthority = 'financial_conduct_authority',
  financialServicesRegister = 'financial_services_register',
  dynamicLeverageInfo = 'dynamic_leverage_info',
}

type LegalDocumentLink = {
  [K in keyof typeof LegalDocuments]?: string
}

interface LegalLinksContextType {
  docLinks: LegalDocumentLink | null
}

const LegalLinksContext = createContext<LegalLinksContextType>({ docLinks: {} })

export const LegalLinksProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const locale = useSessionLanguage()
  const tickmillCompany = useSessionEntity()
  const { isDefaultCFDProductType } = useProductReadContext()
  const apiClient = useApiClient(ClientApiClient)
  const isDefaultCFDProduct = isDefaultCFDProductType()

  const productIds = useMemo(
    () => [isDefaultCFDProduct ? TickmillProductType.CFD : TickmillProductType.ETD],
    [isDefaultCFDProduct]
  )

  const fetchLegalDocuments = useCallback(
    async () => await apiClient.getLegalDocumentsQuery(locale, tickmillCompany, productIds),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale, tickmillCompany, productIds]
  )
  const { data: documents } = useFetchOne(fetchLegalDocuments)

  const docFinder = useCallback(
    (code: LegalDocuments) => documents?.find((document) => document.code === code)?.url,
    [documents]
  )

  const docLinks = useMemo(
    () =>
      Object.fromEntries(
        Object.keys(LegalDocuments).map((docKey) => [
          docKey,
          docFinder(LegalDocuments[docKey as keyof typeof LegalDocuments]),
        ])
      ) as LegalDocumentLink,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [documents]
  )

  return <LegalLinksContext.Provider value={{ docLinks }}>{children}</LegalLinksContext.Provider>
}

export const useLegalLinksContext = (): LegalLinksContextType => {
  const context = useContext(LegalLinksContext)
  if (!context) {
    throw new Error('useLegalLinksContext must be used within a LegalLinksProvider')
  }
  return context
}
