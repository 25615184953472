import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { ExternalLink } from '../../global/ExternalLink'
import { usePublicDomainsReadContext } from '../../global/context/PublicDomainsContext'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { EmailIcon } from '../../icons/EmailIcon'
import { LiveChatIcon } from '../../icons/LiveChatIcon'
import { PhoneIcon } from '../../icons/PhoneIcon'
import { isAccountCorporate } from '../../model/AccountType'
import { Text, TextStrong } from '../../ui/Typography/Typography'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { PageSections, getContactUsLink } from '../../utils/companyName.utils'
import { dispatchOpenChat } from '../../utils/cookie.utils'

import styles from './ProductAppropriatenessTestPage.module.scss'

export const SupportOptions: FC = () => {
  const { t } = useTranslation()
  const { domains } = usePublicDomainsReadContext()
  const { account } = useAccountReadContext()
  const locale = useSessionLanguage()
  const contactUsLink = getContactUsLink(domains, locale)
  const contactDetailLink = getContactUsLink(domains, locale, PageSections.CONTACT_DETAILS)
  const isCorporate = isAccountCorporate(account)

  return (
    <div className={classNames(styles.supportOptions, 'is-fullwidth')}>
      <h1 className='mb-6'>{t('Profile.Financial Info & Experience')}</h1>
      <TextStrong>
        {isCorporate ? (
          <p className='mb-4'>
            {t(
              'Profile.Please fill these sections in accordance to the knowledge and experience of the person authorised to trade on behalf of the company.'
            )}
          </p>
        ) : (
          <p className='mb-4'>
            {t('Sign up.Please answer all questions with accurate information that reflect')}
          </p>
        )}
      </TextStrong>
    </div>
  )
}

export const CFDSupportOptions: FC = () => {
  const { t } = useTranslation()
  const { domains } = usePublicDomainsReadContext()
  const locale = useSessionLanguage()
  const link = getContactUsLink(domains, locale)

  return (
    <div className={classNames(styles.supportOptions, 'is-fullwidth')}>
      <h1 className='mb-6'>{t('CFDs Appropriateness Test')}</h1>
      <div className='mb-4'>
        <TextStrong>
          {t(
            'Please answer all questions accurately and ensure that your answers truly reflect your current circumstances at the time of completing this registration'
          )}
        </TextStrong>
      </div>
      <div className='mb-4'>
        <Text>
          {t(
            'It is required to take an appropriateness test to ensure that you have sufficient knowledge to trade CFDs.'
          )}
        </Text>
      </div>
    </div>
  )
}
