import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { useArabicSessionLanguage } from '../../global/context/SessionSettingsContext'
import { Table } from '../../global/table/Table'
import { BankAccount } from '../../model/BankAccount'
import { Tooltip } from '../../ui/Popups/Tooltip/Tooltip'
import { TextSmall } from '../../ui/Typography/Typography'
import { BankBadgeStatus } from './BankBadgeStatus'

import styles from './BankAccountsTable.module.scss'

interface BankAccountsTableProps {
  bankAccounts?: BankAccount[]
}

export const BankAccountsTable: React.FC<BankAccountsTableProps> = ({ bankAccounts }) => {
  const isArabic = useArabicSessionLanguage()
  const { t } = useTranslation()
  const [expandedRow, setExpandedRow] = useState('')

  const handleSetExpandedRow = (id: string) => setExpandedRow(id === expandedRow ? '' : id)

  return (
    <React.Fragment>
      <Table className={styles.table}>
        <thead>
          <tr>
            <th>{t('Beneficiary Name')}</th>
            <th>{t('IBAN/Account Number')}</th>
            <th>{t('Bank Name')}</th>
            <th className='is-flex is-justify-content-center mr-5'>{t('Status')}</th>
          </tr>
        </thead>
        {bankAccounts?.map((bank) => (
          <tbody>
            <tr>
              <td className='has-cursor-pointer' onClick={() => handleSetExpandedRow(bank.id)}>
                <Tooltip content={bank.accountName} direction={isArabic ? 'topLeft' : 'topRight'}>
                  <strong className={styles.cellText}>{bank.accountName}</strong>
                </Tooltip>
              </td>
              <td>
                <Tooltip content={bank.bankAccount}>
                  <span className={styles.cellText}>{bank.bankAccount}</span>
                </Tooltip>
              </td>
              <td>
                <Tooltip content={bank.bankName}>
                  <span className={styles.cellText}>{bank.bankName}</span>
                </Tooltip>
              </td>
              <td className='is-flex is-justify-content-center'>
                <span>
                  <BankBadgeStatus {...bank.status} />
                </span>
              </td>
            </tr>
            {expandedRow === bank.id && (
              <tr className={styles.expandable}>
                <td className={styles.expanded} colSpan={4}>
                  <div
                    dir={isArabic ? 'rtl' : ''}
                    className={classNames('px-4 py-4', styles.container)}
                  >
                    <div className={styles.leftContainer}>
                      <ExpandedField
                        label={t('Bank Address')}
                        value={bank.bankAddress}
                        isArabic={isArabic}
                      />
                      <ExpandedField label={t('Swift')} value={bank.bankCode} isArabic={isArabic} />
                      <ExpandedField
                        label={t('Sort Code')}
                        value={bank.bankCodeSort}
                        isArabic={isArabic}
                      />
                    </div>
                    <div>
                      <ExpandedField
                        label={t('Documents')}
                        value={bank.document?.name}
                        isArabic={isArabic}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        ))}
      </Table>
    </React.Fragment>
  )
}

const ExpandedField = ({
  label,
  value,
  isArabic,
  onClick,
}: {
  label: string
  value: string
  isArabic: boolean
  onClick?: () => void
}) => {
  if (!value) return null
  return (
    <div className='mt-4'>
      <TextSmall dir={isArabic ? 'rtl' : ''} className={styles.secondaryTextSmall}>
        {label}:
      </TextSmall>
      <TextSmall
        dir={isArabic ? 'rtl' : ''}
        className={classNames({
          'is-link': !!onClick,
        })}
        onClick={onClick}
      >
        {' '}
        {value}
      </TextSmall>
    </div>
  )
}
