import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { CopyIcon } from '../../icons/CopyIconActiveIcon'
import { DropArrowDownIcon } from '../../icons/DropArrowDownIcon'
import { DropArrowUpIcon } from '../../icons/DropArrowUpIcon'
import { InfoIcon } from '../../icons/InfoIcon'
import { TextSmall, TextTiny } from '../../ui/Typography/Typography'
import { copyToClipboard } from '../../utils/navigator.utils'
import { useSnackbar } from '../context/SnackbarContext'
import { getLabel, getPlaceholder, isFocus } from '../formField/helpers'
import { RadioButton } from '../radioButton/RadioButton'

import styles from './SelectField.module.scss'

interface Option {
  value?: string | number
  label: string | number
}

export interface SelectFieldProps {
  label?: string
  placeholder?: string
  name?: string
  className?: string
  hint?: string
  required?: boolean
  disabled?: boolean
  tooltip?: string
  error?: string
  allowCopy?: boolean
  onChange?(value: React.ChangeEvent<HTMLSelectElement>): void
  onClick?(toggleDropdown: () => void): void
  value?: string | number
  renderTooltip?(): React.ReactNode
  options?: Option[]
  radio?: boolean
  renderValue?: React.ReactNode
  mini?: boolean
  allowEmpty?: boolean
}

export function SelectField(props: SelectFieldProps): JSX.Element {
  const {
    name = '',
    hint,
    className,
    tooltip,
    renderTooltip,
    allowCopy,
    onClick,
    error,
    value,
    radio,
    options = [],
    disabled = !options || options.length < 2,
    renderValue,
    mini,
    allowEmpty,
    ...inputProps
  } = props
  const { addSnackbar } = useSnackbar()

  useEffect(() => {
    if (options.length !== 1) return
    inputProps.onChange?.({
      target: {
        name,
        value: options[0].value,
      },
    } as React.ChangeEvent<HTMLSelectElement>)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.length])

  const { t } = useTranslation()
  const fieldRef = useRef<HTMLDivElement>(null)

  const label = getLabel(props)
  const placeholder = getPlaceholder(props)
  const selectOptions = useMemo(
    () => [...(allowEmpty ? [{ value: '', label: placeholder || t('Select') }] : []), ...options],
    [allowEmpty, options, placeholder, t]
  )

  const tooltipNode = renderTooltip ? (
    renderTooltip()
  ) : tooltip ? (
    <span data-tooltip={tooltip}>
      {' '}
      <InfoIcon />
    </span>
  ) : null

  const [isOpen, setIsOpen] = useState(false)

  const toggleDropdown = (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e) e.stopPropagation()
    if (disabled) return
    if (onClick) {
      onClick(() => setIsOpen(!isOpen))
    } else setIsOpen(!isOpen)
  }

  const handleOptionSelect = (option: Option) => {
    inputProps.onChange?.({
      target: {
        name,
        value: option.value,
      },
    } as React.ChangeEvent<HTMLSelectElement>)
    setIsOpen(false)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (fieldRef.current && !fieldRef.current.contains(event.target as Node)) {
        if (isOpen) {
          toggleDropdown()
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <div className={classNames(styles.field, className)} ref={fieldRef}>
      {isOpen && <div onClick={toggleDropdown} className={styles.fieldBackground}></div>}
      <div
        className={classNames(styles.select, {
          [styles.inputError]: !!error,
          [styles.disabled]: disabled,
          [styles.mini]: mini,
        })}
        onClick={toggleDropdown}
      >
        <div className={styles.labelValueContainer}>
          {isFocus({
            renderValue,
            value: selectOptions.find((option) => option.value === value)?.label,
            label,
          }) &&
            !!label && (
              <label
                className={classNames('label', styles.label, {
                  [styles.hasTextDanger]: !!error,
                })}
              >
                {label}
                {tooltipNode}
              </label>
            )}

          <div
            className={classNames(styles.selectedOption, {
              [styles.mini]: mini,
            })}
            id={name}
          >
            {allowCopy && (
              <span className={styles.icon}>
                <CopyIcon
                  onClick={(e) => {
                    e.stopPropagation()
                    copyToClipboard(value)
                    addSnackbar.success({ message: t('Copied') })
                  }}
                  size={18}
                  color='textSecondary'
                />
              </span>
            )}
            {renderValue ? (
              renderValue
            ) : value ? (
              selectOptions.find((option) => option.value === value)?.label
            ) : (
              <span className={styles.placeholder}>{placeholder || t('Select')}</span>
            )}
          </div>
        </div>
        {isOpen ? <DropArrowUpIcon size={15} /> : <DropArrowDownIcon size={15} />}
      </div>

      {isOpen && (
        <ul className={styles.optionsList}>
          {selectOptions.map((option) => (
            <li
              key={option.value}
              className={classNames(styles.option, {
                [styles.selectedOption]: option.value === value,
              })}
              onClick={() => handleOptionSelect(option)}
            >
              {radio ? (
                <RadioButton
                  name={name}
                  value={option.value}
                  checked={option.value === value}
                  key={option.value}
                  onClick={() => handleOptionSelect(option)}
                >
                  <TextSmall className={styles.optionLabel}>{option.label}</TextSmall>
                </RadioButton>
              ) : (
                <TextSmall className={styles.optionLabel}>{option.label}</TextSmall>
              )}
            </li>
          ))}
        </ul>
      )}

      {hint && <TextTiny className={styles.hint}>{hint}</TextTiny>}

      {error && (
        <TextTiny className={classNames(styles.errorMessage, 'is-danger')}>{error}</TextTiny>
      )}
    </div>
  )
}
