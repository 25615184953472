import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Button } from '../global/button/Button'
import { ArrowLeftIcon } from '../icons/ArrowLeftIcon'
import { ArrowRightIcon } from '../icons/ArrowRightIcon'
import { BankIcon } from '../icons/BankIcon'
import { DepositIcon } from '../icons/DepositIcon'
import { TickmillWalletIcon } from '../icons/TickmillWalletIcon'
import { TradingAccountIcon } from '../icons/TradingAccountIcon'
import { TradingPlatformIcon } from '../icons/TradingPlatformIcon'
import { WalletIcon } from '../icons/WalletIcon'
import { isOne } from '../utils/validations'

import styles from './JourneyPage.module.scss'

interface Props {
  stepNumber: number
  nextScreen: () => void
  startJourney: () => void
}

export const JourneyStep: React.FC<Props> = ({ stepNumber, nextScreen, startJourney }) => {
  const { t } = useTranslation()

  if (isOne(stepNumber)) {
    return (
      <div className={classNames(styles.wrapper, 'pt-6')}>
        <h1 className={classNames(styles.title, 'mb-6')}>{t('What is Tickmill?')}</h1>
        <p>{t('Tickmill is NOT a trading platform. At least not yet')}</p>
        <p>
          {t('Tickmill is your')} <b> {t('personal wallet')}</b>{' '}
          {t('between your bank account and trading platform.')}
        </p>

        <div className={classNames(styles['wrapper-row'], 'mt-6')}>
          <div className={classNames(styles.wrapper)}>
            <BankIcon size={65} />
            <p className='mt-2'>{t('Your')}</p>
            <p>{t('Bank')}</p>
          </div>
          <div className='pl-6 pr-6'>
            <div>
              <ArrowLeftIcon />
            </div>
            <div>
              <ArrowRightIcon />
            </div>
          </div>
          <div className={classNames(styles['wrapper-row'])}>
            <TickmillWalletIcon />
          </div>
          <div className='pl-6 pr-6'>
            <div>
              <ArrowLeftIcon />
            </div>
            <div>
              <ArrowRightIcon />
            </div>
          </div>
          <div className={classNames(styles.wrapper)}>
            <TradingPlatformIcon />
            <p className='mt-2'>{t('Trading')}</p>
            <p>{t('Platform')}</p>
          </div>
        </div>

        <div className={classNames('mt-6')} style={{ width: 144 }}>
          <Button appearance='primary' size='S' onClick={nextScreen}>
            {t('Next')}
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className={classNames(styles.wrapper, 'pt-6')}>
      <h1 className={styles.title}>{t('First steps')}</h1>
      <div className={classNames(styles['wrapper-row'], 'mt-6')}>
        <div className={classNames(styles.wrapper, 'mr-6')}>
          <WalletIcon size={80} />
          <p className={classNames('mt-4 mb-5', styles.black)}>{t('Create a Wallet')}</p>
          <div className={classNames(styles.round, styles.padded)}>1</div>
        </div>

        <div className={classNames(styles.wrapper, 'mr-6')}>
          <TradingAccountIcon size={80} />
          <p className={classNames('mt-4 mb-5', styles.black)}>{t('Create a trading account')}</p>
          <div className={classNames(styles.round, styles.padded)}>2</div>
        </div>

        <div className={styles.wrapper}>
          <DepositIcon size={80} />
          <p className={classNames('mt-4 mb-5', styles.black)}>{t('Deposit funds')}</p>
          <div className={classNames(styles.round, styles.padded)}>3</div>
        </div>
      </div>
      <p className='mt-6'>{t("...and your'r ready to go!")}</p>

      <div className={classNames(styles.wrapper, 'mt-6')}>
        <Button appearance='primary' size='S' onClick={startJourney}>
          {t('Start your journey')}
        </Button>
      </div>
    </div>
  )
}
