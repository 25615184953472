import React, { forwardRef, useImperativeHandle, useState } from 'react'
import classNames from 'classnames'

import styles from './Tooltip.module.scss'

export type TooltipDirection = 'top' | 'bottom' | 'left' | 'right' | 'topRight' | 'topLeft'

interface TooltipProps {
  content?: string | null
  direction?: TooltipDirection
  children?: React.ReactNode
  autoHide?: number
  disabled?: boolean
  className?: string
}

export const Tooltip = forwardRef((props: TooltipProps, ref) => {
  const { content, direction = 'top', children, disabled, className } = props
  const { autoHide } = props
  let timeout: NodeJS.Timeout

  const [active, setActive] = useState(false)

  useImperativeHandle(ref, () => ({
    activate() {
      if (!active && !disabled && !!content) {
        setActive(true)
        if (autoHide) setTimeout(() => setActive(false), autoHide)
      }
    },
  }))

  const showTip = () => {
    if (disabled || !content) return
    setActive(true)
  }

  const hideTip = () => {
    if (disabled || !content) return
    clearInterval(timeout)
    setActive(false)
  }

  return (
    <div onMouseEnter={showTip} onMouseLeave={hideTip} className={styles.tooltipWrapper}>
      {children}
      {active && (
        <div className={classNames(styles.tooltip, styles[direction], className)}>
          <span>{content}</span>
        </div>
      )}
    </div>
  )
})
