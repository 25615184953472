import React, { ReactNode, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { differenceInDays, differenceInMilliseconds, secondsToMilliseconds } from 'date-fns'

import { ExternalLink } from '../../global/ExternalLink'
import { Loading } from '../../global/Loading/Loading'
import { Button } from '../../global/button/Button'
import { useSessionEntity } from '../../global/context/EntityContext'
import { useProductReadContext, useProductWriteContext } from '../../global/context/ProductContext'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { useHomePageUrl } from '../../hooks/useHomePageUrl'
import { AnnouncementIcon } from '../../icons/AnnouncementIcon'
import { WarningIcon } from '../../icons/WarningIcon'
import {
  AccountDetailedDto,
  hasProductActivatedStatus,
  hasProductClosedStatus,
  hasProductDormantStatus,
  isAppropriatenessTestMaximumRetakeLimitReached,
  isProductRegistered,
} from '../../model/AccountDetailedDto'
import { TestAnswerOutcomeStatus, TestAnswersDto } from '../../model/ClientTestAnswerDto'
import {
  TickmillProductType,
  isTickmillProductTypeCFD,
  isTickmillProductTypeETD,
} from '../../model/TickmillProductType'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { TextStrong } from '../../ui/Typography/Typography'
import { useAccountReadContext, useAccountWriteContext } from '../../utils/AccountContextContext'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { TickmillCompaniesEnum, isTickmillUKType } from '../../utils/companyName.utils'
import { useFetchOne } from '../../utils/useFetch'
import { isOne } from '../../utils/validations'

import styles from './AppropriatenessTest.module.scss'

export const AppropriatenessTestPage: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const apiClient = useApiClient(ClientApiClient)
  const locale = useSessionLanguage()
  const homePageUrl = useHomePageUrl()
  const { refreshAccount } = useAccountWriteContext()
  const { changeProductContext } = useProductWriteContext()
  const { product } = useProductReadContext()
  const { account } = useAccountReadContext()
  const isProductTypeCFD = isTickmillProductTypeCFD(product)
  const isProductTypeETD = isTickmillProductTypeETD(product)
  const hasCFDLimitReached = isAppropriatenessTestMaximumRetakeLimitReached(
    account,
    TickmillProductType.CFD
  )
  const hasETDLimitReached = isAppropriatenessTestMaximumRetakeLimitReached(
    account,
    TickmillProductType.ETD
  )
  const entity = useSessionEntity()
  const isETDRegistered = isProductRegistered(account, TickmillProductType.ETD)
  const isCFDRegistered = isProductRegistered(account, TickmillProductType.CFD)
  const isETDActivated = hasProductActivatedStatus(account, TickmillProductType.ETD)
  const isCFDActivated = hasProductActivatedStatus(account, TickmillProductType.CFD)

  const callback = useCallback(async () => {
    return Promise.all([
      apiClient.getAccountAppTestAnswers(locale, product),
      apiClient.getApTestAttempts(TickmillProductType.CFD),
      apiClient.getApTestAttempts(TickmillProductType.ETD),
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data, isLoading } = useFetchOne(callback)
  const [apTestAnswers, attemptCFD, attemptETD] = data || []

  const remainingWaitingTime = getRemainingWaitingTime(account, apTestAnswers)
  const isTakeTestAllowed = canTakeTest(account, apTestAnswers, product)

  const remainingDays = useMemo((): number => {
    return 365 - differenceInDays(new Date(), new Date(apTestAnswers?.dateTaken || ''))
  }, [apTestAnswers])

  const { days, hours, minutes, seconds } = remainingWaitingTime
  const isTimeLeft = !!(days + hours + minutes + seconds)

  const showProductSelector = account?.companyConfiguration.showProductSwitcher

  const onTryProduct = async (tickmillProductType: TickmillProductType) => {
    if (tickmillProductType) {
      if (!isProductRegistered(account, tickmillProductType)) {
        await apiClient.addTickmillProduct(tickmillProductType)
        refreshAccount(locale)
      }

      changeProductContext(tickmillProductType)
    }

    navigate('/dashboard/traders-room/balances')
  }

  const goToAppTakeTest = useCallback(() => {
    navigate('/profile/appropriateness-test/take-test')
  }, [navigate])

  useEffect(() => {
    refreshAccount(locale)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderAppropriatenessTestView = (): ReactNode => {
    if (isETDRegistered && isCFDRegistered && !isTimeLeft && attemptCFD && attemptETD) {
      return (
        <AppropriatenessTestMainView
          apTestAnswers={apTestAnswers}
          entity={entity}
          attempt={isProductTypeCFD ? attemptCFD : attemptETD}
          remainingWaitingTime={remainingWaitingTime}
          isTakeTestAllowed={isTakeTestAllowed}
          goToAppTakeTest={goToAppTakeTest}
          redirectLink={homePageUrl}
        />
      )
    }
    if (isProductTypeETD) {
      return (
        <AppropriatenessTestETDView
          apTestAnswers={apTestAnswers}
          attempt={attemptETD}
          remainingWaitingTime={remainingWaitingTime}
          isTakeTestAllowed={isTakeTestAllowed}
          goToAppTakeTest={goToAppTakeTest}
          onTryProductClick={() => onTryProduct(TickmillProductType.CFD)}
          redirectLink={homePageUrl}
          hasLimitReached={hasETDLimitReached}
          isRightBannerHidden={
            !showProductSelector ||
            !hasETDLimitReached ||
            !isTickmillUKType(entity) ||
            isCFDActivated
          }
          remainingDays={remainingDays}
        />
      )
    }
    if (isProductTypeCFD) {
      return (
        <AppropriatenessTestCFDView
          apTestAnswers={apTestAnswers}
          attempt={attemptCFD}
          remainingWaitingTime={remainingWaitingTime}
          isTakeTestAllowed={isTakeTestAllowed}
          goToAppTakeTest={goToAppTakeTest}
          onTryProductClick={() => onTryProduct(TickmillProductType.ETD)}
          redirectLink={homePageUrl}
          hasLimitReached={hasCFDLimitReached}
          isRightBannerHidden={
            !showProductSelector ||
            !hasCFDLimitReached ||
            !isTickmillUKType(entity) ||
            isETDActivated
          }
          remainingDays={remainingDays}
        />
      )
    }
    return (
      <AppropriatenessTestMainView
        apTestAnswers={apTestAnswers}
        entity={entity}
        attempt={isProductTypeCFD ? attemptCFD : attemptETD}
        remainingWaitingTime={remainingWaitingTime}
        isTakeTestAllowed={isTakeTestAllowed}
        goToAppTakeTest={goToAppTakeTest}
        redirectLink={homePageUrl}
      />
    )
  }

  return (
    <Loading isLoading={isLoading} showLoadingIcon>
      <PageHeader title={t('Profile.Trading Info & Experience')} />
      {renderAppropriatenessTestView()}
    </Loading>
  )
}

interface AppropriatenessTestMainViewProps {
  apTestAnswers?: TestAnswersDto
  entity: TickmillCompaniesEnum
  attempt?: number
  remainingWaitingTime: GetRemainingWaitingTimeValue
  isTakeTestAllowed: boolean
  goToAppTakeTest: () => void
  redirectLink: string
}

const AppropriatenessTestMainView: React.FC<AppropriatenessTestMainViewProps> = ({
  apTestAnswers,
  attempt,
  entity,
  remainingWaitingTime,
  isTakeTestAllowed,
  goToAppTakeTest,
  redirectLink,
}) => {
  const { t } = useTranslation()

  return (
    <div className='pb-3 mt-3'>
      <div className={styles.box}>
        <WarningTitle title={t('Profile.WARNING!')} />
        <div className='mb-5'>
          <WarningMessage
            message={t(
              'Profile.Client Area and opening a Trading Account will only be available upon satisfactory completion of appropriateness test.'
            )}
          />
          {apTestAnswers && apTestAnswers.outcome.outcome === TestAnswerOutcomeStatus.FAILED && (
            <DueDateMessage attempt={attempt} remainingWaitingTime={remainingWaitingTime} />
          )}
        </div>
        <div className='columns is-centered'>
          {isTickmillUKType(entity) && (
            <div className='column is-3'>
              <Link to='/dashboard/learning/tutorial-videos'>
                <Button size='M' appearance='secondary' className='is-fullwidth'>
                  <span>{t('Profile.Futures Training Materials')}</span>
                </Button>
              </Link>
            </div>
          )}
          <div className='column is-3'>
            <Link to='/dashboard/learning/trainings'>
              <Button size='M' appearance='secondary' className='is-fullwidth'>
                <span>{t('Profile.CFD Training Materials')}</span>
              </Button>
            </Link>
          </div>
        </div>

        <div className='pb-6'>
          <div className='columns is-centered'>
            <div className='column is-3'>
              <Button
                size='M'
                appearance='primary'
                className='is-fullwidth'
                onClick={goToAppTakeTest}
                disabled={!isTakeTestAllowed}
              >
                {t('Profile.Take test')}
              </Button>
            </div>
          </div>
        </div>

        <div className='columns is-centered mb-6'>
          {isTickmillUKType(entity) && (
            <div className='column is-3 is-flex is-justify-content-center'>
              <ExternalLink url={`${redirectLink}/futures#`} className='is-link'>
                {t('Profile.Get Your Free Futures Demo Account')}
              </ExternalLink>
            </div>
          )}
          <div className='column is-3 is-flex is-justify-content-center'>
            <ExternalLink url={`${redirectLink}/trading/demo-account`} className='is-link'>
              {t('Profile.Get Your Free CFD Demo Account')}
            </ExternalLink>
          </div>
        </div>
      </div>
    </div>
  )
}

interface AppropriatenessTestETDViewProps {
  apTestAnswers?: TestAnswersDto
  attempt?: number
  remainingWaitingTime: GetRemainingWaitingTimeValue
  isTakeTestAllowed: boolean
  goToAppTakeTest: () => void
  onTryProductClick: () => void
  redirectLink?: string
  hasLimitReached: boolean
  isRightBannerHidden?: boolean
  remainingDays: number
}

const AppropriatenessTestETDView: React.FC<AppropriatenessTestETDViewProps> = ({
  apTestAnswers,
  attempt,
  remainingWaitingTime,
  isTakeTestAllowed,
  goToAppTakeTest,
  onTryProductClick,
  redirectLink,
  hasLimitReached,
  isRightBannerHidden,
  remainingDays,
}) => {
  const { t } = useTranslation()

  return (
    <div className={classNames(styles.appropriatenessTestView, 'pb-3', 'mt-3')}>
      <div className={classNames(styles.box, styles.boxFill, styles.boxError, styles.boxPadding)}>
        <WarningTitle
          title={
            hasLimitReached ? t('Profile.Trading Experience Test Failed!') : t('Profile.WARNING!')
          }
        />
        <WarningMessage
          message={
            hasLimitReached
              ? t(
                  'Profile.Maximum attempts (3/3) reached\\. You will be able to take the appropriateness test in [365] days',
                  { remainingDays }
                )
              : t(
                  'Profile.Client Area and opening a Trading Account will only be available upon satisfactory completion of appropriateness test.'
                )
          }
        />
        {!hasLimitReached && apTestAnswers?.outcome.outcome === TestAnswerOutcomeStatus.FAILED && (
          <DueDateMessage attempt={attempt} remainingWaitingTime={remainingWaitingTime} />
        )}
        <div className='columns is-centered mt-3'>
          <div className='column is-5'>
            <Link to='/dashboard/learning/tutorial-videos'>
              <Button size='M' appearance='secondary' className='is-fullwidth'>
                <span>{t('Profile.Futures Training Materials')}</span>
              </Button>
            </Link>
          </div>
          <div className='column is-5'>
            <Button
              size='M'
              appearance='primary'
              className='is-fullwidth'
              onClick={goToAppTakeTest}
              disabled={!isTakeTestAllowed || hasLimitReached}
            >
              {t('Profile.Take test')}
            </Button>
          </div>
        </div>

        <div className='pt-4'>
          <DemoAccountLink
            redirectLink={`${redirectLink}/futures#`}
            label={t('Profile.Get Your Free Futures Demo Account')}
          />
        </div>
      </div>
      {!isRightBannerHidden && (
        <div className={classNames(styles.box, styles.boxBanner)}>
          <div
            className={classNames(styles.bannerIconWrapper, 'is-flex is-justify-content-center')}
          >
            <AnnouncementIcon size={80} color='textSecondary' />
          </div>
          <TextStrong
            isParagraph
            className={classNames(styles.infoStrong, 'content', 'text-align-center')}
          >
            {t('Profile.Try CFD Product')}
          </TextStrong>
          <p className={classNames(styles.textSecondary, 'content', 'text-align-center')}>
            {t(
              'Profile.You still can apply for our CFD product by clicking the “Try CFD Product” button'
            )}
          </p>
          <div className='pb-4'>
            <Button
              size='M'
              appearance='primary'
              className='is-fullwidth'
              onClick={onTryProductClick}
            >
              <span>{t('Profile.Try CFD Product')}</span>
            </Button>
          </div>
          <Link to='/dashboard/learning/trainings'>
            <Button size='M' appearance='secondary' className='is-fullwidth'>
              <span>{t('Profile.CFD Training Materials')}</span>
            </Button>
          </Link>
          <DemoAccountLink
            redirectLink={`${redirectLink}/trading/demo-account`}
            label={t('Profile.Get Your Free CFD Demo Account')}
          />
        </div>
      )}
    </div>
  )
}

interface AppropriatenessTestCFDViewProps {
  apTestAnswers?: TestAnswersDto
  attempt?: number
  remainingWaitingTime: GetRemainingWaitingTimeValue
  isTakeTestAllowed: boolean
  goToAppTakeTest: () => void
  onTryProductClick: () => void
  redirectLink?: string
  hasLimitReached: boolean
  isRightBannerHidden?: boolean
  remainingDays: number
}

const AppropriatenessTestCFDView: React.FC<AppropriatenessTestCFDViewProps> = ({
  apTestAnswers,
  attempt,
  remainingWaitingTime,
  isTakeTestAllowed,
  goToAppTakeTest,
  onTryProductClick,
  redirectLink,
  hasLimitReached,
  isRightBannerHidden,
  remainingDays,
}) => {
  const { t } = useTranslation()

  return (
    <div className={classNames(styles.appropriatenessTestView, 'pb-3', 'mt-3')}>
      <div className={classNames(styles.box, styles.boxFill, styles.boxError, styles.boxPadding)}>
        <WarningTitle
          title={
            hasLimitReached ? t('Profile.Trading Experience Test Failed!') : t('Profile.WARNING!')
          }
        />
        <WarningMessage
          message={
            hasLimitReached
              ? t(
                  'Profile.Maximum attempts (3/3) reached\\. You will be able to take the appropriateness test in [365] days',
                  { remainingDays }
                )
              : t(
                  'Profile.Client Area and opening a Trading Account will only be available upon satisfactory completion of appropriateness test.'
                )
          }
        />
        {!hasLimitReached && apTestAnswers?.outcome.outcome === TestAnswerOutcomeStatus.FAILED && (
          <DueDateMessage attempt={attempt} remainingWaitingTime={remainingWaitingTime} />
        )}
        <div className='columns is-centered mt-3'>
          <div className='column is-5'>
            <Link to='/dashboard/learning/trainings'>
              <Button size='M' appearance='secondary' className='is-fullwidth'>
                <span>{t('Profile.CFD Training Materials')}</span>
              </Button>
            </Link>
          </div>

          <div className='column is-5'>
            <Button
              size='M'
              appearance='primary'
              className='is-fullwidth'
              onClick={goToAppTakeTest}
              disabled={!isTakeTestAllowed || hasLimitReached}
            >
              {t('Profile.Take test')}
            </Button>
          </div>
        </div>
        <div className='pt-4'>
          <DemoAccountLink
            redirectLink={`${redirectLink}/trading/demo-account`}
            label={t('Profile.Get Your Free CFD Demo Account')}
          />
        </div>
      </div>
      {!isRightBannerHidden && (
        <div className={classNames(styles.box, styles.boxBanner)}>
          <div
            className={classNames(styles.bannerIconWrapper, 'is-flex is-justify-content-center')}
          >
            <AnnouncementIcon size={80} color='textSecondary' />
          </div>
          <TextStrong
            isParagraph
            className={classNames(styles.infoStrong, 'content', 'text-align-center')}
          >
            {t('Profile.Try Futures Product')}
          </TextStrong>
          <p className={classNames(styles.textSecondary, 'content', 'text-align-center')}>
            {t(
              'Profile.You still can apply for our Futures product by clicking the “Try Futures Product” button'
            )}
          </p>
          <div className='pb-4'>
            <Button
              size='M'
              appearance='primary'
              className='is-fullwidth'
              onClick={onTryProductClick}
            >
              <span>{t('Profile.Try Futures Product')}</span>
            </Button>
          </div>

          <Link to='/dashboard/learning/tutorial-videos'>
            <Button size='M' appearance='secondary' className='is-fullwidth'>
              <span>{t('Profile.Futures Training Materials')}</span>
            </Button>
          </Link>

          <DemoAccountLink
            redirectLink={`${redirectLink}/futures#`}
            label={t('Profile.Get Your Free Futures Demo Account')}
          />
        </div>
      )}
    </div>
  )
}

interface WarningTitleProps {
  title: string
}

const WarningTitle: React.FC<WarningTitleProps> = ({ title }) => {
  return (
    <div className='pb-4'>
      <span className='text-align-center'>
        <h4
          className={classNames(
            styles.warning,
            'is-flex',
            'is-align-items-center',
            'is-justify-content-center'
          )}
        >
          <WarningIcon size={20} color={'error'} />
          <span className='ml-1'>{title}</span>
        </h4>
      </span>
    </div>
  )
}

interface WarningMessageProps {
  message: string
}

const WarningMessage: React.FC<WarningMessageProps> = ({ message }) => {
  return (
    <div className='mb-4 is-flex is-justify-content-center'>
      <p className={'content text-align-center'}>{message}</p>
    </div>
  )
}

interface DueDateMessageProps {
  attempt?: number
  remainingWaitingTime: GetRemainingWaitingTimeValue
}

const DueDateMessage: React.FC<DueDateMessageProps> = ({ attempt, remainingWaitingTime }) => {
  const { t } = useTranslation()

  const { days, hours, minutes, seconds } = remainingWaitingTime

  const isTimeLeft = !!(days + hours + minutes + seconds)

  if (isTimeLeft) {
    return (
      <TextStrong isParagraph className={styles.infoStrong}>
        {t(
          'Profile.Your next appropriateness test (attempts {{attempt}} of 3) is due in {{days}} days {{hours}} hours {{minutes}} minutes {{seconds}} seconds.',
          {
            attempt,
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds,
          }
        )}
      </TextStrong>
    )
  }

  if (isOne(attempt)) {
    return (
      <TextStrong isParagraph className={styles.infoStrong}>
        {t('Profile.You can start your appropriateness test (attempt {{attempt}} of 3).', {
          attempt,
        })}
      </TextStrong>
    )
  }

  return (
    <TextStrong isParagraph className={styles.infoStrong}>
      {t('Profile.Your next appropriateness test (attempts {{attempt}} of 3)', {
        attempt,
      })}
    </TextStrong>
  )
}

interface DemoAccountLinkProps {
  redirectLink: string
  label: string
}

const DemoAccountLink: React.FC<DemoAccountLinkProps> = ({ redirectLink, label }) => {
  return (
    <div className='pb-4 mt-4 text-align-center'>
      <ExternalLink url={redirectLink} className='is-link'>
        {label}
      </ExternalLink>
    </div>
  )
}

const calculateRemainingWaitingTime = (
  account: AccountDetailedDto | undefined,
  data: TestAnswersDto | undefined
) => {
  if (!account || !data) return 0

  const coolDownPeriod = account?.companyConfiguration?.appropriatenessTestCooldownPeriod
  const coolDownPeriodMs = secondsToMilliseconds(coolDownPeriod)

  const dateTimeNow = new Date().getTime()
  const dateTaken = new Date(data?.dateTaken).getTime()

  const waitingTimeMs = coolDownPeriodMs - differenceInMilliseconds(dateTimeNow, dateTaken)

  return waitingTimeMs
}

interface GetRemainingWaitingTimeValue {
  days: number
  hours: number
  minutes: number
  seconds: number
}

export const getRemainingWaitingTime = (
  account: AccountDetailedDto | undefined,
  data: TestAnswersDto | undefined
): GetRemainingWaitingTimeValue => {
  const remainingWaitingTime = calculateRemainingWaitingTime(account, data)
  const time = remainingWaitingTime > 0 ? remainingWaitingTime : 0

  const days = Math.floor(time / (24 * 60 * 60 * 1000))
  const daysMs = time % (24 * 60 * 60 * 1000)
  const hours = Math.floor(daysMs / (60 * 60 * 1000))
  const hoursMs = time % (60 * 60 * 1000)
  const minutes = Math.floor(hoursMs / (60 * 1000))
  const minutesMs = time % (60 * 1000)
  const seconds = Math.floor(minutesMs / 1000)

  return {
    days: days || 0,
    hours: hours || 0,
    minutes: minutes || 0,
    seconds: seconds || 0,
  }
}

export const canTakeTest = (
  accountDetailed: AccountDetailedDto | undefined,
  data: TestAnswersDto | undefined,
  product: TickmillProductType
): boolean => {
  if (!accountDetailed) return false

  if (!data) return true

  if (hasProductDormantStatus(accountDetailed, product)) {
    return true
  }

  if (hasProductClosedStatus(accountDetailed, product)) {
    return true
  }

  return calculateRemainingWaitingTime(accountDetailed, data) <= 0
}
