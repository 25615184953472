import React, { SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { Form, FormikErrors, FormikProps, withFormik } from 'formik'
import { t } from 'i18next'

import { Badge } from '../../global/badge/Badge'
import { CheckBox } from '../../global/checkBox/CheckBox'
import { useSessionEntity } from '../../global/context/EntityContext'
import { createFormField } from '../../global/formField/FormField'
import { createFormSelectField } from '../../global/formField/FormSelectField'
import { AccountDetailedDto } from '../../model/AccountDetailedDto'
import { TestAnswersDto } from '../../model/ClientTestAnswerDto'
import { isTickmillSCType } from '../../utils/companyName.utils'
import { formatDate } from '../../utils/date.utils'
import { FormSubmitValues } from '../../utils/formValidation'

import styles from './PersonalInfo.module.scss'

export interface PersonalInfoFormValues {
  firstName: string
  middleName: string
  lastName: string
  nativeName?: string
  countryCode: string
  mobileNumber: string
  country: string
  address: string
  city: string
  state: string
  postalCode: string
  dateOfBirth: string
  taxIdentificationNumber: string
  title: number
  email: string
  nationality: string
  isUsCitizen: boolean
}

const FormField = createFormField<PersonalInfoFormValues>()
const NoTypeFormField = createFormField()
const FormSelectField = createFormSelectField<PersonalInfoFormValues>()

const PersonalInfoFormPageUI: React.FC<FormikProps<PersonalInfoFormValues> & OuterProps> = (
  props
) => {
  const { handleSubmit, setSubmitting, values, supportPhone, supportEmail } = props

  const handleSubmitForm = (event: SyntheticEvent) => {
    event.preventDefault()
    setSubmitting(true)
    handleSubmit()
  }

  const entity = useSessionEntity()

  const { t } = useTranslation()

  return (
    <>
      <div className={styles.topInfo}>
        <p>
          {t('Profile.For assistance or queries, please contact')}
          <Link to='/contact-us' className='is-link'>
            {t('Profile.Client Support')}
          </Link>
        </p>
        <p>
          {t(
            'Profile.Subject to our policy and to safeguard your account, please inform Tickmill in case of any changes related to your personal details'
          )}
        </p>
        <p>
          {t('Profile.Telephone')}: {supportPhone} | {t('Email')}: {supportEmail}
        </p>
      </div>
      <div>
        <Form autoComplete='off' className={styles.form} onSubmit={handleSubmitForm}>
          <div className='columns is-gapless'>
            <div className='column'>
              <section className={classNames(styles.section)}>
                <h3>{t('Personal Details')}</h3>
                <div className='column is-3 is-half-mobile'>
                  <FormSelectField
                    name={'title'}
                    label={t('Profile.Title')}
                    placeholder={t('Profile.Title')}
                    type='select'
                    required
                    disabled
                  >
                    <option className='is-hidden' />
                    <option value='1'>{t('Profile.Mr.')}</option>
                    <option value='2'>{t('Profile.Ms.')}</option>
                  </FormSelectField>
                </div>

                <div className='is-flex-desktop'>
                  <div className='column is-half-desktop'>
                    <FormField
                      name='firstName'
                      label={t('Profile.First Name')}
                      placeholder={t('Profile.First Name')}
                      type='text'
                      required
                      disabled
                    />
                  </div>
                  <div className={classNames('column is-half-desktop', styles.reset)}>
                    <FormField
                      name='middleName'
                      type='text'
                      label={t('Profile.Middle Name')}
                      required
                      placeholder={t('Profile.Middle Name')}
                      disabled
                    />
                  </div>
                </div>
                <FormField
                  name='lastName'
                  label={t('Profile.Last Name')}
                  type='text'
                  placeholder={t('Profile.Last Name')}
                  required
                  disabled
                />
                {values.nativeName && (
                  <FormField
                    name='nativeName'
                    label={t('Profile.Native Name')}
                    type='text'
                    placeholder={t('Profile.Native Name')}
                    required
                    disabled
                  />
                )}
                <FormField
                  name={'dateOfBirth'}
                  label={t('Profile.Date Of Birth')}
                  type='text'
                  placeholder={t('Profile.Date Of Birth')}
                  required
                  disabled
                />
                {!isTickmillSCType(entity) && (
                  <FormField
                    name={'taxIdentificationNumber'}
                    label={t('Profile.Tax Identification Number / Goods & Services Tax Number')}
                    type='text'
                    placeholder={t(
                      'Profile.Tax Identification Number / Goods & Services Tax Number'
                    )}
                    required
                    disabled
                  />
                )}
                <FormField
                  name={'email'}
                  label={t('Profile.Email Address')}
                  type='text'
                  placeholder={t('Profile.Email Address')}
                  required
                  disabled
                />
                <div className='is-flex'>
                  <div className='column is-3'>
                    <FormField
                      name={'countryCode'}
                      label={t('Profile.Country Code')}
                      type='text'
                      placeholder={t('Profile.Country Code')}
                      required
                      disabled
                    />
                  </div>
                  <div className='column'>
                    <FormField
                      name={'mobileNumber'}
                      label={t('Profile.Mobile Number')}
                      type='number'
                      min='0'
                      autoComplete='false'
                      onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                      placeholder={t('Profile.Mobile Number')}
                      required
                      disabled
                    />
                  </div>
                </div>
              </section>
              <section className={classNames(styles.section)}>
                <h3>{t('Profile.Nationality & Address')}</h3>
                <FormField
                  name={'country'}
                  disabled
                  label={t('Profile.Country of Residence')}
                  type='text'
                  placeholder={t('Profile.Country of Residence')}
                  required
                />
                <FormField
                  name='nationality'
                  label={t('Profile.Nationality')}
                  disabled
                  type='select'
                  placeholder={t('Profile.Nationality')}
                  required
                />
                <FormField
                  name={'address'}
                  label={t('Profile.Address Line')}
                  type='text'
                  placeholder={t('Profile.Address Line')}
                  required
                  disabled
                />
                <FormField
                  name={'city'}
                  label={t('Profile.City')}
                  type='text'
                  placeholder={t('Profile.City')}
                  required
                  disabled
                />
                <div className='is-flex'>
                  <div className='column'>
                    <FormField
                      name={'state'}
                      label={t('Profile.State/Region')}
                      type='text'
                      placeholder={t('Profile.State/Region')}
                      required
                      disabled
                    />
                  </div>
                  <div className='column'>
                    <FormField
                      name={'postalCode'}
                      label={t('Profile.Postal Code')}
                      type='text'
                      placeholder={t('Profile.Postal Code')}
                      required
                      disabled
                    />
                  </div>
                </div>
                <h3 className='pt-4'>{t('Profile.US Citizen')}</h3>
                <CheckBox value={props.values.isUsCitizen} disabled>
                  {t('I am a US Citizen for tax purposes')}
                </CheckBox>
              </section>
            </div>
            <div className='column'>
              {props?.appTestAnswers?.sections?.map((section) => {
                if (section.code !== 'trading_experience') {
                  return (
                    <section className={styles.section}>
                      <h3>{section.name}</h3>
                      {section.answers.map((answer) => (
                        <NoTypeFormField
                          name={answer.answer.name}
                          dynamicHeight
                          as={'textarea'}
                          label={answer.question.name || ''}
                          type='text'
                          value={answer.answer.name || 'N/A'}
                          disabled
                        />
                      ))}
                    </section>
                  )
                }

                return (
                  <section className={styles.section}>
                    <h3>{section.name}</h3>
                    {section.answers.map((answer) => (
                      <div className='pt-4 is-flex is-align-items-flex-start is-justify-content-space-between'>
                        <span className={classNames(styles.text, 'mr-4')}>
                          {answer.question.name}
                        </span>
                        <Badge
                          text={answer.answer.name}
                          appearance={answer.answer.key === 'no' ? 'declined' : 'active'}
                        />
                      </div>
                    ))}
                  </section>
                )
              })}
            </div>
          </div>
        </Form>
      </div>
    </>
  )
}

interface OuterProps {
  appTestAnswers?: TestAnswersDto
  account?: AccountDetailedDto

  onSubmit(values: FormSubmitValues<PersonalInfoFormValues>): Promise<void>

  supportPhone: string
  supportEmail: string
}

export const PersonalInfoFormPage = withFormik<OuterProps, PersonalInfoFormValues>({
  mapPropsToValues: ({ account }) => {
    return {
      firstName: account?.firstname || '',
      countryCode:
        account?.phoneNumbers.find((x) => x.isPrimary)?.countryCode?.replace('+', '') || '',
      lastName: account?.lastname || '',
      middleName: account?.middlename || '',
      nativeName: account?.nativeName || '',
      mobileNumber: account?.phoneNumbers.find((x) => x.isPrimary)?.number || '',
      address: account?.addresses.find((x) => x.isPrimary)?.street || '',
      city: account?.addresses.find((x) => x.isPrimary)?.city || '',
      state: account?.addresses.find((x) => x.isPrimary)?.state || '',
      postalCode: account?.addresses.find((x) => x.isPrimary)?.postalCode || '',
      country: account?.addresses.find((x) => x.isPrimary)?.country.name || '',
      dateOfBirth: account?.birthday
        ? formatDate(account.birthday, account.dateFormatType?.name || 'dd.MM.yyyy')
        : '',
      taxIdentificationNumber: account?.taxId || '',
      title: account?.gender.id || 1,
      email: account?.emails.find((x) => x.isPrimary)?.address || '',
      nationality: account?.nationalities.map((n) => n.name).toString() || '',
      isUsCitizen: account?.isUsCitizen || false,
    }
  },
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit(values)
    } finally {
      setSubmitting(false)
    }
  },
  validate: (values) => {
    const errors: FormikErrors<PersonalInfoFormValues> = {}
    if (!values.title) {
      errors.title = t('Validation.Required')
    }
    if (!values.firstName) {
      errors.firstName = t('Validation.Required')
    }
    if (!values.lastName) {
      errors.lastName = t('Validation.Required')
    }
    if (!values.countryCode) {
      errors.countryCode = t('Validation.Required')
    }
    if (!values.mobileNumber) {
      errors.mobileNumber = t('Validation.Required')
    }
    if (!values.city) {
      errors.city = t('Validation.Required')
    }
    if (!values.mobileNumber) {
      errors.mobileNumber = t('Validation.Required')
    }
    if (!values.dateOfBirth) {
      errors.dateOfBirth = t('Validation.Required')
    }
    if (!values.taxIdentificationNumber) {
      errors.taxIdentificationNumber = t('Validation.Required')
    }
    if (!values.email) {
      errors.email = t('Validation.Required')
    }
    if (!values.address) {
      errors.address = t('Validation.Required')
    }
    if (!values.nationality) {
      errors.nationality = t('Validation.Required')
    }
    if (!values.state) {
      errors.state = t('Validation.Required')
    }
    if (!values.postalCode) {
      errors.postalCode = t('Validation.Required')
    }

    return errors
  },
  enableReinitialize: true,
})(PersonalInfoFormPageUI)
