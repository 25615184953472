import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Button } from '../../../global/button/Button'
import { SmartphoneIcon } from '../../../icons/SmartphoneIcon'
import { TrashIcon } from '../../../icons/TrashIcon'
import { ClientPhoneNumberDto } from '../../../model/AccountDetailedDto'
import { Text, TextLargeStrong, TextStrong } from '../../../ui/Typography/Typography'

import styles from './PhoneNumberEditModal.module.scss'

interface PhoneNumberEditModalProps {
  phoneNumber: ClientPhoneNumberDto
  onCancel: () => void
  deleteNumber: (id: string) => void
  makeNumberPrimary: (id: string) => void
}

export const PhoneNumberEditModal: React.FC<PhoneNumberEditModalProps> = ({
  onCancel,
  phoneNumber,
  deleteNumber,
  makeNumberPrimary,
}) => {
  const { t } = useTranslation()
  if (!phoneNumber) return null
  return (
    <>
      <header className='modal-card-head'>
        <TextLargeStrong className='modal-card-title'>
          {phoneNumber.countryCode + phoneNumber.number}
        </TextLargeStrong>
      </header>
      <div className={styles.body}>
        <div onClick={() => makeNumberPrimary(phoneNumber.id)} className={styles.primary}>
          <SmartphoneIcon color='text' />
          <TextStrong className={styles.label}>{t('PhoneNumbers.Set as primary')}</TextStrong>
        </div>
        {!phoneNumber.isUsedIn2FA && (
          <>
            <hr className={styles.divider} />
            <div onClick={() => deleteNumber(phoneNumber.id)} className={styles.delete}>
              <TrashIcon color='error' />
              <Text className={styles.label}>{t('PhoneNumbers.Delete')}</Text>
            </div>
          </>
        )}
      </div>
      <footer className={classNames('modal-card-foot', styles.footer)}>
        <Button className={styles.button} appearance='plain' onClick={onCancel}>
          {t('Cancel')}
        </Button>
      </footer>
    </>
  )
}
