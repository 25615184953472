import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'

import { Tabs } from '../global/tabs/Tabs'
import { isNoTabsPath } from '../utils/path'
import { AutochartistPage } from './Autochartist/AutochartistPage'
import { PlatformsPage } from './Platforms/PlatformsPage'

import styles from './Tools.module.scss'

export const Tools: React.FC = () => {
  const { t } = useTranslation()
  const { tab } = useParams<{ tab?: string }>()
  const navigate = useNavigate()
  const location = useLocation()
  const TabsDefinition = [
    { name: t('Platforms'), id: 'platforms', disabled: false },
    // {
    //   name: t('Autochartist.Autochartist'),
    //   id: 'autochartist',
    //   disabled: false,
    // },
  ]

  return (
    <div>
      {!isNoTabsPath(location.pathname) && (
        <div className={classNames('section', styles.adminRoutes)}>
          <Tabs
            tabs={TabsDefinition}
            currentTab={tab || ''}
            appearance='light'
            onTabChanged={(tab) => navigate(`/dashboard/tools/${tab}`)}
          />
        </div>
      )}
      {(tab === 'platforms' || tab === 'metatrader') && <PlatformsPage />}
      {/* {tab === 'autochartist' && <AutochartistPage />} */}
    </div>
  )
}
