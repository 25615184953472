import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button } from '../../../global/button/Button'
import { WarningIcon } from '../../../icons/WarningIcon'
import { TextStrong } from '../../../ui/Typography/Typography'
import { PersonalDetailsTemplate } from '../PersonalInfoPage/PersonalDetailsTemplate'

import styles from '../PersonalInfoPage.module.scss'

interface OuterProps {
  setStep(n: number): void
}

export const PersonalInfoStepUSPage: React.FC<OuterProps> = (props) => {
  const { setStep } = props

  const goBack = () => {
    setStep(1)
  }

  return (
    <PersonalDetailsTemplate step={1} hasQRCodeSection goBack={goBack} hideTitle>
      <USCitizenWarning onGoBack={() => setStep(1)} />
    </PersonalDetailsTemplate>
  )
}

const USCitizenWarning = ({ onGoBack }: { onGoBack: () => void }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className={styles.usWarning}>
      <div className={styles.usWarningHeader}>
        <WarningIcon color={'error'} size={18} />
        <h3 className='pl-3'>{t('Sign up.US Citizen')}</h3>
      </div>
      <p className={styles.subtitle}>
        {t("Sign up.Unfortunately, we can't offer our services to US citizens")}
      </p>
      <p className={styles.text}>{t('Sign up.A US Citizen has been defined by the IRS as')}</p>
      <ul className={styles.list}>
        <li className={styles.text}>
          {t('Sign up.USCitizenConditions.An individual born in the United States')}
        </li>
        <li className={styles.text}>
          {t('Sign up.USCitizenConditions.An individual whose parent is a US citizen')}
        </li>
        <li className={styles.text}>
          {t(
            'Sign up.USCitizenConditions.An individual born in Puerto Rico, Guam or the US Virgin Islands'
          )}
        </li>
        <li className={styles.text}>
          {t('Sign up.USCitizenConditions.A former alien who has been naturalized as a US citizen')}
        </li>
      </ul>
      <div className={styles.buttonWrapper}>
        <Button type='button' size='L' appearance='secondary' onClick={() => onGoBack()}>
          <TextStrong>{t("Sign up.I'm Not a US Citizen")}</TextStrong>
        </Button>
        <Button type='button' appearance='primary' size='L' onClick={() => navigate('/login')}>
          <TextStrong>{t("Sign up.I'm a US Citizen")}</TextStrong>
        </Button>
      </div>
    </div>
  )
}
