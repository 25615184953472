import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'

import { PageNotFound } from '../global/notFound/PageNotFound'
import { Tabs } from '../global/tabs/Tabs'
import { isNoTabsPath } from '../utils/path'
import { PaymentAgentRoutes } from './PaymentAgentRoutes'

import styles from './PaymentAgent.module.scss'

export const PaymentAgent = () => {
  const { tab } = useParams<{ tab?: string }>()
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const TabsDefinition = [
    {
      name: t('Tabs.Wallets'),
      id: 'wallets',
      disabled: false,
    },
    {
      name: t('Tabs.Transaction History'),
      id: 'transaction-history',
      disabled: false,
    },
    { name: t('Tabs.Profile Info'), id: 'profile-info', disabled: false },
  ]

  if (tab === undefined) {
    return <PageNotFound />
  }

  return (
    <React.Fragment>
      {!isNoTabsPath(location.pathname) && (
        <div className={classNames('section', styles.adminRoutes)}>
          <Tabs
            tabs={TabsDefinition}
            currentTab={tab}
            appearance='light'
            onTabChanged={(tab) => navigate(`/dashboard/payment-agent/${tab}`)}
          />
        </div>
      )}
      <PaymentAgentRoutes tab={tab} />
    </React.Fragment>
  )
}
