import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'

import { Tabs, defineTab } from '../global/tabs/Tabs'
import { isNoTabsPath } from '../utils/path'
import { MarketDataVendors } from './MarketDataVendors/MarketDataVendors'
import { PlatformVendorsPage } from './PlatformVendors/PlatformVendorsPage'

import styles from './Tools.module.scss'

export const ToolsETD: React.FC = () => {
  const { t } = useTranslation()
  const { tab } = useParams<{ tab?: string }>()
  const navigate = useNavigate()
  const location = useLocation()

  function* getTabs() {
    yield {
      ...defineTab('platform-vendors', t('Platform Vendors.title')),
    }
    yield {
      ...defineTab('market-data-vendors', t('Market Data.Market Data Vendors')),
    }
  }

  const TabsDefinition = [...getTabs()]

  return (
    <div>
      {!isNoTabsPath(location.pathname) && (
        <div className={classNames('section', styles.adminRoutes)}>
          <Tabs
            tabs={TabsDefinition}
            currentTab={tab || ''}
            appearance='light'
            onTabChanged={(tab) => navigate(`/dashboard/tools/${tab}`)}
          />
        </div>
      )}
      {tab === 'platform-vendors' && <PlatformVendorsPage />}
      {tab === 'market-data-vendors' && <MarketDataVendors />}
    </div>
  )
}
