import React, { FormEventHandler, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { subYears } from 'date-fns'
import isAfter from 'date-fns/isAfter'
import { Field, Form, FormikErrors, FormikProps, withFormik } from 'formik'
import { t } from 'i18next'

import { Button } from '../../../global/button/Button'
import { CheckBox } from '../../../global/checkBox/CheckBox'
import {
  useArabicSessionLanguage,
  useSessionLanguage,
} from '../../../global/context/SessionSettingsContext'
import { createFormField } from '../../../global/formField/FormField'
import { createFormSelectField } from '../../../global/formField/FormSelectField'
import { InfoModal } from '../../../global/modal/InfoModal'
import { Modal } from '../../../global/modal/Modal'
import { BackIcon } from '../../../icons/BackIcon'
import { ForwardIcon } from '../../../icons/ForwardIcon'
import { InfoIcon } from '../../../icons/InfoIcon'
import { LeadMembersEnum } from '../../../model/CreateLead'
import { Text, TextTiny } from '../../../ui/Typography/Typography'
import { getMonthOptions } from '../../../utils/date.utils'
import { FullNameLettersPattern } from '../../../utils/formValidation'
import { preventPaste } from '../../../utils/input.utils'
import { FormValuesStep0 } from '../PersonalInfoPage/PersonalInfoStepAccountTypePage'

import styles from '../PersonalInfoPage.module.scss'
import 'react-datepicker/dist/react-datepicker.css'
import { isChinese, isZero } from '../../../utils/validations'

export interface FormValuesStep1 {
  genderId: string
  firstName: string
  lastName: string
  middleName: string
  nativeName?: string
  monthOfBirth: string
  dayOfBirth: string
  yearOfBirth: string
  isUsCitizen?: boolean
}

export type FormValues = FormValuesStep0 & FormValuesStep1
const FormField = createFormField<FormValuesStep1>()
const FormSelectField = createFormSelectField<FormValuesStep1>()

const PersonalDetailsStep1FormUI: React.FC<FormikProps<FormValuesStep1> & OuterProps> = (props) => {
  const { values, handleSubmit, setFieldValue } = props

  const { t } = useTranslation()

  const genderRef = useRef<HTMLInputElement>(null)
  const ref = useRef<HTMLFormElement>(null)

  const [showUSCitizenModal, setShowUSCitizenModal] = useState(false)
  const [isUsCitizen, setIsUsCitizen] = useState(false)

  const locale = useSessionLanguage()
  const isArabic = useArabicSessionLanguage()

  useEffect(() => {
    props.validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const onSubmit: FormEventHandler = (e) => {
    e.preventDefault()
    e.stopPropagation()
    props.validateForm()
    if (!genderRef?.current?.value) {
      ref?.current?.scrollIntoView({ behavior: 'smooth' })
    }
    if (genderRef?.current?.checkValidity()) {
      ref?.current?.scrollIntoView({ behavior: 'smooth' })
      genderRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }
    if (isUsCitizen) setFieldValue('isUsCitizen', true)

    handleSubmit()
  }

  document.addEventListener('wheel', (event) => {
    if ((document?.activeElement as any)?.type === 'number') {
      ;(document?.activeElement as any)?.blur()
    }
  })

  const onKeyDown = (e: { key: string; preventDefault: () => any }) =>
    ['e', 'E', '+', '-', '.', ','].includes(e.key) && e.preventDefault()

  return (
    <React.Fragment>
      {showUSCitizenModal && (
        <Modal
          cardClassName={styles.modal}
          closeModal={() => setShowUSCitizenModal(false)}
          render={({ closeModal }) => (
            <InfoModal
              onCancel={closeModal}
              title={t('Sign up.US Citizen in tax purposes')}
              renderBody={() => (
                <section className='modal-card-body'>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "Sign up.Unfortunately, we can't offer our services to US citizens"
                      ),
                    }}
                  />
                </section>
              )}
              renderFooter={() => (
                <React.Fragment>
                  <button
                    className='button'
                    onClick={() => {
                      setShowUSCitizenModal(false)
                    }}
                    type='button'
                  >
                    <b>{t('Got It')}</b>
                  </button>
                </React.Fragment>
              )}
              onConfirm={() => setShowUSCitizenModal(false)}
            />
          )}
        />
      )}
      <Form className={styles.form} onSubmit={onSubmit} ref={ref} autoComplete='off'>
        <h3>{t('Sign up.Title and Name')}</h3>
        <div
          className={classNames(styles.genderForm, {
            [styles.radioError]: !!props.errors.genderId && props.touched.genderId,
          })}
        >
          {[
            { id: '1', name: t('Mr') },
            { id: '2', name: t('Mrs/Ms') },
          ].map((g) => (
            <div key={g.id} className={'field'}>
              <div className={classNames('control')}>
                <Field
                  className={classNames('radio')}
                  type='radio'
                  required
                  innerRef={genderRef}
                  name='genderId'
                  checked={values.genderId === g.id}
                  onChange={() => setFieldValue('genderId', g.id)}
                />
                <label>{g.name}</label>
              </div>
            </div>
          ))}
        </div>
        {!!props.errors.genderId && props.touched.genderId && (
          <TextTiny isParagraph className={styles.error}>
            {t('Validation.Required')}
          </TextTiny>
        )}
        <FormField
          type='text'
          showLabel
          required
          name={'firstName'}
          label={t('Sign up.First name')}
        />
        <FormField type='text' name={'middleName'} label={t('Sign up.Middle name')} showLabel />
        <FormField
          type='text'
          required
          name={'lastName'}
          label={t('Sign up.Last name')}
          showLabel
        />
        {locale === 'zh' && (
          <div className={styles.nativeNameContainer}>
            <Text>{t('Sign up.Please fill in your native name with Chinese characters')}</Text>
            <FormField type='text' name={'nativeName'} label={t('Sign up.Native Name')} showLabel />
          </div>
        )}
        <h3>{t('Sign up.Date of Birth')}</h3>
        <div className={styles.dateContainer}>
          <div>
            <FormField
              type='number'
              autoComplete='false'
              min='1'
              max='31'
              name={'dayOfBirth'}
              placeholder={t('Day')}
              label={t('Day')}
              className={styles.dateForm}
              value={values.dayOfBirth.toString().replace(/^0+/, '')}
              required
              onKeyDown={onKeyDown}
              onPaste={preventPaste}
            />
          </div>
          <div className={classNames(styles.dateForm, styles.monthField)}>
            <FormSelectField name={'monthOfBirth'} label={t('Month')} showLabel required>
              {getMonthOptions(t).map((option) => (
                <option value={option.id} key={option.id}>
                  {option.label}
                </option>
              ))}
            </FormSelectField>
          </div>

          <div>
            <FormField
              type='number'
              autoComplete='false'
              min='0'
              name={'yearOfBirth'}
              placeholder={t('Year')}
              label={t('Year')}
              value={values.yearOfBirth.toString().replace(/^0+/, '')}
              className={styles.dateForm}
              required
              onKeyDown={onKeyDown}
              onPaste={preventPaste}
            />
          </div>
        </div>
        <span className='is-flex is-align-items-baseline pb-4'>
          <h3>{t('Sign up.US Citizen')}</h3>
          <button
            className={styles.plainButton}
            type='button'
            onClick={() => setShowUSCitizenModal(true)}
          >
            <InfoIcon />
          </button>
        </span>
        <CheckBox
          value={isUsCitizen}
          error={''}
          onChange={(e) => {
            setIsUsCitizen((prevState) => !prevState)
          }}
        >
          {t('Sign up.I am a US Citizen for tax purposes')}
        </CheckBox>
        <Button
          className={styles.button}
          type='button'
          appearance='primary'
          size='L'
          onClick={onSubmit}
          renderRightIcon={() => (
            <span className='is-flex is-align-items-center'>
              {isArabic ? <BackIcon inverse /> : <ForwardIcon inverse />}
            </span>
          )}
          disabled={props.isSubmitting}
        >
          {t('Next')}
        </Button>
      </Form>
    </React.Fragment>
  )
}

interface OuterProps {
  values: Partial<FormValuesStep1>

  onSubmit(values: FormValuesStep1): void
}

export const PersonalDetailsStep1Form = withFormik<OuterProps, FormValuesStep1>({
  mapPropsToValues: ({ values }) => {
    const searchParams = new URLSearchParams(window.location.search)
    const birthday = new Date(searchParams.get(LeadMembersEnum.BIRTHDAY) || '')
    const monthOfBirth = birthday.getMonth() + 1
    const monthOfBirthValue = monthOfBirth ? monthOfBirth.toString() : ''
    return {
      firstName: values.firstName || searchParams.get(LeadMembersEnum.FIRST_NAME) || '',
      genderId: values.genderId || searchParams.get(LeadMembersEnum.GENDER_ID) || '',
      middleName: values.middleName || searchParams.get(LeadMembersEnum.MIDDLE_NAME) || '',
      monthOfBirth: values.monthOfBirth || monthOfBirthValue,
      dayOfBirth: values.dayOfBirth || birthday.getDate().toString() || '',
      yearOfBirth: values.yearOfBirth || birthday.getFullYear().toString() || '',
      lastName: values.lastName || searchParams.get(LeadMembersEnum.LAST_NAME) || '',
      nativeName: values.nativeName || searchParams.get(LeadMembersEnum.NATIVE_NAME) || '',
    }
  },
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      setSubmitting(true)
      await props.onSubmit(values)
    } finally {
      setSubmitting(false)
    }
  },
  validate: (values) => {
    const errors: FormikErrors<FormValuesStep1> = {}
    function isLeapYear(year: number) {
      return (isZero(year % 4) && year % 100 !== 0) || isZero(year % 400)
    }
    const year = new Date().getFullYear()
    const month = new Date().getMonth() + 1
    const day = new Date().getDate()

    if (!FullNameLettersPattern.test(values.firstName)) {
      errors.firstName = t('Validation.First name contains unsupported characters')
    }

    if (!values.firstName || values.firstName.length < 2) {
      errors.firstName = t('Validation.At least characters', {
        length: 2,
      })
    }

    if (values.nativeName && !isChinese(values.nativeName)) {
      errors.nativeName = t('Validation.You can only input your native name in Chinese')
    }

    if (!values.firstName.trim()) {
      errors.firstName = t('Validation.Required')
    }

    if (!FullNameLettersPattern.test(values.lastName)) {
      errors.lastName = t('Validation.Last name contains unsupported characters')
    }
    if (values.middleName && !FullNameLettersPattern.test(values.middleName)) {
      errors.middleName = t('Validation.Middle name contains unsupported characters')
    }

    if (!values.lastName || values.lastName.length < 2) {
      errors.lastName = t('Validation.At least characters', {
        length: 2,
      })
    }
    if (!values.lastName.trim()) {
      errors.lastName = t('Validation.Required')
    }

    if (!values.genderId) {
      errors.genderId = t('Validation.Required')
    }
    if (values.dayOfBirth && values.yearOfBirth && values.monthOfBirth) {
      if (
        isAfter(
          new Date(
            Number(values.yearOfBirth),
            Number(values.monthOfBirth),
            Number(values.dayOfBirth)
          ),
          subYears(new Date(year, month, day), 18)
        )
      )
        errors.yearOfBirth = t('Validation.You must be 18 years of age or older')
    }
    if (Number(values.monthOfBirth) > 12 || Number(values.monthOfBirth) < 1) {
      errors.monthOfBirth = t('Validation.Enter a valid month')
    }

    if (
      ([2].includes(Number(values.monthOfBirth)) &&
        !isLeapYear(Number(values.yearOfBirth)) &&
        Number(values.dayOfBirth) > 28) ||
      Number(values.dayOfBirth) < 1
    ) {
      errors.dayOfBirth = t('Validation.Enter a valid date')
    }
    if (
      ([2].includes(Number(values.monthOfBirth)) &&
        isLeapYear(Number(values.yearOfBirth)) &&
        Number(values.dayOfBirth) > 29) ||
      Number(values.dayOfBirth) < 1
    ) {
      errors.dayOfBirth = t('Validation.Enter a valid date')
    }
    if (
      ([4, 6, 9, 11].includes(Number(values.monthOfBirth)) && Number(values.dayOfBirth) > 30) ||
      Number(values.dayOfBirth) < 1
    ) {
      errors.dayOfBirth = t('Validation.Enter a valid date')
    }
    if (
      ([1, 3, 5, 7, 8, 10, 12].includes(Number(values.monthOfBirth)) &&
        Number(values.dayOfBirth) > 31) ||
      Number(values.dayOfBirth) < 1
    ) {
      errors.dayOfBirth = t('Validation.Enter a valid date')
    }

    if (Number(values.dayOfBirth) > 31) {
      errors.dayOfBirth = t('Validation.Enter a valid date')
    }
    if (values.dayOfBirth.toString().length !== 1 && values.dayOfBirth.toString().length !== 2) {
      errors.dayOfBirth = t('Validation.Enter a valid date')
    }

    if (!values.dayOfBirth) {
      errors.dayOfBirth = t('Validation.Enter a valid date')
    }

    if (isNaN(Number(values.yearOfBirth)) || Number(values.yearOfBirth) <= 1900) {
      errors.yearOfBirth = t('Validation.Enter a valid year')
    }
    if (!values.monthOfBirth) {
      errors.monthOfBirth = t('Validation.Enter a valid month')
    }
    if (!values.yearOfBirth) {
      errors.yearOfBirth = t('Validation.Enter a valid year')
    }
    return errors
  },
  enableReinitialize: true,
})(PersonalDetailsStep1FormUI)
