import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { MarketDataClassificationStatus } from '../../model/AccountDetailedDto'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { useRedirectToSupport } from '../../utils/useRedirectToSupport'

import styles from './MarketDataClassification.module.scss'

export const MarketDataClassificationPage = () => {
  const { t } = useTranslation()
  const { account } = useAccountReadContext()
  const { supportUrl } = useRedirectToSupport()
  const marketDataClassification =
    account?.marketDataClassification?.id === MarketDataClassificationStatus.Professional
      ? t('Profile.Market-Data Professional')
      : t('Profile.Market-Data Private')

  return (
    <Fragment>
      <PageHeader title={t('Profile.Market Data Classification')} />
      <div className={styles.marketDataClassificattion}>
        <h3>{t('Account Type')}</h3>
        <span className={styles.status}>
          {t('Profile.Current status')}{' '}
          <span className={styles.statusValue}>{marketDataClassification}</span>
        </span>
        <span className={styles.note}>
          {t('Profile.If you have any questions regarding your Market Data Classification')}{' '}
          <Link to={supportUrl}>{t('Contact Support')}</Link>.
        </span>
      </div>
    </Fragment>
  )
}
