import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { AppMarketLink } from '../../../global/appMarketLink/mobile/AppMarketLink'
import { LeadMembersEnum } from '../../../model/CreateLead'
import { useWindowResize } from '../../../utils/domUtils'
import {
  AccountTypeStep,
  FewStepLeftSection,
  FewStepSection,
  SignupFormWrapper,
  SignupStep,
  SignupWrapper,
} from '../../SignupPage'

import 'react-datepicker/dist/react-datepicker.css'

export interface FormValuesStep0 {
  typeId: number
}

interface PersonalInfoAccountTypeProps {
  values: Partial<FormValuesStep0>
  onSubmit(values: FormValuesStep0): void
}

export const PersonalInfoStepAccountTypePage: React.FC<PersonalInfoAccountTypeProps> = (props) => {
  const { values: formValues, onSubmit } = props

  const { t } = useTranslation()
  const isMobile = useWindowResize()
  const [searchParams] = useSearchParams()

  const [accountType, setAccountType] = useState(
    formValues.typeId || Number(searchParams.get(LeadMembersEnum.TYPE_ID)) || 1
  )

  const handleAccountChange = (value: number) => {
    setAccountType(value)
  }

  const handleSubmitForm = (v: number) => {
    onSubmit({ typeId: v })
  }

  return (
    <SignupFormWrapper label={t('Sign up.Sign up')}>
      <SignupStep section={1} accountTypeId={formValues?.typeId || 1} />
      {isMobile && (
        <SignupWrapper>
          <FewStepSection accountType={accountType} />
        </SignupWrapper>
      )}
      <SignupWrapper>
        {!isMobile && <FewStepLeftSection accountType={accountType} />}
        <AccountTypeStep
          values={{ typeId: formValues?.typeId }}
          accountType={accountType}
          onAccountChange={handleAccountChange}
          onSubmit={handleSubmitForm}
        />
      </SignupWrapper>
      <div className='mt-4 is-hidden-desktop'>
        <AppMarketLink />
      </div>
    </SignupFormWrapper>
  )
}
