import { AccountDetailedDto } from '../model/AccountDetailedDto'
import { isTickmillPartner } from './companyName.utils'

export const isLearningRouteAllowed = (account: AccountDetailedDto | undefined) =>
  account?.companyConfiguration.hasForexTools

export const isTradersRoomRouteAllowed = (account: AccountDetailedDto | undefined) =>
  !isTickmillPartner(account)

export const isIBRouteAllowed = (account?: AccountDetailedDto) =>
  account?.companyConfiguration.hasIBRoom
