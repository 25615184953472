import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import {
  useCategorySizesReadContext,
  useCategorySizesWriteContext,
} from '../../utils/CategorySizesContext'
import { isZero } from '../../utils/validations'
import { Loading } from '../Loading/Loading'
import { FileUploadResult } from './FileUploadResult'
import { FileUploadSelect } from './FileUploadSelect'
import { FileExtension, getFileExtensions } from './types'

import styles from './FileUpload.module.scss'

export interface FileUploadProps {
  title?: string
  file?: FileData
  isLoading?: boolean
  error?: string
  acceptType?: FileExtension[]
  disabled?: boolean
  className?: string
  documentCategory: number

  onUpload(file: FileData): void

  onRemove?(file: FileData): void

  onBeforeRemove?(file: FileData): void

  onRemovePlaceholder?(file: FileData): void

  buttonText?: string
}

export interface FileData {
  id?: string
  fileName: string
  fileType: string
  fileSize: number
  fileSizeBytes: number
  fileSizeKb: string
  fileSizeMb: string
  base64Content: string
  data: File
  createdAt?: string
  error: boolean
}

export const FileUpload: React.FC<FileUploadProps> = (props) => {
  const { t } = useTranslation()
  const {
    file,
    title,
    isLoading = false,
    acceptType = getFileExtensions(),
    disabled = false,
    onUpload,
    onRemove,
    onBeforeRemove,
    onRemovePlaceholder,
    buttonText,
    className,
    documentCategory,
  } = props
  const { categorySizes } = useCategorySizesReadContext()
  const { refreshCategorySizes } = useCategorySizesWriteContext()
  const [error, setError] = useState<string | undefined>(undefined)
  const [contextCategorySize, setContextCategorySize] = useState<number>(4)

  useEffect(() => {
    if (isZero(categorySizes.length)) refreshCategorySizes()
    const contextCategory = categorySizes.find((x) => x.id === documentCategory)
    if (contextCategory) setContextCategorySize(contextCategory.maxUploadFileSizeInMb)
    else setContextCategorySize(4)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorySizes, documentCategory])

  useEffect(() => {
    if (props?.file === undefined) {
      setError(undefined)
    }

    if (props?.error) {
      setError(props.error)
    }
  }, [props.error, props.file])

  const handleUpload = async (file: FileData) => {
    if (!isFileNameValid(file.fileName)) {
      setError(t('Error in file name, characters allowed: a-z A-Z 0-9 - _ ( ) . ,'))
    }

    if (!isTypeValid(file.fileType, acceptType)) {
      setError(
        t('Error in file type. Supported format: ') + `${acceptType.toString().toUpperCase()}`
      )
    }

    if (!isFileSizeValid(file.fileSize, contextCategorySize)) {
      setError(t('Error in file size'))
    }

    if (
      isFileNameValid(file.fileName) &&
      isTypeValid(file.fileType, acceptType) &&
      isFileSizeValid(file.fileSize, contextCategorySize)
    ) {
      const base64Content = await toBase64(file.data)
      if (base64Content) {
        onUpload({ ...file, base64Content, error: false })
        setError(undefined)
      }
    } else {
      onUpload({ ...file, error: true })
    }
  }

  const handleRemove = () => {
    if (onBeforeRemove) {
      if (file) {
        onBeforeRemove(file)
      }
      setError(undefined)
    }

    if (!onBeforeRemove) {
      if (file) {
        onRemove?.(file)
      }
      setError(undefined)
    }
  }

  const handleRemovePlaceholder = () => {
    if (file) {
      onRemovePlaceholder?.(file)
    }
  }

  return (
    <Loading isLoading={isLoading} showLoadingIcon text={t('Uploading ...')}>
      <div className={classNames(styles.wrapper, className)}>
        {!file?.fileName && (
          <FileUploadSelect
            file={file}
            title={title}
            acceptType={acceptType}
            maxFileSize={contextCategorySize}
            onUpload={handleUpload}
            disabled={disabled}
            onRemovePlaceholder={handleRemovePlaceholder}
            buttonText={buttonText}
          />
        )}
        {file?.fileName && (
          <FileUploadResult
            title={title}
            file={file}
            error={error}
            disabled={disabled}
            onUpload={handleUpload}
            onRemove={handleRemove}
          />
        )}
      </div>
    </Loading>
  )
}

const isFileNameValid = (fileName: string) => {
  return /^[a-zA-Z0-9-_()., ]+$/.test(fileName)
}

const isTypeValid = (fileType: string, fileExtensions: string[]) => {
  return fileExtensions.includes(fileType?.toLowerCase())
}

const isFileSizeValid = (fileSize: number, maxFileSize: number) => {
  const fileSizeMb = getFileSizeMb(fileSize)
  return fileSizeMb <= maxFileSize
}

const getFileSizeBytes = (fileSize: number) => {
  return fileSize
}

const getFileSizeKb = (fileSize: number) => {
  return fileSize / 1024
}

const getFileSizeMb = (fileSize: number) => {
  return fileSize / 1024 / 1024
}

const getFile = (data: File) => {
  const fileType = getFileExtension(data)
  const fileName = data?.name
  const fileSize = data?.size

  return { fileName, fileType, fileSize }
}

const getFileExtension = (file: File): string => {
  const fileName = file?.name || ''
  const fileNameSize = fileName.split('.').length
  return fileName.split('.')[fileNameSize - 1]
}

export const createFileData = (data: File): FileData => {
  const { fileName, fileType, fileSize } = getFile(data)

  const fileSizeBytes = getFileSizeBytes(fileSize)
  const fileSizeKb = getFileSizeKb(fileSize)
  const fileSizeMb = getFileSizeMb(fileSize)

  return {
    fileName,
    fileType,
    fileSize,
    fileSizeBytes,
    fileSizeKb: Number(fileSizeKb).toFixed(2),
    fileSizeMb: Number(fileSizeMb).toFixed(2),
    base64Content: '',
    data,
    error: false,
  }
}

const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

export default FileUpload
