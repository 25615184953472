import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { useSessionEntity } from '../../global/context/EntityContext'
import { SelectableModal } from '../../global/field/SelectableModal'
import { NameDto } from '../../model/NameDto'
import { getTickmillSupportEmail } from '../../utils/companyName.utils'

import styles from './TradingAccountsPage.module.scss'

interface VipModalProps {
  closeModal: () => void
  onSelectAccountType: (typeDto?: NameDto) => void
  accountTypes: NameDto<number>[]
}
export const VipAccountTypeModal: React.FC<VipModalProps> = ({
  closeModal,
  onSelectAccountType,
  accountTypes,
}) => {
  const { t } = useTranslation()
  const entity = useSessionEntity()

  return (
    <SelectableModal
      onCancel={closeModal}
      title={t('Trading Account.Account Type')}
      renderOptions={() => (
        <div className={styles.vipContent}>
          <p className={classNames(styles.textStrong, 'mb-2')}>
            {t('Trading Account.Do you really want to open a VIP account')}
          </p>
          <p>
            {t(
              'Trading Account.NB! We will open a Pro account for you now. To change it to VIP, please follow the steps below:'
            )}
          </p>
          <br />
          <p>{t('Trading Account.1. Reach balance of 50,000 (USD, EUR or GBP)')}</p>
          <p>
            {t(
              'Trading Account.2. Send an email to support@tickmill.com and request to upgrade your account from Pro to VIP.',
              {
                email: getTickmillSupportEmail(entity),
              }
            )}
          </p>
          <p>
            {t(
              'Trading Account.3. In case equity is less than 25,000 (USD, EUR or GBP) Tickmill reserves a right to change account type back to Pro.'
            )}
          </p>
        </div>
      )}
      renderFooter={() => (
        <React.Fragment>
          <button className='button' onClick={closeModal} type='button'>
            {t('Decline').toUpperCase()}
          </button>
          <button
            className='button'
            onClick={() => onSelectAccountType(accountTypes.find((x) => x.name === 'Pro'))}
            type='button'
          >
            <b>{t('Trading Account.Accept').toUpperCase()}</b>
          </button>
        </React.Fragment>
      )}
    />
  )
}
