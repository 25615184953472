export interface TestSectionsDto {
  id: string
  name?: string
  category?: string
  tickmillCompanyId?: number
  version: number
  maxFailScore: number
  maxReferredScore: number
  subcategory?: string
  sections: TestSectionDto[]
  dateTaken?: string
}

export enum AppropriatenessTestSectionCode {
  PersonalInformation = 'personal_information',
  FinancialBackground = 'financial_background',
  TradingKnowledge = 'trading_knowledge_and_experience',
  CFDInvestmentKnowledge = 'cfd_investment_knowledge',
  ETDInvestmentKnowledge = 'futures_investment_knowledge',
}

export const isAppropriatenessTestPersonalInformation = (sectionCode?: string): boolean => {
  return AppropriatenessTestSectionCode.PersonalInformation === sectionCode
}

export const isAppropriatenessTestTradingKnowledge = (sectionCode?: string): boolean => {
  return AppropriatenessTestSectionCode.TradingKnowledge === sectionCode
}

export interface TestSectionAnswerDto {
  id: string
  name: string
  order: number
  code: string
  key: string
  answerId: string
}

export interface QuestionDto {
  id: string
  name: string
  questionId: string
  code?: string
  section: { id: string; name: string }
  widgetType?: TestQuestionWidgetType
  widgetName?: string
  text?: string
  description?: string
  tooltip?: string
  errorMessage?: string
  order: number
}

export enum IBTestSubCategory {
  Default = '6',
  MultiTier = '7',
}

export interface TestSectionWidgetDto {
  name: string
  type: TestQuestionWidgetType
  options: string | null
  tooltip: string | null
  errorMessage: string | null
  widgetType: TestQuestionWidgetType
}

export interface TestSectionQuestionDto {
  question: QuestionDto
  isMandatory: boolean
  order: number
  answers: TestSectionAnswerDto[]
  widget: TestSectionWidgetDto
}

export interface FreeTextAnswer {
  testQuestionId: string
  answer: string | null | number
}

export interface TestSectionDto {
  id: string
  name?: string
  order: number
  code?: string
  questions: TestSectionQuestionDto[]
}

export enum TestQuestionWidgetType {
  Text = 'text',
  Radio = 'radio',
  Checkbox = 'checkbox',
  Multiselect = 'multi_select',
  ShortText = 'short_text',
  Select = 'select',
  Country = 'country',
}
