import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useProductReadContext } from '../../../../global/context/ProductContext'
import { WalletDepositTransactionSuccessPage } from './WalletDepositTransactionSuccessPage'

export const TradersRoomWalletDepositTransactionSuccessPage: React.FC = (props) => {
  const navigate = useNavigate()
  const { isDefaultCFDProductType } = useProductReadContext()

  const handleCancel = () => {
    navigate(
      isDefaultCFDProductType()
        ? '/dashboard/traders-room/wallets'
        : '/dashboard/traders-room/balances'
    )
  }

  return <WalletDepositTransactionSuccessPage {...props} onCancel={handleCancel} />
}
