import React, { SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { ExternalLink } from '../global/ExternalLink'
import { Button } from '../global/button/Button'
import { useProductReadContext } from '../global/context/ProductContext'
import { usePublicDomainsReadContext } from '../global/context/PublicDomainsContext'
import { useSessionLanguage } from '../global/context/SessionSettingsContext'
import IconButton from '../global/iconButton/IconButton'
import { CloseIcon } from '../icons/CloseIcon'
import { SupportEmailIcon } from '../icons/SupportEmailIcon'
import { SupportLiveChatIcon } from '../icons/SupportLiveChatIcon'
import { SupportSmartPhoneIcon } from '../icons/SupportSmartPhoneIcon'
import { isTickmillProductTypeETD } from '../model/TickmillProductType'
import { getContactUsLink } from '../utils/companyName.utils'
import { dispatchOpenChat } from '../utils/cookie.utils'
import { useScrollToTop } from '../utils/useScrollToTop'
import { SupportFaq } from './faq/SupportFaq'

import styles from './SupportPage.module.scss'

export const Support: React.FC = () => {
  const { t } = useTranslation()
  const locale = useSessionLanguage()
  const { product } = useProductReadContext()
  const { domains } = usePublicDomainsReadContext()

  const supportLink = getContactUsLink(domains, locale)

  useScrollToTop()

  const onStartChat = (event: SyntheticEvent) => {
    event.preventDefault()
    dispatchOpenChat()
  }

  return (
    <>
      <div className={styles.wrapper}>
        <h2 className='has-text-centered'>{t('Support.We are here for you')}</h2>
        <p className={classNames(styles.subtitle)}>
          {t('Support.Get in touch with our dedicated Support team available through')}
        </p>
        <div className={classNames(styles.cards, 'mb-2')}>
          <div className={styles.card}>
            <div className={styles.icon}>
              <SupportLiveChatIcon size={70} color={'contrastMediumLow'} />
            </div>

            <h2 className={styles.title}>{t('Support.Live Chat')}</h2>
            <p className={styles.textGray}>{t('Support.Let one of our multilingual')}</p>
            <Button appearance='primary' size='M' className={styles.btn} onClick={onStartChat}>
              {t('Support.Start Chat')}
            </Button>
            <p className={styles.textGray}>{t('Support.Live chat time')}</p>
          </div>

          <div className={styles.card}>
            <div className={styles.icon}>
              <SupportEmailIcon size={70} color={'contrastMediumLow'} />
            </div>

            <h2 className={styles.title}>{t('Support.Mail')}</h2>
            <p className={styles.textGray}>{t('Support.Drop us an email')}</p>
            <ExternalLink url={supportLink}>
              <Button appearance='secondary' size='M' className={styles.btn}>
                {t('Support.Send a Message')}
              </Button>
            </ExternalLink>
            <p className={styles.textGray}>{t('Support.Email time')}</p>
          </div>

          <div className={styles.card}>
            <div className={styles.icon}>
              <SupportSmartPhoneIcon size={70} color={'contrastMediumLow'} />
            </div>

            <h2 className={styles.title}>{t('Support.Phone')}</h2>
            <p className={styles.textGray}>
              {t(
                'Support.Got questions? Call our Client Support team and let us answer your queries.'
              )}
            </p>
            <ExternalLink url={supportLink}>
              <Button appearance='secondary' size='M' className={styles.btn}>
                {t('Support.Call Us')}
              </Button>
            </ExternalLink>

            <p className={styles.textGray}>{t('Support.Phone time')}</p>
          </div>
        </div>
        {isTickmillProductTypeETD(product) && (
          <>
            <h2 className='has-text-centered pt-6 mt-5'>{t('Support.FAQ')}</h2>
            <SupportFaq />
          </>
        )}
      </div>
    </>
  )
}

export const SupportPage: React.FC = () => {
  const navigate = useNavigate()

  const goBack = () => navigate('/login')

  return (
    <div className='mt-3'>
      <IconButton onClick={goBack}>
        <CloseIcon />
      </IconButton>
      <Support />
    </div>
  )
}
