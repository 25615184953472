import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { MaintenanceModeContext } from '../../utils/MaintenanceModeContext'
import { SystemNotification } from '../systemNotification/SystemNotification'

export const MaintenanceMessage = () => {
  const { t } = useTranslation()
  const { isMaintenanceMode } = useContext(MaintenanceModeContext)
  const showNotification = useMemo(() => {
    if (isMaintenanceMode) {
      console.log('Maintenance mode is active')
      return true
    }
    const currentDate = new Date()
    const maintenanceStartDate = new Date('June 22, 2024 09:00:00 GMT+3')
    const maintenanceEndDate = new Date('June 22, 2024 11:35:00 GMT+3')

    return currentDate >= maintenanceStartDate && currentDate <= maintenanceEndDate
  }, [isMaintenanceMode])

  return showNotification ? (
    <SystemNotification
      title={t('Upcoming systems maintenance', {
        date: 'Saturday, June 22th 2024',
        fromTime: '09:00 GMT+3 ',
        toTime: '13:00 GMT+3',
      })}
    />
  ) : null
}
