import { FC } from 'react'
import { Link } from 'react-router-dom'

interface Props {
  hideOn: string
  path: string
  title: string
}

export const HowToUseLink: FC<Props> = ({ hideOn, title, path }) => {
  return (
    <Link to={path} className={`is-link ${hideOn}`}>
      {title}
    </Link>
  )
}
