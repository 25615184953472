import React from 'react'
import { useTranslation } from 'react-i18next'

import { InformationModal } from '../../global/modal/InformationModal'
import { IBTestSubCategory } from '../../model/TestSectionsDto'

import styles from './BecomeIBModal.module.scss'

interface Props {
  onCancel: () => void
  subCategoryId?: IBTestSubCategory
}

export const BecomeIBModal: React.FC<Props> = ({ onCancel, subCategoryId }) => {
  const { t } = useTranslation()
  return (
    <InformationModal
      title={t('IB.Why should I become an IB?')}
      buttonText={t('Got It')}
      onCancel={onCancel}
    >
      <div className={styles.body}>
        <div className={styles.item}>
          <h3>{t('IB.1 Build your client base')}</h3>
          <p>{t('IB.Recommend Tickmill to other traders or your friends')}</p>
        </div>
        <div className={styles.item}>
          <h3>{t('IB.2 Earn from trades')}</h3>
          <p>
            {t(
              'IB.The more your clients trade, the bigger is your reward. The reward is calculated'
            )}
          </p>
        </div>
        <div className={styles.item}>
          <h3>{t('IB.3 Manage your funds')}</h3>
          <p>{t('IB.Access and manage your funds the way you prefer')}</p>
        </div>
      </div>
    </InformationModal>
  )
}

export default BecomeIBModal
