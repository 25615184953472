import classNames from 'classnames'
import { TFunction } from 'i18next'

import { defineTab } from '../tabs/Tabs'

interface GetMobileTabsProps {
  isAccountPending: boolean
  isTraderRoomVisible: boolean
  isProductTypeCFD?: boolean
  t: TFunction<'translation', undefined, 'translation'>
}

export const tabMapping = (t: TFunction, isProductTypeCFD?: boolean) =>
  isProductTypeCFD
    ? [
        {
          main: 'dashboard',
          sub: 'traders-room',
          tab: 'wallets',
          id: 'traders-room-wallets',
          label: t('Tabs.Wallets'),
        },
        {
          main: 'dashboard',
          sub: 'traders-room',
          tab: 'trading-accounts',
          id: 'traders-room-trading-accounts',
          label: t('Tabs.Trading Accounts'),
        },
      ]
    : [
        {
          main: 'dashboard',
          sub: 'traders-room',
          tab: 'balances',
          id: 'traders-room-balances',
          label: t('Tabs.Balances'),
        },
        {
          main: 'dashboard',
          sub: 'subscriptions',
          tab: 'market-data',
          id: 'subscriptions-market-data',
          label: t('Tabs.Subscriptions'),
        },
      ]

export const getMobileTabs = ({
  isAccountPending,
  isTraderRoomVisible,
  t,
  isProductTypeCFD,
}: GetMobileTabsProps) =>
  tabMapping(t, isProductTypeCFD).map((tab) => ({
    ...defineTab(
      tab.id,
      tab.label,
      false,
      false,
      `${tab.sub}/${tab.tab}`,
      classNames({
        'is-hidden': isAccountPending || !isTraderRoomVisible,
      })
    ),
  }))
