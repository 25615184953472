import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import classNames from 'classnames'

import { useDeviceReferral } from '../../../Referral/DeviceReferral'
import { useReferral } from '../../../Referral/Referral'
import { Loading } from '../../../global/Loading/Loading'
import { Button } from '../../../global/button/Button'
import { useEntitySettings, useSessionEntity } from '../../../global/context/EntityContext'
import {
  useArabicSessionLanguage,
  useSessionLanguage,
} from '../../../global/context/SessionSettingsContext'
import { ToastContext, errorToast } from '../../../global/toast/Toast'
import { BackIcon } from '../../../icons/BackIcon'
import { ForwardIcon } from '../../../icons/ForwardIcon'
import { AccountType } from '../../../model/AccountType'
import { LeadDto, isLeadSourceMobileRegistrationType } from '../../../model/LeadDto'
import { NameDto } from '../../../model/NameDto'
import { AuthUser } from '../../../model/User'
import { Text } from '../../../ui/Typography/Typography'
import { useApiClient } from '../../../utils/ApiClient'
import { AuthSessionContext } from '../../../utils/AuthContext'
import { ClientApiClient } from '../../../utils/clientApi'
import {
  TickmillCompaniesEnum,
  entityPerTickmillCompany,
  getTickmillPublicDomain,
  isTickmillSCType,
} from '../../../utils/companyName.utils'
import { loginToAuth } from '../../../utils/loginToAuth'
import { TokenStorage } from '../../../utils/tokenStorage'
import { useFetchOne } from '../../../utils/useFetch'
import { useRedirectToSupport } from '../../../utils/useRedirectToSupport'
import { isZero } from '../../../utils/validations'
import { wait } from '../../../utils/wait'
import { CompanyDetailsStep1FormValues } from '../../CompanyDetailsInfo/CompanyDetailsStep1Form'
import { CompanyDetailsStep1Page } from '../../CompanyDetailsInfo/CompanyDetailsStep1Page'
import { CompanyDetailsStep2FormValues } from '../../CompanyDetailsInfo/CompanyDetailsStep2Form'
import { CompanyDetailsStep2Page } from '../../CompanyDetailsInfo/CompanyDetailsStep2Page'
import { CompanyDetailsStep3FormValues } from '../../CompanyDetailsInfo/CompanyDetailsStep3Form'
import { CompanyDetailsStep3Page } from '../../CompanyDetailsInfo/CompanyDetailsStep3Page'
import { CompanyDetailsStep4FormValues } from '../../CompanyDetailsInfo/CompanyDetailsStep4Form'
import { CompanyDetailsStep4Page } from '../../CompanyDetailsInfo/CompanyDetailsStep4Page'
import { SignupFormWrapper, SignupStep, SignupWrapper } from '../../SignupPage'
import { FormValuesLegalDocs } from '../PersonalInfoPage/PersonalInfoDocumentsFactory'
import { FormValuesStep4, PersonalDetailsStep4Form } from '../PersonalInfoStep4Form'
import { FormValuesStep5, PersonalDetailsStep5Form } from '../PersonalInfoStep5Form'
import { PersonalInfoStep6Page } from '../PersonalInfoStep6Page/PersonalInfoStep6Page'
import { SignupWarningMessages } from '../SignupWarningMessages'
import { usePersonalInfoTotalSteps } from '../usePersonalInfoTotalSteps'
import { SignUpRegisterTemplate } from './SignUpRegisterTemplate'

import styles from '../PersonalInfoPage.module.scss'

export interface ClientRegisterValues {
  firstname: string
  middleName: string
  lastname: string
  genderId: number
  primaryPhoneNumberCountryCode: string // "(lead.countryCode)",
  primaryPhoneNumber: string // "(lead.phoneNumber)",
  primaryEmail: string // "(lead.email)",
  birthday: string // "(lead.birthDay)",
  tickmillCompanyId: number //(lead.tickmillCompany.Id),
  type: number //(lead.type.Id),
  communicationLanguageId: string //"(lead.language.Id)",
  primaryAddressCountryId: string //"(lead.country.Id)",
  primaryAddressStreet: string //"(step4.primaryAddressStreet)",
  primaryAddressCity: string //"(step4.primaryAddressCity)",
  primaryAddressState: string //"(step4.primaryAddressState)",
  primaryAddressPostalCode: string //"(step4.primaryAddressPostalCode)",
  nationalities: {
    nationalityId: string
    isPrimary: boolean
  }[] //(step4.nationalities)
  personalId?: string //"(step4.PersonalId)",
  taxId: string
  taxIdAvailable: boolean //(step4.taxIdAvailable),
  taxIdUnavailableReasonId: number | null //(step4.taxIdUnavailableReasonId), -- Optional if taxIdAvailable is false and reasons are supported by entity
  isPoliticallyExposedPerson: boolean //(step4.isPoliticallyExposedPerson),
  mainIbCode: string //"(step4.mainIbCode)",
  password: string //"(step5.password)",
  shouldReceiveNewsletter: boolean //(step6.shouldReceiveNewsletter),
  tickmillPublicDomain: string // getDomainName(true)

  companyName?: string
  companyRegistrationDate?: string
  companyRegistrationNumber?: string
  companyTypeId?: string

  companyAddressStreet?: string
  companyAddressCity?: string
  companyAddressState?: string
  companyAddressPostalCode?: string

  companyAddressCountry?: string
  companyPhysicalAddressStreet?: string
  companyPhysicalAddressCity?: string
  companyPhysicalAddressState?: string
  companyPhysicalAddressPostalCode?: string

  companyPhysicalAddressCountry?: string
  companyEmail?: string
  companyPhoneNumberCountryCode?: string
  companyPhoneNumber?: string
  legalRepresentationBasisId?: string
  ownershipStructureId?: string
  companyOwners?: CompanyOwner[]
  companyDirectors?: CompanyDirector[]
  companyAuthorisedSignatories?: CompanySignatory[]
  companyAuthorisedTraders?: CompanyTrader[]
  marketDataClassificationId?: number
  companyWebsite?: string
  legalEntityIdentifier?: string
}

interface CompanyDirector {
  name: string
  street: string
  city: string
  state: string
  zipCode: string
  country: NameDto<string>
  taxIdentificationNumber?: string
}

interface CompanySignatory {
  name: string
  role: string
}

interface CompanyTrader {
  name: string
  role: string
  email: string
}

interface CompanyOwner {
  name: string
  ownership: string
  birthday: string
  street: string
  city: string
  state: string
  zipCode: string
  country: NameDto<string>
  taxIdentificationNumber: string
}

type FormValues = FormValuesStep4 &
  FormValuesStep5 &
  FormValuesLegalDocs &
  CompanyDetailsStep1FormValues &
  CompanyDetailsStep2FormValues &
  CompanyDetailsStep3FormValues
// CompanyDetailsStep4FormValues

type FormStepValues =
  | FormValuesStep4
  | FormValuesStep5
  | FormValuesLegalDocs
  | CompanyDetailsStep1FormValues
  | CompanyDetailsStep2FormValues
  | CompanyDetailsStep3FormValues
  // | CompanyDetailsStep4FormValues
  | undefined

const isFormValuesStep5 = (values: FormStepValues): values is FormValuesStep5 => {
  if (!values) return false
  return 'password' in values
}

export const SignUpRegisterPage: React.FC = () => {
  const { leadId } = useParams<{ leadId?: string }>()
  const [urlParams] = useSearchParams()
  const navigate = useNavigate()
  const referral = useReferral()
  const totalStepsCount = usePersonalInfoTotalSteps()

  const { t } = useTranslation()
  const apiClient = useApiClient(ClientApiClient)
  const entity = useSessionEntity()
  const locale = useSessionLanguage()
  const [, setEntity] = useEntitySettings()

  const [, setAuth] = useContext(AuthSessionContext) // eslint-disable-line @typescript-eslint/no-unused-vars
  const setToast = useContext(ToastContext)

  const [subStep, setSubStep] = useState(4)
  const [taxUnavailableReasons, setTaxUnavailableReasons] = useState<NameDto[]>([])
  const [formStepValues, setFormStepValues] = useState<FormStepValues>()
  const [isSubmitting, setSubmitting] = useState<boolean>(false)
  const [error, setError] = useState('')
  const token = urlParams.get('token') || ''

  const isArabic = useArabicSessionLanguage()

  const leadCallback = useCallback(async () => {
    if (!token) {
      setError(t('Sign up.Something went wrong, please contact support.'))
      return undefined
    }
    try {
      if (leadId && token) {
        const resp = await apiClient.getLeadByIdAndToken(leadId, token)
        setEntity({ entity: resp.tickmillCompany.id })
        return resp
      }
    } catch (e: unknown) {
      setError(t('Sign up.Something went wrong, please contact support.'))
      return undefined
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadId])
  const { data: lead, isLoading } = useFetchOne(leadCallback)

  useEffect(() => {
    getTaxUnavailableReasons()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const callback = useCallback(
    async () => {
      if (!lead?.id || !lead?.token) return []

      const response = await apiClient.getClientProductsByToken(lead.id, lead.token)

      if (isZero(response?.length)) {
        setError(t('Sign up.Something went wrong, please contact support.'))
      }

      return response
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lead?.id, lead?.token]
  )

  const productsCallback = useCallback(
    async () => {
      if (!lead?.id || !lead?.token) return []

      const response = await apiClient.getClientProductsByToken(lead.id, lead.token)

      if (isZero(response?.length)) {
        setError(t('Sign up.Something went wrong, please contact support.'))
      }

      return response
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lead?.id, lead?.token]
  )

  const { data: products = [] } = useFetchOne(productsCallback)

  const legalDocumentsCallback = useCallback(async () => {
    return apiClient.getLegalDocumentsQuery(
      locale,
      lead?.tickmillCompany.id,
      products.map((x) => x.id),
      lead?.country?.id
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lead, locale, products])

  const { data: legalDocuments = [] } = useFetchOne(legalDocumentsCallback)

  const getTaxUnavailableReasons = async () => {
    const resp = await apiClient.getTaxUnavailableReasons(locale)
    setTaxUnavailableReasons(resp)
  }

  useEffect(() => {
    getTaxUnavailableReasons()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const nationalitiesCallback = useCallback(async () => {
    return apiClient.getNationalities()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: nationalities = [] } = useFetchOne(nationalitiesCallback)

  const { supportUrl } = useRedirectToSupport()

  const companyLegalBasesCallback = useCallback(async () => {
    return apiClient.getLegalBases()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ownershipStructuresCallback = useCallback(async () => {
    return apiClient.getOwnershipStructures()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: legalBases = [] } = useFetchOne(companyLegalBasesCallback)
  const { data: ownershipStructures = [] } = useFetchOne(ownershipStructuresCallback)

  const getMainIbCodeByActivities = async (): Promise<string> => {
    const activities = lead?.activities || []
    const ibCodes = activities.filter((x) => x.campaign === 'ib_link')
    const referralCodes = await Promise.all(
      ibCodes.map(
        async (x) => await apiClient.validateIntroducingBrokerByCode(x.referralCode, entity)
      )
    )
    return referralCodes.length ? referralCodes[0].name : ''
  }

  const { referral_main_ib_code, aff_token } = referral.getReferralTokenValues(lead)
  const [mainIbCode, setMainIbCode] = useState(referral_main_ib_code)

  useEffect(() => {
    if (subStep === 4 && !mainIbCode) {
      getMainIbCodeByActivities().then((mainIbCode) => {
        setMainIbCode(mainIbCode)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadId, lead])

  useEffect(() => {
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subStep])

  const requireTaxId = useMemo(() => !isTickmillSCType(entity), [entity])

  if (!lead || error) {
    return (
      <Loading isLoading={isLoading}>
        <div className='pt-4 mt-4'></div>
        <div className={styles.box}>
          <div className='pt-6 pb-6'>
            <div className='pb-5'>
              <h1 className='text-align-center'>{error}</h1>
            </div>
            <div className='pb-4'>
              <div className='text-align-center'>
                <Text isParagraph className={styles.text}>
                  <Link to={supportUrl}>
                    <Button appearance='primary' size='L' type='button'>
                      {t('Contact support')}
                    </Button>
                  </Link>
                </Text>
              </div>
            </div>
          </div>
        </div>
      </Loading>
    )
  }
  // means client is already created, let's redirect to the login page
  if (lead.client) {
    navigate('/login')
    return null
  }

  // means that if lead tickmill company didn't fit to domain entity
  if (
    process.env.NODE_ENV !== 'development' &&
    !window.env.APP_ENTITY.includes(entityPerTickmillCompany[lead.tickmillCompany.id])
  ) {
    setToast(errorToast('Client Not Found.'))
    navigate('/login')
    return null
  }

  const isMainIBCodeValid = async (ibCode: string) => {
    try {
      await apiClient.validateIntroducingBrokerByCode(ibCode, entity)
      return true
    } catch {
      return false
    }
  }

  const onSubmitStep = async (step: number, values: FormStepValues) => {
    if (step === 4) {
      values = values as FormValuesStep4
      if (
        entity === TickmillCompaniesEnum.TICKMILL_EU ||
        ((entity === TickmillCompaniesEnum.TICKMILL_UK ||
          entity === TickmillCompaniesEnum.TICKMILL_PA) &&
          values.mainIbCode &&
          !(await isMainIBCodeValid(values.mainIbCode)))
      ) {
        values.mainIbCode = ''
      }
      setFormStepValues(values)
      return setSubStep(5)
    } else if (step === 5 && totalStepsCount === 6) {
      if (formStepValues) {
        setFormStepValues({ ...formStepValues, ...values })
        return setSubStep(6)
      }
    } else if (step === 5) {
      if (formStepValues) {
        values = values as FormValuesStep5
        const data = {
          ...formStepValues,
          ...values,
          shouldReceiveNewsletter: lead.optIn,
        } as FormValues

        if (lead.type.id === AccountType.Corporate) {
          setFormStepValues(data)
          return setSubStep(6)
        } else {
          setFormStepValues(data)
          await apiClient.createClient(denormalizeClientRegisterValues(lead, data))
          await signInAndMoveToFinancialInfo(data)
        }
      }
    } else if (step === 6 && totalStepsCount === 6) {
      values = values as FormValuesLegalDocs
      const data = {
        ...formStepValues,
        agreedLegalDocuments: values?.agreedLegalDocuments || [],
        marketDataClassificationId: values?.readDocumentMarketData?.marketDataClassificationId,
        shouldReceiveNewsletter: lead.optIn,
      } as FormValues

      if (lead.type.id === AccountType.Corporate) {
        setFormStepValues(data)
        return setSubStep(7)
      } else {
        setFormStepValues(data)
        await apiClient.createClient(denormalizeClientRegisterValues(lead, data))
        await signInAndMoveToFinancialInfo(data)
      }
    } else if (step === totalStepsCount + 1) {
      if (formStepValues) {
        setFormStepValues({ ...formStepValues, ...values })
        setSubStep(totalStepsCount + 2)
      }
    } else if (step === totalStepsCount + 2) {
      if (formStepValues) {
        const data = { ...formStepValues, ...values } as FormValues
        setFormStepValues(data)
        setSubStep(totalStepsCount + 3)
      }
    } else if (step === totalStepsCount + 3) {
      if (formStepValues) {
        const data = {
          ...formStepValues,
          ...values,
        } as FormValues
        setFormStepValues(data)
        // if (
        //   entity === TickmillCompaniesEnum.TICKMILL_UK ||
        //   entity === TickmillCompaniesEnum.TICKMILL_EU
        // ) {
        //   setSubStep(totalStepsCount + 4)
        // } else {
        await apiClient.createClient(denormalizeClientRegisterValues(lead, data))
        await signInAndMoveToFinancialInfo(data)
        // }
      }
    } else if (step === totalStepsCount + 4) {
      if (formStepValues) {
        const data = {
          ...formStepValues,
          ...values,
        } as FormValues
        setFormStepValues(data)
        await apiClient.createClient(denormalizeClientRegisterValues(lead, data))
        await signInAndMoveToFinancialInfo(data)
      }
    }
  }

  const signInAndMoveToFinancialInfo = async (values: FormStepValues) => {
    await wait(1000)
    await doLogin(values, async (auth) => {
      navigate('/users/register3/' + auth.id)
    })
  }

  const doLogin = async (values: FormStepValues, callback: (auth: AuthUser) => Promise<void>) => {
    if (isFormValuesStep5(values)) {
      setSubmitting(true)
      const login = await apiClient.login({
        email: lead.email,
        password: values?.password,
      })
      const auth = loginToAuth(login)
      if (!auth) return
      setAuth(auth)
      localStorage.setItem('auth', JSON.stringify(auth))
      TokenStorage.storeToken('access', auth.accessToken)
      TokenStorage.storeToken('refresh', auth.refreshToken)
      TokenStorage.storeSession(auth.sessionId)

      await callback?.(auth)

      setSubmitting(false)
    }
  }

  if (isLeadSourceMobileRegistrationType(lead.leadSource.id)) {
    return <SuccessPage lead={lead} tokenId={token} />
  }

  if (subStep === 4) {
    return (
      <SignUpRegisterTemplate
        lead={lead}
        subStep={subStep}
        isLoading={isLoading}
        onStepChange={setSubStep}
        reversed
      >
        <SignupWarningMessages />

        <PersonalDetailsStep4Form
          lead={lead}
          entity={entity}
          initialValues={
            {
              ...formStepValues,
              mainIbCode,
              affiliateToken: aff_token,
            } as FormValuesStep4
          }
          nationalities={nationalities}
          country={lead.country}
          taxReasons={taxUnavailableReasons}
          onSubmit={(v) => onSubmitStep(4, v)}
          requireTaxId={requireTaxId}
        />
      </SignUpRegisterTemplate>
    )
  }

  if (subStep === 5) {
    return (
      <SignUpRegisterTemplate
        lead={lead}
        subStep={subStep}
        isLoading={isLoading}
        onStepChange={setSubStep}
        reversed
      >
        <SignupWarningMessages />

        <PersonalDetailsStep5Form
          initialValues={
            {
              ...formStepValues,
              firstName: lead?.firstName,
            } as FormValuesStep5
          }
          leadData={{ id: lead.id, token: lead.token }}
          onSubmit={(v) => onSubmitStep(subStep, v)}
        />
      </SignUpRegisterTemplate>
    )
  }

  if (subStep === 6 && totalStepsCount === 6) {
    return (
      <PersonalInfoStep6Page
        lead={lead}
        subStep={subStep}
        isLoading={isLoading}
        onStepChange={setSubStep}
        legalDocuments={legalDocuments}
        products={products}
        onSubmit={(v) => onSubmitStep(subStep, v)}
      />
    )
  }

  if (subStep === totalStepsCount + 1) {
    return (
      <SignUpRegisterTemplate
        lead={lead}
        subStep={subStep}
        isLoading={isLoading}
        onStepChange={setSubStep}
      >
        <SignupWarningMessages leadTypeId={lead?.type?.id} />
        <CompanyDetailsStep1Page
          initialValues={formStepValues as CompanyDetailsStep1FormValues}
          onSubmit={(values) => onSubmitStep(subStep, values)}
        />
      </SignUpRegisterTemplate>
    )
  }

  if (subStep === totalStepsCount + 2) {
    return (
      <SignUpRegisterTemplate
        lead={lead}
        subStep={subStep}
        isLoading={isLoading}
        onStepChange={setSubStep}
      >
        <SignupWarningMessages leadTypeId={lead?.type?.id} />
        <CompanyDetailsStep2Page
          legalBases={legalBases}
          ownershipStructures={ownershipStructures}
          initialValues={formStepValues as CompanyDetailsStep2FormValues}
          onSubmit={(values) => onSubmitStep(subStep, values)}
        />
      </SignUpRegisterTemplate>
    )
  }

  if (subStep === totalStepsCount + 3) {
    return (
      <SignUpRegisterTemplate
        lead={lead}
        subStep={subStep}
        isLoading={isLoading}
        onStepChange={setSubStep}
      >
        <SignupWarningMessages leadTypeId={lead?.type?.id} />
        <CompanyDetailsStep3Page onSubmit={(values) => onSubmitStep(subStep, values)} />
      </SignUpRegisterTemplate>
    )
  }

  if (subStep === totalStepsCount + 4) {
    return (
      <SignupFormWrapper label={t('Sign up.Sign Up')} products={products}>
        <SignupStep
          accountTypeId={lead.type.id}
          section={lead.type.id === AccountType.Corporate ? 3 : 2}
        />
        <SignupWrapper
          className={classNames(
            'is-flex-direction-column is-align-items-center',
            styles.confirmationWrapper
          )}
        >
          <h1 className='pt-4'>{t('Sign up.Profile Created')}!</h1>
          <span className={classNames(styles.text, 'pt-4')}>
            {t('Sign up.Let’s get on to the next step – Your Trading Experience!')}
          </span>
          <Button
            onClick={() => signInAndMoveToFinancialInfo(formStepValues)}
            className='mt-4'
            type='button'
            appearance='primary'
            size='L'
            renderRightIcon={() => (
              <span className='is-flex is-align-items-center'>
                {isArabic ? <BackIcon inverse /> : <ForwardIcon inverse />}
              </span>
            )}
            disabled={isSubmitting}
          >
            {t('Next')}
          </Button>
        </SignupWrapper>
      </SignupFormWrapper>
    )
  }

  return null
}

interface SuccessPageProps {
  lead: LeadDto
  tokenId: string
}

const SuccessPage: React.FC<SuccessPageProps> = (props) => {
  const { lead } = props
  const { referralAppLink } = useDeviceReferral()

  const { t } = useTranslation()

  const goToMobileApp = () => {
    window.location.href = referralAppLink({ id: lead.id, token: props.tokenId, type: 'register' })
  }

  return (
    <div className={styles.box}>
      <div className='pt-6 pb-6'>
        <div className='pb-5'>
          <Text isParagraph className={classNames(styles.text, 'text-align-center')}>
            {t('Sign up.Please continue your registration by going back to the mobile app.')}
          </Text>
        </div>
        <div className='pb-4'>
          <div className='text-align-center'>
            <Text className={styles.text}>
              <Button appearance='primary' size='L' type='button' onClick={goToMobileApp}>
                {t('Sign up.Back to the app')}
              </Button>
            </Text>
          </div>
        </div>
      </div>
    </div>
  )
}

export const denormalizeClientRegisterValues = (
  lead: LeadDto,
  data: FormValues
): ClientRegisterValues => {
  const formVales = {
    // step 4
    firstname: lead.firstName,
    middleName: lead.middleName,
    lastname: lead.lastName,
    nativeName: lead.nativeName,
    genderId: lead.gender.id,
    primaryPhoneNumberCountryCode: lead.countryCode,
    primaryPhoneNumber: lead.phoneNumber,
    primaryEmail: lead.email,
    birthday: lead.birthDay,
    tickmillCompanyId: lead.tickmillCompany.id,
    type: lead.type.id,
    communicationLanguageId: lead.language.id,
    primaryAddressCountryId: lead.country.id,
    primaryAddressStreet: data.address,
    primaryAddressState: data.primaryAddressState,
    primaryAddressCity: data.primaryAddressCity,
    primaryAddressPostalCode: data.primaryAddressPostalCode,
    nationalities: data.nationalities.map((n, i) => ({
      nationalityId: n.id,
      isPrimary: isZero(i),
    })),
    personalId: data.personalId,
    taxId: !data.taxIdNotAvailable ? data.taxId : '',
    taxIdAvailable: !data.taxIdNotAvailable,
    isPoliticallyExposedPerson: data.isPoliticallyExposedPerson,
    mainIbCode: data.mainIbCode,
    affToken: data.affiliateToken,
    password: data.password || '',
    taxIdUnavailableReasonId: data.taxIdUnavailableReasonId || null,
    tickmillPublicDomain: getTickmillPublicDomain(),

    // step 6
    shouldReceiveNewsletter: lead.optIn || false,
    agreedLegalDocuments: data?.agreedLegalDocuments || [],
    marketDataClassificationId: data?.marketDataClassificationId || undefined,
  }

  const companyDetailsValues = {
    // step 7
    companyName: data?.companyName,
    companyRegistrationDate: data?.companyRegistrationDate,
    companyRegistrationNumber: data?.companyRegistrationNumber,
    companyTypeId: data?.companyTypeId,

    companyAddressCountry: data?.companyAddressCountry,
    companyAddressCity: data?.registrationAddress?.companyAddressCity,
    companyAddressPostalCode: data?.registrationAddress?.companyAddressPostalCode,
    companyAddressState: data?.registrationAddress?.companyAddressState,
    companyAddressStreet: data?.registrationAddress?.companyAddressStreet,

    companyPhysicalAddressCountry: data?.companyAddressCountry,
    companyPhysicalAddressCity: data?.physicalAddress?.companyAddressCity,
    companyPhysicalAddressPostalCode: data?.physicalAddress?.companyAddressPostalCode,
    companyPhysicalAddressState: data?.physicalAddress?.companyAddressState,
    companyPhysicalAddressStreet: data?.physicalAddress?.companyAddressStreet,

    companyPhoneNumberCountryCode: data?.companyPhoneNumberCountryCode,
    companyPhoneNumber: data?.companyPhoneNumber,
    companyEmail: data?.companyEmail,
    clientTests: data?.clientTests,

    // step 8
    legalRepresentationBasisId: data?.legalRepresentationBasis?.id?.toString(),
    ownershipStructureId: data?.ownershipStructure?.id?.toString(),
    companyOwners: data?.companyOwners,

    // step 9
    companyDirectors: data?.directors || [],
    companyAuthorisedSignatories: data?.signatories || [],
    companyAuthorisedTraders: data?.traders || [],
  }

  if (companyDetailsValues?.companyName) {
    return { ...formVales, ...companyDetailsValues }
  }

  return formVales
}
