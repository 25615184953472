import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { getReferralCookies } from '../../../Referral/helpers'
import { CreateLead, LeadMembersEnum } from '../../../model/CreateLead'
import { LeadResponseDto } from '../../../model/LeadResponseDto'
import { ResponseError, useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import { getTickmillPublicDomain } from '../../../utils/companyName.utils'
import { getLeadReferrer } from '../../../utils/cookie.utils'
import { isOne, isZero, minTwoDigits } from '../../../utils/validations'
import { FormValuesStep1 } from '../PersonalInfoStep1Page/PersonalInfoDetailsStep1Form'
import { PersonalInfoDetailsStep1Page } from '../PersonalInfoStep1Page/PersonalInfoDetailsStep1Page'
import { FormValuesStep2 } from '../PersonalInfoStep2Page/PersonalInfoStep2Form'
import { PersonalInfoStep2Page } from '../PersonalInfoStep2Page/PersonalInfoStep2Page'
import { FormValuesStep3 } from '../PersonalInfoStep3Page/PersonalInfoStep3Form'
import { PersonalInfoStep3Page } from '../PersonalInfoStep3Page/PersonalInfoStep3Page'
import { PersonalInfoDetailsStep4Page } from '../PersonalInfoStep4Page/PersonalInfoDetailsStep4Page'
import { PersonalInfoStepUSPage } from '../PersonalInfoStepUSPage/PersonalInfoStepUSPage'
import { FormValuesStep0, PersonalInfoStepAccountTypePage } from './PersonalInfoStepAccountTypePage'

type FormValues = FormValuesStep0 & FormValuesStep1 & FormValuesStep2 & FormValuesStep3

export const PersonalInfoPage = () => {
  const apiClient = useApiClient(ClientApiClient)

  const [step, setStep] = useState<number | 'US Citizen'>(0)
  const [formValues, setFormValues] = useState<FormValues>()
  const [lead, setLead] = useState<LeadResponseDto | undefined>()
  const [error, setError] = useState<string>()
  const [params] = useSearchParams()

  const isRedirection = () => {
    return (
      params.get('redir') &&
      params.get(LeadMembersEnum.FIRST_NAME) &&
      params.get(LeadMembersEnum.LAST_NAME) &&
      params.get(LeadMembersEnum.GENDER_ID) &&
      params.get(LeadMembersEnum.BIRTHDAY)
    )
  }

  useEffect(() => {
    const birthDay = new Date(params.get(LeadMembersEnum.BIRTHDAY) || '')
    const monthOfBirth = birthDay.getMonth() + 1

    if (isRedirection() || params.get('source')) {
      setFormValues({
        optIn: false,
        productIds: [],
        countryId: params.get(LeadMembersEnum.COUNTRY_ID) || '',
        tickmillCompanyId: Number(params.get(LeadMembersEnum.TICKMILL_COMPANY_ID)),
        typeId: Number(params.get(LeadMembersEnum.TYPE_ID)),
        genderId: params.get(LeadMembersEnum.GENDER_ID) || '',
        firstName: params.get(LeadMembersEnum.FIRST_NAME) || '',
        lastName: params.get(LeadMembersEnum.LAST_NAME) || '',
        middleName: params.get(LeadMembersEnum.MIDDLE_NAME) || '',
        dayOfBirth: birthDay.getDate().toString() || '',
        monthOfBirth: monthOfBirth.toString() || '',
        yearOfBirth: birthDay.getFullYear().toString() || '',
        countryCode: params.get(LeadMembersEnum.COUNTRY_CODE) || '',
        phoneNumber: params.get(LeadMembersEnum.PHONE_NUMBER) || '',
        email: params.get(LeadMembersEnum.EMAIL) || '',
        languageId: params.get(LeadMembersEnum.LANGUAGE_ID) || '',
      })
    }
    if (isRedirection()) {
      setStep(2)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmitStep = async (
    step: number,
    values: FormValuesStep0 | FormValuesStep1 | FormValuesStep2 | FormValuesStep3
  ) => {
    if (isZero(step)) {
      const v = values as FormValuesStep0
      setFormValues({
        genderId: '',
        typeId: v.typeId,
        email: '',
        countryId: '',
        lastName: '',
        firstName: '',
        nativeName: '',
        monthOfBirth: '',
        dayOfBirth: '',
        yearOfBirth: '',
        middleName: '',
        countryCode: '',
        phoneNumber: '',
        languageId: '',
        optIn: false,
        tickmillCompanyId: 0,
        productIds: [],
      })
      return setStep(1)
    }
    if (isOne(step)) {
      const v = values as FormValuesStep1
      setFormValues({
        ...formValues,
        typeId: formValues?.typeId || 1,
        email: '',
        countryId: '',
        countryCode: '',
        phoneNumber: '',
        languageId: '',
        optIn: false,
        tickmillCompanyId: 0,
        productIds: [],
        agreedLegalDocuments: [],
        genderId: v.genderId,
        firstName: v.firstName,
        lastName: v.lastName,
        nativeName: v.nativeName,
        middleName: v.middleName,
        monthOfBirth: v.monthOfBirth,
        dayOfBirth: v.dayOfBirth,
        yearOfBirth: v.yearOfBirth,
      })
      if (v.isUsCitizen) return setStep('US Citizen')
      return setStep(2)
    } else if (step === 2) {
      const v = values as FormValuesStep2
      setFormValues({
        ...v,
        email: '',
        languageId: '',
        countryCode: '',
        phoneNumber: '',
        optIn: v.optIn,
        typeId: formValues?.typeId || 1,
        genderId: formValues?.genderId || '0',
        firstName: formValues?.firstName || '',
        lastName: formValues?.lastName || '',
        nativeName: formValues?.nativeName || '',
        middleName: formValues?.middleName || '',
        monthOfBirth: formValues?.monthOfBirth || '',
        dayOfBirth: formValues?.dayOfBirth || '',
        yearOfBirth: formValues?.yearOfBirth || '',
        countryId: v.countryId,
        tickmillCompanyId: v.tickmillCompanyId,
        productIds: v.productIds,
        agreedLegalDocuments: v.agreedLegalDocuments || [],
      })
      return setStep(3)
    } else if (step === 3) {
      const v = values as FormValuesStep2 & FormValuesStep3
      setFormValues({
        email: v.email,
        languageId: v.languageId,
        countryCode: v.countryCode,
        phoneNumber: v.phoneNumber,
        typeId: formValues?.typeId || 1,
        genderId: formValues?.genderId || '0',
        firstName: formValues?.firstName || '',
        lastName: formValues?.lastName || '',
        nativeName: formValues?.nativeName || '',
        middleName: formValues?.middleName || '',
        monthOfBirth: formValues?.monthOfBirth || '',
        dayOfBirth: formValues?.monthOfBirth || '',
        yearOfBirth: formValues?.yearOfBirth || '',
        countryId: formValues?.countryId || '',
        tickmillCompanyId: formValues?.tickmillCompanyId || 0,
        productIds: formValues?.productIds || [1],
        agreedLegalDocuments: v.agreedLegalDocuments || [],
        optIn: formValues?.optIn || false,
        phoneNumberType: v?.phoneNumberType,
      })
      if (formValues) {
        try {
          const formData = {
            ...formValues,
            email: v.email,
            languageId: v.languageId,
            countryCode: v.countryCode,
            phoneNumber: v.phoneNumber,
          }
          const resp = await apiClient.createLead(dataToSubmit(formData))

          setLead(resp)
          setError(undefined)
          return setStep(4)
        } catch (err: unknown) {
          const error = err as ResponseError
          const code = error?.response?.response?.data?.code

          console.error(error)

          setError(code)
          setStep(4)
          throw error
        }
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])

  return (
    <PersonalInfoStepFactory
      step={step}
      lead={lead}
      formValues={formValues}
      error={error}
      onStepChange={setStep}
      onSubmit={onSubmitStep}
    />
  )
}

interface PersonalInfoStepFactoryProps {
  step: number | string
  lead: LeadResponseDto | undefined
  error: string | undefined
  formValues: FormValues | undefined
  onSubmit(
    step: number,
    values: FormValuesStep0 | FormValuesStep1 | FormValuesStep2 | FormValuesStep3
  ): Promise<void>
  onStepChange(n: number): void
}

const PersonalInfoStepFactory: React.FC<PersonalInfoStepFactoryProps> = (props) => {
  const { step, lead, error, formValues, onStepChange, onSubmit } = props

  switch (step) {
    case 0:
      return (
        <PersonalInfoStepAccountTypePage
          values={{ typeId: formValues?.typeId }}
          onSubmit={(values) => onSubmit(0, values)}
        />
      )
    case 1:
      return (
        <PersonalInfoDetailsStep1Page
          step={step}
          setStep={onStepChange}
          values={formValues}
          onSubmit={(values) => onSubmit(1, values)}
        />
      )
    case 2:
      return (
        <PersonalInfoStep2Page
          formValues={formValues}
          step={step}
          setStep={onStepChange}
          onSubmit={(values) => onSubmit(2, values)}
        />
      )
    case 3:
      return (
        <PersonalInfoStep3Page
          step={step}
          formValues={formValues}
          setStep={onStepChange}
          onSubmit={(values) => onSubmit(3, values)}
        />
      )
    case 4:
      return (
        <PersonalInfoDetailsStep4Page
          step={step}
          values={formValues}
          lead={lead}
          error={error}
          setStep={onStepChange}
        />
      )
    case 'US Citizen':
      return <PersonalInfoStepUSPage setStep={onStepChange} />
    default:
      return null
  }
}

const dataToSubmit = (values: FormValues): CreateLead => {
  const referral = getReferralCookies()
  return {
    countryId: values.countryId,
    birthDay:
      values.yearOfBirth +
      '-' +
      minTwoDigits(values.monthOfBirth) +
      '-' +
      minTwoDigits(values.dayOfBirth),
    languageId: values.languageId,
    genderId: values.genderId,
    firstName: values.firstName,
    lastName: values.lastName,
    nativeName: values.nativeName,
    middleName: values.middleName,
    email: values.email,
    countryCode: values.countryCode,
    optIn: values.optIn,
    productIds: values.productIds,
    phoneNumber: values.phoneNumber,
    source: 'Live Registration',
    tickmillCompanyId: values.tickmillCompanyId,
    tickmillPublicDomain: getTickmillPublicDomain(),
    typeId: values.typeId.toString(),
    referrer: getLeadReferrer(document.referrer || referral.referrer),
    campaign: referral.utm_campaign,
    campaignSource: referral.utm_source,
    campaignMedium: referral.utm_medium,
    campaignTerm: referral.utm_term,
    campaignContent: referral.utm_content,
    referralCode: referral.referral_code || '',
    affToken: referral.aff_token || '',
    agreedLegalDocuments: values.agreedLegalDocuments || [],
  }
}
