import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form, FormikErrors, FormikProps, withFormik } from 'formik'
import { t } from 'i18next'

import { PercentageAmountChip } from '../../../global/PercentageChip/PercentageAmountChip'
import { Button } from '../../../global/button/Button'
import { useSessionLanguage } from '../../../global/context/SessionSettingsContext'
import { createFormField } from '../../../global/formField/FormField'
import { createFormNumericField } from '../../../global/formField/FormNumericField'
import { CancelActionModal } from '../../../global/modal/CancleActionModal'
import { InformationModal } from '../../../global/modal/InformationModal'
import { Modal } from '../../../global/modal/Modal'
import { FormTemplate } from '../../../global/templates/FormTemplate'
import { useFormatNumber } from '../../../hooks/useFormatNumber'
import { DropArrowDownIcon } from '../../../icons/DropArrowDownIcon'
import { TradingAccount } from '../../../model/TradingAccount'
import { CurrencyType, WalletDto, WalletTypeEnum } from '../../../model/WalletDto'
import { ExchangeRateBox } from '../../../ui/ExchangeRateBox/ExchangeRateBox'
import { PageData, useApiClient } from '../../../utils/ApiClient'
import { usePathHistoryContext } from '../../../utils/PathHistoryContext'
import { ClientApiClient } from '../../../utils/clientApi'
import { TickmillCompaniesEnum } from '../../../utils/companyName.utils'
import { WalletRestrictions, isRestricted } from '../../../utils/wallet.utils'
import { ExchangeRateModal } from './ExchangeRateModal'
import { TransferFromModal } from './TransferFromModal'
import { TransferToModal } from './TransferToModal'
import { TransferPageProps } from './WalletTransferPage'

import styles from './TransferForm.module.scss'

export type WalletOrTradingAccountType = {
  platformOverview?: {
    login: string
    group: string
    equity: number
    balance: number
    lastEndOfDayBalance: number
    usedMargin: number
    freeMargin: number
    marginLevel: number
  }

  id: string
  name?: string | number
  wallet?: {
    id: string
  }
  currency:
    | {
        id: CurrencyType | undefined
        name: string
      }
    | undefined
  balance: number
  walletType?: {
    id: number
  }
}

export interface TransferValues {
  transferFrom: WalletOrTradingAccountType
  transferTo: WalletOrTradingAccountType
  fromAmount: number
  minAmount: number
}

interface OuterProps {
  limits: TransferPageProps['limits']
  title: string
  entity: TickmillCompaniesEnum
  description?: React.ReactNode
  wallets: WalletDto[]
  tradingAccounts: PageData<TradingAccount> | undefined
  walletId?: string
  walletTypeFrom: WalletTypeEnum
  walletTypeTo: WalletTypeEnum[]
  tradingAccountId?: string
  handleFormSubmit: (values: TransferValues) => void
  isFromCampaign?: boolean
  transferFromLabel?: string
}

const FormField = createFormField<TransferValues>()
const FormNumericField = createFormNumericField<TransferValues>()

const TransferFormUI: React.FC<FormikProps<TransferValues> & OuterProps> = (props) => {
  const {
    title,
    description,
    wallets,
    tradingAccounts,
    walletId,
    tradingAccountId,
    walletTypeFrom,
    walletTypeTo,
    values,
    isValid,
    setFieldValue,
    handleSubmit,
    isFromCampaign,
    transferFromLabel,
  } = props
  const { transferTo, transferFrom, fromAmount } = values

  const [exitConfirmationModal, setExitConfirmationModal] = useState(false)
  const [transferFromModal, setTransferFromModal] = useState(false)
  const [transferToModal, setTransferToModal] = useState(false)
  const [exchangeRateModal, setExchangeRateModal] = useState(false)
  const [exchangeRate, setExchangeRate] = useState<number>(0)
  const [isBalanceModalOpen, setBalanceModalOpen] = useState(false)
  const [, setFromAmount] = useState<number | undefined>(undefined)

  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const { formatNumber, formatMoney } = useFormatNumber()
  const { navigateToPreviousPath } = usePathHistoryContext()

  const handleAmountChange = (field: string, value: number) => {
    if (field === 'fromAmount') {
      setFromAmount(value)
    }
  }

  const handleSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleSubmit()
  }

  const getReceivingAmount = (fromAmount: number, fractionDigits = 2) => {
    const amount = exchangeRate > 0 ? fromAmount * exchangeRate : fromAmount
    return formatNumber(amount, fractionDigits)
  }

  const locale = useSessionLanguage()

  useEffect(() => {
    props.validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, values.transferTo])

  useEffect(() => {
    ;(async () => {
      if (
        transferFrom.id &&
        transferTo.id &&
        transferFrom.currency?.id &&
        transferTo.currency?.id &&
        transferFrom.currency.id !== transferTo.currency.id
      ) {
        const { rate } = await apiClient.getExchangeRate({
          BaseCcy: transferFrom.currency.id,
          QuoteCcy: transferTo.currency.id,
          direction: 'sell',
        })
        setExchangeRate(rate)
      } else {
        setExchangeRate(0)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferFrom, transferTo])

  useEffect(() => {
    if (tradingAccountId) {
      const selectedTradingAccount = tradingAccounts?.items.find((ta) => ta.id === tradingAccountId)
      handleTransferFromChange(selectedTradingAccount as WalletOrTradingAccountType)
    } else if (walletId) {
      const selectedWallet = wallets.find((wallet) => wallet.id === walletId)
      handleTransferFromChange(selectedWallet as WalletOrTradingAccountType)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const apiClient = useApiClient(ClientApiClient)

  const handleTransferFromChange = async (taOrWallet: WalletOrTradingAccountType) => {
    setFieldValue('transferFrom', taOrWallet)
    // Set transferTo to the wallet of trading account
    if (!isWallet(taOrWallet)) {
      const wallet = getWallet(taOrWallet?.wallet?.id)
      if (
        wallet?.restrictions &&
        !isRestricted(WalletRestrictions.TRANSFER_TO, wallet?.restrictions)
      ) {
        setFieldValue('transferTo', wallet)
      }
    } else {
      setFieldValue('transferTo', {})
    }
    setTransferFromModal(false)
  }

  const handleTransferToChange = (taOrWallet: WalletOrTradingAccountType) => {
    setFieldValue('transferTo', taOrWallet)
    setTransferToModal(false)
  }

  const getTransferName = (
    isTransferFrom: boolean,
    tradingAccountOrWallet: WalletOrTradingAccountType
  ) => {
    const walletName =
      isTransferFrom && location.pathname.includes('introducing-broker')
        ? t('Wallet.Currency IB Wallet', {
            walletCurrencyId: tradingAccountOrWallet.currency?.id,
          })
        : t('Wallet.Currency Wallet', {
            walletCurrencyId: tradingAccountOrWallet.currency?.id,
          })

    return isWallet(tradingAccountOrWallet) ? walletName : tradingAccountOrWallet.name
  }

  const handleExitPageConfirmationModal = () => {
    setExitConfirmationModal(false)
  }

  const handleExitPageConfirmationOpen = () => {
    setExitConfirmationModal(true)
  }

  const handleSetTransferToModalOpen = () => {
    if (transferFrom.id && isWallet(transferFrom)) {
      setTransferToModal(true)
    }
  }

  const getWallet = (walletId: string | undefined) => {
    return wallets.find((wallet) => wallet.id === walletId)
  }

  const getBalance = (walletOrTa: WalletOrTradingAccountType) =>
    isWallet(walletOrTa) ? walletOrTa.balance : walletOrTa.platformOverview?.balance

  const openTransferFromModal = () => {
    setFieldValue('fromAmount', undefined)
    setTransferFromModal(true)
  }

  const walletBalance = getBalance(transferFrom)

  return (
    <React.Fragment>
      {exitConfirmationModal && (
        <Modal
          closeModal={handleExitPageConfirmationModal}
          render={() => (
            <CancelActionModal
              onConfirm={navigateToPreviousPath}
              onCancel={handleExitPageConfirmationModal}
            />
          )}
        />
      )}
      {exchangeRateModal && (
        <Modal
          closeModal={() => setExchangeRateModal(false)}
          render={({ closeModal }) => <ExchangeRateModal onCancel={closeModal} />}
        />
      )}
      {transferFromModal && (
        <Modal
          closeModal={() => setTransferFromModal(false)}
          render={({ closeModal }) => (
            <TransferFromModal
              onCancel={closeModal}
              wallets={wallets.filter((x) => x.walletType.id === walletTypeFrom)}
              tradingAccounts={tradingAccounts}
              setTransferFrom={handleTransferFromChange}
              transferFrom={transferFrom}
              transferTo={transferTo}
            />
          )}
        />
      )}
      {transferToModal && transferFrom.id && (
        <Modal
          closeModal={() => setTransferToModal(false)}
          render={({ closeModal }) => (
            <TransferToModal
              onCancel={closeModal}
              wallets={wallets.filter((x) => walletTypeTo.includes(x.walletType.id))}
              tradingAccounts={tradingAccounts}
              setTransferTo={handleTransferToChange}
              transferFrom={transferFrom}
              transferTo={transferTo}
            />
          )}
        />
      )}
      {isBalanceModalOpen && (
        <Modal
          closeModal={() => setBalanceModalOpen(false)}
          render={({ closeModal }) => (
            <InformationModal
              title={t('Wallet.Current Balance')}
              onCancel={closeModal}
              buttonText={t('Got it')}
            >
              <p>{t('Wallet.Your current balance is the total preferred currency')}</p>
            </InformationModal>
          )}
        />
      )}
      <FormTemplate title={title || t('Wallet.Transfer')} goBack={handleExitPageConfirmationOpen}>
        <Form className={styles.wrapper} onSubmit={handleSubmitForm} autoComplete='off'>
          {description && <p className={styles.info}>{description}</p>}
          {
            <TransferFromField
              enabled={!isFromCampaign}
              label={transferFromLabel || t('Wallet.Transfer From')}
              setTransferFromModal={openTransferFromModal}
              value={transferFrom.id && getTransferName(true, transferFrom)}
              hint={
                transferFrom.id &&
                `${t('Wallet.Balance')} ${formatMoney(
                  getBalance(transferFrom),
                  transferFrom.currency?.id
                )} `
              }
              rightIcon={<DropArrowDownIcon />}
            />
          }
          <div className={styles.amountWrapper}>
            <div className={styles.first}>
              <FormNumericField
                name='fromAmount'
                label={t('Wallet.Sending Amount')}
                placeholder={t('Wallet.Sending Amount')}
                disabled={!transferFrom.id}
                required
                max={getBalance(transferFrom)}
                step={values.minAmount}
              />
            </div>
            <div className={styles.last}>
              <FormField
                name='transferFrom.currency.name'
                label={t('Wallet.Currency')}
                placeholder={t('Wallet.Currency')}
                className={styles.last}
                value={transferFrom && transferFrom.currency?.id}
                disabled
              />
            </div>
          </div>

          <PercentageAmountChip
            currentAmount={fromAmount}
            walletBalance={walletBalance}
            onAmountChange={(walletBalance) =>
              setFieldValue('fromAmount', walletBalance.toFixed(2))
            }
          />

          <FormField
            wrapperClassname={styles.noMargin}
            name={'transferTo.id'}
            label={t('Wallet.Transfer To')}
            placeholder={t('Wallet.Transfer To')}
            onClick={handleSetTransferToModalOpen}
            value={
              transferTo?.walletType?.id === 10
                ? transferTo.id && getTransferName(true, transferTo)
                : transferTo.id && getTransferName(false, transferTo)
            }
            hint={
              transferTo.id &&
              `${t('Wallet.Balance')} ${formatMoney(
                getBalance(transferTo),
                transferTo.currency?.id
              )}`
            }
            rightIcon={<DropArrowDownIcon />}
            disabled={!transferFrom.id || isFromCampaign}
            readOnly
            required
          />

          {transferFrom.id &&
            transferTo.id &&
            transferFrom.currency?.id !== transferTo.currency?.id && (
              <div>
                <div className={styles.amountWrapper}>
                  <div className={styles.first}>
                    <FormField
                      name='transferTo.currency.name'
                      label={t('Wallet.Receiving amount')}
                      autoComplete='false'
                      min={0}
                      onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                      value={getReceivingAmount(fromAmount)}
                      required
                      disabled
                    />
                  </div>
                  <div className={styles.last}>
                    <FormField
                      name='transferTo.currency.id'
                      label={t('Currency')}
                      disabled
                      className={styles.last}
                      value={transferTo?.currency?.id}
                    />
                  </div>
                </div>

                {transferFrom.currency?.id && transferTo.currency?.id && (
                  <div className='mt-4'>
                    <ExchangeRateBox
                      currencyFrom={transferFrom.currency?.id}
                      currencyTo={transferTo.currency?.id}
                      calculatedAmount={getReceivingAmount(1, 5)}
                      infoIconClick={() => setExchangeRateModal(true)}
                    />
                  </div>
                )}
              </div>
            )}
          <div className={styles.action}>
            <Button
              appearance='secondary'
              type='button'
              onClick={handleExitPageConfirmationOpen}
              size='L'
            >
              {t('Cancel')}
            </Button>
            <Button disabled={!isValid} type='submit' appearance='primary' size='L'>
              {t('Confirm')}
            </Button>
          </div>
        </Form>
      </FormTemplate>
    </React.Fragment>
  )
}

export const isWallet = (taOrWallet: WalletOrTradingAccountType): boolean => {
  return !('wallet' in taOrWallet)
}

export const TransferForm = withFormik<OuterProps, TransferValues>({
  mapPropsToValues: () => {
    const minAmount = 0.01
    return {
      transferFrom: {
        id: '',
        currency: undefined,
        balance: 0.0,
        wallet: undefined,
        platformOverview: undefined,
      },
      transferTo: {
        id: '',
        currency: undefined,
        balance: 0.0,
        wallet: undefined,
        platformOverview: undefined,
      },
      fromAmount: 0.0,
      minAmount,
    }
  },
  handleSubmit: async (values, { props }) => {
    props.handleFormSubmit(values)
  },
  validate: (values, { walletTypeFrom }) => {
    const errors: FormikErrors<TransferValues> = {}

    if (!values.transferFrom.id) {
      errors.transferFrom = {
        id: t('Validation.Required'),
      }
    }
    if (values.fromAmount <= 0) {
      errors.fromAmount = t('Validation.Amount should be greater than 0.00')
    }
    if (isValidMinAmount(values)) {
      errors.fromAmount = t('Validation.Minimum amount is {{amount}} {{currency}}', {
        currency: values.transferFrom.currency?.id,
        amount: values?.minAmount,
      })
    }

    if (!values.transferTo.id) {
      errors.transferTo = {
        id: t('Validation.Required'),
      }
    }

    if (walletTypeFrom === WalletTypeEnum.IB && (values.fromAmount < 50 || !values.fromAmount)) {
      errors.fromAmount = t(`Validation.Amount cannot be below allowed minimum of`, {
        amount: `50.00 ${values.transferFrom.currency?.id || 'USD'}`,
      })
    }

    return errors
  },
  enableReinitialize: true,
  validateOnMount: true,
})(TransferFormUI)

const isValidMinAmount = (values: TransferValues): boolean => {
  const minAmount = values?.minAmount
  const amount = values?.fromAmount || 0

  return amount < minAmount
}

interface TransferFromFieldProps {
  enabled: boolean
  label: string
  value: string | number | undefined
  hint: string
  rightIcon: React.ReactNode

  setTransferFromModal: () => void
}

const TransferFromField: React.FC<TransferFromFieldProps> = ({
  enabled,
  label,
  value,
  hint,
  rightIcon,
  setTransferFromModal,
}) => {
  return (
    <FormField
      wrapperClassname={styles.noMargin}
      name={'transferFrom.name'}
      label={label}
      placeholder={label}
      onClick={!enabled ? undefined : setTransferFromModal}
      value={value}
      hint={hint}
      rightIcon={rightIcon}
      readOnly
      required
      disabled={!enabled}
    />
  )
}
