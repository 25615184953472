import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { Button } from '../../global/button/Button'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { PlaceholderIcon } from '../../icons/PlaceholderIcon'
import { WarningIcon } from '../../icons/WarningIcon'
import { WalletRestrictions, isRestricted, isWalletSuspended } from '../../utils/wallet.utils'
import { PaymentAgentWalletMappedDto } from './PaymentAgentWalletsPage'

import styles from './PaymentAgentWalletsCard.module.scss'

interface Props {
  wallet: PaymentAgentWalletMappedDto
  setModalVisible(v: boolean): void
}

export const PaymentAgentWalletsCard: React.FC<Props> = (props) => {
  const { wallet, setModalVisible } = props

  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()

  const handleRestrictionModalOpen = () => {
    const hasAllRestrictions = isWalletSuspended(wallet.restrictions)
    if (!hasAllRestrictions) {
      setModalVisible(true)
    }
  }

  const hasAllRestrictions = isWalletSuspended(wallet.restrictions)
  const isTransferFromRestricted = isRestricted(
    WalletRestrictions.TRANSFER_FROM,
    wallet.restrictions
  )
  const isWithdrawRestricted = isRestricted(WalletRestrictions.WITHDRAW, wallet.restrictions)
  const isDepositRestricted = isRestricted(WalletRestrictions.DEPOSIT, wallet.restrictions)

  return (
    <div className={classNames('card', styles.card)}>
      <div className={styles.container}>
        <div className={styles.card} key={wallet.id}>
          <td>
            {hasAllRestrictions && <WarningIcon size={20} color={'error'} />}

            {t(`Wallet.PA Currency Wallet`, {
              walletCurrencyId: wallet.currency.id,
            })}
          </td>
          <h2>{formatMoney(wallet.balance, wallet.currency.id)}</h2>
          <td>
            <span className={styles.reserved}>{t('Wallet.Reserved')}</span>
            {formatMoney(wallet.reserved, wallet.currency.id)}
          </td>
          <div className={styles.btn}>
            {isDepositRestricted && (
              <Button
                size='M'
                appearance='secondary'
                state='disabled'
                renderRightIcon={() => !hasAllRestrictions && <PlaceholderIcon color={'error'} />}
                onClick={handleRestrictionModalOpen}
                className={styles.button}
              >
                {t('Deposit')}
              </Button>
            )}
            {!isDepositRestricted && (
              <Link to={`/dashboard/payment-agent/wallets/${wallet.id}/deposit`}>
                <Button size='M' appearance='secondary' className={styles.button}>
                  {t('Deposit')}
                </Button>
              </Link>
            )}
          </div>
          <div className={styles.btn}>
            {isTransferFromRestricted && (
              <Button
                size='M'
                appearance='primary'
                state='disabled'
                renderRightIcon={() => !hasAllRestrictions && <PlaceholderIcon color={'error'} />}
                onClick={handleRestrictionModalOpen}
                className={styles.button}
              >
                {t('Transfer')}
              </Button>
            )}
            {!isTransferFromRestricted && (
              <Link to={`/dashboard/payment-agent/wallets/${wallet.id}/transfer`}>
                <Button size='M' appearance='primary' className={styles.button}>
                  {t('Transfer')}
                </Button>
              </Link>
            )}
          </div>
          <div className={styles.btn}>
            {isWithdrawRestricted && (
              <Button
                size='M'
                appearance='secondary'
                state='disabled'
                renderRightIcon={() => !hasAllRestrictions && <PlaceholderIcon color={'error'} />}
                onClick={handleRestrictionModalOpen}
                className={styles.button}
              >
                {t('Withdraw')}
              </Button>
            )}
            {!isWithdrawRestricted && (
              <Link to={`/dashboard/payment-agent/wallets/${wallet.id}/withdrawal`}>
                <Button size='M' appearance='secondary' className={styles.button}>
                  {t('Withdraw')}
                </Button>
              </Link>
            )}
          </div>
          <td className='mb-3 mt-3'>
            <Link
              className={classNames(styles.link, 'is-link is-right')}
              to='/dashboard/payment-agent/transaction-history'
              state={{ walletId: wallet.id }}
            >
              {t('View Transaction History')}
            </Link>
          </td>
        </div>
      </div>
    </div>
  )
}
