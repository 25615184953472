import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import classNames from 'classnames'

import { Loading } from '../global/Loading/Loading'
import { ActionIcon } from '../global/actionIcon/ActionIcon'
import { Button } from '../global/button/Button'
import { useSessionLanguage } from '../global/context/SessionSettingsContext'
import { WalletModal, WalletModalItem } from '../global/modal/WalletModal'
import { FormTemplate } from '../global/templates/FormTemplate'
import { useFormatNumber } from '../hooks/useFormatNumber'
import { CircleForwardIcon } from '../icons/CircleForwardIcon'
import { DropArrowDownIcon } from '../icons/DropArrowDownIcon'
import { TickmillBackgroundIcon } from '../icons/TickmillBackgroundIcon'
import { WalletIcon } from '../icons/WalletIcon'
import PSPLogo from '../images/online-banking-icon-48x48px.png'
import { WalletDto, WalletTypeEnum } from '../model/WalletDto'
import { WalletPaymentProvider } from '../model/WalletPaymentProvider'
import { TextSmall, TextStrong, TextTiny } from '../ui/Typography/Typography'
import { useApiClient } from '../utils/ApiClient'
import { ClientApiClient } from '../utils/clientApi'
import { useWindowResize } from '../utils/domUtils'
import { useFetchOne } from '../utils/useFetch'
import { isOne } from '../utils/validations'
import { WalletRestrictions } from '../utils/wallet.utils'

import styles from './FirstDepositPage.module.scss'

export const FirstDepositPage: FC<{ walletType: WalletTypeEnum }> = ({ walletType }) => {
  const locale = useSessionLanguage()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const apiClient = useApiClient(ClientApiClient)
  const [walletModalOption, setWalletModalOption] = useState<WalletDto | undefined>()
  const [selectedWallet, setSelectedWallet] = useState<WalletDto | undefined>()
  const isMobile = useWindowResize()

  const walletsCallback = useCallback(
    () => apiClient.getWallets({ walletTypeId: walletType }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  )
  const { data: wallets = [], isLoading: isWalletsLoading } = useFetchOne(walletsCallback)

  const defaultWallet = wallets.find((wallet) => wallet.isDefault) || wallets[0]

  useEffect(() => {
    if (!selectedWallet) {
      setSelectedWallet(defaultWallet)
    }
  }, [selectedWallet, defaultWallet])

  const depositPage = useMemo(
    () => {
      const walletCount = wallets.length
      if (walletCount < 1) {
        return '/dashboard/traders-room/wallets/deposit'
      } else if (isOne(walletCount)) {
        return `/dashboard/traders-room/wallets/${defaultWallet?.id}/deposit`
      }
      return `/dashboard/traders-room/wallets/${selectedWallet?.id}/deposit`
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultWallet, wallets, selectedWallet]
  )

  const walletDepositMethodsCallback = useCallback(async () => {
    if (selectedWallet) {
      return apiClient.getWalletDepositMethods(locale, selectedWallet?.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWallet, locale])

  const { data: walletPaymentProviders = [], isLoading: isWalletDepositMethodsLoading } =
    useFetchOne(walletDepositMethodsCallback)

  const setHasDismissedFirstDepositPrompt = () =>
    localStorage.setItem('hasDismissedFirstDepositPrompt', 'true')

  const handleGoBack = () => {
    setHasDismissedFirstDepositPrompt()
    navigate('dashboard/traders-room/wallets')
  }

  const closeWalletModal = () => {
    setWalletModalOption(undefined)
  }

  const handleWalletSelection = (wallet?: WalletDto) => {
    setSelectedWallet(wallet)
    setWalletModalOption(undefined)
  }

  const handleSelectOption = (wallet?: WalletDto) => {
    setWalletModalOption(wallet)
  }

  const WalletButton = () =>
    selectedWallet ? (
      <Button
        type='button'
        onClick={() => wallets?.length > 1 && setWalletModalOption(selectedWallet)}
        renderLeftIcon={() => <WalletIcon color='white' />}
        renderRightIcon={
          wallets?.length > 1 ? () => <DropArrowDownIcon color='white' /> : undefined
        }
        appearance='primary'
        className={styles.walletActionButton}
      >
        {`${selectedWallet.name}`}
      </Button>
    ) : null

  return (
    <Loading isLoading={isWalletsLoading} showLoadingIcon>
      {walletModalOption && (
        <WalletModal
          title={t('Choose Wallet')}
          render={({ items }) =>
            items.map((x) => (
              <WalletModalItem
                item={x}
                restrictionType={WalletRestrictions.DEPOSIT}
                selected={x?.id === walletModalOption?.id}
                onSelectOption={handleSelectOption}
                key={x.id}
                hideHint
              />
            ))
          }
          onClose={closeWalletModal}
          onConfirm={() => handleWalletSelection(walletModalOption)}
        />
      )}

      <FormTemplate
        title={t('Ready to Start Trading')}
        goBack={handleGoBack}
        contentClassName={styles.content}
        renderIcon={wallets?.length && selectedWallet?.name && !isMobile && <WalletButton />}
        subtitle={isMobile && <WalletButton />}
      >
        <div className={styles.info}>
          <TextSmall className={styles.semiTransparent}>
            {t(
              'Step into the world of trading by funding your wallet. We offer a range of options to select the one that suits you best. Make your first deposit today and start navigating the financial markets'
            )}
          </TextSmall>
        </div>

        <Loading isLoading={isWalletDepositMethodsLoading} showLoadingIcon>
          <div className='columns is-multiline is-flex is-variable is-2 my-4'>
            {walletPaymentProviders?.map((walletPaymentProvider) => (
              <DepositMethod
                onSetHasDismissedFirstDepositPrompt={setHasDismissedFirstDepositPrompt}
                walletPaymentProvider={walletPaymentProvider}
                depositPage={depositPage}
                key={walletPaymentProvider.method.id}
              />
            ))}
          </div>
        </Loading>
      </FormTemplate>
      <div className='is-hidden-mobile'>
        <div className={classNames(styles.backgroundIcon, styles.activatedBackgroundIconLeft)}>
          <TickmillBackgroundIcon />
        </div>
        <div className={classNames(styles.backgroundIcon, styles.activatedBackgroundIconRight)}>
          <TickmillBackgroundIcon />
        </div>
      </div>
    </Loading>
  )
}

interface DepositMethodProps {
  walletPaymentProvider: WalletPaymentProvider
  depositPage: string
  onSetHasDismissedFirstDepositPrompt(isDismissed: boolean): void
}

const DepositMethod: FC<DepositMethodProps> = ({
  walletPaymentProvider,
  depositPage,
  onSetHasDismissedFirstDepositPrompt,
}) => {
  const { formatMoney } = useFormatNumber()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isHovered, setIsHovered] = useState(false)

  const handleClick = () => {
    onSetHasDismissedFirstDepositPrompt(true)
    navigate(depositPage, { state: { walletPaymentProvider } })
  }

  const handleHover = () => {
    setIsHovered((prevState) => !prevState)
  }

  return (
    <div
      className='column is-flex is-full-mobile is-one-third-tablet mt-4 py-0'
      onMouseOver={handleHover}
      onMouseOut={handleHover}
    >
      <div className={classNames('has-cursor-pointer', styles.depositMethod)} onClick={handleClick}>
        <div className='is-flex is-align-items-center mb-2'>
          <div className={classNames(styles.logoWrapper)}>
            <img src={walletPaymentProvider.logo || PSPLogo} alt={walletPaymentProvider.name} />
          </div>

          <TextStrong>{walletPaymentProvider.description}</TextStrong>
        </div>
        <div>
          <TextTiny>
            {t('Processing Time')} {walletPaymentProvider.fundingTime}
          </TextTiny>
          <br />
          <TextTiny>
            {t('Fee')} {formatMoney(walletPaymentProvider.fee, walletPaymentProvider.currency.id)}
          </TextTiny>
          <br />
          <TextTiny>
            {t('Wallet.Minimum deposit')}{' '}
            {formatMoney(walletPaymentProvider.minAmount, walletPaymentProvider.currency.id)}
          </TextTiny>
          <br />
          {walletPaymentProvider.regulator && (
            <TextTiny>{walletPaymentProvider.regulator}</TextTiny>
          )}
        </div>

        <div className='has-text-right mt-2'>
          <ActionIcon
            className='p-0'
            onClick={handleClick}
            renderOnClick={() => <CircleForwardIcon color='text' />}
          >
            {isHovered ? (
              <CircleForwardIcon inverse secondaryColor='text' />
            ) : (
              <CircleForwardIcon />
            )}
          </ActionIcon>
        </div>
      </div>
    </div>
  )
}
