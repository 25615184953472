import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { v4 as uuid } from 'uuid'

import { Button } from '../../global/button/Button'
import { useArabicSessionLanguage } from '../../global/context/SessionSettingsContext'
import { createFormField } from '../../global/formField/FormField'
import { Modal } from '../../global/modal/Modal'
import { PhoneCodeSelectModal } from '../../global/modal/PhoneCodeModal'
import { BackIcon } from '../../icons/BackIcon'
import { DropArrowDownIcon } from '../../icons/DropArrowDownIcon'
import { ForwardIcon } from '../../icons/ForwardIcon'
import { CountryPreferenceStrippedDto } from '../../model/CountryDto'
import { CountryCodeFlagIcon } from '../../ui/CountryCodeFlagIcon'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useWindowResize } from '../../utils/domUtils'
import { preventPaste } from '../../utils/input.utils'
import { useScrollToTop } from '../../utils/useScrollToTop'
import { FormValuesStep3 } from './PersonalInfoStep3Page/PersonalInfoStep3Form'

import styles from './PersonalInfoPage.module.scss'

interface UpdatePhoneFormProps {
  countryCode: string
  countries: CountryPreferenceStrippedDto[]

  onSubmit(phoneNumber: string, countryCode: string): Promise<void>
  onCancel(): void
}

const FormField = createFormField<FormValuesStep3>()

export const UpdatePhoneForm: React.FC<UpdatePhoneFormProps> = (props) => {
  const { countryCode, onSubmit, onCancel, countries } = props

  useScrollToTop()

  const { t } = useTranslation()
  const isMobile = useWindowResize()

  const apiClient = useApiClient(ClientApiClient)
  const isArabic = useArabicSessionLanguage()
  const delayDebounceFn = useRef<NodeJS.Timeout>()
  const { setFieldValue, values } = useFormikContext<FormValuesStep3>()

  const [isCountryModalOpen, setIsCountryModelOpen] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState<CountryPreferenceStrippedDto>()
  const [phoneNumberError, setPhoneNumberError] = useState<string>()
  const [isValid, setIsValid] = useState(false)

  const handleOnChangeOption = (country: CountryPreferenceStrippedDto) => {
    setIsValid(false)
    setFieldValue('countryCode', country.phoneCode)
    setSelectedCountry(country)
    setIsCountryModelOpen(false)
    validatePhoneNumber(values.phoneNumber, country.phoneCode)
  }

  useEffect(() => {
    setFieldValue('countryCode', countryCode)
    setSelectedCountry(countries.find((c) => c.phoneCode === countryCode))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries, countryCode])

  const validatePhoneNumber = async (phoneNumber: string, countryCode?: string) => {
    try {
      await apiClient.validatePhoneNumber(phoneNumber, countryCode || values.countryCode)
      setPhoneNumberError(undefined)
      setIsValid(true)
    } catch {
      setPhoneNumberError(t('Sign up.Please check your phone number'))
      setIsValid(false)
    }
  }

  const handleChangePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsValid(false)
    setFieldValue('phoneNumber', e.target.value)
    if (!e.target.value) {
      setIsValid(false)
      setPhoneNumberError(undefined)
      clearTimeout(delayDebounceFn.current)
      return
    }
    clearTimeout(delayDebounceFn.current)
    delayDebounceFn.current = setTimeout(async () => validatePhoneNumber(e.target.value), 1000)
  }

  return (
    <div className={styles.updatePhone}>
      {isCountryModalOpen && (
        <Modal
          cardClassName={styles.modal}
          closeModal={() => setIsCountryModelOpen(false)}
          render={() => (
            <PhoneCodeSelectModal
              title={t('Sign up.Phone Code')}
              options={countries}
              onCancel={() => setIsCountryModelOpen(false)}
              handleOnChangeOption={handleOnChangeOption}
              selectedCountry={values.countryCode}
            />
          )}
        />
      )}
      <h3>{t('Sign up.Phone number')}</h3>
      <div className={styles.phoneWrapper}>
        <FormField
          className={styles.countryCode}
          label={t('Sign up.Country Code')}
          placeholder={t('Sign up.Country Code')}
          value={values.countryCode || countryCode}
          autoComplete={`countryCode-${uuid()}`}
          leftIcon={<CountryCodeFlagIcon countries={countries} selectedCountry={selectedCountry} />}
          onClick={() => setIsCountryModelOpen(true)}
          rightIcon={<DropArrowDownIcon />}
          required
        />
        <div className={styles.phoneNumberWrapper}>
          <FormField
            wrapperClassname={styles.phoneNumberWrapper}
            className={styles.phoneNumber}
            name='phoneNumber'
            label={t('Sign up.Mobile Number')}
            onChange={handleChangePhoneNumber}
            type='tel'
            min='0'
            autoComplete={`phoneNumber-${uuid()}`}
            required
            showLabel
            onPaste={preventPaste}
            onKeyDown={(event) =>
              ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault()
            }
          />
          {!!phoneNumberError && (
            <div className='help is-danger has-text-weight-bold mb-3 ml-1'>{phoneNumberError}</div>
          )}
        </div>
      </div>
      <div className='is-flex is-flex-wrap-wrap'>
        {isMobile ? (
          <div className='is-flex-grow-1 is-flex-direction-column'>
            <span className='is-flex ml-1 mb-2 is-flex-grow-1'>
              <SubmitButton onSubmit={onSubmit} isValid={isValid} values={values} />
            </span>
            <span className='is-flex mr-1 mb-2 is-flex-grow-1'>
              <CancelButton onCancel={onCancel} />
            </span>
          </div>
        ) : (
          <div className='is-flex is-flex-grow-1'>
            <span className='is-flex mr-1 mb-2 is-flex-grow-1'>
              <CancelButton onCancel={onCancel} />
            </span>
            <span className='is-flex ml-1 mb-2 is-flex-grow-1'>
              <SubmitButton onSubmit={onSubmit} isValid={isValid} values={values} />
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

interface SubmitButtonProps {
  onSubmit(phoneNumber: string, countryCode: string): Promise<void>
  isValid: boolean
  values: FormValuesStep3
}

const SubmitButton: React.FC<SubmitButtonProps> = (props) => {
  const { onSubmit, isValid, values } = props

  const { t } = useTranslation()
  const isArabic = useArabicSessionLanguage()

  return (
    <Button
      renderRightIcon={() => (
        <span className='is-flex is-align-items-center'>
          {isArabic ? <BackIcon inverse /> : <ForwardIcon inverse />}
        </span>
      )}
      size='L'
      disabled={!isValid}
      appearance='primary'
      onClick={() => onSubmit(values.phoneNumber, values.countryCode)}
      className='is-flex-grow-1'
    >
      {t('Next')}
    </Button>
  )
}

const CancelButton: React.FC<{
  onCancel(): void
}> = (props) => {
  const { onCancel } = props

  const { t } = useTranslation()

  return (
    <Button size='L' appearance='secondary' onClick={onCancel} className='is-flex-grow-1'>
      {t('Cancel')}
    </Button>
  )
}
