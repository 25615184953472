import { ReactNode, createContext, useEffect, useMemo, useState } from 'react'

import { Locale } from '../global/locale/Locale'
import { useGuardedContext } from '../global/useGuardedContext'
import { AccountDetailedDto } from '../model/AccountDetailedDto'
import { useApiClient } from './ApiClient'
import { ClientApiClient } from './clientApi'
import { JSONParse } from './json'

interface AccountReadContextImpl {
  account?: AccountDetailedDto
}

interface AccountWriteContextImpl {
  refreshAccount: (locale: Locale) => Promise<void>
  clearAccount: () => void
}

const AccountReadContext = createContext<AccountReadContextImpl | undefined>(undefined)
AccountReadContext.displayName = 'AccountReadContext'

const AccountWriteContext = createContext<AccountWriteContextImpl | undefined>(undefined)
AccountWriteContext.displayName = 'AccountWriteContext'

export function useAccountReadContext(): AccountReadContextImpl {
  return useGuardedContext(AccountReadContext)
}

export function useAccountWriteContext(): AccountWriteContextImpl {
  return useGuardedContext(AccountWriteContext)
}

interface Props {
  account?: AccountDetailedDto
  children: ReactNode
}

export function AccountContextProvider({ account, children }: Props): React.ReactElement {
  const [accountState, setAccountState] = useState(account)

  useEffect(() => {
    setAccountState(account)
  }, [account])

  const apiClient = useApiClient(ClientApiClient)

  const readContext = useMemo<AccountReadContextImpl>(
    () => ({
      account: accountState,
    }),
    [accountState]
  )

  const writeContext = useMemo<AccountWriteContextImpl>(() => {
    return {
      async refreshAccount(locale) {
        try {
          const accountWithAppTests = await apiClient.getAccountWithAppTestResults(locale)
          localStorage.setItem('account', JSON.stringify(accountWithAppTests))
          setAccountState(accountWithAppTests)
        } catch (e: unknown) {
          console.error('Error during refreshAccount', e)
        }
      },
      clearAccount() {
        localStorage.removeItem('account')
        setAccountState(undefined)
      },
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountState])

  return (
    <AccountReadContext.Provider value={readContext}>
      <AccountWriteContext.Provider value={writeContext}>{children}</AccountWriteContext.Provider>
    </AccountReadContext.Provider>
  )
}

export const initiateAccountContext = (): AccountDetailedDto | undefined => {
  const account = localStorage.getItem('account')
  if (account) {
    return JSONParse<AccountDetailedDto>(account)
  }
  return undefined
}
