import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useFormikContext } from 'formik'

import { CheckBox } from '../../../global/checkBox/CheckBox'
import { useSessionEntity } from '../../../global/context/EntityContext'
import { DocumentFileUploads } from '../../../global/fileUpload/DocumentFileUploads'
import { FileData } from '../../../global/fileUpload/FileUpload'
import { FieldGroup } from '../../../global/form/FieldGroup'
import { createFormField } from '../../../global/formField/FormField'
import { createFormSelectField } from '../../../global/formField/FormSelectField'
import { AccountDetailedDto } from '../../../model/AccountDetailedDto'
import { DocumentCategoryType } from '../../../model/DocumentCategories'
import { NameDto } from '../../../model/NameDto'
import { isTickmillSCType } from '../../../utils/companyName.utils'
import { TaxIdUnavailableReasons } from './TaxIdUnavailableReasons'
import { YearlyKycUpdateDetailsFormValues } from './YearlyKycUpdateDetailsForm'

import styles from './YearlyKycPersonalInfoForm.module.scss'

const FormField = createFormField<YearlyKycUpdateDetailsFormValues>()
const FormSelectField = createFormSelectField<YearlyKycUpdateDetailsFormValues>()

interface YearlyKycPersonalInfoFormProps {
  account: AccountDetailedDto | undefined
  proofOfAddressNeeded: () => boolean
  tinUnavailableReasons: NameDto[]
  productRegistrationRequirement?: boolean
}

export const YearlyKycPersonalInfoForm: React.FC<YearlyKycPersonalInfoFormProps> = (props) => {
  const entity = useSessionEntity()
  const { account, proofOfAddressNeeded, tinUnavailableReasons } = props
  const { values, setValues, setFieldValue } = useFormikContext<YearlyKycUpdateDetailsFormValues>()

  const [hasTaxUnavailableReason, setHasTaxUnavailableReason] = useState<boolean>(true)
  const { t } = useTranslation()

  const handleDocumentUploads = async (fileData: FileData[]) => {
    setValues({
      ...values,
      documents: fileData,
    })
  }

  useEffect(() => {
    if (values.taxIdentificationNumber) {
      setHasTaxUnavailableReason(false)
      setFieldValue('taxIdUnavailableReasonId', null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.taxIdentificationNumber])

  return (
    <React.Fragment>
      <div className={classNames('pb-4', styles.box)}>
        <h3>{t('Profile.Personal Info')}</h3>

        <FieldGroup>
          <FormSelectField
            name='countryCode'
            label={t('Profile.Country Code')}
            placeholder={t('Profile.Country Code')}
            required
          >
            {values.countries
              .map((x) => x.phoneCode)
              .filter((item, pos, self) => self.indexOf(item) === pos)
              .sort()
              .map((phoneCode) => (
                <option value={phoneCode} key={phoneCode}>
                  {phoneCode}
                </option>
              ))}
          </FormSelectField>
          <FormField
            name='phoneNumber'
            label={t('PhoneNumbers.Phone Number')}
            placeholder={t('PhoneNumbers.Phone Number')}
            required
          />
        </FieldGroup>

        <FormField
          name='emailAddress'
          label={t('Profile.Email Address')}
          placeholder={t('Profile.Email Address')}
          disabled
          required
        />

        <FormField
          name='countryName'
          label={t('Profile.Country of Residence')}
          placeholder={t('Profile.Country of Residence')}
          disabled
          required
        />

        <FormField
          name='street'
          label={t('Profile.Address line')}
          placeholder={t('Profile.Address line')}
          required
        />

        <FormField name='city' label={t('Profile.City')} placeholder={t('Profile.City')} required />

        <FieldGroup>
          <FormField
            name='state'
            label={t('Profile.State / Region')}
            placeholder={t('Profile.State / Region')}
            required
          />
          <FormField
            name='zipCode'
            label={t('Profile.Postal Code')}
            placeholder={t('Profile.Postal Code')}
            required
          />
        </FieldGroup>

        {!isTickmillSCType(entity) && (
          <>
            <FormField
              name='taxIdentificationNumber'
              label={t('Profile.Tax Identification Number / Goods & Services Tax Number')}
              placeholder={t('Profile.Tax Identification Number / Goods & Services Tax Number')}
            />
            {!values.taxIdentificationNumber && (
              <div className='mt-5'>
                <CheckBox
                  value={hasTaxUnavailableReason}
                  label={t('Profile.Not Available')}
                  onChange={() => setHasTaxUnavailableReason((prev) => !prev)}
                />
              </div>
            )}
            {hasTaxUnavailableReason && <TaxIdUnavailableReasons reasons={tinUnavailableReasons} />}
          </>
        )}
      </div>

      {account?.isKYCUpdateNeeded && proofOfAddressNeeded() && (
        <div>
          <h4 className={styles.textStrong}>{t('Profile.Proof Of Address')}</h4>
          <p className='content'>
            {t('The image must be high quality, unobstructed and show the full document page.')}
          </p>
          <DocumentFileUploads
            documentCategory={DocumentCategoryType.Address}
            onChange={handleDocumentUploads}
          />
        </div>
      )}
    </React.Fragment>
  )
}
