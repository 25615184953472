import { useEffect } from 'react'

type ScrollToTopProps = number | string | Object | boolean | []

export const useScrollToTop = (effect?: ScrollToTopProps) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [effect])

  return null
}
