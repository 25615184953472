import { TFunction } from 'i18next'

import { NameDto } from '../model/NameDto'

export const normalizeSortLabel = (
  t: TFunction,
  data: NameDto<string>[],
  labelId?: string | null
) => {
  if (!labelId) return `${t('SortBy')}`
  const dataLabel = data.find((x) => x.id === labelId)?.name
  return `${t('SortBy')}: ${dataLabel}`
}
