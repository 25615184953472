import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { TickmillLoadingIcon } from '../../icons/TickmillLoadingIcon'
import { LoadingProps } from './Loading'

import styles from './Loading.module.scss'

export const LoadingWrapper: React.FunctionComponent<LoadingProps> = (props) => {
  const { text, showLoadingIcon, isModal, className } = props
  const { t } = useTranslation()

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.isModal]: isModal,
      })}
    >
      {showLoadingIcon && (
        <div className={styles.icon}>
          <TickmillLoadingIcon />
        </div>
      )}
      <p className={className}>{text ?? t('Loading...')}</p>
    </div>
  )
}
