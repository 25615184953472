import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { INITIAL_COUNTER_SECONDS } from '../../SignUp/PersonalInfo/PersonalInfoStepMobileValidation'
import { Loading } from '../../global/Loading/Loading'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { Modal } from '../../global/modal/Modal'
import { ToastContext, errorToast, successToast } from '../../global/toast/Toast'
import { AccountDetailedDto, ClientPhoneNumberDto } from '../../model/AccountDetailedDto'
import { AccountType } from '../../model/AccountType'
import { isAccountActivatedStatus } from '../../model/BankAccount'
import { PhoneVerificationWorkflowEnum } from '../../model/CreatePhoneNumberVerification'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { Text } from '../../ui/Typography/Typography'
import { useAccountReadContext, useAccountWriteContext } from '../../utils/AccountContextContext'
import { ResponseError, useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useWindowResize } from '../../utils/domUtils'
import { useFetchOne } from '../../utils/useFetch'
import { PhoneNumberAddFormValues } from './Modals/PhoneNumberAddForm'
import { PhoneNumberAddModal } from './Modals/PhoneNumberAddModal'
import { PhoneNumberDeleteModal } from './Modals/PhoneNumberDeleteModal'
import { PhoneNumberVerifyModal } from './Modals/PhoneNumberVerifyModal'
import { PhoneNumbersCard } from './PhoneNumbersCard'
import { PhoneNumbersTable } from './PhoneNumbersTable'

export const filterIndividualPhoneNumbers = (account: AccountDetailedDto): ClientPhoneNumberDto[] =>
  account.phoneNumbers.filter(
    (phoneNumber) => account.type.id === AccountType.Individual || phoneNumber.type === 'Personal'
  )

export const PhoneNumbersPage: React.FC = () => {
  const { t } = useTranslation()
  const isMobile = useWindowResize()
  const apiClient = useApiClient(ClientApiClient)
  const setToast = useContext(ToastContext)
  const [isLoading, setIsLoading] = useState(false)

  const locale = useSessionLanguage()
  const { account } = useAccountReadContext()
  const { refreshAccount } = useAccountWriteContext()

  const [addModalVisible, setAddModalVisible] = useState(false)
  const [requestId, setRequestId] = useState<string>()
  const [numberToVerify, setNumberToVerify] = useState<ClientPhoneNumberDto>()
  const [numberToDelete, setNumberToDelete] = useState<ClientPhoneNumberDto>()

  const [verificationError, setVerificationError] = useState<string>()
  const [verificationCodeError, setVerificationCodeError] = useState<string>()
  const [verificationSuccess, setVerificationSuccess] = useState(false)

  const [verifyCounter, setVerifyCounter] = useState(INITIAL_COUNTER_SECONDS)

  const individualPhoneNumbers = useMemo(
    () => (account ? filterIndividualPhoneNumbers(account) : []),
    [account]
  )

  const handleCloseVerifyModal = () => {
    setNumberToVerify(undefined)
    setRequestId(undefined)
    setVerificationError(undefined)
    setVerificationCodeError(undefined)
    setVerificationSuccess(false)
  }

  const handlePhoneError = (error: ResponseError) => {
    const errorCode = error?.response?.response?.data.code
    if (errorCode === 'phone_number_cancellation_failed') {
      handlePhoneNumberCancelError(error)
    } else if (errorCode === 'phone_verification_check_failed') {
      setVerificationError(t('Sign up.Wrong code/Phone Verification failed'))
      setVerificationCodeError(errorCode)
      setTimeout(() => setVerificationError(undefined), 5000)
    } else if (
      errorCode === 'max_new_verification_code_requests_reached' ||
      errorCode === 'phone_verification_max_attempts_reached'
    ) {
      // We refresh account because we get the phone numbers from the account
      setVerificationError(t('Sign up.Wrong code/Phone Verification failed'))
      setVerificationCodeError(errorCode)
      setTimeout(() => {
        refreshAccount(locale)
        handleCloseVerifyModal()
        setToast(errorToast(t("errors.You've reached the maximum verification attempts")))
      }, 1000)
    } else {
      setVerificationError(t('errors.Phone verification check has failed'))
      setVerificationCodeError(errorCode)
      setTimeout(() => setVerificationError(undefined), 5000)
    }
  }

  const handlePhoneNumberCancelError = (error?: ResponseError) => {
    const errorCode = error?.response?.response?.data.code
    setVerificationError(t('errors.Phone verification check has failed'))
    setVerificationCodeError(errorCode)
    setVerifyCounter(INITIAL_COUNTER_SECONDS)
    setTimeout(() => setVerificationError(undefined), 5000)
  }

  const resendCode = async (phoneNumber: string, phoneNumberId: string) => {
    if (!requestId) return
    try {
      const phoneVerificationId = await apiClient.resendPhoneNumberVerification(
        requestId,
        phoneNumberId,
        PhoneVerificationWorkflowEnum.WorkflowSMS
      )
      setVerifyCounter(INITIAL_COUNTER_SECONDS)
      setRequestId(phoneVerificationId.phoneVerificationId)
      setToast(successToast(t('Sign up.Code was sent', { phoneNumber })))
    } catch (error: unknown) {
      handlePhoneError(error as ResponseError)
    }
  }

  const onReceivePhoneCall = async (phoneNumber: string, phoneNumberId: string) => {
    if (!requestId) return
    try {
      const phoneVerificationId = await apiClient.resendPhoneNumberVerification(
        requestId,
        phoneNumberId,
        PhoneVerificationWorkflowEnum.WorkFlowTTS
      )
      setRequestId(phoneVerificationId.phoneVerificationId)
      setToast(successToast(t('Sign up.Expect phone call', { phoneNumber })))
    } catch (error: unknown) {
      handlePhoneError(error as ResponseError)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const countriesCallback = useCallback(async () => await apiClient.getCountries(), [])

  const { data: countries = [] } = useFetchOne(countriesCallback)

  const deleteNumber = async (number: ClientPhoneNumberDto) => {
    try {
      setIsLoading(true)
      await apiClient.deletePhoneNumber(number.id)
      refreshAccount(locale)
      setNumberToDelete(undefined)
    } catch (e: unknown) {
      setNumberToDelete(undefined)
      setToast(errorToast(t('errors.Something went wrong! Please try again later')))
    } finally {
      setIsLoading(false)
    }
  }

  const addNumber = async (data: PhoneNumberAddFormValues) => {
    try {
      setIsLoading(true)
      setToast(undefined)
      const response = await apiClient.addPhoneNumber(data)
      setAddModalVisible(false)
      if (response.shouldVerify && isAccountActivatedStatus(account)) {
        await requestPhoneVerification(response)
      } else refreshAccount(locale)
    } catch (e: unknown) {
      setToast(errorToast(t('errors.Something went wrong! Please try again later')))
    } finally {
      setAddModalVisible(false)
      setIsLoading(false)
    }
  }

  const handleVerifyNumber = async (
    number: ClientPhoneNumberDto,
    requestId: string,
    code: string
  ) => {
    try {
      await apiClient.verifyPhoneNumber(number.id, requestId, code)
      setVerifyCounter(INITIAL_COUNTER_SECONDS)
      setVerificationSuccess(true)
      setTimeout(() => {
        setVerificationSuccess(false)
        handleCloseVerifyModal()
        refreshAccount(locale)
      }, 1000)
    } catch (error: unknown) {
      handlePhoneError(error as ResponseError)
      throw new Error()
    }
  }

  const requestPhoneVerification = async (phoneNumber: ClientPhoneNumberDto) => {
    try {
      const response = await apiClient.requestPhoneNumberVerification(phoneNumber.id)
      setVerifyCounter(INITIAL_COUNTER_SECONDS)
      setNumberToVerify(phoneNumber)
      setRequestId(response.phoneVerificationId)
      return response
    } catch (error: unknown) {
      handlePhoneError(error as ResponseError)
      refreshAccount(locale)
    }
  }

  const cancelPhoneVerification = async () => {
    if (!numberToVerify || !requestId || verifyCounter > 0) return
    try {
      await apiClient.cancelPhoneNumberVerification(numberToVerify.id, requestId)
      handleCloseVerifyModal()
      refreshAccount(locale)
    } catch (error: unknown) {
      handlePhoneError(error as ResponseError)
    }
  }

  const makeNumberPrimary = async (number: ClientPhoneNumberDto) => {
    setIsLoading(true)
    try {
      await apiClient.updatePhoneNumber(number.id, {
        ...number,
        isPrimary: true,
      })
      refreshAccount(locale)
    } catch (e: unknown) {
      setToast(errorToast(t('errors.Something went wrong! Please try again later')))
    } finally {
      setIsLoading(false)
    }
  }

  const infoMessage = useMemo(() => {
    if (!account) return ''
    if (filterIndividualPhoneNumbers(account).length >= 3) {
      return t('PhoneNumbers.Three phone numbers are allowed per account')
    }
    return ''
  }, [account, t])

  if (isLoading) {
    return <Loading isLoading={isLoading} showLoadingIcon />
  }

  return (
    <React.Fragment>
      {addModalVisible && account && (
        <Modal
          closeModal={() => setAddModalVisible(false)}
          render={({ closeModal }) => (
            <PhoneNumberAddModal
              countries={countries}
              account={account}
              onConfirm={(v) => addNumber(v)}
              onCancel={closeModal}
            />
          )}
        />
      )}
      {numberToVerify && requestId && (
        <Modal
          closeModal={() => cancelPhoneVerification()}
          render={() => (
            <PhoneNumberVerifyModal
              verifyCounter={verifyCounter}
              setVerifyCounter={setVerifyCounter}
              setVerificationError={setVerificationError}
              setVerificationCodeError={setVerificationCodeError}
              phoneNumber={numberToVerify}
              verificationError={verificationError}
              verificationCodeError={verificationCodeError}
              verificationSuccess={verificationSuccess}
              requestId={requestId}
              onReceivePhoneCall={onReceivePhoneCall}
              resendCode={resendCode}
              onVerify={handleVerifyNumber}
              onCancel={cancelPhoneVerification}
            />
          )}
        />
      )}
      {numberToDelete && (
        <Modal
          closeModal={() => setNumberToDelete(undefined)}
          render={() => (
            <PhoneNumberDeleteModal
              phoneNumber={numberToDelete}
              onConfirm={() => deleteNumber(numberToDelete)}
              onCancel={() => setNumberToDelete(undefined)}
            />
          )}
        />
      )}
      <PageHeader
        title={t('My Phone Numbers')}
        actions={[
          {
            onClick: () => setAddModalVisible(true),
            label: `+ ${t('PhoneNumbers.Add New Phone Number')}`,
            size: 'S',
            hidden: isMobile,
            tooltipText: infoMessage,
            disabled: (individualPhoneNumbers.length ?? 0) >= 3,
          },
        ]}
      />
      {!individualPhoneNumbers.length ? (
        <Text isParagraph className='text-align-center pt-4'>
          {t('No results')}
        </Text>
      ) : isMobile ? (
        <PhoneNumbersCard
          addNewNumber={() =>
            individualPhoneNumbers.length >= 3 ? null : setAddModalVisible(true)
          }
          verifyNumber={(number) => requestPhoneVerification(number)}
          deleteNumber={(number) => setNumberToDelete(number)}
          makeNumberPrimary={(number) => makeNumberPrimary(number)}
          phoneNumbers={individualPhoneNumbers}
        />
      ) : (
        <PhoneNumbersTable
          isLoading={isLoading}
          verifyNumber={(number) => requestPhoneVerification(number)}
          deleteNumber={(number) => setNumberToDelete(number)}
          makeNumberPrimary={(number) => makeNumberPrimary(number)}
          phoneNumbers={individualPhoneNumbers}
        />
      )}
    </React.Fragment>
  )
}
