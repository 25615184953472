import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Loading } from '../../../global/Loading/Loading'
import { Text } from '../../../ui/Typography/Typography'
import { useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import { useFetchOne } from '../../../utils/useFetch'
import { PersonalDetailsTemplate } from '../PersonalInfoPage/PersonalDetailsTemplate'
import { FormValuesStep0 } from '../PersonalInfoPage/PersonalInfoStepAccountTypePage'
import { FormValuesStep1 } from '../PersonalInfoStep1Page/PersonalInfoDetailsStep1Form'
import { FormValuesStep2 } from '../PersonalInfoStep2Page/PersonalInfoStep2Form'
import { SignupWarningMessages } from '../SignupWarningMessages'
import { FormValuesStep3, PersonalDetailsStep3Form } from './PersonalInfoStep3Form'

type FormValues = FormValuesStep0 & FormValuesStep1 & FormValuesStep2 & FormValuesStep3

interface OuterProps {
  step: number
  setStep(n: number): void
  formValues?: FormValues
  onSubmit(values: FormValues): void
}

export const PersonalInfoStep3Page: React.FC<OuterProps> = (props) => {
  const { formValues, step, setStep, onSubmit } = props

  const { t } = useTranslation()
  const apiClient = useApiClient(ClientApiClient)

  const callback = useCallback(async () => {
    return Promise.all([
      apiClient.getCountries(),
      apiClient.getClientAreaLanguages({ forSignIn: true, clientAreaVisible: true }),
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { data = [], isLoading } = useFetchOne(callback)
  const [countries = [], languages = []] = data

  const filteredLanguages = languages.filter((language) => language.clientAreaVisible)

  const goBack = () => {
    setStep(step - 1)
  }

  return (
    <PersonalDetailsTemplate
      renderDescription={() => (
        <Text isParagraph>
          {t(
            'Sign up.Your personal information is required in the next step to complete the application process. Your ID documents are needed for this step.'
          )}
        </Text>
      )}
      reversed
      step={step}
      goBack={goBack}
    >
      <SignupWarningMessages />

      <Loading showLoadingIcon isLoading={isLoading}>
        <PersonalDetailsStep3Form
          countries={countries}
          countryId={formValues?.countryId}
          languages={filteredLanguages}
          phoneCodes={countries.map((x) => x.phoneCode)}
          onSubmit={onSubmit}
        />
      </Loading>
    </PersonalDetailsTemplate>
  )
}
