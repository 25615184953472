import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Loading } from '../../../global/Loading/Loading'
import { Button } from '../../../global/button/Button'
import { Chip } from '../../../global/chip/Chip'
import { useSessionLanguage } from '../../../global/context/SessionSettingsContext'
import { SelectField } from '../../../global/field/SelectField'
import { CheckCircledIcon } from '../../../icons/CheckCircledIcon'
import { LandingPageDto } from '../../../model/LandingPageDto'
import { CreateQRImageFormat, CreateQRResolution } from '../../../model/createQR'
import { useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import { useCallbackWithForceRefresh } from '../../../utils/useCallbackWithForceRefresh'
import { useFetchOne } from '../../../utils/useFetch'
import { useIBReferralCodes } from '../../../utils/useIBReferralCodes'
import { LandingPageCard } from '../LandingPageCard'
import { Header } from '../ReferralParts'
import { useLandingPageFetch } from '../hooks/useLandingPageFetch'
import { useLanguagesFetch } from '../hooks/useLanguagesFetch'
import { useSelectLanguage } from '../hooks/useSelectLanguage'

import styles from './QRCodePage.module.scss'

const SIZE_OPTIONS: { value: CreateQRResolution; label: string }[] = [
  { value: '120x120', label: '120x120' },
  { value: '240x240', label: '240x240' },
  { value: '360x360', label: '360x360' },
  { value: '480x480', label: '480x480' },
  { value: '600x600', label: '600x600' },
  { value: '720x720', label: '720x720' },
  { value: '840x840', label: '840x840' },
  { value: '960x960', label: '960x960' },
  { value: '1080x1080', label: '1080x1080' },
  { value: '1200x1200', label: '1200x1200' },
]

const IMAGE_FORMAT_OPTIONS: { value: CreateQRImageFormat; label: string }[] = [
  { value: 'svg', label: '.SVG' },
  { value: 'png', label: '.PNG' },
  { value: 'jpeg', label: '.JPEG' },
]

export const QRCodePage: FC = () => {
  const { t } = useTranslation()

  const apiClient = useApiClient(ClientApiClient)

  const locale = useSessionLanguage()

  const domain = window.location.host

  const [resolution, setResolution] = useState<CreateQRResolution>(SIZE_OPTIONS[0].value)
  const [imageFormat, setImageFormat] = useState<CreateQRImageFormat>(IMAGE_FORMAT_OPTIONS[0].value)
  const [qrCode, setQRCode] = useState<File>()
  const [isQRCodeLoading, setIsQRCodeLoading] = useState(false)
  const [isQRCodeCopied, setIsQRCodeCopied] = useState(false)

  const languagesProps = useLanguagesFetch()
  const { languages, isLoadingLanguages } = languagesProps

  const ibReferralCodes = useIBReferralCodes()
  const { referralCodes, isLoading: isLoadingReferralCodes, ibCode, mtCode } = ibReferralCodes

  const [referralCode, setReferralCode] = useState(ibReferralCodes.ibCode || ibReferralCodes.mtCode)

  const selectLanguageProps = useSelectLanguage({
    languages,
    referralCode,
  })
  const { selectedLandingPage, setSelectedLandingPage, language, setLanguage } = selectLanguageProps

  const landingPagesProps = useLandingPageFetch(language)
  const { landingPages, isLoadingLandingPages } = landingPagesProps

  const isLoading = isLoadingLandingPages || isLoadingLanguages || isLoadingReferralCodes
  const hasLandingPages = landingPages && landingPages.items.length > 0

  const typeOptions: { value: number; label: string }[] = [
    { value: 1, label: t('Referral Materials.Static') },
    { value: 2, label: t('Referral Materials.Dynamic') },
  ]

  const handleLandingPageClick = async (page: LandingPageDto) => {
    setSelectedLandingPage(page)
  }

  const handleSetLanguage = (event: ChangeEvent<HTMLSelectElement>) => {
    setLanguage(event.target.value)
  }

  const { callback: getReferralLinkCallback } = useCallbackWithForceRefresh(async () => {
    if (!selectedLandingPage || !referralCode) {
      return undefined
    }

    const response = await apiClient.getReferralLandingPageLink(
      language,
      selectedLandingPage.id,
      domain,
      referralCode,
      false
    )

    return response.url
  }, [locale, selectedLandingPage, domain, referralCode])

  const { data: referralLandingPageLink } = useFetchOne(getReferralLinkCallback)

  const getCreateQR = async (imageFormat: CreateQRImageFormat) => {
    if (!resolution || !imageFormat || !referralLandingPageLink) {
      return undefined
    }

    return apiClient.getCreateQR({
      url: decodeURI(referralLandingPageLink),
      resolution: resolution,
      imageFormat: imageFormat,
      includeLogoInQR: true,
      shortenUrl: true,
      foregroundColor: '000000',
      backgroundColor: 'ffffff',
    })
  }

  const handleCreateQR = async (imageFormat: CreateQRImageFormat) => {
    try {
      setIsQRCodeLoading(true)

      const qrCode = await getCreateQR(imageFormat)

      if (!qrCode) {
        return
      }

      setQRCode(qrCode)
      setIsQRCodeLoading(false)
    } catch (error: unknown) {
      console.error(error)

      setIsQRCodeLoading(false)
    }
  }

  const handleImageFormatChange = (imageFormat: CreateQRImageFormat) => {
    setImageFormat(imageFormat)
    handleCreateQR(imageFormat)
  }

  const qrCodeSrc = useMemo(() => {
    if (!qrCode) {
      return undefined
    }

    return URL.createObjectURL(qrCode)
  }, [qrCode])

  const handleQRCodeDownload = () => {
    if (!qrCodeSrc) {
      return
    }

    const a = document.createElement('a')
    a.href = qrCodeSrc
    a.download = 'qr-code'
    a.click()
  }

  const handleQRCodeCopyToClipboard = async () => {
    if (!qrCodeSrc) {
      return
    }

    let qrCodeFile = qrCode

    if (imageFormat === 'svg') {
      const qrCodeResponse = await getCreateQR('png')

      if (!qrCodeResponse) {
        return
      }

      qrCodeFile = qrCodeResponse
    }

    const qrCodeBlob = new Blob([qrCodeFile as BlobPart], { type: 'image/png' })

    try {
      navigator.clipboard.write([
        new ClipboardItem({
          'image/png': qrCodeBlob,
        }),
      ])

      setIsQRCodeCopied(true)

      setTimeout(() => {
        setIsQRCodeCopied(false)
      }, 3000)
    } catch (error: unknown) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (ibCode || mtCode) {
      setReferralCode(ibCode || mtCode)
    }
  }, [ibCode, mtCode])

  return (
    <Loading isLoading={isLoading}>
      <Header name={t('Referral Materials.Get QR Code')} />
      <div className='columns'>
        <SelectField
          className='column is-one-third'
          name='language'
          label={t('Referral Materials.Select Landing Page Language')}
          onChange={handleSetLanguage}
          value={language}
          options={languages.map(({ id, name }) => ({
            value: id,
            label: name,
          }))}
        />
        <SelectField
          className='column is-one-third'
          onChange={(v) => setReferralCode(v.target.value)}
          value={referralCode}
          disabled={referralCodes.length < 2}
          label={t('IB.Your Referral Code')}
          options={referralCodes.map(({ code }) => ({
            value: code,
            label: code,
          }))}
        />
      </div>
      {!!hasLandingPages && (
        <>
          <h3 className='pb-2'>{t('Referral Materials.Select Landing Page:')}</h3>
          <div className='is-flex is-flex-wrap-wrap'>
            {landingPages?.items.map((page) => (
              <LandingPageCard
                key={page.id}
                page={page}
                selectedPage={selectedLandingPage}
                onClick={handleLandingPageClick}
              />
            ))}
          </div>
        </>
      )}
      {!!hasLandingPages && !!selectedLandingPage && (
        <div className='mt-6'>
          <h3>{t('Referral Materials.Set QR Code:')}</h3>
          <div className={styles.bottomSelectorsWrapper}>
            <SelectField
              name='size'
              className={styles.bottomSelector}
              placeholder={t('Referral Materials.Sizes')}
              label={t('Referral Materials.Sizes')}
              onChange={({ target }) => setResolution(target.value as CreateQRResolution)}
              value={resolution}
              options={SIZE_OPTIONS}
            />
            <SelectField
              name='size'
              className={styles.bottomSelector}
              placeholder={t('Referral Materials.Type')}
              label={t('Referral Materials.Type')}
              value={typeOptions[0].value}
              options={typeOptions}
              disabled
            />
            <Button
              size='L'
              appearance='primary'
              type='button'
              className={styles.createButton}
              onClick={() => handleCreateQR(imageFormat)}
            >
              {t('Referral Materials.Create')}
            </Button>
          </div>
        </div>
      )}
      {!!hasLandingPages && !!selectedLandingPage && !!qrCodeSrc && (
        <div className='is-flex'>
          <QRCodeCard
            src={qrCodeSrc}
            imageFormat={imageFormat}
            onImageFormatChange={handleImageFormatChange}
            onDownload={handleQRCodeDownload}
            onCopyToClipboard={handleQRCodeCopyToClipboard}
            isCopyToClipboardButtonDisabled={isQRCodeLoading}
            isDownloadButtonDisabled={isQRCodeLoading}
            isCopied={isQRCodeCopied}
          />
        </div>
      )}
    </Loading>
  )
}

interface QRCodeCardProps {
  src: string
  imageFormat: CreateQRImageFormat
  onImageFormatChange: (format: CreateQRImageFormat) => void
  onDownload: () => void
  onCopyToClipboard: () => void
  isDownloadButtonDisabled?: boolean
  isCopyToClipboardButtonDisabled?: boolean
  isCopied?: boolean
}

const QRCodeCard: FC<QRCodeCardProps> = ({
  src,
  imageFormat,
  onImageFormatChange,
  onCopyToClipboard,
  onDownload,
  isCopyToClipboardButtonDisabled,
  isDownloadButtonDisabled,
  isCopied,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.qrCodeCard}>
      <img className={styles.qrCodeImage} src={src} />

      <div className={styles.qrCodeCardChips}>
        {IMAGE_FORMAT_OPTIONS.map((option) => {
          return (
            <Chip
              text={option.label}
              isActive={option.value === imageFormat}
              onClick={() => onImageFormatChange(option.value)}
            />
          )
        })}
      </div>

      <Button
        size='L'
        appearance='primary'
        type='button'
        fullWidth
        onClick={onDownload}
        disabled={isDownloadButtonDisabled}
      >
        {t('Download')}
      </Button>

      <Button
        size='L'
        appearance='secondary'
        type='button'
        fullWidth
        onClick={onCopyToClipboard}
        disabled={isCopyToClipboardButtonDisabled}
      >
        {isCopied ? (
          <span className='is-flex is-align-items-center'>
            {t('Copied')}&nbsp;
            <CheckCircledIcon circleColor={'success'} checkColor={'white'} />
          </span>
        ) : (
          t('Referral Materials.Copy To Clipboard')
        )}
      </Button>
    </div>
  )
}
