import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { Loading } from '../global/Loading/Loading'
import { useApiClient } from '../utils/ApiClient'
import { ClientApiClient } from '../utils/clientApi'
import { useFetchOne } from '../utils/useFetch'
import { TradersRoomModals } from './TradersRoomModals'
import { TradersRoomRoutes } from './TradersRoomRoutes'
import { TradersRoomTabs } from './TradersRoomTabs'

export const TradersRoom: React.FC = () => {
  const { tab } = useParams<{ tab?: string }>()
  const apiClient = useApiClient(ClientApiClient)

  const callback = useCallback(async () => apiClient.getClientCampaigns(), [])
  const listCallback = useCallback(async () => apiClient.getClientCampaignsList(), [])

  const { data: campaigns, isLoading } = useFetchOne(callback)
  const { data: campaignsList, isLoading: isLoadingList } = useFetchOne(listCallback)

  return (
    <Loading isLoading={isLoading || isLoadingList}>
      <TradersRoomModals />
      <TradersRoomTabs clientCampaigns={campaigns} clientCampaignsList={campaignsList?.items} />
      <TradersRoomRoutes tab={tab} />
    </Loading>
  )
}
