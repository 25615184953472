import React, { Dispatch, SetStateAction } from 'react'
import { Location } from 'react-router-dom'

import { AuthUser } from '../model/User'

export type MaybeAuthSession = AuthUser | null
export type AuthSessionContextType = [MaybeAuthSession, Dispatch<SetStateAction<MaybeAuthSession>>]

export const AuthSessionContext = React.createContext<AuthSessionContextType>([null, () => {}])

export const initiateAuthSession = (location: Location): MaybeAuthSession => {
  const auth = localStorage.getItem('auth')

  if (
    auth &&
    location.pathname !== 'login' &&
    location.pathname !== '/users/verify-email' &&
    location.pathname !== '/users/verify-phone' &&
    location.pathname !== '/users/register2' &&
    !location.pathname.includes('reset-password')
  )
    return JSON.parse(auth)
  return null
}
