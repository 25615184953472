import React from 'react'
import { useTranslation } from 'react-i18next'

import { InfoModal } from '../../../global/modal/InfoModal'
import { Modal } from '../../../global/modal/Modal'

import styles from './MultiTierModals.module.scss'

interface SubAccountsTableInfoModalProps {
  setInfoModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const SubAccountsTableInfoModal: React.FC<SubAccountsTableInfoModalProps> = ({
  setInfoModal,
}) => {
  const { t } = useTranslation()

  const renderListItem = (title: string, text: string) => (
    <>
      <span className={styles.infoHeader}>{title}</span>
      <ul>
        <li className={styles.infoList}>{text}</li>
      </ul>
      <br />
    </>
  )

  return (
    <Modal
      closeModal={() => setInfoModal(false)}
      render={({ closeModal }) => (
        <InfoModal
          onCancel={closeModal}
          title={t('IB.MultiTier.Sub-IB Accounts')}
          renderBody={() => (
            <section className='modal-card-body'>
              {renderListItem(
                t('IB.MultiTier.Sub-IB Accounts'),
                t('IB.MultiTier.IB accounts in your network')
              )}
              {renderListItem(t('IB.Name'), t('IB.MultiTier.Name of the IB in your network'))}
              {renderListItem(t('IB.Volume'), t('IB.MultiTier.Total volume the IB'))}
              <span className={styles.text}>
                {t('IB.MultiTier.You can change the IB Commission Plan for a sub-IB')}
              </span>
            </section>
          )}
          renderFooter={() => (
            <button className='button' onClick={closeModal} type='button'>
              {t('Got It')}
            </button>
          )}
          onConfirm={closeModal}
        />
      )}
    />
  )
}
