import React, { PropsWithChildren, createContext, useContext, useState } from 'react'

export enum MaintenanceType {
  MessageWithStartDate = 'maintenance.MessageWithStartDate',
  MessageWithStartDateTime = 'maintenance.MessageWithStartDateTime',
  MessageWithEndDateTime = 'maintenance.MessageWithEndDateTime',
  MessageWithEndDate = 'maintenance.MessageWithEndDate',
}

export interface MaintenanceProps {
  Message: string
  StartDateTime: string
  EndDateTime: string
}

interface MaintenanceModeContextProps {
  maintenance: MaintenanceProps
  isMaintenanceMode: boolean
  setMaintenance(value?: MaintenanceProps): void
}

export const initialMaintenanceState = {
  Message: '',
  StartDateTime: '',
  EndDateTime: '',
}

export const MaintenanceModeContext = createContext<MaintenanceModeContextProps>({
  maintenance: initialMaintenanceState,
  isMaintenanceMode: false,
  setMaintenance: () => initialMaintenanceState,
})
MaintenanceModeContext.displayName = 'MaintenanceModeContext'

export const useMaintenance = (): MaintenanceModeContextProps => useContext(MaintenanceModeContext)

export const MaintenanceModeProvider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props

  const initialState = createInitialState()
  const [maintenance, setMaintenance] = useState(initialState)

  const handleMaintenance = (value?: MaintenanceProps) => {
    if (!initialState.Message) setMaintenance(value || initialState)
  }

  return (
    <MaintenanceModeContext.Provider
      value={{
        maintenance,
        isMaintenanceMode: !!maintenance.Message,
        setMaintenance: handleMaintenance,
      }}
    >
      {children}
    </MaintenanceModeContext.Provider>
  )
}

const createInitialState = () => {
  return {
    Message: window.env.APP_MAINTENANCE_MESSAGE || initialMaintenanceState.Message,
    StartDateTime:
      window.env.APP_MAINTENANCE_START_DATE_TIME || initialMaintenanceState.StartDateTime,
    EndDateTime: window.env.APP_MAINTENANCE_END_DATE_TIME || initialMaintenanceState.EndDateTime,
  }
}
