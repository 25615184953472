import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

import { Loading } from '../../../global/Loading/Loading'
import { useEntitySettings } from '../../../global/context/EntityContext'
import { useLegalLinksContext } from '../../../global/context/LegalLinksContext'
import { useSessionLanguage } from '../../../global/context/SessionSettingsContext'
import { LegalDocumentDto } from '../../../model/CompanyLegalDocumentDto'
import { LeadMembersEnum } from '../../../model/CreateLead'
import { RegulatorDto, RegulatorOptionDto } from '../../../model/RegulatorOptionDto'
import { TickmillProductType } from '../../../model/TickmillProductType'
import { useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import { TickmillCompaniesEnum, isTickmillPartnerType } from '../../../utils/companyName.utils'
import { dispatchEntitySelected } from '../../../utils/cookie.utils'
import { filterDuplicatesById } from '../../../utils/duplicates'
import { getLocationCountry } from '../../../utils/geolocation'
import { useFetchOne } from '../../../utils/useFetch'
import { useFetchRegulators } from '../../useFetchRegulators'
import { FormValuesStep0 } from '../PersonalInfoPage/PersonalInfoStepAccountTypePage'
import { FormValuesStep1 } from '../PersonalInfoStep1Page/PersonalInfoDetailsStep1Form'
import { FormValuesStep3 } from '../PersonalInfoStep3Page/PersonalInfoStep3Form'
import { FormValuesStep2, PersonalDetailsStep2Form } from './PersonalInfoStep2Form'

type FormValues = FormValuesStep0 & FormValuesStep1 & FormValuesStep2 & FormValuesStep3

interface OuterProps {
  step: number
  setStep(n: number): void
  formValues?: FormValues
  onSubmit(values: FormValues): void
}

export const PersonalInfoStep2Page: React.FC<OuterProps> = (props) => {
  const { step, setStep, formValues, onSubmit } = props
  const locale = useSessionLanguage()

  const regulator = useRegulator()
  const regulators = useFetchRegulators()
  const countryLocation = useFetchCountryLocation(props, regulators.data)

  const documents = useFetchDocuments(countryLocation, regulator, formValues)

  const handleRegulatorSelected = (regulatorValue: RegulatorDto | undefined) => {
    regulator.setRegulatorSelected(regulatorValue)
    dispatchEntitySelected(
      regulatorValue?.tickmillCompanyId || TickmillCompaniesEnum.GENERIC,
      locale,
      formValues?.email,
      formValues?.firstName
    )
  }

  const handleCountrySelected = (country: string | undefined) => {
    countryLocation.setCountrySelected(country)
  }

  const goBack = () => {
    setStep(step - 1)
  }

  const isLoading = useMemo(
    () => countryLocation.isLoading && !!countryLocation.countrySelected,
    [countryLocation.isLoading, countryLocation.countrySelected]
  )

  return (
    <Loading showLoadingIcon isLoading={isLoading}>
      {!countryLocation.isLoading && (
        <PersonalDetailsStep2Form
          step={step}
          formValues={formValues}
          groupReferences={groupReferences(documents.data)}
          passedDocuments={documents.data
            .filter(filterDocumentsByClient)
            .filter(filterDuplicatesById)
            .sort((a: LegalDocumentDto, b: LegalDocumentDto) => a.order - b.order)}
          regulatorSelected={regulator.regulatorSelected}
          regulators={regulators.data}
          countryId={countryLocation.countrySelected}
          tickmillCompanyId={regulator.tickmillCompanyId}
          disabled={regulators.isLoading}
          isLoading={regulators.isLoading || countryLocation.isLoading}
          onSubmit={onSubmit}
          onCountrySelectedChange={handleCountrySelected}
          onRegulatorSelectedChange={handleRegulatorSelected}
          goBack={goBack}
        />
      )}
    </Loading>
  )
}

const useFetchDocuments = (
  countryLocation: FetchCountryLocationProps,
  regulator: RegulatorProps,
  formValues?: FormValues
) => {
  const apiClient = useApiClient(ClientApiClient)
  const locale = useSessionLanguage()

  const callback = useCallback(async () => {
    const isRegulator =
      regulator.isRegulatorSelected || regulator.isPartnerType || !!formValues?.tickmillCompanyId

    if (
      [locale, isRegulator, !countryLocation.isLoading].every((x) => !!x) &&
      countryLocation.countrySelected
    ) {
      return await apiClient.getLegalDocumentsQuery(
        locale,
        regulator.tickmillCompanyId || formValues?.tickmillCompanyId,
        [TickmillProductType.CFD],
        countryLocation.countrySelected
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    locale,
    regulator.regulatorSelected?.title,
    countryLocation.countrySelected,
    countryLocation.isLoading,
  ])
  const response = useFetchOne(callback)

  return { ...response, data: response.data || [] }
}

interface FetchCountryLocationProps {
  countrySelected: string | undefined
  setCountrySelected(value: string | undefined): void
  isLoading: boolean
}

const useFetchCountryLocation = (
  props: OuterProps,
  regulators: RegulatorOptionDto[] = []
): FetchCountryLocationProps => {
  const searchParams = new URLSearchParams(window.location.search)

  const [params] = useSearchParams()
  const location = useLocation()
  const locationCountry = getLocationCountry(location)
  const apiClient = useApiClient(ClientApiClient)

  const countryId =
    props?.formValues?.countryId ||
    locationCountry ||
    searchParams.get(LeadMembersEnum.COUNTRY_ID) ||
    ''

  const [countrySelected, setCountrySelected] = useState<string | undefined>(countryId)
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (!countryId && regulators.length >= 1) {
      if (locationCountry) {
        const getCountryByGeolocation = regulators.find(
          (x) => x.country.id.toLowerCase() === locationCountry.toLowerCase()
        )

        if (getCountryByGeolocation) {
          setCountrySelected(getCountryByGeolocation.country.id)
        }
        setLoading(false)
      } else {
        try {
          apiClient.getVisitorGeolocation().then((d) => {
            const getCountryByGeolocation = regulators.find(
              (x) => x.country.id === d.country_code_iso3
            )
            if (getCountryByGeolocation && !params.get('redir') && !params.get('source')) {
              setCountrySelected(getCountryByGeolocation.country.id)
            }
          })
        } catch (e: unknown) {
          console.error(e)
        } finally {
          setLoading(false)
        }
      }
    } else {
      setLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regulators])

  return { countrySelected, setCountrySelected, isLoading }
}

interface RegulatorProps {
  tickmillCompanyId?: TickmillCompaniesEnum
  isPartnerType: boolean
  isRegulatorSelected: boolean
  regulatorSelected: RegulatorDto | undefined
  setRegulatorSelected(value: RegulatorDto | undefined): void
}

const useRegulator = (): RegulatorProps => {
  const [entity] = useEntitySettings()

  const [regulatorSelected, setRegulatorSelected] = useState<RegulatorDto | undefined>()

  const tickmillCompanyId = regulatorSelected?.tickmillCompanyId
  const isRegulatorSelected = !!tickmillCompanyId
  const isPartnerType = isTickmillPartnerType(entity.entity)

  if (isTickmillPartnerType(entity.entity)) {
    return {
      tickmillCompanyId: TickmillCompaniesEnum.TICKMILL_PA,
      isPartnerType,
      isRegulatorSelected: true,
      regulatorSelected,
      setRegulatorSelected,
    }
  }

  return {
    tickmillCompanyId,
    isPartnerType,
    isRegulatorSelected,
    regulatorSelected,
    setRegulatorSelected,
  }
}

const groupReferences = (documents: LegalDocumentDto[] = []): (string | null)[] => {
  const groupReferences = documents.map((x) => x.groupReference)

  return groupReferences.filter(function (item, pos) {
    return groupReferences.indexOf(item) === pos
  })
}

const filterDocumentsByClient = (document: LegalDocumentDto): boolean => {
  return document.step === 'lead'
}
