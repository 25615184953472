import React, { SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikErrors, FormikProps, withFormik } from 'formik'
import { t } from 'i18next'

import { RawSelectFormField } from '../../global/formField/FormSelectField'
import { ClientAreaLanguageDto } from '../../model/ClientAreaLanguageDto'
import { FormSubmitValues } from '../../utils/formValidation'

import styles from './AccountSettingsPage.module.scss'

export interface CommunicationLanguageFormValues {
  communicationLanguageId: string
}

const CommunicationLanguageFormUI: React.FC<
  FormikProps<CommunicationLanguageFormValues> & OuterProps
> = (props) => {
  const { handleSubmit, setSubmitting } = props
  const { t } = useTranslation()
  const handleChangeForm = (event: SyntheticEvent) => {
    event.preventDefault()
    setSubmitting(true)
    handleSubmit()
  }

  return (
    <>
      <span>{t('Profile.Choose the main language to communicate with support')}</span>
      <Form className={styles.form} onChange={handleChangeForm}>
        <RawSelectFormField
          showLabel
          component='select'
          name={'communicationLanguageId'}
          label={t('Communication language')}
          required
        >
          {(props.languages || []).map((lan) => (
            <option value={lan.id} key={lan.id}>
              {lan.name}
            </option>
          ))}
        </RawSelectFormField>
      </Form>
    </>
  )
}

interface OuterProps {
  communicationLanguageId?: string
  languages: ClientAreaLanguageDto[]
  onSubmit(values: FormSubmitValues<CommunicationLanguageFormValues>): Promise<void>
}

export const CommunicationLanguageForm = withFormik<OuterProps, CommunicationLanguageFormValues>({
  mapPropsToValues: ({ communicationLanguageId }) => {
    return {
      communicationLanguageId: communicationLanguageId || '',
    }
  },
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit(values)
    } finally {
      setSubmitting(false)
    }
  },
  validate: (values) => {
    const errors: FormikErrors<CommunicationLanguageFormValues> = {}
    if (!values.communicationLanguageId) {
      errors.communicationLanguageId = t('Validation.Required')
    }
    return errors
  },
  enableReinitialize: true,
})(CommunicationLanguageFormUI)
