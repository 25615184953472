import { TickmillCompany } from '../utils/companyName.utils'
import { AccountType } from './AccountType'
import { NameDto } from './NameDto'
import { TickmillProductType } from './TickmillProductType'

export interface LeadDto {
  activities: LeadActivity[]
  address: null | string
  assignee: null | string
  birthDay: string
  city: null | string
  client: null | { id: string }
  country: NameDto<string>
  countryCode: string
  created: string
  email: string
  emailVerified: boolean
  externalSystems: {
    externalId: '2057101'
    externalSystem: {
      id: 2
      name: 'HubSpot'
    }
  }[]
  firstName: string
  gender: NameDto
  id: string
  isConverted: boolean
  isUsCitizen: boolean
  language: NameDto<string>
  lastName: string
  nativeName?: string
  leadSource: NameDto
  middleName: string
  name: string
  optIn: boolean
  phoneNumber: string
  phoneVerified: boolean
  phoneNumberTypeId: number
  phoneVerificationRequired: boolean
  postalCode: null | string
  preferredCurrency: NameDto<string>
  products: NameDto[]
  salesStatus: NameDto
  state: null | string
  teams: {
    isPrimary: boolean
    id: string
    name: string
  }[]
  tickmillCompany: TickmillCompany
  title: 'Mr' | 'Mrs'
  token: string
  type: NameDto
  updated: string
  affiliateToken: string | null
}

export const isLeadTypeIndividual = (lead: LeadDto | undefined): boolean => {
  return lead?.type?.id === AccountType.Individual
}

export const isLeadTypeCorporate = (lead: LeadDto | undefined): boolean => {
  return lead?.type?.id === AccountType.Corporate
}

interface LeadActivity {
  referralCode: string
  affiliateToken: string
  campaign: string
  leadSource: NameDto
}

export enum LeadSource {
  LiveRegistration = 5,
  MobileRegistration = 18,
}

export const isLeadSourceLiveRegistration = (name: NameDto | undefined): boolean => {
  return name?.id === LeadSource.LiveRegistration
}

export const isLeadSourceMobileRegistration = (name: NameDto | undefined): boolean => {
  return name?.id === LeadSource.MobileRegistration
}

export const isLeadSourceMobileRegistrationType = (value: number): boolean => {
  return value === LeadSource.MobileRegistration
}
