import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAccountWriteContext } from '../../utils/AccountContextContext'
import { useApiClient } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { SharedContext, initialSharedContext } from '../../utils/SharedContext'
import { ClientApiClient } from '../../utils/clientApi'
import { addEventListeners, removeEventListeners } from '../../utils/eventListener.utils'
import { clearLocalStorage } from '../../utils/useStorage'
import { Modal } from '../modal/Modal'
import { SessionExpirationModal } from '../modal/SessionExpirationModal'

const REDIRECT_TIME = 5 * 60 * 1000 // 5 min
const WARN_AFTER_TIME = 15 * 60 * 1000

export const SessionTimeout = () => {
  const [redirectAfter, setRedirectAfter] = useState(REDIRECT_TIME)
  const [warnAfter, setWarnAfter] = useState(WARN_AFTER_TIME)
  const clientApi = useApiClient(ClientApiClient)
  const { clearAccount } = useAccountWriteContext()

  const [warningModal, setWarningModal] = useState(false)
  const navigate = useNavigate()
  const [auth, setAuth] = useContext(AuthSessionContext) // eslint-disable-line @typescript-eslint/no-unused-vars
  const [, setSharedState] = useContext(SharedContext)

  const onLogout = async () => {
    if (auth) {
      await clientApi.logOut(auth.id)
    }
    clearSessionAndLogout()
  }

  const clearSessionAndLogout = () => {
    setSharedState(initialSharedContext)
    clearAccount()
    clearLocalStorage()
    setAuth(null)
    setWarningModal(false)
    resetRedirectAndWarnTimeout()
    navigate('/logout')
  }

  const resetRedirectAndWarnTimeout = () => {
    setRedirectAfter(REDIRECT_TIME)
    setWarnAfter(WARN_AFTER_TIME)
  }

  useEffect(() => {
    const createWarningTimeout = () => setTimeout(() => setWarningModal(true), warnAfter)

    const listener = () => {
      if (!warningModal) {
        clearTimeout(timeout)
        timeout = createWarningTimeout()
      }
    }

    let timeout = createWarningTimeout()

    addEventListeners(listener)

    return () => {
      removeEventListeners(listener)
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warningModal])

  return (
    <div>
      {warningModal && (
        <Modal
          closeModal={onLogout}
          render={() => (
            <SessionExpirationModal
              onLogout={onLogout}
              onStayConnected={() => {
                setWarningModal(false)
                resetRedirectAndWarnTimeout()
              }}
              redirectAfter={redirectAfter}
            />
          )}
        />
      )}
    </div>
  )
}
