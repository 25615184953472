import React from 'react'

import { useDocumentMarketDataIdState } from '../../../ProductRegistration/LegalDocuments/ReadDocumentMarketDataPage'
import { Loading } from '../../../global/Loading/Loading'
import { LegalDocumentDto } from '../../../model/CompanyLegalDocumentDto'
import { NameDto } from '../../../model/NameDto'
import { FormValuesLegalDocs } from '../PersonalInfoPage/PersonalInfoDocumentsFactory'
import { SignUpRegisterTemplateProps } from '../SignUpRegisterPage/SignUpRegisterTemplate'
import { PersonalInfoStep6Form } from './PersonalInfoStep6Form'

interface OuterProps extends SignUpRegisterTemplateProps {
  legalDocuments: LegalDocumentDto[]
  products: NameDto[]
  shouldReceiveNewsletter?: boolean
  onSubmit(v: FormValuesLegalDocs): Promise<void>
}

export const PersonalInfoStep6Page: React.FC<OuterProps> = (props) => {
  const { legalDocuments: documents, products, isLoading } = props

  const { marketDataClassificationId } = useDocumentMarketDataIdState()

  return (
    <Loading isLoading={isLoading} showLoadingIcon>
      <PersonalInfoStep6Form
        {...props}
        marketDataClassificationId={marketDataClassificationId}
        groupReferences={groupReferences(documents)}
        products={products}
        documents={documents
          .filter(filterDocumentsByClient)
          .filter(filterDuplicateDocuments)
          .sort((a, b) => a.order - b.order)}
      />
    </Loading>
  )
}

const groupReferences = (documents: LegalDocumentDto[] = []): (string | null)[] => {
  const groupReferences = documents.map((x) => x.groupReference)

  return groupReferences.filter(function (item, pos) {
    return groupReferences.indexOf(item) === pos
  })
}

export const filterDocumentsByClient = (document: LegalDocumentDto): boolean => {
  return document.step === 'client'
}

export const filterDuplicateDocuments = (
  value: LegalDocumentDto,
  index: number,
  self: LegalDocumentDto[]
): boolean => index === self.findIndex((t) => t.id === value.id)
