import React from 'react'
import classNames from 'classnames'

import { Color } from '.'

import styles from './Icons.module.scss'

export interface IconProps {
  inverse?: boolean
  themable?: boolean
  className?: string
  size?: number
  width?: number
  height?: number
  color?: Color
  onClick?: React.MouseEventHandler<SVGSVGElement>
  strokeWidth?: number
  secondaryColor?: Color
}

export function iconClassName({ className, themable, inverse }: IconProps): string {
  return classNames(
    'icon',
    className,
    themable && styles.themeFill,
    themable && styles.themeStroke,
    inverse && styles.inverse
  )
}

export function fillIconClassName({ className, themable, inverse }: IconProps): string {
  return classNames('icon', className, themable && styles.themeFill, inverse && styles.inverse)
}

export function strokeIconClassName({ className, themable, inverse }: IconProps): string {
  return classNames('icon', className, themable && styles.themeStroke, inverse && styles.inverse)
}

export function iconColor({ themable, inverse, color }: IconProps): string | undefined {
  if (color) return styles[color]
  if (themable) return undefined
  return inverse ? styles.textInverse : styles.text
}

export function iconSecondaryColor({ secondaryColor }: IconProps): string | undefined {
  if (secondaryColor) return styles[secondaryColor]
  return undefined
}
