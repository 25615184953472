import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Form, FormikErrors, FormikProps, withFormik } from 'formik'

import { INITIAL_COUNTER_SECONDS } from '../../../SignUp/PersonalInfo/PersonalInfoStepMobileValidation'
import { Button } from '../../../global/button/Button'
import { createFormField } from '../../../global/formField/FormField'
import { CheckCircledIcon } from '../../../icons/CheckCircledIcon'
import { FailureExpressionIcon } from '../../../icons/FailureExpressionIcon'
import { Text, TextSmall } from '../../../ui/Typography/Typography'
import { preventPaste } from '../../../utils/input.utils'
import { isZero } from '../../../utils/validations'

import styles from './PhoneNumberModal.module.scss'

const FormField = createFormField<PhoneNumberVerifyFormValues>()

export interface PhoneNumberVerifyFormValues {
  phoneCode1st: string
  phoneCode2nd: string
  phoneCode3rd: string
  phoneCode4th: string
}

const fieldKeys: (keyof PhoneNumberVerifyFormValues)[] = [
  'phoneCode1st',
  'phoneCode2nd',
  'phoneCode3rd',
  'phoneCode4th',
]

const initialState = () => ({
  phoneCode1st: '',
  phoneCode2nd: '',
  phoneCode3rd: '',
  phoneCode4th: '',
})

interface StepBackProps {
  event: {
    key: string
    preventDefault: () => void
  }
  name: string
  value: string
}

const useCounter = (props: OuterProps) => {
  const { counter, setCounter } = props

  useEffect(() => {
    const timer = setInterval(() => {
      if (counter > 0) setCounter(counter - 1)
    }, 1000)
    return () => clearInterval(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter])

  useEffect(() => {
    setCounter(counter)
  }, [counter, setCounter])
}

const useCode = (props: FormikProps<PhoneNumberVerifyFormValues> & OuterProps) => {
  const { values, onPassCode } = props

  useEffect(() => {
    const code =
      values.phoneCode1st + values.phoneCode2nd + values.phoneCode3rd + values.phoneCode4th
    onPassCode(code)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])
}

const PhoneNumberVerifyFormUI: React.FC<FormikProps<PhoneNumberVerifyFormValues> & OuterProps> = (
  props
) => {
  const { verificationError, verificationCodeError, verificationSuccess } = props
  const { phoneNumber, phoneNumberId, counter, setCounter } = props
  const { renderFooter } = props

  const { t } = useTranslation()

  useCounter(props)
  useCode(props)

  const handleResend = () => {
    setCounter(INITIAL_COUNTER_SECONDS)
    props.resendCode(phoneNumber, phoneNumberId)
  }

  const handleReceivePhoneCall = () => {
    setCounter(INITIAL_COUNTER_SECONDS)
    props.onReceivePhoneCall(phoneNumber, phoneNumberId)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    if (value.length <= 1) {
      props.setFieldValue(name, value)
      props.setVerificationError(undefined)
      props.setVerificationCodeError(undefined)
      let nextField: string | undefined
      if (name === 'phoneCode1st') {
        nextField = 'phoneCode2nd'
      } else if (name === 'phoneCode2nd') {
        nextField = 'phoneCode3rd'
      } else {
        nextField = 'phoneCode4th'
      }
      const next = document.getElementById(nextField)
      if (next && !!value) next.focus()
    }
  }

  const handleStepBack = (stepBackProps: StepBackProps) => {
    const { event, name, value } = stepBackProps

    event.preventDefault()
    let previousField: string | undefined
    if (name === 'phoneCode2nd') {
      previousField = 'phoneCode1st'
    } else if (name === 'phoneCode3rd') {
      previousField = 'phoneCode2nd'
    } else if (name === 'phoneCode4th') {
      previousField = 'phoneCode3rd'
    }
    if (previousField) {
      const previous = document.getElementById(previousField)
      if (previous && !value) {
        props.setFieldValue(previousField, '')
        previous?.focus()
      }
    }
  }

  return (
    <React.Fragment>
      <Form className={styles.mobileVerificationWrapper} autoComplete='off'>
        <div className='is-flex is-flex-direction-row is-align-items-center'>
          <h3 className='mr-1'>{t('Sign up.Enter 4-digit code')}</h3>
          {verificationSuccess && <CheckCircledIcon circleColor={'success'} checkColor={'white'} />}
          {isVerificationCodeError(verificationCodeError) && <FailureExpressionIcon />}
        </div>
        <span className={styles.sent}>
          {t('Sign up.Code was sent', {
            phoneNumber: props.phoneNumber,
          })}
        </span>
        <div className={styles.fieldWrapper}>
          {fieldKeys.map((fieldKey: keyof PhoneNumberVerifyFormValues) => (
            <NumberField
              {...props}
              name={fieldKey}
              value={props.values[fieldKey] ? '*' : ''}
              onChange={handleChange}
              onStepBack={handleStepBack}
              key={fieldKey}
            />
          ))}
        </div>
        {!!verificationError && <div className={styles.error}>{verificationError}</div>}
        {isZero(counter) ? (
          <React.Fragment>
            <div className='is-flex text-align-center'>
              <Text className='is-flex is-align-items-center'>
                {t('Sign up.Not get code')}{' '}
                <Button
                  className='pl-4'
                  type='button'
                  onClick={handleResend}
                  appearance='plain'
                  size='S'
                >
                  {t('Sign up.Resend')}
                </Button>
              </Text>
            </div>

            <div className='is-flex'>
              <Button onClick={handleReceivePhoneCall} type='button' size='S' appearance='plain'>
                {t('Sign up.Receive phone call')}
              </Button>
            </div>
          </React.Fragment>
        ) : (
          <TextSmall>
            {t('Sign up.Please allow at least 15 seconds for code to reach your phone...', {
              counter: `0:${String(counter).padStart(2, '0')}`,
            })}
          </TextSmall>
        )}
      </Form>
      {renderFooter(props)}
    </React.Fragment>
  )
}

interface FieldProps extends OuterProps {
  name: keyof PhoneNumberVerifyFormValues
  value: string
  onChange(event: React.ChangeEvent<HTMLInputElement>): void
  onStepBack(stepBackProps: StepBackProps): void
}

const NumberField: React.FC<FieldProps> = (props) => {
  const {
    name,
    value,
    disabled,
    verificationCodeError,
    verificationSuccess,
    onChange,
    onStepBack,
  } = props

  return (
    <FormField
      id={name}
      name={name}
      value={value}
      type='text'
      autoComplete='new-password'
      disabled={disabled || verificationSuccess || isVerificationCodeError(verificationCodeError)}
      onFocus={(event) => event.currentTarget.select()}
      onPaste={preventPaste}
      onChange={onChange}
      onKeyDown={(event) => {
        if (event.key === 'Backspace' && !value) onStepBack({ event, value, name })
        if (['e', 'E', '+', '-'].includes(event.key)) return event.preventDefault()
      }}
      className={classNames(styles.verificationBox, {
        [styles.verificationBoxError]: isVerificationCodeError(verificationCodeError),
        [styles.verificationBoxSuccess]: verificationSuccess,
      })}
      key={name}
    />
  )
}

const isVerificationCodeError = (verificationCodeError?: string) => {
  return (
    verificationCodeError === 'phone_verification_max_attempts_reached' ||
    verificationCodeError === 'max_new_verification_code_requests_reached'
  )
}

export interface OuterProps {
  phoneNumberId: string
  phoneNumber: string
  onPassCode(v: string): void
  resendCode(phoneNumber: string, phoneNumberId: string): void
  onReceivePhoneCall(phoneNumber: string, phoneNumberId: string): void

  verificationSuccess?: boolean
  verificationError?: string
  setVerificationError(v?: string): void
  verificationCodeError?: string
  setVerificationCodeError(v?: string): void

  disabled?: boolean

  counter: number
  setCounter(v: number): void

  onSubmit(): Promise<void>
  renderFooter(props: FormikProps<PhoneNumberVerifyFormValues>): React.ReactNode
}

export const PhoneNumberVerifyForm = withFormik<OuterProps, PhoneNumberVerifyFormValues>({
  mapPropsToValues: () => {
    return initialState()
  },
  handleSubmit: async (values, { props, setValues }) => {
    try {
      await props.onSubmit()
    } catch (error: unknown) {
      setValues(initialState())
    }
  },
  validate: () => {
    const errors: FormikErrors<PhoneNumberVerifyFormValues> = {}
    return errors
  },
})(PhoneNumberVerifyFormUI)
