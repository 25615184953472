import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Loading } from '../../global/Loading/Loading'
import { SortHeader } from '../../global/sortHeader/SortHeader'
import { Table } from '../../global/table/Table'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { IntroducingBrokerReportDto } from '../../model/IntroducingBrokerReportDto'
import { Tooltip } from '../../ui/Popups/Tooltip/Tooltip'
import { SortOrder } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { formatDate } from '../../utils/date.utils'
import { ReportsPageSort } from './ReportsPage'

import styles from './IBReportsTable.module.scss'

interface IBReportsTableProps {
  sortedData: IntroducingBrokerReportDto[]
  isLoading: boolean

  setSort(sort: ReportsPageSort): void

  sortOrder: SortOrder
  sort: ReportsPageSort

  setSortOrder(sort: SortOrder): void
}

export const IBReportsTable: React.FC<IBReportsTableProps> = (props) => {
  const { setSortOrder, setSort, sortOrder, sort, sortedData, isLoading } = props

  const { t } = useTranslation()
  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name
  const { formatNumber, formatMoney } = useFormatNumber()

  return (
    <Table>
      <Loading showLoadingIcon isLoading={isLoading}>
        <thead>
          <tr>
            <SortHeader
              id='login'
              sort={sort}
              sortOrder={sortOrder}
              setSort={(sort: 'login', sortOrder) => {
                setSort(sort)
                setSortOrder(sortOrder)
              }}
            >
              {t('Trading Account.Trading Account')}
            </SortHeader>
            <SortHeader
              id='clientName'
              sort={sort}
              sortOrder={sortOrder}
              setSort={(sort: 'clientName', sortOrder) => {
                setSort(sort)
                setSortOrder(sortOrder)
              }}
            >
              {t('IB.Client')}
            </SortHeader>
            <SortHeader
              id='accountType'
              sort={sort}
              sortOrder={sortOrder}
              setSort={(sort: 'accountType', sortOrder) => {
                setSort(sort)
                setSortOrder(sortOrder)
              }}
            >
              {t('Account Type')}
            </SortHeader>
            <SortHeader
              id='registrationDate'
              sort={sort}
              sortOrder={sortOrder}
              setSort={(sort: 'registrationDate', sortOrder) => {
                setSort(sort)
                setSortOrder(sortOrder)
              }}
            >
              {t('IB.Registration Date')}
            </SortHeader>
            <SortHeader
              id='status'
              sort={sort}
              sortOrder={sortOrder}
              setSort={(sort: 'status', sortOrder) => {
                setSort(sort)
                setSortOrder(sortOrder)
              }}
            >
              {t('Status')}
            </SortHeader>
            <SortHeader
              id='balance'
              sort={sort}
              sortOrder={sortOrder}
              setSort={(sort: 'balance', sortOrder) => {
                setSort(sort)
                setSortOrder(sortOrder)
              }}
            >
              {t('Trading Account.Balance')}
            </SortHeader>
            <SortHeader
              id='volumeSince2019'
              sort={sort}
              sortOrder={sortOrder}
              setSort={(sort: 'volumeSince2019', sortOrder) => {
                setSort(sort)
                setSortOrder(sortOrder)
              }}
            >
              {t('IB.Volume Since 2019')}
            </SortHeader>
            <SortHeader
              id='volumeCurrentMonth'
              sort={sort}
              sortOrder={sortOrder}
              setSort={(sort: 'volumeCurrentMonth', sortOrder) => {
                setSort(sort)
                setSortOrder(sortOrder)
              }}
            >
              {t('IB.Volume Current Month')}
            </SortHeader>
            <SortHeader
              id='volumePreviousMonth'
              sort={sort}
              sortOrder={sortOrder}
              setSort={(sort: 'volumePreviousMonth', sortOrder) => {
                setSort(sort)
                setSortOrder(sortOrder)
              }}
            >
              {t('IB.Volume Previous Month')}
            </SortHeader>
            <SortHeader
              id='swapFree'
              sort={sort}
              sortOrder={sortOrder}
              setSort={(sort: 'swapFree', sortOrder) => {
                setSort(sort)
                setSortOrder(sortOrder)
              }}
            >
              {t('IB.Swap Free')}
            </SortHeader>
            <SortHeader
              id='clientEntity'
              sort={sort}
              sortOrder={sortOrder}
              setSort={(sort: 'clientEntity', sortOrder) => {
                setSort(sort)
                setSortOrder(sortOrder)
              }}
            >
              {t('IB.Entity')}
            </SortHeader>
            <SortHeader
              id='country'
              sort={sort}
              sortOrder={sortOrder}
              setSort={(sort: 'country', sortOrder) => {
                setSort(sort)
                setSortOrder(sortOrder)
              }}
            >
              {t('IB.Country')}
            </SortHeader>
          </tr>
        </thead>
        <tbody>
          {!sortedData.length ? (
            <td align='center' className={styles.text} colSpan={14}>
              {t('No results')}
            </td>
          ) : (
            sortedData.map((report) => {
              return (
                <tr key={report.login}>
                  <td>{report.login}</td>
                  <td className={styles.twoLineCell}>
                    <Tooltip content={report.clientName}>
                      <div className={styles.truncated}>{report.clientName}</div>
                    </Tooltip>
                    <Tooltip content={report.email}>
                      <div className={classNames(styles.truncated, styles.secondary)}>
                        {report.email}
                      </div>
                    </Tooltip>
                  </td>
                  <td>{report.accountType}</td>
                  <td>{formatDate(report.registrationDate, dateFormat)}</td>
                  <td>{report.status}</td>
                  <td>
                    <strong>{formatMoney(report.balance, report.currency)}</strong>
                  </td>
                  <td>{formatNumber(report.volumeSince2019)}</td>
                  <td>{formatNumber(report.volumeCurrentMonth)}</td>
                  <td>{formatNumber(report.volumePreviousMonth)}</td>
                  <td>{report.swapFree ? t('Yes') : t('No')}</td>
                  <td>{report.clientEntity}</td>
                  <td>{report.country}</td>
                </tr>
              )
            })
          )}
        </tbody>
      </Loading>
    </Table>
  )
}
