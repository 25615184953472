import {
  format,
  lastDayOfMonth,
  lastDayOfYear,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns'
import { t } from 'i18next'

import { FilterOptions, SearchField } from '../global/filter/FilterQueryModal'
import { Operator, SearchQueryKeys } from './ApiClient'

export enum DateFilterPresets {
  Today = 1,
  Yesterday,
  Last7Days,
  Last14Days,
  ThisMonth,
  LastMonth,
  Last3Months,
  Last6Months,
  LastYear,
  Custom,
  ThisWeek,
  ThisYear,
  All,
}

export interface IDateFilter {
  id: number
  value: string | [string, string]
  name: string
  operator?: Operator
  field?: 'DateFrom' | 'DateTo'
}

export interface internalQueryType {
  [key: string]: string | { value: string; operator: Operator } | undefined
}

interface SearchFieldRange {
  from: SearchField
  to: SearchField
}

const searchFields: SearchFieldRange[] = [
  { from: SearchField.DateFrom, to: SearchField.DateTo },
  { from: SearchField.ClientDateFrom, to: SearchField.ClientDateTo },
  { from: SearchField.ClientAmountFrom, to: SearchField.ClientAmountTo },
  { from: SearchField.ClientClientAmountFrom, to: SearchField.ClientClientAmountTo },
  { from: SearchField.QueryAmountFrom, to: SearchField.QueryAmountTo },
  { from: SearchField.QueryVolumeFrom, to: SearchField.QueryVolumeTo },
  { from: SearchField.ClientVolumeFrom, to: SearchField.ClientVolumeTo },
  { from: SearchField.ClientVolumeFrom, to: SearchField.ClientVolumeTo },
  { from: SearchField.ClientPriceFrom, to: SearchField.ClientPriceTo },
  { from: SearchField.ClientDateFrom, to: SearchField.ClientDateTo },
  { from: SearchField.ClientRegistrationDateFrom, to: SearchField.ClientRegistrationDateTo },
]

export function getAppliedFiltersLength<T extends SearchQueryKeys>(search: T): number {
  const searchCopy = Object.assign(search, {}) // do not operate on origin search object
  const searchKeys = Object.keys(searchCopy || {})

  const removeSameFilterType = ({ from, to }: SearchFieldRange) => {
    if (searchKeys.includes(from) && searchKeys.includes(to)) {
      delete searchCopy[to]
    }
  }

  searchFields.forEach(removeSameFilterType)

  if (searchKeys.includes('nested')) delete searchCopy['nested']
  const flatMap = Object.values(searchCopy || {})
    .filter((x) => !!x?.toString())
    .map((a) => {
      if (typeof a === 'number') {
        return a.toString().split(',')
      }
      if (typeof a === 'string' || a instanceof String) {
        return a.split(',')
      }
      // that means it's extended filter { name, operator }
      return [a?.value]
    })
  let filtersLengthApplied = 0
  flatMap.forEach((x) => {
    filtersLengthApplied = filtersLengthApplied + x.length
  })
  return filtersLengthApplied
}

export function getTransactionHistoryFiltersLength<T extends SearchQueryKeys>(search: T): number {
  const searchCopy = Object.assign(search, {})

  if (search[FilterOptions.TradingAccount]) {
    delete searchCopy[FilterOptions.TradingAccountPlatformType]
  }

  const searchKeys = Object.keys(searchCopy || {})

  const removeSameFilterType = ({ from, to }: SearchFieldRange) => {
    if (searchKeys.includes(from) && searchKeys.includes(to)) {
      delete searchCopy[to]
    }
  }

  searchFields.forEach(removeSameFilterType)

  if (searchKeys.includes('nested')) delete searchCopy['nested']
  const flatMapFilter = Object.values(searchCopy || {}).filter((x) => !!x?.toString())
  return flatMapFilter?.length
}

export function transformQuery(v?: string): string[] {
  return v?.split(',').filter((x) => x.length > 1) || []
}

export function getDateTime(): IDateFilter[] {
  const dates: IDateFilter[] = []
  dates.push({
    id: DateFilterPresets.Today,
    name: t('Today'),
    operator: Operator.EQUAL,
    value: format(new Date(), 'MM/dd/yyyy'),
  })
  dates.push({
    id: DateFilterPresets.Yesterday,
    name: t('Yesterday'),
    operator: Operator.EQUAL,
    value: format(subDays(new Date(), 1), 'MM/dd/yyyy'),
  })
  dates.push({
    id: DateFilterPresets.Last7Days,
    name: t('Last 7 days'),
    value: format(subDays(new Date(), 7), 'MM/dd/yyyy'),
  })
  dates.push({
    id: DateFilterPresets.Last14Days,
    name: t('Last 14 days'),
    value: format(subDays(new Date(), 14), 'MM/dd/yyyy'),
  })
  dates.push({
    id: DateFilterPresets.ThisMonth,
    name: t('This month'),
    value: [
      format(startOfMonth(subMonths(new Date(), 0)), 'MM/dd/yyyy'),
      format(new Date(), 'MM/dd/yyyy'),
    ],
  })
  dates.push({
    id: DateFilterPresets.LastMonth,
    name: t('Last month'),
    value: [
      format(startOfMonth(subMonths(new Date(), 1)), 'MM/dd/yyyy'),
      format(lastDayOfMonth(subMonths(new Date(), 1)), 'MM/dd/yyyy'),
    ],
  })
  dates.push({
    id: DateFilterPresets.Last3Months,
    name: t('Last 3 months'),
    value: [
      format(startOfMonth(subMonths(new Date(), 3)), 'MM/dd/yyyy'),
      format(lastDayOfMonth(subMonths(new Date(), 1)), 'MM/dd/yyyy'),
    ],
  })
  dates.push({
    id: DateFilterPresets.Last6Months,
    name: t('Last 6 months'),
    value: [
      format(startOfMonth(subMonths(new Date(), 6)), 'MM/dd/yyyy'),
      format(lastDayOfMonth(subMonths(new Date(), 1)), 'MM/dd/yyyy'),
    ],
  })
  dates.push({
    id: DateFilterPresets.LastYear,
    name: t('Last year'),
    value: [
      format(startOfYear(subYears(new Date(), 1)), 'MM/dd/yyyy'),
      format(lastDayOfYear(subYears(new Date(), 1)), 'MM/dd/yyyy'),
    ],
  })
  dates.push({
    id: DateFilterPresets.Custom,
    name: t('Custom'),
    value: ['', ''],
  })
  return dates
}

export function getCustomDateTime(presets?: DateFilterPresets[]): IDateFilter[] {
  const lastDayOfMonthValue = lastDayOfMonth(subMonths(new Date(), 1))
  const lastDayOfYearValue = lastDayOfYear(subYears(new Date(), 1))
  lastDayOfMonthValue.setHours(23)
  lastDayOfMonthValue.setMinutes(59)
  lastDayOfYearValue.setHours(23)
  lastDayOfYearValue.setMinutes(59)

  const defaultPresets: DateFilterPresets[] = [
    DateFilterPresets.Today,
    DateFilterPresets.Yesterday,
    DateFilterPresets.Last7Days,
    DateFilterPresets.Last14Days,
    DateFilterPresets.ThisMonth,
    DateFilterPresets.LastMonth,
    DateFilterPresets.Last3Months,
    DateFilterPresets.Last6Months,
    DateFilterPresets.LastYear,
    DateFilterPresets.Custom,
  ]

  const dateFilterPresets: Record<DateFilterPresets, IDateFilter> = {
    [DateFilterPresets.Today]: {
      id: DateFilterPresets.Today,
      name: t('Today'),
      operator: Operator.EQUAL,
      value: [format(new Date(), 'MM/dd/yyyy'), format(new Date(), 'MM/dd/yyyy')],
    },
    [DateFilterPresets.Yesterday]: {
      id: DateFilterPresets.Yesterday,
      name: t('Yesterday'),
      operator: Operator.EQUAL,
      value: [
        format(subDays(new Date(), 1), 'MM/dd/yyyy'),
        format(subDays(new Date(), 1), 'MM/dd/yyyy'),
      ],
    },
    [DateFilterPresets.Last7Days]: {
      id: DateFilterPresets.Last7Days,
      name: t('Last 7 days'),
      value: format(subDays(new Date(), 7), 'MM/dd/yyyy'),
    },
    [DateFilterPresets.Last14Days]: {
      id: DateFilterPresets.Last14Days,
      name: t('Last 14 days'),
      value: format(subDays(new Date(), 14), 'MM/dd/yyyy'),
    },
    [DateFilterPresets.ThisMonth]: {
      id: DateFilterPresets.ThisMonth,
      name: t('This month'),
      value: [
        format(startOfMonth(subMonths(new Date(), 0)), 'MM/dd/yyyy'),
        format(new Date(), 'MM/dd/yyyy'),
      ],
    },
    [DateFilterPresets.LastMonth]: {
      id: DateFilterPresets.LastMonth,
      name: t('Last month'),
      value: [
        format(startOfMonth(subMonths(new Date(), 1)), 'MM/dd/yyyy'),
        format(lastDayOfMonthValue, 'MM/dd/yyyy HH:mm:ss'),
      ],
    },
    [DateFilterPresets.Last3Months]: {
      id: DateFilterPresets.Last3Months,
      name: t('Last 3 months'),
      value: [
        format(startOfMonth(subMonths(new Date(), 3)), 'MM/dd/yyyy'),
        format(lastDayOfMonthValue, 'MM/dd/yyyy HH:mm:ss'),
      ],
    },
    [DateFilterPresets.Last6Months]: {
      id: DateFilterPresets.Last6Months,
      name: t('Last 6 months'),
      value: [
        format(startOfMonth(subMonths(new Date(), 6)), 'MM/dd/yyyy'),
        format(lastDayOfMonthValue, 'MM/dd/yyyy HH:mm:ss'),
      ],
    },
    [DateFilterPresets.LastYear]: {
      id: DateFilterPresets.LastYear,
      name: t('Last year'),
      value: [
        format(startOfYear(subYears(new Date(), 1)), 'MM/dd/yyyy'),
        format(lastDayOfYearValue, 'MM/dd/yyyy HH:mm:ss'),
      ],
    },
    [DateFilterPresets.Custom]: {
      id: DateFilterPresets.Custom,
      name: t('Custom'),
      value: ['', ''],
    },
    [DateFilterPresets.ThisWeek]: {
      id: DateFilterPresets.ThisWeek,
      name: t('This Week'),
      value: [
        format(startOfWeek(subWeeks(new Date(), 0)), 'MM/dd/yyyy'),
        format(new Date(), 'MM/dd/yyyy'),
      ],
    },
    [DateFilterPresets.ThisYear]: {
      id: DateFilterPresets.ThisYear,
      name: t('This Year'),
      value: [
        format(startOfYear(subYears(new Date(), 0)), 'MM/dd/yyyy'),
        format(new Date(), 'MM/dd/yyyy'),
      ],
    },
    [DateFilterPresets.All]: {
      id: DateFilterPresets.All,
      name: t('All'),
      value: '',
    },
  }

  const dates: IDateFilter[] = []

  if (presets) {
    presets.forEach((item) => dates.push(dateFilterPresets[item]))
  } else {
    defaultPresets.forEach((item) => dates.push(dateFilterPresets[item]))
  }

  return dates
}

export function setQuery(f?: IDateFilter): internalQueryType | undefined {
  if (!f) {
    return undefined
  }
  switch (f.id) {
    case DateFilterPresets.Today:
      return {
        DateFrom: undefined,
        DateTo: undefined,
        DateCreated: {
          operator: Operator.EQUAL,
          value: Array.isArray(f.value) ? f.value[0] : f.value,
        },
      }
    case DateFilterPresets.Yesterday:
      return {
        DateFrom: undefined,
        DateTo: undefined,
        DateCreated: {
          operator: Operator.EQUAL,
          value: Array.isArray(f.value) ? f.value[0] : f.value,
        },
      }
    case DateFilterPresets.Last7Days:
    case DateFilterPresets.Last14Days:
      return {
        DateFrom: undefined,
        DateTo: undefined,
        DateCreated: {
          operator: Operator.MORE_OR_EQUAL,
          value: Array.isArray(f.value) ? f.value[0] : f.value,
        },
      }
    case DateFilterPresets.ThisMonth:
    case DateFilterPresets.LastMonth:
    case DateFilterPresets.Last3Months:
    case DateFilterPresets.Last6Months:
    case DateFilterPresets.LastYear:
    case DateFilterPresets.Custom:
      return {
        DateCreated: undefined,
        DateFrom: {
          operator: Operator.MORE_OR_EQUAL,
          value: Array.isArray(f.value) ? f.value[0] : f.value,
        },
        DateTo: {
          operator: Operator.LESS_OR_EQUAL,
          value: Array.isArray(f.value) ? f.value[1] : f.value,
        },
      }
    default:
      return undefined
  }
}

export function getCurrentDateFilterFromDateString(
  dateString?: {
    value: string
    operator: Operator
  },
  dates?: IDateFilter[]
): IDateFilter | undefined {
  if (!dateString) return undefined
  const currDate = format(new Date(dateString.value), 'MM/dd/yyyy')
  const findGetTime = (dates || getDateTime()).find((x) => x.value === currDate)
  if (!findGetTime) return undefined
  return findGetTime
}

export function getCurrentCustomFilter(
  dateString?: {
    value: string
    operator: Operator
  },
  includeTime?: boolean
): IDateFilter | undefined {
  if (!dateString) return undefined
  const currDate = includeTime
    ? format(new Date(dateString.value), 'MM/dd/yyyy HH:mm', {})
    : format(new Date(dateString.value), 'MM/dd/yyyy')
  return {
    id: 100,
    name: 'Custom',
    value: currDate,
  }
}

export const prefixFilter = ({
  prefix,
  value,
}: {
  prefix?: string
  value?: string | number
}): string | undefined => {
  if (!value) return undefined
  return prefix + '__' + value.toString()
}
