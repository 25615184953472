import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { Button } from '../../global/button/Button'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { DotsIcon } from '../../icons/DotsIcon'
import { WarningIcon } from '../../icons/WarningIcon'
import { AlertIconWrapper } from '../../ui/AlertIconWrapper/AlertIconWrapper'
import { TextSmall, TextSmallStrong } from '../../ui/Typography/Typography'
import { WalletRestrictions, isRestricted, isWalletSuspended } from '../../utils/wallet.utils'
import { IntroducingBrokerMappedDto } from './IBWalletsPage'

import styles from './IBWalletCard.module.scss'

interface WalletCardProps {
  wallet: IntroducingBrokerMappedDto
  setModalVisible: (v: boolean) => void

  setWalletNumberModal({
    visible,
    data,
  }: {
    visible: boolean
    data?: IntroducingBrokerMappedDto
  }): void
}

export const IBWalletCard: React.FC<WalletCardProps> = ({
  wallet,
  setModalVisible,
  setWalletNumberModal,
}) => {
  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()

  const hasAllRestrictions = isWalletSuspended(wallet.restrictions)
  const isTransferFromRestricted = isRestricted(
    WalletRestrictions.TRANSFER_FROM,
    wallet.restrictions
  )
  const isWithdrawRestricted = isRestricted(WalletRestrictions.WITHDRAW, wallet.restrictions)
  return (
    <div className={styles.card}>
      <TextSmall className={styles.warning}>
        {hasAllRestrictions && <WarningIcon size={20} color={'error'} />}
        {wallet.currency.id} {t('Wallet.IB Wallet')}
      </TextSmall>
      <span className='is-pulled-right'>
        <span
          onClick={() =>
            setWalletNumberModal({
              visible: true,
              data: wallet,
            })
          }
          className='has-cursor-pointer'
        >
          <DotsIcon />
        </span>
      </span>
      <h2 className={styles.currency}>{formatMoney(wallet.balance, wallet.currency.id)}</h2>
      <div className='mb-1'>
        <Link
          to={
            isTransferFromRestricted
              ? '#'
              : `/dashboard/introducing-broker/wallets/${wallet.id}/transfer`
          }
        >
          <AlertIconWrapper
            fullWidth
            spaced
            isActive={!hasAllRestrictions && isTransferFromRestricted}
          >
            <Button
              onClick={() =>
                !hasAllRestrictions && isTransferFromRestricted && setModalVisible(true)
              }
              size='M'
              appearance='primary'
              disabled={isTransferFromRestricted}
              className={classNames(styles.button, {
                [styles.icon]: !hasAllRestrictions && isTransferFromRestricted,
              })}
            >
              {t('Transfer')}
            </Button>
          </AlertIconWrapper>
        </Link>
      </div>
      <div className='mb-4'>
        <Link
          to={
            isWithdrawRestricted
              ? '#'
              : `/dashboard/introducing-broker/wallets/${wallet.id}/withdrawal`
          }
        >
          <AlertIconWrapper spaced fullWidth isActive={!hasAllRestrictions && isWithdrawRestricted}>
            <Button
              onClick={() => !hasAllRestrictions && isWithdrawRestricted && setModalVisible(true)}
              size='M'
              appearance='secondary'
              disabled={isWithdrawRestricted}
              className={classNames(styles.button, {
                [styles.icon]: !hasAllRestrictions && isWithdrawRestricted,
              })}
            >
              {t('Withdraw')}
            </Button>
          </AlertIconWrapper>
        </Link>
      </div>
      <div className={styles.title}>
        <TextSmall isParagraph className={styles.label}>
          {t('IB.Incoming Reserved')}
        </TextSmall>
        <TextSmallStrong className={styles.value}>
          {formatMoney(wallet.incomingReserved, wallet.currency.id)}
        </TextSmallStrong>
      </div>
      <div className={styles.title}>
        <TextSmall isParagraph className={styles.label}>
          {t('IB.Outgoing Reserved')}
        </TextSmall>
        <TextSmallStrong className={styles.value}>
          {formatMoney(wallet.reserved, wallet.currency.id)}
        </TextSmallStrong>
      </div>
      <div className={styles.title}>
        <TextSmall isParagraph className={styles.label}>
          {t('IB.All Time Reward')}
        </TextSmall>
        <TextSmallStrong className={styles.value}>
          {formatMoney(wallet.allTimeReward, wallet.currency.id)}
        </TextSmallStrong>
      </div>
      <div className={classNames('pt-4', styles.view)}>
        <Link
          className={styles.history}
          to='/dashboard/introducing-broker/transaction-history'
          state={{ walletId: wallet.id }}
        >
          {t('View Transaction History')}
        </Link>
      </div>
    </div>
  )
}
