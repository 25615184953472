import React, { PropsWithChildren, ReactNode } from 'react'
import classNames from 'classnames'

import { CloseIcon } from '../../icons/CloseIcon'
import { DummyIcon } from '../../icons/DummyIcon'
import { TextSmall } from '../../ui/Typography/Typography'
import IconButton from '../iconButton/IconButton'

import styles from './FormTemplate.module.scss'

interface CommandPageTemplateProps {
  title: string
  subtitle?: string | ReactNode

  goBack?(): void

  contentClassName?: string
  headerDisplayType?: 'grid' | 'flex'
  wrapperClassName?: string

  titleClassName?: string
  goBackIcon?: JSX.Element
  wrapped?: boolean
  bottomText?: string

  renderIcon?: ReactNode
}

export const FormTemplate: React.FC<PropsWithChildren<CommandPageTemplateProps>> = (props) => {
  const {
    titleClassName,
    goBackIcon,
    subtitle,
    wrapped,
    children,
    title,
    goBack,
    contentClassName,
    headerDisplayType = 'grid',
    wrapperClassName,
    renderIcon,
  } = props

  return (
    <div
      className={classNames(styles.wrapper, wrapperClassName, {
        [styles.wrapped]: !!wrapped,
      })}
    >
      <div
        className={classNames(styles.title, {
          [styles.grid]: headerDisplayType === 'grid',
          [styles.flex]: headerDisplayType === 'flex',
        })}
      >
        <div className={styles.closeButtonWrapper}>
          {goBack && <IconButton onClick={goBack}>{goBackIcon ?? <CloseIcon />}</IconButton>}
        </div>

        <h3 className={titleClassName}>{title}</h3>

        <div className={styles.iconWrapper}>{renderIcon || <DummyIcon />}</div>
      </div>
      {!!subtitle && <TextSmall className={styles.subtitle}>{subtitle}</TextSmall>}
      <div
        className={classNames(styles.content, contentClassName, {
          [styles.noWrapper]: !!wrapped,
        })}
      >
        {children}
      </div>
      {!!props.bottomText && (
        <TextSmall className='text-align-center'>{props.bottomText}</TextSmall>
      )}
    </div>
  )
}
