import { useEffect, useState } from 'react'

import { useSessionLanguage } from '../../../global/context/SessionSettingsContext'
import { ClientAreaLanguageDto } from '../../../model/ClientAreaLanguageDto'
import { LandingPageDto } from '../../../model/LandingPageDto'

export interface UseSelectLanguageValue {
  language: string
  setLanguage(value: string): void
  selectedLandingPage: LandingPageDto | undefined
  setSelectedLandingPage(value: LandingPageDto): void
}

interface UseSelectLanguageParams {
  languages: ClientAreaLanguageDto[]
  referralCode?: string
}

export const useSelectLanguage = ({
  languages,
  referralCode,
}: UseSelectLanguageParams): UseSelectLanguageValue => {
  const locale = useSessionLanguage()

  const [language, setLanguage] = useState('en')
  const [selectedLandingPage, setSelectedLandingPage] = useState<LandingPageDto>()

  useEffect(() => {
    if (languages.find((x) => x.id === locale)) setLanguage(locale)
    else setLanguage('en')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languages, locale])

  useEffect(() => {
    setSelectedLandingPage(undefined)
  }, [referralCode, language])

  return {
    language,
    setLanguage,
    selectedLandingPage,
    setSelectedLandingPage,
  }
}
