import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'

import { Loading } from '../../global/Loading/Loading'
import { useSessionEntity } from '../../global/context/EntityContext'
import { useProductReadContext } from '../../global/context/ProductContext'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { InfoModal } from '../../global/modal/InfoModal'
import { InformationModal } from '../../global/modal/InformationModal'
import { Modal } from '../../global/modal/Modal'
import {
  hasProductPendingDocumentVerificationStatus,
  isAccountDetailedActivatedStatus,
} from '../../model/AccountDetailedDto'
import { ClientNciPriorityDto } from '../../model/ClientNciPriorityDto'
import { CreateClientDocument } from '../../model/CreateClientDocuments'
import { ClientNciHistoryStatus } from '../../model/CreateNciHistory'
import { DocumentCategoryType } from '../../model/DocumentCategories'
import { useAccountReadContext, useAccountWriteContext } from '../../utils/AccountContextContext'
import { useApiClient } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { ClientApiClient } from '../../utils/clientApi'
import { TickmillCompaniesEnum } from '../../utils/companyName.utils'
import { isTradersRoomRouteAllowed } from '../../utils/permission.utils'
import { useFetchOne } from '../../utils/useFetch'
import { clearLocalStorage } from '../../utils/useStorage'
import { isOne, isZero } from '../../utils/validations'
import { LeftSection, SignupFormWrapper, SignupStep, SignupWrapper } from '../SignupPage'
import { DocumentInfoPageProofOfAddressStepForm } from './DocumentInfoPageProofOfAddressStepForm'
import { DocumentInfoPageStep1Page } from './DocumentInfoPageStep1/DocumentInfoPageStep1Page'
import { DocumentInfoPageStepPendingForm } from './DocumentInfoPageStepPending'

import styles from './DocumentsInfoPage.module.scss'

interface AccountNciAvailablePrioritiesProps {
  counter: { value: number; isLocked: boolean }
}

const useAccountNciAvailablePriorities = (props: AccountNciAvailablePrioritiesProps) => {
  const { counter } = props

  const locale = useSessionLanguage()
  const apiClient = useApiClient(ClientApiClient)

  const [accountNciAvailablePriorities, setAccountNciAvailablePriorities] =
    useState<ClientNciPriorityDto>()

  const accountNciAvailablePrioritiesCallback = useCallback(async () => {
    if (!counter.isLocked) return apiClient.getAccountNciAvailablePriorities(locale)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, locale])

  const { data: accountNciAvailablePrioritiesFetch, isLoading } = useFetchOne<ClientNciPriorityDto>(
    accountNciAvailablePrioritiesCallback
  )

  useEffect(() => {
    if (accountNciAvailablePrioritiesFetch)
      setAccountNciAvailablePriorities(accountNciAvailablePrioritiesFetch)
  }, [accountNciAvailablePrioritiesFetch])

  return {
    accountNciAvailablePriorities,
    setAccountNciAvailablePriorities,
    isLoading,
  }
}

const useDocumentCategories = () => {
  const locale = useSessionLanguage()
  const apiClient = useApiClient(ClientApiClient)
  const { account } = useAccountReadContext()

  const documentTypesCallback = useCallback(async () => {
    return apiClient.getDocumentCategories(DocumentCategoryType.Address, locale)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account?.type.id, locale])

  const { data: documentTypes } = useFetchOne(documentTypesCallback)

  return { documentTypes }
}

export const DocumentsInfoPage: React.FC = () => {
  const { clientId } = useParams<{ clientId?: string }>()
  const navigate = useNavigate()

  const apiClient = useApiClient(ClientApiClient)
  const { account } = useAccountReadContext()
  const { refreshAccount } = useAccountWriteContext()
  const locale = useSessionLanguage()
  const entity = useSessionEntity()
  const { product } = useProductReadContext()

  const { t } = useTranslation()
  const [_, setAuth] = useContext(AuthSessionContext) // eslint-disable-line @typescript-eslint/no-unused-vars

  const [step, setStep] = useState(0)
  const [counter, setCounter] = useState({ value: 0, isLocked: false })
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [alreadyUploadedModal, setAlreadyUploadedModal] = useState(false)

  const { accountNciAvailablePriorities, setAccountNciAvailablePriorities, isLoading } =
    useAccountNciAvailablePriorities({ counter })

  const { documentTypes } = useDocumentCategories()

  useEffect(() => {
    // refreshAccount(locale);
    setStep(hasProductPendingDocumentVerificationStatus(account, product) ? 0 : 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const handleSubmitReason = async (reason: string) => {
    await apiClient.accountAcceptNciHistory({
      statusId: ClientNciHistoryStatus.INACTIVE,
      nciPriorityId: accountNciAvailablePriorities?.id || '',
      reason,
    })
    setCounter({ value: counter.value + 1, isLocked: false })
  }

  const handleChoosePreviousDoc = async (doc: ClientNciPriorityDto, count: number) => {
    setAccountNciAvailablePriorities(doc)
    setCounter({ value: count, isLocked: true })
  }

  const handleUploadDocuments = async (documents: CreateClientDocument[]) => {
    try {
      await apiClient.uploadDocuments({ documents })
      setStep(4)
    } catch {
      setAlreadyUploadedModal(true)
    }
  }

  const handlePendingSubmit = async () => {
    return setStep(1)
  }

  const handleSubmit = async () => {
    setStep(2)
  }

  const handleSkip = async () => {
    if (isZero(step)) {
      return setStep(1)
    }
    if (isOne(step)) {
      return setStep(2)
    }
    if (step === 2) {
      return setStep(4)
    }
  }

  const handleSkipDocuments = async () => {
    await refreshAccount(locale)
    const path = isTradersRoomRouteAllowed(account)
      ? '/dashboard/traders-room/wallets'
      : '/dashboard/introducing-broker/wallets'
    navigate(path)
  }

  const handleCloseErrorModal = () => {
    setAlreadyUploadedModal(false)
  }

  if (isAccountActivated(account) && isAccountId(account?.id, clientId)) {
    setAuth(null)
    clearLocalStorage()
    navigate('/logout')
    return null
  }

  if (step === 4) {
    navigate('/users/register-confirm')
  }

  return (
    <Loading isLoading={isLoading}>
      {alreadyUploadedModal && (
        <Modal
          closeModal={handleCloseErrorModal}
          render={() => (
            <InformationModal
              onCancel={handleCloseErrorModal}
              buttonText={t('Got It')}
              title={t('Upload failed')}
            >
              <p className={styles.text}>{t('The document has been already uploaded')}</p>
            </InformationModal>
          )}
        />
      )}

      {confirmationModal && (
        <Modal
          closeModal={() => setConfirmationModal(false)}
          render={({ closeModal }) => (
            <InfoModal
              onCancel={closeModal}
              title={t('Sign up.Cancel Document Upload')}
              renderBody={() => (
                <section className='modal-card-body'>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t('Sign up.Do you really like to skip uploading Document now?'),
                    }}
                  />
                </section>
              )}
              renderFooter={() => (
                <React.Fragment>
                  <button
                    className='button'
                    onClick={() => setConfirmationModal(false)}
                    type='button'
                  >
                    {t('Sign up.Back to Document Upload')}
                  </button>
                  <button className='button' onClick={handleSkipDocuments} type='button'>
                    <b>{t('Sign up.Yes, Cancel Document Upload')}</b>
                  </button>
                </React.Fragment>
              )}
              onConfirm={handleSkipDocuments}
            />
          )}
        />
      )}
      <SignupFormWrapper label={t('Sign up.Sign up')}>
        <SignupStep subStep={{ current: step, total: 2 }} section={4} />
        <SignupWrapper reversed={step === 2}>
          <React.Fragment>
            <LeftSection
              title={t('Sign up.Documents')}
              description={() => (
                <React.Fragment>
                  {entity === TickmillCompaniesEnum.TICKMILL_UK ? (
                    <p className={styles.text}>
                      {t(
                        'Sign up.We just need to verify your identity. Get your identification documents ready.'
                      )}
                    </p>
                  ) : (
                    <p className={styles.text}>
                      {t(
                        'Sign up.We just need to verify your identity. Get your identification documents ready and you should complete this step in about 5 minutes.'
                      )}
                    </p>
                  )}
                </React.Fragment>
              )}
            />
            <section className={classNames(styles.formSection, styles.wrapper)}>
              {isZero(step) && (
                <DocumentInfoPageStepPendingForm
                  handleSubmit={handlePendingSubmit}
                  onSkip={handleSkip}
                  onSkipDocuments={handleSkipDocuments}
                />
              )}
              {accountNciAvailablePriorities && isOne(step) && (
                <DocumentInfoPageStep1Page
                  accountNciAvailablePriorities={accountNciAvailablePriorities}
                  handleSubmitReason={handleSubmitReason}
                  handleChoosePreviousDoc={handleChoosePreviousDoc}
                  handleSubmit={handleSubmit}
                  onSkip={handleSkip}
                  onSkipDocuments={handleSkipDocuments}
                />
              )}
              {step === 2 && (
                <DocumentInfoPageProofOfAddressStepForm
                  handleSubmit={handleUploadDocuments}
                  onSkip={handleSkip}
                  onSkipDocuments={handleSkipDocuments}
                  documentTypes={documentTypes}
                />
              )}
            </section>
          </React.Fragment>
        </SignupWrapper>
      </SignupFormWrapper>
    </Loading>
  )
}

const isAccountActivated = (account: any) => {
  return isAccountDetailedActivatedStatus(account?.status.id || -1)
}

const isAccountId = (accountId: any, clientId: any) => {
  return accountId !== clientId
}
