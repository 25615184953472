import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import { AppMarketQRCode } from '../global/appMarketLink/desktop/AppMarketQRCode'
import { AppMarketLink } from '../global/appMarketLink/mobile/AppMarketLink'
import { Button } from '../global/button/Button'
import { useSessionEntity } from '../global/context/EntityContext'
import { TickmillLogoIcon } from '../icons/TickmillLogoIcon'
import { isTickmillPartnerType } from '../utils/companyName.utils'

import styles from './LogoutPage.module.scss'

export const LogoutPage = () => {
  const navigate = useNavigate()
  const entity = useSessionEntity()
  const { t } = useTranslation()
  return (
    <div className={styles.topWrapper}>
      <div className={styles.wrapper}>
        <div className={styles.qrCode}>
          <AppMarketQRCode />
        </div>
        <div className={styles.inner}>
          <TickmillLogoIcon />
          <span className={styles.title}>{t('You have been successfully logged out')}</span>
          <Button type='button' onClick={() => navigate('/login')} size='L' appearance='secondary'>
            <span className={styles.login}>{t('Login')}</span>
          </Button>
          {!isTickmillPartnerType(entity) && (
            <div className={styles.signUpBox}>
              <span>{t('Not a member')}</span>
              <Link to='/sign-up'>{t('Sign up.Sign up')}</Link>
            </div>
          )}
        </div>
        <div className={styles.marketLink}>
          <AppMarketLink />
        </div>
      </div>
    </div>
  )
}
