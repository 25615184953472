import { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Loading } from '../../global/Loading/Loading'
import { useProductReadContext } from '../../global/context/ProductContext'
import { InformationModal } from '../../global/modal/InformationModal'
import { Modal } from '../../global/modal/Modal'
import { AccountGroupType } from '../../model/AccountGroupType'
import { CampaignStatusEnum } from '../../model/CampaignStatusEnum'
import { PlatformTypeEnum } from '../../model/PlatformTypeEnum'
import { isTickmillProductTypeCFD, isTickmillProductTypeETD } from '../../model/TickmillProductType'
import { TradingAccount } from '../../model/TradingAccount'
import { WalletTypeEnum } from '../../model/WalletDto'
import { Operator, useApiClient } from '../../utils/ApiClient'
import { useGuide } from '../../utils/FirstTimeGuideContext'
import { SharedContext } from '../../utils/SharedContext'
import { ClientApiClient } from '../../utils/clientApi'
import { useFetchOne } from '../../utils/useFetch'
import { isOne } from '../../utils/validations'
import { WalletRestrictions, isRestricted, isWalletSuspended } from '../../utils/wallet.utils'
import { FirstTimeGuideCarouselCFD } from './FirstTimeGuideCarouselCFD'
import { FirstTimeGuideCarouselETD } from './FirstTimeGuideCarouselETD'

import styles from './FirstTimeGuide.module.scss'

export const FirstTimeGuideCarousel = ({
  walletType,
}: {
  walletType?: WalletTypeEnum
}): JSX.Element => {
  const { product } = useProductReadContext()
  const { guide, fetchGuide, isLoading } = useGuide()
  const { t } = useTranslation()
  const [sharedState, setSharedState] = useContext(SharedContext)

  const apiClient = useApiClient(ClientApiClient)

  const [campaignAccount, setCampaignAccount] = useState<TradingAccount | undefined>()
  const [depositOptionModal, setDepositOptionModal] = useState(false)
  const [selectedDepositType, setSelectedDepositType] = useState<string | undefined>()

  const closeDepositOptionModal = () => setDepositOptionModal(false)
  const openDepositOptionModal = () => setDepositOptionModal(true)

  const navigate = useNavigate()

  const walletsCallback = useCallback(async () => {
    let { walletTypes } = sharedState
    if (!sharedState.walletTypes.length) {
      walletTypes = await apiClient.getWalletTypes()
    }
    const walletTypeId = WalletTypeEnum['FX/CFD']
    setSharedState((currentState) => ({
      ...currentState,
      walletTypes,
    }))

    return await apiClient.getWallets({ walletTypeId })
  }, [apiClient])

  const { data: wallets = [] } = useFetchOne(walletsCallback)

  const handleDepositChange = (depositTo: string) => {
    setSelectedDepositType(depositTo)
    closeDepositOptionModal()
    if (campaignAccount && depositTo === 'campaign account') {
      navigate(`/dashboard/traders-room/trading-accounts/${campaignAccount.id}/deposit-to-campaign`)
      setSelectedDepositType(undefined)
    } else {
      navigate(`/dashboard/traders-room/wallets/${depositTo}/deposit`)
      setSelectedDepositType(undefined)
    }
  }

  const [isDepositLoading, setIsDepositLoading] = useState(false)
  const handleDeposit = async () => {
    setIsDepositLoading(true)
    openDepositOptionModal()
    try {
      const tradingAccounts = await apiClient.getTradingAccounts({
        search: {
          Campaign: {
            value: true,
            operator: Operator.IS_NOT_EMPTY,
          },
          AccountGroupType: {
            value: AccountGroupType.Live,
            operator: Operator.EQUAL,
          },
          Platform: {
            value: PlatformTypeEnum.CQGCAST,
            operator: Operator.NOT_EQUAL,
          },
          Status: {
            value: CampaignStatusEnum.Enabled,
            operator: Operator.EQUAL,
          },
        },
      })

      if (tradingAccounts.items.length) {
        setCampaignAccount(tradingAccounts.items.find((x) => !!x.campaigns.length))
      } else if (isOne(wallets.length)) {
        closeDepositOptionModal()
        navigate(`/dashboard/traders-room/wallets/${wallets[0].id}/deposit`)
      } else {
        closeDepositOptionModal()
        navigate('/dashboard/traders-room/wallets/deposit')
      }
    } finally {
      setIsDepositLoading(false)
    }
  }

  if (guide?.shouldShow) {
    return (
      <Loading isLoading={isLoading}>
        {depositOptionModal && (
          <Modal
            closeModal={closeDepositOptionModal}
            render={() => (
              <InformationModal
                spacing='compact'
                title={t('Wallet.Deposit To')}
                onCancel={closeDepositOptionModal}
                buttonText={t('Cancel')}
              >
                <Loading isLoading={isDepositLoading}>
                  <ul>
                    <li className={styles.hasBorderBottom}>
                      <label className='radio pt-5 pb-5 pl-6'>
                        <input
                          value={'campaign account'}
                          type='radio'
                          name='depositTo'
                          className='mr-1'
                          onChange={() => handleDepositChange('campaign account')}
                          checked={selectedDepositType === 'campaign account'}
                        />
                        {t('Campaign Account')}
                      </label>
                    </li>
                    {wallets.map((wallet) => {
                      const isWalletAllowed = !(
                        isRestricted(WalletRestrictions.DEPOSIT, wallet.restrictions) ||
                        isWalletSuspended(wallet.restrictions)
                      )
                      if (!isWalletAllowed) return
                      return (
                        <li key={wallet.id}>
                          <label className='radio pt-5 pb-5 pl-6'>
                            <input
                              value={wallet.id}
                              type='radio'
                              name='depositTo'
                              className='mr-1'
                              onChange={(e) => handleDepositChange(e.target.value)}
                              checked={selectedDepositType === wallet.id}
                            />
                            {`${wallet.currency.id} ${t('Wallet.Wallet')}`}
                          </label>
                        </li>
                      )
                    })}
                  </ul>
                </Loading>
              </InformationModal>
            )}
          />
        )}
        {isTickmillProductTypeCFD(product) && (
          <FirstTimeGuideCarouselCFD
            reloadGuide={fetchGuide}
            walletType={walletType}
            handleDeposit={handleDeposit}
          />
        )}
        {isTickmillProductTypeETD(product) && (
          <FirstTimeGuideCarouselETD reloadGuide={fetchGuide} handleDeposit={handleDeposit} />
        )}
      </Loading>
    )
  }

  return <></>
}
