import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'

import { Loading } from '../global/Loading/Loading'
import { Tabs } from '../global/tabs/Tabs'
import { isNoTabsPath } from '../utils/path'
import { TradersRoomRoutesETD } from './TradersRoomRoutes'

import styles from './TradersRoom.module.scss'

export const TradersRoomETD: React.FC = () => {
  const { tab } = useParams<{ tab?: string }>()

  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()

  return (
    <Loading isLoading={false}>
      <React.Fragment>
        <div>
          {!isNoTabsPath(location.pathname) && (
            <div className={classNames('section', styles.adminRoutes)}>
              <Tabs
                tabs={[
                  {
                    id: 'balances',
                    name: t('Tabs.Balances'),
                    disabled: false,
                  },
                  {
                    id: 'transactions',
                    name: t('Tabs.Transactions'),
                    disabled: false,
                  },
                  {
                    id: 'transaction-history',
                    name: t('Tabs.Transaction History'),
                    disabled: false,
                  },
                ]}
                currentTab={tab || ''}
                appearance='light'
                onTabChanged={(tab) => {
                  return navigate(`/dashboard/traders-room/${tab}`)
                }}
              />
            </div>
          )}
          <TradersRoomRoutesETD tab={tab} />
        </div>
      </React.Fragment>
    </Loading>
  )
}
