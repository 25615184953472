import React, { ChangeEvent, useRef, useState } from 'react'
import classNames from 'classnames'
import { Field, getIn, useFormikContext } from 'formik'
import type { FieldConfig as FormikFieldConfig } from 'formik/dist/Field'

import { TextTiny } from '../../ui/Typography/Typography'
import { isZero } from '../../utils/validations'
import { DeepNonNullable } from '../types/DeepNonNullable'
import type { DeepRequired } from '../types/DeepRequired'
import type { TypedFieldProps } from '../types/TypedFieldProps'
import type { ValidateField } from '../types/ValidateField'
import { getFieldValid, getLabel, getName, getPlaceholder, isFocus } from './helpers'

import styles from './FormField.module.scss'

interface FormFieldProps extends Omit<FormikFieldConfig, 'name' | 'value'> {
  namePrefix?: string
  helpText?: string
  highlighted?: boolean
  icon?: React.ReactNode
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  hint?: string
  min?: number
  max?: number
  decimalPlaces?: number
  onChange?(
    value: React.ChangeEvent<HTMLInputElement>,
    callback: (event: ChangeEvent<HTMLInputElement>) => void
  ): void
  onBlur?(
    value: React.ChangeEvent<HTMLInputElement>,
    callback: (event: ChangeEvent<HTMLInputElement>) => void
  ): void
  onClick?(): void
}

export function RawFormNumericField<FormValues>(
  props: FormFieldProps & TypedFieldProps<FormValues>
): JSX.Element {
  const {
    namePrefix = '',
    name = '',
    min,
    max,
    icon,
    leftIcon,
    rightIcon,
    hint,
    className,
    onChange,
    onBlur,
    decimalPlaces,
    showLabel = false,
    ...inputProps
  } = props

  const fieldRef = useRef<HTMLDivElement>(null)
  const [valueState, setValueState] = useState<string | undefined>(undefined)
  const context = useFormikContext<FormValues>()

  const value = getIn(context.values, name)

  const error = getIn(context.errors, name)
  const touched = getIn(context.touched, name)

  const label = getLabel(props)
  const placeholder = getPlaceholder(props, value)
  const isValid = getFieldValid(error, touched)

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    if (decimalPlaces !== undefined) {
      const regex = new RegExp(`^\\d*\\.?\\d{0,${decimalPlaces}}$`)
      if (!regex.test(value)) return
    }

    const eventWithLimitedDecimals = {
      ...event,
      target: { ...event.target, value },
    }

    if (onChange) {
      onChange(eventWithLimitedDecimals, handleField(eventWithLimitedDecimals))
      setValueState(value)
    } else {
      handleField(eventWithLimitedDecimals)()
      setValueState(value)
    }
  }

  const handleFieldBlur = (event: ChangeEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(event, handleField(event))
      setValueState(undefined)
    } else {
      handleField(event)()
      setValueState(undefined)
    }
  }

  const handleField = (event: ChangeEvent<HTMLInputElement>) => () => {
    const value = Number.parseFloat(event.target.value)

    context.setFieldTouched(name)

    if (isNumericFieldMinValid(value, min)) {
      context.setFieldValue(name, min)
    } else if (max !== undefined && isNumericFieldMaxValid(value, max)) {
      context.setFieldValue(name, max)
    } else {
      context.setFieldValue(name, !isNaN(value) ? value : undefined)
    }
  }

  return (
    <React.Fragment>
      <div className={classNames('field', styles.field)} ref={fieldRef}>
        {(showLabel ||
          isFocus({
            value: getValue(valueState, value),
            label,
          })) &&
          !!label && (
            <label
              className={classNames(styles.labelSecondary, {
                'has-text-danger': !isValid,
              })}
            >
              {label}
            </label>
          )}
        <div className={classNames('control')}>
          <Field
            name={getName(namePrefix, props)}
            {...inputProps}
            label={label}
            placeholder={placeholder}
            value={getValue(valueState, value) || ''}
            type='number'
            min={min}
            onKeyDown={(e: { key: string; preventDefault: () => any }) =>
              ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
            }
            onChange={handleFieldChange}
            onBlur={handleFieldBlur}
            onWheel={(event: ChangeEvent<HTMLInputElement>) => event.target.blur()}
            className={classNames('input', styles.input, {
              [styles.inputFocus]: isFocus({
                value: getValue(valueState, value),
                label,
              }),
              [styles.inputError]: !isValid,
              [styles.isIconLeft]: !!leftIcon,
              [styles.isIconRight]: !!(icon || rightIcon),
            })}
          />

          <span
            className={classNames(styles.icon, styles.iconLeft, {
              [styles.inputFocus]: isFocus({
                value: getValue(valueState, value),
                label,
              }),
            })}
          >
            {leftIcon}
          </span>
          <span className={classNames(styles.icon, styles.iconRight)}>{icon || rightIcon}</span>
        </div>

        {hint && <TextTiny className={styles.hint}>{hint}</TextTiny>}

        {!isValid && (
          <TextTiny isParagraph className={classNames(styles.errorMessage, 'is-danger')}>
            {error}
          </TextTiny>
        )}
      </div>
    </React.Fragment>
  )
}

const getValue = (valueState: string | number | undefined, value: string | number) => {
  if (valueState === '') return undefined
  if (typeof valueState === 'number' && valueState <= 0) return valueState
  return valueState || value
}

export const isNumericFieldMinValid = (value: number, min = 0): boolean => {
  const hasMin = min || isZero(min)
  return !!(hasMin && value < min)
}

export const isNumericFieldMaxValid = (value: number, max = 0): boolean => {
  const hasMax = max || isZero(max)
  return !!(hasMax && value > max)
}

export const isNumericFieldRangeValid = (value = 0, min: number, max: number): boolean => {
  if (isNaN(value) || value === undefined || value === null) {
    return false
  }

  const isMinValid = !isNumericFieldMinValid(value, min)
  const isMaxValid = !isNumericFieldMaxValid(value, max)

  return isMinValid && isMaxValid
}

export function createFormNumericField<FormValues>() {
  return function TypedField<Name extends ValidateField<DeepNonNullable<DeepRequired<FormValues>>>>(
    props: FormFieldProps & TypedFieldProps<DeepNonNullable<DeepRequired<FormValues>>, Name>
  ): JSX.Element {
    return <RawFormNumericField {...props} />
  }
}
