import React, { FormEventHandler, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikErrors, FormikProps, useFormikContext, withFormik } from 'formik'

import { Button } from '../../global/button/Button'
import { useArabicSessionLanguage } from '../../global/context/SessionSettingsContext'
import { FieldGroup } from '../../global/form/FieldGroup'
import { createFormField } from '../../global/formField/FormField'
import IconButton from '../../global/iconButton/IconButton'
import { CountrySelectModal } from '../../global/modal/CountrySelectModal'
import { Modal } from '../../global/modal/Modal'
import { BackIcon } from '../../icons/BackIcon'
import { CloseIcon } from '../../icons/CloseIcon'
import { DropArrowDownIcon } from '../../icons/DropArrowDownIcon'
import { ForwardIcon } from '../../icons/ForwardIcon'
import { InfoIcon } from '../../icons/InfoIcon'
import { NameDto } from '../../model/NameDto'
import { RegulatorOptionDto } from '../../model/RegulatorOptionDto'
import { EmailPattern, EnglishLettersPattern } from '../../utils/formValidation'
import { AuthorisedSignatoriesInfoModal } from './AuthorisedSignatoriesInfoModal'
import { AuthorisedTradersInfoModal } from './AuthorisedTradersInfoModal'
import { DirectorInfoModal } from './DirectorInfoModal'
import { NumberOfModal } from './NumberOfModal'

import styles from './CompanyDetailsStep.module.scss'

const FormField = createFormField<CompanyDetailsStep3FormValues>()
const DynamicFormField = createFormField()

export interface CompanyDetailsStep3FormValues {
  numberOfDirectors: number
  directors: Director[]
  numberOfSignatories: number
  signatories: Signatory[]
  numberOfTraders: number
  traders: Trader[]
}

interface Director {
  name: string
  street: string
  city: string
  state: string
  zipCode: string
  country: NameDto<string>
}

interface Signatory {
  name: string
  role: string
}

interface Trader {
  name: string
  role: string
  email: string
}

interface OuterProps {
  regulators: RegulatorOptionDto[]
  onSubmit(values: CompanyDetailsStep3FormValues): void
}

const CompanyDetailsStep3FormUI: React.FC<
  FormikProps<CompanyDetailsStep3FormValues> & OuterProps
> = (props) => {
  const { handleSubmit, setFieldValue, values, isSubmitting, regulators = [] } = props

  const { t } = useTranslation()
  const isArabic = useArabicSessionLanguage()

  const [isNumberDirectorsModalOpen, setNumberDirectorsModalOpen] = useState(false)
  const [isNumberSignatoriesModalOpen, setNumberSignatoriesModalOpen] = useState(false)
  const [isNumberTradersModalOpen, setNumberTradersModalOpen] = useState(false)
  const [isAuthorisedSignatoriesInfoModalOpen, setAuthorisedSignatoriesInfoModalOpen] =
    useState(false)
  const [isAuthorisedTradersInfoModalOpen, setAuthorisedTradersInfoModalOpen] = useState(false)

  const handleNumberDirectorsModalOpen = () => {
    setNumberDirectorsModalOpen(true)
  }

  const handleNumberDirectorsModalClose = () => {
    setNumberDirectorsModalOpen(false)
  }

  const handleNumberSignatoriesModalOpen = () => {
    setNumberSignatoriesModalOpen(true)
  }

  const handleNumberSignatoriesModalClose = () => {
    setNumberSignatoriesModalOpen(false)
  }

  const handleNumberTradersModalOpen = () => {
    setNumberTradersModalOpen(true)
  }

  const handleNumberTradersModalClose = () => {
    setNumberTradersModalOpen(false)
  }

  const handleAuthorisedSignatoriesInfoModalOpen = () => {
    setAuthorisedSignatoriesInfoModalOpen(true)
  }

  const handleAuthorisedSignatoriesInfoModalClose = () => {
    setAuthorisedSignatoriesInfoModalOpen(false)
  }

  const handleAuthorisedTradersInfoModalOpen = () => {
    setAuthorisedTradersInfoModalOpen(true)
  }

  const handleAuthorisedTradersInfoModalClose = () => {
    setAuthorisedTradersInfoModalOpen(false)
  }

  const handleSubmitForm: FormEventHandler = (event) => {
    event.preventDefault()
    handleSubmit()
  }

  const handleAddNumberOfDirectors = () => {
    setFieldValue('numberOfDirectors', values.numberOfDirectors + 1)
  }

  const handleAddNumberOfSignatories = () => {
    setFieldValue('numberOfSignatories', values.numberOfSignatories + 1)
  }

  const handleAddNumberOfTraders = () => {
    setFieldValue('numberOfTraders', values.numberOfTraders + 1)
  }

  return (
    <React.Fragment>
      {isNumberDirectorsModalOpen && (
        <NumberOfModal
          name='numberOfDirectors'
          title={t('Sign up.Number of Directors')}
          numberOf={values.numberOfDirectors}
          onSelectOption={handleNumberDirectorsModalClose}
          onClose={handleNumberDirectorsModalClose}
        />
      )}
      {isNumberSignatoriesModalOpen && (
        <NumberOfModal
          name='numberOfSignatories'
          title={t('Sign up.Number of Signatories')}
          numberOf={values.numberOfSignatories}
          onSelectOption={handleNumberSignatoriesModalClose}
          onClose={handleNumberSignatoriesModalClose}
        />
      )}
      {isNumberTradersModalOpen && (
        <NumberOfModal
          name='numberOfTraders'
          title={t('Sign up.Number of Traders')}
          numberOf={values.numberOfTraders}
          onSelectOption={handleNumberTradersModalClose}
          onClose={handleNumberTradersModalClose}
        />
      )}
      {isAuthorisedSignatoriesInfoModalOpen && (
        <AuthorisedSignatoriesInfoModal closeModal={handleAuthorisedSignatoriesInfoModalClose} />
      )}
      {isAuthorisedTradersInfoModalOpen && (
        <AuthorisedTradersInfoModal closeModal={handleAuthorisedTradersInfoModalClose} />
      )}

      <Form className={styles.form} onSubmit={handleSubmitForm}>
        <div>
          <h3 className='pb-4'>{t('Sign up.Number of Directors')}</h3>
          <div className='pb-4'>
            <FormField
              name='numberOfDirectors'
              label={t('Sign up.Number of Directors')}
              type='number'
              min='0'
              required
              readOnly
              showLabel
              onClick={handleNumberDirectorsModalOpen}
            />
            {Array.from({ length: values.numberOfDirectors }).map((_, id) => (
              <DirectorFields
                id={id}
                name={`directors[${[id]}]`}
                item={values.directors[id]}
                regulators={regulators}
                key={id}
              />
            ))}
            <Button
              className='p-0'
              type='button'
              appearance='plain'
              size='S'
              onClick={handleAddNumberOfDirectors}
            >
              {t('Sign up.+ Add Director')}
            </Button>
          </div>
        </div>

        <div className='pb-4'>
          <h3 className='pb-4'>
            {t('Sign up.Authorised Signatories')}
            <IconButton className='pl-2' onClick={handleAuthorisedSignatoriesInfoModalOpen}>
              <InfoIcon />
            </IconButton>
          </h3>
          <FormField
            name='numberOfSignatories'
            label={t('Sign up.Number of Signatories')}
            type='number'
            min='0'
            required
            readOnly
            showLabel
            onClick={handleNumberSignatoriesModalOpen}
          />
          {Array.from({ length: values.numberOfSignatories }).map((_, id) => (
            <SignatoryFields id={id} name={`signatories[${[id]}]`} key={id} />
          ))}
          <Button
            className='p-0'
            type='button'
            appearance='plain'
            size='S'
            onClick={handleAddNumberOfSignatories}
          >
            {t('Sign up.+ Add Signatory')}
          </Button>
        </div>

        <div className='pb-4'>
          <h3 className='pb-4'>
            {t('Sign up.Authorised Traders')}{' '}
            <IconButton className='pl-2' onClick={handleAuthorisedTradersInfoModalOpen}>
              <InfoIcon />
            </IconButton>
          </h3>
          <FormField
            name='numberOfTraders'
            label={t('Sign up.Number of Traders')}
            type='number'
            min='0'
            required
            readOnly
            showLabel
            onClick={handleNumberTradersModalOpen}
          />
          {Array.from({ length: values.numberOfTraders }).map((_, id) => (
            <TraderFields id={id} name={`traders[${[id]}]`} key={id} />
          ))}
          <Button
            className='p-0'
            type='button'
            appearance='plain'
            size='S'
            onClick={handleAddNumberOfTraders}
          >
            {t('Sign up.+ Add Trader')}
          </Button>
        </div>

        <Button
          className={styles.button}
          type='submit'
          appearance='primary'
          size='L'
          renderRightIcon={() => (
            <span className='is-flex is-align-items-center'>
              {isArabic ? <BackIcon inverse /> : <ForwardIcon inverse />}
            </span>
          )}
          disabled={isSubmitting}
        >
          {t('Next')}
        </Button>
      </Form>
    </React.Fragment>
  )
}

interface DirectorFieldsProps {
  id: number
  name: string
  item: Director
  regulators: RegulatorOptionDto[]
}

const DirectorFields: React.FC<DirectorFieldsProps> = (props) => {
  const { id, name, item, regulators } = props
  const countryOptions = regulators.map((regulator) => regulator.country)

  const { t } = useTranslation()
  const { validateStreet, validateCity, validateState, validateCountry, validateZipCode } =
    useFormValidation()
  const [isDirectorInfoModalOpen, setDirectorInfoModalOpen] = useState(false)

  const { values, setFieldValue } = useFormikContext<CompanyDetailsStep3FormValues>()

  const [isDirectorCountryModalOpen, setDirectorCountryModalOpen] = useState(false)

  const handleDirectorCountryModalOpen = () => {
    setDirectorCountryModalOpen(true)
  }

  const handleDirectorCountryModalClose = () => {
    setDirectorCountryModalOpen(false)
  }

  const handleDirectorInfoModalOpen = () => {
    setDirectorInfoModalOpen(true)
  }

  const handleDirectorInfoModalClose = () => {
    setDirectorInfoModalOpen(false)
  }

  const handleRemoveNumberOfDirectors = () => {
    setFieldValue('numberOfDirectors', values.numberOfDirectors - 1)
    setFieldValue(
      'directors',
      values.directors.filter((_, index) => index !== id)
    )
  }

  const handleCountryOption = (country: NameDto<string>) => {
    setFieldValue(`${name}.country`, country)
    setDirectorCountryModalOpen(false)
  }

  return (
    <React.Fragment>
      {isDirectorCountryModalOpen && (
        <Modal
          cardClassName={styles.modal}
          closeModal={handleDirectorCountryModalClose}
          render={() => (
            <CountrySelectModal
              title={t('Sign up.Country of Residence')}
              options={countryOptions}
              selectedCountry={item?.country?.name}
              handleOnChangeOption={handleCountryOption}
              onCancel={handleDirectorCountryModalClose}
            />
          )}
        />
      )}
      {isDirectorInfoModalOpen && <DirectorInfoModal closeModal={handleDirectorInfoModalClose} />}
      <h3 className='pb-4 is-flex is-justify-content-space-between is-align-items-center'>
        <span>
          {t('Sign up.Director')} {id + 1}
          <IconButton className='pl-2' onClick={handleDirectorInfoModalOpen}>
            <InfoIcon />
          </IconButton>
        </span>{' '}
        {id >= 1 && (
          <IconButton onClick={handleRemoveNumberOfDirectors}>
            <CloseIcon color={'error'} />
          </IconButton>
        )}
      </h3>

      <DynamicFormField
        name={`${name}.name`}
        label={t('Sign up.Name')}
        type='text'
        max={100}
        min={1}
        required
        showLabel
        pattern='.*\S+.*'
      />
      <DynamicFormField
        name={`${name}.country`}
        label={t('Sign up.Country of Residence')}
        value={item?.country?.name}
        rightIcon={<DropArrowDownIcon />}
        required
        showLabel
        onChange={(event: React.ChangeEvent) => event.preventDefault()}
        onClick={handleDirectorCountryModalOpen}
        validate={validateCountry}
      />
      <DynamicFormField
        name={`${name}.street`}
        label={t('Sign up.Street Name, Street Number, Apt. Number')}
        validate={validateStreet}
        required
        showLabel
        pattern='.*\S+.*'
      />
      <DynamicFormField
        name={`${name}.city`}
        label={t('Sign up.City')}
        validate={validateCity}
        required
        showLabel
        pattern='.*\S+.*'
      />

      <FieldGroup>
        <DynamicFormField
          name={`${name}.state`}
          label={t('Sign up.State/Region')}
          validate={validateState}
          required
          showLabel
          pattern='.*\S+.*'
        />
        <DynamicFormField
          name={`${name}.zipCode`}
          label={t('Sign up.ZIP/Postal Code')}
          validate={validateZipCode}
          type='text'
          required
          showLabel
          pattern='.*\S+.*'
        />
      </FieldGroup>
    </React.Fragment>
  )
}

const useFormValidation = () => {
  const { t } = useTranslation()

  const validateStreet = (value: string) => {
    if (!value) {
      return `${t('Validation.Required')}`
    }
    if (!EnglishLettersPattern.test(value)) {
      return `${t('Validation.Invalid street')}`
    }
    return undefined
  }

  const validateCity = (value: string) => {
    if (!value) {
      return `${t('Validation.Required')}`
    }
    if (!EnglishLettersPattern.test(value)) {
      return `${t('Validation.Invalid city')}`
    }
  }

  const validateState = (value: string) => {
    if (!value) {
      return `${t('Validation.Required')}`
    }
    if (!EnglishLettersPattern.test(value)) {
      return `${t('Validation.Invalid state')}`
    }
  }

  const validateCountry = (value: string) => {
    if (!value) {
      return `${t('Validation.Required')}`
    }
  }

  const validateZipCode = (value: string) => {
    if (!value) {
      return `${t('Validation.Required')}`
    }
    if (value.length > 32) {
      return `${t('Validation.Maximum character limit is 32')}`
    }
    if (!EnglishLettersPattern.test(value)) {
      return `${t('Validation.Invalid postal code')}`
    }
  }

  return { validateStreet, validateCity, validateState, validateCountry, validateZipCode }
}

interface SignatoryFieldsProps {
  id: number
  name: string
}

const SignatoryFields: React.FC<SignatoryFieldsProps> = (props) => {
  const { id, name } = props

  const { t } = useTranslation()

  const { values, setFieldValue } = useFormikContext<CompanyDetailsStep3FormValues>()

  const handleRemoveNumberOfSignatories = () => {
    setFieldValue('numberOfSignatories', values.numberOfSignatories - 1)
    setFieldValue(
      'signatories',
      values.signatories.filter((_, index) => index !== id)
    )
  }

  return (
    <React.Fragment>
      <h3 className='pb-4 is-flex is-justify-content-space-between is-align-items-center'>
        <span>
          {t('Sign up.Signatory')} {id + 1}
        </span>{' '}
        {id >= 1 && (
          <IconButton onClick={handleRemoveNumberOfSignatories}>
            <CloseIcon color={'error'} />
          </IconButton>
        )}
      </h3>

      <DynamicFormField
        name={`${name}.name`}
        label={t('Sign up.Name')}
        type='text'
        max={100}
        min={1}
        required
        showLabel
        pattern='.*\S+.*'
      />
      <DynamicFormField
        name={`${name}.role`}
        label={t('Sign up.Role in Company')}
        type='text'
        required
        showLabel
        pattern='.*\S+.*'
      />
    </React.Fragment>
  )
}

interface TraderFieldsProps {
  id: number
  name: string
}

const useValidateTraderFields = () => {
  const { t } = useTranslation()

  const validateEmail = (value: string) => {
    if (!value) {
      return `${t('Validation.Required')}`
    }
    if (value && !EmailPattern.test(value)) {
      return `${t('Validation.Invalid email address')}`
    }
  }

  return [validateEmail]
}

const TraderFields: React.FC<TraderFieldsProps> = (props) => {
  const { id, name } = props

  const { t } = useTranslation()

  const { values, setFieldValue } = useFormikContext<CompanyDetailsStep3FormValues>()

  const handleRemoveNumberOfTraders = () => {
    setFieldValue('numberOfTraders', values.numberOfTraders - 1)
    setFieldValue(
      'traders',
      values.traders.filter((_, index) => index !== id)
    )
  }

  const [validateEmail] = useValidateTraderFields()
  return (
    <React.Fragment>
      <h3 className='pb-4 is-flex is-justify-content-space-between is-align-items-center'>
        {t('Sign up.Trader')} {id + 1}{' '}
        {id >= 1 && (
          <IconButton onClick={handleRemoveNumberOfTraders}>
            <CloseIcon color={'error'} />
          </IconButton>
        )}
      </h3>

      <DynamicFormField
        name={`${name}.name`}
        label={t('Sign up.Name')}
        type='text'
        max={100}
        min={1}
        required
        showLabel
        pattern='.*\S+.*'
      />
      <DynamicFormField
        name={`${name}.role`}
        label={t('Sign up.Role in Company')}
        type='text'
        required
        showLabel
        pattern='.*\S+.*'
      />
      <DynamicFormField
        name={`${name}.email`}
        label={t('Sign up.Email')}
        type='text'
        required
        showLabel
        validate={validateEmail}
      />
    </React.Fragment>
  )
}

export const CompanyDetailsStep3Form = withFormik<OuterProps, CompanyDetailsStep3FormValues>({
  mapPropsToValues: () => {
    return {
      numberOfDirectors: 1,
      directors: [],
      numberOfSignatories: 1,
      signatories: [],
      numberOfTraders: 1,
      traders: [],
    }
  },
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      setSubmitting(true)
      await props.onSubmit(values)
    } finally {
      setSubmitting(false)
    }
  },
  validate: () => {
    const errors: FormikErrors<CompanyDetailsStep3FormValues> = {}
    return errors
  },
})(CompanyDetailsStep3FormUI)
