import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'
import { format } from 'date-fns'

import { Loading } from '../global/Loading/Loading'
import { useSessionLanguage } from '../global/context/SessionSettingsContext'
import { Tabs, defineTab } from '../global/tabs/Tabs'
import { AccountGroupType } from '../model/AccountGroupType'
import { PlatformTypeEnum } from '../model/PlatformTypeEnum'
import { StatementType } from '../model/Statements'
import { PageHeader } from '../ui/Table/Header/PageHeader'
import { Operator, useApiClient } from '../utils/ApiClient'
import { ClientApiClient } from '../utils/clientApi'
import { getDateFromDay, getDateRangeOfLastYear } from '../utils/date.utils'
import { useWindowResize } from '../utils/domUtils'
import { getAvailableDates } from '../utils/statements'
import { useFetchOne } from '../utils/useFetch'
import { DownloadedStatement } from './DownloadedStatement'
import { StatementPage } from './Statement'

import styles from './StatementsETD.module.scss'

export interface StatementsProps {}

export const StatementsETD: React.FC = () => {
  const { t } = useTranslation()
  const { tab } = useParams<{ tab?: string }>()
  const navigate = useNavigate()
  const isMobile = useWindowResize()
  const locale = useSessionLanguage()
  const apiClient = useApiClient(ClientApiClient)
  const [downloading, setDownloading] = useState(false)
  const [downloadedDaily, setDownloadedDaily] = useState(false)
  const [downloadedMonthly, setDownloadedMonthly] = useState(false)
  const [loading, setLoading] = useState(true)
  const isDaily = tab === 'daily'
  const isMonthly = tab === 'monthly'

  const fetchStatements = async (type: StatementType, month?: number, day?: number) => {
    if (!month || !tradingAccountId) return
    setDownloading(true)
    const monthDate = day
      ? getDateFromDay(month, day)
      : getDateFromDay(
          month,
          availableDates?.availableDayOptions.find((d) => d.month === month)?.days?.[0].id || 1
        )
    try {
      const response = await apiClient.getTradingAccountStatement(tradingAccountId, type, {
        date: format(monthDate, 'yyyy-MM-dd'),
      })
      window.location.assign(response.url)
      if (type === StatementType.Daily) {
        setDownloadedDaily(true)
      } else if (type === StatementType.Monthly) {
        setDownloadedMonthly(true)
      }
    } finally {
      setDownloading(false)
    }
  }

  const tradingAccountCallback = useCallback(async () => {
    const [tradingAccount] = (
      await apiClient.getTradingAccounts({
        search: {
          AccountGroupType: {
            value: AccountGroupType.Live,
            operator: Operator.EQUAL,
          },
          Platform: {
            value: PlatformTypeEnum.CQGCAST, // it's for ETD
            operator: Operator.EQUAL,
          },
          Status: {
            value: 1,
            operator: Operator.EQUAL,
          },
        },
      })
    ).items
    return tradingAccount?.id
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: tradingAccountId } = useFetchOne(tradingAccountCallback)

  const availableDatesCallback = useCallback(async () => {
    if (!tradingAccountId) {
      setLoading(false)
      return
    }
    if (!loading) setLoading(true)
    try {
      const monthData = getDateRangeOfLastYear()
      const response = await apiClient.checkTradingAccountStatement(
        tradingAccountId,
        isDaily ? StatementType.Daily : StatementType.Monthly,
        {
          dateRangeStart: format(monthData.dateRangeStart, 'yyyy-MM-dd'),
          dateRangeEnd: format(monthData.dateRangeEnd, 'yyyy-MM-dd'),
        }
      )
      setLoading(false)
      return getAvailableDates(t, response.dates)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, tradingAccountId, locale])

  const { data: availableDates } = useFetchOne(availableDatesCallback)

  function* getTabs() {
    yield {
      ...defineTab('daily', t('Statements.Daily')),
    }
    yield {
      ...defineTab('monthly', t('Statements.Monthly')),
    }
  }

  const TabsDefinition = [...getTabs()]

  useEffect(() => {
    setDownloadedDaily(false)
    setDownloadedMonthly(false)
  }, [tab])

  return (
    <div>
      <div className={classNames('section', styles.adminRoutes)}>
        <Tabs
          className={styles.tabs}
          tabs={TabsDefinition}
          currentTab={tab || ''}
          appearance={isMobile ? undefined : 'light'}
          onTabChanged={(tab) => navigate(`/dashboard/statements/${tab}`)}
        />
      </div>

      {isDaily && <PageHeader title={t('Statements.Daily Statements')} />}

      {isMonthly && <PageHeader title={t('Statements.Monthly Statements')} />}

      <Loading
        showLoadingIcon
        text={downloading ? t('Statements.Preparing download') : undefined}
        isLoading={loading || downloading}
      >
        {downloadedDaily && isDaily ? (
          <DownloadedStatement goBack={() => setDownloadedDaily(false)} />
        ) : downloadedMonthly && isMonthly ? (
          <DownloadedStatement goBack={() => setDownloadedMonthly(false)} />
        ) : (
          <StatementPage
            availableDates={availableDates}
            onSubmit={fetchStatements}
            type={isDaily ? StatementType.Daily : StatementType.Monthly}
          />
        )}
      </Loading>
    </div>
  )
}
