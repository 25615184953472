import React from 'react'
import { useTranslation } from 'react-i18next'

import IconButton from '../../../global/iconButton/IconButton'
import { DotsIcon } from '../../../icons/DotsIcon'
import { SubIBAccount } from '../../../model/MultiTierDto'

import styles from './MultiTierCards.module.scss'

interface MultiTierSubAccountsCardProps {
  data: SubIBAccount

  setActionsModal: React.Dispatch<React.SetStateAction<SubIBAccount | undefined>>
}

export const MultiTierSubAccountsCard: React.FC<MultiTierSubAccountsCardProps> = ({
  data,
  setActionsModal,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.card}>
      <header className={styles.cardHeader}>
        <span className={styles.cardTitle}>{data.account.name}</span>

        <IconButton onClick={() => setActionsModal(data)}>
          <DotsIcon />
        </IconButton>
      </header>

      <div className={styles.title}>
        <span className={styles.label}>{t('IB.MultiTier.Plan')}</span>
        <span className={styles.value}>{data.commissionPlanGroup.name}</span>
      </div>
    </div>
  )
}
