import { useNavigate } from 'react-router-dom'

import { PageHeader } from '../../ui/Table/Header/PageHeader'

import styles from './SubscriptionsTradingPlatforms.module.scss'

export const SubscriptionsTradingPlatformsHelpETDPage = ({
  title,
  description,
}: {
  title: string
  description: string
}) => {
  const navigate = useNavigate()

  const handleGoBack = () => {
    navigate('/dashboard/subscriptions/trading-platforms')
  }

  return (
    <div>
      <PageHeader title={title} backButton={handleGoBack} />
      <div className={styles.content}>
        <h3>{title}</h3>
        <div className='pt-3'>
          <p>{description}</p>
        </div>
      </div>
    </div>
  )
}
