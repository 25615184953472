import React, { Fragment, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TransactionBadgeStatus } from '../../Traders-Room/Transaction-History/TransactionBadgeStatus'
import { useArabicSessionLanguage } from '../../global/context/SessionSettingsContext'
import { ConfirmationModal } from '../../global/modal/ConfirmationModal'
import { Modal } from '../../global/modal/Modal'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { TransactionDto } from '../../model/TransactionDto'
import { TransactionStatus } from '../../model/TransactionStatus'
import { CurrencyType } from '../../model/WalletDto'
import { TextSmall } from '../../ui/Typography/Typography'
import { PageQuery, useApiClient } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { ClientApiClient } from '../../utils/clientApi'
import { formatDate } from '../../utils/date.utils'
import {
  TransactionType,
  getIBTransactionFieldsByTransactionType,
  getTransactionType,
} from '../../utils/transaction.utils'

import styles from './IntroducingBrokerTransactionHistoryCard.module.scss'

interface Props {
  data: TransactionDto

  forceRefresh(): void
  isLoading: boolean
  pageQuery?: PageQuery
  setPageQuery: ((pageQuery?: PageQuery | undefined) => void) | undefined
  setSelectedTransaction(id: string): void
  setHistoryModal(v: boolean): void
}

export const IntroducingBrokerTransactionHistoryCard: React.FC<Props> = ({
  data,
  setHistoryModal,
  setSelectedTransaction,
  forceRefresh,
}) => {
  const { t } = useTranslation()
  const isArabic = useArabicSessionLanguage()
  const [auth] = useContext(AuthSessionContext)
  const apiClient = useApiClient(ClientApiClient)
  const dateFormat = auth?.dateFormatType?.name
  const { formatMoney } = useFormatNumber()

  const [expanded, setExpanded] = useState(false)
  const [cancelTransactionModal, setCancelTransactionModal] = useState(false)
  const transactionType = getTransactionType(data.type.id)
  const fields = getIBTransactionFieldsByTransactionType(transactionType, data, isArabic, auth?.id)
  const handleSetTransactionHistoryModal = (id: string) => {
    setSelectedTransaction(id)
    setHistoryModal(true)
  }

  const showMoreMenu =
    (data.type.id === TransactionType.WithdrawToPaymentAgent ||
      data.type.id === TransactionType.WalletWithdrawal) &&
    data.state.id === TransactionStatus.AwaitingApproval

  const onCancelTransaction = async (transactionId: string) => {
    await apiClient.cancelTransaction(transactionId)
    forceRefresh()
  }

  return (
    <>
      {cancelTransactionModal && (
        <Modal
          closeModal={() => setCancelTransactionModal(false)}
          render={({ closeModal }) => (
            <ConfirmationModal
              title={t('Transactions.Cancel Confirmation')}
              onCancel={closeModal}
              renderFooter={() => (
                <React.Fragment>
                  <button
                    className='button'
                    onClick={() => setCancelTransactionModal(false)}
                    type='button'
                  >
                    {t('Transactions.Back to Transaction History')}
                  </button>
                  <button
                    className='button'
                    onClick={() => onCancelTransaction(data.id)}
                    type='button'
                  >
                    {t('Transactions.Yes, Cancel Transaction')}
                  </button>
                </React.Fragment>
              )}
            >
              <p
                dangerouslySetInnerHTML={{
                  __html: t('Transactions.Cancel Confirmation'),
                }}
              />
            </ConfirmationModal>
          )}
        />
      )}
      <div onClick={() => setExpanded((prevState) => !prevState)} className={styles.card}>
        <div className={styles.title}>
          <span>{data.type.name}</span>
          <span>{formatMoney(fields.amount, fields.currency as CurrencyType)}</span>
        </div>
        <div className={styles.subtitle}>
          <span>{formatDate(data.createdDate, dateFormat)}</span>
          <span onClick={() => handleSetTransactionHistoryModal(data.id)} className={styles.active}>
            <TransactionBadgeStatus {...data.state} />
          </span>
        </div>
        {expanded && (
          <Fragment>
            <div className={styles.subtitle}>
              <span>{t('From')}</span>
              <span dir={isArabic ? 'ltr' : ''}>{fields.from}</span>
            </div>
            <div className={styles.subtitle}>
              <span>{t('To')}</span>
              <span dir={isArabic ? 'ltr' : ''}>{fields.to}</span>
            </div>
            <div className={styles.subtitle}>
              <span>{t('IB.Reference number')}</span>
              <span>{data.referenceId}</span>
            </div>
            {showMoreMenu && (
              <div className='ml-auto'>
                <TextSmall className={styles.error} onClick={() => setCancelTransactionModal(true)}>
                  {t('Transactions.Cancel Transaction')}
                </TextSmall>
              </div>
            )}
          </Fragment>
        )}
      </div>
    </>
  )
}
