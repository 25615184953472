import React, { PropsWithChildren } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { Button } from '../../global/button/Button'

import styles from './TransactionsPage.module.scss'

interface CardProps {
  title: string
  description?: string
  renderDescription?(): React.ReactNode
  buttonText: string
  additionalDescription?: string
  appearance: 'primary' | 'secondary'
  icon: React.ReactNode
  to: string
}

export const Cards: React.FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
  return <div className={classNames('mt-4 columns', styles.cards)}>{children}</div>
}

export const Card: React.FC<CardProps> = ({
  title,
  description,
  renderDescription,
  buttonText,
  appearance,
  icon,
  to,
  additionalDescription,
}) => {
  const navigate = useNavigate()

  return (
    <div className={classNames('column', styles.card)}>
      <div>
        <div className='mb-5'>{icon}</div>
        <div className='mb-5'>{title && <h3 className={styles.title}>{title}</h3>}</div>
        {description && (
          <div>
            <p className={classNames('text-secondary mb-5 has-text-centered', styles.text)}>
              {description}
            </p>
          </div>
        )}
        {renderDescription && (
          <div className={classNames('text-secondary mb-5 has-text-centered', styles.text)}>
            {renderDescription()}
          </div>
        )}
      </div>
      <div className='container is-fluid'>
        <Button
          size='L'
          appearance={appearance}
          className={styles.buttonCard}
          onClick={() => navigate(to)}
        >
          {buttonText}
        </Button>
        {additionalDescription && (
          <div className={styles.textBlock}>
            <p className={classNames('text-secondary mt-4 has-text-centered', styles.text)}>
              {additionalDescription}
            </p>
          </div>
        )}
      </div>
    </div>
  )
}
