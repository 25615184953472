import React from 'react'
import classNames from 'classnames'

import { TextTiny } from '../../ui/Typography/Typography'
import { useArabicSessionLanguage } from '../context/SessionSettingsContext'

import styles from './TextField.module.scss'

interface TextFieldProps<T extends string | number> {
  type: T extends number ? 'number' : 'text'
  value?: T | null
  min?: number
  max?: number
  lockOnLimit?: boolean
  maxDigits?: number
  maxChatacters?: number
  onChange?: (value: T) => void
  readOnly?: boolean
  label?: string
  className?: string
  interactiveText?: boolean
  decimalPlaces?: number
  error?: string
  disabled?: boolean
  renderIcon?: React.ReactNode
  iconPosition?: 'right' | 'left'
  zerosLimitter?: boolean
  onKeyDown?(event: React.KeyboardEvent<HTMLInputElement>): void

  labelClassName?: string
}

export function TextField<T extends string | number>(props: TextFieldProps<T>): JSX.Element {
  const {
    label,
    className,
    renderIcon,
    iconPosition,
    onChange,
    min,
    max,
    maxDigits,
    labelClassName,
  } = props
  const { error, lockOnLimit, value, zerosLimitter, onKeyDown, maxChatacters } = props
  const { interactiveText, readOnly, disabled, type, decimalPlaces } = props

  const isArabic = useArabicSessionLanguage()
  const isIconLeft = !!renderIcon && (iconPosition === 'left' || !iconPosition)
  const isIconRight = !!renderIcon && iconPosition === 'right'

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (!onChange) return
    if (type === 'number') {
      if (decimalPlaces !== undefined) {
        if (zerosLimitter) {
          if (/^0{2,}/.test(e.target.value)) return
        }
        const regex = new RegExp(`^\\d*\\.?\\d{0,${decimalPlaces}}$`)
        if (!regex.test(e.target.value)) return
      } else if (zerosLimitter) {
        if (/^0{2,}/.test(e.target.value)) return
      }

      if (maxDigits !== undefined) {
        if (e.target.value.split('.')[0].length > maxDigits) return
      }

      if (isNaN(parseFloat(e.target.value))) return onChange(e.target.value as T)
      const numberValue = parseFloat(e.target.value)
      if (min && numberValue < min) {
        if (lockOnLimit) return
        return onChange(min as unknown as T)
      }
      if (max && numberValue > max) {
        if (lockOnLimit) return
        return onChange(max as unknown as T)
      }
      return onChange(e.target.value as unknown as T)
    }

    if (maxChatacters && e.target.value.length > maxChatacters) return

    onChange(e.target.value as T)
  }

  return (
    <div className={classNames('field', styles.textField, className)}>
      {!!label && (
        <label
          dir={isArabic ? 'rtl' : ''}
          className={classNames('label', styles.label, labelClassName, {
            [styles.isIconLeft]: isIconLeft,
            [styles.isIconRight]: isIconRight,
          })}
        >
          {label}
        </label>
      )}
      <div className={classNames('control')}>
        <input
          type={type}
          min={min}
          max={max}
          readOnly={readOnly}
          disabled={disabled}
          onKeyDown={onKeyDown}
          onChange={handleChange}
          value={value?.toString()}
          className={classNames('input', styles.input, {
            [styles.disabled]: disabled || readOnly,
            [styles.interactiveText]: interactiveText,
            [styles.isIconLeft]: isIconLeft,
            [styles.isIconRight]: isIconRight,
            [styles.inputReadOnly]: !!readOnly,
            [styles.labledInput]: !!label,
            [styles.errored]: !!error,
          })}
        />
        <span
          dir={isArabic ? 'rtl' : ''}
          className={classNames(styles.icon, {
            [styles.loweredIcon]: label && !!renderIcon,
            [styles.iconLeft]: (iconPosition === 'left' || !iconPosition) && styles.iconLeft,
            [styles.iconRight]: iconPosition === 'right' && styles.iconLeft,
          })}
        >
          {renderIcon}
        </span>
      </div>
      {!!error && <TextTiny className={styles.errorText}>{error}</TextTiny>}
    </div>
  )
}
