import React, { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Loading } from '../../../../global/Loading/Loading'
import {
  MasterTransaction,
  isTransactionFormRedirect,
  isTransactionHistory,
  isTransactionSuccessful,
  isTransactionUnSuccessful,
  isTransactionUrlRedirect,
} from '../../../../model/MasterTransaction'
import { isPaymentProviderBankType } from '../../../../model/PaymentProviderType'
import { useApiClient } from '../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../utils/clientApi'
import { TradingAccountRestrictions, isRestricted } from '../../../../utils/trading-account.utils'
import { useCallbackWithForceRefresh } from '../../../../utils/useCallbackWithForceRefresh'
import { useFetchOne } from '../../../../utils/useFetch'
import { TradingAccountDepositFormValues } from '../TradingAccountDepositForm/TradingAccountDepositForm'
import { TradingAccountDepositPage } from './TradingAccountDepositPage'

export const TradersRoomTradingAccountDepositPage: React.FC = () => {
  const { tradingAccountId } = useParams<{ tradingAccountId?: string }>()
  const apiClient = useApiClient(ClientApiClient)

  const navigate = useNavigate()

  const redirectToTradingAccounts = useCallback(() => {
    navigate(`/dashboard/traders-room/trading-accounts`)
  }, [navigate])

  const { callback } = useCallbackWithForceRefresh(async () => {
    if (tradingAccountId) return apiClient.getTradingAccountById(tradingAccountId)
  }, [tradingAccountId])

  const { data, isLoading } = useFetchOne(callback)

  useEffect(() => {
    if (
      isRestricted(TradingAccountRestrictions.TRANSFER_FROM, data?.restrictions) &&
      !data?.campaign
    ) {
      redirectToTradingAccounts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.restrictions])

  const handleSubmit = (data: MasterTransaction, values: TradingAccountDepositFormValues) => {
    if (isTransactionHistory(data)) {
      navigate(`/dashboard/traders-room/transaction-history`)
    }

    if (isPaymentProviderBankType(values.paymentProvider.providerCategory.id)) {
      navigate(
        `/dashboard/traders-room/trading-accounts/${values.tradingAccount.id}/deposit/transaction/succeed`,
        {
          state: {
            data,
            providerCategoryId: values.paymentProvider.providerCategory.id,
          },
        }
      )
    }

    if (isTransactionUrlRedirect(data)) {
      navigate(`/dashboard/traders-room/trading-accounts/${tradingAccountId}/deposit/redirect`, {
        state: {
          transaction: data,
          values,
        },
      })
    }

    if (isTransactionFormRedirect(data)) {
      navigate(`/dashboard/traders-room/trading-accounts/${tradingAccountId}/deposit/redirect`, {
        state: {
          transaction: data,
          values,
        },
      })
    }

    if (isTransactionSuccessful(data)) {
      navigate(
        `/dashboard/traders-room/trading-accounts/${values.tradingAccount.id}/deposit/transaction/succeed`,
        {
          state: {
            data,
            providerCategoryId: values.paymentProvider.providerCategory.id,
          },
        }
      )
    }

    if (isTransactionUnSuccessful(data)) {
      navigate(
        `/dashboard/traders-room/trading-accounts/${values.tradingAccount.id}/deposit/transaction/failed`,
        {
          state: {
            data,
            providerCategoryId: values.paymentProvider.providerCategory.id,
          },
        }
      )
    }
  }

  return (
    <Loading isLoading={isLoading}>
      {data && (
        <TradingAccountDepositPage
          tradingAccount={data}
          onSubmit={handleSubmit}
          onCancel={redirectToTradingAccounts}
        />
      )}
    </Loading>
  )
}
