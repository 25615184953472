import React, { Fragment, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Loading } from '../../global/Loading/Loading'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { IntroducingBrokerReportDto } from '../../model/IntroducingBrokerReportDto'
import { AuthSessionContext } from '../../utils/AuthContext'
import { formatDate } from '../../utils/date.utils'

import styles from './IBReportCard.module.scss'

interface IBReportsCardsProps {
  sortedData: IntroducingBrokerReportDto[]
  isLoading: boolean
}

interface IBReportCardProps {
  data: IntroducingBrokerReportDto
}

export const IBReportCards: React.FC<IBReportsCardsProps> = ({ sortedData, isLoading }) => {
  const { t } = useTranslation()

  return (
    <Loading showLoadingIcon isLoading={isLoading}>
      {!sortedData.length ? (
        <div className={classNames(styles.text, 'text-align-center pt-4')}>{t('No results')}</div>
      ) : (
        sortedData.map((report) => <IBReportCard data={report} />)
      )}
    </Loading>
  )
}

const IBReportCard: React.FC<IBReportCardProps> = ({ data }) => {
  const [auth] = useContext(AuthSessionContext)

  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)
  const { formatNumber, formatMoney } = useFormatNumber()

  const dateFormat = auth?.dateFormatType?.name

  return (
    <div onClick={() => setExpanded((prevState) => !prevState)} className={styles.card}>
      <div className={styles.title}>
        <span className={styles.mainLabel}>{data.clientName}</span>
        <span className={styles.mainLabel}>{data.accountType}</span>
      </div>
      {!expanded && (
        <div className={styles.title}>
          <span className={styles.subLabel}>{data.country}</span>
          <span className={styles.mainLabel}>{formatMoney(data.balance, data.currency)}</span>
        </div>
      )}
      {expanded && (
        <Fragment>
          <div className={styles.subtitle}>
            <span className={styles.textSecondary}>{data.email}</span>
          </div>
          <div className={styles.subtitle}>
            <span className={styles.textSecondary}>{t('Status')}</span>
            <span>{data.status}</span>
          </div>
          <div className={styles.subtitle}>
            <span className={styles.textSecondary}>{t('Trading Account.Trading Account')}</span>
            <span>{data.login}</span>
          </div>
          <div className={styles.subtitle}>
            <span className={styles.textSecondary}>{t('IB.Registration Date')}</span>
            <span>{formatDate(data.registrationDate, dateFormat)}</span>
          </div>
          <div className={styles.subtitle}>
            <span className={styles.textSecondary}>{t('Trading Account.Balance')}</span>
            <span className={styles.mainLabel}>{formatMoney(data.balance, data.currency)}</span>
          </div>

          <div className={styles.subtitle}>
            <span className={styles.textSecondary}>{t('IB.Volume Since 2019')}</span>
            <span>{formatNumber(data.volumeSince2019)}</span>
          </div>
          <div className={styles.subtitle}>
            <span className={styles.textSecondary}>{t('IB.Volume Current Month')}</span>
            <span>{formatNumber(data.volumeCurrentMonth)}</span>
          </div>
          <div className={styles.subtitle}>
            <span className={styles.textSecondary}>{t('IB.Volume Previous Month')}</span>
            <span>{formatNumber(data.volumePreviousMonth)}</span>
          </div>
          <div className={styles.subtitle}>
            <span className={styles.textSecondary}>{t('IB.Swap Free')}</span>
            <span>{data.swapFree ? t('Yes') : t('No')}</span>
          </div>
          <div className={styles.subtitle}>
            <span className={styles.textSecondary}>{t('IB.Entity')}</span>
            <span>{data.clientEntity}</span>
          </div>
        </Fragment>
      )}
    </div>
  )
}
