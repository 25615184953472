import React from 'react'

import {
  LegalDocumentDto,
  isLegalDocumentWidgetCheckbox,
  isLegalDocumentWidgetReadAndAgree,
} from '../../../model/CompanyLegalDocumentDto'
import { DocumentCheckBoxField } from './DocumentCheckBoxField'
import { DocumentFieldItem, DocumentReadAndAgreeField } from './DocumentReadAndAgreeField'

interface DocumentFieldFactoryProps {
  documents: LegalDocumentDto[]
  renderItem?(props: DocumentFieldItem): React.ReactNode
}

export const DocumentFieldFactory: React.FC<DocumentFieldFactoryProps> = (props) => {
  const { documents = [], renderItem } = props

  if (isLegalDocumentWidgetReadAndAgree(documents)) {
    return (
      <div className='mb-5'>
        <DocumentReadAndAgreeField documents={documents} renderItem={renderItem} />
      </div>
    )
  }

  if (isLegalDocumentWidgetCheckbox(documents)) {
    return (
      <div className='mb-4'>
        <DocumentCheckBoxField documents={documents} renderItem={renderItem} />
      </div>
    )
  }

  return null
}
