import React from 'react'
import { useTranslation } from 'react-i18next'

import { DropArrowDownIcon } from '../../../icons/DropArrowDownIcon'
import { DropArrowUpIcon } from '../../../icons/DropArrowUpIcon'
import { IBNetworkPlans } from '../../../model/MultiTierDto'

import styles from './MultiTierCards.module.scss'

interface MultiTierNetworkCardProps {
  data: IBNetworkPlans
}

export const MultiTierNetworkCard: React.FC<MultiTierNetworkCardProps> = ({ data }) => {
  const [isExpanded, setIsExpanded] = React.useState(false)
  const { t } = useTranslation()

  return (
    <div className={styles.card}>
      <header className={styles.cardHeader}>
        <span className={styles.cardTitle}>{data.introducingBroker.name}</span>
        {!isExpanded && (
          <div className={styles.expandIcon}>
            <DropArrowDownIcon
              className={styles.expandableRowIcon}
              onClick={() => setIsExpanded(true)}
            />
          </div>
        )}
        {isExpanded && (
          <div className={styles.expandIcon}>
            <DropArrowUpIcon
              className={styles.expandableRowIcon}
              onClick={() => setIsExpanded(false)}
            />
          </div>
        )}
      </header>

      <div className={styles.title}>
        <span className={styles.label}>{t('IB.MultiTier.Level')}</span>
        <span className={styles.value}>{data.level}</span>
      </div>
      <div className={styles.title}>
        <span className={styles.label}>{t('IB.MultiTier.Clients')}</span>
        <span className={styles.value}>{data.numberOfClients}</span>
      </div>

      {isExpanded &&
        data.subAccounts.map((subAccount) => (
          <div className={styles.subAccount}>
            <header className={styles.cardHeader}>
              <span className={styles.cardTitle}>{subAccount.introducingBroker.name}</span>
            </header>

            <div className={styles.title}>
              <span className={styles.label}>{t('IB.MultiTier.Level')}</span>
              <span className={styles.value}>{subAccount.level}</span>
            </div>
            <div className={styles.title}>
              <span className={styles.label}>{t('IB.MultiTier.Clients')}</span>
              <span className={styles.value}>{subAccount.numberOfClients}</span>
            </div>
          </div>
        ))}
    </div>
  )
}
