import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { RiskWarningModal } from '../../SignUp/SignupPage'
import { useLossPercentagesReadContext } from '../../global/context/LossPercentagesContext'
import { useProductReadContext, useProductWriteContext } from '../../global/context/ProductContext'
import IconButton from '../../global/iconButton/IconButton'
import { CancelActionModal } from '../../global/modal/CancleActionModal'
import { Modal } from '../../global/modal/Modal'
import { CloseIcon } from '../../icons/CloseIcon'
import { TickmillProductType } from '../../model/TickmillProductType'
import { TextSmall } from '../../ui/Typography/Typography'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { isTickmillPartner } from '../../utils/companyName.utils'
import { navigateBasedOnProductType } from '../../utils/navigationUtils'
import { ProductRiskWarning } from '../ProductRiskWarning'

import styles from '../../global/templates/FormTemplate.module.scss'

interface ProductRegistrationStepsTemplateProps {
  title: string
  subtitle?: string | React.ReactNode
}

export const ProductRegistrationStepsTemplate: React.FC<
  React.PropsWithChildren<ProductRegistrationStepsTemplateProps>
> = (props) => {
  const { title, subtitle, children } = props

  const navigate = useNavigate()

  const { account } = useAccountReadContext()
  const { product } = useProductReadContext()
  const { percentages } = useLossPercentagesReadContext()
  const { changeProductContext } = useProductWriteContext()
  const { isDefaultCFDProductType } = useProductReadContext()

  const [isRiskWarningModalOpen, setRiskWarningModalOpen] = useState(false)
  const [isExitConfirmationModalOpen, setExitConfirmationModalOpen] = useState(false)

  const isCFDProduct = isDefaultCFDProductType()
  const isPartner = isTickmillPartner(account)
  const productKey = TickmillProductType[product]

  const handleRiskWarningOpen = () => {
    setRiskWarningModalOpen(true)
  }

  const handleRiskWarningClose = () => {
    setRiskWarningModalOpen(false)
  }

  const handleConfirmExit = () => {
    setExitConfirmationModalOpen(false)
    if (account && account?.tickmillProducts.length > 1) {
      changeProductContext(isCFDProduct ? TickmillProductType.ETD : TickmillProductType.CFD)
    }
    navigateBasedOnProductType(isCFDProduct, navigate, isPartner)
  }

  const handleExitConfirmationModalOpen = () => {
    setExitConfirmationModalOpen(true)
  }

  const handleExitConfirmationModalClose = () => {
    setExitConfirmationModalOpen(false)
  }

  return (
    <>
      {isRiskWarningModalOpen && (
        <Modal
          closeModal={handleRiskWarningClose}
          render={({ closeModal }) => (
            <RiskWarningModal
              onCancel={closeModal}
              products={[productKey]}
              risk={percentages?.percentage}
            />
          )}
        />
      )}
      {isExitConfirmationModalOpen && (
        <Modal
          closeModal={handleExitConfirmationModalClose}
          render={({ closeModal }) => (
            <CancelActionModal onCancel={closeModal} onConfirm={handleConfirmExit} />
          )}
        />
      )}
      <div className={styles.wrapper}>
        <div className={classNames(styles.title, styles.grid)}>
          <div className={styles.closeButtonWrapper}>
            {account && account?.tickmillProducts.length > 1 && (
              <IconButton onClick={handleExitConfirmationModalOpen}>{<CloseIcon />}</IconButton>
            )}
          </div>
          <h3>{title}</h3>
          <div className={styles.iconWrapper}>
            <ProductRiskWarning onClick={handleRiskWarningOpen} />
          </div>
        </div>
        {!!subtitle && <TextSmall className='has-text-centered'>{subtitle}</TextSmall>}
        <div className={classNames(styles.content, styles.container, styles.fullWidth)}>
          {children}
        </div>
      </div>
    </>
  )
}
