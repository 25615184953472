import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { Loading } from '../../global/Loading/Loading'
import { SelectableModal } from '../../global/field/SelectableModal'
import { InfoModal } from '../../global/modal/InfoModal'
import { Modal } from '../../global/modal/Modal'
import { RestrictionActionModal } from '../../global/modal/RestrictionActionModal'
import { WalletNumberModal } from '../../global/modal/WalletNumberModal'
import { BonusTransactionDto } from '../../model/BonusTransactionDto'
import {
  WalletCurrency,
  WalletDto,
  WalletStateEnum,
  WalletTypeEnum,
  filterWalletsByType,
} from '../../model/WalletDto'
import { FirstTimeGuideCarousel } from '../../ui/FirstTimeGuideCarousel/FirstTimeGuideCarousel'
import { Text, TextStrong } from '../../ui/Typography/Typography'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { useApiClient } from '../../utils/ApiClient'
import { useShowFirstTimeGuide } from '../../utils/FirstTimeGuideContext'
import { SharedContext } from '../../utils/SharedContext'
import { ClientApiClient } from '../../utils/clientApi'
import { useCallbackWithForceRefresh } from '../../utils/useCallbackWithForceRefresh'
import { useFetchOne } from '../../utils/useFetch'
import { useRedirectToSupport } from '../../utils/useRedirectToSupport'
import { isZero } from '../../utils/validations'
import { WalletRestrictions, isRestricted } from '../../utils/wallet.utils'
import { ConditionalAddTradingAccount } from '../Trading-Accounts/ConditionalAddTradingAccount'
import { WalletsTable } from './WalletsTable'

import modalStyles from '../../global/modal/CancelActionModal.module.scss'
import styles from './WalletsPage.module.scss'

interface WalletsPageProps {
  walletType: WalletTypeEnum
}

export const WalletsPage: React.FC<WalletsPageProps> = ({ walletType }) => {
  const { account } = useAccountReadContext()
  const [modal, setModalVisible] = useState<boolean>(false)
  const [walletNumberModal, setWalletNumberModal] = useState<{
    visible: boolean
    data?: WalletDto
  }>({ visible: false, data: undefined })
  const [walletToAdd, setWalletToAdd] = useState<WalletCurrency>()

  const [availableWalletsModal, setAvailableWalletsModalVisible] = useState<boolean>(false)
  const [addWalletModal, setAddWalletModalVisible] = useState<boolean>(false)
  const { supportUrl } = useRedirectToSupport()
  const { t } = useTranslation()
  const apiClient = useApiClient(ClientApiClient)
  const [sharedState, setSharedState] = useContext(SharedContext)
  const [bonusData, setBonusData] = useState<BonusTransactionDto[]>()
  const [bonusModal, setBonusModal] = useState(false)
  const [bonusLoading, setBonusLoading] = useState(false)

  const getReservedData = async (walletId: string) => {
    setBonusModal(true)
    setBonusLoading(true)
    try {
      const { items: reservedData } = await apiClient.getActiveBonus(walletId)
      setBonusData(reservedData)
    } catch (e: unknown) {
      setBonusModal(false)
    } finally {
      setBonusLoading(false)
    }
  }

  const closeBonusModal = () => {
    setBonusModal(false)
    setBonusData([])
    setBonusLoading(false)
  }
  const clearWalletCreationState = () => {
    setAddWalletModalVisible(false)
    setWalletToAdd(undefined)
    forceRefresh()
    forceRefreshAvailableWallets()
  }

  const openWallet = async () => {
    if (walletToAdd) {
      try {
        await apiClient.addWallet({
          walletTypeId: walletType,
          walletStateId: WalletStateEnum.Active,
          currencyId: walletToAdd.id,
        })
      } finally {
        clearWalletCreationState()
      }
    }
    clearWalletCreationState()
  }

  const { callback: walletsCallback, forceRefresh } = useCallbackWithForceRefresh(async () => {
    let { walletTypes } = sharedState
    if (!sharedState.walletTypes.length) {
      walletTypes = await apiClient.getWalletTypes()
    }
    setSharedState((currentState) => ({
      ...currentState,
      walletTypes,
    }))

    // TODO Ask why it's not working and brings all wallets
    return await apiClient.getWallets({ walletTypeId: walletType })
  }, [])

  const { data: wallets = [], isLoading } = useFetchOne(walletsCallback)

  const { callback: availableWalletsCallback, forceRefresh: forceRefreshAvailableWallets } =
    useCallbackWithForceRefresh(async () => {
      return apiClient.getAvailableWallets(walletType)
    }, [])
  const { data: availableWalletTypes = [], isLoading: isLoadingWalletTypes } =
    useFetchOne(availableWalletsCallback)

  const showFirstTimeGuid = useShowFirstTimeGuide()

  const getWalletCreationRestrictionInfo = (): {
    restrictionTitle: string
    restrictionDescription: string
  } => {
    if (isRestricted(WalletRestrictions.CREATE_WALLET, account?.restrictions)) {
      return {
        restrictionTitle: 'Restricted Access',
        restrictionDescription: 'errors.Client is restricted to create a wallet',
      }
    }
    if (isZero(availableWalletTypes.length)) {
      return {
        restrictionTitle: 'Wallet.Maximum limit of Wallets reached!',
        restrictionDescription:
          'Wallet.You have reached the maximum limit of Wallets! Please contact our Support team for more information.',
      }
    }
    return {
      restrictionTitle: 'Restricted Access',
      restrictionDescription: 'errors.Client is restricted to create a wallet',
    }
  }

  const { restrictionTitle, restrictionDescription } = getWalletCreationRestrictionInfo()

  return (
    <div>
      {showFirstTimeGuid && <FirstTimeGuideCarousel />}
      {modal && (
        <Modal
          closeModal={() => setModalVisible(false)}
          render={({ closeModal }) => (
            <RestrictionActionModal
              onCancel={closeModal}
              onConfirm={() => {
                setModalVisible(false)
              }}
            />
          )}
        />
      )}
      {walletNumberModal.visible && (
        <Modal
          closeModal={() =>
            setWalletNumberModal({
              visible: false,
              data: undefined,
            })
          }
          render={({ closeModal }) => (
            <WalletNumberModal wallet={walletNumberModal.data!} onConfirm={closeModal} />
          )}
        />
      )}
      {!!bonusModal && (
        <Modal
          closeModal={closeBonusModal}
          render={({ closeModal }) => (
            <InfoModal
              onCancel={closeModal}
              title={t('Wallet.Reserved funds for Bonus')}
              renderBody={() => (
                <section className={styles.bonusItemsWrapper}>
                  <Loading showLoadingIcon isLoading={bonusLoading}>
                    {bonusData?.map((data) => (
                      <div className={styles.bonusItems}>
                        <TextStrong>
                          {t('Reference')}: <Text>{data.referenceId}</Text>
                        </TextStrong>
                        <TextStrong>
                          {t('Wallet.Bonus amount')}: <Text>{data.amount}</Text>
                        </TextStrong>
                        <TextStrong>
                          {t('Wallet.Bonus reserved')}: <Text>{data.bonusReserved}</Text>
                        </TextStrong>
                        <TextStrong>
                          {t('Wallet.Lots required')}: <Text>{data.lotsRequired}</Text>
                        </TextStrong>
                        <TextStrong>
                          {t('Wallet.Lots traded')}: <Text>{data.lotsTraded}</Text>
                        </TextStrong>
                      </div>
                    ))}
                  </Loading>
                </section>
              )}
              renderFooter={() => (
                <button className='button' onClick={closeModal} type='button'>
                  {t('Got It')}
                </button>
              )}
              onConfirm={closeModal}
            />
          )}
        />
      )}
      {addWalletModal && (
        <Modal
          closeModal={() => setAddWalletModalVisible(false)}
          render={({ closeModal }) => (
            <SelectableModal
              onCancel={closeModal}
              title={t('Add New Wallet')}
              renderOptions={() => (
                <React.Fragment>
                  <div className='control'>
                    <label className={classNames('column is-full-desktop radio is-center')}>
                      <span className='is-flex'>
                        {t('A new wallet will be created for the specified currency.')}
                      </span>
                    </label>
                    {availableWalletTypes.map((walletCurr) => {
                      return (
                        <label
                          key={walletCurr.id}
                          className={classNames(styles.text, 'radio column is-full-desktop radio')}
                        >
                          <input
                            className={styles.radio}
                            onClick={() => setWalletToAdd(walletCurr)}
                            type='radio'
                            value={walletCurr.id}
                            name='option'
                          />{' '}
                          {walletCurr.id}
                        </label>
                      )
                    })}
                  </div>
                </React.Fragment>
              )}
              renderFooter={() => (
                <React.Fragment>
                  <button className='button' onClick={() => setAddWalletModalVisible(false)}>
                    <span className='text-small'>{t('Cancel')}</span>
                  </button>
                  <button
                    className='button'
                    onClick={() => {
                      openWallet()
                    }}
                  >
                    <span className='text-small-strong'>{t('Add')}</span>
                  </button>
                </React.Fragment>
              )}
            />
          )}
        />
      )}
      {availableWalletsModal && (
        <Modal
          closeModal={() => setAvailableWalletsModalVisible(false)}
          render={({ closeModal }) => (
            <InfoModal
              onCancel={closeModal}
              title={t(restrictionTitle)}
              renderBody={() => (
                <section className={classNames('modal-card-body', modalStyles.body)}>
                  <p
                    className={styles.text}
                    dangerouslySetInnerHTML={{
                      __html: t(restrictionDescription),
                    }}
                  />
                </section>
              )}
              onConfirm={() => setAvailableWalletsModalVisible(false)}
              renderFooter={() => (
                <>
                  <Link to={supportUrl}>
                    <button className='button has-text-weight-normal' type='button'>
                      {t('Contact Support')}
                    </button>
                  </Link>
                  <button
                    className='button'
                    onClick={() => setAvailableWalletsModalVisible(false)}
                    type='button'
                  >
                    {t('Got It')}
                  </button>
                </>
              )}
            />
          )}
        />
      )}
      <Loading showLoadingIcon isLoading={isLoading || isLoadingWalletTypes}>
        <WalletsTable
          getReservedData={getReservedData}
          wallets={filterWalletsByType(wallets, walletType)}
          setModalVisible={setModalVisible}
          setAvailableWalletsModalVisible={setAvailableWalletsModalVisible}
          setAddWalletModal={setAddWalletModalVisible}
          setWalletNumberModal={setWalletNumberModal}
          isWalletCreationRestricted={
            isRestricted(WalletRestrictions.CREATE_WALLET, account?.restrictions) ||
            isZero(availableWalletTypes.length)
          }
          account={account}
        />
      </Loading>
    </div>
  )
}
