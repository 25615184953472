import React, { MouseEventHandler, PropsWithChildren, ReactNode } from 'react'
import classNames from 'classnames'

import styles from './Button.module.scss'

enum ButtonAppearance {
  Primary = 'primary',
  Secondary = 'secondary',
  Plain = 'plain',
  Link = 'link',
  Tertiary = 'tertiary',
  Selectable = 'selectable',
}

enum ButtonState {
  Normal = 'normal',
  Focus = 'focus',
  Disabled = 'disabled',
}

export interface ButtonProps {
  type?: 'submit' | 'reset' | 'button' | undefined
  appearance?: 'primary' | 'secondary' | 'plain' | 'link' | 'tertiary' | 'selectable'
  state?: 'normal' | 'focus' | 'disabled' | undefined
  size?: 'XS' | 'S' | 'M' | 'L'
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  fullWidth?: boolean
  className?: string
  renderLeftIcon?: () => ReactNode
  renderRightIcon?: () => ReactNode
  onMouseOver?: MouseEventHandler<HTMLButtonElement>
  onMouseOut?: MouseEventHandler<HTMLButtonElement>
}

export const Button: React.FunctionComponent<PropsWithChildren<ButtonProps>> = ({
  type,
  className,
  appearance,
  state,
  size,
  onClick,
  disabled,
  fullWidth,
  renderLeftIcon,
  renderRightIcon,
  onMouseOut,
  onMouseOver,
  children,
}) => {
  return (
    <button
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      className={classNames('button', className, styles.btn, {
        [styles.tertiary]: appearance === ButtonAppearance.Tertiary,
        [styles.isPlain]: appearance === ButtonAppearance.Plain,
        [styles.primary]: appearance === ButtonAppearance.Primary,
        [styles.secondary]: appearance === ButtonAppearance.Secondary,
        [styles.link]: appearance === ButtonAppearance.Link,
        [styles.selectable]: appearance === ButtonAppearance.Selectable,
        [styles.focus]: state === ButtonState.Focus,
        [styles.disabled]: state === ButtonState.Disabled || disabled,
        [styles.fullWidth]: fullWidth,
        [styles[`size-${size}`]]: !!size,
      })}
      type={type}
      disabled={disabled}
    >
      {renderLeftIcon && (
        <span className={classNames('is-flex is-align-items-center', styles.isIconLeft)}>
          {renderLeftIcon()}
        </span>
      )}
      <span>{children}</span>
      {renderRightIcon && (
        <span className={classNames('is-flex is-align-items-center', styles.isIconRight)}>
          {renderRightIcon()}
        </span>
      )}
    </button>
  )
}
