import { IconProps } from './IconProps'

import styles from './Icons.module.scss'

export function QRCodeIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 80 80'
      fill='none'
      {...props}
    >
      <path
        d='M63.75 52.5H53.75C53.0596 52.5 52.5 53.0596 52.5 53.75V63.75C52.5 64.4404 53.0596 65 53.75 65H63.75C64.4404 65 65 64.4404 65 63.75V53.75C65 53.0596 64.4404 52.5 63.75 52.5Z'
        fill='#BEBEBE'
      />
      <path
        d='M51.25 42.5H43.75C43.0596 42.5 42.5 43.0596 42.5 43.75V51.25C42.5 51.9404 43.0596 52.5 43.75 52.5H51.25C51.9404 52.5 52.5 51.9404 52.5 51.25V43.75C52.5 43.0596 51.9404 42.5 51.25 42.5Z'
        fill='#BEBEBE'
      />
      <path
        d='M73.75 65H66.25C65.5596 65 65 65.5596 65 66.25V73.75C65 74.4404 65.5596 75 66.25 75H73.75C74.4404 75 75 74.4404 75 73.75V66.25C75 65.5596 74.4404 65 73.75 65Z'
        fill='#BEBEBE'
      />
      <path
        d='M73.75 42.5H68.75C68.0596 42.5 67.5 43.0596 67.5 43.75V48.75C67.5 49.4404 68.0596 50 68.75 50H73.75C74.4404 50 75 49.4404 75 48.75V43.75C75 43.0596 74.4404 42.5 73.75 42.5Z'
        fill='#BEBEBE'
      />
      <path
        d='M48.75 67.5H43.75C43.0596 67.5 42.5 68.0596 42.5 68.75V73.75C42.5 74.4404 43.0596 75 43.75 75H48.75C49.4404 75 50 74.4404 50 73.75V68.75C50 68.0596 49.4404 67.5 48.75 67.5Z'
        fill='#BEBEBE'
      />
      <path
        d='M63.75 15H53.75C53.0596 15 52.5 15.5596 52.5 16.25V26.25C52.5 26.9404 53.0596 27.5 53.75 27.5H63.75C64.4404 27.5 65 26.9404 65 26.25V16.25C65 15.5596 64.4404 15 63.75 15Z'
        fill='#BEBEBE'
      />
      <path
        d='M70 7.5H47.5C46.1193 7.5 45 8.61929 45 10V32.5C45 33.8807 46.1193 35 47.5 35H70C71.3807 35 72.5 33.8807 72.5 32.5V10C72.5 8.61929 71.3807 7.5 70 7.5Z'
        stroke='#BEBEBE'
        stroke-width='3'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M26.25 15H16.25C15.5596 15 15 15.5596 15 16.25V26.25C15 26.9404 15.5596 27.5 16.25 27.5H26.25C26.9404 27.5 27.5 26.9404 27.5 26.25V16.25C27.5 15.5596 26.9404 15 26.25 15Z'
        fill='#BEBEBE'
      />
      <path
        d='M32.5 7.5H10C8.61929 7.5 7.5 8.61929 7.5 10V32.5C7.5 33.8807 8.61929 35 10 35H32.5C33.8807 35 35 33.8807 35 32.5V10C35 8.61929 33.8807 7.5 32.5 7.5Z'
        stroke='#BEBEBE'
        stroke-width='3'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M26.25 52.5H16.25C15.5596 52.5 15 53.0596 15 53.75V63.75C15 64.4404 15.5596 65 16.25 65H26.25C26.9404 65 27.5 64.4404 27.5 63.75V53.75C27.5 53.0596 26.9404 52.5 26.25 52.5Z'
        fill='#BEBEBE'
      />
      <path
        d='M32.5 45H10C8.61929 45 7.5 46.1193 7.5 47.5V70C7.5 71.3807 8.61929 72.5 10 72.5H32.5C33.8807 72.5 35 71.3807 35 70V47.5C35 46.1193 33.8807 45 32.5 45Z'
        stroke='#BEBEBE'
        stroke-width='3'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
