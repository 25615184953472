import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Loading } from '../../global/Loading/Loading'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { ExternalLinkIcon } from '../../icons/ExternalLinkIcon'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useFetchOne } from '../../utils/useFetch'
import { useIBReferralCodes } from '../../utils/useIBReferralCodes'

import styles from './LegalDocuments.module.scss'

export const LegalDocumentsPage: React.FC = () => {
  const apiClient = useApiClient(ClientApiClient)
  const { legalDocumentsSchemes, isLoading: referralCodesLoading } = useIBReferralCodes()
  const { t } = useTranslation()
  const locale = useSessionLanguage()

  const legalDocumentsCallback = useCallback(async () => {
    return apiClient.getIntroducingBrokerLegalDocuments(locale, legalDocumentsSchemes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legalDocumentsSchemes, locale])

  const { data: legalDocuments, isLoading } = useFetchOne(legalDocumentsCallback)

  return (
    <div className={styles.wrapper}>
      <PageHeader title={t('Legal documents.Legal documents')} />
      <Loading showLoadingIcon isLoading={isLoading || referralCodesLoading}>
        <div className={styles.cards}>
          {legalDocuments?.map(({ code, title, url }) => (
            <div className={styles.card} key={code}>
              <div className='column'>
                <p>{title}</p>
              </div>
              <div className='column has-text-right'>
                <a target='_blank' href={url} rel='noreferrer'>
                  <ExternalLinkIcon size={15} />
                </a>
              </div>
            </div>
          ))}
        </div>
      </Loading>
    </div>
  )
}
