import React from 'react'
import { useTranslation } from 'react-i18next'

import { InfoModal } from '../../global/modal/InfoModal'
import { Modal } from '../../global/modal/Modal'

import styles from './CompanyDetailsStep.module.scss'

interface ShareholderModalProps {
  onClose(): void
}

export const ShareholderModal: React.FC<ShareholderModalProps> = (props) => {
  const { onClose } = props

  const { t } = useTranslation()

  return (
    <Modal
      cardClassName={styles.modal}
      closeModal={onClose}
      render={({ closeModal }) => (
        <InfoModal
          onCancel={closeModal}
          title={t('Sign up.Shareholder')}
          renderBody={() => (
            <section className='modal-card-body'>
              <p
                dangerouslySetInnerHTML={{
                  __html: t(
                    'Sign up.Each Director, and any Shareholder who owns more than 25% of the shares must'
                  ),
                }}
              />
            </section>
          )}
          renderFooter={() => (
            <button className='button' onClick={onClose} type='button'>
              <b>{t('Got It')}</b>
            </button>
          )}
          onConfirm={onClose}
        />
      )}
    />
  )
}
