import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Notification } from '../notification/notification'

import styles from '../notification/notification.module.scss'

export interface Toast {
  type: 'danger' | 'success' | 'warning'
  title?: string
  skipScroll?: boolean
  subtitle?: string
  dangerouslySetInnerHTML?: { __html: string }
  url?: string
  onClick?: () => any
  requireInteraction?: boolean
}

export const isToastTypeDanger = (type: string): boolean => {
  return type === 'danger'
}

export const isToastTypeSuccess = (type: string): boolean => {
  return type === 'success'
}

export const isToastTypeWarning = (type: string): boolean => {
  return type === 'warning'
}

export const isToastSilent = (variant?: 'silent' | 'normal'): boolean => {
  return variant === 'silent'
}

export const ToastContext = React.createContext<(toast: Toast | undefined) => void>(() => {})

const toastWithOptions = (titleOrOptions: string | Partial<Toast>, moreOptions: Toast): Toast => {
  const base = typeof titleOrOptions === 'string' ? { title: titleOrOptions } : titleOrOptions
  return { ...base, ...moreOptions }
}

export const errorToast = (titleOrOptions: string | Partial<Toast>): Toast =>
  toastWithOptions(titleOrOptions, { type: 'danger' })
export const infoToast = (titleOrOptions: string | Partial<Toast>): Toast =>
  toastWithOptions(titleOrOptions, { type: 'warning' })

export const successToast = (titleOrOptions: string | Partial<Toast>): Toast =>
  toastWithOptions(titleOrOptions, { type: 'success' })

interface Props {
  of?: Toast
}

export const ToastBanner: React.FC<Props> = ({ of }) => {
  const setToast = useContext(ToastContext)
  const navigate = useNavigate()

  const handleClick = () => {
    if (of?.url || of?.onClick) {
      if (of.onClick) {
        of.onClick()
      } else {
        navigate(of.url!)
      }
      return setToast(undefined)
    } else {
      return undefined
    }
  }

  useEffect(() => {
    if (of && !of.skipScroll) window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [of])

  const handleClose = () => {
    setToast(undefined)
    if (of?.onClick) {
      of.onClick()
    }
  }

  if (!of) {
    return null
  }

  return (
    <div className={styles.notificationWrapper}>
      <Notification
        variant={of.type}
        title={of.title}
        subtitle={of.subtitle}
        dangerouslySetInnerHTML={of.dangerouslySetInnerHTML}
        onClick={handleClick}
        onCancel={handleClose}
      />
    </div>
  )
}
