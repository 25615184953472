import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'
import classNames from 'classnames'
import { Form, FormikErrors, FormikProps, useFormikContext, withFormik } from 'formik'
import { t } from 'i18next'

import { DocumentInfoPageProofOfAddressStepFormValues } from '../../SignUp/DocumentsInfoPage/DocumentInfoPageProofOfAddressStepForm'
import { Loading } from '../../global/Loading/Loading'
import { Button } from '../../global/button/Button'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { CopyLink } from '../../global/copyLink/CopyLink'
import { createFormPasswordField } from '../../global/formField/FormPasswordField'
import IconButton from '../../global/iconButton/IconButton'
import { InfoModal } from '../../global/modal/InfoModal'
import { Modal } from '../../global/modal/Modal'
import { CopyIcon } from '../../icons/CopyIcon'
import { DownloadIcon } from '../../icons/DownloadIcon'
import { InfoIcon } from '../../icons/InfoIcon'
import { TwoCircularArrowsIcon } from '../../icons/TwoCircularArrowsIcon'
import {
  AccountDetailedDto,
  ClientPhoneNumberDto,
  getVerifiedPhoneNumbers,
} from '../../model/AccountDetailedDto'
import { NameDto } from '../../model/NameDto'
import {
  TwoFAProviderEnum,
  TwoFAProviderItem,
  TwoFactorAuthRecoveryCodeDto,
} from '../../model/TwoFactorAuthentication'
import { CheckboxSwitch } from '../../ui/CheckboxSwitch/CheckboxSwitch'
import { Tooltip } from '../../ui/Popups/Tooltip/Tooltip'
import { Text, TextStrong } from '../../ui/Typography/Typography'
import { ResponseError, instanceOfAxiosError, useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useWindowResize } from '../../utils/domUtils'
import { FormSubmitValues } from '../../utils/formValidation'

import styles from './AccountSettingsPage.module.scss'

export interface TwoFactorAuthenticationProps {
  activeProviders: TwoFAProviderItem[]
  account?: AccountDetailedDto
  providers: NameDto[]
  onProviderToggle: (provider: NameDto, isActive: boolean) => void
  onPhoneNumberChange: (phone: string, provider: NameDto) => void
  onGenerateRecoveryCodes: () => void
}

export const TwoFactorAuthentication: React.FC<TwoFactorAuthenticationProps> = ({
  providers,
  onProviderToggle,
  activeProviders,
  account,
  onPhoneNumberChange,
  onGenerateRecoveryCodes,
}) => {
  const { t } = useTranslation()

  const email = account?.emails.find((x) => x.isPrimary)
  const phone = activeProviders.find(
    (activeProvider) => activeProvider.type.id === TwoFAProviderEnum.SMS
  )?.authMedium
  const verifiedPhones = getVerifiedPhoneNumbers(account)

  return (
    <>
      <Text isParagraph className={styles.twoFASubtitle}>
        {t(
          'Profile.We highly recommend enabling two-factor authentication as an essential measure to enhance the security of your account'
        )}
      </Text>
      {providers.map((provider) => {
        const isSwitchOn = activeProviders.some(
          (activeProvider) => activeProvider.type.id === provider.id
        )
        const isPhoneDisabled = !verifiedPhones?.length && provider.id === TwoFAProviderEnum.SMS
        const isSwitchDisabled = isPhoneDisabled
        return (
          <div
            key={provider.name}
            className='is-flex is-align-items-center is-justify-content-space-between'
          >
            <TextStrong
              className={classNames(
                styles.twoFALabel,
                'is-flex',
                'is-align-items-center',
                'is-flex-wrap-wrap'
              )}
            >
              <span className='mr-2'>{provider.name}</span>
              {provider.id === TwoFAProviderEnum.SMS && (
                <span className='is-flex mr-3'>
                  <Tooltip
                    autoHide={2000}
                    content={t(
                      'Profile.SMS can be enabled together with Email to increase the deliverability of the verification code'
                    )}
                    direction={'topRight'}
                    className={styles.tooltipContainer}
                  >
                    <InfoIcon size={12} />
                  </Tooltip>
                </span>
              )}
              {provider.id === TwoFAProviderEnum.Email && isSwitchOn && (
                <Text className={classNames(styles.textContrastMediumLow, 'mr-3')}>
                  {email?.address}
                </Text>
              )}
              {provider.id === TwoFAProviderEnum.SMS && !isPhoneDisabled && (
                <span className='is-flex is-align-items-center'>
                  {isSwitchOn && (
                    <Text className={classNames(styles.textContrastMediumLow)}>{phone}</Text>
                  )}
                  {isSwitchOn && verifiedPhones && verifiedPhones?.length > 1 && (
                    <button
                      onClick={() => phone && onPhoneNumberChange(phone, provider)}
                      className={classNames('is-link', 'ml-1')}
                    >
                      <Text>{t('Profile.Change')}</Text>
                    </button>
                  )}
                </span>
              )}
            </TextStrong>
            <span>
              <Tooltip
                autoHide={2000}
                direction='topLeft'
                disabled={!isPhoneDisabled}
                content={t(`Profile.Verify your phone number at 'My Phone Numbers'`)}
              >
                <CheckboxSwitch
                  disabled={isSwitchDisabled}
                  name={provider.name}
                  value={isSwitchOn}
                  onChange={(value) => onProviderToggle(provider, value)}
                />
              </Tooltip>
            </span>
          </div>
        )
      })}
      {activeProviders.length > 0 && (
        <div className='is-flex is-justify-content-center'>
          <Button
            appearance='selectable'
            renderLeftIcon={() => <TwoCircularArrowsIcon />}
            onClick={() => onGenerateRecoveryCodes()}
          >
            {t('Profile.View Recovery Codes')}
          </Button>
        </div>
      )}
    </>
  )
}

interface InfoModalProps {
  isOpen?: boolean
  close(): void
}

export const TwoFactorAuthenticationInfoModal: React.FC<InfoModalProps> = ({ close, isOpen }) => {
  const { t } = useTranslation()
  if (!isOpen) return null
  return (
    <Modal
      closeModal={close}
      render={() => (
        <InfoModal
          title={t('Profile.Two-factor authentication')}
          renderFooter={() => {
            return (
              <button className='button' onClick={close} type='button'>
                {t('Got It')}
              </button>
            )
          }}
          renderBody={() => {
            return (
              <>
                <section className='modal-card-body'>
                  <TextStrong
                    isParagraph
                    dangerouslySetInnerHTML={{
                      __html: t('Profile.What is Two-Factor Authentication?'),
                    }}
                  />
                  <Text
                    isParagraph
                    dangerouslySetInnerHTML={{
                      __html: t(
                        'Profile.A feature that helps you get access to your Client Area with improved security and minimising the security breach threats'
                      ),
                    }}
                  />
                </section>
                <div className={styles.twoFAModalDivider} />
                <section className='modal-card-body'>
                  <TextStrong
                    isParagraph
                    dangerouslySetInnerHTML={{
                      __html: t('Profile.How does it work?'),
                    }}
                  />
                  <Text
                    isParagraph
                    dangerouslySetInnerHTML={{
                      __html: t(
                        'Profile.Once enabled, a verification code will be sent to you via your selected method every time you log in'
                      ),
                    }}
                  />
                </section>
              </>
            )
          }}
        />
      )}
    />
  )
}

const FormField = createFormPasswordField<TwoFactorAuthenticationPasswordModalFormValues>()

interface OuterProps {
  onSubmit(values: FormSubmitValues<TwoFactorAuthenticationPasswordModalFormValues>): Promise<void>
  close: () => void
  isOpen: boolean
}

export interface TwoFactorAuthenticationPasswordModalFormValues {
  password: string
}

const TwoFactorAuthenticationPasswordModalFormUI: React.FC<
  FormikProps<TwoFactorAuthenticationPasswordModalFormValues> & OuterProps
> = (props) => {
  const { handleSubmit, setSubmitting, close, isOpen, resetForm } = props

  const locale = useSessionLanguage()

  const handleSubmitForm = (event: SyntheticEvent) => {
    event.preventDefault()
    setSubmitting(true)
    handleSubmit()
  }

  const onClose = () => {
    resetForm()
    close()
  }

  useEffect(() => {
    props.validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  return (
    <>
      <Form onSubmit={handleSubmitForm}>
        <TwoFactorAuthenticationPasswordModal close={onClose} isOpen={isOpen} />
      </Form>
    </>
  )
}

export const TwoFactorAuthenticationPasswordFormModal = withFormik<
  OuterProps,
  TwoFactorAuthenticationPasswordModalFormValues
>({
  mapPropsToValues: () => {
    return {
      password: '',
    }
  },
  handleSubmit: async (values, { props, setSubmitting, setErrors, resetForm }) => {
    try {
      await props.onSubmit(values)

      resetForm()
    } catch (error: unknown) {
      if (
        (instanceOfAxiosError(error as Error) &&
          (error as AxiosError).response?.data.code === 'invalid_credentials') ||
        (error instanceof ResponseError &&
          error.response.response?.data.code === 'invalid_credentials')
      ) {
        setErrors({
          password: t('errors.Password does not match'),
        })
      }
    } finally {
      setSubmitting(false)
    }
  },
  validate: (values) => {
    const errors: FormikErrors<TwoFactorAuthenticationPasswordModalFormValues> = {}
    if (!values.password) {
      errors.password = t('Validation.Required')
    }

    return errors
  },
  enableReinitialize: true,
  isInitialValid: false,
})(TwoFactorAuthenticationPasswordModalFormUI)

interface TwoFactorAuthenticationPasswordModalProps {
  close: () => void
  isOpen?: boolean
}

export const TwoFactorAuthenticationPasswordModal: React.FC<
  TwoFactorAuthenticationPasswordModalProps
> = ({ close, isOpen }) => {
  const { t } = useTranslation()

  const { handleSubmit, isValid, dirty } =
    useFormikContext<DocumentInfoPageProofOfAddressStepFormValues>()

  const handleClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    close()
  }

  if (!isOpen) return null
  return (
    <Modal
      closeModal={close}
      render={() => (
        <>
          <header className='modal-card-head'>
            <p className='modal-card-title'>{t('Profile.2FA for Log in')}</p>
          </header>
          <section className={'modal-card-body'}>
            <div className='pb-4'>
              <Text>{t('Profile.Please confirm with your Client Area password')}</Text>
            </div>

            <FormField name='password' type='password' placeholder={t('Password')} />
          </section>
          <footer className={'modal-card-foot'}>
            <button className='button' onClick={handleClose} type='button'>
              {t('Cancel')}
            </button>
            <button
              className='button'
              onClick={() => handleSubmit()}
              disabled={!(isValid && dirty)}
              type='button'
            >
              {t('Confirm')}
            </button>
          </footer>
        </>
      )}
    />
  )
}

export interface TwoFactorAuthenticationSelectPhoneModalProps {
  close: () => void
  isOpen: boolean
  phoneNumbers: ClientPhoneNumberDto[]
  selectPhone: (phoneNumber: string) => void
  selectedPhone?: string
  onConfirm: (phoneNumber: string) => void
}

export const TwoFactorAuthenticationSelectPhoneModal: React.FC<
  TwoFactorAuthenticationSelectPhoneModalProps
> = ({ close, isOpen, phoneNumbers, onConfirm, selectedPhone, selectPhone }) => {
  const { t } = useTranslation()

  if (!isOpen) return null
  return (
    <Modal
      closeModal={close}
      render={() => (
        <>
          <header className='modal-card-head'>
            <p className='modal-card-title'>{t('Profile.2FA for Log in')}</p>
          </header>
          <section className='modal-card-body p-0'>
            {phoneNumbers.map((phone) => {
              return (
                <li className={styles.twoFAModalRadioListItem} key={phone.id}>
                  <input
                    type='radio'
                    value={phone.id}
                    defaultChecked={selectedPhone === phone.countryCode + phone.number}
                    onClick={() => selectPhone(phone.countryCode + phone.number)}
                    name='sessionTheme'
                  />
                  <label className='radio ml-4'>
                    {phone.countryCode} {phone.number}
                  </label>
                </li>
              )
            })}
          </section>
          <footer className={'modal-card-foot'}>
            <button className='button' onClick={close} type='button'>
              {t('Cancel')}
            </button>
            <button
              className='button'
              onClick={() => selectedPhone && onConfirm(selectedPhone)}
              disabled={!selectedPhone}
              type='submit'
            >
              {t('Confirm')}
            </button>
          </footer>
        </>
      )}
    />
  )
}

interface ConfirmTurnOffTwoFactorAuthenticationModalProps {
  onConfirm: () => void
  close: () => void
  isOpen?: boolean
}

export const ConfirmTurnOffTwoFactorAuthenticationModal: React.FC<
  ConfirmTurnOffTwoFactorAuthenticationModalProps
> = ({ onConfirm, close, isOpen }) => {
  const { t } = useTranslation()
  if (!isOpen) return null

  return (
    <Modal
      closeModal={close}
      render={() => (
        <InfoModal
          title={t('Warning')}
          renderFooter={() => {
            return (
              <>
                <button className='button' onClick={close} type='button'>
                  {t('Cancel')}
                </button>
                <button className='button' onClick={onConfirm} type='button'>
                  {t('Profile.Accept the risk')}
                </button>
              </>
            )
          }}
          renderBody={() => {
            return (
              <section className='modal-card-body'>
                <Text
                  isParagraph
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'Profile.We advise you not to turn off your Two-Factor authentication (2FA) or any of the enabled channels, as that will remove the extra layer of security the 2FA adds to your Client Area'
                    ),
                  }}
                />
              </section>
            )
          }}
        />
      )}
    />
  )
}

export interface TwoFactorAuthenticationGenerateRecoveryCodesModalProps {
  close: () => void
  isOpen: boolean
}

export const TwoFactorAuthenticationGenerateRecoveryCodesModal: React.FC<
  TwoFactorAuthenticationGenerateRecoveryCodesModalProps
> = ({ close, isOpen }) => {
  const { t } = useTranslation()
  const apiClient = useApiClient(ClientApiClient)

  const [isRecoveryCodesLoading, setIsRecoveryCodesLoading] = useState(false)
  const [recoveryCodes, setRecoveryCodes] = useState<TwoFactorAuthRecoveryCodeDto[]>([])

  const generateRecoveryCodes = useCallback(async () => {
    try {
      setIsRecoveryCodesLoading(true)
      const response = await apiClient.get2FARecoveryCodesGenerate()
      setRecoveryCodes(response.recoveryCodesDto)
      setIsRecoveryCodesLoading(false)
    } catch (error: unknown) {
      console.error(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const reGenerateRecoveryCodes = useCallback(async () => {
    try {
      setIsRecoveryCodesLoading(true)
      const response = await apiClient.get2FARecoveryCodesRegenerate()
      setRecoveryCodes(response.recoveryCodesDto)
      setIsRecoveryCodesLoading(false)
    } catch (error: unknown) {
      console.error(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getRecoveryCodesAsText = () => {
    return recoveryCodes.map((code) => code.recoveryCode).join(' ')
  }

  const downloadRecoveryCodesTxtFile = () => {
    const recoveryCodesAsText = recoveryCodes.map((code) => code.recoveryCode).join('\n')

    const blob = new Blob([recoveryCodesAsText], { type: 'text/plain' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = 'recoveryCodes.txt'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  }

  useEffect(() => {
    if (isOpen) {
      generateRecoveryCodes()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  if (!isOpen) return null

  return (
    <Modal
      closeModal={close}
      render={() => (
        <>
          <header className='modal-card-head'>
            <p className='modal-card-title'>{t('Profile.Save your recovery codes')}</p>
          </header>
          <section className={styles.recoveryCodesModalSection}>
            <div className='px-5 py-4'>
              <Text
                isParagraph
                dangerouslySetInnerHTML={{
                  __html: t(
                    `Profile.If you lose access to your device or email, you’ll need to use one of the following recovery codes to log into your Client Area`,
                    {
                      interpolation: { escapeValue: false },
                    }
                  ),
                }}
              ></Text>
            </div>
          </section>
          <section className={styles.recoveryCodesModalCodesSection}>
            <Loading isLoading={isRecoveryCodesLoading} showLoadingIcon>
              <ol>
                {recoveryCodes.map((code) => {
                  return <li>{code.recoveryCode}</li>
                })}
              </ol>
            </Loading>
          </section>
          <section className={styles.recoveryCodesModalSection}>
            <div className='px-5 py-4 is-flex is-align-center is-justify-content-space-between'>
              <div className='is-flex is-align-center is-hidden-mobile'>
                <CopyLink label={`${t('Copied')}!`} value={getRecoveryCodesAsText()}>
                  <Button
                    appearance='selectable'
                    renderLeftIcon={() => <CopyIcon />}
                    className={styles.recoveryCodesModalButton}
                  >
                    {t('Copy')}
                  </Button>
                </CopyLink>
                <Button
                  className={styles.recoveryCodesModalButton}
                  appearance='selectable'
                  renderLeftIcon={() => <DownloadIcon />}
                  onClick={() => downloadRecoveryCodesTxtFile()}
                >
                  {t('Download')}
                </Button>
              </div>
              <div className='is-flex is-align-center is-hidden-tablet is-hidden-desktop'>
                <CopyLink label={`${t('Copied')}!`} value={getRecoveryCodesAsText()}>
                  <IconButton
                    appearance='selectable'
                    className={styles.recoveryCodesModalButtonMobile}
                  >
                    <CopyIcon />
                  </IconButton>
                </CopyLink>
                <IconButton
                  className={styles.recoveryCodesModalButtonMobile}
                  onClick={() => downloadRecoveryCodesTxtFile()}
                  appearance='selectable'
                >
                  <DownloadIcon />
                </IconButton>
              </div>
              <Button
                className={styles.recoveryCodesModalButton}
                appearance='selectable'
                renderLeftIcon={() => <TwoCircularArrowsIcon />}
                onClick={() => reGenerateRecoveryCodes()}
              >
                {t(`Profile.Get new codes`)}
              </Button>
            </div>
          </section>
          <footer className={'modal-card-foot'}>
            <button className='button' onClick={close} type='button'>
              {t('Close')}
            </button>
          </footer>
        </>
      )}
    />
  )
}
