import { isZero } from './validations'

export const isEmpty = (v: unknown): boolean => {
  if (v == null) return true
  // tslint:disable-next-line:triple-equals
  if ('boolean' == typeof v) return false
  // tslint:disable-next-line:triple-equals
  if ('number' == typeof v) return isZero(v)
  // tslint:disable-next-line:triple-equals
  if ('string' == typeof v) return isZero(v.trim().length)
  // tslint:disable-next-line:triple-equals
  if ('function' == typeof v) return isZero(v.length)
  if (Array.isArray(v)) return isZero(v.length)
  if (v instanceof Error) return isEmpty(v.message)
  if ('object' === typeof v) return isZero(Object.entries(v as Record<string, unknown>).length)

  return false
}

export const isNotEmpty = (v: unknown) => !isEmpty(v)
