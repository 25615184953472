import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { Paging } from '../global/Paging/Paging'
import { BackButton } from '../global/backButton/BackButton'
import { Button } from '../global/button/Button'
import { useProductReadContext } from '../global/context/ProductContext'
import { useSessionLanguage } from '../global/context/SessionSettingsContext'
import { defineMiniTab } from '../global/tabs/Tabs'
import { Notification, getNotificationSearchQueryById } from '../model/Notification'
import { PageHeader } from '../ui/Table/Header/PageHeader'
import { PageQuery, useApiClient } from '../utils/ApiClient'
import { ClientApiClient } from '../utils/clientApi'
import { getDomainName } from '../utils/cookie.utils'
import { useWindowResize } from '../utils/domUtils'
import { useNotificationUnread } from '../utils/notifications'
import { useCallbackWithForceRefresh } from '../utils/useCallbackWithForceRefresh'
import { useFetchAppendablePage } from '../utils/useFetch'
import { NotificationPreview } from './NotificationPreview'

import styles from './NotificationCenter.module.scss'

export const NotificationCenter: React.FC = () => {
  const isMobile = useWindowResize()
  const { t } = useTranslation()
  const locale = useSessionLanguage()
  const navigate = useNavigate()
  const [notifications, setNotifications] = useState<Notification[]>([])
  const apiClient = useApiClient(ClientApiClient)
  const [selectedTab, setSelectedTab] = useState<string>('')
  const [removingNotification, setRemovingNotification] = useState<string>()
  const { refreshAllNotifications } = useNotificationUnread()
  const { product: tickmillProduct } = useProductReadContext()
  const [isFirstRender, setIsFirstRender] = useState(true)

  const { callback, forceRefresh } = useCallbackWithForceRefresh(
    (query?: PageQuery) =>
      apiClient
        .getNotifications({
          search: getNotificationSearchQueryById(selectedTab, {
            TickmillProduct: tickmillProduct,
          }),
          ...query,
          languageId: locale,
          operator: 'AND',
          domain: getDomainName(true),
        })
        .then((response) => {
          setNotifications(response.items)
          setIsFirstRender(false)
          return response
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  )

  const { meta, setPageQuery, isLoading, pageQuery } = useFetchAppendablePage(callback)

  useEffect(() => {
    if (isFirstRender) return
    setPageQuery!({
      ...pageQuery,
      pageIndex: 1,
      pageSize: 10,
      search: getNotificationSearchQueryById(selectedTab, {
        TickmillProduct: tickmillProduct,
      }),
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab])

  useEffect(() => {
    if (meta && !notifications.length && meta?.pageIndex !== 1)
      setPageQuery!({ ...pageQuery, pageIndex: meta.pageIndex - 1 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta, notifications])

  const openNotification = (id: string) => navigate(`/notifications/${id}`)

  const removeNotification = async (id: string) => {
    setRemovingNotification(id)
    setTimeout(async () => {
      setNotifications((notifications) =>
        notifications.filter((notification) => notification.id !== id)
      )
      await apiClient.deleteNotification(id)
      await forceRefresh()
      await refreshAllNotifications()
    }, 550)
  }

  const readAll = async () => {
    setNotifications((notifications) =>
      notifications.map((notification) => ({
        ...notification,
        isRead: true,
      }))
    )
    await apiClient.readAllNotifications(notifications.map((notification) => notification.id))
    await refreshAllNotifications()
    await forceRefresh()
  }

  const readNotification = async (notification: Notification) => {
    if (!notification.isRead) {
      setNotifications((notifications) =>
        notifications.map((n) => (n.id === notification.id ? { ...n, isRead: true } : n))
      )
      await apiClient.readNotification(notification.id)
    }
  }

  const allowReadAll = useMemo(() => {
    return notifications?.some((notification) => !notification.isRead)
  }, [notifications])

  function* getTabs() {
    const tabs = [
      [t('Notifications.tabs.All'), ''],
      [t('Notifications.tabs.General'), '1'],
      [t('Notifications.tabs.Platform'), '2'],
      [t('Notifications.tabs.Promotional'), '3'],
    ]

    for (const [label, id] of tabs) {
      yield { ...defineMiniTab(label, id) }
    }
  }

  const tabs = [...getTabs()]

  return (
    <div className={styles.notifications}>
      <div className={classNames(styles.backButton)}>
        <BackButton section={t('Notifications.Notification Centre')} className='is-uppercase' />
      </div>
      <PageHeader
        backButton={isMobile ? () => navigate('/') : undefined}
        actions={[
          {
            disabled: !allowReadAll,
            hidden: isMobile,
            onClick: () => readAll(),
            label: t('Notifications.Mark All Read'),
          },
        ]}
        tabsControl={{
          setSelectedTab,
          selectedTab,
          tabs,
        }}
        className={styles.header}
        title={t('Notifications.Notifications')}
      />
      {allowReadAll && (
        <Button
          appearance='plain'
          className={styles.readAll}
          onClick={readAll}
          data-test='notificationcenter-readall'
        >
          {t('Notifications.Mark All Read')}
        </Button>
      )}

      {notifications?.map((notification) => (
        <NotificationPreview
          className={classNames({
            [styles.removing]: removingNotification === notification.id,
          })}
          key={notification.id}
          read={() => readNotification(notification)}
          open={() => openNotification(notification.id)}
          remove={() => removeNotification(notification.id)}
          notification={notification}
        />
      ))}

      {meta && (
        <Paging
          pageData={meta}
          isLoading={isLoading}
          onPageChanged={(pageIndex, pageSize) => {
            window.scrollTo(0, 0)
            setPageQuery!({
              ...pageQuery,
              pageIndex,
              pageSize,
            })
          }}
        />
      )}
    </div>
  )
}
