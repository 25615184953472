import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormikProps, withFormik } from 'formik'

import { createFormField } from '../../global/formField/FormField'
import { CurrencyModal, CurrencyModalItem } from '../../global/modal/CurrencyModal'
import { InformationModal } from '../../global/modal/InformationModal'
import { Modal } from '../../global/modal/Modal'
import { DropArrowDownIcon } from '../../icons/DropArrowDownIcon'
import { NameDto } from '../../model/NameDto'
import { CurrencyType } from '../../model/WalletDto'
import { Text } from '../../ui/Typography/Typography'
import { FormSubmitValues } from '../../utils/formValidation'

const FormField = createFormField<DisplayCurrencyFormValues>()

interface OuterProps {
  currencies: NameDto<CurrencyType>[]
  displayCurrency?: string
  onSubmit(values: FormSubmitValues<DisplayCurrencyFormValues>): Promise<void>
}

export interface DisplayCurrencyFormValues {
  displayCurrencyId: string
}

const DisplayCurrencyFormUI: FC<FormikProps<DisplayCurrencyFormValues> & OuterProps> = (props) => {
  const { currencies, setFieldValue, setSubmitting, handleSubmit, values } = props
  const [currencyModal, setCurrencyModal] = useState(false)

  const handleCurrencyModal = (isOpen: boolean) => {
    setCurrencyModal(isOpen)
  }

  const handleSelectOption = (id: string) => {
    handleCurrencyModal(false)
    setFieldValue('displayCurrencyId', id)
    setSubmitting(true)
    handleSubmit()
  }

  const { t } = useTranslation()

  return (
    <>
      {currencyModal && (
        <CurrencyModal
          currencies={currencies}
          renderItem={({ item }) => (
            <CurrencyModalItem
              selected={item.id === values.displayCurrencyId}
              currency={item}
              onSelectOption={() => handleSelectOption(item.id)}
              key={item.id}
            />
          )}
          onSelectOption={() => {}}
          onClose={() => handleCurrencyModal(false)}
          title={t('Display Currency')}
        />
      )}
      <Text>{t('Choose in what currency you want your total balances to be displayed')}</Text>

      <FormField
        name='displayCurrencyId'
        label={t('Display Currency')}
        placeholder={t('Display Currency')}
        rightIcon={<DropArrowDownIcon />}
        onClick={() => handleCurrencyModal(true)}
      />
    </>
  )
}

export const DisplayCurrencyForm = withFormik<OuterProps, DisplayCurrencyFormValues>({
  mapPropsToValues: ({ displayCurrency }) => {
    return {
      displayCurrencyId: displayCurrency || '',
    }
  },
  validate: () => {},
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit(values)
    } finally {
      setSubmitting(false)
    }
  },
  enableReinitialize: true,
})(DisplayCurrencyFormUI)

interface InfoModalProps {
  close: () => void
  isOpen?: boolean
}

export const CurrencyInfoModal: React.FC<InfoModalProps> = ({ close, isOpen }) => {
  const { t } = useTranslation()
  if (!isOpen) return null
  return (
    <Modal
      closeModal={close}
      render={() => (
        <InformationModal buttonText={t('Got it!')} title={'Display Currency'} onCancel={close}>
          <p>{t('Choose a main currency to show an aggregated amount of your')}</p>
        </InformationModal>
      )}
    />
  )
}
