import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { AppMarketLink } from '../../global/appMarketLink/mobile/AppMarketLink'
import { Button } from '../../global/button/Button'
import { useSessionEntity } from '../../global/context/EntityContext'
import { useProductReadContext } from '../../global/context/ProductContext'
import IconButton from '../../global/iconButton/IconButton'
import { ChevronLeftIcon } from '../../icons/ChevronLeftIcon'
import { ChevronRightIcon } from '../../icons/ChevronRightIcon'
import { CloseIcon } from '../../icons/CloseIcon'
import { CreateTradingAccountIcon } from '../../icons/CreateTradingAccountIcon'
import { FundWalletIcon } from '../../icons/FundWalletIcon'
import { GettingStartedCongratulationsIcon } from '../../icons/GettingStartedCongratulationsIcon'
import { GettingStartedTransferFundsIcon } from '../../icons/GettingStartedDepositFundsIcon'
import { GettingStartedDepositIcon } from '../../icons/GettingStartedDepositIcon'
import { GettingStartedDownloadIcon } from '../../icons/GettingStartedDownloadIcon'
import { GettingStartedEmailIcon } from '../../icons/GettingStartedEmailIcon'
import { GettingStartedMobileDownloadIcon } from '../../icons/GettingStartedMobileDownloadIcon'
import { GettingStartedSubscribeMarketDataIcon } from '../../icons/GettingStartedSubscribeMarketDataIcon'
import { GettingStartedSubscribePlatformIcon } from '../../icons/GettingStartedSubscribePlatformIcon'
import { MakeDepositIcon } from '../../icons/MakeDepositIcon'
import { PointedLeftArrowIcon } from '../../icons/PointedLeftArrowIcon'
import { PointedRightArrowIcon } from '../../icons/PointedRightArrowIcon'
import { SecureClientAreaIcon } from '../../icons/SecureClientAreaIcon'
import { TickmillProductType } from '../../model/TickmillProductType'
import { TickmillCompaniesEnum } from '../../utils/companyName.utils'
import { useWindowResize } from '../../utils/domUtils'
import { isOne } from '../../utils/validations'
import { CarouselDots, CarouselHandlersProps, CarouselNumberDots } from '../Carousel/Carousel'
import { Text, TextSmall, TextStrong } from '../Typography/Typography'

import styles from './FirstTimeGuide.module.scss'

export const FundWalletGuideCarouselChild: React.FC<Partial<CarouselHandlersProps>> = (props) => {
  const { t } = useTranslation()
  const isMobile = useWindowResize()
  const navigate = useNavigate()

  const handleButton = () => {
    navigate('/profile/security-settings')
  }

  return (
    <GuideCarouselChildCFD
      {...props}
      title={t('Secure Your Client Area')}
      description={t('Enable Two-Factor Authentication (2FA)')}
      icon={<SecureClientAreaIcon />}
      button={
        <Button
          appearance='primary'
          onClick={handleButton}
          size='S'
          className={styles.buttonStyle}
          fullWidth={isMobile}
        >
          {t('Go to Settings')}
        </Button>
      }
    />
  )
}

export const CreateTradingAccountGuideCarouselChild: React.FC<
  Partial<CarouselHandlersProps> & { onDeposit: () => void }
> = (props) => {
  const { t } = useTranslation()
  const isMobile = useWindowResize()
  const navigate = useNavigate()
  const { onDeposit } = props

  const handleButton = () => {
    props?.defaultWallet?.id &&
      navigate(`/dashboard/traders-room/wallets/${props.defaultWallet.id}/deposit`)
  }

  return (
    <GuideCarouselChildCFD
      {...props}
      title={t('Fund your Wallet')}
      description={t('Add funds to your Tickmill Wallet')}
      icon={<FundWalletIcon />}
      button={
        <Button
          appearance='primary'
          onClick={onDeposit}
          size='S'
          className={styles.buttonStyle}
          fullWidth={isMobile}
        >
          {t('Deposit')}
        </Button>
      }
    />
  )
}

export const TransferWalletToTradingAccountGuideCarouselChild: React.FC<
  Partial<CarouselHandlersProps>
> = (props) => {
  const { t } = useTranslation()
  const isMobile = useWindowResize()
  const navigate = useNavigate()

  const handleButton = () => {
    navigate('/dashboard/traders-room/trading-accounts/new')
  }

  return (
    <GuideCarouselChildCFD
      {...props}
      title={t('Create a Trading Account')}
      description={t('Open a live trading account')}
      icon={<CreateTradingAccountIcon />}
      button={
        <Button
          appearance='primary'
          onClick={handleButton}
          size='S'
          className={styles.buttonStyle}
          fullWidth={isMobile}
        >
          {t('Create Trading Account')}
        </Button>
      }
    />
  )
}

export const GetMetaTraderGuideCarouselChild: React.FC<Partial<CarouselHandlersProps>> = (
  props
) => {
  const { t } = useTranslation()
  const isMobile = useWindowResize()
  const navigate = useNavigate()

  const handleButton = () => {
    navigate('/dashboard/traders-room/wallets/transfer')
  }

  return (
    <GuideCarouselChildCFD
      {...props}
      title={t('Make a Deposit')}
      description={t('Transfer funds from your Tickmill Wallet')}
      icon={<MakeDepositIcon />}
      button={
        <Button
          appearance='primary'
          onClick={handleButton}
          size='S'
          className={styles.buttonStyle}
          fullWidth={isMobile}
        >
          {t('Transfer Funds')}
        </Button>
      }
    />
  )
}

export const DownloadTradingCarouselChild: React.FC<Partial<CarouselHandlersProps>> = (props) => {
  const { t } = useTranslation()
  const isMobile = useWindowResize()
  const navigate = useNavigate()

  const handleButton = () => {
    navigate('/dashboard/tools/platforms')
  }

  return (
    <GuideCarouselChildCFD
      {...props}
      title={t('Download Trading Platform')}
      description={t('Enjoy trading on the go with the powerful Tickmill')}
      icon={<GettingStartedDownloadIcon />}
      button={
        <Button
          appearance='primary'
          onClick={handleButton}
          size='S'
          className={styles.buttonStyle}
          fullWidth={isMobile}
        >
          {t('Download')}
        </Button>
      }
    />
  )
}

export const TickmillMobileAppCarouselChild: React.FC<Partial<CarouselHandlersProps>> = (props) => {
  const { t } = useTranslation()

  const isLastSlide = props?.active === 5

  return (
    <GuideCarouselChildCFD
      {...props}
      title={t('Tickmill Mobile app')}
      description={t('FirstTimeGuide.To deposit')}
      icon={<GettingStartedMobileDownloadIcon />}
      content={
        isLastSlide && (
          <div className={styles.appLinkContainer}>
            <AppMarketLink wrapperClassName={styles.appLinkWrapper} justIcons hideCFDMessage />
          </div>
        )
      }
    />
  )
}

export const FundYourWalletCarouselChild: React.FC<
  Partial<CarouselHandlersProps> & { onDeposit: () => void }
> = (props) => {
  const { t } = useTranslation()
  const { onDeposit } = props

  return (
    <GuideCarouselChild
      {...props}
      title={t('Fund your Tickmill Wallet')}
      description={t('Min. 1,000 USD or equivalent in GBP, EUR, HKD, SGD and CHF')}
      icon={<GettingStartedDepositIcon />}
      button={
        <Button appearance='primary' onClick={onDeposit} size='S' className={styles.buttonStyle}>
          {t('Learning.Start Deposit')}
        </Button>
      }
    />
  )
}

export const TransferFundsFromWalletToTradingAccountCarouselChild: React.FC<
  Partial<CarouselHandlersProps>
> = (props) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const handleButton = () => {
    navigate('/dashboard/traders-room/wallets/transfer')
  }

  return (
    <GuideCarouselChild
      {...props}
      title={t('Learning.Transfer funds from Wallet to Trading Account')}
      description={t(
        'Learning.Please note that the payment for the required trading platform and market'
      )}
      icon={<GettingStartedTransferFundsIcon />}
      button={
        <Button appearance='primary' onClick={handleButton} size='S' className={styles.buttonStyle}>
          {t('Learning.Start Transfer')}
        </Button>
      }
    />
  )
}

export const SubscribeToTradingPlatformCarouselChild: React.FC<Partial<CarouselHandlersProps>> = (
  props
) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const handleButton = () => {
    navigate('/dashboard/subscriptions/trading-platforms')
  }

  return (
    <GuideCarouselChild
      {...props}
      title={t('Learning.Subscribe to Trading Platform')}
      icon={<GettingStartedSubscribePlatformIcon />}
      button={
        <Button appearance='primary' onClick={handleButton} size='S' className={styles.buttonStyle}>
          {t('Learning.Subscribe')}
        </Button>
      }
    />
  )
}

export const SubscribeToMarketDataCarouselChild: React.FC<Partial<CarouselHandlersProps>> = (
  props
) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const handleButton = () => {
    navigate('/dashboard/subscriptions/market-data')
  }

  return (
    <GuideCarouselChild
      {...props}
      title={t('Learning.Subscribe to Market Data')}
      icon={<GettingStartedSubscribeMarketDataIcon />}
      button={
        <Button appearance='primary' onClick={handleButton} size='S' className={styles.buttonStyle}>
          {t('Learning.Subscribe')}
        </Button>
      }
    />
  )
}

export const CheckEmailInstructionsCarouselChild: React.FC<Partial<CarouselHandlersProps>> = (
  props
) => {
  const { t } = useTranslation()

  return (
    <GuideCarouselChild
      {...props}
      title={t(
        'Learning.Check your email for instructions on how to connect to a third party platform'
      )}
      icon={<GettingStartedEmailIcon />}
    />
  )
}

export const CongratulationsCarouselChild: React.FC<Partial<CarouselHandlersProps>> = (props) => {
  const { t } = useTranslation()

  return (
    <GuideCarouselChild
      {...props}
      title={t('Learning.Congratulations!')}
      description={t('Learning.All Required steps done and you can start using ETD products')}
      icon={<GettingStartedCongratulationsIcon />}
    />
  )
}

const AdditionalInfo = () => {
  const { t } = useTranslation()
  return (
    <div className='is-flex is-justify-content-center is-flex-direction-column mb-5'>
      <p>
        <TextStrong>{t('Tickmill Trader:')}</TextStrong>{' '}
        <Text>{t('User-friendly Mobile App for iOS and Android')}</Text>
      </p>
      <p>
        <TextStrong>{t('MT4MT5')}</TextStrong> <Text>{t('Desktop and Mobile App')}</Text>
      </p>
    </div>
  )
}

interface GuideStepProps extends Partial<CarouselHandlersProps> {
  title?: string
  description?: string
  icon?: React.ReactNode
  button?: React.ReactNode
  content?: React.ReactNode
  className?: string
  onConfirmationModalOpen?(): void
  hasAdditionalInfo?: boolean
}

const GuideCarouselChild: React.FC<PropsWithChildren<GuideStepProps>> = (props) => {
  const {
    title,
    description,
    icon,
    button,
    onConfirmationModalOpen,
    active,
    items,
    isFirstSlide,
    isLastSlide,
    onScrollNext,
    onScrollPrev,
    onScrollActive,
    children,
    className,
    hasAdditionalInfo,
  } = props

  const isMobile = useWindowResize()
  const entity = useSessionEntity()

  const leftArrowHidden = isFirstSlide || isMobile ? 'is-invisible' : ''
  const rightArrowHidden = isLastSlide || isMobile ? 'is-invisible' : ''
  const carouselSlide = props.active

  const isSeychellesOrAsiaEntity = () =>
    entity === TickmillCompaniesEnum.TICKMILL_SC || entity === TickmillCompaniesEnum.TICKMILL_AS

  const { product } = useProductReadContext()

  return (
    <div
      className={classNames(styles.card, {
        [styles.slideCFD]: carouselSlide !== 4 && product === TickmillProductType.CFD,
        [styles.fourthSlideCFD]:
          carouselSlide === 3 && product === TickmillProductType.CFD && isSeychellesOrAsiaEntity(),
        [styles.slideETD]: !isOne(carouselSlide) && product === TickmillProductType.ETD,
        [styles.secondSlideETD]: isOne(carouselSlide) && product === TickmillProductType.ETD,
        [styles.fifthSlideCFD]: carouselSlide === 4 && product === TickmillProductType.CFD,
        [styles.hasMultiplePlatform]: hasAdditionalInfo,
      })}
    >
      <div className='columns is-8 is-flex-grow-1'>
        <div className={classNames(' is-flex-grow-1 column text-align-center', styles.cardWrapper)}>
          <div className={styles.infoBlock}>
            <div className={styles.close}>
              <IconButton onClick={onConfirmationModalOpen}>
                <CloseIcon color='disabled' />
              </IconButton>
            </div>
            <div className={classNames(styles.leftArrow, 'is-clickable')}>
              <PointedLeftArrowIcon onClick={onScrollPrev} className={leftArrowHidden} />
            </div>
            <div className={className || styles.infoContent}>
              <div className={styles.info}>
                <h3>{title}</h3>
                {description && (
                  <TextSmall className='mb-5 text-secondary'>{description}</TextSmall>
                )}
                {hasAdditionalInfo && <AdditionalInfo />}
                {button && <div className='mb-5'>{button}</div>}
                <div>
                  <CarouselDots slides={items} active={active} onActive={onScrollActive} />
                </div>
              </div>
              {children && <div className='mt-4'>{children}</div>}
            </div>
            <div className={classNames(styles.rightArrow, 'is-clickable')}>
              <PointedRightArrowIcon onClick={onScrollNext} className={rightArrowHidden} />
            </div>
          </div>
        </div>
        {icon && (
          <div className='is-flex-grow-1 column'>
            <div className={styles.icon}>{icon}</div>
          </div>
        )}
      </div>
    </div>
  )
}

const GuideCarouselChildCFD: React.FC<PropsWithChildren<GuideStepProps>> = (props) => {
  const {
    title,
    description,
    icon,
    button,
    onConfirmationModalOpen,
    active = 0,
    items,
    onScrollNext,
    onScrollPrev,
    className,
    hasAdditionalInfo,
    isFirstSlide,
    isLastSlide,
    content,
  } = props

  const leftArrowHidden = isFirstSlide ? 'is-invisible' : ''
  const rightArrowHidden = isLastSlide ? 'is-invisible' : ''

  return (
    <div className={classNames(styles.carouselWrapper)}>
      <>
        <div className={styles.carouselHeaderMobile}>
          <div className={styles.carouselPagination}>
            <div className={classNames(styles.leftArrow, 'is-clickable')}>
              <ChevronLeftIcon
                onClick={() => !leftArrowHidden && onScrollPrev?.()}
                color={leftArrowHidden ? 'disabled' : 'text'}
              />
            </div>
            <p>
              {active + 1} <span className={styles.active}>/ {items?.length}</span>
            </p>
            <div className={classNames(styles.rightArrow, 'is-clickable')}>
              <ChevronRightIcon
                onClick={() => !rightArrowHidden && onScrollNext?.()}
                color={rightArrowHidden ? 'disabled' : 'text'}
              />
            </div>
          </div>
          <div className={styles.close}>
            <IconButton onClick={onConfirmationModalOpen}>
              <CloseIcon color='contrastMedium' />
            </IconButton>
          </div>
        </div>
        <div className={classNames(styles.cardWrapper)}>
          <div className={styles.infoBlock}>
            <div className={className || styles.infoContent}>
              <div className={styles.info}>
                <div>
                  <h2>{title}</h2>
                  {description && (
                    <div className={styles.description}>
                      <Text>{description}</Text>
                    </div>
                  )}
                  {hasAdditionalInfo && <AdditionalInfo />}
                  <div className={styles.button}>{button}</div>
                </div>

                <div className={styles.carouselHeaderDesktop}>
                  <div className={styles.carouselPagination}>
                    <div className={classNames(styles.leftArrow, 'is-clickable')}>
                      <ChevronLeftIcon
                        onClick={() => !leftArrowHidden && onScrollPrev?.()}
                        color={leftArrowHidden ? 'disabled' : 'contrastMedium'}
                      />
                    </div>
                    <CarouselNumberDots slides={items} active={active} />
                    <div className={classNames(styles.rightArrow, 'is-clickable')}>
                      <ChevronRightIcon
                        onClick={() => !rightArrowHidden && onScrollNext?.()}
                        color={rightArrowHidden ? 'disabled' : 'contrastMedium'}
                      />
                    </div>
                  </div>
                </div>

                {content && <>{content}</>}
              </div>
            </div>
          </div>
        </div>
        {icon && (
          <>
            <div className={styles.close}>
              <IconButton onClick={onConfirmationModalOpen}>
                <CloseIcon color='contrastMedium' />
              </IconButton>
            </div>
            <div className={styles.icon}>{icon}</div>
          </>
        )}
      </>
    </div>
  )
}
