import { FC } from 'react'

import '../../icons/Icons.module.scss'

export const TMPartnersLogoMobile: FC = () => (
  <svg width='120' height='30' viewBox='0 0 120 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clip-path='url(#clip0_83719_353148)'>
      <path
        d='M53.091 16.4632C54.582 16.4632 55.8183 15.8813 56.7638 14.6813L58.7274 16.6813C57.1638 18.4268 55.3456 19.2995 53.2729 19.2995C51.1638 19.2995 49.4547 18.645 48.0729 17.2995C46.7274 15.9904 46.0365 14.2813 46.0365 12.2813C46.0365 10.245 46.7274 8.57225 48.1092 7.22679C49.491 5.88134 51.2001 5.19043 53.2001 5.19043C55.4547 5.19043 57.3092 6.02679 58.8365 7.73588L56.9456 9.88134C56.0001 8.68134 54.8001 8.09952 53.3456 8.09952C52.2183 8.09952 51.2365 8.46316 50.4001 9.22679C49.6001 9.99043 49.1638 10.9722 49.1638 12.245C49.1638 13.5177 49.5638 14.5359 50.3274 15.2995C51.091 16.0632 52.0001 16.4632 53.091 16.4632Z'
        fill='var(--logo-text-color)'
      />
      <path
        d='M34.3637 19.154V8.13587H38.2182V5.51768H27.4546V8.13587H31.3091V19.154H34.3637Z'
        fill='var(--logo-text-color)'
      />
      <path d='M43.4909 5.51768H40.4364V19.154H43.4909V5.51768Z' fill='var(--logo-text-color)' />
      <path
        d='M64.1819 5.51768H61.1273V19.154H64.1819V15.2995L65.5637 13.7722L69.5637 19.154H73.1273C72.789 18.6764 72.3526 18.0681 71.8183 17.3231L71.8139 17.317C71.3729 16.7022 70.8652 15.9944 70.291 15.1904C69.1658 13.5689 68.3418 12.4292 67.8464 11.744L67.7091 11.554L73.1273 5.51768H69.3455L64.1819 11.1177V5.51768Z'
        fill='var(--logo-text-color)'
      />
      <path
        d='M87.2 10.5722L83.5273 18.0268H81.7091L78.0364 10.5722V19.154H74.9818V5.51768H79.0909L82.6182 13.0086L86.1454 5.51768H90.2545V19.154H87.2V10.5722Z'
        fill='var(--logo-text-color)'
      />
      <path d='M96.6545 5.51768H93.6V19.154H96.6545V5.51768Z' fill='var(--logo-text-color)' />
      <path
        d='M99.9999 19.154V5.51768H103.054V16.4268H108.873V19.154H99.9999Z'
        fill='var(--logo-text-color)'
      />
      <path
        d='M111.091 5.51768V19.154H119.964V16.4268H114.145V5.51768H111.091Z'
        fill='var(--logo-text-color)'
      />
      <path
        d='M5.78182 9.00909C5.56364 9.15455 5.38182 9.48182 5.38182 9.73636H5.45455V14.2455L0 17.3727V7.19091C0 6.53636 0.472727 5.73636 1.01818 5.40909L9.74546 0.245455C10.2909 -0.0818182 11.2 -0.0818182 11.7455 0.245455L20.4727 5.40909C20.5091 5.40909 20.5455 5.44546 20.5818 5.48182L15.0545 8.68182L11.1273 6.39091C10.8727 6.24545 10.5091 6.24545 10.2909 6.39091L5.78182 9.00909Z'
        fill='var(--logo-custom-color)'
      />
      <path
        d='M16.0002 14.9364V10.4273L21.382 7.30005V17.4819C21.382 18.1364 20.9093 18.9364 20.3638 19.2637L11.6365 24.4273C11.0911 24.7546 10.182 24.7546 9.63653 24.4273L0.909262 19.2637C0.872901 19.2637 0.836539 19.2273 0.800178 19.191L6.32744 15.991L10.2547 18.2819C10.5093 18.4273 10.8729 18.4273 11.0911 18.2819L15.6002 15.6637C15.8184 15.5182 16.0002 15.191 16.0002 14.9364Z'
        fill='var(--logo-custom-color)'
      />
      <path
        d='M80.025 24.06C80.68 24.06 81.185 24.215 81.54 24.525C81.9 24.835 82.08 25.27 82.08 25.83C82.08 26.415 81.9 26.87 81.54 27.195C81.18 27.515 80.675 27.675 80.025 27.675H78.72V29.31H78V24.06H80.025ZM79.995 27.0225C80.445 27.0225 80.79 26.925 81.03 26.73C81.27 26.53 81.39 26.2375 81.39 25.8525C81.39 25.4775 81.27 25.195 81.03 25.005C80.79 24.81 80.445 24.7125 79.995 24.7125L78.72 24.72V27.0225H79.995Z'
        fill='var(--logo-text-color)'
      />
      <path
        d='M86.9241 29.31L86.3691 28.035H83.6016L83.0541 29.31H82.2966L84.6291 24.06H85.3791L87.7041 29.31H86.9241ZM83.8866 27.3825H86.0916L84.9816 24.8325L83.8866 27.3825Z'
        fill='var(--logo-text-color)'
      />
      <path
        d='M92.156 29.31L91.1135 27.66C90.9935 27.67 90.896 27.675 90.821 27.675H89.486V29.31H88.766V24.06H90.821C91.486 24.06 91.9985 24.215 92.3585 24.525C92.7235 24.83 92.906 25.265 92.906 25.83C92.906 26.255 92.806 26.6125 92.606 26.9025C92.411 27.1925 92.1285 27.4025 91.7585 27.5325L92.981 29.31H92.156ZM90.821 27.0225C91.271 27.0225 91.616 26.925 91.856 26.73C92.096 26.53 92.216 26.2375 92.216 25.8525C92.216 25.4775 92.096 25.195 91.856 25.005C91.616 24.81 91.271 24.7125 90.821 24.7125H89.486V27.0225H90.821Z'
        fill='var(--logo-text-color)'
      />
      <path
        d='M93.8109 24.06H97.9134V24.72H96.2184V29.31H95.4984V24.72H93.8109V24.06Z'
        fill='var(--logo-text-color)'
      />
      <path
        d='M102.776 24.06H103.489V29.31H102.761L99.7613 25.245V29.31H99.0413V24.06H99.7688L102.776 28.1325V24.06Z'
        fill='var(--logo-text-color)'
      />
      <path
        d='M105.296 24.06H108.941V24.7125H106.016V26.3325H108.633V26.985H106.016V28.6575H109.031V29.31H105.296V24.06Z'
        fill='var(--logo-text-color)'
      />
      <path
        d='M113.886 29.31L112.843 27.66C112.723 27.67 112.626 27.675 112.551 27.675H111.216V29.31H110.496V24.06H112.551C113.216 24.06 113.728 24.215 114.088 24.525C114.453 24.83 114.636 25.265 114.636 25.83C114.636 26.255 114.536 26.6125 114.336 26.9025C114.141 27.1925 113.858 27.4025 113.488 27.5325L114.711 29.31H113.886ZM112.551 27.0225C113.001 27.0225 113.346 26.925 113.586 26.73C113.826 26.53 113.946 26.2375 113.946 25.8525C113.946 25.4775 113.826 25.195 113.586 25.005C113.346 24.81 113.001 24.7125 112.551 24.7125H111.216V27.0225H112.551Z'
        fill='var(--logo-text-color)'
      />
      <path
        d='M119.283 25.155C119.038 25 118.783 24.885 118.518 24.81C118.258 24.73 118.018 24.69 117.798 24.69C117.493 24.69 117.248 24.75 117.063 24.87C116.883 24.985 116.793 25.145 116.793 25.35C116.793 25.535 116.848 25.6875 116.958 25.8075C117.068 25.9225 117.203 26.015 117.363 26.085C117.523 26.15 117.746 26.2275 118.031 26.3175C118.386 26.4275 118.673 26.5325 118.893 26.6325C119.113 26.7325 119.301 26.8825 119.456 27.0825C119.611 27.2775 119.688 27.5375 119.688 27.8625C119.688 28.1625 119.606 28.425 119.441 28.65C119.281 28.875 119.053 29.05 118.758 29.175C118.468 29.295 118.133 29.355 117.753 29.355C117.368 29.355 116.993 29.28 116.628 29.13C116.263 28.98 115.948 28.78 115.683 28.53L116.006 27.9075C116.256 28.1525 116.536 28.3425 116.846 28.4775C117.161 28.6075 117.466 28.6725 117.761 28.6725C118.111 28.6725 118.386 28.605 118.586 28.47C118.791 28.33 118.893 28.1425 118.893 27.9075C118.893 27.7225 118.838 27.57 118.728 27.45C118.618 27.33 118.478 27.235 118.308 27.165C118.143 27.095 117.918 27.0175 117.633 26.9325C117.283 26.8275 116.998 26.725 116.778 26.625C116.558 26.525 116.371 26.3775 116.216 26.1825C116.066 25.9875 115.991 25.73 115.991 25.41C115.991 25.13 116.068 24.8825 116.223 24.6675C116.378 24.4525 116.596 24.2875 116.876 24.1725C117.156 24.0575 117.481 24 117.851 24C118.166 24 118.473 24.0475 118.773 24.1425C119.078 24.2325 119.348 24.355 119.583 24.51L119.283 25.155Z'
        fill='var(--logo-text-color)'
      />
    </g>
    <defs>
      <clipPath id='clip0_83719_353148'>
        <rect width='120' height='30' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
