import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Loading } from '../../global/Loading/Loading'
import { Button } from '../../global/button/Button'
import { PendingIcon } from '../../icons/PendingIcon'
import { useApiClient } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { ClientApiClient } from '../../utils/clientApi'
import { getCompanyFullName } from '../../utils/companyName.utils'
import { useFetchOne } from '../../utils/useFetch'

import styles from './DocumentVerification.module.scss'

export const DocumentVerificationPage: React.FunctionComponent = () => {
  const apiClient = useApiClient(ClientApiClient)

  const callback = useCallback(
    () => apiClient.getRequiredDocuments(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const { data: documents = [], isLoading } = useFetchOne(callback)

  return (
    <Loading isLoading={isLoading} showLoadingIcon>
      <div className={styles.box}>
        {!documents.length ? <VerificationPending /> : <VerificationProgress />}
      </div>
    </Loading>
  )
}

const VerificationPending = () => {
  const { t } = useTranslation()

  return (
    <div className='columns'>
      <div className='column'>
        <div className={styles.boxLeft}>
          <div className='pt-5 pb-5'>
            <div className='pb-5 text-align-center'>
              <h3>{t('Profile.Verification pending')}</h3>
            </div>

            <div className='pb-5 text-align-center'>
              <p className='is-text'>
                {t(
                  "Profile.Please wait for Tickmill's approval before you can start trading. It takes max 1 workday after you submitted documents."
                )}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='column'>
        <div className={styles.boxRight}>
          <PendingIcon />
        </div>
      </div>
    </div>
  )
}

const VerificationProgress = () => {
  const navigate = useNavigate()

  const [session] = useContext(AuthSessionContext)

  const { t } = useTranslation()

  const handleUploadDocuments = () => {
    navigate('/profile/document-management')
  }

  return (
    <div className='columns'>
      <div className='column'>
        <div className={styles.boxLeft}>
          <div className='pt-5 pb-5'>
            <div className='pb-5 text-align-center'>
              <h3>{t('Profile.Verification in progress')}</h3>
            </div>

            <div className='pb-5 text-align-center'>
              <p className='is-text'>
                {t('Profile.Thank you for registering with')}{' '}
                {getCompanyFullName(session?.tickmillCompany.id)}.
              </p>
              <p className='is-text'>
                {t(
                  'Profile.Please allow us some time to verify your documents. You will be notified by email as soon as the verification process is complete.'
                )}
              </p>
            </div>

            <div className='text-align-center'>
              <Button appearance='primary' size='S' onClick={handleUploadDocuments}>
                {t('Profile.Upload Documents')}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className='column'>
        <div className={styles.boxRight}>
          <PendingIcon />
        </div>
      </div>
    </div>
  )
}
