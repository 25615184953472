import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { CloseIcon } from '../../icons/CloseIcon'
import { NotificationIcon } from '../../icons/NotificationIcon'
import { SuccessIcon } from '../../icons/SuccessIcon'
import { WarningIcon } from '../../icons/WarningIcon'
import {
  detectHideGeneralNotificationsClientSide,
  detectHidePlatformNotificationsClientSide,
  detectHidePromoNotificationsClientSide,
} from '../../model/Notification'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import IconButton from '../iconButton/IconButton'

import styles from './notification.module.scss'

type NotificationVariantType = 'default' | 'warning' | 'danger' | 'success'

export interface NotificationProps {
  title?: string | React.ReactNode
  subtitle?: string | React.ReactNode
  dangerouslySetInnerHTML?: { __html: string }
  variant?: NotificationVariantType
  onClick?(): void
  onCancel?(): void
  wrapperClassName?: string
}

export const Notification: React.FC<NotificationProps> = (props) => {
  const { title, subtitle, variant = 'default', onClick, onCancel } = props
  const { dangerouslySetInnerHTML, wrapperClassName } = props

  return (
    <div
      className={classNames('notification', styles.notification, wrapperClassName, {
        ['is-' + variant]: true,
        'has-cursor-pointer': !!onClick,
      })}
      onClick={onClick}
    >
      <div className={styles.notificationContent}>
        {title && (
          <div className={styles.mainContent}>
            <IconFactory variant={variant} />
            <p className={styles.title}>{title}</p>
          </div>
        )}
        <div className={styles.mainContent}>
          {!title && <IconFactory variant={variant} />}
          {(subtitle || dangerouslySetInnerHTML) && (
            <p className={classNames(styles.subTitle, { [styles.extraMargin]: title })}>
              {isString(subtitle) ? <p>{subtitle}</p> : subtitle}
              {dangerouslySetInnerHTML && (
                <span
                  className={styles.innerHtml}
                  dangerouslySetInnerHTML={dangerouslySetInnerHTML}
                />
              )}
            </p>
          )}
        </div>
      </div>
      {onCancel && (
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      )}
    </div>
  )
}

const isString = (value: string | React.ReactNode | undefined) => {
  return typeof value === 'string'
}

export const NotificationWarning: React.FC<Omit<NotificationProps, 'variant'>> = (props) => {
  return <Notification {...props} variant={'warning'} />
}

export const IBDocNotification: React.FC<{ active?: boolean }> = (props) => {
  const { t } = useTranslation()
  const { account } = useAccountReadContext()
  const [isIB, setIsIB] = useState(false)
  useEffect(() => {
    setIsIB(!!account?.clientIntroducingBroker?.id)
  }, [account])
  const showNotification = isIB && props.active

  if (!showNotification) return null
  return (
    <Notification
      title={t('IB.Updated Introducing Broker Agreement')}
      subtitle={t('IB.We are updating our Introducing Broker Agreement')}
    />
  )
}

export const NotificationDanger: React.FC<Omit<NotificationProps, 'variant'>> = (props) => {
  return <Notification {...props} variant={'danger'} />
}

export const NotificationSuccess: React.FC<Omit<NotificationProps, 'variant'>> = (props) => {
  return <Notification {...props} variant={'success'} />
}

interface IconFactoryProps {
  variant: NotificationVariantType
}

const IconFactory: React.FC<IconFactoryProps> = (props) => {
  const { variant } = props

  if (variant === 'success') {
    return <SuccessIcon size={12} />
  }
  if (variant === 'warning') {
    return <WarningIcon color='warning' />
  }
  if (variant === 'danger') {
    return <WarningIcon color='error' />
  }

  return <NotificationIcon />
}

export interface NotificationsContextInterface {
  hidePlatformNotifications: boolean
  hideGeneralNotifications: boolean
  hidePromoNotifications: boolean
}

export function initiateNotificationsContext(): NotificationsContextInterface {
  return {
    hidePlatformNotifications: detectHidePlatformNotificationsClientSide(),
    hideGeneralNotifications: detectHideGeneralNotificationsClientSide(),
    hidePromoNotifications: detectHidePromoNotificationsClientSide(),
  }
}
