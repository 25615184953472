import React from 'react'
import { useTranslation } from 'react-i18next'

import { Modal } from '../../../global/modal/Modal'
import { RadioButton } from '../../../global/radioButton/RadioButton'
import { ClientNciPriorityDto } from '../../../model/ClientNciPriorityDto'

import styles from './DocumentChooseAnotherModal.module.scss'

interface DocumentChooseAnotherModalProps {
  onConfirm(doc: ClientNciPriorityDto, count: number): void
  onCancel(): void
  documentOptions: ClientNciPriorityDto[]
}

export const DocumentChooseAnotherModal: React.FC<DocumentChooseAnotherModalProps> = ({
  onConfirm,
  onCancel,
  documentOptions,
}) => {
  const { t } = useTranslation()
  const [selected, setSelected] = React.useState<string>(documentOptions[0].id)

  const handleRollBackDocument = () => {
    if (!selected) return
    const doc = documentOptions.find((doc) => doc.id === selected)
    if (!doc) return
    onConfirm(doc, documentOptions.indexOf(doc))
  }

  return (
    <Modal
      closeModal={onCancel}
      cardClassName={styles.modal}
      render={() => (
        <React.Fragment>
          <header className='modal-card-head'>
            <p className='modal-card-title'>{t('Sign up.Document Type')}</p>
          </header>
          <div className={styles.body}>
            {documentOptions.map((doc) => (
              <div className={styles.option}>
                <RadioButton
                  label={doc.nciRule.shortName || ''}
                  key={`documentTypeId${doc.id}`}
                  onClick={() => setSelected(doc.id)}
                  checked={doc.id === selected}
                />
              </div>
            ))}
          </div>
          <footer className='modal-card-foot'>
            <button className='button' onClick={onCancel} type='button'>
              {t('Cancel')}
            </button>
            <button className='button' type='button' onClick={handleRollBackDocument}>
              {t('Submit')}
            </button>
          </footer>
        </React.Fragment>
      )}
    />
  )
}
