import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { WalletDepositFormValues } from '../../Traders-Room/Wallets/WalletDeposit/WalletDepositForm/WalletDepositForm'
import { TransferIcon } from '../../icons/TransferIcon'
import { Button } from '../button/Button'

import styles from './ProceedModal.module.scss'

interface ProceedModalProps {
  title?: string
  addition?: string
  onProceed(): void
  onCancel?(): void
}

export const ProceedModal: React.FunctionComponent<PropsWithChildren<ProceedModalProps>> = ({
  title,
  onProceed,
  addition,
}) => {
  const { values } = useFormikContext<WalletDepositFormValues>()
  const { t } = useTranslation()

  return (
    <section className={styles.body}>
      <div className={styles.content}>
        <TransferIcon size={80} strokeWidth={0.65} color='textSecondary' />
        {values.paymentProvider.name === 'Crypto Currency' ? (
          <h4>
            <strong>{addition}</strong>
          </h4>
        ) : null}
        <h4>
          <strong>{title}</strong>
        </h4>
        <Button appearance='secondary' fullWidth size='L' type='submit' onClick={onProceed}>
          {t('Proceed')}
        </Button>
      </div>
    </section>
  )
}
