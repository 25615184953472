import React, { PropsWithChildren, useState } from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'

import styles from './Bubble.module.scss'

export type TooltipDirection =
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'topRight'
  | 'topLeft'
  | 'bottomRight'
  | 'bottomLeft'

const variants = {
  open: {
    opacity: 1,
    scale: 1,
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.5,
    },
  },
  closed: {
    opacity: 0,
    scale: 0.95,
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.25,
    },
  },
}

export interface BubbleProps extends PropsWithChildren {
  component?: React.ReactNode
  direction?: TooltipDirection
  disabled?: boolean
}

export const Bubble: React.FC<BubbleProps> = (props) => {
  const { component, direction = 'top', children, disabled } = props

  const [active, setActive] = useState(false)

  const showTip = () => !disabled && setActive(true)
  const hideTip = () => !disabled && setActive(false)

  return (
    <div onMouseEnter={showTip} onMouseLeave={hideTip} className={styles.bubbleWrapper}>
      {component}
      <motion.div
        animate={active ? 'open' : 'closed'}
        initial='closed'
        variants={variants}
        className={classNames(styles.bubble, styles[direction], { [styles.active]: active })}
      >
        {children}
      </motion.div>
    </div>
  )
}
