import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Loading } from '../../../global/Loading/Loading'
import { Button } from '../../../global/button/Button'
import { SelectableModal } from '../../../global/field/SelectableModal'
import IconButton from '../../../global/iconButton/IconButton'
import { Modal } from '../../../global/modal/Modal'
import { Table } from '../../../global/table/Table'
import { DotsIcon } from '../../../icons/DotsIcon'
import { EditIcon } from '../../../icons/EditIcon'
import {
  AssignedCommissionPlan,
  PlanValues,
  UpdateCommissionPlan,
  customOrder,
} from '../../../model/MultiTierDto'
import { PageHeader } from '../../../ui/Table/Header/PageHeader'
import { useAccountReadContext } from '../../../utils/AccountContextContext'
import { useApiClient } from '../../../utils/ApiClient'
import { commissionTiers } from '../../../utils/MultiTier'
import { ClientApiClient } from '../../../utils/clientApi'
import { useWindowResize } from '../../../utils/domUtils'
import { useCallbackWithForceRefresh } from '../../../utils/useCallbackWithForceRefresh'
import { useFetchOne } from '../../../utils/useFetch'
import { MultiTierCommisionCard } from '../MultiTierCards/MultiTierCommisionCard'
import { AddEditNewPlanModal } from '../MultiTierModals/AddEditNewPlanModal'
import { CommissionTableInfoModal } from '../MultiTierModals/CommissionTableInfoModal'

import styles from './MultiTierParts.module.scss'

export const MultiTierCommision: FC = () => {
  const { t } = useTranslation()
  const { account } = useAccountReadContext()
  const [infoModal, setInfoModal] = useState(false)
  const [actionsModal, setActionsModal] = useState<AssignedCommissionPlan>()
  const [addNewPlanModal, setAddNewPlanModal] = useState(false)
  const [editPlanModal, setEditPlanModal] = useState<AssignedCommissionPlan>()
  const apiClient = useApiClient(ClientApiClient)
  const isMobile = useWindowResize()
  const introducingBrokerId = account?.clientIntroducingBroker?.id || ''

  const { callback, forceRefresh: refreshCommissionPlans } =
    useCallbackWithForceRefresh(async () => {
      const data = await apiClient.getIBCommissionPlans(introducingBrokerId)
      const summary = await apiClient.getIBCommissionPlansSummary(introducingBrokerId)
      const sortedData = data.map((item) => {
        return {
          ...item,
          commissionPlans: item.commissionPlans.sort((a, b) => {
            return (
              customOrder.indexOf(a.tradingAccountType.name) -
              customOrder.indexOf(b.tradingAccountType.name)
            )
          }),
        }
      })

      return {
        summary: summary,
        data: sortedData,
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [introducingBrokerId])

  const { data: commissionPlans, isLoading } = useFetchOne(callback)

  const handleCommissionPlan = async (
    commissionPlans: UpdateCommissionPlan[],
    commissionPlanId: string
  ) => {
    const introducingBrokerId = account?.clientIntroducingBroker?.id || ''
    if (commissionPlanId) {
      await apiClient.updateCommissionPlan(introducingBrokerId, commissionPlanId, commissionPlans)
      setEditPlanModal(undefined)
    } else {
      await apiClient.addCommissionPlan(introducingBrokerId, commissionPlans)
      setAddNewPlanModal(false)
    }
    refreshCommissionPlans()
  }

  return (
    <div>
      {addNewPlanModal && (
        <AddEditNewPlanModal
          commissionPlan={editPlanModal}
          summary={commissionTiers(commissionPlans?.summary.commissionPlans)}
          onCancel={() => setAddNewPlanModal(false)}
          onSubmit={handleCommissionPlan}
          commissionPlans={commissionPlans?.summary.commissionPlans ?? []}
        />
      )}
      {editPlanModal && (
        <AddEditNewPlanModal
          commissionPlan={editPlanModal}
          summary={commissionTiers(commissionPlans?.summary.commissionPlans)}
          onCancel={() => setEditPlanModal(undefined)}
          onSubmit={handleCommissionPlan}
          commissionPlans={commissionPlans?.summary.commissionPlans ?? []}
        />
      )}
      {!!actionsModal && (
        <Modal
          closeModal={() => setActionsModal(undefined)}
          render={() => (
            <SelectableModal
              title={t('Actions')}
              onCancel={() => setActionsModal(undefined)}
              renderOptions={() => (
                <>
                  <button
                    className={styles.editButton}
                    onClick={() => {
                      setEditPlanModal(actionsModal)
                      setActionsModal(undefined)
                    }}
                  >
                    <EditIcon />
                    Edit
                  </button>
                </>
              )}
            />
          )}
        />
      )}
      {infoModal && (
        <CommissionTableInfoModal
          setInfoModal={setInfoModal}
          availablePlans={commissionPlans?.summary.commissionPlans.map(
            (plan) => plan.tradingAccountType.name as keyof PlanValues
          )}
        />
      )}
      <PageHeader
        renderSubtitle={() => (
          <div className={styles.subtitle}>
            {commissionPlans?.summary.commissionPlans.map((plan) => (
              <span>
                <span className={styles.greyText}>{plan.tradingAccountType.name}: </span>
                {plan.amount}
              </span>
            ))}
          </div>
        )}
        actions={[
          {
            onClick: () => setAddNewPlanModal(true),
            label: `+ ${t('IB.MultiTier.Add new Plan')}`,
            hidden: isMobile,
            disabled: (commissionPlans?.data.filter((group) => !group.isDefault).length || 0) >= 3,
          },
        ]}
        title={t('IB.MultiTier.IB Commission Plans')}
        titleInfoToggle={() => setInfoModal(true)}
      />
      <Loading showLoadingIcon isLoading={isLoading}>
        {isMobile && (
          <>
            {commissionPlans?.data.map((x) => (
              <MultiTierCommisionCard key={x.id} data={x} setActionsModal={setActionsModal} />
            ))}
            <Button
              onClick={() => setAddNewPlanModal(true)}
              appearance='secondary'
              size='M'
              className={styles.mobileAddButton}
              disabled={
                (commissionPlans?.data.filter((group) => !group.isDefault).length || 0) >= 3
              }
            >{`+ ${t('IB.MultiTier.Add new IB Commission Plan')}`}</Button>
          </>
        )}

        <Table>
          <thead>
            <tr>
              <th>{t('IB.MultiTier.IB Commission Plans')}</th>
              {commissionPlans?.summary.commissionPlans.map((plan) => (
                <th align='center'>{plan.tradingAccountType.name}</th>
              ))}
              <th />
            </tr>
          </thead>
          <tbody>
            {commissionPlans?.data.map((x) => {
              return (
                <tr key={x.id}>
                  <td>{x.name}</td>
                  {commissionPlans?.summary.commissionPlans.map((availablePlan) => {
                    return (
                      <td width={100} align='center'>
                        {x.commissionPlans.map((currentPlan) =>
                          currentPlan.tradingAccountType.name ===
                          availablePlan.tradingAccountType.name
                            ? currentPlan.amount
                            : ''
                        )}
                      </td>
                    )
                  })}
                  <td>
                    {!x.isDefault && (
                      <div className='has-text-right pr-2 is-flex'>
                        <IconButton onClick={() => setActionsModal(x)}>
                          <DotsIcon />
                        </IconButton>
                      </div>
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </Loading>
    </div>
  )
}
