import React, { Fragment, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Modal } from '../../global/modal/Modal'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { SubscriptionHistoryItem, showSubLevel } from '../../model/SubscriptionsDto'
import { AuthSessionContext } from '../../utils/AuthContext'
import { formatDate } from '../../utils/date.utils'
import { SubscriptionBadgeStatus } from './SubscriptionBadgeStatus'
import { SubscriptionStatusHistoryModal } from './SubscriptionStatusHistoryModal'

import styles from './SubscriptionsHistoryCard.module.scss'

interface Props {
  data: SubscriptionHistoryItem[]
}

export const SubscriptionsHistoryCard: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()

  const [expandedRow, setExpandedRow] = useState('')
  const [historyStatusModal, setHistoryStatusModal] = useState<boolean>(false)
  const [selectedSubscription, setSelectedSubscription] = useState('')
  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name

  const handleSetExpandedRow = (id: string) => {
    if (id === expandedRow) {
      id = ''
    }
    setExpandedRow(id)
  }

  return (
    <React.Fragment>
      {data.map((x) => (
        <>
          {historyStatusModal && (
            <Modal
              closeModal={() => setHistoryStatusModal(false)}
              render={({ closeModal }) => (
                <SubscriptionStatusHistoryModal id={selectedSubscription} onCancel={closeModal} />
              )}
            />
          )}
          <div className={styles.card}>
            <div className={styles.title} onClick={() => handleSetExpandedRow(x.id)}>
              <span className={styles.value}>{x.subscription.name}</span>
              <span className={classNames(styles.textSmall, styles.value)}>
                {formatMoney(x.amount)}
              </span>
            </div>
            <div className={styles.title}>
              <span className={classNames(styles.textSmall, styles.label, 'is-align-items-center')}>
                {formatDate(x.createdDate, dateFormat)}
              </span>
              <div className='has-cursor-pointer' onClick={() => setSelectedSubscription(x.id)}>
                <span onClick={() => setHistoryStatusModal(true)}>
                  <SubscriptionBadgeStatus {...x.state} />
                </span>
              </div>
            </div>
            {expandedRow === x.id && (
              <Fragment>
                <div className={styles.subtitle}>
                  <span className={styles.label}>{t('Subscriptions.Request Type')}</span>
                  <span className={styles.label}>{x.type.name}</span>
                </div>
                <div className={styles.subtitle}>
                  <span className={styles.label}>{t('Subscriptions.Product Group')}</span>
                  <div className={classNames('is-flex is-flex-direction-column', styles.address)}>
                    <span className={styles.label}>{x.subscriptionGroupType.name}</span>
                  </div>
                </div>
                {showSubLevel(x.subscriptionGroupType.id, x.subscriptionDetailType.id) && (
                  <div className={styles.subtitle}>
                    <span className={styles.label}>{t('Subscriptions.Level')}</span>
                    <span className={styles.label}>{x.subscriptionDetailType.id}</span>
                  </div>
                )}
              </Fragment>
            )}
          </div>
        </>
      ))}
    </React.Fragment>
  )
}
