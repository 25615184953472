import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import {
  overrideForZHToCNLanguage,
  useSessionLanguage,
} from '../../global/context/SessionSettingsContext'
import { Modal } from '../../global/modal/Modal'
import { VideoSelectorModal } from '../../global/modal/VideoSelectorModal'
import { ChevronDownIcon } from '../../icons/ChevronDownIcon'
import placeholder from '../../images/placeholder.svg'
import { TutorialGroupEnum, TutorialVideosDto } from '../../model/TutorialVideosDto'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useWindowResize } from '../../utils/domUtils'
import { useFetchOne } from '../../utils/useFetch'

import styles from './TutorialVideos.module.scss'

export interface VideoItem {
  id: string
  title: string
  image?: string
  video: string
  duration?: string
}

function transformData(videosDtos: TutorialVideosDto[]) {
  return videosDtos.reduce<Record<TutorialGroupEnum, VideoItem[]>>(
    (acc, video) => {
      const { tutorialGroup, tutorials } = video
      const id = tutorialGroup.id
      const videos: VideoItem[] = (tutorials || []).map((t, idx) => ({
        id: `${id}-${idx}`,
        title: t.title,
        video: t.tutorialLink,
      }))
      return { ...acc, [id]: videos }
    },
    {
      [TutorialGroupEnum.introducingToFutures]: [],
      [TutorialGroupEnum.understandingFutures]: [],
      [TutorialGroupEnum.keyEconomic]: [],
      [TutorialGroupEnum.exchangeInstrument]: [],
    }
  )
}

export const TutorialVideosPage: React.FC = () => {
  const { t } = useTranslation()
  const isMobile = useWindowResize()
  const locale = useSessionLanguage()
  const languageId = overrideForZHToCNLanguage(locale)
  const videoRef = useRef<HTMLDivElement>(null)

  const [selectedVideo, setSelectedVideo] = useState<VideoItem | undefined>()
  const [videoType, setVideoType] = useState<TutorialGroupEnum | undefined>()

  const [modal, setModal] = useState(false)

  const apiClient = useApiClient(ClientApiClient)

  const getTutorialVideosCallback = useCallback(async () => {
    const data = await apiClient.getTutorialVideos(languageId)
    return transformData(data)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageId])

  const { data: getTutorialVideosData } = useFetchOne(getTutorialVideosCallback)

  const handleSetSelectedVideo = (video: VideoItem, videoType: TutorialGroupEnum) => {
    setVideoType(videoType)
    setSelectedVideo(video)
  }

  const getVideoTypeTitle = () => {
    switch (videoType) {
      case TutorialGroupEnum.exchangeInstrument:
        return t('Learning.The Small Exchange Instruments')
      case TutorialGroupEnum.introducingToFutures:
        return t('Learning.Introduction to Futures')
      case TutorialGroupEnum.keyEconomic:
        return t('Learning.Key Economic Events')
      default:
        return t('Learning.Understanding the Benefits of Futures')
    }
  }

  const getSelectedTutorialVideoId = () => {
    if (selectedVideo?.id.startsWith('1')) return TutorialGroupEnum.introducingToFutures
    if (selectedVideo?.id.startsWith('2')) return TutorialGroupEnum.understandingFutures
    if (selectedVideo?.id.startsWith('3')) return TutorialGroupEnum.keyEconomic
    if (selectedVideo?.id.startsWith('5')) return TutorialGroupEnum.exchangeInstrument
    else return TutorialGroupEnum.introducingToFutures
  }

  const selectedTutorialVideoId = getSelectedTutorialVideoId()

  useEffect(() => {
    if (videoRef.current) {
      const elementRect = videoRef.current.getBoundingClientRect()
      const absoluteElementTop = elementRect.top + window.scrollY
      if (isMobile) {
        window.scrollTo({
          top: absoluteElementTop - 80,
          behavior: 'smooth',
        })
      } else {
        videoRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [selectedVideo?.id, isMobile])

  return (
    <>
      {modal && (
        <Modal
          closeModal={() => setModal(false)}
          render={() => {
            return (
              <VideoSelectorModal
                onCancel={() => setModal(false)}
                items={getTutorialVideosData?.[selectedTutorialVideoId] || []}
                onSelect={(item) => setSelectedVideo(item)}
                selectedItem={selectedVideo}
              />
            )
          }}
        />
      )}
      {!selectedVideo && (
        <>
          <PageHeader title={t('Learning.Tutorial Videos')} />
          {Object.keys(getTutorialVideosData || {}).map((key) => {
            const currentKey: TutorialGroupEnum = Number(key)
            return (
              <div key={currentKey} className='container mt-6'>
                <h3 className='mb-4'>
                  {currentKey === TutorialGroupEnum.introducingToFutures &&
                    t('Learning.Introduction to Futures')}
                  {currentKey === TutorialGroupEnum.keyEconomic &&
                    t('Learning.Key Economic Events')}
                  {currentKey === TutorialGroupEnum.exchangeInstrument &&
                    t('Learning.The Small Exchange Instruments')}
                  {currentKey === TutorialGroupEnum.understandingFutures &&
                    t('Learning.Understanding the Benefits of Futures')}
                </h3>
                <p className={classNames('text', styles.textSecondary)}>
                  {currentKey === TutorialGroupEnum.introducingToFutures &&
                    t(
                      'Learning.Going from the basics to complex trading techniques, our educational videos equip you to enhance your trading'
                    )}
                  {currentKey === TutorialGroupEnum.understandingFutures &&
                    t('Learning.Why do people trade Futures')}
                  {currentKey === TutorialGroupEnum.keyEconomic &&
                    t(
                      'Learning.Learn about the key drivers of the markets with our in depth video coverage'
                    )}
                </p>
                <div className='columns is-multiline is-flex  is-variable is-2'>
                  {getTutorialVideosData?.[currentKey].map((video, idx) => (
                    <div
                      key={idx}
                      className='column is-flex is-half-mobile is-one-quarter-desktop is-one-quarter-tablet is-clickable'
                      onClick={() => handleSetSelectedVideo(video, currentKey)}
                    >
                      <div className={classNames(styles.card, 'is-clickable')}>
                        <img src={placeholder} alt='alt' />
                        <p className={classNames(styles.textStrong, 'mt-3 mb-4')}>{video.title}</p>
                        <p className={classNames(styles.textSmall, styles.textSecondary)}>
                          {video.duration}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )
          })}
        </>
      )}
      {selectedVideo && !isMobile && (
        <div ref={videoRef}>
          <PageHeader
            title={
              <>
                <span className={styles.textSecondary}>{getVideoTypeTitle()} / </span>
                <span>{selectedVideo.title}</span>
              </>
            }
            backButton={() => setSelectedVideo(undefined)}
          />

          <div className={classNames('mt-3', styles.card)}>
            <div className='columns'>
              <div className='column is-3 mt-5'>
                {getTutorialVideosData?.[selectedTutorialVideoId].map((video) => {
                  return (
                    <div
                      onClick={() => setSelectedVideo(video)}
                      key={video.id}
                      className={classNames(styles.itemWrapper, 'mt-3 is-clickable', {
                        [styles.selected]: video.id === selectedVideo.id,
                      })}
                    >
                      <div className={classNames('is-flex  is-justify-content-space-between')}>
                        <div className={styles.textSmall}>{video.title}</div>
                        <div
                          className={classNames(
                            styles.textSmall,
                            styles.textSecondary,
                            'has-text-right'
                          )}
                        >
                          {video.duration}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className='column is-9'>
                <h3 className='mb-3'>{selectedVideo.title}</h3>
                <div className={styles.videoContainer}>
                  <div className={styles.videoWrapper}>
                    <iframe
                      title={selectedVideo.title}
                      allow='autoplay'
                      allowFullScreen
                      width='100%'
                      height='100%'
                      src={selectedVideo.video}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {selectedVideo && isMobile && (
        <div ref={videoRef}>
          <PageHeader
            title={
              <>
                <span className={styles.textSecondary}>{'.../ '}</span>
                <span>{selectedVideo.title}</span>
              </>
            }
            backButton={() => setSelectedVideo(undefined)}
          />
          <div className={styles.card}>
            {
              <div className='is-flex is-justify-content-space-between mt-3 is-clickable'>
                <h3>{selectedVideo.title}</h3>
                <div className='is-flex'>
                  <div
                    className={classNames(
                      styles.textSmall,
                      styles.textSecondary,
                      'has-text-right mr-2'
                    )}
                  >
                    {selectedVideo.duration}
                  </div>
                  <span className='is-clickable' onClick={() => setModal(true)}>
                    <ChevronDownIcon />
                  </span>
                </div>
              </div>
            }

            <div className={styles.videoContainer}>
              <div className={styles.videoWrapper}>
                <iframe
                  title={selectedVideo.title}
                  allow='autoplay'
                  allowFullScreen
                  width='100%'
                  height='100%'
                  src={selectedVideo.video}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
