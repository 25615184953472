import React from 'react'
import { useTranslation } from 'react-i18next'

import { InfoModal } from '../../global/modal/InfoModal'
import { Modal } from '../../global/modal/Modal'

interface DirectorInfoModalProps {
  closeModal(): void
}

export const DirectorInfoModal: React.FC<DirectorInfoModalProps> = (props) => {
  const { closeModal } = props

  const { t } = useTranslation()

  return (
    <Modal
      closeModal={closeModal}
      render={({ closeModal }) => (
        <InfoModal
          onCancel={closeModal}
          title={t('Sign up.Director')}
          renderBody={() => (
            <section className='modal-card-body'>
              <p
                dangerouslySetInnerHTML={{
                  __html: t(
                    'Sign up.Any Shareholder who owns more than 25% of the shares must provide their personal information below (for two active directors, or one director for sole directors)'
                  ),
                }}
              />
            </section>
          )}
          renderFooter={() => (
            <button className='button' onClick={closeModal} type='button'>
              <b>{t('Got It')}</b>
            </button>
          )}
          onConfirm={closeModal}
        />
      )}
    />
  )
}
