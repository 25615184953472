import React from 'react'
import { useTranslation } from 'react-i18next'
import { isFuture, isPast } from 'date-fns'

import { Badge } from '../../global/badge/Badge'
import { CampaignStatusEnum } from '../../model/CampaignStatusEnum'
import { DocumentStatus } from '../../model/DocumentStatus'
import { NameDto } from '../../model/NameDto'
import { isZero } from '../../utils/validations'

export function calculateTotalRebate(totalRebate: number, rebateCapPerClient: number) {
  if (isZero(totalRebate)) return totalRebate
  return `${totalRebate}/${rebateCapPerClient}`
}

interface CampaignStatusProps {
  status: NameDto & { type?: DocumentStatus }
  from: string
  to: string
}

export const CampaignStatus: React.FC<CampaignStatusProps> = (props) => {
  const { t } = useTranslation()

  if (isCampaignEnded(props)) {
    return <Badge text={t('Ended')} appearance='completed' />
  }

  if (isCampaignActive(props)) {
    return <Badge text={t('Active')} appearance='active' />
  }

  return <Badge text={t('Paused')} appearance='cancelled' />
}

const isCampaignEnded = (props: CampaignStatusProps) => {
  const { status, to } = props
  const { id, type = DocumentStatus.None } = status
  const statusType = id | type

  return isPast(new Date(to)) || statusType === CampaignStatusEnum.Disabled
}

const isCampaignActive = (props: CampaignStatusProps) => {
  const { status, from, to } = props
  const { id, type = DocumentStatus.None } = status
  const statusType = id | type

  return (
    isPast(new Date(from)) && isFuture(new Date(to)) && statusType === CampaignStatusEnum.Enabled
  )
}
