import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'
import { Field, FieldProps, getIn, useFormikContext } from 'formik'
import { FieldConfig as FormikFieldConfig } from 'formik/dist/Field'

import { RadioGroup } from '../radioButton/RadioGroup'
import { DeepNonNullable } from '../types/DeepNonNullable'
import { DeepRequired } from '../types/DeepRequired'
import { TypedFieldProps } from '../types/TypedFieldProps'
import { ValidateField } from '../types/ValidateField'
import { getFieldValid } from './helpers'

interface RadioGroupProps extends Omit<FormikFieldConfig, 'name'> {
  onChange?(value: React.ChangeEvent<HTMLDivElement>): void
  children: React.ReactNode
}

export function RawRadioGroupField<FormValues>(
  props: RadioGroupProps & TypedFieldProps<FormValues>
): JSX.Element {
  const { namePrefix = '', name = '', value, className, onChange, children, ...inputProps } = props

  const fieldRef = useRef<HTMLDivElement>(null)
  const context = useFormikContext<FormValues>()

  const error = getIn(context.errors, name)
  const touched = getIn(context.touched, name)
  const isValid = getFieldValid(error, touched)

  useEffect(() => {
    context.setFieldValue(name, value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleRadio = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event)
    } else {
      await context.setFieldValue(name, event.target.value)
      await context.setFieldTouched(name)
    }
  }

  return (
    <div className={classNames('field')} ref={fieldRef}>
      <div className={classNames('control')}>
        <Field name={namePrefix + name} {...inputProps}>
          {(fieldProps: FieldProps<string>) => {
            return (
              <RadioGroup
                value={fieldProps.field.value}
                error={!isValid ? error : undefined}
                onChange={handleRadio}
                {...inputProps}
              >
                {children}
              </RadioGroup>
            )
          }}
        </Field>
      </div>
    </div>
  )
}

export function createFormRadioField<FormValues>() {
  return function TypedField<Name extends ValidateField<DeepNonNullable<DeepRequired<FormValues>>>>(
    props: RadioGroupProps & TypedFieldProps<DeepNonNullable<DeepRequired<FormValues>>, Name>
  ): JSX.Element {
    return <RawRadioGroupField {...props} />
  }
}
