import { useState } from 'react'
import { Link, LinkProps } from 'react-router-dom'

import IconButton from '../../../global/iconButton/IconButton'
import { Modal } from '../../../global/modal/Modal'
import { CloseIcon } from '../../../icons/CloseIcon'
import { useWindowResize } from '../../../utils/domUtils'
import { TextLargeStrong, TextSmall } from '../../Typography/Typography'
import { Bubble, BubbleProps } from '../Bubble/Bubble'

import styles from './InfoCard.module.scss'

interface InfoCardProps extends BubbleProps {
  title?: string
  text: React.ReactNode
  render?: React.ReactNode
  link?: {
    to: LinkProps['to']
    state?: LinkProps['state']
    text: string
  }
}

export const InfoCard: React.FC<InfoCardProps> = (props) => {
  const { title, text, render, link, disabled, children } = props
  const [modalOpen, setModalOpen] = useState(false)

  const isMobile = useWindowResize()

  if (isMobile)
    return (
      <div onClick={() => setModalOpen(true)}>
        {modalOpen && !disabled && (
          <Modal
            closeModal={() => setModalOpen(false)}
            pullDown
            render={() => (
              <div className={styles.infoCard}>
                {title && (
                  <div className={styles.mobileTitle}>
                    <TextLargeStrong className={styles.title}>{title}</TextLargeStrong>
                    <IconButton onClick={() => setModalOpen(false)}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                )}
                <TextSmall className={styles.text}>{text}</TextSmall>
                {render && <div className={styles.render}>{render}</div>}
                {link && (
                  <Link to={link.to} state={link.state}>
                    {link.text}
                  </Link>
                )}
              </div>
            )}
          />
        )}
        {children}
      </div>
    )

  return (
    <Bubble {...props} component={children}>
      <div className={styles.infoCard}>
        {title && <TextLargeStrong className={styles.title}>{title}</TextLargeStrong>}
        <TextSmall className={styles.text}>{text}</TextSmall>
        {render && <div className={styles.render}>{render}</div>}
        {link && (
          <Link to={link.to} state={link.state}>
            {link.text}
          </Link>
        )}
      </div>
    </Bubble>
  )
}
