import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Tabs, defineTab } from '../global/tabs/Tabs'
import { getPath } from '../utils/path'

import styles from './SubscriptionMobileTabs.module.scss'

interface TabDetail {
  main: string
  sub: string
  tab: string
  id: string
  label: string
}
const createTabDetails = (t: Function): TabDetail[] => [
  {
    main: 'dashboard',
    sub: 'subscriptions',
    tab: 'trading-platforms',
    id: 'trading-platforms',
    label: t('Subscriptions.Trading Platforms'),
  },
  {
    main: 'dashboard',
    sub: 'subscriptions',
    tab: 'market-data',
    id: 'market-data',
    label: t('Market Data.title'),
  },
]
export const SubscriptionMobileTabs = () => {
  const [currentTab, setCurrentTab] = useState<string>('')

  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const tabList = createTabDetails(t)
  const tabs = tabList.map((tab) => ({
    ...defineTab(tab.id, tab.label, false, false, `${tab.sub}/${tab.tab}`),
  }))

  useEffect(
    () => {
      const path = getPath(location.pathname) ?? {}
      const tab = tabList.find(
        (x) => x.main === path.main && x.sub === path.sub && x.tab === path.tab
      )
      setCurrentTab(tab?.id || '')
    },
    [location.pathname, t] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const handleTabChange = (tab: string) => {
    const path = tabs.find((tabDetail) => tabDetail.id === tab)
    navigate(`/dashboard/${path?.path || tab}`)
  }

  return (
    <Tabs
      wrapperClassName={styles.tabsWrapper}
      tabs={tabs}
      currentTab={currentTab}
      onTabChanged={handleTabChange}
    />
  )
}
