import React, { useState } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { SearchIcon } from '../../icons/SearchIcon'
import { CountryPreferenceStrippedDto } from '../../model/CountryDto'
import { getCountryCode } from '../../utils/Countries'
import { useAutoselect } from '../../utils/autoSelect'
import { inMemorySort } from '../../utils/useSortBy'

import styles from './CountrySelectModal.module.scss'

interface Props {
  title: string
  info?: string
  options: CountryPreferenceStrippedDto[]
  onCancel: () => void
  handleOnChangeOption: (countries: CountryPreferenceStrippedDto) => void
  renderFooter?: () => React.ReactNode
  selectedCountry: string | undefined
}

export const PhoneCodeSelectModal: React.FC<Props> = ({
  renderFooter,
  onCancel,
  handleOnChangeOption,
  title,
  info,
  options,
  selectedCountry,
}) => {
  const [search, setSearch] = useState('')
  const [countryList, setCountryList] = useState(options)
  const { t } = useTranslation()
  const handleSetSearch = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target
    setSearch(value)
    setCountryList(
      options.filter(
        (x) =>
          x.country.name.toLowerCase().startsWith(value.toLowerCase()) ||
          (x.phoneCode || '').startsWith(`+${value}`)
      )
    )
  }
  const searchRef = useAutoselect<HTMLInputElement>(true)

  return (
    <>
      <header className='modal-card-head'>
        <p className='modal-card-title'>{title}</p>
      </header>
      <section className={classNames('modal-card-body', styles.body)}>
        <ul>
          <li>
            <div className={styles.searchBlock}>
              {info && <p className={classNames(styles.text, 'mb-3')}>{info}</p>}
              <div className='field'>
                <p className='control has-icons-left'>
                  <input
                    className='input'
                    type='text'
                    placeholder={t('Search')}
                    onChange={handleSetSearch}
                    value={search}
                    ref={searchRef}
                  />
                  <span className='icon is-small is-left'>
                    <SearchIcon />
                  </span>
                </p>
              </div>
            </div>
          </li>
          {inMemorySort(countryList, 'country.name').map((country) => (
            <li
              onClick={() => handleOnChangeOption(country)}
              className={classNames({
                'has-background-light': selectedCountry === country.phoneCode,
              })}
              key={country.country.id}
            >
              <div className='is-flex px-3'>
                <ReactCountryFlag
                  svg
                  countryCode={
                    getCountryCode(country.country.id === 'LFT' ? 'MYS' : country.country.id) || ''
                  }
                />
                {country.phoneCode} {country.country.name}
                {country.country.nativeName ? (
                  <span className='has-text-grey ml-1'>({country.country.nativeName})</span>
                ) : null}
              </div>
            </li>
          ))}
        </ul>
      </section>
      <footer className='modal-card-foot'>
        {!renderFooter ? (
          <button className='button' onClick={onCancel} type='button'>
            {t('Cancel')}
          </button>
        ) : (
          renderFooter()
        )}
      </footer>
    </>
  )
}
