import React, { Fragment, useEffect, useState } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import '../layout/site.scss'
import { DashboardRoutes } from '../Dashboard/DashboardRoutes'
import { DashboardRoutesETD } from '../Dashboard/DashboardRoutesETD'
import { FirstDepositPage } from '../FirstDeposit/FirstDepositPage'
import { ForgotPasswordPage } from '../ForgotPassword/ForgotPasswordPage'
import { ResetPasswordPage } from '../ForgotPassword/ResetPasswordPage'
import { JourneyPage } from '../Journey/JourneyPage'
import { LearningETD } from '../Learning/LearningETD'
import { TrainingPage } from '../Learning/Trainings/TrainingPage'
import { LoginPage } from '../Login/LoginPage'
import { LogoutPage } from '../Logout/LogoutPage'
import MarketingPage from '../MarketingForm/MarketingPage'
import { NotificationCenter } from '../Notifications/NotificationCenter'
import { NotificationDetailsPage } from '../Notifications/NotificationDetailsPage'
import { DepositRedirectFromProviderPage } from '../PaymentProviderRedirect/DepositRedirectFromProviderPage/DepositRedirectFromProviderPage'
import { ProductAppropriatenessTestPage } from '../ProductRegistration/AppropriatenessTest/ProductAppropriatenessTestPage'
import { ProductLegalDocumentsPage } from '../ProductRegistration/LegalDocuments/ProductLegalDocumentsPage'
import { ProductPersonalDetailsPage } from '../ProductRegistration/ProductPersonalDetails/ProductPersonalDetailsPage'
import { ProductLegalDocumentsStepPage } from '../ProductRegistration/ProductRegistrationStepsPage/ProductLegalDocumentsStepPage'
import { CreateBankAccountPage } from '../Profile/BankAccounts/CreateBankAccountPage'
import { ProfileRoutes } from '../Profile/ProfileRoutes'
import { YearlyKycUpdateDetailsPage } from '../Profile/YearlyKycUpdateDetails/YearlyKycUpdateDetailsPage'
import { ConfirmPage } from '../SignUp/ConfirmPage/ConfirmPage'
import { DocumentsInfoPage } from '../SignUp/DocumentsInfoPage/DocumentsInfoPage'
import { FinancialInfoPage } from '../SignUp/FinancialInfo/FinancialInfoPage'
import { PersonalInfoPage } from '../SignUp/PersonalInfo/PersonalInfoPage/PersonalInfoPage'
import { SignUpRegisterPage } from '../SignUp/PersonalInfo/SignUpRegisterPage/SignUpRegisterPage'
import { VerifyEmailPage } from '../SignUp/PersonalInfo/VerifyEmailPage'
import { VerifyPhoneNumberPage } from '../SignUp/PersonalInfo/VerifyPhoneNumberPage'
import { ContactUsPage } from '../Support/ContactUsPage'
import { SupportPage } from '../Support/SupportPage'
import { TwoFactorAuthErrorPage } from '../TwoFactorAuth/TwoFactorAuthErrorPage'
import { TwoFactorAuthPage } from '../TwoFactorAuth/TwoFactorAuthPage'
import { FirstAppropriateTest } from '../WarningAccount/FirstAppropriateTest'
import { WarningAccountPage } from '../WarningAccount/WarningAccountPage'
import { WrongEntityPage } from '../WrongEntity/WrongEntityPage'
import { Loading } from '../global/Loading/Loading'
import { useSessionEntity } from '../global/context/EntityContext'
import { useProductReadContext } from '../global/context/ProductContext'
import { useSessionLanguage, useSessionSettings } from '../global/context/SessionSettingsContext'
import { AllLocales, Locale } from '../global/locale/Locale'
import { PageNotFound } from '../global/notFound/PageNotFound'
import { Toast } from '../global/toast/Toast'
import i18n from '../i18n'
import {
  AccountDetailedDto,
  allowKYCUpdate,
  hasConsentedToLegalDocuments,
  hasProductActivatedStatus,
  hasProductEmailVerifiedStatus,
  hasSubmittedAppropriatenessTest,
  isAccountDocumentManagementPermitted,
  isAccountKycUpdateDetailsAllowed,
  isAccountKycUpdateDetailsNotAllowed,
  isKYCUpdateDue,
  isProductAppropriatenessValid,
  showClosedAccountPage,
  showInactiveAccountPage,
} from '../model/AccountDetailedDto'
import { WalletTypeEnum } from '../model/WalletDto'
import { useAccountWriteContext } from '../utils/AccountContextContext'
import { MaybeAuthSession } from '../utils/AuthContext'
import { useFirstDepositContext } from '../utils/FirstDepositContext'
import { useMaintenance } from '../utils/MaintenanceModeContext'
import { TickmillCompaniesEnum, isTickmillUK } from '../utils/companyName.utils'
import { useNotificationFunctionality } from '../utils/notifications'
import { noAuthPaths } from '../utils/path'
import { isLearningRouteAllowed } from '../utils/permission.utils'
import { Layout } from './Layout'
import { ProductRedirector } from './ProductRedirector'
import { TradingAccountTradeRedirect } from './TradingAccountTradeRedirect'

export const MainContainer: React.FC<{
  toast?: Toast
  auth: MaybeAuthSession
  account?: AccountDetailedDto
  urlLocale?: Locale
  productHandled?: boolean
  setCurrentPath: (path: string) => void
}> = (props) => {
  const { auth, toast, account, urlLocale, productHandled, setCurrentPath } = props

  const [, setSettings] = useSessionSettings()
  const { isMaintenanceMode } = useMaintenance()
  const [isActivated, setIsActivated] = useState(true)
  const appLocale = useSessionLanguage()
  const { clearAccount } = useAccountWriteContext()
  document.body.dir = i18n.dir()
  const location = useLocation()
  const { search } = location
  const { isDefaultCFDProductType, product } = useProductReadContext()
  const { enableNotifications } = useNotificationFunctionality()
  const isProductTypeCFD = isDefaultCFDProductType()
  const entity = useSessionEntity()
  const { hasFirstDeposit, isLoading } = useFirstDepositContext()

  useEffect(() => {
    if (urlLocale && urlLocale !== appLocale && AllLocales.includes(urlLocale))
      setSettings({ locale: urlLocale as Locale })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!auth) clearAccount()
    if (!account) return
    setIsActivated(hasProductActivatedStatus(account, product))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, auth])

  if (isMaintenanceMode)
    return (
      <>
        <Routes>
          <Route element={<Layout setCurrentPath={setCurrentPath} toast={toast} />}>
            <Route path='/support' element={<SupportPage />} />
            <Route path={'/login'} element={<LoginPage />} />
            <Route path={'/*'} element={<Navigate to={'/login'} />} />
          </Route>
        </Routes>
      </>
    )

  if (auth && hasFirstDeposit === null) {
    return <Loading isLoading={isLoading} showLoadingIcon />
  }

  if (auth && account && productHandled) {
    return (
      <Fragment>
        <Routes>
          <Route element={<Layout setCurrentPath={setCurrentPath} toast={toast} />}>
            <Route path='login/2fa' element={<TwoFactorAuthPage />} />
            <Route path='login/2fa/expired' element={<TwoFactorAuthErrorPage />} />
            {noAuthPaths.map((path) => (
              <Route
                path={path}
                key={path}
                element={<Navigate to={isActivated ? 'dashboard' : 'profile'} />}
              />
            ))}
            {showClosedAccountPage(account, product) && (
              <Route
                path='/dashboard/closed-account'
                element={<WarningAccountPage status={'Closed'} />}
              />
            )}
            {showInactiveAccountPage(account, product) && (
              <Route
                path='/dashboard/inactive-account'
                element={<WarningAccountPage status={'Inactive'} />}
              />
            )}
            {(!hasConsentedToLegalDocuments(account, product) || isActivated) && (
              <Route path='/dashboard/legal-documents' element={<ProductLegalDocumentsPage />} />
            )}
            {!hasConsentedToLegalDocuments(account, product) && (
              <Route
                path='/dashboard/product-registration/legal-documents'
                element={<ProductLegalDocumentsStepPage />}
              />
            )}
            {isProductAppropriatenessValid(product, account) && (
              <Route
                path='/dashboard/product-registration/appropriateness-test'
                element={<ProductAppropriatenessTestPage />}
              />
            )}
            {hasProductEmailVerifiedStatus(account, product) && (
              <Route path='/dashboard/take-app-test' element={<FirstAppropriateTest />} />
            )}
            {isKYCUpdateDue(account) && (
              <Route
                path='/dashboard/product-registration/kyc-personal-details'
                element={<ProductPersonalDetailsPage />}
              />
            )}

            <Route path='/dashboard/update-kyc' element={<ProductPersonalDetailsPage />} />

            <Route
              path='/payments/redirect-to-transactions/:statusId'
              element={<DepositRedirectFromProviderPage />}
            />

            {isActivated && !hasFirstDeposit && isProductTypeCFD && (
              <Route
                path='/dashboard/first-deposit'
                element={<FirstDepositPage walletType={WalletTypeEnum['FX/CFD']} />}
              />
            )}

            <Route path='profile'>
              {allowKYCUpdate(account) ? (
                <Route path='kyc-update-details' element={<YearlyKycUpdateDetailsPage />} />
              ) : (
                <Route
                  path='kyc-update-details'
                  element={<Navigate to='/profile/personal-info' />}
                />
              )}
              <Route path='bank-accounts/new' element={<CreateBankAccountPage />} />
            </Route>

            {isLearningRouteAllowed(account) && !isActivated && (
              <Route path='/dashboard/learning/trainings' element={<TrainingPage />} />
            )}
            {isLearningRouteAllowed(account) && !isActivated && (
              <Route path='/dashboard/learning/:tab/*' element={<LearningETD />} />
            )}
            {isAccountDocumentManagementPermitted(product, account) &&
              !isAccountKycUpdateDetailsAllowed(account, product, true) &&
              ['/dashboard', '/profile/appropriateness-test', '/profile/kyc-update-details'].map(
                (path) => (
                  <Route
                    key={path}
                    path={path}
                    element={<Navigate to='/profile/document-management' />}
                  />
                )
              )}
            {isProductAppropriatenessValid(product, account) &&
              (hasSubmittedAppropriatenessTest(account, product) || !isTickmillUK(account)) &&
              ['/profile/document-management'].map((path) => (
                <Route
                  key={path}
                  path={path}
                  element={<Navigate to='/profile/appropriateness-test' />}
                />
              ))}
            {isProductAppropriatenessValid(product, account) &&
              ['/profile/document-management'].map((path) => (
                <Route
                  key={path}
                  path={path}
                  element={<Navigate to='/dashboard/take-app-test' />}
                />
              ))}
            {isProductAppropriatenessValid(product, account) &&
              [
                '/dashboard/traders-room',
                '/dashboard/introducing-broker',
                '/dashboard/payment-agent',
                '/profile/kyc-update-details',
              ].map((path) => (
                <Route
                  key={path}
                  path={path}
                  element={<Navigate to='/dashboard/product-registration/appropriateness-test' />}
                />
              ))}

            {!isProductAppropriatenessValid(product, account) &&
              isAccountKycUpdateDetailsAllowed(account, product, true) &&
              isKYCUpdateDue(account) &&
              ['dashboard/:tab/*', 'profile/:tab/*'].map((path) => (
                <Route
                  key={path}
                  path={path}
                  element={<Navigate to='/profile/kyc-update-details' />}
                />
              ))}

            {isActivated && (
              <Route
                path='dashboard/:tab/*'
                element={isProductTypeCFD ? <DashboardRoutes /> : <DashboardRoutesETD />}
              />
            )}

            <Route path='profile/:tab/*' element={<ProfileRoutes />} />
            {account && isAccountKycUpdateDetailsNotAllowed(account, product) && (
              <Route
                path='/profile/kyc-update-details'
                element={<Navigate to='/profile/personal-info' />}
              />
            )}
            {enableNotifications && <Route path='notifications' element={<NotificationCenter />} />}
            {enableNotifications && (
              <Route path='notifications/:notificationId' element={<NotificationDetailsPage />} />
            )}
            <Route path='users'>
              {!!account &&
                !hasProductEmailVerifiedStatus(account, product) &&
                ['register3', 'register4'].map((path) => (
                  <Route
                    key={path}
                    path={path}
                    element={<Navigate to='/dashboard/traders-room' />}
                  />
                ))}
              {!!account && !hasProductEmailVerifiedStatus(account, product) && (
                <Route
                  path='register3/:clientId'
                  element={<Navigate to='/dashboard/traders-room' />}
                />
              )}
              {entity !== TickmillCompaniesEnum.TICKMILL_PA && (
                <>
                  <Route path='register3/:clientId' element={<FinancialInfoPage />} />
                  <Route path='register4/:clientId' element={<DocumentsInfoPage />} />
                  <Route path='register-confirm' element={<ConfirmPage />} />
                </>
              )}
            </Route>
            <Route path='/journey' element={<JourneyPage />} />
            <Route path='/contact-us/:tab/*' element={<ContactUsPage />} />
            <Route path='/contact-us' element={<ContactUsPage />} />
            <Route path='/logout' element={<LogoutPage />} />
            <Route path='/wrong-entity' element={<WrongEntityPage />} />
            <Route path='/404' element={<PageNotFound />} />
            <Route
              path='/trading-account-trade-redirect'
              element={<TradingAccountTradeRedirect />}
            />
            <Route path='*' element={<ProductRedirector />} />
          </Route>
          <Route path='/trading-account-trade-redirect' element={<TradingAccountTradeRedirect />} />
        </Routes>
      </Fragment>
    )
  }

  if (!auth) {
    return (
      <>
        <Routes>
          <Route element={<Layout setCurrentPath={setCurrentPath} toast={toast} />}>
            <Route path='login' element={<LoginPage />} />
            <Route path='login/2fa' element={<TwoFactorAuthPage />} />
            <Route path='login/2fa/expired' element={<TwoFactorAuthErrorPage />} />
            {entity !== TickmillCompaniesEnum.TICKMILL_PA && (
              <Route path='sign-up' element={<PersonalInfoPage />} />
            )}
            <Route path='logout' element={<LogoutPage />} />
            <Route path='users'>
              <Route path='reset-password/:email/:token' element={<ResetPasswordPage />} />
              <Route path='login' element={<Navigate to={`/login${search}`} />} />
              <Route path='verify-email/:leadId' element={<VerifyEmailPage />} />
              <Route path='verify-phone/:leadId' element={<VerifyPhoneNumberPage />} />
              {entity !== TickmillCompaniesEnum.TICKMILL_PA && (
                <>
                  <Route path='register' element={<Navigate to={`/sign-up${search}`} />} />
                  <Route path='register2/:leadId' element={<SignUpRegisterPage />} />
                  <Route path='register2' element={<Navigate to={`/sign-up${search}`} />} />
                </>
              )}
            </Route>
            <Route path='forgot-password' element={<ForgotPasswordPage />} />
            <Route path='support' element={<SupportPage />} />
            <Route path={'/marketing-form'} element={<MarketingPage />} />
            <Route path='*' element={<Navigate to={`/login${search}`} />} />
          </Route>
          <Route path='/trading-account-trade-redirect' element={<TradingAccountTradeRedirect />} />
        </Routes>
      </>
    )
  }

  return (
    <Routes>
      <Route path='*' element={<Loading isLoading showLoadingIcon />} />
    </Routes>
  )
}
