import {
  differenceInMinutes,
  differenceInMonths,
  differenceInYears,
  isBefore,
  subMonths,
} from 'date-fns'

import { isDocumentManagementNotificationNeeded } from '../Profile/DocumentManagement/DocumentManagementPage'
import { WarningPageStatus } from '../WarningAccount/WarningAccountPage'
import { TickmillCompaniesEnum, TickmillCompany } from '../utils/companyName.utils'
import { getExpirationDaysInterval } from '../utils/date.utils'
import { AccountType } from './AccountType'
import { NameDto } from './NameDto'
import { TickmillProductDto } from './TickmillProductDto'
import { TickmillProductType } from './TickmillProductType'
import { CurrencyType } from './WalletDto'

export type AccountDetailedDto = {
  id: string
  clientAreaId?: string
  firstname: string
  middlename: string | null
  lastname: string
  type: NameDto<number>
  tickmillCompany: TickmillCompany
  status: NameDto<number>
  nativeName: string | null
  gender: NameDto<number>
  communicationLanguage: NameDto<string>
  username: string | null
  nationalClientIdentifier: string | null
  nciType: NameDto<number> | null
  assignee?: {
    id: string
    name: string
    status?: NameDto<number>
  } | null
  affiliateToken: string | null
  birthday: string
  salesStatus?: string | null
  classification: NameDto<number>
  personalId: string | null
  dateFormatType?: NameDto<number> | null
  documentType: NameDto<number> | null
  documentId: string | null
  documentExpirationDate: string | null
  documentIdInternal: string | null
  educationLevel: NameDto<number> | null
  preferredLandingPage: PreferredLandingPage | null
  clientAmlSummary: {
    score: number
    amlRiskLevel: NameDto
  }
  marketDataClassification: NameDto<MarketDataClassificationStatus> | null
  multiTierRegistrationAllowed: boolean
  mainIbScheme: NameDto
  employer: string | null
  isEmployed: boolean | null
  businessNature: NameDto<number> | null
  isUsCitizen: boolean
  isPoliticallyExposedPerson: boolean
  taxId: string | null
  taxIdAvailable: boolean
  taxIdAvailabilityStatus: NameDto<number>
  introducingBroker: null | NameDto<string>
  registrationFinishDate: string | null
  isSwapAccount?: boolean
  loginFailedCount: number | null
  lastClientAreaLoginDate: string | null
  hasSentWelcomeEmail: boolean | null
  validationCompletedDate: string | null
  isAddressInfoLocked: boolean
  isPaymentInfoLocked: boolean
  isBrexitTransferAccepted: boolean
  isFIXApiUser: boolean
  isPersonalInfoLocked: boolean
  isAdditionalInfoLocked: boolean
  isCompanyInfoLocked: boolean | null
  isKYCUpdateNeeded: boolean
  isDormantUpdateNeeded: boolean
  isInternalTestAccount: boolean
  isSwapFreeAccount: boolean
  isWelcomeBonusAccount: boolean
  isVerificationExpedited: boolean
  shouldReceiveNewsletter: boolean
  introducingBrokerRegistrationAllowed: boolean
  hasUploadedDocuments: boolean
  approachedByRep: boolean
  defaultTradingAccountBook?: NameDto<number> | null
  maxLeverage: NameDto<number> | null
  brokerIntroductionMethod: NameDto<number> | null
  riskSet: null | any
  vulnerableGroup: null | any
  detailsUpdateAvailableUntil: string | null
  leadId?: string
  kycLastUpdatedDate: null | string
  detailsLastUpdatedDate: string | null
  kycUpdateAvailableUntil: string | null
  companyDetails: null | CompanyDetails
  nationalities: ClientNationalityDto[]
  phoneNumbers: ClientPhoneNumberDto[]
  emails: ClientEmailDto[]
  teams: ClientLeadTeamDto[]
  addresses: ClientAddressDto[]
  restrictions: NameDto[]
  clientAnswers: ClientAnswerDto[]
  clientPaymentAgent: null | { id: string; name: string; isActive: boolean }
  clientIntroducingBroker: null | { id: string; name: string }
  externalSystems?: ExternalSystem[]
  isFavorite: boolean
  createdDate: string | null
  updatedDate: string | null
  updatedAt: string | null
  truliooWatchlistStatus?: NameDto<number> | null
  worldCheckStatus?: NameDto<number> | null
  preferredCurrency: NameDto<CurrencyType> | null
  displayCurrency: NameDto<CurrencyType> | null

  tickmillPublicDomain: null | string
  tickmillProducts: TickmillProductDto[]
  companyConfiguration: CompanyConfigurationDto
  affiliate: null | NameDto<string>
  isAppropriatenessTestAccountFailed: {
    [TickmillProductType.CFD]: boolean
    [TickmillProductType.ETD]: boolean
  }
  firstDeposit?: string | null
  clientProductStates?: ClientProductState[]
  agreedLegalDocuments: NameDto<string>[]
}

interface CompanyConfigurationDto {
  hasRegulatoryOptions: boolean
  hasDynamicLeverage: boolean
  hasTraderRoom: boolean
  hasIBRoom: boolean
  hasPARoom: boolean
  hasForexTools: boolean
  hasMultiTier: boolean
  hasAutochartist: boolean
  hasStocks: boolean
  hasClassification: boolean
  appropriatenessTestCooldownPeriod: number
  hasNCI: boolean
  showProductSwitcher: boolean
}

interface PreferredLandingPage extends NameDto {
  tickmillProductId: TickmillProductType
}

export interface ClientProductState {
  tickmillProduct: {
    id: number
    name: TickmillProductType
  }
  calculatedStatus: {
    id: number
    name: AccountDetailedStatus
  }
  passedAppropriatenessTest: boolean
  agreedToLegalDocuments: boolean
  setMarketDataClassification: boolean
  submittedAppropriatenessTest: boolean
  appropriatenessTestMaximumRetakeLimitReached: boolean
  allRequirementsMet: boolean
}

export const isAccountCreated1YearAgo = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  if (!accountDetailed?.createdDate) {
    return false
  }

  const yearDateOld = new Date(accountDetailed.createdDate)
  const yearDateNow = new Date()

  const differenceInYears1 = differenceInYears(yearDateNow, yearDateOld)

  return differenceInYears1 >= 1
}

export const isAccountCreated3MonthAgo = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  if (!accountDetailed?.createdDate) {
    return false
  }
  const monthDateOld = new Date(accountDetailed.createdDate)
  const monthDateNow = new Date()

  const differenceInMonths3 = differenceInMonths(monthDateNow, monthDateOld)

  return differenceInMonths3 >= 3
}

export const isAccountIndividualType = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  if (!accountDetailed) {
    return false
  }
  return accountDetailed?.type?.id === AccountType.Individual
}

export const isAccountCompanyType = (accountDetailed: AccountDetailedDto | undefined): boolean => {
  if (!accountDetailed) {
    return false
  }
  return accountDetailed?.type?.id === AccountType.Corporate
}

export const isAccountKycUpdateDetailsNotAllowed = (
  accountDetailed: AccountDetailedDto,
  product: TickmillProductType
): boolean => {
  return !isAccountKycUpdateDetailsAllowed(accountDetailed, product, true)
}

export const isAccountKycUpdateDetailsAllowed = (
  accountDetailed: AccountDetailedDto | undefined,
  product: TickmillProductType,
  ignoreDateDifferenceInMinutes?: boolean
): boolean => {
  if (ignoreDateDifferenceInMinutes) {
    return !!accountDetailed?.isKYCUpdateNeeded || !!accountDetailed?.isDormantUpdateNeeded
  }
  if (!accountDetailed) {
    return false
  }

  const dateDifferenceInMinutes = differenceInMinutes(
    new Date(),
    new Date(accountDetailed?.detailsUpdateAvailableUntil ?? '')
  )

  const isKYCUpdateNeeded = accountDetailed?.isKYCUpdateNeeded && dateDifferenceInMinutes >= 0

  const isDormantUpdateNeeded =
    accountDetailed?.isDormantUpdateNeeded &&
    dateDifferenceInMinutes >= 0 &&
    !hasProductDormantStatus(accountDetailed, product)

  return !!(isKYCUpdateNeeded || isDormantUpdateNeeded)
}

export const accountHasIntroducingBroker = (
  accountDetailed: AccountDetailedDto | undefined
): boolean =>
  !!accountDetailed?.introducingBroker?.id || !!accountDetailed?.clientIntroducingBroker?.id

export const isAccountDocumentAllLocked = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  if (accountDetailed === undefined || accountDetailed === null) {
    return false
  }

  if (isAccountCompanyType(accountDetailed)) {
    return (
      (accountDetailed?.isPersonalInfoLocked ?? false) &&
      (accountDetailed?.isAddressInfoLocked ?? false) &&
      (accountDetailed?.isCompanyInfoLocked ?? false) &&
      (accountDetailed?.isAdditionalInfoLocked ?? false)
    )
  }

  if (!isAccountCompanyType(accountDetailed)) {
    return (
      accountDetailed?.isPersonalInfoLocked &&
      accountDetailed?.isAddressInfoLocked &&
      accountDetailed?.isAdditionalInfoLocked
    )
  }

  return false
}

export const isAccountDocumentAllowedUpload = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  return (
    isAccountPersonalInfoDocumentAllowedUpload(accountDetailed) ||
    isAccountAddressInfoDocumentAllowedUpload(accountDetailed) ||
    isAccountCompanyInfoDocumentAllowedUpload(accountDetailed) ||
    isAccountAdditionalInfoDocumentAllowedUpload(accountDetailed) ||
    isAccountPaymentInfoDocumentAllowedUpload(accountDetailed)
  )
}

export const isAccountPersonalInfoDocumentAllowedUpload = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  if (accountDetailed === undefined || accountDetailed === null) {
    return false
  }

  return (
    !accountDetailed.isPersonalInfoLocked || isDocumentManagementNotificationNeeded(accountDetailed)
  )
}

export const isAccountAddressInfoDocumentAllowedUpload = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  if (accountDetailed === undefined || accountDetailed === null) {
    return false
  }
  return !accountDetailed?.isAddressInfoLocked
}

export const isAccountPaymentInfoDocumentAllowedUpload = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  if (accountDetailed === undefined || accountDetailed === null) {
    return false
  }
  return !accountDetailed?.isPaymentInfoLocked
}

export const isAccountCompanyInfoDocumentAllowedUpload = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  if (accountDetailed === undefined || accountDetailed === null) {
    return false
  }
  return !accountDetailed?.isCompanyInfoLocked && isAccountCompanyType(accountDetailed)
}

export const isAccountAdditionalInfoDocumentAllowedUpload = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  if (accountDetailed === undefined || accountDetailed === null) {
    return false
  }
  return !accountDetailed?.isAdditionalInfoLocked
}

export const isAccountDocumentsApproved = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  return (
    isAccountPersonalInfoDocumentApproved(accountDetailed) &&
    isAccountAddressInfoDocumentApproved(accountDetailed) &&
    isAccountCompanyInfoDocumentApproved(accountDetailed) &&
    isAccountAdditionalInfoDocumentApproved(accountDetailed)
  )
}

export const isAccountPersonalInfoDocumentApproved = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  if (
    accountDetailed === undefined ||
    accountDetailed === null ||
    accountDetailed?.isPersonalInfoLocked === undefined ||
    accountDetailed?.isPersonalInfoLocked === null
  ) {
    return true
  }

  return !!accountDetailed.isPersonalInfoLocked
}

export const isAccountAddressInfoDocumentApproved = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  if (
    accountDetailed === undefined ||
    accountDetailed === null ||
    accountDetailed?.isAddressInfoLocked === undefined ||
    accountDetailed?.isAddressInfoLocked === null
  ) {
    return true
  }
  return !!accountDetailed?.isAddressInfoLocked
}

export const isAccountCompanyInfoDocumentApproved = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  if (
    accountDetailed === undefined ||
    accountDetailed === null ||
    accountDetailed?.isCompanyInfoLocked === undefined ||
    accountDetailed?.isCompanyInfoLocked === null
  ) {
    return true
  }
  return !!accountDetailed?.isCompanyInfoLocked && isAccountCompanyType(accountDetailed)
}

export const isAccountAdditionalInfoDocumentApproved = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  if (
    accountDetailed === undefined ||
    accountDetailed === null ||
    accountDetailed?.isAdditionalInfoLocked === undefined ||
    accountDetailed?.isAdditionalInfoLocked === null
  ) {
    return true
  }
  return !!accountDetailed?.isAdditionalInfoLocked
}

export const isAccountDetailedActivated = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  if (accountDetailed === undefined || accountDetailed === null) {
    return false
  }

  return AccountDetailedStatus.Activated === accountDetailed.status.id
}

export const isAccountEmailVerifiedStatus = (status: number): boolean => {
  return AccountDetailedStatus.EmailVerified === status
}

export const isAccountEmailVerified = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  if (accountDetailed === undefined || accountDetailed === null) {
    return false
  }

  return isAccountEmailVerifiedStatus(accountDetailed.status.id)
}

export const getClientPrimaryEmail = (account: AccountDetailedDto): string | undefined =>
  account.emails.find((e) => e.isPrimary)?.address

export interface ClientNationalityDto {
  id: string
  name: string
  isPrimary: boolean
}

export interface ClientPhoneNumberDto {
  id: string
  tempId?: number
  type: string
  countryCode: string
  number: string
  isPrimary: boolean
  isVerified: boolean
  createdDate?: string
  shouldVerify: boolean
  isUsedIn2FA: boolean
}

export interface ClientEmailDto {
  id?: string
  tempId?: number
  emailType: string
  address: string
  isPrimary: boolean
}

export interface ClientLeadTeamDto {
  id: string
  name: string
  isPrimary: boolean
}

export interface ClientAddressDto {
  id?: string
  tempId?: number
  type: string
  street: string | null
  city: string | null
  state: string | null
  postalCode: string | null
  country: {
    id: string
    name: string
  }
  isPrimary: boolean
}

interface ClientAnswerDto {
  question: string
  answer: string
}

interface CompanyDetails {
  name: string
  registrationDate: string
  registrationNumber: string
  website: string
  email: {
    emailType: string
    address: string
    isPrimary: boolean
  }
  phoneNumber: {
    type: string
    countryCode: string
    number: string
    isPrimary: boolean
  }
  legalRepresentationBasisId: {
    id: number
    name: string
  }
  legalRepresentationBasis: {
    name: string
    id: number
  }
  ownershipStructure: NameDto<number>
  ownersCount: number
  address: {
    type: string
    street: string
    city: string
    state: string
    postalCode: string
    country: string
    isPrimary: boolean
  }
  legalEntityIdentifier: string
  owners: Owner[]
  companyType: {
    id: number
    name: string
  } | null
}

export interface Owner {
  id?: string
  name: string
  ownership: number
}

export interface ExternalSystem {
  id: string
  externalSystem: {
    id: 1 | 2 | 3
    name: 'SugarCRM' | 'HubSpot' | 'Legacy SugarCRM'
  }
  externalId: string
  externalUrl: string
}

export enum AccountDetailedStatus {
  AppropriatenessTestSubmitted = 1,
  EmailVerified = 2,
  PendingDocumentVerification = 3,
  Activated = 4,
  ValidationDenied = 5,
  Blocked = 6,
  Dormant = 7,
  Closed = 8,
}

export enum MarketDataClassificationStatus {
  Private = 1,
  Professional = 2,
}

export const isAccountAppropriatenessTestSubmittedStatus = (status: number): boolean => {
  return AccountDetailedStatus.AppropriatenessTestSubmitted === status
}

export const isAccountPendingDocumentVerificationStatus = (status: number): boolean => {
  return AccountDetailedStatus.PendingDocumentVerification === status
}

export const isAccountDetailedActivatedStatus = (status: number | undefined): boolean => {
  return AccountDetailedStatus.Activated === status
}

export const isAccountDetailedValidationDeniedStatus = (status: number): boolean => {
  return AccountDetailedStatus.ValidationDenied === status
}

export const hasProductDormantStatus = (
  account: AccountDetailedDto | undefined,
  product: TickmillProductType
): boolean =>
  evaluateProductStateAttribute(
    account,
    product,
    (productState) => productState.calculatedStatus.id === AccountDetailedStatus.Dormant
  )

export const hasProductClosedStatus = (
  account: AccountDetailedDto | undefined,
  product: TickmillProductType
): boolean =>
  evaluateProductStateAttribute(
    account,
    product,
    (productState) => productState.calculatedStatus.id === AccountDetailedStatus.Closed
  )

export const hasProductAppropriatenessTestSubmittedStatus = (
  account: AccountDetailedDto | undefined,
  product: TickmillProductType
): boolean =>
  evaluateProductStateAttribute(
    account,
    product,
    (productStatus) =>
      productStatus.calculatedStatus.id === AccountDetailedStatus.AppropriatenessTestSubmitted
  )

export const isProductAppropriatenessValid = (
  product: TickmillProductType,
  account: AccountDetailedDto | undefined
): boolean => {
  if (account === undefined || account === null) {
    return false
  }

  return (
    !hasClearedAppropriatenessTest(account, product) ||
    isProductAppropriatenessTestFailed(product, account) ||
    hasProductDormantStatus(account, product) ||
    hasProductClosedStatus(account, product) ||
    hasProductEmailVerifiedStatus(account, product)
  )
}

export const isAccountDocumentManagementPermitted = (
  product: TickmillProductType,
  accountDetailed: AccountDetailedDto | undefined
): boolean =>
  isProductAppropriatenessTestNotFailed(product, accountDetailed) ||
  hasProductPendingDocumentVerificationStatus(accountDetailed, product)

export const isAccountDocumentManagementExpired = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  if (!accountDetailed?.documentExpirationDate) {
    return false
  }

  const documentExpirationDate = accountDetailed?.documentExpirationDate || 1
  const expirationDate = new Date(documentExpirationDate)

  return isDocumentManagementExpired(expirationDate.toISOString())
}

export const isAccountDocumentManagement1MonthExpired = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  if (!accountDetailed?.documentExpirationDate) {
    return false
  }

  const documentExpirationDate = accountDetailed?.documentExpirationDate || 1
  const expirationDate = new Date(documentExpirationDate)

  return isDocumentManagement1MonthExpired(expirationDate.toISOString())
}

export const isAccountDocumentManagement2MonthExpired = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  if (!accountDetailed?.documentExpirationDate) {
    return false
  }

  const documentExpirationDate = accountDetailed?.documentExpirationDate || 1
  const expirationDate = new Date(documentExpirationDate)

  return isDocumentManagement2MonthExpired(expirationDate.toISOString())
}

export const isDocumentManagementExpired = (expirationDate: string | undefined | null): boolean => {
  if (!expirationDate) {
    return false
  }

  return isBefore(new Date(expirationDate), new Date())
}

export const isDocumentManagement1MonthExpired = (
  expirationDate: string | undefined | null
): boolean => {
  if (!expirationDate) {
    return false
  }

  return isBefore(subMonths(new Date(expirationDate), 1), new Date())
}

export const isDocumentManagement2MonthExpired = (
  expirationDate: string | undefined | null
): boolean => {
  if (!expirationDate) {
    return false
  }

  return isBefore(subMonths(new Date(expirationDate), 2), new Date())
}

export const isProductAppropriatenessTestFailed = (
  product: TickmillProductType,
  account: AccountDetailedDto | undefined
): boolean =>
  hasProductAppropriatenessTestSubmittedStatus(account, product) &&
  !hasClearedAppropriatenessTest(account, product)

export const isProductAppropriatenessTestNotFailed = (
  product: TickmillProductType,
  account: AccountDetailedDto | undefined
): boolean =>
  hasProductAppropriatenessTestSubmittedStatus(account, product) &&
  hasClearedAppropriatenessTest(account, product)

export const hasProductPendingDocumentVerificationStatus = (
  accountDetailed: AccountDetailedDto | undefined,
  productType: TickmillProductType
): boolean =>
  evaluateProductStateAttribute(
    accountDetailed,
    productType,
    (productState) =>
      productState.calculatedStatus.id === AccountDetailedStatus.PendingDocumentVerification
  )

export const hasProductEmailVerifiedStatus = (
  account: AccountDetailedDto | undefined,
  product: TickmillProductType
): boolean =>
  evaluateProductStateAttribute(
    account,
    product,
    (productStatus) => productStatus.calculatedStatus.id === AccountDetailedStatus.EmailVerified
  )

export const hasSubmittedAppropriatenessTest = (
  account: AccountDetailedDto | undefined,
  product: TickmillProductType
): boolean =>
  evaluateProductStateAttribute(
    account,
    product,
    (productStatus) => productStatus.submittedAppropriatenessTest
  )

export const getAccountDocumentExpirationDaysInterval = (
  account: AccountDetailedDto | undefined
): number => {
  if (!account?.documentExpirationDate) {
    return 0
  }

  return getExpirationDaysInterval(account?.documentExpirationDate)
}

export const getVerifiedPhoneNumbers = (
  account: AccountDetailedDto | undefined
): ClientPhoneNumberDto[] | undefined => {
  return account?.phoneNumbers.filter((phone) => phone.isVerified)
}

export const isClientOnCoolOff = (
  account: AccountDetailedDto | undefined,
  isProductTypeCFD: boolean
): boolean => {
  if (!account) return false

  const targetProductType = isProductTypeCFD ? TickmillProductType.ETD : TickmillProductType.CFD

  const productState = account?.clientProductStates?.find(
    (productState) => productState.tickmillProduct.id === targetProductType
  )

  return !!(
    productState?.appropriatenessTestMaximumRetakeLimitReached &&
    productState.calculatedStatus.id === AccountDetailedStatus.ValidationDenied
  )
}

export const isAppropriatenessTestMaximumRetakeLimitReached = (
  account: AccountDetailedDto | undefined,
  product: TickmillProductType
): boolean => {
  if (!account) return false

  const productState = account?.clientProductStates?.find(
    (productState) => productState.tickmillProduct.id === product
  )

  return !!productState?.appropriatenessTestMaximumRetakeLimitReached
}

export const isProductCalculatedStatusEmailVerified = (
  account: AccountDetailedDto | undefined,
  isProductTypeCFD: boolean
): boolean => {
  if (!account) return false

  const targetProductType = isProductTypeCFD ? TickmillProductType.CFD : TickmillProductType.ETD

  const productState = account?.clientProductStates?.find(
    (productState) => productState.tickmillProduct.id === targetProductType
  )

  return productState?.calculatedStatus.id === AccountDetailedStatus.EmailVerified
}

export const isFuturesValidationDenied = (account: AccountDetailedDto | undefined): boolean => {
  if (!account) return false

  const etdProductState = account.clientProductStates?.find(
    (productState) => productState.tickmillProduct.id === TickmillProductType.ETD
  )

  if (
    etdProductState &&
    etdProductState.calculatedStatus.id === AccountDetailedStatus.ValidationDenied
  ) {
    return true
  }

  return false
}

export const showClosedAccountPage = (
  account: AccountDetailedDto | undefined,
  product: TickmillProductType
): boolean => {
  const Status: WarningPageStatus = 'Closed'

  return !!(
    account &&
    hasProductClosedStatus(account, product) &&
    !localStorage.getItem(`hasVisited${Status}AccountPage`)
  )
}

export const showInactiveAccountPage = (
  account: AccountDetailedDto | undefined,
  product: TickmillProductType
): boolean => {
  const Status: WarningPageStatus = 'Inactive'

  return !!(
    account &&
    hasProductDormantStatus(account, product) &&
    !localStorage.getItem(`hasVisited${Status}AccountPage`)
  )
}
export const determineProductState = (
  account: AccountDetailedDto | undefined,
  productType: TickmillProductType
): ClientProductState | undefined => {
  if (!account) return undefined

  return account.clientProductStates?.find(
    (productState) => productState.tickmillProduct.id === productType
  )
}

const evaluateProductStateAttribute = (
  account: AccountDetailedDto | undefined,
  productType: TickmillProductType,
  attributeEvaluator: (productState: ClientProductState) => boolean
): boolean => {
  const productState = determineProductState(account, productType)
  return productState ? attributeEvaluator(productState) : false
}

export const isProductRegistered = (
  account: AccountDetailedDto | undefined,
  productType: TickmillProductType
): boolean => evaluateProductStateAttribute(account, productType, () => true)

export const hasConsentedToLegalDocuments = (
  account: AccountDetailedDto | undefined,
  productType: TickmillProductType
): boolean =>
  evaluateProductStateAttribute(
    account,
    productType,
    (productState) => !!productState.agreedToLegalDocuments
  )

export const hasClearedAppropriatenessTest = (
  account: AccountDetailedDto | undefined,
  tickmillProductType: TickmillProductType
): boolean =>
  evaluateProductStateAttribute(
    account,
    tickmillProductType,
    (productState) => !!productState.passedAppropriatenessTest
  )

export const areAllProductRequirementsMet = (
  account: AccountDetailedDto | undefined,
  tickmillProductType: TickmillProductType
): boolean =>
  evaluateProductStateAttribute(
    account,
    tickmillProductType,
    (productState) => !!productState.allRequirementsMet
  )

export const hasProductActivatedStatus = (
  account: AccountDetailedDto | undefined,
  productType: TickmillProductType
): boolean => {
  return evaluateProductStateAttribute(
    account,
    productType,
    (productState) => productState.calculatedStatus.id === AccountDetailedStatus.Activated
  )
}

export const isTargetProductRegistered = (
  account: AccountDetailedDto | undefined,
  isProductTypeCFD: boolean
): boolean => {
  if (!account) return false
  const targetProduct = isProductTypeCFD ? TickmillProductType.ETD : TickmillProductType.CFD
  return isProductRegistered(account, targetProduct)
}

export const allowKYCUpdate = (account: AccountDetailedDto | undefined): boolean => {
  if (!account) return false
  return account.isKYCUpdateNeeded || account.isDormantUpdateNeeded
}

export const isKYCUpdateDue = (account: AccountDetailedDto | undefined): boolean => {
  if (!account) return false
  if (!allowKYCUpdate(account)) return false
  const currentDate = new Date(Date.now())
  const kycDate = new Date(account.kycUpdateAvailableUntil ?? Date.now())
  const detailsUpdateDate = new Date(account.detailsUpdateAvailableUntil ?? Date.now())

  return kycDate < currentDate || detailsUpdateDate < currentDate
}
export const isClientRegisteredMonthAgo = (
  account: AccountDetailedDto | undefined,
  month: number
): boolean => {
  if (!account) return false
  return differenceInMonths(new Date(), new Date(account?.createdDate ?? '')) >= month
}

export const requiresAppTestImprovement = (company: TickmillCompaniesEnum): boolean =>
  company === TickmillCompaniesEnum.TICKMILL_EU || company === TickmillCompaniesEnum.TICKMILL_UK
