import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Form, FormikErrors, FormikProps, withFormik } from 'formik'
import { t } from 'i18next'

import { Loading } from '../../../global/Loading/Loading'
import { Button } from '../../../global/button/Button'
import { useSessionLanguage } from '../../../global/context/SessionSettingsContext'
import { SelectModal, SelectModalOption } from '../../../global/field/SelectModal'
import { createFormField } from '../../../global/formField/FormField'
import { createFormNumericField } from '../../../global/formField/FormNumericField'
import { CancelActionModal } from '../../../global/modal/CancleActionModal'
import { Modal } from '../../../global/modal/Modal'
import { FormTemplate } from '../../../global/templates/FormTemplate'
import { useFormatNumber } from '../../../hooks/useFormatNumber'
import { DropArrowDownIcon } from '../../../icons/DropArrowDownIcon'
import { TradingAccount } from '../../../model/TradingAccount'
import { CurrencyType, WalletDto } from '../../../model/WalletDto'
import { TextSmall, TextTinyStrong } from '../../../ui/Typography/Typography'
import { usePathHistoryContext } from '../../../utils/PathHistoryContext'
import { currencyToSymbol } from '../../../utils/currency'
import { useScrollToTop } from '../../../utils/useScrollToTop'

import styles from './TransferForm.module.scss'

const FormNumericField = createFormNumericField()
type DisplayType = 'wallet' | 'tradingAccount'

interface WalletOrTradingAccountOption {
  id: string
  name: string
  currency: CurrencyType
  type: string
  balance: number | null
  displayType: DisplayType
}

interface MatrixOption {
  wallet: WalletOrTradingAccountOption
  tradingAccount: WalletOrTradingAccountOption
}

const getMatrixOptions = (wallets: WalletDto[], tradingAccounts: TradingAccount[]) => {
  const { id, name, platformOverview } = tradingAccounts?.[0] || {}

  return wallets.reduce<Partial<Record<CurrencyType, MatrixOption>>>((options, wallet) => {
    const balanceObj = platformOverview?.balances.find((b) => b.currency === wallet.currency.id)

    const balance = balanceObj?.balance || null

    options[wallet.currency.id] = {
      wallet: {
        id: wallet.id,
        name: wallet.name,
        currency: wallet.currency.id,
        balance: wallet.balance,
        type: t('Wallet.Wallet'),
        displayType: 'wallet',
      },
      tradingAccount: {
        id,
        name,
        currency: wallet.currency.id,
        balance,
        type: t('Trading Account.Trading Account'),
        displayType: 'tradingAccount',
      },
    }

    return options
  }, {})
}

export interface TransferValues {
  transferFrom: WalletOrTradingAccountOption | null
  transferTo: WalletOrTradingAccountOption | null
  fromAmount: number
}

interface OuterProps {
  wallets: WalletDto[]
  currency?: CurrencyType
  fetchingAccounts: boolean
  tradingAccounts: TradingAccount[]
  preselectedWallet?: WalletDto
  preselectedTradingAccount?: TradingAccount
  handleFormSubmit: (values: TransferValues) => void
}

const FormField = createFormField<TransferValues>()

const TransferFormETDUI: React.FC<FormikProps<TransferValues> & OuterProps> = (props) => {
  const {
    wallets,
    tradingAccounts,
    values,
    isValid,
    setFieldValue,
    handleSubmit,
    fetchingAccounts,
  } = props
  useScrollToTop()
  const { transferFrom, fromAmount } = values

  const [exitConfirmationModal, setExitConfirmationModal] = useState(false)
  const [transferFromModal, setTransferFromModal] = useState(false)
  const showTANotice = useMemo(() => {
    if (!tradingAccounts.length) return false
    if (values.transferFrom?.displayType !== 'wallet') return false
    return !tradingAccounts.some(({ platformOverview }) =>
      platformOverview.balances.some((b) => b.currency === transferFrom?.currency)
    )
  }, [tradingAccounts, transferFrom?.currency, values.transferFrom?.displayType])

  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()
  const { navigateToPreviousPath } = usePathHistoryContext()

  const matrixOptions = useMemo<Partial<Record<CurrencyType, MatrixOption>>>(
    () => getMatrixOptions(wallets, tradingAccounts),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [wallets, tradingAccounts, t]
  )

  const handleSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleSubmit()
  }

  const handleExitPageConfirmationOpen = () => {
    setExitConfirmationModal(true)
  }

  const locale = useSessionLanguage()

  useEffect(() => {
    props.validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const handleExitPageConfirmationModal = () => {
    setExitConfirmationModal(false)
  }

  const getBalance = (walletOrTa: WalletOrTradingAccountOption) => {
    const balanceText = t('Trading Account.Balance')

    return (
      <span>{`${balanceText}: ${formatMoney(walletOrTa.balance ?? 0, walletOrTa.currency)}`}</span>
    )
  }
  const handleSetTransferFrom = (walletOrTa: WalletOrTradingAccountOption) => {
    setFieldValue('transferFrom', walletOrTa)
    setTransferFromModal(false)
    handleSetTransferTo(walletOrTa)
  }
  const handleSetTransferTo = (walletOrTa: WalletOrTradingAccountOption) => {
    const transferToOption =
      walletOrTa.displayType === 'wallet'
        ? matrixOptions[walletOrTa.currency]?.tradingAccount
        : matrixOptions[walletOrTa.currency]?.wallet

    setFieldValue('transferTo', transferToOption)
  }
  return (
    <React.Fragment>
      {exitConfirmationModal && (
        <Modal
          closeModal={handleExitPageConfirmationModal}
          render={() => (
            <CancelActionModal
              onConfirm={navigateToPreviousPath}
              onCancel={handleExitPageConfirmationModal}
            />
          )}
        />
      )}
      {transferFromModal && (
        <Modal
          closeModal={() => setTransferFromModal(false)}
          render={({ closeModal }) => (
            <SelectModal
              onCancel={closeModal}
              title={t('Wallet.Transfer From')}
              renderOptions={() => (
                <div className='control'>
                  {Object.entries(matrixOptions).map(([currency, { wallet, tradingAccount }]) => (
                    <React.Fragment key={wallet.id}>
                      <p className={styles.currency}>{currency}</p>
                      <SelectModalOption
                        label={`${wallet.currency} ${wallet.type}`}
                        value={wallet.id === values.transferFrom?.id}
                        onClick={() => handleSetTransferFrom(wallet)}
                      >
                        {getBalance(wallet)}
                      </SelectModalOption>
                      {(tradingAccount.balance ?? -1) >= 0 && (
                        <SelectModalOption
                          label={`${tradingAccount.currency} ${tradingAccount.type}`}
                          value={
                            tradingAccount.currency === values.transferFrom?.currency &&
                            tradingAccount.id === values.transferFrom?.id
                          }
                          onClick={() => handleSetTransferFrom(tradingAccount)}
                          key={tradingAccount.id}
                        >
                          <span>{getBalance(tradingAccount)}</span>
                        </SelectModalOption>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              )}
            />
          )}
        />
      )}
      <FormTemplate title={t('Wallet.Transfer')} goBack={handleExitPageConfirmationOpen}>
        <Form className={styles.wrapper} onSubmit={handleSubmitForm} autoComplete='off'>
          <p className={classNames(styles.info)}>
            <span className='danger'>{t('Trading Account.Important')}</span>{' '}
            {t(
              'Transactions.You can only transfer between Wallets and Trading Accounts of the same currency'
            )}
          </p>
          <Loading showLoadingIcon isLoading={fetchingAccounts}>
            <FormField
              wrapperClassname={styles.noMargin}
              name='transferFrom.name'
              label={t('Wallet.Transfer From')}
              placeholder={t('Wallet.Transfer From')}
              rightIcon={<DropArrowDownIcon />}
              value={
                values.transferFrom
                  ? `${values.transferFrom.currency} ${values.transferFrom.type}`
                  : ''
              }
              readOnly
              hint={
                values.transferFrom &&
                `${t('Trading Account.Balance')} ${formatMoney(
                  values.transferFrom.balance ?? 0,
                  values.transferFrom.currency
                )}`
              }
              required
              onClick={() => setTransferFromModal(true)}
            />
            <div className={styles.amountWrapper}>
              <div className={styles.first}>
                <FormNumericField
                  name={'fromAmount'}
                  label={t('Wallet.Sending Amount')}
                  type='number'
                  autoComplete='false'
                  decimalPlaces={2}
                  min={0}
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                    ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
                  }
                  placeholder={t('Wallet.Sending Amount')}
                  value={fromAmount}
                  disabled={!transferFrom?.id}
                  required
                  step='any'
                />
              </div>
              <div className={styles.last}>
                <FormField
                  name='transferFrom.currency'
                  label={t('Wallet.Currency')}
                  placeholder={t('Wallet.Currency')}
                  className={styles.last}
                  value={transferFrom && transferFrom.currency}
                  disabled
                />
              </div>
            </div>
            <div className={styles.transferTo}>
              <FormField
                disabled
                name={'transferTo.id'}
                label={t('Wallet.Transfer To')}
                placeholder={t('Wallet.Transfer To')}
                value={
                  values.transferTo ? `${values.transferTo.currency} ${values.transferTo.type}` : ''
                }
                hint={
                  values.transferTo &&
                  `${t('Trading Account.Balance')} ${formatMoney(
                    values.transferTo.balance ?? 0,
                    values.transferTo.currency
                  )}`
                }
                rightIcon={<DropArrowDownIcon />}
                readOnly
                required
              />
            </div>
          </Loading>
          {showTANotice && (
            <div className={styles.walletNotice}>
              <div className={styles.symbolWrapper}>
                <TextTinyStrong className={styles.symbol}>
                  {currencyToSymbol(values.transferTo?.currency)}
                </TextTinyStrong>
              </div>
              <TextSmall>
                {t('Wallet.A new currency balance will be added to your Trading Account', {
                  currency: values.transferTo?.currency,
                })}
              </TextSmall>
            </div>
          )}
          <div className={styles.action}>
            <Button
              appearance='secondary'
              type='button'
              onClick={handleExitPageConfirmationOpen}
              size='L'
            >
              {t('Cancel')}
            </Button>
            <Button disabled={!isValid} type='submit' appearance='primary' size='L'>
              {t('Confirm')}
            </Button>
          </div>
        </Form>
      </FormTemplate>
    </React.Fragment>
  )
}

export const TransferFormETD = withFormik<OuterProps, TransferValues>({
  mapPropsToValues: ({
    preselectedWallet,
    preselectedTradingAccount,
    wallets,
    currency,
    tradingAccounts,
  }) => {
    const transferFrom = preselectedWallet
      ? {
          id: preselectedWallet.id,
          currency: preselectedWallet.currency.id,
          balance: preselectedWallet.balance,
          name: preselectedWallet.name,
          type: t('Wallet.Wallet'),
          displayType: 'wallet' as const,
          restrictions: preselectedWallet.restrictions,
        }
      : preselectedTradingAccount &&
        currency &&
        (preselectedTradingAccount?.platformOverview.balances.find(
          (balance) => balance.currency === currency
        )?.balance ?? -1) >= 0
      ? {
          id: preselectedTradingAccount.id,
          currency: currency,
          balance:
            preselectedTradingAccount.platformOverview.balances.find(
              (balance) => balance.currency === currency
            )?.balance || 0,
          name: preselectedTradingAccount.name,
          type: t('Trading Account.Trading Account'),
          displayType: 'tradingAccount' as const,
          restrictions: preselectedTradingAccount.restrictions,
        }
      : null

    const matrixOptions = getMatrixOptions(wallets, tradingAccounts)

    return {
      transferFrom,
      transferTo: transferFrom
        ? transferFrom.displayType === 'wallet'
          ? matrixOptions[transferFrom.currency]?.tradingAccount || null
          : matrixOptions[transferFrom.currency]?.wallet || null
        : null,

      fromAmount: 0.0,
    }
  },

  handleSubmit: async (values, { props }) => {
    props.handleFormSubmit(values)
  },
  validate: (values) => {
    const errors: FormikErrors<TransferValues> = {}
    if (!values.transferFrom) {
      errors.transferFrom = t('Validation.Required')
    }
    if (values.fromAmount <= 0 || isNaN(values.fromAmount)) {
      errors.fromAmount = t('Validation.Amount should be greater than 0.00')
    }
    if (!values.transferTo) {
      errors.transferTo = t('Validation.Required')
    }
    return errors
  },
  enableReinitialize: true,
  isInitialValid: false,
})(TransferFormETDUI)
