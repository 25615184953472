import { ReactNode, createContext, useEffect, useMemo, useState } from 'react'

import { PublicDomainDto } from '../../model/PublicDomainDto'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useGuardedContext } from '../useGuardedContext'

interface PublicDomainsReadContextProps {
  domains?: PublicDomainDto
}

interface PublicDomainsWriteContextProps {
  refreshDomains: () => void
}

const PublicDomainsReadContext = createContext<PublicDomainsReadContextProps | undefined>(undefined)

PublicDomainsReadContext.displayName = 'PublicDomainsReadContext'

const PublicDomainsWriteContext = createContext<PublicDomainsWriteContextProps | undefined>(
  undefined
)

export function usePublicDomainsReadContext(): PublicDomainsReadContextProps {
  return useGuardedContext(PublicDomainsReadContext)
}

export function usePublicDomainsWriteContext() {
  return useGuardedContext(PublicDomainsWriteContext)
}

interface Props {
  publicDomains?: PublicDomainDto
  children: ReactNode
}

export function PublicDomainsContextProvider({ publicDomains, children }: Props) {
  const [domains, setDomains] = useState(publicDomains)
  const locationHost =
    process.env.NODE_ENV === 'development'
      ? 'dev-bo01-ca-02-sc.d.tickmill.com'
      : window.location.host
  const apiClient = useApiClient(ClientApiClient)

  useEffect(() => {
    const fetchDomains = async () => {
      try {
        const fetchedDomains = await apiClient.getPublicDomain(locationHost)
        setDomains(fetchedDomains)
      } catch (error: unknown) {
        console.error('Error fetching domains:', error)
      }
    }

    fetchDomains()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationHost])

  const writeContext = useMemo<PublicDomainsWriteContextProps>(() => {
    return {
      async refreshDomains(pubDomains?: PublicDomainDto) {
        if (!pubDomains) {
          setDomains(await apiClient.getPublicDomain(locationHost))
        } else {
          setDomains(domains)
        }
      },
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const readContext = useMemo<PublicDomainsReadContextProps>(() => {
    return { domains: domains }
  }, [domains])

  return (
    <PublicDomainsReadContext.Provider value={readContext}>
      <PublicDomainsWriteContext.Provider value={writeContext}>
        {children}
      </PublicDomainsWriteContext.Provider>
    </PublicDomainsReadContext.Provider>
  )
}
