import { FC } from 'react'

import '../../icons/Icons.module.scss'

export const TMAsiaLogoMobile: FC = () => (
  <svg width='120' height='30' viewBox='0 0 120 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clip-path='url(#clip0_77751_365935)'>
      <path
        d='M53.091 16.4632C54.582 16.4632 55.8183 15.8813 56.7638 14.6813L58.7274 16.6813C57.1638 18.4268 55.3456 19.2995 53.2729 19.2995C51.1638 19.2995 49.4547 18.645 48.0729 17.2995C46.7274 15.9904 46.0365 14.2813 46.0365 12.2813C46.0365 10.245 46.7274 8.57225 48.1092 7.22679C49.491 5.88134 51.2001 5.19043 53.2001 5.19043C55.4547 5.19043 57.3092 6.02679 58.8365 7.73588L56.9456 9.88134C56.0001 8.68134 54.8001 8.09952 53.3456 8.09952C52.2183 8.09952 51.2365 8.46316 50.4001 9.22679C49.6001 9.99043 49.1638 10.9722 49.1638 12.245C49.1638 13.5177 49.5638 14.5359 50.3274 15.2995C51.091 16.0632 52.0001 16.4632 53.091 16.4632Z'
        fill='var(--logo-text-color)'
      />
      <path
        d='M34.3637 19.154V8.13587H38.2182V5.51768H27.4546V8.13587H31.3091V19.154H34.3637Z'
        fill='var(--logo-text-color)'
      />
      <path d='M43.4909 5.51768H40.4364V19.154H43.4909V5.51768Z' fill='var(--logo-text-color)' />
      <path
        d='M64.1819 5.51768H61.1273V19.154H64.1819V15.2995L65.5637 13.7722L69.5637 19.154H73.1273C72.789 18.6764 72.3526 18.0681 71.8183 17.3231L71.8139 17.317C71.3729 16.7022 70.8652 15.9944 70.291 15.1904C69.1658 13.5689 68.3418 12.4292 67.8464 11.744L67.7091 11.554L73.1273 5.51768H69.3455L64.1819 11.1177V5.51768Z'
        fill='var(--logo-text-color)'
      />
      <path
        d='M87.2 10.5722L83.5273 18.0268H81.7091L78.0364 10.5722V19.154H74.9818V5.51768H79.0909L82.6182 13.0086L86.1454 5.51768H90.2545V19.154H87.2V10.5722Z'
        fill='var(--logo-text-color)'
      />
      <path d='M96.6545 5.51768H93.6V19.154H96.6545V5.51768Z' fill='var(--logo-text-color)' />
      <path
        d='M99.9999 19.154V5.51768H103.054V16.4268H108.873V19.154H99.9999Z'
        fill='var(--logo-text-color)'
      />
      <path
        d='M111.091 5.51768V19.154H119.964V16.4268H114.145V5.51768H111.091Z'
        fill='var(--logo-text-color)'
      />
      <path
        d='M5.78182 9.00909C5.56364 9.15455 5.38182 9.48182 5.38182 9.73636H5.45455V14.2455L0 17.3727V7.19091C0 6.53636 0.472727 5.73636 1.01818 5.40909L9.74546 0.245455C10.2909 -0.0818182 11.2 -0.0818182 11.7455 0.245455L20.4727 5.40909C20.5091 5.40909 20.5455 5.44546 20.5818 5.48182L15.0545 8.68182L11.1273 6.39091C10.8727 6.24545 10.5091 6.24545 10.2909 6.39091L5.78182 9.00909Z'
        fill='var(--logo-custom-color)'
      />
      <path
        d='M16.0002 14.9364V10.4273L21.382 7.30005V17.4819C21.382 18.1364 20.9093 18.9364 20.3638 19.2637L11.6365 24.4273C11.0911 24.7546 10.182 24.7546 9.63653 24.4273L0.909262 19.2637C0.872901 19.2637 0.836539 19.2273 0.800178 19.191L6.32744 15.991L10.2547 18.2819C10.5093 18.4273 10.8729 18.4273 11.0911 18.2819L15.6002 15.6637C15.8184 15.5182 16.0002 15.191 16.0002 14.9364Z'
        fill='var(--logo-custom-color)'
      />
      <path
        d='M105.877 29.31L105.322 28.035H102.555L102.007 29.31H101.25L103.582 24.06H104.332L106.657 29.31H105.877ZM102.84 27.3825H105.045L103.935 24.8325L102.84 27.3825Z'
        fill='var(--logo-text-color)'
      />
      <path
        d='M110.736 25.155C110.491 25 110.236 24.885 109.971 24.81C109.711 24.73 109.471 24.69 109.251 24.69C108.946 24.69 108.701 24.75 108.516 24.87C108.336 24.985 108.246 25.145 108.246 25.35C108.246 25.535 108.301 25.6875 108.411 25.8075C108.521 25.9225 108.656 26.015 108.816 26.085C108.976 26.15 109.198 26.2275 109.483 26.3175C109.838 26.4275 110.126 26.5325 110.346 26.6325C110.566 26.7325 110.753 26.8825 110.908 27.0825C111.063 27.2775 111.141 27.5375 111.141 27.8625C111.141 28.1625 111.058 28.425 110.893 28.65C110.733 28.875 110.506 29.05 110.211 29.175C109.921 29.295 109.586 29.355 109.206 29.355C108.821 29.355 108.446 29.28 108.081 29.13C107.716 28.98 107.401 28.78 107.136 28.53L107.458 27.9075C107.708 28.1525 107.988 28.3425 108.298 28.4775C108.613 28.6075 108.918 28.6725 109.213 28.6725C109.563 28.6725 109.838 28.605 110.038 28.47C110.243 28.33 110.346 28.1425 110.346 27.9075C110.346 27.7225 110.291 27.57 110.181 27.45C110.071 27.33 109.931 27.235 109.761 27.165C109.596 27.095 109.371 27.0175 109.086 26.9325C108.736 26.8275 108.451 26.725 108.231 26.625C108.011 26.525 107.823 26.3775 107.668 26.1825C107.518 25.9875 107.443 25.73 107.443 25.41C107.443 25.13 107.521 24.8825 107.676 24.6675C107.831 24.4525 108.048 24.2875 108.328 24.1725C108.608 24.0575 108.933 24 109.303 24C109.618 24 109.926 24.0475 110.226 24.1425C110.531 24.2325 110.801 24.355 111.036 24.51L110.736 25.155Z'
        fill='var(--logo-text-color)'
      />
      <path d='M112.59 24.06H113.31V29.31H112.59V24.06Z' fill='var(--logo-text-color)' />
      <path
        d='M119.009 29.31L118.454 28.035H115.686L115.139 29.31H114.381L116.714 24.06H117.464L119.789 29.31H119.009ZM115.971 27.3825H118.176L117.066 24.8325L115.971 27.3825Z'
        fill='var(--logo-text-color)'
      />
    </g>
    <defs>
      <clipPath id='clip0_77751_365935'>
        <rect width='120' height='30' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
