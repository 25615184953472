import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { SelectModal, SelectModalOption } from '../../global/field/SelectModal'
import { Modal } from '../../global/modal/Modal'
import { NameDto } from '../../model/NameDto'
import { CompanyDetailsStep2FormValues } from './CompanyDetailsStep2Form'

interface OwnershipStructureModalProps {
  ownershipStructures: NameDto[]
  onSelectOption(option: NameDto): void
  onClose(): void
}

export const OwnershipStructureModal: React.FC<OwnershipStructureModalProps> = (props) => {
  const { ownershipStructures, onClose, onSelectOption } = props

  const { t } = useTranslation()

  const { values, setValues } = useFormikContext<CompanyDetailsStep2FormValues>()

  const handleSelectOption = (name: NameDto) => {
    setValues({ ...values, ownershipStructure: name })
    onSelectOption(name)
  }

  return (
    <Modal
      closeModal={onClose}
      render={({ closeModal }) => (
        <SelectModal
          onCancel={closeModal}
          title={t('Sign up.Ownership Structure')}
          renderOptions={() => (
            <div className='control'>
              {ownershipStructures.map((name) => (
                <SelectModalOption
                  label={name.name}
                  value={values.ownershipStructure.id === name.id}
                  onClick={() => handleSelectOption(name)}
                  key={name.id}
                />
              ))}
            </div>
          )}
        />
      )}
    />
  )
}
