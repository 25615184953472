import React, { useCallback, useEffect, useRef } from 'react'
import classNames from 'classnames'

import { Loading } from '../../global/Loading/Loading'
import { BackButton } from '../../global/backButton/BackButton'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { PlayIcon } from '../../icons/PlayIcon'
import { CourseDto } from '../../model/Course'
import { LessonDto } from '../../model/Lesson'
import { Tel3Client } from '../../utils/Tel3Client'
import { useWindowResize } from '../../utils/domUtils'
import { useFetchOne } from '../../utils/useFetch'

import styles from './Training.module.scss'

export const TrainingVideosPage = () => {
  const [selectedCourse, setSelectedCourse] = React.useState<CourseDto | null>(null)
  const [videos, setVideos] = React.useState<LessonDto[]>([])
  const [selectedVideo, setSelectedVideo] = React.useState<LessonDto | null>(null)
  const [isLoading, setIslLoading] = React.useState<boolean>(true)
  const videoRef = useRef<HTMLVideoElement>(null)
  const apiClient = new Tel3Client()
  const isMobile = useWindowResize()
  const locale = useSessionLanguage()

  const callback = useCallback(async () => {
    setIslLoading(true)
    const response = await apiClient.get<CourseDto[]>('/product/level-items/course')
    const filtered = response.filter((plan) => plan.isOpen)
    setIslLoading(false)
    return filtered
  }, [locale])

  const onSelectCourse = async (course: CourseDto) => {
    const response = await apiClient.get<LessonDto[]>(
      `/product/level-children/course?id=${course.id}`
    )
    setVideos(response)
    setSelectedVideo(response[0])
    setSelectedCourse(course)
    if (isMobile) {
      setTimeout(() => {
        if (videoRef.current) {
          const elementPosition = videoRef.current.getBoundingClientRect().top + window.scrollY
          const offset = 100
          window.scrollTo({
            top: elementPosition - offset,
            behavior: 'smooth',
          })
        }
      }, 100)
    }
  }

  useEffect(() => {
    if (selectedCourse) {
      setSelectedCourse(null)
      setSelectedVideo(null)
    }
  }, [locale])

  const { data } = useFetchOne(callback)

  if (selectedCourse && selectedVideo) {
    return (
      <section>
        <div className={classNames(styles.backButton)}>
          <BackButton onClick={() => setSelectedVideo(null)} />
        </div>
        <div className={styles.listContainer}>
          <div>
            <h1>{selectedCourse.name}</h1>
            <h2>{selectedVideo.name}</h2>
            <video className={styles.video} src={selectedVideo.videoUrl} ref={videoRef} controls />
          </div>
          <div className={styles.list}>
            {videos.map((video, index) => (
              <div
                onClick={() => setSelectedVideo(video)}
                key={index}
                className={classNames(styles.item, {
                  [styles.isSelected]: selectedVideo.id === video.id,
                })}
              >
                <PlayIcon />
                <h4>{video.name}</h4>
                <span className={styles.duration}>{video.videoDuration}</span>
              </div>
            ))}
          </div>
        </div>
      </section>
    )
  }
  return (
    <Loading isLoading={isLoading}>
      <div className={styles.courses}>
        {data &&
          data.map((course, index) => (
            <div onClick={() => onSelectCourse(course)} key={index} className={styles.card}>
              <h4>{course.name}</h4>
              <img src={course.image} alt={course.name} />
            </div>
          ))}
      </div>
    </Loading>
  )
}
